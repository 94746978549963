const state = () => {
  return {
    messageModalSub: '',
    messageModalTitle: '',
    confirmModalSub: '',
    confirmModalTitle: '',
    notificationMessage: '',
    pdfModalTitle: '',
    showMessageModal: false,
    showConfirmModal: false,
    showSignatureModal: false,
    showLoadingModal: false,
    sendMessageModal: false,
    showPasswordModal: false,
    showPdfModal: false,
    sendSmsEmailModal: false,
    smsEmailModalType: 'sms',
    showNotificationModal: false,
    messageModalBackground: true,
    confirm: null,
    decline: null,
    messageSend: null,
    messageDecline: null,
    sendMessageData: null,
    pdfLink: null,
    pdfTableReference: null,
    pdfIdReference: null,
    pdfSigned: false,
    signDone: null,
    xml: null,
    printPdf: null,
    downloadPdf: null,
    passwordModalPassword: false,
    passwordModalConsensi: false,
    passwordModalLicenza: false,
    confirm_text: null,
    decline_text: null,
    modalBlock: false,
    showVaccineModal: false,
    vaccineData: null,
    showWeightModal: false,
    weightData: null,
    showAnnotationModal: false,
    annotationData: null,
    showTableModal: false,
    tableData: null,
    pdfClient: null,
    pdfDate: null,
    pdfConfirmations: null,
    isLight: false,
    profileColor: null,
    templateModal: false,
    templateData: null
  }
}

// getters
const getters = {
  getShowMessageModal: (state) => state.showMessageModal,
  getShowConfirmModal: (state) => state.showConfirmModal,
  getShowSignatureModal: (state) => state.showSignatureModal,
  getShowLoadingModal: (state) => state.showLoadingModal,
  getSendMessageModal: (state) => state.sendMessageModal,
  getSendMessageData: (state) => state.sendMessageData,
  getShowPdfModal: (state) => state.showPdfModal,
  getSendSmsEmailModal: (state) => state.sendSmsEmailModal,
  getSmsEmailModalType: (state) => state.smsEmailModalType,
  getMessageModalTitle: (state) => state.messageModalTitle,
  getPdfModalTitle: (state) => state.pdfModalTitle,
  getPdfModalLink: (state) => state.pdfLink,
  getPdfTableReference: (state) => state.pdfTableReference,
  getPdfIdReference: (state) => state.pdfIdReference,
  getPdfClient: (state) => state.pdfClient,
  getPdfSigned: (state) => state.pdfSigned,
  getPdfConfirmations: (state) => state.pdfConfirmations,
  getXmlModal: (state) => state.xml,
  getMessageModalSubtitle: (state) => state.messageModalSub,
  getConfirmModalTitle: (state) => state.confirmModalTitle,
  getConfirmModalSubtitle: (state) => state.confirmModalSub,
  getConfirmModalConfirmText: (state) => state.confirm_text,
  getConfirmModalDeclineText: (state) => state.decline_text,
  getMessageModalBackground: (state) => state.messageModalBackground,
  getShowNotificationModal: (state) => state.showNotificationModal,
  getNotificationMessage: (state) => state.notificationMessage,
  getShowPasswordModal: (state) => state.showPasswordModal,
  getPasswordModalPassword: (state) => state.passwordModalPassword,
  getPasswordModalConsensi: (state) => state.passwordModalConsensi,
  getPasswordModalLicenza: (state) => state.passwordModalLicenza,
  getShowBlockModal: (state) => state.modalBlock,
  getShowVaccineModal: (state) => state.showVaccineModal,
  getVaccineData: (state) => state.vaccineData,
  getShowWeightModal: (state) => state.showWeightModal,
  getWeightData: (state) => state.weightData,
  getAnnotationModal: (state) => state.showAnnotationModal,
  getAnnotationData: (state) => state.annotationData,
  getTableModal: (state) => state.showTableModal,
  getTableData: (state) => state.tableData,
  getPdfDate: (state) => state.pdfDate,
  getProfileColor: (state) => state.profileColor,
  getIsLight: (state) => state.isLight,
  getShowTemplateModal: (state) => state.templateModal,
  getTemplateModalData: (state) => state.templateData
}

// actions
const actions = {
  lockPage: function ({ commit }) {
    commit('setBlockModal', { block: true })
  },
  unlockPage: function ({ commit }) {
    commit('setBlockModal', { block: false })
  },
  openMessageModal: function ({ commit }, title, subtitle, backgroud) {
    commit('setMessageModalData', title, subtitle, backgroud)
  },
  openPasswordModal: function (
    { commit },
    openPassword,
    openConsensi,
    openLicenza
  ) {
    commit('setPasswordModalData', openPassword, openConsensi, openLicenza)
  },
  openNotificationModal: function ({ commit }, message, backgroud) {
    commit('setNotificationModalData', message, backgroud)
  },
  openConfirmModal: function (
    { commit },
    title,
    subtitle,
    backgroud,
    confirm,
    decline,
    confirm_text,
    decline_text
  ) {
    commit(
      'setConfirmModalData',
      title,
      subtitle,
      backgroud,
      confirm,
      decline,
      confirm_text,
      decline_text
    )
  },
  openSignatureModal: function ({ commit }, confirm, decline) {
    commit('setSignatureModalData', confirm, decline)
  },
  openSendMessageModal: function ({ commit }, data, confirm, decline) {
    commit('setSendMessageModal', data, confirm, decline)
  },
  closeSendMessageModal: function ({ commit }) {
    commit('removeSendModalData')
  },
  openTemplateModal: function ({ commit }, data) {
    commit('setTemplateModalData', data)
  },
  closeTemplateModal: function ({ commit }) {
    commit('removeTemplateModalData')
  },
  templateModalConfirm: function ({ commit, state }, data) {
    state.templateData.confirm ? state.templateData.confirm(data) : null
    commit('removeTemplateModalData')
  },
  openSmsEmailModal: function (
    { commit },
    backgroud,
    confirm,
    decline,
    modal_type
  ) {
    commit('setSmsEmailModal', backgroud, confirm, decline, modal_type)
  },
  openPdfModal: function (
    { commit },
    title,
    link,
    backgroud,
    print,
    download,
    xml,
    table_reference,
    id_reference,
    client,
    pdf_date,
    pdf_signed,
    sign_done,
    confirmations
  ) {
    commit(
      'setPdfModalData',
      title,
      link,
      backgroud,
      print,
      download,
      xml,
      table_reference,
      id_reference,
      client,
      pdf_date,
      pdf_signed,
      sign_done,
      confirmations
    )
  },
  openVaccineModal: function ({ commit }, vaccineData) {
    commit('setVaccineModalData', vaccineData)
  },
  closeVaccineModal: function ({ commit }) {
    commit('removeVaccineModalData')
  },
  openWeightModal: function ({ commit }, weightData) {
    commit('setWeightModalData', weightData)
  },
  closeWeightModal: function ({ commit }) {
    commit('removeWeightModalData')
  },
  openAnnotationModal: function ({ commit }, annotationData) {
    commit('setAnnotationModalData', annotationData)
  },
  openTableModal: function ({ commit }, tableData) {
    commit('setTableModalData', tableData)
  },
  closeTableModal: function ({ commit }) {
    commit('removeTableModalData')
  },
  closeAnnotationModal: function ({ commit }) {
    commit('removeAnnotationModalData')
  },
  closeSendSMSModal: function ({ commit }) {
    commit('removeSendSMSModalData')
  },
  closePdfModal: function ({ commit }) {
    commit('removePdfModalData')
  },
  closeMessageModal: function ({ commit }) {
    commit('removeMessageModalData')
  },
  closePasswordModal: function ({ commit }) {
    commit('removePasswordModalData')
  },
  closeNotificationModal: function ({ commit }) {
    commit('removeNotificationModalData')
  },
  closeConfirmModal: function ({ commit }) {
    commit('removeConfirmModalData')
  },
  closeSignatureModal: function ({ commit }) {
    commit('removeSignatureModalData')
  },
  signatureModalConfirm: function ({ commit, state }, data) {
    state.confirm(data)
    commit('removeSignatureModalData')
  },
  signatureModalDecline: function ({ commit, state }) {
    state.decline()
    commit('removeSignatureModalData')
  },
  signDoneMethod: function ({ commit, state }) {
    state.signDone()
    commit('removePdfModalData')
  },
  messageModalConfirm: function ({ commit, state }) {
    state.confirm()
    commit('removeConfirmModalData')
  },
  messageModalDecline: function ({ commit, state }) {
    state.decline()
    commit('removeConfirmModalData')
  },
  pdfModalPrint: function ({ state }) {
    state.printPdf()
  },
  pdfModalDownload: function ({ state }) {
    state.downloadPdf()
  },
  messageSendConfirm: function ({ commit, state }, data) {
    state.messageSend(data)
    commit('removeSendMessageModalData')
  },
  messageSendDecline: function ({ commit, state }) {
    state.messageDecline()
    commit('removeSendMessageModalData')
  },
  showLoadingModalAction: function ({ commit }) {
    commit('showLoadingModalMutation')
  },
  hideLoadingModalAction: function ({ commit }) {
    commit('hideLoadingModalMutation')
  },
  updateProfileColor: function ({ commit }, value) {
    commit('setProfileColor', value)
  },
  updateIsLight: function ({ commit }, value) {
    commit('setIsLight', value)
  }
}

// mutations
const mutations = {
  setMessageModalData: function (state, payload) {
    state.messageModalTitle = payload.title
    state.messageModalSub = payload.subtitle
    state.showMessageModal = true
    if (payload.backgroud !== null && payload.backgroud !== undefined) {
      state.messageModalBackground = payload.backgroud
    }
  },
  setPasswordModalData: function (state, payload) {
    state.passwordModalPassword = payload.openPassword
    state.passwordModalConsensi = payload.openConsensi
    state.passwordModalLicenza = payload.openLicenza
    state.showPasswordModal = true
  },
  setNotificationModalData: function (state, payload) {
    state.notificationMessage = payload.message
    state.showNotificationModal = true
    if (payload.backgroud !== null && payload.backgroud !== undefined) {
      state.messageModalBackground = payload.backgroud
    }
  },
  setConfirmModalData: function (state, payload) {
    state.confirmModalTitle = payload.title
    state.confirmModalSub = payload.subtitle
    state.showConfirmModal = true
    state.confirm = payload.confirm
    state.decline = payload.decline
    if (payload.confirm_text) state.confirm_text = payload.confirm_text
    if (payload.decline_text) state.decline_text = payload.decline_text
  },
  setSignatureModalData: function (state, payload) {
    state.showSignatureModal = true
    state.confirm = payload.confirm
    state.decline = payload.decline
  },
  setSendMessageModal: function (state, payload) {
    state.sendMessageModal = true
    state.sendMessageData = payload.data
    state.messageSend = payload.confirm
    state.messageDecline = payload.decline
  },
  setTemplateModalData: function (state, payload) {
    state.templateModal = true
    state.templateData = payload
  },
  removeTemplateModalData: function (state) {
    state.templateModal = false
    state.templateData = null
  },
  setSmsEmailModal: function (state, payload) {
    state.sendSmsEmailModal = true
    state.smsEmailModalType = payload.modal_type
    state.messageSend = payload.confirm
    state.messageDecline = payload.decline
  },
  setPdfModalData: function (state, payload) {
    state.pdfModalTitle = payload.title
    state.pdfLink = payload.link
    state.xml = payload.xml
    state.showPdfModal = true
    state.printPdf = payload.print
    state.downloadPdf = payload.download
    state.pdfTableReference = payload.table_reference
    state.pdfIdReference = payload.id_reference
    state.pdfClient = payload.client
    state.pdfSigned = payload.pdf_signed
    state.signDone = payload.sign_done
    state.pdfDate = payload.pdf_date
    if (payload.backgroud !== null && payload.backgroud !== undefined) {
      state.messageModalBackground = payload.backgroud
    }
    state.pdfConfirmations = payload.confirmations
  },
  setVaccineModalData: function (state, payload) {
    state.vaccineData = payload.vaccineData
    state.showVaccineModal = true
  },
  removeVaccineModalData: function (state) {
    state.showVaccineModal = false
  },
  setAnnotationModalData: function (state, payload) {
    state.annotationData = payload.annotationData
    state.showAnnotationModal = true
  },
  setTableModalData: function (state, payload) {
    state.tableData = payload.tableData
    state.showTableModal = true
  },
  removeTableModalData: function (state) {
    state.showTableModal = false
  },
  removeAnnotationModalData: function (state) {
    state.showAnnotationModal = false
  },
  setWeightModalData: function (state, payload) {
    state.weightData = payload.weightData
    state.showWeightModal = true
  },
  removeWeightModalData: function (state) {
    state.showWeightModal = false
  },
  removeSendModalData: function (state) {
    state.sendMessageModal = false
    state.sendMessageData = null
    state.messageSend = null
    state.messageDecline = null
  },

  removeSendSMSData: function (state) {
    state.sendSmsEmailModal = false
    state.smsEmailModalType = 'sms'
    state.messageSend = null
    state.messageDecline = null
  },

  removeMessageModalData: function (state) {
    state.messageModalTitle = ''
    state.messageModalSub = ''
    state.showMessageModal = false
    state.messageModalBackground = true
    state.confirm = null
    state.decline = null
  },
  removePasswordModalData: function (state) {
    state.passwordModalPassword = false
    state.passwordModalConsensi = false
    state.passwordModalLicenza = false
    state.showPasswordModal = false
    state.messageModalBackground = true
    state.confirm = null
    state.decline = null
  },
  removeNotificationModalData: function (state) {
    state.notificationMessage = ''
    state.showNotificationModal = false
    state.messageModalBackground = true
  },
  removeSendMessageModalData: function (state) {
    state.sendMessageModal = false
    state.sendMessageData = null
    state.messageSend = null
    state.messageDecline = null
  },

  removeConfirmModalData: function (state) {
    state.confirmModalTitle = ''
    state.confirmModalSub = ''
    state.showConfirmModal = false
    state.messageModalBackground = true
    state.confirm = null
    state.decline = null
    state.confirm_text = null
    state.decline_text = null
  },
  removeSignatureModalData: function (state) {
    state.showSignatureModal = false
    state.confirm = null
    state.decline = null
  },
  showLoadingModalMutation: function (state) {
    state.showLoadingModal = true
  },
  hideLoadingModalMutation: function (state) {
    state.showLoadingModal = false
  },
  removePdfModalData: function (state) {
    state.showPdfModal = false
    state.pdfModalTitle = null
    state.pdfLink = null
    state.xml = null
    state.printPdf = null
    state.downloadPdf = null
    state.pdfTableReference = null
    state.pdfIdReference = null
    state.pdfSigned = false
    state.signDone = null
    state.pdfClient = null
    state.pdfDate = null
  },
  setBlockModal: function (state, payload) {
    state.modalBlock = payload.block
  },
  setProfileColor: function (state, payload) {
    state.profileColor = payload
  },
  setIsLight: function (state, payload) {
    state.isLight = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
