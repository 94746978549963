<template>
  <div
    class="rounded-md p-3 shadow-md border-2 border-theme-44 border-opacity-20 m-3"
  >
    <div class="flex justify-center">
      <h2 class="text-3xl text-theme-40 nunito mt-1 font-bold py-2">
        {{ title }}
      </h2>
    </div>

    <div id="responsive-table-list" class="bg-white p-3 rounded-lg">
      <div class="paginator-container">
        <div class="prova mr-5 my-2 flex justify-between">
          <div class="mt-1">
            <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
              >Mostra
            </span>
            <select
              class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
              @change="(event) => changeNumPages(event)"
            >
              <option v-for="show in forPageOption" :key="show" :value="show">
                {{ show }}
              </option>
            </select>
            <span class="text-md font-bold ml-2 mt-1"> elementi</span>
          </div>
        </div>
        <Paginator
          :for-page-option="pagination.forPage"
          :total-records="totalRecord"
          @changePagination="changePagination"
        />
      </div>

      <div
        class="table-container pb-4 scrollbar scrollbar-thin scrollbar-thumb-theme-44 scrollbar-track-theme-2 overflow-y-scroll"
      >
        <table class="w-full">
          <THeader
            v-for="(elem, index) in tableConfigClinicDoc"
            :key="index"
            class="t-header"
            :name="elem.title"
          />
          <Cell class="t-header" />
          <!-- <Cell
                          class="border-b-2 border-theme-13 font-bold bg-theme-33"
                        /> -->
          <tbody>
            <tr>
              <td
                v-for="(elem, index) in tableConfigClinicDoc"
                :key="index"
                class="border-b whitespace-nowrap p-2 py-4"
              >
                <select
                  v-if="elem.type === 'select'"
                  v-model="table_filter[elem.key]"
                  class="custom-input"
                >
                  <option selected></option>
                  <!--<option :value="null"></option>-->
                  <option
                    v-for="(item, i) in elem.option"
                    :key="i"
                    :value="item"
                  >
                    {{ item.name ? item.name : item }}
                  </option>
                </select>
                <input
                  v-else
                  v-model="elem.value"
                  :type="elem.type"
                  :name="elem.title"
                  class="custom-input"
                  :class="{ invisible: elem.type === 'none' }"
                  :placeholder="elem.title"
                  @change="filterHandler(elem)"
                />
              </td>
            </tr>
            <tr
              v-for="(item, index) in documents"
              :key="index"
              :class="{ 'bg-theme-2': index % 2 === 0 }"
              class="hover:bg-theme-44 cursor-pointer"
            >
              <td class="text-center border-r border-theme-45">
                <TableShotColor :code="item.code" />
              </td>
              <td
                class="border-b border-l whitespace-nowrap text-left p-1 px-1"
              >
                <div class="flex items-center justify-center px-2">
                  {{ item.medical ? 'Medico' : 'Non medico' }}
                </div>
              </td>
              <td class="border-b border-l whitespace-nowrap text-center p-1">
                {{ $FormatDateMin(item.arrival_time) }}
              </td>
              <td
                :data-epoch="item.arrival_time"
                class="border-b border-l whitespace-nowrap text-center p-1 #update_timer"
              >
                {{ $getDifferenceMinutes(item.arrival_time) }}
              </td>
              <td
                class="border-b border-l whitespace-nowrap text-center p-1 capitalize"
              >
                {{
                  item.staff_user_first_name && item.staff_user_last_name
                    ? item.staff_user_first_name +
                      ' ' +
                      item.staff_user_last_name
                    : '-'
                }}
              </td>
              <td class="border-b border-l whitespace-nowrap text-center p-1">
                <div
                  class="tooltipbuttonprofile flex items-center gap-1 capitalize"
                >
                  <div @click="$emit('goToCustomer', item.customer_id)">
                    <ChevronsRightIcon class="orange-round" />
                    <span
                      class="tooltipbuttontext"
                      style="
                        text-transform: uppercase;
                        margin-left: 200px;
                        margin-top: -10px;
                      "
                      >Apri Anagrafica</span
                    >
                  </div>
                  <div class="pt-1">
                    {{
                      item.customer_first_name && item.customer_last_name
                        ? item.customer_first_name +
                          ' ' +
                          item.customer_last_name
                        : '-'
                    }}
                  </div>
                </div>
              </td>
              <td class="border-b border-l whitespace-nowrap text-center pl-2">
                <div class="tooltipbuttonprofile flex items-center gap-1">
                  <div @click="$emit('goToPet', item.pet_id)">
                    <ChevronsRightIcon class="orange-round" />
                    <span
                      class="tooltipbuttontext"
                      style="margin-left: 60px; margin-top: -10px"
                      >Apri Anagrafica</span
                    >
                  </div>
                  <div class="pt-1">
                    {{ item.pet_name ? item.pet_name : 'Animale?' }}
                  </div>
                </div>
              </td>
              <td class="border-b border-l whitespace-nowrap text-center p-1">
                {{ item.reason ? item.reason : '' }}
              </td>
              <td
                v-if="title == 'Clienti in clinica'"
                class="border-b border-l whitespace-nowrap text-center p-1"
              >
                <span v-if="item.department_name">
                  {{ item.department_name }}</span
                >
                <span v-if="item.department_name && item.clinic_room_name">
                  -
                </span>
                <span v-if="item.clinic_room_name">
                  {{ item.clinic_room_name }}</span
                >
              </td>
              <td
                v-else
                class="border-b border-l whitespace-nowrap text-center p-1"
              >
                <button
                  v-if="
                    permissions &&
                    permissions['sala d attesa'] &&
                    permissions['sala d attesa'].move_waiting_room?.enabled ===
                      true
                  "
                  class="tooltipbuttonprofile orange-round h-5 w-5"
                  @click="manageRoom(item)"
                >
                  <i class="fas fa-arrow-down text-sm" />
                  <span
                    class="tooltipbuttontext"
                    style="
                      text-transform: uppercase;
                      width: 130px;
                      margin-left: -35px;
                      margin-top: -20px;
                    "
                    >Ammetti in clinica</span
                  >
                </button>
              </td>
              <td
                v-if="title == 'Clienti in clinica'"
                class="border-b border-l whitespace-nowrap text-center p-1"
              >
                <button
                  v-if="
                    permissions &&
                    permissions['sala d attesa'] &&
                    permissions['sala d attesa'].move_waiting_room?.enabled ===
                      true
                  "
                  class="orange-round h-5 w-5"
                  @click="backToWaiting(item)"
                >
                  <div class="tooltipbuttonprofile flex justify-center">
                    <i class="fas fa-arrow-up text-sm" />
                    <span
                      class="tooltipbuttontext"
                      style="
                        text-transform: uppercase;
                        width: 160px;
                        margin-left: -45px;
                        margin-top: -20px;
                      "
                      >Sposta in sala d'attesa</span
                    >
                  </div>
                </button>
              </td>
              <td class="border-b border-l whitespace-nowrap text-center p-1">
                <button
                  v-if="
                    permissions &&
                    permissions['sala d attesa'] &&
                    permissions['sala d attesa'].move_waiting_room?.enabled ===
                      true
                  "
                  class="orange-round h-5 w-5"
                  @click="exit(item)"
                >
                  <div class="tooltipbuttonprofile flex justify-center">
                    <i class="fas fa-sign-out-alt" style="font-size: 0.8rem" />
                    <span
                      class="tooltipbuttontext"
                      style="
                        text-transform: uppercase;
                        width: 80px;
                        margin-left: -15px;
                        margin-top: -30px;
                      "
                      >Dimetti</span
                    >
                  </div>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <AddInClinicModal
      v-if="openAddInClinic"
      :waiting_id="chosen_id"
      :room_id="chosen_room"
      :pet_id="chosen_pet"
      @close="closeModal"
    />
  </div>
</template>
s
<script>
import { defineComponent, computed, ref, toRef, toRaw } from 'vue'
import Paginator from '../../components/paginator/Main'
import THeader from '../../components/cvit-table/THeader.vue'
import Cell from '../../components/cvit-table/Cell.vue'
import AddInClinicModal from '../../components/modals/addInClinicModal.vue'
import axios from '../../gateway/backend-api'
import { useStore } from 'vuex'
import TableShotColor from '../details-customer/TableShotColor.vue'

export default defineComponent({
  components: {
    Paginator,
    THeader,
    Cell,
    AddInClinicModal,
    TableShotColor
  },
  props: {
    tableConfigClinicDoc: {
      type: [Array],
      default: function () {
        return []
      }
    },
    documents: {
      type: [Array],
      default: function () {
        return []
      }
    },
    pagination: {
      type: [Object],
      default: function () {
        return {}
      }
    },
    forPageOption: {
      type: [Array],
      default: function () {
        return []
      }
    },
    totalRecord: Number,
    // 'selectedPatient',
    title: {
      type: [String],
      default: function () {
        return ''
      }
    },
    buttonText: {
      type: [String],
      default: function () {
        return ''
      }
    }
  },
  emits: [
    'update',
    'changePagination',
    'goToPet',
    'goToCustomer',
    'filterHandler'
  ],
  setup(props) {
    const store = useStore()
    // ----- COMPUTED ----- //
    const table_config = computed(function () {
      // configurazione tabella, filtrando i dati del paziente nel caso in cui siamo nella sezione anagrafica paziente
      return props.tableConfigClinicDoc.filter(
        (elem) => !(elem.key === 'patient' && props.selectedPatient)
      )
    })
    const permissions = computed(() => {
      return store.state.reusableData.permissions
        ? store.state.reusableData.permissions
        : null
    })

    const active_filters = ref({})
    const table_filter = ref([])
    const pagination = toRef(props, 'pagination')
    // ----- FINE COMPUTED ----- //
    const changePagination = (value) => {
      if (value.page != pagination.value.page) {
        pagination.value.page = value.page
        pagination.value.forPage = value.forPage
      }
    }

    const changeNumPages = (elem) => {
      if (elem.target.value != pagination.value.forPage) {
        pagination.value.forPage = elem.target.value
      }
    }

    return {
      table_config,
      changePagination,
      changeNumPages,
      permissions,
      active_filters,
      table_filter
    }
  },
  data() {
    return {
      openAddInClinic: false,
      chosen_room: '',
      chosen_id: ''
    }
  },
  watch: {
    'table_filter.medical': function (elem) {
      this.filterSelect(elem, 'medical')
    }
  },
  methods: {
    exit(item) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'Dimetti',
        subtitle: 'Sei sicuro di voler dimettere il cliente?',
        background: false,
        confirm: () => {
          axios
            .post(
              '/' +
                localStorage.getItem('clinic') +
                '/waiting-room/' +
                item.id +
                '/move',
              {
                type: 'exit'
              }
            )
            .then((res) => {
              if (res) {
                this.$store.dispatch('commonComponents/openNotificationModal', {
                  message: 'Cliente dimesso con successo'
                })
                // Emit per aggiornare le tabelle al componente SalaAttesa
                this.$emit('update')
              }
            })
        },
        decline: () => {
          return false
        }
      })
    },
    backToWaiting(item) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: "Sposta in sala d'attesa",
        subtitle: "Sei sicuro di spostare il cliente in sala d'attesa?",
        background: false,
        confirm: () => {
          axios
            .post(
              '/' +
                localStorage.getItem('clinic') +
                '/waiting-room/' +
                item.id +
                '/move',
              {
                type: 'waiting'
              }
            )
            .then((res) => {
              if (res) {
                this.$store.dispatch('commonComponents/openNotificationModal', {
                  message: "Cliente spostato in sala d'attesa con successo"
                })

                // Emit per aggiornare le tabelle al componente SalaAttesa
                this.$emit('update')
              }
            })
        },
        decline: () => {
          return false
        }
      })
    },
    manageRoom(item) {
      this.chosen_id = item.id
      this.chosen_pet = item.pet_id
      this.chosen_room = item.room_id
      this.openAddInClinic = true
    },
    closeModal() {
      this.openAddInClinic = false
      // Emit per aggiornare le tabelle al componente SalaAttesa
      this.$emit('update')
    },
    // Metodo generico per handler filtro select
    filterSelect(elem, field) {
      if (elem) this.active_filters[field] = elem.value
      else this.active_filters[field] = undefined

      this.$emit('filterHandler', toRaw(this.active_filters))
    },
    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value
      this.$emit('filterHandler', toRaw(this.active_filters))
    }
  }
})
</script>
