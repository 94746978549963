const state = () => {
  if (localStorage.menu) {
    return JSON.parse(localStorage.getItem('menu'))
  }
}

// getters
const getters = {
  getMenu: (state) => state.menu
}

// actions
const actions = {
  getMenu: function ({ commit }, payload) {
    commit('getMenu', payload)
  }
}

// mutations
const mutations = {
  getMenu: function (state, payload) {
    localStorage.setItem('menu', payload)
    state.menu = JSON.parse(payload).menu
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
