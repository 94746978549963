<template>
  <!-- Main modal -->
  <div
    id="defaultModal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none s"
  >
    <div
      class="relative top-12 p-5 mx-auto overflow-scroll scrollbar-none border w-3/5 shadow-lg rounded-md bg-white"
    >
      <button type="button" class="closing-btn" @click="$emit('close')">
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <!-- Modal content -->
      <div class="flex flex-col content-end overflow-y-auto">
        <!-- Modal header -->
        <div class="">
          <h3 class="modal-title mt-5">
            {{ title }}
          </h3>
        </div>
        <!-- Modal body -->
        <div class="p-6 space-y-6">
          <div class="flex flex-col content-start mb-1">
            <!-- Inizio layout Grid -->
            <div class="grid grid-cols-12 items-center">
              <div class="h-50 col-span-12 mt-5 flex flex-col">
                <QuillEditor
                  ref="input3"
                  v-model:content="message"
                  theme="snow"
                  contentType="html"
                  class="form-control h-40"
                  @textChange="conta"
                />
                <!-- <p class="ml-auto text-sm opacity-75">
                  {{ contatore }}<strong>/200</strong>
                </p> -->
              </div>

              <!-- Fine  inserimento dati -->
            </div>
            <!-- Fine layout Grid -->
          </div>
        </div>
        <!-- Modal footer -->
        <div class="flex items-center justify-end gap-3 px-6">
          <button
            ref="input4"
            type="button"
            class="orange-btn"
            @click="confirm"
          >
            <PlusCircleIcon class="h-5 w-5 mr-2" />
            {{ annotation?.to_modify ? 'Modifica' : 'Aggiungi' }}
          </button>
          <button type="button" class="gray-btn" @click="$emit('close')">
            <span class="h-5"></span>
            Annulla
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'

export default defineComponent({
  props: ['type', 'modify', 'annotation'],
  emits: ['close', 'confirm', 'addNote', 'modifyNote'],
  setup(props, { emit }) {
    const message = ref('')
    onMounted(() => {
      if (props.annotation && props.annotation && props.annotation.note_data) {
        message.value = props.annotation.note_data.note
      }
    })

    const confirm = () => {
      if (message.value) {
        console.log(message.value)
        if (props.annotation && props.annotation.to_modify) {
          console.log('check annotazioni: ', props.annotation.to_modify)
          emit(
            'modifyNote',
            props.type,
            props?.annotation?.note_data?.id,
            message.value
          )
        } else {
          emit('addNote', props.type, message.value)
        }
      }
      emit('close')
    }

    const handleChange = (val) => {
      console.log(val)
    }

    const title = computed(() => {
      return props.type == 'communications'
        ? 'Nuova Comunicazione'
        : props.type == 'annotations'
        ? 'Nuova Annotazione'
        : props.type == 'events'
        ? 'Nuovo Evento Veterinario'
        : 'Nuovo Accordo per pagamento'
    })

    // FINE Gestione Ricerca Propietario/Paziente

    return {
      title,
      message,
      confirm,
      handleChange
    }
  },
  methods: {
    conta: function () {
      this.contatore = this.$refs.input3.getText().toString().length - 1
    }
  }
})
</script>
