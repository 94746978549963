<!--Cell-badge component created from tailwind ui component with changeable color -->
<template>
  <td class="px-6 py-4 whitespace-nowrap">
    <span
      :class="{
        'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800':
          backColor == true,
        'px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-200 text-green-800':
          backColor == false
      }"
    >
      {{ elementi }}
    </span>
  </td>
</template>

<script>
export default {
  /* eslint-disable eol-last */
  data() {
    return {}
  },
  props: {
    elementi: Object,
    backColor: Boolean
  },
  created() {}
}
</script>

<style></style>
