<template>
  <div>
    <div
      id="cassa-modal"
      class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50"
    >
      <div
        class="relative top-16 mt-1 mx-auto px-5 pt-3 pb-7 border shadow-lg rounded-md bg-white"
        :class="[isSetup == false ? 'w-3/5' : 'w-2/5']"
      >
        <button type="button" class="closing-btn" @click="this.$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <div class="flex flex-col">
          <div class="w-full">
            <h2 class="modal-title mt-8" id="modal-title">
              Cassa Previdenziale
            </h2>
            <!-- <div v-if="isSetup == false" class=""> -->
            <div class="py-2 flex flex-col items-center">
              <p class="flex-1 text-gray-500 p-3">
                Attraverso questa sezione puoi impostare i dati relativi alla
                cassa professionale di appartenenza da applicare nella fattura
                che stai creando
              </p>
            </div>
            <div
              class="flex flex-col md:flex-row my-2 text-center items-center gap-2"
            >
              <input
                v-model="checkCassa"
                type="checkbox"
                class="form-check-switch"
              />

              <label v-if="checkCassa == false" for="" class="form-label mt-2"
                >Non attivo</label
              >

              <label v-else for="" class="form-label mt-2">Attivo</label>
            </div>
          </div>

          <!-- BEGIN: CALCOLO CASSA PREVIDENZIALE SU SERVIZI -->
          <div class="px-4 py-3" v-if="modifica && checkCassa">
            <div class="flex flex-col lg:flex-row my-2">
              <div class="flex-1 text-sm">
                <div class="px-3">
                  <label for="" class="form-label">Tipo cassa</label>
                  <select class="custom-input mt-2" v-model="cassaSelected">
                    <option v-for="n in cassaOptions" :value="n" :key="n.code">
                      {{ n.code }} - {{ n.description }}
                    </option>
                  </select>
                </div>
                <div class="flex flex-col lg:flex-row my-2 gap-1 items-end">
                  <div class="w-full lg:flex-1 flex-col px-3">
                    <label class="form-label">Aliquota cassa</label>
                    <div class="relative">
                      <input
                        class="custom-input mt-2 px-8"
                        type="number"
                        @change="calcCassa"
                        v-model="cassaAliq"
                        min="0"
                        step="0.01"
                        style="text-align: right"
                      />
                      <div class="ml-1 font-semibold absolute top-3 right-5">
                        %
                      </div>
                    </div>
                  </div>
                  <div class="w-full lg:flex-1 flex-col px-3">
                    <label class="form-label">su</label>

                    <!-- <p class="pt-4">{{ cassaPerc }}</p> -->
                    <input
                      type="number"
                      class="custom-input mt-2 px-8"
                      @change="calcCassa"
                      v-model="cassaPerc"
                      min="0"
                      max="100"
                      step="0.01"
                      style="text-align: right"
                    />
                  </div>
                  <div class="w-full lg:flex-1 flex-col px-3">
                    <label for="" class="form-label">Codice IVA</label>
                    <select v-model="cassaIVA" class="custom-input mt-2">
                      <option v-for="n in listVAT" :value="n" :key="n.id">
                        {{ n.descrizione }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
              <div v-if="isSetup == false" class="flex-1">
                <DropServices
                  :services="this.services"
                  @taxable="updateTaxable"
                  :oldChecks="this.oldChecks"
                />
                <div class="text-center my-3">
                  Totale imponibile {{ this.taxable }} €
                </div>
              </div>
            </div>
            <!-- END: CALCOLO CASSA PREVIDENZIALE SU SERVIZI -->
            <!-- BEGIN: RIEPILOGO CASSA -->
            <div v-if="isSetup == false" class="">
              <div class="col-span-12 flex-1">
                <div class="text-center">
                  <h3
                    class="text-xl font-bold text-center bg-theme-2 rounded-md w-full p-1 mt-2"
                  >
                    Riepilogo
                  </h3>
                  <div class="flex items-center gap-3 my-2 px-3">
                    <div class="flex-1 shadow-md rounded-md my-2 py-2">
                      <h4 class="text-lg font-bold">Imponibile cassa</h4>

                      <div class="flex-1 py-1">{{ this.taxable }} €</div>
                    </div>
                    <div class="flex-1 shadow-md rounded-md my-2 py-2">
                      <h4 class="text-lg font-bold">Importo cassa</h4>

                      <div class="flex">
                        <div class="flex-1 py-1">{{ this.cassaValue }} €</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- END: RIEPILOGO CASSA -->
            <div
              v-if="false"
              class="flex items-baseline w-full justify-end my-2 gap-2 px-3"
            >
              <input
                class="orange-check"
                type="checkbox"
                data-toggle="toggle"
                data-onstyle="primary"
                data-offstyle="secondary"
              />
              <p class="font-medium">Cassa soggetta a ritenuta</p>
            </div>
          </div>

          <div
            v-if="isSetup == false"
            class="mt-2 flex items-center justify-end pr-4 gap-3"
          >
            <button
              v-if="changeCassaFattura !== null"
              id="dialogbox-delete-button"
              class="orange-btn"
              type="button"
              @click="() => handleChangeCassaFattura()"
            >
              <SaveIcon class="w-5 h-5 mr-2 justify-center" />
              Salva
            </button>
            <button
              v-else
              id="dialogbox-delete-button"
              class="orange-btn"
              type="button"
              @click="addCassa"
            >
              <SaveIcon class="w-5 h-5 mr-2 justify-center" />
              Salva
            </button>

            <button
              autofocus
              @click="this.$emit('close')"
              id="dialogbox-anulla-button"
              type="button"
              class="gray-btn"
            >
              Annulla
            </button>
          </div>

          <!-- BEGIN: footer setup clinica -->
          <div class="" v-if="isSetup == true">
            <div class="mt-2 flex items-center justify-end pr-4 gap-3">
              <button
                id="dialogbox-delete-button"
                type="button"
                @click="saveDefaultCassa"
                class="orange-btn"
              >
                <SaveIcon class="w-5 h-5 mr-2 justify-center" />
                Salva
              </button>

              <button
                autofocus
                @click="this.$emit('close')"
                id="dialogbox-anulla-button"
                type="button"
                class="gray-btn"
              >
                Annulla
              </button>
            </div>
          </div>
          <!-- END: footer setup clinica -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../gateway/backend-api'
import DropServices from '../../components/drop-services/DropServices.vue'
export default {
  // Eventi personalizzati da richiamare nel componente FatturazioneAttiva
  components: {
    DropServices
  },
  event: ['close', 'changeCassaFattura'],
  props: {
    services: [],
    cassaVecchia: Object,
    oldChecks: [],
    isComingFromSetup: Object,
    cassaFattura: Boolean
  },

  data() {
    return {
      checkCassa: null,
      modifica: false,
      cassaSelected: [],
      cassaOptions: [],
      taxable: '',
      cassaValue: '',
      cassaAliq: '',
      cassaPerc: 100,
      cassaIVA: [],
      checks: [],
      listVAT: [],
      isSetup: false
    }
  },
  watch: {
    services: {
      deep: true
    }
  },
  created() {
    this.getListCassaPrev()

    if (this.cassaVecchia !== null) {
      if (this.cassaVecchia.value > 0) {
        this.modifica = true
        this.checkCassa = true
        this.cassaSelected = this.cassaVecchia.type
        this.cassaValue = this.cassaVecchia.value
        this.cassaPerc = this.cassaVecchia.onPerc
        this.cassaAliq = this.cassaVecchia.aliquota
        this.cassaIVA = this.cassaVecchia.VAT
      }
    }
    this.getVAT()

    if (this.isComingFromSetup !== null && this.isComingFromSetup === true) {
      this.isSetup = true
      this.modifica = true
      this.checkCassa = true
      this.cassaSelected = this.cassaVecchia.type
      this.cassaValue = this.cassaVecchia.value
      this.cassaPerc =
        this.cassaVecchia.onPerc == undefined ? 100 : this.cassaVecchia.onPerc
      this.cassaAliq = this.cassaVecchia.aliquota
      this.cassaIVA = this.cassaVecchia.VAT
    }

    if (this.cassaFattura) {
      this.checkCassa = this.cassaFattura
    }
  },

  methods: {
    saveDefaultCassa: function () {
      if (
        this.modifica == false &&
        this.checkCassa == true &&
        (this.cassaSelected == '' ||
          this.cassaSelected == null ||
          this.cassaAliq == '' ||
          this.cassaAliq == null ||
          this.cassaPerc == '' ||
          this.cassaPerc == null ||
          this.cassaIVA == '' ||
          this.cassaIVA == null)
      ) {
        alert('Compila tutti i campi')
      } else {
        var cassa = {
          type: this.cassaSelected,
          aliquota: this.cassaAliq,
          onPerc: this.cassaPerc,
          VAT: this.cassaIVA
        }
        this.$emit('saveDefault', cassa)
      }
    },

    modifCassa() {
      this.modifica = !this.modifica
    },
    getListCassaPrev() {
      axios
        .get(
          '/' + localStorage.getItem('clinic') + '/bills/cassa-previdenziale'
        )
        .then((res) => {
          this.cassaOptions = res.data
        })
    },
    updateTaxable(value, checks) {
      this.taxable = value
      this.checks = checks
      this.calcCassa()
    },
    calcCassa() {
      this.cassaValue =
        (((this.taxable / 100) * this.cassaPerc) / 100) * this.cassaAliq
      this.cassaValue =
        Math.round((this.cassaValue + Number.EPSILON) * 100) / 100
    },
    addCassa: function () {
      if (this.cassaValue > 0) {
        let cassa
        if (this.checkCassa) {
          cassa = {
            type: this.cassaSelected,
            aliquota: this.cassaAliq,
            onPerc: this.cassaPerc,
            VAT: this.cassaIVA,
            value: this.cassaValue
          }
        } else {
          cassa = {
            type: '',
            aliquota: '',
            onPerc: 100,
            VAT: '',
            value: 0
          }
        }
        this.$emit('addCassa', cassa, this.checks)
      } else {
        alert('Devi impostare i valori')
      }
    },
    // Funzione che ritorna le scelte selezionabili come IVA
    getVAT() {
      axios
        .get('/' + localStorage.getItem('clinic') + '/bills/IVA')
        .then((res) => {
          this.listVAT = res.data
        })
    },
    handleChangeCassaFattura() {
      this.$emit('changeCassaFattura', this.checkCassa)
      this.$emit('close')
    }
  }
}
</script>

<style></style>
