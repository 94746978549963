import { createRouter, createWebHistory } from 'vue-router'
import SideMenu from '../layouts/side-menu/Main.vue'
import Dashboard from '../views/dashboard/Dashboard.vue'
import LastUpdate from '../views/last-update/LastUpdate.vue'
/* profilo */
import Profile from '../views/profile/Profile.vue'
/* calendario */
import Calendar from '../views/calendar/Calendar.vue'
/* sala d'attesa */
import SalaAttesa from '../views/sala-attesa/SalaAttesa.vue'
/* segreteria */
import ChiusuraCassa from '../views/segreteria/ChiusuraCassa.vue'
import FatturazioneAttiva from '../views/segreteria/FatturazioneAttiva.vue'
import Preventivi from '../views/segreteria/Preventivi.vue'
// import Preventivi from '../views/segreteria/Preventivi.vue'
import Insoluti from '../views/segreteria/Insoluti.vue'
import ContoAperto from '../views/segreteria/ContoAperto.vue'
import AnagraficaClienti from '../views/anagrafica/AnagraficaClienti.vue'
import AnagraficaPazienti from '../views/anagrafica/AnagraficaPazienti.vue'
import AnagraficaReferentiEsterni from '../views/anagrafica/AnagraficaReferentiEsterni.vue'
import RegistroSalaAttesa from '../views/segreteria/RegistroSalaAttesa.vue'
/* tabulator */

/* area medica */
import CartelleCliniche from '../views/area-medica/CartelleCliniche.vue'
import DocumentiClinici from '../views/area-medica/DocumentiClinici.vue'
import RegistroRicette from '../views/area-medica/RegistroRicette.vue'
import RegistroVaccinazioni from '../views/area-medica/RegistroVaccinazioni.vue'
/* degenza */
import Degenza from '../views/degenza/Degenza.vue'
/* magazzino */
import VistaCategorie from '../views/magazzino/VistaCategorie.vue'
import VistaReparti from '../views/magazzino/VistaReparti.vue'
import VistaMovimentazioni from '../views/magazzino/VistaMovimentazioni.vue'
import ArticoliScadenza from '../views/magazzino/ArticoliScadenza.vue'
import ArticoliSottoSoglia from '../views/magazzino/ArticoliSottoSoglia.vue'
import Fornitori from '../views/anagrafica/Fornitori.vue'
/* gestione clienti */
import RegistroComunicazioni from '../views/gestione-clienti/RegistroComunicazioni.vue'
import TemplateComunicazioni from '../views/gestione-clienti/TemplateComunicazioni.vue'
/* amministrazione e statistiche */
import ReportEconomici from '../views/amministrazione-statistiche/ReportEconomici.vue'
import Tariffs from '../views/amministrazione-statistiche/Tariffs.vue'
import PerformanceLog from '../views/amministrazione-statistiche/PerformanceLog'
import FatturazionePassiva from '../views/amministrazione-statistiche/FatturazionePassiva.vue'
import EmailInviate from '../views/../views/amministrazione-statistiche/EmailInviate.vue'
import PrimaNotaCassa from '../views/amministrazione-statistiche/PrimaNotaCassa.vue'
/* setup clinica */
import DatiClinica from '../views/setup-clinica/DatiClinica.vue'
import DatiClinicaAruba from '../views/setup-clinica/DatiClinicaAruba.vue'
import Staff from '../views/setup-clinica/staff-sale-clinica/Staff.vue'
import SaleClinica from '../views/setup-clinica/staff-sale-clinica/SaleClinica.vue'
import FatturazioneAttivaSet from '../views/setup-clinica/fatturazione/FatturazioneAttivaSet.vue'
import FatturazionePassivaSet from '../views/setup-clinica/fatturazione/FatturazionePassivaSet.vue'
import DetraibilitaPrestazioniTS from '../views/setup-clinica/fatturazione/DetraibilitaPrestazioniTS.vue'
import SpecieRazza from '../views/setup-clinica/pet/SpecieRazza.vue'
import ColorazioneManto from '../views/setup-clinica/pet/ColorazioneManto.vue'
import CasiClinici from '../views/setup-clinica/area-medica/CasiClinici.vue'
import DocumentiPersonalizzati from '../views/setup-clinica/area-medica/DocumentiPersonalizzati.vue'
import ValoriEsamiLaboratorio from '../views/setup-clinica/area-medica/ValoriEsamiLaboratorio.vue'
import ConfigurazioneREV from '../views/setup-clinica/area-medica/ConfigurazioneREV.vue'
import Vaccinazioni from '../views/setup-clinica/pet/Vaccinazioni.vue'
/*assistenza */
import Teleassistenza from '../views/assistenza/Teleassistenza.vue'
import VetsLab from '../views/vetslab/VetsLab.vue'
import Statistiche from '../components/statistiche/Statistiche.vue'
import StatistichePazienti from '../components/statistiche/StatistichePazienti.vue'
import StatisticheClienti from '../components/statistiche/StatisticheClienti.vue'
import FattureStaff from '../components/statistiche/FattureStaff.vue'
import ScegliClinica from '../components/scegli-clinica/ScegliClinica.vue'
import Permessi from '../components/permessi/Permessi.vue'

/* Carosello */
import Carosello from '../components/carousel/Carosello.vue'

import store from '../store'
import { startTimer, stopTimer } from '../global-timer'
import Login from '../views/login/Main.vue'
import forgotPassword from '../views/forgot-password/Main.vue'
import ballantipage from '../views/ballantipage/Main.vue'
import ballantinotfound from '../views/ballantinotfound/Main.vue'
import passwordChange from '../views/password-change/Main.vue'
import Register from '../views/register/Main.vue'
import ErrorPage from '../views/error-page/Main.vue'
import Role_3 from '../components/roles/role_3.vue'

let callsDone = false

const routes = [
  {
    path: '/',
    component: SideMenu,
    children: [
      // Dashboard
      {
        path: '/',
        name: 'side-menu-dashboard',
        component: Dashboard,
        meta: {
          requiresAuth: true
        }
      },
      // Ultimo Aggiornamento
      {
        path: '/last-update',
        name: 'last-update',
        component: LastUpdate,
        meta: {
          requiresAuth: true
        }
      },
      // Profile
      {
        path: '/profile',
        name: 'side-menu-profile',
        component: Profile,
        meta: {
          requiresAuth: true
        }
      },
      // Calendar
      {
        path: '/calendar',
        name: 'side-menu-calendar',
        component: Calendar,
        meta: {
          requiresAuth: true
        }
      },
      // Waiting room
      {
        path: "/sala-d'attesa",
        name: 'side-menu-sala-attesa',
        component: SalaAttesa,
        meta: {
          requiresAuth: true
        }
      },
      // Secretariat
      {
        path: '/segreteria/chiusura-di-cassa',
        name: 'side-menu-segreteria-chiusura-cassa',
        component: ChiusuraCassa,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/segreteria/fatturazione-attiva',
        name: 'side-menu-segreteria-fatturazione-attiva',
        component: FatturazioneAttiva,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/segreteria/preventivi',
        name: 'side-menu-segreteria-preventivi',
        component: Preventivi,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/segreteria/insoluti',
        name: 'side-menu-segreteria-insoluti',
        component: Insoluti,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/segreteria/conto-aperto',
        name: 'side-menu-segreteria-conto-aperto',
        component: ContoAperto,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/segreteria/registro-sala-attesa',
        name: 'side-menu-segreteria-registro-sala-attesa',
        component: RegistroSalaAttesa,
        meta: {
          requiresAuth: true
        }
      },
      // Registry
      {
        path: '/anagrafica/anagrafica-clienti',
        name: 'side-menu-anagrafica-anagrafica-clienti',
        component: AnagraficaClienti,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/anagrafica/anagrafica-pazienti',
        name: 'side-menu-anagrafica-anagrafica-pazienti',
        component: AnagraficaPazienti,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/anagrafica/anagrafica-referenti-esterni',
        name: 'side-menu-anagrafica-anagrafica-referenti-esterni',
        component: AnagraficaReferentiEsterni,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/anagrafica/fornitori',
        name: 'side-menu-anagrafica-registro-fornitori',
        component: Fornitori,
        meta: {
          requiresAuth: true
        }
      },
      // Medical area
      {
        path: '/area-medica/cartelle-cliniche',
        name: 'side-menu-area-medica-cartelle-cliniche',
        component: CartelleCliniche,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/area-medica/documenti-clinici',
        name: 'side-menu-area-medica-documenti-clinici',
        component: DocumentiClinici,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/area-medica/registro-ricette',
        name: 'side-menu-area-medica-registro-ricette',
        component: RegistroRicette,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/area-medica/registro-vaccinazioni',
        name: 'side-menu-area-medica-registro-vaccinazioni',
        component: RegistroVaccinazioni,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/area-medica/last-updates',
        name: 'side-menu-setup-clinica-last-updates',
        component: LastUpdate,
        meta: {
          requiresAuth: true
        }
      },
      // Hospitalization
      {
        path: '/degenza',
        name: 'side-menu-degenza',
        component: Degenza,
        meta: {
          requiresAuth: true
        }
      },
      // Warehouse
      {
        path: '/magazzino/articoli',
        name: 'side-menu-magazzino-vista-categorie',
        component: VistaCategorie,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/magazzino/vista-repart',
        name: 'side-menu-magazzino-vista-reparti',
        component: VistaReparti,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/magazzino/vista-movimentazioni',
        name: 'side-menu-magazzino-vista-movimentazioni',
        component: VistaMovimentazioni,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/magazzino/articoli-in-scadenza',
        name: 'side-menu-magazzino-articoli-scadenza',
        component: ArticoliScadenza,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/magazzino/articoli-sotto-soglia',
        name: 'side-menu-magazzino-articoli-sotto-soglia',
        component: ArticoliSottoSoglia,
        meta: {
          requiresAuth: true
        }
      },
      // Customer management
      {
        path: '/gestione-clienti/registro-comunicazioni',
        name: 'side-menu-gestione-clienti-registro-comunicazioni',
        component: RegistroComunicazioni,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/gestione-clienti/template-comunicazioni',
        name: 'side-menu-gestione-clienti-template-comunicazioni',
        component: TemplateComunicazioni,
        meta: {
          requiresAuth: true
        }
      },
      // Administration
      {
        path: '/amministrazione-statistiche/report-economici',
        name: 'side-menu-amministrazione-statistiche-report-economici',
        component: ReportEconomici,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/amministrazione-statistiche/tariffs',
        name: 'side-menu-amministrazione-statistiche-tariffario',
        component: Tariffs,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/amministrazione-statistiche/registro-prestazioni',
        name: 'side-menu-amministrazione-statistiche-registro-prestazioni',
        component: PerformanceLog,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/amministrazione-statistiche/fatturazione-passiva',
        name: 'side-menu-amministrazione-statistiche-fatturazione-passiva',
        component: FatturazionePassiva,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/amministrazione-statistiche/email-inviate',
        name: 'side-menu-amministrazione-statistiche-email-inviate',
        component: EmailInviate,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/amministrazione-statistiche/prima-nota-cassa',
        name: 'side-menu-amministrazione-statistiche-prima-nota-cassa',
        component: PrimaNotaCassa,
        meta: {
          requiresAuth: true
        }
      },
      // Clinic setup
      {
        path: '/setup-clinica/dati-clinica',
        name: 'side-menu-setup-clinica-dati-clinica',
        component: DatiClinica,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/setup-clinica/dati-clinica-aruba',
        name: 'side-menu-setup-clinica-dati-clinica-dati-clinica-aruba',
        component: DatiClinicaAruba,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/setup-clinica/staff-sale-clinica/staff',
        name: 'side-menu-setup-clinica-staff-sale-cliniche-staff',
        component: Staff,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/setup-clinica/staff-sale-clinica/sale-clinica',
        name: 'side-menu-setup-clinica-staff-sale-cliniche-sale-clinica',
        component: SaleClinica,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/setup-clinica/fatturazione/fatturazione-attiva',
        name: 'side-menu-setup-clinica-fatturazione-attiva',
        component: FatturazioneAttivaSet,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/setup-clinica/fatturazione/fatturazione-passiva',
        name: 'side-menu-setup-clinica-fatturazione-passiva',
        component: FatturazionePassivaSet,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/setup-clinica/fatturazione/detraibilità-prestazioni-ts',
        name: 'side-menu-setup-clinica-fatturazione-detraibilità-prestazioni-ts',
        component: DetraibilitaPrestazioniTS,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/setup-clinica/pet/specie-razza',
        name: 'side-menu-setup-clinica-pet-specie-razza',
        component: SpecieRazza,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/setup-clinica/pet/vaccinazioni',
        name: 'side-menu-setup-clinica-pet-vaccinazioni',
        component: Vaccinazioni,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/setup-clinica/pet/colorazione-manto',
        name: 'side-menu-setup-clinica-pet-colorazione-manto',
        component: ColorazioneManto,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/setup-clinica/area-medica/casi-clinici',
        name: 'side-menu-setup-clinica-area-medica-casi-clinici',
        component: CasiClinici,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/setup-clinica/area-medica/documenti-personalizzati',
        name: 'side-menu-setup-clinica-area-medica-documenti-personalizzati',
        component: DocumentiPersonalizzati,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/setup-clinica/area-medica/valori-laboratorio',
        name: 'side-menu-setup-clinica-area-medica-valori-laboratorio',
        component: ValoriEsamiLaboratorio,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/setup-clinica/area-medica/rev',
        name: 'side-menu-setup-clinica-area-medica-rev',
        component: ConfigurazioneREV,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/telemedicina',
        name: 'side-menu-teleassistenza',
        component: Teleassistenza,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/vetslab',
        name: 'side-menu-vetslab',
        component: VetsLab,
        meta: {
          requiresAuth: true
        }
      },
      // Statistiche
      {
        path: '/stats-struttura',
        name: 'side-menu-statistiche-struttura',
        component: Statistiche,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/stats-clienti',
        name: 'side-menu-statistiche-clienti',
        component: StatisticheClienti,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/stats-staff',
        name: 'side-menu-statistiche-staff',
        component: FattureStaff,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/stats-pazienti',
        name: 'side-menu-statistiche-pazienti',
        component: StatistichePazienti,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/permission',
        name: 'side-menu-setup-clinica-staff-sale-cliniche-permessi',
        component: Permessi,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: '/carosello',
        name: 'side-menu-setup-clinica-dati-clinica-carosello',
        component: Carosello,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },

  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      guest: true
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: forgotPassword
  },
  {
    path: '/password-change',
    name: 'password-change',
    component: passwordChange
  },
  {
    path: '/resetpage',
    name: 'resetpage',
    component: ballantipage
  },
  {
    path: '/error',
    name: 'error',
    component: ballantinotfound
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/error-page',
    name: 'error-page',
    component: ErrorPage
  },
  {
    path: '/:pathMatch(.*)*',
    component: ErrorPage
  },
  {
    path: '/clinical-page',
    name: 'clinical-page',
    component: Role_3,
    meta: {
      requiresAuth: true
    },
    props: {
      pet_id: 1,
      ClinicalPageModal: true
    }
  },
  {
    path: '/scegli-clinica',
    name: 'scegli-clinica',
    props: true,
    component: ScegliClinica
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 }
  }
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isAuthenticated) {
      next()
    }
    next('/login')
  } else next()

  if (to.meta.requiresAuth && callsDone === false) {
    store.dispatch('reusableData/getDefaultPaymentMethods')
    store.dispatch('reusableData/getPaymentMethods')
    store.dispatch('reusableData/getDocumentTypes')
    store.dispatch('reusableData/getInvoiceTypes')
    store.dispatch('reusableData/getInvoiceTypesClinic')
    store.dispatch('reusableData/getInvoiceStatus')
    store.dispatch('reusableData/getListVat')
    store.dispatch('reusableData/getSchedeClinicheTemplate')
    store.dispatch('reusableData/getRefertiTemplate')
    store.dispatch('reusableData/getRefertiSupport')
    store.dispatch('reusableData/getRefertiLab')
    store.dispatch('reusableData/getTotalBell')

    if (store.getters.isAuthenticated && localStorage.getItem('clinic'))
      store.dispatch('reusableData/getClinicaSetup')

    store.dispatch('getUserData').then(() => {
      if (
        store.getters.getUser?.role?.id === 5 &&
        localStorage.getItem('clinic') &&
        from.path !== '/clinical-page'
      ) {
        router.push({ name: 'clinical-page' })
      }
      store.dispatch(
        'reusableData/getPermissionsAction',
        store.getters.getUser.id
      )

      store.dispatch('reusableData/setPrefissoSezionale', {
        prefisso_sezionale: JSON.parse(localStorage.getItem('clinic-suffix'))
      })
      // .then(() => {
      //   console.log(
      //     'permissisons',
      //     store.getters['reusableData/getPermissions']
      //   )
      // })
    })

    startTimer()
    callsDone = true
  }

  if (!to.meta.requiresAuth) {
    callsDone = false
    stopTimer()
  }

  if (to.matched.some((record) => record.meta.guest)) {
    if (store.getters.isAuthenticated) {
      next('/')
      return
    }
    next()
  } else next()
})

export default router
