<template>
  <div v-if="tableConfigClinicDoc && documents" class="paginator-container">
    <div class="flex justify-between">
      <div class="mt-1">
        <span class="flex-row text-md ml-2 font-bold mr-2">Mostra </span>

        <select
          class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
          @change="(event) => changeNumPages(event)"
        >
          <option v-for="show in forPageOption" :key="show" :value="show">
            {{ show }}
          </option>
        </select>
        <span class="text-md font-bold ml-2 mt-1"> elementi</span>
      </div>
    </div>
    <Paginator
      v-if="totalRecords > pagination.forPage"
      :for-page-option="pagination.forPage"
      :total-records="totalRecords"
      @changePagination="(event) => changePagination(event)"
    />
  </div>

  <!-- BEGIN: Data List -->
  <div class="table-container pb-3">
    <table
      v-if="tableConfigClinicDoc && documents"
      class="w-full shadow custom-border rounded-lg table-custom"
    >
      <thead class="thead-custom t-header h-8">
        <tr>
          <th
            v-for="(item, itemKey) in tableConfigClinicDoc"
            :key="itemKey"
            colspan="2"
          >
            {{ item.title }}
          </th>
          <th></th>
        </tr>
      </thead>

      <tbody class="tbody-custom overflow-auto scrollbar_custom">
        <tr>
          <td
            v-for="(elem, index) in tableConfigClinicDoc"
            :key="index"
            class="text-center px-1 py-1"
            colspan="2"
          >
            <select
              v-if="elem.type === 'select'"
              v-model="elem.value"
              class="w-full border rounded-md h-7 text-gray-500"
            >
              <option selected value="">{{ elem.title }}</option>
              <option v-for="(item, i) in elem.option" :key="i" :value="item">
                {{ item }}
              </option>
            </select>
            <input
              v-else
              v-model="elem.value"
              :type="elem.type"
              min="0"
              :name="elem.title"
              class="form-control h-7"
              :class="{
                invisible: elem.type === 'none',
                'p-0': elem.type === 'date'
              }"
              :placeholder="elem.title"
            />
          </td>
          <td></td>
        </tr>
        <tr
          v-for="(doc, docKey) in newDoc"
          :key="docKey"
          class="py-1.5 text-xs font-normal hover:bg-theme-45 overflow-scroll"
          :class="{ 'bg-theme-2': docKey % 2 === 0 }"
        >
          <td
            class="text-center text-sm border-r-2 border-theme-46"
            colspan="2"
          >
            <div class="flex items-center gap-3 pl-5">
              <template v-if="aliasToSave[doc.id]">{{
                $emit('getAliasData', docKey, doc.id)
              }}</template>
              <span v-if="doc.price_added" class="text-theme-41">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  class="fill-current h-5 mr-2"
                >
                  <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                  /></svg
              ></span>
              <span v-else>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  class="fill-current h-5 opacity-0 mr-2"
                >
                  <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"
                  /></svg
              ></span>
              <p class="text-xs text-left">
                {{ doc.description }}
              </p>
            </div>
          </td>
          <td
            class="text-center text-sm border-r-2 border-theme-46"
            colspan="2"
          >
            <input
              v-model="newDoc[docKey]['alias']"
              class="custom-input text-xs"
              type="text"
              :placeholder="doc.description"
              @change="addAlias(doc)"
            />
          </td>
          <td
            class="text-center align-center justify-center text-xs border-r-2 border-theme-46"
          >
            <button
              type="button"
              :class="[
                doc.price_added
                  ? 'orange-btn hover:bg-theme-41 hover:text-white'
                  : 'gray-btn-inverted'
              ]"
              @click="$emit('openPriceModal', doc, docKey)"
            >
              <span v-if="doc.price_added"> Prezzi aggiunti</span>
              <span v-else> Inserisci prezzo</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="documents && documents.length === 0"
      class="w-full flex justify-center mt-2"
    >
      Nessun Risultato
    </div>
  </div>
  <!-- END: Data List -->
</template>

<script>
import { defineComponent, ref, toRef } from 'vue'

import Paginator from '../../components/paginator/Main'

export default defineComponent({
  components: {
    Paginator
  },
  props: [
    'documents',
    'totalRecords',
    'pagination',
    'forPageOption',
    'selectedCategoria',
    'aliasToSave'
  ],
  emits: [
    'removeWeight',
    'openPriceModal',
    'addAlias',
    'getNomenclatore',
    'changePagination',
    'getAliasData',
    'changeNumPages'
  ],
  setup(props, { emit }) {
    // variabili paginazione
    const newDoc = toRef(props, 'documents')
    const tableConfigClinicDoc = ref([
      {
        key: 'name',
        title: 'Nome',
        type: 'string'
      },
      {
        key: 'alias',
        title: 'Alias',
        type: 'string'
      }
    ])

    // Metodo per eliminare un elemento dalla lista

    //  cambio elementi per pagina, e ritorno nella prima pagina
    const changePagination = (value) => {
      emit('changePagination', value)
    }

    // metodo che cambia il numero della pagina
    const changeNumPages = (elem) => {
      emit('changeNumPages', elem.target.value)
    }

    const addAlias = (doc) => {
      emit('addAlias', doc)
    }

    // ----- FINE COMPUTED ----- //

    return {
      changePagination,
      changeNumPages,
      tableConfigClinicDoc,
      newDoc,
      addAlias
    }
  }
})
</script>
