<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black scrollbar-none"
    :class="
      background && background === false ? 'bg-opacity-0' : 'bg-opacity-50'
    "
  >
    <div
      class="w-3/5 sm:w-4/5 relative top-12 p-5 mx-auto overflow-scroll scrollbar-none border shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="flex flex-col content-end overflow-y-auto">
        <div class="w-full flex items-center gap-2 justify-end mt-6">
          <button
            v-if="
              table_reference &&
              id_reference &&
              client &&
              permissions &&
              permissions['Comunicazioni'] &&
              permissions['Comunicazioni'].send_email &&
              permissions['Comunicazioni'].send_email.enabled === true
            "
            type="button"
            class="orange-btn-inverted"
            @click="handleSendMail()"
          >
            MAIL
          </button>
          <button
            v-if="link"
            type="button"
            class="orange-btn-inverted"
            @click="handlePrint()"
          >
            STAMPA
          </button>

          <button
            type="button"
            class="closing-btn"
            @click="this.$emit('close')"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- Modal header -->

        <div class="w-full relative">
          <h3 class="modal-title mt-2">{{ title }}</h3>
          <button
            v-if="!xml && table_reference && id_reference && !signed"
            class="orange-btn m-2"
            @click="handleSignDocument"
          >
            Firma <PenToolIcon class="h-5 w-5" />
          </button>

          <button
            type="button"
            class="absolute top-4 right-5 text-white hover:text-theme-41"
            @click="this.$emit('download')"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="h-5 fill-current"
            >
              <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V274.7l-73.4-73.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l128 128c12.5 12.5 32.8 12.5 45.3 0l128-128c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L288 274.7V32zM64 352c-35.3 0-64 28.7-64 64v32c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V416c0-35.3-28.7-64-64-64H346.5l-45.3 45.3c-25 25-65.5 25-90.5 0L165.5 352H64zM432 456c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z"
              />
            </svg>
          </button>
        </div>

        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div class="px-6 py-3">
          <VuePdfEmbed v-if="link" :source="link" />

          <code-highlight v-if="xml" language="javascript">
            {{ xml }}
          </code-highlight>
          <div v-if="confirmations" class="flex flex-col">
            <div v-for="(c, index) in confirmations" :key="index">
              {{ c.title }}
              {{ c.description }}
              <div v-for="o in c.options" :key="o.value" class="flex">
                <input
                  :id="o.value"
                  type="radio"
                  :value="o.value"
                  :name="'radio-' + index"
                  :checked="o.selected"
                  @change="(event) => c.onChange(event.target.value)"
                />
                <label :for="o.value">{{ o.label }}</label>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal footer -->

        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from 'vue'
import { useStore } from 'vuex'
import axios from '../../gateway/backend-api'
import printJS from 'print-js'
import VuePdfEmbed from 'vue-pdf-embed'
import CodeHighlight from 'vue-code-highlight/src/CodeHighlight.vue'
import 'vue-code-highlight/themes/prism.css'

export default defineComponent({
  components: { VuePdfEmbed, CodeHighlight },
  props: [
    'title',
    'link',
    'background',
    'xml',
    'table_reference',
    'id_reference',
    'signed',
    'client',
    'pdf_date',
    'confirmations'
  ],
  emits: ['close', 'print', 'download', 'sign_done'],
  setup(props) {
    const store = useStore()

    const permissions = computed(() => {
      return store.state.reusableData.permissions
        ? store.state.reusableData.permissions
        : null
    })

    const handleSignDocument = () => {
      store.dispatch('commonComponents/openSignatureModal', {
        confirm: (signature) => {
          if (props.table_reference && props.id_reference) {
            axios
              .post('/' + localStorage.getItem('clinic') + '/signature', {
                table: props.table_reference,
                id: props.id_reference,
                signature: signature
              })
              .then(() => {
                store.dispatch('commonComponents/openNotificationModal', {
                  message: 'Firma salvata'
                })
                store.dispatch('commonComponents/signDoneMethod')
                store.dispatch('commonComponents/closePdfModal')
              })
              .catch((err) => {
                console.log(err)
                if (err?.response?.status && err.response.status === 409) {
                  store.dispatch('commonComponents/openNotificationModal', {
                    message: 'Firma già salvata'
                  })
                }
              })
          }
        },
        decline: () => {
          console.log('Errore')
        }
      })
    }

    const handlePrint = () => {
      printJS(props.link)
    }

    const handleSendMail = () => {
      store.dispatch('commonComponents/openSendMessageModal', {
        data: {
          type: 'mail',
          client: props.client,
          attachements: [
            {
              id: props.id_reference,
              table: props.table_reference,
              name: props.title,
              date: props.pdf_date
            }
          ]
        },
        confirm: (data) => {
          console.log('invio la mail', data)
          let attachements = []
          if (data && data.selectedFile) {
            data.selectedFile.forEach((el) => {
              attachements.push({
                id: typeof el.id === 'string' ? 1 : el.id,
                alternative_id: el.id.toString(),
                type: el.table,
                file_name: el.name
              })
            })
          }
          store.dispatch('commonComponents/showLoadingModalAction')
          axios
            .post('/' + localStorage.getItem('clinic') + '/mailersend/send', {
              customer_id: props.client.id,
              type: 'email',
              email: data.destinatario,
              //text: 'Documento: ' + props.title,
              object: data.titolo,
              cc: data.cc,
              body: data.message,
              email_type: 'comunication',
              attachments: attachements
            })
            .then(() => {
              store.dispatch('commonComponents/openNotificationModal', {
                message: 'Email inviata con successo!'
              })
              store.dispatch('commonComponents/hideLoadingModalAction')
            })
            .catch(() => {
              store.dispatch('commonComponents/openNotificationModal', {
                message: "Errore durante l'invio della mail"
              })
              store.dispatch('commonComponents/hideLoadingModalAction')
            })
        }
      })
      // store.dispatch('commonComponents/openConfirmModal', {
      //   title: 'CONFERMA',
      //   subtitle:
      //     'Sei sicuro di voler inviare il documento per mail a ' +
      //     (props.client.last_name
      //       ? props.client.last_name + ' ' + props.client.first_name
      //       : props.client.ragione_sociale) +
      //     '?',
      //   background: false,
      //   confirm_text: 'Conferma',
      //   decline_text: 'Annulla',
      //   confirm: () => {
      //     axios
      //       .post(
      //         '/' + localStorage.getItem('clinic') + '/mailersend/send_test',
      //         {
      //           customer_id: props.client.id,
      //           type: 'email',
      //           email: 'sabin.antal@training4u.it',
      //           text: 'Documento: ' + props.title,
      //           object: 'oggetto',
      //           email_type: 'comunication',
      //           pdf_id: props.id_reference,
      //           pdf_table: props.table_reference
      //         }
      //       )
      //       .then(() => {})
      //       .catch(() => {})
      //   },
      //   decline: () => {}
      // })
    }

    return { handleSignDocument, handlePrint, handleSendMail, permissions }
  }
})
</script>
