<template>
  <div
    id="causale-modal"
    class=""
    :class="
      comingFromSetup
        ? ''
        : 'fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 '
    "
  >
    <div
      :class="
        comingFromSetup
          ? ''
          : 'relative w-2/5 top-16 mt-1 mx-auto px-5 pt-3 pb-7 border shadow-lg rounded-md bg-white'
      "
    >
      <button
        v-if="!comingFromSetup"
        type="button"
        class="closing-btn"
        @click="$emit('close')"
      >
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>

      <div class="flex flex-col">
        <div v-if="!comingFromSetup" class="w-full">
          <h2 id="modal-title" class="modal-title mt-8">Causale documento</h2>
          <div class="py-2 flex flex-col items-center">
            <p class="flex-1 text-gray-500 p-3">
              Attraverso questa sezione puoi indicare le causali della fattura
              che stai creando.
            </p>

            <div
              v-if="!comingFromSetup"
              class="flex flex-col md:flex-row my-2 text-center items-center gap-2"
            >
              <input
                v-model="checkCausale"
                type="checkbox"
                class="form-check-switch"
                data-toggle="toggle"
                data-onstyle="primary"
                data-offstyle="secondary"
              />

              <label v-if="checkCausale == false" for="" class="form-label mt-2"
                >Non attivo</label
              >
              <label v-else for="" class="form-label mt-2">Attivo</label>

              <div v-if="checkCausale == true" class="flex-1">
                <button
                  v-if="modifica == false"
                  type="button"
                  class="orange-btn ml-3"
                  @click="modifCausale"
                >
                  <PlusIcon class="h-5" /> Aggiungi causale
                </button>
                <button
                  v-else
                  type="button"
                  class="gray-btn"
                  @click="modifCausale"
                >
                  <MinusIcon class="h-5" /> Rimuovi causale
                </button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="modifica && checkCausale" class="px-4 p-3">
          <div class="flex flex-col my-2">
            <label for="" class="form-label">Causale</label>

            <input v-model="textCausale" type="text" class="custom-input" />
          </div>
        </div>

        <!-- BEGIN: Footer per il modale in fatturazione -->
        <div
          v-if="!comingFromSetup"
          class="px-3 flex mt-2 gap-2 items-center justify-end"
        >
          <button
            id="dialogbox-delete-button"
            type="button"
            @click="aggiungiCausale"
            class="orange-btn"
          >
            <SaveIcon class="w-5 h-5 mr-2 justify-center" />
            Salva
          </button>

          <button
            id="dialogbox-annulla-button"
            autofocus
            type="button"
            class="gray-btn"
            @click="$emit('close')"
          >
            Annulla
          </button>
        </div>

        <!-- END: Footer per il modale in fatturazione -->

        <!-- BEGIN: Footer per il modale in setup clinica -->

        <!-- <div > -->
        <div v-else class="px-3 flex mt-2 gap-2 items-center justify-end">
          <button
            id="dialogbox-delete-button"
            type="button"
            @click="salvaCausaleDefault"
            class="orange-btn"
          >
            <SaveIcon class="w-5 h-5 mr-2 justify-center" />
            Salva
          </button>

          <button
            id="dialogbox-annulla-button"
            autofocus
            type="button"
            class="gray-btn"
            @click="$emit('close')"
          >
            Annulla
          </button>
        </div>
        <!-- </div> -->

        <!-- END: Footer per il modale in setup clinica -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  event: ['close'],
  props: {
    comingFromSetup: Object,
    oldCausale: Object
  },
  data() {
    return {
      checkCausale: false,
      textCausale: '',
      modifica: false
    }
  },
  created() {
    if (this.comingFromSetup != null && this.comingFromSetup == true) {
      this.modifica = true
      this.checkCausale = true
      if (this.oldCausale != null && this.oldCausale != '') {
        this.textCausale = this.oldCausale.text
      }
    } else {
      this.checkCausale = true
      this.textCausale = this.oldCausale.text
    }
  },
  methods: {
    modifCausale() {
      this.modifica = !this.modifica
    },
    aggiungiCausale() {
      let causale = {
        text: this.textCausale
      }
      this.$emit('activeCausale', causale)
    },
    salvaCausaleDefault() {
      let causale = {
        text: this.textCausale
      }
      this.$emit('addCausaleSetup', causale)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
