<template>
  <!-- BEGIN: Prescriptions-->
  <div class="pb-3 my-1">
    <div class="w-full">
      <div class="flex justify-between relative top-4">
        <div
          class="flex flex-col items-center px-5 py-3 sm:py-0 border-b border-gray-200 dark:border-dark-5"
        >
          <div
            v-if="
              permissions &&
              permissions['REV - Ricetta Elettonica Veterinaria'] &&
              permissions['REV - Ricetta Elettonica Veterinaria'].store_rev &&
              permissions['REV - Ricetta Elettonica Veterinaria'].store_rev
                .enabled === true
            "
            class="flex w-full justify-end mb-5"
          >
            <button
              type="file"
              class="orange-btn-inverted mr-2"
              @click="$emit('handleNewRecipeModal')"
            >
              <FilePlusIcon class="h-5 w-5 mr-2" /> Ricetta Elettronica
            </button>
            <button
              type="file"
              class="orange-btn-inverted"
              @click="$emit('handleFreeRecipeModal')"
            >
              <FilePlusIcon class="h-5 w-5 mr-2" /> Ricetta Libera
            </button>
          </div>
          <RegistroTable
            v-if="
              revDocs &&
              tableConfig_clinic_doc &&
              permissions &&
              permissions['REV - Ricetta Elettonica Veterinaria'] &&
              permissions['REV - Ricetta Elettonica Veterinaria'].view_rev &&
              permissions['REV - Ricetta Elettonica Veterinaria'].view_rev
                .enabled === true
            "
            :table-config-clinic-doc="tableConfig_clinic_doc"
            :documents="revDocs"
            :selected-patient="true"
            :loading-search-rev="loadingSearchRev"
            :total-records="totalRecords"
            :emit-rev="
              permissions &&
              permissions['REV - Ricetta Elettonica Veterinaria'] &&
              permissions['REV - Ricetta Elettonica Veterinaria'].emit_rev &&
              permissions['REV - Ricetta Elettonica Veterinaria'].emit_rev
                .enabled === true
            "
            :edit-rev="
              permissions &&
              permissions['REV - Ricetta Elettonica Veterinaria'] &&
              permissions['REV - Ricetta Elettonica Veterinaria'].edit_rev &&
              permissions['REV - Ricetta Elettonica Veterinaria'].edit_rev
                .enabled === true
            "
            @duplicaRev="
              (elem, filter, pagination) => duplicaRev(elem, filter, pagination)
            "
            @handleLoadRev="
              (elem, pagination) => {
                $emit('handleLoadRev', elem, pagination)
              }
            "
            @handleSendMessage="
              (elem) => {
                $emit('handleSendMessage', elem)
              }
            "
            @handleSavePdfRev="
              (elem) => {
                $emit('handleSavePdfRev', elem)
              }
            "
            @handleDeleteRev="
              (elem) => {
                $emit('handleDeleteRev', elem)
              }
            "
            @edit="
              (elem) => {
                $emit('handleEditRev', elem)
              }
            "
          />
          <div v-else>
            <p>I permessi per visualizzare le ricette non sono abilitati</p>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full h-fit content-center items-center justify-center">
      <RotateCwIcon
        v-if="!tableConfig_clinic_doc || !revDocs"
        class="animate-spin h-full w-5 mr-3 scale-150 pb-5"
      />
    </div>
  </div>
  <!-- END: Prescriptions-->
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import axios from '../../gateway/backend-api'
import RegistroTable from './../../views/area-medica/RegistroTable.vue'

export default defineComponent({
  name: 'PetPrescription',
  components: {
    RegistroTable
  },
  props: {
    petDetails: {
      type: [Object],
      default: function () {
        return {}
      }
    },
    revDocs: {
      type: [Array],
      default: function () {
        return []
      }
    },
    totalRecords: {
      type: [Number],
      default: function () {
        return 0
      }
    }
  },
  emits: [
    'handleSendMessage',
    'handleNewRecipeModal',
    'handleSavePdfRev',
    'handleLoadRev',
    'handleDeleteRev',
    'handleFreeRecipeModal',
    'handleEditRev'
  ],
  data() {
    return {
      tableConfig_clinic_doc: null,
      messageModal: false,
      loadingSearchRev: false
    }
  },
  computed: {
    ...mapGetters({
      petsDetails: 'anagraficaPazienti/getPetsDetails',
      permissions: 'reusableData/getPermissions'
    })
  },
  mounted() {
    axios
      .get('/registri_table_config') // scarico la configurazione della tabella
      .then((resp) => {
        const temp = []
        for (var elem of resp.data) {
          temp.push({ ...elem, value: '' }) // ai dati ricevuti aggiungo un campo per il valore del filtro che sarà inserito
        }
        this.tableConfig_clinic_doc = temp
      })

    this.$emit('handleLoadRev')
  },
  methods: {
    duplicaRev(elem, filter, pagination) {
      this.loadingSearchRev = true

      axios
        .post('/' + localStorage.getItem('clinic') + '/rev/duplicaRev', {
          rev_id: elem.id
        })

        .then(() => {
          this.$emit('handleLoadRev', filter, pagination)
        })
        .catch(() => {
          this.loadingSearchRev = false
        })
    }
  }
})
</script>
