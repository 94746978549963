<template>
  <NotificationComponent />
  <!--BEGIN animals list-->
  <div
    class="my-2 mx-3 bg-white px-2 py-6 rounded-md shadow-md border border-theme-44 border-opacity-30"
  >
    <h4 v-if="viewDeadAnimal" class="details-subtitle">Animali Deceduti</h4>
    <h4 v-if="!viewDeadAnimal" class="details-subtitle">Animali Correnti</h4>
    <div class="flex items-center justify-end gap-3">
      <button class="orange-btn px-3 tooltipbutton" @click="handleShowNewPet">
        <span class="tooltipbuttontext" style="margin-left: 3.5rem"
          >Aggiungi Pet</span
        >
        <svg viewBox="0 0 30 32" class="w-4 fill-current">
          <path
            d="M13.335 24.283c0 0.12 0.009 0.22 0.016 0.288 0.008 0.099 0.022 0.197 0.038 0.293-0.022 0.003-0.046 0.006-0.068 0.009-0.833 0.116-1.665 0.32-2.502 0.612-1.106 0.386-2.279 0.609-3.439 0.801-2.474 0.409-4.097-1.226-3.522-3.661 0.302-1.281 0.873-2.52 1.461-3.71 0.342-0.689 0.722-1.346 1.143-1.964-0.008-0.002-0.014-0.005-0.022-0.006 1.158-1.689 2.617-3.135 4.448-4.268 0.425-0.263 0.851-0.488 1.278-0.674 0.039-0.017 0.077-0.035 0.116-0.050 0.005-0.003 0.009-0.005 0.014-0.006 0.877-0.376 1.742-0.56 2.598-0.571h0.197c0.856 0.011 1.722 0.195 2.598 0.571 0.005 0.002 0.009 0.003 0.014 0.006 0.039 0.016 0.077 0.033 0.116 0.050 0.427 0.186 0.853 0.411 1.278 0.674 1.206 0.748 2.251 1.629 3.154 2.628-0.253 0.005-0.508 0.041-0.768 0.109-1.478 0.376-2.51 1.706-2.51 3.231v2.296h-2.296c-1.843 0-3.341 1.498-3.341 3.341z"
          ></path>
          <path
            d="M8.831 8.903c1.037 0.559 2.090 0.694 3.116-0.016 1.19-0.823 1.561-2.041 1.585-3.587-0.085-1.922-0.875-3.636-2.696-4.778-1.612-1.012-3.371-0.518-4.242 1.174-1.213 2.362-0.105 5.948 2.235 7.207z"
          ></path>
          <path
            d="M18.355 9.242c1.305 0.379 2.367-0.165 3.272-1.037 1.207-1.163 1.769-2.628 1.827-4.424-0.020-0.209-0.031-0.551-0.087-0.886-0.42-2.58-2.797-3.655-4.921-2.232-1.744 1.168-2.794 3.892-2.304 5.967 0.294 1.25 0.916 2.233 2.214 2.611z"
          ></path>
          <path
            d="M6.437 12.248c-0.049-2.084-1.472-4.026-3.344-4.563-1.371-0.393-2.611 0.305-2.97 1.673-0.535 2.041 0.732 4.552 2.709 5.364 1.944 0.798 3.655-0.376 3.606-2.474z"
          ></path>
          <path
            d="M27.247 14.691c1.692-0.73 2.96-2.898 2.764-4.72-0.219-2.015-1.851-2.946-3.683-2.063-1.772 0.855-2.545 2.384-2.743 3.913-0.105 2.531 1.656 3.738 3.662 2.871z"
          ></path>
          <path
            d="M28.075 22.783h-1.821s0 0 0 0h-1.522v-3.344c0-0.061-0.003-0.121-0.009-0.183 0-0.014-0.003-0.028-0.005-0.043s-0.003-0.028-0.005-0.043c0 0-0.002-0.003-0.003-0.005-0.060-0.433-0.26-0.818-0.551-1.113-0.077-0.076-0.161-0.145-0.249-0.206 0 0 0 0 0 0s-0.003 0-0.003-0.002c-0.031-0.022-0.065-0.044-0.098-0.065-0.016-0.009-0.031-0.019-0.046-0.028-0.030-0.017-0.060-0.035-0.090-0.050-0.025-0.013-0.050-0.025-0.076-0.036-0.024-0.011-0.047-0.024-0.072-0.033-0.050-0.022-0.104-0.041-0.157-0.057-0.014-0.005-0.028-0.008-0.044-0.013-0.044-0.013-0.088-0.025-0.134-0.035-0.016-0.003-0.030-0.006-0.046-0.009-0.049-0.009-0.099-0.017-0.148-0.022-0.011 0-0.020-0.003-0.031-0.003-0.063-0.006-0.126-0.009-0.19-0.009-0.083 0-0.164 0.006-0.242 0.017-0.083 0.011-0.165 0.028-0.247 0.049 0 0 0 0 0 0s0 0 0 0c-0.474 0.121-0.88 0.416-1.144 0.814-0.022 0.033-0.041 0.066-0.061 0.101-0.006 0.011-0.014 0.024-0.020 0.035-0.154 0.28-0.242 0.601-0.242 0.943v3.344h-3.344c-1.083 0-1.958 0.877-1.958 1.958 0 0.058 0.006 0.113 0.011 0.17 0.005 0.060 0.014 0.118 0.024 0.176 0 0.006 0.003 0.013 0.003 0.019 0.005 0.028 0.009 0.057 0.016 0.083 0 0 0.003 0 0.003 0 0.203 0.864 0.976 1.508 1.901 1.508h3.344v3.344c0 0.54 0.22 1.031 0.573 1.385 0.354 0.354 0.844 0.573 1.387 0.573 1.081 0 1.958-0.877 1.958-1.958v-3.344h3.344c1.080 0 1.956-0.877 1.956-1.956s-0.877-1.958-1.956-1.958z"
          ></path>
        </svg>
      </button>

      <button
        v-if="!viewDeadAnimal"
        class="orange-btn px-3 tooltipbutton"
        :class="[viewDeadAnimal ? 'orange-btn_active' : '']"
        @click="toggleDeadAnimal()"
      >
        <span class="tooltipbuttontext" style="margin-left: 3rem; width: 130px"
          >Vedi Animali Deceduti</span
        >
        <svg id="icon-Risorsa-16" viewBox="0 0 27 32" class="w-4 fill-current">
          <path
            d="M25.451 28.297h-23.6c-1.023 0-1.851 0.83-1.851 1.851s0.83 1.851 1.851 1.851h23.6c1.023 0 1.851-0.83 1.851-1.851s-0.83-1.851-1.851-1.851z"
          ></path>
          <path
            d="M25.423 11.082c0-6.12-4.961-11.082-11.082-11.082-6.883 0-12.463 5.58-12.463 12.463v13.734h23.545v-15.116zM17.774 10.41h-2.64v7.507c0 0.818-0.664 1.482-1.482 1.482s-1.482-0.664-1.482-1.482v-7.507h-2.64c-0.818 0-1.482-0.664-1.482-1.482s0.664-1.482 1.482-1.482h2.64v-2.534c0-0.818 0.664-1.482 1.482-1.482s1.482 0.664 1.482 1.482v2.534h2.64c0.818 0 1.482 0.664 1.482 1.482s-0.664 1.482-1.482 1.482z"
          ></path>
        </svg>
      </button>

      <button
        v-if="viewDeadAnimal"
        class="orange-btn px-3 tooltipbutton"
        :class="[viewDeadAnimal ? 'orange-btn_active' : '']"
        @click="toggleDeadAnimal()"
      >
        <span class="tooltipbuttontext" style="margin-left: 3rem; width: 130px"
          >Vedi Animali Correnti</span
        >
        <svg viewBox="0 0 512 512" class="w-4 fill-current">
          <path
            d="M228.3 469.1L47.6 300.4c-4.2-3.9-8.2-8.1-11.9-12.4h87c22.6 0 43-13.6 51.7-34.5l10.5-25.2 49.3 109.5c3.8 8.5 12.1 14 21.4 14.1s17.8-5 22-13.3L320 253.7l1.7 3.4c9.5 19 28.9 31 50.1 31H476.3c-3.7 4.3-7.7 8.5-11.9 12.4L283.7 469.1c-7.5 7-17.4 10.9-27.7 10.9s-20.2-3.9-27.7-10.9zM503.7 240h-132c-3 0-5.8-1.7-7.2-4.4l-23.2-46.3c-4.1-8.1-12.4-13.3-21.5-13.3s-17.4 5.1-21.5 13.3l-41.4 82.8L205.9 158.2c-3.9-8.7-12.7-14.3-22.2-14.1s-18.1 5.9-21.8 14.8l-31.8 76.3c-1.2 3-4.2 4.9-7.4 4.9H16c-2.6 0-5 .4-7.3 1.1C3 225.2 0 208.2 0 190.9v-5.8c0-69.9 50.5-129.5 119.4-141C165 36.5 211.4 51.4 244 84l12 12 12-12c32.6-32.6 79-47.5 124.6-39.9C461.5 55.6 512 115.2 512 185.1v5.8c0 16.9-2.8 33.5-8.3 49.1z"
          />
        </svg>
      </button>

      <!-- <input type="text" class="custom-input w-1/5" placeholder="Search..." /> -->
    </div>
    <!-- BEGIN: Add new animal modal -->
    <!-- <NuovoPet /> -->
    <NewPet
      v-if="showNewPet"
      :title="'Aggiungi un nuovo paziente'"
      :client="customerDetails"
      :coming-from-client="true"
      @close="handleShowNewPet"
      @render="update"
      @referente="openReferente"
    />
    <div v-if="newReferent" class="w-100">
      <NewReferente :title="'referente'" @close="newReferent = false" />
    </div>
    <!-- END: Add new animal modal -->
    <!-- BEGIN: Data List -->
    <div class="col-span-12 overflow-auto lg:overflow-visible mt-4">
      <!-- BEGIN: Living animal list-->
      <div class="paginator-container">
        <div
          class="prova mr-5 my-2 flex flex-col items-center md:flex-row md:justify-between flex-1"
        >
          <div class="mt-1">
            <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
              >Mostra
            </span>
            <select
              id=""
              v-model="pagination.forPage"
              name=""
              class="form-select w-16 h-6 pr-2 pl-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
            >
              <option
                v-for="show in forPageOption"
                :key="show"
                :value="show"
                class="form-select px-3"
              >
                {{ show }}
              </option>
            </select>
            <span class="text-md font-bold ml-2 mt-1"> elementi</span>
          </div>
        </div>
        <Paginator
          class="flex-0"
          :for-page-option="pagination.forPage"
          :total-records="totalRecords"
          @changePagination="
            (event) => $h.debounce(() => changePagination(event))
          "
        />
      </div>
      <div class="table-container mx-2">
        <table
          v-if="!viewDeadAnimal"
          :key="componentKey"
          class="overflow-hidden sm:rounded-lg w-full"
        >
          <THeader
            v-for="(item, itemKey) in table_config"
            :key="itemKey"
            :name="item.title"
            class="text-white border-b-2 border-theme-41 whitespace-nowrap text-center font-bold bg-theme-40"
          />
          <tbody class="bg-white">
            <tr>
              <td
                v-for="(elem, index) in table_config"
                :key="index"
                class="text-center px-1 py-3"
              >
                <select
                  v-if="elem.type === 'select'"
                  v-model="pets_filter[elem.key]"
                  class="w-full border rounded-md h-7 text-gray-500 mt-2"
                >
                  <option selected></option>
                  <option
                    v-for="(item, i) in elem.option"
                    :key="i"
                    :value="item"
                  >
                    {{ item.name ? item.name : item }}
                  </option>
                </select>
                <input
                  v-else
                  v-model="elem.value"
                  :type="elem.type"
                  min="0"
                  :name="elem.title"
                  class="form-control h-7 mt-2"
                  :class="{
                    invisible: elem.type === 'none',
                    'p-0': elem.type === 'date'
                  }"
                  :placeholder="elem.title"
                  @change="filterHandler(elem)"
                />
              </td>
            </tr>
            <template v-for="(pet, index) in pets" :key="index">
              <tr
                v-if="pet.alive"
                class="h-12 text-center text-base font-normal hover:bg-theme-45"
                :class="{ 'bg-theme-2': index % 2 === 0 }"
              >
                <Cell :elementi="pet.type" class="border-r-2 border-theme-45" />
                <td class="border-r-2 border-theme-45 text-center">
                  <div
                    class="flex justify-center gap-2 text-sm font-medium text-gray-900 px-2"
                  >
                    <div class="tooltipbutton" @click="() => getPet(pet)">
                      <span class="tooltipbuttontext" style="margin-left: -1rem"
                        >Apri Anagrafica</span
                      >
                      <ChevronsRightIcon class="orange-round" />
                    </div>
                    {{ pet.name }}
                    <div v-if="!pet.alive">
                      <svg
                        id="icon-Risorsa-16"
                        viewBox="0 0 27 32"
                        class="w-4 fill-current text-theme-49"
                      >
                        <path
                          d="M25.451 28.297h-23.6c-1.023 0-1.851 0.83-1.851 1.851s0.83 1.851 1.851 1.851h23.6c1.023 0 1.851-0.83 1.851-1.851s-0.83-1.851-1.851-1.851z"
                        ></path>
                        <path
                          d="M25.423 11.082c0-6.12-4.961-11.082-11.082-11.082-6.883 0-12.463 5.58-12.463 12.463v13.734h23.545v-15.116zM17.774 10.41h-2.64v7.507c0 0.818-0.664 1.482-1.482 1.482s-1.482-0.664-1.482-1.482v-7.507h-2.64c-0.818 0-1.482-0.664-1.482-1.482s0.664-1.482 1.482-1.482h2.64v-2.534c0-0.818 0.664-1.482 1.482-1.482s1.482 0.664 1.482 1.482v2.534h2.64c0.818 0 1.482 0.664 1.482 1.482s-0.664 1.482-1.482 1.482z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </td>

                <td
                  class="whitespace-nowrap text-center px-3 py-3 border-r-2 border-theme-45"
                >
                  <i
                    class="text-base"
                    :class="[
                      pet.gender === 'M'
                        ? 'fas fa-mars'
                        : pet.gender === 'F'
                        ? 'fas fa-venus'
                        : ''
                    ]"
                  />
                </td>
                <Cell
                  :elementi="pet.breed"
                  class="border-r-2 border-theme-45"
                />
                <Cell
                  :elementi="
                    /*pet.pet_info &&*/
                    /* Rimossa variabile pet.pet_info && per problema visualizzazione frontend */
                    pet.pet_info.birth_date
                      ? getPetAgeString(pet.pet_info.birth_date)
                      : 0
                  "
                  class="border-r border-gray-500"
                />

                <CellBadge :elementi="pet.microchip" :back-color="true" />
              </tr>
            </template>
          </tbody>
        </table>
        <!-- END: Living animal list-->

        <!-- BEGIN: Dead animal list-->
        <table v-else class="w-full">
          <thead class="t-header h-10">
            <tr>
              <th>SPECIE</th>
              <th>NOME</th>
              <th>SESSO</th>
              <th>RAZZA</th>
              <th>DATA DECESSO</th>
            </tr>
          </thead>
          <tbody class="bg-white divide-y divide-gray-200">
            <tr
              v-for="(pet, index) in petsDead"
              :key="index"
              class="h-12 text-center text-base font-normal hover:bg-blue-200 cursor-pointer"
              :class="{ 'bg-blue-100': index % 2 === 0 }"
              @click.stop="() => $goToPet(pet)"
            >
              <Cell :elementi="pet.type" class="border-r border-gray-500" />

              <Cell
                :elementi="pet.name"
                class="border-r border-gray-500 hover:underline"
              />
              <td class="text-center border-r border-gray-500">
                <i
                  class="text-lg"
                  :class="[
                    pet.gender === 'M'
                      ? 'fas fa-mars'
                      : pet.gender === 'F'
                      ? 'fas fa-venus'
                      : ''
                  ]"
                />
              </td>
              <Cell :elementi="pet.breed" class="border-r border-gray-500" />
              <Cell
                :elementi="$FormatDate(pet.date_of_death)"
                class="border-r border-gray-500"
              />
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Dead animal list-->
    </div>
  </div>
  <!--END animals list-->
</template>

<script>
import { defineComponent } from 'vue'
import NewPet from '../../views/nuovo-pet/NewPet.vue'
import axios from '../../gateway/backend-api'
import Paginator from '../paginator/Main'
import THeader from '../cvit-table/THeader.vue'
import Cell from '../../components/cvit-table/Cell.vue'
import CellBadge from '../../components/cvit-table/Cell-Badge.vue'
import NewReferente from '../../views/nuovo-pet/NewReferente.vue'
import { mapGetters, mapActions } from 'vuex'
import NotificationComponent from '../../components/notification/NotificationComponent.vue'
import Toastify from 'toastify-js'

const forPageOption = [10, 20, 50, 100]
export default defineComponent({
  components: {
    NotificationComponent,
    NewPet,
    NewReferente,
    Paginator,
    THeader,
    Cell,
    CellBadge
    /* CellLink */
  },
  props: {
    customerDetails: {
      type: [Object],
      default: function () {
        return {}
      }
    },
    active: {
      type: [Boolean],
      default: true
    }
  },
  data() {
    return {
      componentKey: 0,
      showNewPet: false,
      newReferent: false,
      viewDeadAnimal: false,
      pets: [],
      petsDead: [],
      totalRecords: 0,
      forPageOption,
      active_filters: {},
      pets_filter: {},
      pagination: {
        page: 1,
        forPage: forPageOption[0] //lasciare preimpostato 10 come defualt
      },
      table_config: [
        {
          key: 'species',
          title: 'Specie',
          type: 'select',
          option: []
        },
        {
          key: 'name',
          title: 'Nome',
          type: 'string'
        },
        {
          key: 'gender',
          title: 'Sesso',
          type: 'select',
          option: [
            { name: 'M', value: 'M' },
            { name: 'F', value: 'F' }
          ]
        },
        {
          key: 'breed',
          title: 'Razza',
          type: 'select',
          option: []
        },
        {
          key: 'age',
          title: 'Età',
          type: 'string'
        },
        {
          key: 'microchip',
          title: 'Microchip',
          type: 'string'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getPetsDetails: 'anagraficaPazienti/getPetsDetails',
      getComparisonPets: 'anagraficaPazienti/getComparisonPets',
      getCustomersDetails: 'anagraficaClienti/getCustomersDetails',
      getComparisonCustomer: 'anagraficaClienti/getComparisonCustomer',
      user: 'getUser'
    })
  },
  watch: {
    'pets_filter.species': function (elem) {
      this.filterSelect(elem, 'species')
    },
    'pets_filter.gender': function (elem) {
      this.filterSelect(elem, 'gender')
    },
    'pets_filter.breed': function (elem) {
      this.filterSelect(elem, 'breed')
    }
  },
  mounted() {
    if (this.customerDetails.full_count_pet)
      this.totalRecords = this.customerDetails.full_count_pet
    this.active_filters.id_owner = this.customerDetails.id
    this.pets = this.customerDetails.pets_alive
    this.petsDead = this.customerDetails.pets_dead
    if (this.active)
      axios
        .get('/' + localStorage.getItem('clinic') + '/pet/types_breeds')
        .then((res) => {
          if (this.table_config) this.table_config[0].option = res.data
        })
  },
  methods: {
    ...mapActions({
      getPetAction: 'anagraficaPazienti/getPetAction',
      getCustomerAction: 'anagraficaClienti/getCustomerAction',
      updateClientData: 'anagraficaClienti/updateClientData'
    }),
    getPet: function (pet) {
      axios
        .get(localStorage.getItem('clinic') + '/pet/' + pet.id)
        .then((res) => {
          this.$goToPet(res.data)
        })
    },
    getPetAgeString: function (birth_date) {
      var age = this.$getDifferenceYears(birth_date)
      if (age != 0) {
        if (age == 1) return age + ' anno'
        else return age + ' anni'
      } else {
        age = this.$getDifferenceMonths(birth_date)
        if (age == 1) return age + ' mese'
        else return age + ' mesi'
      }
    },
    handleShowNewPet() {
      this.showNewPet = !this.showNewPet
    },

    toggleDeadAnimal: function () {
      this.viewDeadAnimal = !this.viewDeadAnimal
    },

    openReferente() {
      this.newReferent = !this.newReferent
    },
    changePagination: function (value) {
      this.pagination.page = value.page
      this.pagination.forPage = value.forPage
      this.getPetsApiList()
    },
    update() {
      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/customer/' +
            this.customerDetails.id
        )
        .then((res) => {
          this.pets = res.data.pets_alive
          this.petsDead = res.data.pets_dead
          this.componentKey += 1
        })
      this.editClient()
    },
    editClient() {
      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/customer/' +
            this.customerDetails.id
        )
        .then((res) => {
          //this.customerDetails = res.data
          this.updateClientData(res.data)
        })
    },
    // funzione che fa apparire pop-up quando superi il numero di tab che si possono aprire
    maxTabOpen: function () {
      Toastify({
        node: cash('#max-tab-open').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    },
    // funzione che fa apparire pop-up quando provi ad aprire una tab già aperta
    tabAlreadyOpen: function () {
      Toastify({
        node: cash('#tab-already-open').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    },
    // FILTER METHODS
    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()

      if (this.filterActive) this.getPetsApiList()
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    },
    getPetsApiList: function () {
      if (this.pagination.page != 1)
        this.$store.dispatch('commonComponents/showLoadingModalAction')

      axios
        .post('/' + localStorage.getItem('clinic') + '/search/searchPet', {
          toSearch: this.active_filters,
          per_page: this.pagination.forPage,
          page: this.pagination.page
        })
        .then((res) => {
          this.pets = res.data.pet.data.items
          this.totalRecords = res.data.pet.total
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    filterSelect(elem, field) {
      if (elem) {
        switch (field) {
          case 'species':
            if (
              this.active_filters.pet_type_id != null &&
              this.active_filters.pet_type_id != elem.id
            ) {
              this.active_filters.pet_breed_id = null
            }
            this.table_config[3].option = elem.breeds
            this.active_filters.pet_type_id = elem.id
            break
          case 'breed':
            this.active_filters.pet_breed_id = elem.id
            break
          default:
            this.active_filters[field] = elem.value
        }
      } else {
        switch (field) {
          case 'species':
            this.active_filters.pet_type_id = elem
            break
          case 'breed':
            this.active_filters.pet_breed_id = elem
            break
          default:
            this.active_filters[field] = null
        }
      }
      if (field == 'species') this.pets_filter.breeds = null
      if (!this.active_filters.pet_type_id) {
        this.table_config[3].option = []
        this.active_filters.pet_breed_id = null
      }

      this.checkIfFilterActive()

      if (this.filterActive) this.getPetsApiList()
    }
  }
})
</script>
