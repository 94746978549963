<template>
  <!-- Main modal -->
  <div
    id="defaultModal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-12 mt-1 mx-auto px-3 pt-3 pb-7 border w-3/4 md:w-4/6 shadow-lg rounded-md bg-white scrollbar-none"
    >
      <!-- Modal content -->

      <div class="mb-9">
        <button type="button" class="closing-btn" @click="$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <!-- Modal header -->
      <div class="m-2">
        <h3 class="modal-title">
          {{ edit_id ? 'Modifica ricetta' : 'Nuova Ricetta' }}
        </h3>
      </div>
      <!-- Modal body -->
      <div class="flex justify-end items-center p-2">
        <label class="form-label font-medium text-base text-right"
          >Data:
        </label>
        <p class="form-label font-medium text-gray-600 ml-2">
          {{ now }}
        </p>
      </div>

      <div class="p-2">
        <div class="flex flex-col mb-1">
          <!-- SELEZIONARE UTENTE nuevo  -->
          <div class="flex flex-col md:flex-row gap-6">
            <div v-if="!clientInvoice" id="select-utente" class="flex-1 p-3">
              <label class="font-semibold text-theme-43">
                SELEZIONARE UN UTENTE
              </label>
              <div class="mt-3 relative">
                <div class="">
                  <input
                    @input="
                      (event) =>
                        $h.debounce(
                          () => (searchBarClient = event.target.value)
                        )
                    "
                    type="text"
                    class="custom-input pl-10 rounded-xl py-2 z-10"
                    placeholder="Ricerca rapida nell'anagrafica"
                    @click.stop="dropDownSearchClient"
                  />
                  <svg
                    class="text-theme-44 fill-current w-6 absolute top-1 left-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 54.3 54.4"
                    @click="toggleModal"
                  >
                    <path
                      d="M16.4 4.7c9.2-7 22.3-6.1 30.8 2.1 8.4 8.1 9.4 21.1 2.4 31.1-5.5 7.9-19.5 14.2-33.5 4.9-3.2 3.2-6.5 6.5-9.7 9.8-2 2-3.8 2.3-5.4.8-1.6-1.5-1.4-3.4.7-5.6.3-.4.7-.7 1.1-1.1 2.9-2.9 5.9-5.8 8.7-8.6-8.7-12.9-4.1-26.5 4.9-33.4Zm31.2 19c0-9.2-7.8-17-17.2-17-9.5 0-17 7.7-17 17.3 0 9.4 7.6 16.9 17.2 16.9 9.5 0 17-7.6 17.1-17.3Z"
                    />
                  </svg>
                </div>
                <SearchDrop
                  v-if="isActiveSearchClient === true"
                  class="z-50 search-result bg-theme-44 bg-opacity-80 overflow-scroll scrollbar-none rounded-xl absolute invisible opacity-0 mt-4 py-4 px-3 right-0 top-6"
                  style="width: 28rem"
                  :filter-categories="['customer']"
                  :pet-invoice="petInvoice"
                  :filter="searchBarClient"
                  @childToParent="onChildClickClient"
                  @closeDropDownSearch="closeDropDownSearchClient"
                />
              </div>
            </div>
            <div
              v-if="clientInvoice"
              class="flex-1 px-1 py-2 border-2 border-theme-44 border-opacity-10 shadow-md rounded-md"
            >
              <div
                class="bg-theme-2 rounded-md p-1 flex justify-between items-center mb-2"
              >
                <h2 class="font-bold nunito text-lg pl-1">Dati Proprietario</h2>
                <a
                  class="cursor-pointer text-theme-41 fill-current"
                  @click="removeSelectedClient"
                >
                  <i class="fas fa-times text-center p-3" />
                </a>
              </div>

              <div class="flex flex-col ml-5">
                <div class="grid grid-cols-3 gap-2">
                  <label
                    class="form-label font-bold text-gray-700 text-xs sm:text-sm xl:text-base col-span-1"
                    >Cliente:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 text-xs sm:text-sm xl:text-base col-span-2"
                  >
                    {{
                      clientInvoice.first_name + ' ' + clientInvoice.last_name
                    }}
                  </p>
                </div>
                <div class="grid grid-cols-3 gap-2">
                  <label
                    class="form-label font-bold text-gray-700 text-xs sm:text-sm xl:text-base col-span-1"
                    >Indirizzo:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 text-xs sm:text-sm xl:text-base col-span-2"
                  >
                    {{
                      clientInvoice.address.address +
                      ' (' +
                      clientInvoice.address.district +
                      ')'
                    }}
                  </p>
                </div>
                <div class="grid grid-cols-3 gap-2">
                  <label
                    class="form-label font-bold text-gray-700 text-xs sm:text-sm xl:text-base col-span-1"
                    >Email:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 text-xs sm:text-sm xl:text-base col-span-2"
                  >
                    {{
                      clientInvoice.emails
                        ? clientInvoice.emails.email1.email
                        : '--'
                    }}
                  </p>
                </div>
                <div class="grid grid-cols-3 gap-2">
                  <label
                    class="form-label font-bold text-gray-700 text-xs sm:text-sm xl:text-base col-span-1"
                    >Codice Fiscale:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 text-xs sm:text-sm xl:text-base col-span-2"
                  >
                    {{ clientInvoice.fiscal_code }}
                  </p>
                </div>
              </div>
            </div>

            <!-- SELEZIONARE CLIENTE nuevo -->
            <div
              v-if="!selected"
              id="select-client
            "
              class="flex-1 p-3"
            >
              <label class="font-semibold text-theme-43">
                SELEZIONARE UN CLIENTE
              </label>
              <div class="mt-3 relative">
                <div class="">
                  <input
                    @input="
                      (event) =>
                        $h.debounce(() => (searchBarPet = event.target.value))
                    "
                    type="text"
                    class="custom-input pl-10 rounded-xl py-2 z-10"
                    placeholder="Ricerca rapida nell'anagrafica"
                    @click.stop="dropDownSearchPet"
                  />
                  <svg
                    class="text-theme-44 fill-current w-6 absolute top-1 left-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 54.3 54.4"
                    @click="toggleModal"
                  >
                    <path
                      d="M16.4 4.7c9.2-7 22.3-6.1 30.8 2.1 8.4 8.1 9.4 21.1 2.4 31.1-5.5 7.9-19.5 14.2-33.5 4.9-3.2 3.2-6.5 6.5-9.7 9.8-2 2-3.8 2.3-5.4.8-1.6-1.5-1.4-3.4.7-5.6.3-.4.7-.7 1.1-1.1 2.9-2.9 5.9-5.8 8.7-8.6-8.7-12.9-4.1-26.5 4.9-33.4Zm31.2 19c0-9.2-7.8-17-17.2-17-9.5 0-17 7.7-17 17.3 0 9.4 7.6 16.9 17.2 16.9 9.5 0 17-7.6 17.1-17.3Z"
                    />
                  </svg>
                </div>
                <SearchDrop
                  v-if="isActiveSearchPet === true"
                  class="z-50 search-result bg-theme-44 bg-opacity-80 overflow-scroll scrollbar-none rounded-xl absolute invisible opacity-0 mt-4 py-4 px-3 right-0 top-6"
                  style="width: 28rem"
                  :filter-categories="['pet']"
                  :customer-invoice="clientInvoice"
                  :filter="searchBarPet"
                  @childToParent="onChildClickPet"
                  @closeDropDownSearch="closeDropDownSearchPet"
                />
              </div>
            </div>
            <div
              v-if="selected"
              class="flex-1 px-1 py-2 border-2 border-theme-44 border-opacity-10 shadow-md rounded-md"
            >
              <div
                class="bg-theme-2 rounded-md p-1 flex justify-between items-center mb-2"
              >
                <h2 class="font-bold nunito text-lg pl-1">Dati Pet</h2>
                <a
                  class="cursor-pointer text-theme-41 fill-current"
                  @click="removeSelectedPet"
                >
                  <i class="fas fa-times text-center p-3" />
                </a>
              </div>
              <div class="flex flex-col ml-5">
                <div class="grid grid-cols-3 gap-2">
                  <label
                    class="form-label font-bold text-gray-700 text-xs sm:text-sm xl:text-base col-span-1"
                    >Cliente:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 text-xs sm:text-sm xl:text-base col-span-2"
                  >
                    {{ selected.name }}
                  </p>
                </div>
                <div class="grid grid-cols-3 gap-2">
                  <label
                    class="form-label font-bold text-gray-700 text-xs sm:text-sm xl:text-base col-span-1"
                    >Indirizzo:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 text-xs sm:text-sm xl:text-base col-span-2"
                  >
                    {{ selected.owners[0].address.address }}
                  </p>
                </div>
                <div class="grid grid-cols-3 gap-2">
                  <label
                    class="form-label font-bold text-gray-700 text-xs sm:text-sm xl:text-base col-span-1"
                    >Sesso:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 text-xs sm:text-sm xl:text-base col-span-2"
                  >
                    {{ selected.gender }}
                  </p>
                </div>
                <div class="grid grid-cols-3 gap-2">
                  <label
                    class="form-label font-bold text-gray-700 text-xs sm:text-sm xl:text-base col-span-1"
                    >Microchip:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 text-xs sm:text-sm xl:text-base col-span-2"
                  >
                    {{ selected.microchip }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full items-center mt-6 p-3">
            <div class="flex flex-col sm:flex-row justify-between">
              <label class="font-semibold text-theme-43 mb-3 sm:mb-0"
                >ELENCO MEDICINALI
              </label>
              <button
                type="file"
                class="orange-btn w-48"
                @click="$emit('addDrug')"
              >
                <PlusCircleIcon class="h-5 w-5 mr-2" />
                Aggiungi Farmaco
              </button>
            </div>
            <div
              class="table-container scrollbar scrollbar-thumb-theme-44 scrollbar-track-theme-2 my-5 pb-3"
            >
              <table class="w-full">
                <thead class="t-header bg-theme-45 text-theme-43 h-10">
                  <tr>
                    <td
                      v-for="(item, itemKey) in allegatiConfig"
                      :key="itemKey"
                      class="px-8"
                    >
                      {{ item }}
                    </td>
                    <td></td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(doc, docKey) in newDrugs"
                    :key="doc.id"
                    class="h-12 text-xs font-normal hover:bg-theme-43 hover:bg-opacity-20"
                    :class="{ 'bg-theme-2': docKey % 2 === 0 }"
                  >
                    <template v-if="doc.tipo == 'Farmaco' || doc.aic">
                      <template v-for="(c, i) in doc.farmaco" :key="i">
                        <td
                          v-if="i != 'tipo'"
                          class="text-center text-sm border-r-2 border-theme-46"
                        >
                          <template v-if="c != 'data'">
                            {{ c }}
                          </template>
                        </td>
                      </template>
                    </template>
                    <template v-else>
                      <td
                        class="text-center text-sm border-r-2 border-theme-46"
                      ></td>
                      <td
                        class="text-center text-sm border-r-2 border-theme-46"
                      >
                        {{ doc.tipo }}
                      </td>
                      <td
                        class="text-center text-sm border-r-2 border-theme-46"
                      ></td>
                      <td
                        class="text-center text-sm border-r-2 border-theme-46"
                      >
                        {{ doc.qty }}
                      </td>
                      <td
                        class="text-center text-sm border-r-2 border-theme-46"
                      >
                        {{ doc.posologia }}
                      </td>
                      <td
                        class="text-center text-sm border-r-2 border-theme-46"
                      >
                        {{
                          doc.data && doc.data.chronical
                            ? 'Cronico'
                            : doc.tNumber
                        }}
                      </td>
                    </template>

                    <td class="text-center text-sm border-r-2 border-theme-46">
                      <div class="flex justify-center gap-1 items-center">
                        <button
                          type="button"
                          class="mr-1 text-theme-41 p-0.5 hover:bg-theme-41 hover:text-white rounded-lg text-sm ml-1"
                          @click="$emit('removeDrug', doc)"
                        >
                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </button>

                        <button
                          type="button"
                          class="mr-1 text-theme-41 fill-current p-1 hover:bg-theme-41 hover:text-white rounded-lg text-sm ml-1"
                          @click="$emit('editDrug', doc)"
                        >
                          <svg
                            class="w-4 h-4"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                          >
                            <path
                              d="M362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32zM421.7 220.3L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3z"
                            />
                          </svg>
                        </button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-if="newDrugs && newDrugs.length === 0" class="col-span-12">
              <label
                class="flex items-center justify-center align-center w-full text-gray-500 mt-2"
                >Nessun Farmaco Selezionato.</label
              >
            </div>
            <div class="mt-3">
              <div class="flex flex-col gap-1">
                <label class="form-label">Diagnosi: </label>
                <v-select
                  v-model="diagnosiSelected"
                  :options="diagnosiOptions"
                  label="descrizione"
                  :reduce="(value) => value.codice"
                  placeholder="Seleziona la diagnosi"
                  class="py-2 flex-1"
                >
                  <template #option="option">
                    {{ option.descrizione }}
                  </template>
                </v-select>
              </div>
            </div>
            <div class="mt-3">
              <div class="flex flex-col gap-1">
                <label class="form-label">Note: </label>

                <textarea v-model="ricNote" class="custom-input" rows="4" />
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row justify-end">
          <input
            ref="file"
            type="file"
            class="form-control p-2 hidden"
            required
            @change="handleFileChange"
          />
        </div>
        <p class="text-theme-41 font-semibold text-sm px-3">
          *La ricetta non può contenere più di un medicinale di tipo Estero,
          Galenico o Omeopatico.
        </p>
      </div>
      <!-- Modal footer -->

      <div class="px-6 flex items-center justify-end mt-3">
        <button
          ref="input4"
          type="button"
          class="orange-btn mr-3"
          :class="!selected || !clientInvoice ? 'disabled' : ''"
          @click="edit_id ? handleEdit() : handleSave(false)"
        >
          <PlusCircleIcon class="h-5 w-5 mr-2" />
          {{ edit_id ? 'Modifica' : 'Salva' }}
        </button>
        <button
          v-if="!edit_id"
          ref="input4"
          type="button"
          class="orange-btn mr-3"
          :class="!selected || !clientInvoice ? 'disabled' : ''"
          @click="handleSave(true)"
        >
          <PlusCircleIcon class="h-5 w-5 mr-2" /> Salva ed Emetti
        </button>
        <button type="button" class="gray-btn" @click="$emit('close')">
          <span class="h-5"></span>
          Annulla
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import moment from 'moment'
import SearchDrop from '../../components/search-drop/SearchDrop.vue'
import { useStore } from '@/store'

export default defineComponent({
  components: { SearchDrop },
  props: [
    'newDrugs',
    'pet',
    'clientInvoice',
    'petInvoice',
    'edit_id',
    'diagnosi',
    'note'
  ],
  emits: [
    'addDrug',
    'removeDrug',
    'editDrug',
    'Drug',
    'close',
    'emitDrug',
    'editRev',
    'selectClientPet',
    'setRicNote',
    'setDiagnosi'
  ],

  setup(props, { emit }) {
    const store = useStore()
    const searchBarClient = ref('')
    const searchBarPet = ref('')
    const isActiveSearchClient = ref(false)
    const isActiveSearchPet = ref(false)
    const ricNote = ref('')
    const diagnosiSelected = ref(null)
    const diagnosiOptions = ref([
      {
        codice: 'RESPIRATORIE',
        descrizione: 'Patologie respiratorie'
      },
      {
        codice: 'CUTANEE',
        descrizione: 'Patologie cutanee'
      },
      {
        codice: 'NERVOSE',
        descrizione: 'Patologie nervose'
      },
      {
        codice: 'UROGENITALI',
        descrizione: 'Patologie urogenitali'
      },
      {
        codice: 'LOCOMOTORIE',
        descrizione: 'Patologie locomotorie'
      },
      {
        codice: 'ENTERICHE',
        descrizione: 'Patologie enteriche'
      },
      {
        codice: 'VACCINALE',
        descrizione: 'Profilassi vaccinale'
      },
      {
        codice: 'MAMMARIE',
        descrizione: 'Patologie mammarie'
      },
      {
        codice: 'SETTICEMICHE',
        descrizione: 'Patologie setticemiche'
      },
      {
        codice: 'OSTEOARTICOLARI',
        descrizione: 'Patologie osteoarticolari'
      },
      {
        codice: 'OCULARI',
        descrizione: 'Patologie oculari'
      },
      {
        codice: 'ANTIPARASSITARI',
        descrizione: 'Trattamenti antiparassitari'
      },
      {
        codice: 'CARDIOVASCOLARI',
        descrizione: 'Malattie Cardiovascolari'
      },
      {
        codice: 'ANESTESIA',
        descrizione: 'Anestesia'
      },
      {
        codice: 'METABOLICHE',
        descrizione: 'Malattie Metaboliche'
      },
      {
        codice: 'COMPORTAMENTALI',
        descrizione: 'Patologie Comportamentali'
      },
      {
        codice: 'ONCOLOGICHE',
        descrizione: 'Malattie Oncologiche'
      },
      {
        codice: 'ALTRO',
        descrizione: 'Altro (specificare nel campo note)'
      }
    ])
    // const diagnosiOptions = ref([
    //   { name: 'Patologie respiratorie', id: 1 },
    //   { name: 'Patologie cutanee', id: 2 },
    //   { name: 'Patologie nervose', id: 3 },
    //   { name: 'Patologie urogenitali', id: 4 },
    //   { name: 'Patologie locomotorie', id: 5 },
    //   { name: 'Patologie enteriche', id: 6 },
    //   { name: 'Patologie vaccinale', id: 7 },
    //   { name: 'Patologie mammarie', id: 8 },
    //   { name: 'Patologie setticemiche', id: 9 },
    //   { name: 'Patologie osteoarticolari', id: 10 },
    //   { name: 'Patologie oculari', id: 11 },
    //   { name: 'Trattamenti antiparassoitari', id: 12 },
    //   { name: 'Malattie metaboliche', id: 13 },
    //   { name: 'Malattie oncologiche', id: 14 },
    //   { name: 'Patologie comportamentali', id: 15 },
    //   { name: 'Malattie cardiovascolari', id: 16 },
    //   { name: 'Anestesia', id: 17 },
    //   { name: 'Altro(specificare nel campo note)', id: 18 }
    // ])

    const now = moment().format('D/M/YYYY')

    const allegatiConfig = [
      'Aic',
      'Farmaco / Tipo',
      'Confezione',
      'Qtà',
      'Posologia',
      'Durata Tratt. (gg)'
    ]

    const formatdate = (epoch) => {
      return moment.unix(epoch).format('DD/MM/YYYY')
    }

    const dropDownSearchClient = () => {
      isActiveSearchClient.value = true
      isActiveSearchPet.value = false
    }
    const closeDropDownSearchClient = () => {
      isActiveSearchClient.value = false
    }
    const dropDownSearchPet = () => {
      isActiveSearchPet.value = true
      isActiveSearchClient.value = false
    }
    const closeDropDownSearchPet = () => {
      isActiveSearchPet.value = false
    }
    const onChildClickClient = (value) => {
      emit('selectClientPet', 'clientInvoice', value)
      closeDropDownSearchPet()
      closeDropDownSearchClient()
    }
    const onChildClickPet = (value) => {
      emit('selectClientPet', 'petInvoice', value)
      closeDropDownSearchPet()
      closeDropDownSearchClient()
    }

    const removeSelectedClient = () => {
      emit('selectClientPet', 'clientInvoice', null)
      searchBarClient.value = ''
    }

    const removeSelectedPet = () => {
      emit('selectClientPet', 'petInvoice', null)
      searchBarPet.value = ''
    }

    const handleSave = (emit_value) => {
      if (props.newDrugs && props.newDrugs.length === 0) {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di aggiungere un farmaco'
        })
        return
      }
      if (!diagnosiSelected.value) {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega selezionare la diagnosi'
        })
        return
      }
      if (props.petInvoice && props.clientInvoice)
        emit('emitDrug', props.clientInvoice, emit_value)
      else
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Dati cliente o paziente mancanti'
        })
    }

    const handleEdit = () => {
      if (props.newDrugs && props.newDrugs.length === 0) {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di aggiungere un farmaco'
        })
        return
      }
      if (!diagnosiSelected.value) {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega selezionare la diagnosi'
        })
        return
      }
      if (props.petInvoice && props.clientInvoice)
        emit('editRev', props.clientInvoice, props.petInvoice)
      else
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Dati cliente o paziente mancanti'
        })
    }

    const selected = computed(() => {
      return props.petInvoice
    })

    return {
      allegatiConfig,
      searchBarClient,
      searchBarPet,
      isActiveSearchClient,
      isActiveSearchPet,
      formatdate,
      now,
      dropDownSearchClient,
      closeDropDownSearchClient,
      dropDownSearchPet,
      closeDropDownSearchPet,
      onChildClickClient,
      onChildClickPet,
      removeSelectedClient,
      removeSelectedPet,
      selected,
      ricNote,
      diagnosiSelected,
      diagnosiOptions,
      handleSave,
      handleEdit
    }
  },

  watch: {
    isActiveSearchClient: function (elem) {
      if (!elem) this.searchBarClient = ''
    },
    isActiveSearchPet: function (elem) {
      if (!elem) this.searchBarPet = ''
    },
    ricNote: function (elem) {
      if (elem) this.$emit('setRicNote', elem)
    },
    diagnosiSelected: function (elem) {
      this.$emit('setDiagnosi', elem)
    }
  },
  created() {
    if (
      this.petInvoice &&
      typeof this.petInvoice.owners[0] != 'undefined' &&
      !this.clientInvoice
    )
      this.$emit('selectClientPet', 'clientInvoice', this.petInvoice.owners[0])
    if (this.diagnosi) this.diagnosiSelected = this.diagnosi
    if (this.note) this.ricNote = this.note
  },
  mounted() {
    document.addEventListener('click', this.closeDropDownSearchClient)
    document.addEventListener('click', this.closeDropDownSearchPet)
  }
})
</script>
