<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-opacity-50 bg-black scrollbar-none"
  >
    <div
      class="relative top-12 p-2 mx-auto overflow-hidden scrollbar-none border sm:w-4/5 w-full shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="flex flex-col content-end">
        <button type="button" class="closing-btn" @click="handleClose">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <!-- Modal header -->
        <div class="flex items-start pt-5 pb-1 rounded-t">
          <h3 class="modal-title w-full mt-5">
            {{ title }}
          </h3>
        </div>
        <!-- Fine Modal header -->

        <!-- Modal body -->

        <div class="p-6 grid grid-cols-11 gap-3">
          <div
            class="col-span-3 flex flex-col rounded-md border border-theme-44 border-opacity-30 shadow-sm"
          >
            <span class="tariff-subs bg-theme-44 rounded-b-none">TAGLIE</span>

            <div class="overflow-y-scroll scrollbar-none rounded-b-md">
              <div
                class="flex items-center gap-10 pt-2 justify-evenly opacity-0"
              >
                <label class="form-label text-xs">NETTO</label>
                <label class="form-label text-xs">IVATO</label>
              </div>
              <div
                v-for="element in newData['t1']"
                v-show="
                  tagliaShow
                    ? element.id_tariffario_taglie === tagliaShow
                      ? true
                      : false
                    : true
                "
                :key="element"
                class="grid gap-3 py-2 px-2 justify-center align-middle items-center"
                :class="[
                  readOnly ? 'col-span-4' : 'col-span-5',
                  element.id_tariffario_taglie % 2 === 0
                    ? 'bg-white   '
                    : 'bg-theme-2 '
                ]"
              >
                <div
                  class="relative flex h-9"
                  style="align-items: center"
                  :class="readOnly ? 'col-span-1' : 'col-span-2'"
                >
                  <span>
                    {{ element.alias ?? tagliaLabel ?? '' }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="(column, colIndex) in newData"
            :key="column"
            class="col-span-2 flex flex-col rounded-md border border-theme-44 border-opacity-30 shadow-sm"
          >
            <span
              v-if="colIndex === 't1'"
              class="tariff-subs bg-theme-44 rounded-b-none"
              >DIURNO</span
            >
            <span
              v-if="colIndex === 't2'"
              class="tariff-subs bg-theme-44 rounded-b-none"
              >NOTTURNO</span
            >
            <span
              v-if="colIndex === 't3'"
              class="tariff-subs bg-theme-44 rounded-b-none"
              >FESTIVO</span
            >
            <span
              v-if="colIndex === 't4'"
              class="tariff-subs bg-theme-44 rounded-b-none"
              >ASSICURAZIONE</span
            >

            <div class="overflow-y-scroll scrollbar-none">
              <div class="flex items-center gap-10 pt-2 justify-evenly">
                <label class="form-label text-xs">NETTO</label>
                <label class="form-label text-xs">IVATO</label>
              </div>
              <div
                v-for="(element, elementIndex) in column"
                v-show="
                  tagliaShow
                    ? element.id_tariffario_taglie === tagliaShow
                      ? true
                      : false
                    : true
                "
                :key="element"
                class="grid gap-3 justify-center align-middle items-center"
                style="margin: 0.5px"
                :class="[
                  readOnly ? 'col-span-4 ' : 'col-span-5 ',
                  element.id_tariffario_taglie % 2 === 0
                    ? 'bg-white   '
                    : 'bg-theme-2 '
                ]"
              >
                <div
                  class="flex gap-2 items-center"
                  style="height: 3.2rem"
                  :class="[readOnly ? 'col-span-1' : 'col-span-2']"
                >
                  <div class="relative flex-1 px-1">
                    <input
                      v-if="!readOnly && !netto"
                      v-model="newData[colIndex][elementIndex].data"
                      class="custom-input pl-5 focus:outline-none focus:ring-2 focus:ring-theme-41 py-1"
                      disabled
                      type="number"
                      placeholder="prezzo netto"
                    />
                    <input
                      v-else-if="!readOnly && netto"
                      v-model="newData[colIndex][elementIndex].data_netto"
                      class="custom-input pl-5 focus:outline-none focus:ring-2 focus:ring-theme-41 py-1"
                      type="number"
                      oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                      step="0.0001"
                      maxlength="7"
                      placeholder="prezzo netto"
                    />
                    <span
                      v-if="!readOnly || (readOnly && element.data)"
                      class="absolute left-3 text-theme-44 top-1"
                      >€</span
                    >
                    <input
                      v-if="readOnly && element.data"
                      type="text"
                      class="custom-input pl-5 py-1"
                      disabled
                      :value="
                        calculateRawPrice(
                          element.data_netto ?? element.data,
                          false,
                          discount,
                          greatest
                        )
                      "
                    />
                  </div>
                  <div class="relative flex-1 px-1">
                    <input
                      v-if="!readOnly && !netto"
                      v-model="calculatedPrice[colIndex][elementIndex]"
                      class="custom-input pl-5 focus:outline-none focus:ring-2 focus:ring-theme-41 py-1"
                      type="number"
                      step="0.001"
                      placeholder="prezzo ivato"
                      @change="(el) => modifyNetto(el, colIndex, elementIndex)"
                    />
                    <input
                      v-else-if="!readOnly && netto"
                      v-model="calculatedPrice[colIndex][elementIndex]"
                      class="custom-input pl-5 focus:outline-none focus:ring-2 focus:ring-theme-41 py-1"
                      disabled
                      type="number"
                      placeholder="prezzo ivato"
                    />

                    <span
                      v-if="!readOnly || (readOnly && element.data)"
                      class="absolute left-3 text-theme-44 top-1"
                      >€</span
                    >
                    <input
                      v-if="readOnly && element.data"
                      type="text"
                      class="custom-input pl-5 py-1"
                      disabled
                      :value="
                        calculateRawPrice(
                          element.data_netto ?? element.data,
                          true,
                          discount,
                          greatest
                        )
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Modal footer -->
        <div class="flex items-center justify-end gap-3 pr-6 pb-3">
          <button
            v-if="!readOnly"
            type="button"
            class="orange-btn"
            @click="handleConfirm"
          >
            <span class="h-5"></span>
            Conferma
          </button>
          <button type="button" class="gray-btn" @click="handleClose">
            <span class="h-5"></span>
            Annulla
          </button>
        </div>

        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRef, computed } from 'vue'
import { useStore } from '@/store'
import axios from '../../gateway/backend-api'

export default defineComponent({
  props: [
    'title',
    'data',
    'fullData',
    'directSave',
    'tariffarioId',
    'tagliaShow',
    'readOnly',
    'netto',
    'cassa',
    'discount',
    'greatest',
    'tagliaLabel'
  ],
  emits: ['confirm', 'decline'],
  setup(props, { emit }) {
    const store = useStore()

    const ivaPerc = computed(
      () => store.getters['reusableData/getRegimeFiscaleIvaPerc']
    )

    // const netto = ref(true)

    // document.getElementById('grey-topbar').classList.remove('z-30')
    // document.getElementById('mob-bar').classList.remove('z-30')

    // ----- DATA -----
    const newData = toRef(props, 'data')

    // if (newData.value) {
    //   Object.keys(newData.value).forEach(function (t) {
    //     Object.keys(newData.value[t]).forEach(function (el) {
    //       newData.value[t][el].data_netto = newData.value[t][el].data
    //     })
    //   })
    // }

    const handleConfirm = () => {
      if (props.directSave && props.directSave === true && props.fullData) {
        let tmp = props.fullData
        tmp.code = newData.value
        let send_data = { tariffario_clinic_id: props.tariffarioId, price: {} }

        send_data.price[props.fullData.id] = tmp
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/tariffario/salva-tariffario',
            send_data
          )
          .then((res) => {
            if (res.data.status == 'success') {
              store.dispatch('commonComponents/openNotificationModal', {
                message: 'Prezzi salvati correttamente'
              })
              emit('decline')
            }
          })
      } else {
        let toSend = {}
        if (newData.value) {
          Object.keys(newData.value).forEach(function (t) {
            let tmp = {}

            Object.keys(newData.value[t]).forEach(function (el) {
              tmp[el] = {
                ...newData.value[t][el]
              }
              tmp[el].data = tmp[el].data_netto
            })
            toSend[t] = tmp
          })
        }
        emit('confirm', toSend)
      }
    }

    const modifyNetto = (number, t, el) => {
      if (number.target.value && number.target.value !== '') {
        newData.value[t][el].data_netto = number.target.value / ivaPerc.value
      } else {
        newData.value[t][el].data_netto = 0
      }

      // if (props.cassa) {
      //   newData.value[t][el].data =
      //     Math.round((newData.value[t][el].data + Number.EPSILON) * 100) / 100
      // }
    }

    const calculatedPrice = computed(() => {
      let elem = {}
      if (newData.value) {
        Object.keys(newData.value).forEach(function (t) {
          let tmp = {}

          Object.keys(newData.value[t]).forEach(function (el) {
            if (newData.value[t][el].data_netto) {
              tmp[el] =
                Math.round(
                  ((newData.value[t][el].data_netto ??
                    newData.value[t][el].data) *
                    ivaPerc.value +
                    Number.EPSILON) *
                    100000000
                ) / 100000000

              newData.value[t][el].data =
                Math.round(
                  (newData.value[t][el].data_netto + Number.EPSILON) * 100
                ) / 100
            } else {
              console.log('sono nel else')
              tmp[el] = null
            }
          })
          elem[t] = tmp
        })
      }

      return elem
    })

    // const getArrotondamentoNetto = (netto) => {
    //   let netto_arrotondato = Math.round((netto + Number.EPSILON) * 100) / 100

    //   return netto - netto_arrotondato
    // }

    return {
      newData,
      calculatedPrice,
      handleConfirm,
      modifyNetto,
      ivaPerc
    }
  },
  methods: {
    handleClose() {
      this.$emit('decline')
    },
    calculateRawPrice(price, iva, discount, greatest) {
      let res = price

      if (iva === true) res = res * this.ivaPerc
      if (discount && discount !== 0 && discount !== '0')
        res -= res * ((discount * 1) / 100)
      if (greatest && greatest !== 0 && greatest !== '0')
        res += res * ((greatest * 1) / 100)
      res = Math.round((res + Number.EPSILON) * 100) / 100
      return res
    }
  }
})
</script>
