<template>
  <div>
    <div
      class="bg-white rounded-lg m-10 px-3 py-5 scrollbar-none"
      style="height: 90vh; overflow-y: scroll; overflow-x: hidden"
    >
      <h2 class="modal-title">Scegli la clinica</h2>
      <div class="paginator-container">
        <div class="mr-5 my-2 flex justify-between flex-1">
          <div class="mt-1">
            <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
              >Mostra
            </span>
            <select
              id=""
              v-model="pagination.forPage"
              name=""
              class="form-select w-16 h-6 pr-2 pl-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
            >
              <option
                v-for="show in forPageOption"
                :key="show"
                :value="show"
                class="form-select px-3"
              >
                {{ show }}
              </option>
            </select>
            <span class="text-md font-bold ml-2 mt-1"> elementi</span>
          </div>
        </div>
        <Paginator
          class="flex-0"
          :for-page-option="pagination.forPage"
          :total-records="totalRecords"
          @changePagination="changePagination"
        />
      </div>
      <div class="grid grid-cols-12 gap-6 mt-5 mx-3">
        <div
          v-for="(clinic, index) in clinics"
          :key="clinic"
          class="col-span-12 sm:col-span-6 md:col-span-4"
        >
          <div
            class="bg-white shadow-md border boder-theme-49 border-opacity-60 rounded-md"
          >
            <div
              class="flex flex-col lg:flex-row items-center p-5 text-center min-h-56 justify-evenly gap-5"
            >
              <div>
                <svg viewBox="0 0 40 32" class="h-6 fill-current">
                  <path
                    d="M34.25 5.127h-4.362v-0.289c0-2.667-2.171-4.838-4.838-4.838h-10.66c-2.667 0-4.838 2.171-4.838 4.838v0.289h-4.159c-2.974-0.001-5.393 2.419-5.393 5.393v16.088c0 2.971 2.419 5.391 5.393 5.391h28.857c2.971 0 5.391-2.419 5.391-5.391v-16.088c0-2.974-2.419-5.393-5.391-5.393zM13.096 4.838c0-0.712 0.582-1.293 1.293-1.293h10.66c0.714 0 1.293 0.582 1.293 1.293v22.123c0 0.714-0.579 1.293-1.293 1.293h-2.426v-3.268c0-1.562-1.266-2.828-2.828-2.828s-2.828 1.266-2.828 2.828v3.268h-2.578c-0.712 0-1.293-0.579-1.293-1.293v-22.122zM5.393 28.455c-1.019 0-1.849-0.827-1.849-1.846v-5.195h2.593c0.989 0 1.793-0.804 1.793-1.793s-0.804-1.796-1.793-1.796h-2.593v-2.242h2.593c0.989 0 1.793-0.806 1.793-1.796s-0.804-1.793-1.793-1.793h-2.593v-1.474c0-1.019 0.83-1.849 1.849-1.849h4.159v18.29c0 0.523 0.083 1.025 0.239 1.494h-4.398zM34.25 28.455h-4.599c0.153-0.47 0.236-0.971 0.236-1.494v-18.288h4.362c1.019 0 1.846 0.83 1.846 1.849v1.474h-2.591c-0.989 0-1.796 0.804-1.796 1.793s0.806 1.796 1.796 1.796h2.591v2.242h-2.591c-0.989 0-1.796 0.806-1.796 1.796s0.806 1.793 1.796 1.793h2.591v5.195c0 1.019-0.827 1.846-1.846 1.846z"
                  ></path>
                  <path
                    d="M17.006 10.659h1.395v1.395c0 0.52 0.422 0.943 0.943 0.943h0.943c0.52 0 0.943-0.422 0.943-0.943v-1.395h1.395c0.52 0 0.943-0.422 0.943-0.943v-0.943c0-0.52-0.422-0.943-0.943-0.943h-1.395v-1.395c0-0.52-0.422-0.943-0.943-0.943h-0.943c-0.52 0-0.943 0.422-0.943 0.943v1.395h-1.395c-0.52 0-0.943 0.422-0.943 0.943v0.943c0 0.52 0.422 0.943 0.943 0.943z"
                  ></path>
                </svg>
              </div>
              <div
                class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0"
              >
                <a class="text-lg font-bold nunito text-theme-40">{{
                  clinic.name
                }}</a>
              </div>
              <div class="flex mt-4 lg:mt-0">
                <button
                  class="orange-btn px-6"
                  @click="goToHome(index, clinic)"
                >
                  Entra
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Paginator from '../../components/paginator/Main.vue'
import axios from '../../gateway/backend-api'
import { mapGetters } from 'vuex'

import router from '@/router'

const forPageOption = [10, 20, 50]

export default defineComponent({
  components: {
    Paginator
  },
  setup() {},
  data() {
    return {
      clinics: {},
      totalRecords: 0,
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      }
    }
  },

  computed: {
    ...mapGetters({
      user: 'getUser'
    })
  },
  mounted() {
    if (this.user && this.user.clinic) this.clinics = this.user.clinic
    else {
      //this.$store.dispatch('logout')
      if (localStorage.getItem('userToken')) {
        this.$store.dispatch('logout')
      } else {
        router.push('/login')
      }
    }
  },

  methods: {
    goToHome(index, clinic) {
      axios
        .get('/' + index + '/auth/' + this.user.id + '/get-menu')
        .then((res) => {
          this.$store.dispatch('setMenu', res.data).then(() => {
            const userData = index
            localStorage.setItem('clinic', userData)
            localStorage.setItem('clinic-name', clinic.name)
            localStorage.setItem('clinic-affiliate', clinic.affiliate_at)
            localStorage.setItem(
              'clinic-sign-acceptance',
              clinic.sign_acceptances
            )
            localStorage.setItem(
              'clinic-suffix',
              JSON.stringify(clinic.suffix_sectionals)
            )
            this.$store.dispatch('reusableData/getClinicaSetup')
            this.$store.dispatch('reusableData/setPrefissoSezionale', {
              prefisso_sezionale: clinic.suffix_sectionals
            })
            router.push('/')

            //Numero Notifiche
            this.$store.dispatch('reusableData/getTotalBell')
          })
        })
        .catch(() => {
          router.push('/login')
        })
      // Assegna la clinica scelta
    }
  }
})
</script>
