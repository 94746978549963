<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-opacity-50 bg-black scrollbar-none"
  >
    <div
      class="relative top-12 mt-1 mx-auto px-3 pt-3 pb-7 border w-3/4 md:w-4/6 shadow-lg rounded-md bg-white overflow-y-scroll scrollbar-none"
    >
      <!-- Modal content -->
      <div class="flex flex-col content-end overflow-y-auto">
        <!-- Modal header -->
        <div class="mb-9">
          <button type="button" class="closing-btn" @click="$emit('close')">
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div class="m-2">
          <h3 class="modal-title">Nuovo Articolo</h3>
        </div>
        <div
          class="flex flex-col md:flex-row md:items-center justify-between gap-4 px-6 py-2"
        >
          <!-- <div
            v-if="pack"
            class="flex-1 grid grid-cols-8 gap-4 items-center justify-center content-center"
          >
            <input
              v-model="name"
              type="text"
              placeholder="Inserisci nome"
              class="custom-input col-span-8"
            />
            <span class="col-span-8">Prezzo finale desiderato:</span>
            <span class="col-span-1">Diurno</span>
            <input
              v-model="t1"
              type="number"
              placeholder="Diurno"
              class="custom-input col-span-1"
            />
            <span class="col-span-1">Notturno</span>
            <input
              v-model="t2"
              type="number"
              placeholder="Notturno"
              class="custom-input col-span-1"
            />
            <span class="col-span-1">Festivo</span>
            <input
              v-model="t3"
              type="number"
              placeholder="Festivo"
              class="custom-input col-span-1"
            />
            <span class="col-span-1">Assicurazione</span>
            <input
              v-model="t4"
              type="number"
              placeholder="Assicurazione"
              class="custom-input col-span-1"
            />
          </div> -->
          <div
            v-if="!pack"
            class="flex-1 grid grid-cols-8 gap-4 items-center justify-center content-center"
          >
            <input
              v-model="name"
              type="text"
              placeholder="Inserisci nome"
              class="custom-input col-span-8"
            />

            <span class="col-span-1">Sconto</span>
            <input
              v-model="sconto"
              type="number"
              placeholder="Inserisci sconto"
              class="custom-input col-span-1"
            />
            <div class="col-span-1"></div>
            <span class="col-span-1">Maggiorazione</span>
            <input
              v-model="maggiorazione"
              type="number"
              placeholder="Inserisci maggiorazione"
              class="custom-input col-span-1"
            />
          </div>
        </div>
        <!-- Modal footer -->
        <div class="flex justify-end items-center px-6 mt-5">
          <button
            type="button"
            class="orange-btn"
            @click="editData ? handleEdit() : handleConfirm()"
          >
            <PlusCircleIcon class="h-5 w-5 mr-2" />
            {{ editData ? 'Modifica' : 'Aggiungi' }}
          </button>

          <button class="ml-3 gray-btn" type="button" @click="$emit('close')">
            <span class="h-5"></span>
            Annulla
          </button>
        </div>
        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  props: ['title', 'pack'],
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    const store = useStore()
    const name = ref('')

    const ivaPerc = computed(
      () => store.getters['reusableData/getRegimeFiscaleIvaPerc']
    )

    const sconto = ref(0)
    const maggiorazione = ref(0)

    const t1 = ref(0)
    const t2 = ref(0)
    const t3 = ref(0)
    const t4 = ref(0)

    const handleConfirm = () => {
      if (name.value !== '') {
        emit(
          'confirm',
          name.value,
          t1.value / ivaPerc.value ?? 1,
          t2.value / ivaPerc.value ?? 1,
          t3.value / ivaPerc.value ?? 1,
          t4.value / ivaPerc.value ?? 1,
          sconto.value,
          maggiorazione.value
        )
      } else {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Inserire titolo pacchetto'
        })
      }
    }

    const handleEdit = () => {
      console.log('devo fare la edit')

      emit('close')
    }
    // ----- DATA -----
    return {
      name,
      sconto,
      maggiorazione,
      handleConfirm,
      t1,
      t2,
      t3,
      t4,
      ivaPerc,
      handleEdit
    }
  }
})
</script>
