<template>
  <!-- BEGIN: Responsive Table -->
  <div
    class="px-2 content-container bg-white overflow-auto scrollbar_custom rounded-lg"
  >
    <div class="px-2 vacc_table_cont overflow-auto">
      <table
        class="w-full shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
      >
        <THeader
          v-for="(item, itemKey) in tableData.config"
          :key="itemKey"
          :name="item.title"
          class="t-header"
        />
        <tbody>
          <tr
            v-for="(vaccine, index) in vaccinations"
            :key="vaccine.id"
            class="bg-white text-theme-43 text-center"
            :class="{ 'bg-theme-2': index % 2 === 0 }"
          >
            <td class="py-1.5 font-bold">
              {{ vaccine.name }}
            </td>
            <td class="py-1.5">
              {{ vaccine.lot }}
            </td>
            <td class="py-1.5">
              {{ $FormatDate(vaccine.expiry_date) }}
            </td>
            <td class="py-1.5">
              {{ vaccine.pet_type_name }}
            </td>
            <td class="py-1.5">
              <input
                type="checkbox"
                class="form-check-switch"
                :checked="vaccine.hidden"
                @click="(event) => setHidden(vaccine, event)"
              />
            </td>
            <td class="py-1.5">
              <button
                v-if="
                  permissions['pet'] &&
                  permissions['pet']?.delete_pet_vaccination?.enabled === true
                "
                class="px-2 py-1 hover:text-theme-41"
                @click="deleteVaccine(vaccine)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  class="h-4 fill-current"
                >
                  <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z"
                  />
                </svg>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="!showNewVaccino" class="text-right my-5">
      <button class="orange-btn px-2 py-1" @click="handleShowNewVaccino">
        <PlusIcon class="h-5 w-5 mr-2" /> Aggiungi nuovo vaccino
      </button>
    </div>
    <div v-if="showNewVaccino">
      <NewVaccinoModal @close="closeNewVaccino()" />
    </div>
  </div>
  <!-- END: Responsive Table -->
</template>
<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import THeader from '../../../components/cvit-table/THeader.vue'
import axios from '../../../gateway/backend-api'
import NewVaccinoModal from './NewVaccinoModal.vue'

export default defineComponent({
  components: {
    THeader,
    NewVaccinoModal
  },
  data() {
    return {
      showNewVaccino: false,
      vaccinations: [],
      tableData: {
        config: [
          {
            key: 'name',
            title: 'Nome'
          },
          {
            key: 'lotto',
            title: 'Lotto'
          },
          {
            key: 'exp_date',
            title: 'Data di scadenza'
          },
          {
            key: 'pet_type',
            title: 'Specie'
          },
          {
            key: 'hidden',
            title: 'Nascondi'
          },
          {
            key: 'button_delete'
          }
        ],
        input: [
          {
            key: 'name',
            title: 'Nome',
            type: 'text'
          },
          {
            key: 'lotto',
            title: 'Lotto',
            type: 'text'
          },
          {
            key: 'exp_date',
            title: 'Data di scadenza',
            type: 'date'
          },
          {
            key: 'pet_type',
            title: 'Specie',
            type: 'text'
          },
          {
            key: 'button_delete'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions'
    })
  },
  mounted() {
    // Restituisce i dati per comporre la tabella
    this.getVaccinations()
  },

  methods: {
    setHidden(vaccine, e) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      console.log(vaccine, e.target.checked)
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/vaccination/' +
            vaccine.id +
            '/editVaccine',
          {
            vaccine_id: vaccine.id,
            hidden: e.target.checked
          }
        )
        .then(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.getVaccinations()
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    handleShowNewVaccino() {
      this.showNewVaccino = !this.showNewVaccino
    },

    closeNewVaccino() {
      this.handleShowNewVaccino()
      this.getVaccinations()
    },
    getVaccinations() {
      axios
        .get('/' + localStorage.getItem('clinic') + '/vaccination')
        .then((resp) => {
          this.vaccinations = resp.data
        })
    },
    deleteVaccine(vaccine) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'Cancella specie',
        subtitle:
          'Sei sicuro di voler cancellare questo vaccino ' + vaccine.name + '?',
        background: false,
        confirm: () => {
          axios
            .post(
              '/' +
                localStorage.getItem('clinic') +
                '/vaccination/' +
                vaccine.id
            )
            .then((res) => {
              this.getVaccinations()
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message:
                  res.status === 200 ? 'Vaccino cancellato con successo' : res
              })
            })
        },
        decline: () => {
          return false
        }
      })
    }
  }
})
</script>
