<template>
  <!-- BEGIN: Documents-->
  <div>
    <div class="pos intro-y grid grid-cols-12 gap-5">
      <div class="intro-y col-span-12">
        <div class="post intro-y bg-white rounded-b-2xl">
          <div
            class="post__tabs nav nav-tabs flex-col sm:flex-row bg-theme-2 text-theme-42"
            role="tablist"
          >
            <button
              class="z-50 w-full sm:w-40 h-10 text-center flex justify-center items-center rounded-t-xl md:rounded-tr-xl text-white bg-theme-40"
            >
              <i class="fas fa-clock w-4 h-4 mr-2" />
              <span class="font-medium text-base">Registro ingressi </span>
            </button>
          </div>

          <div
            class="post__content tab-content bg-white rounded-b-lg overflow-auto scrollbar_custom anagrafcustomer-cont"
          >
            <div class="rounded-md p-5">
              <div class="box mt-1">
                <div id="responsive-table-list">
                  <div class="paginator-container">
                    <div class="prova mr-5 my-2 flex justify-between flex-1">
                      <div class="my-1">
                        <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
                          >Mostra
                        </span>

                        <select
                          id=""
                          name=""
                          class="form-select w-16 h-6 pr-2 pl-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
                          @change="(event) => changeNumPages(event)"
                        >
                          <option
                            v-for="show in forPageOption"
                            :key="show"
                            :value="show"
                          >
                            {{ show }}
                          </option>
                        </select>

                        <span class="text-md font-bold ml-2 mt-1">
                          elementi</span
                        >
                      </div>
                    </div>

                    <Paginator
                      :for-page-option="pagination.forPage"
                      :total-records="totalRecords"
                      @changePagination="(event) => changePagination(event)"
                    />
                  </div>

                  <!-- BEGIN: Data List -->
                  <div class="table-container bg-white scrollbar_custom pb-5">
                    <table class="w-full">
                      <THeader
                        v-for="(item, itemKey) in tableData"
                        :key="itemKey"
                        :name="item.title"
                        class="t-header h-10"
                      />
                      <tbody>
                        <tr>
                          <td
                            v-for="(elem, index) in tableData"
                            :key="index"
                            class="text-center px-1 py-3"
                          >
                            <select
                              v-if="elem.type === 'select'"
                              v-model="waiting_filter[elem.key]"
                              class="w-full border rounded-md h-7 text-gray-500 mt-2"
                            >
                              <option selected></option>
                              <option
                                v-for="(item, i) in elem.option"
                                :key="i"
                                :value="item"
                              >
                                {{ item.name ? item.name : item }}
                              </option>
                            </select>
                            <input
                              v-else
                              v-model="elem.value"
                              :type="elem.type"
                              min="0"
                              :name="elem.title"
                              class="form-control h-7 mt-2"
                              :class="{
                                invisible: elem.type === 'none',
                                'p-0': elem.type === 'date'
                              }"
                              :placeholder="elem.title"
                              @change="filterHandler(elem)"
                            />
                          </td>
                        </tr>
                        <tr
                          v-for="(item, i) in list"
                          :key="i"
                          class="text-base font-normal hover:bg-theme-44 cursor-pointer"
                          :class="{ 'bg-theme-2': i % 2 === 0 }"
                        >
                          <Cell
                            :elementi="item.medical ? 'Medico' : 'Non medico'"
                            class="text-center text-sm border-r-2 border-theme-46"
                          />
                          <Cell
                            :elementi="
                              item.staff_user_first_name &&
                              item.staff_user_last_name
                                ? item.staff_user_last_name +
                                  ' ' +
                                  item.staff_user_first_name
                                : ' '
                            "
                            class="text-center text-sm border-r-2 border-theme-46"
                          />
                          <Cell
                            :elementi="
                              item.customer_first_name &&
                              item.customer_last_name
                                ? item.customer_last_name +
                                  ' ' +
                                  item.customer_first_name
                                : ' '
                            "
                            class="text-center text-sm border-r-2 border-theme-46"
                          />
                          <Cell
                            :elementi="item.pet_name"
                            class="text-center text-sm border-r-2 border-theme-46"
                          />

                          <Cell
                            :elementi="item.reason"
                            class="text-center text-sm border-r-2 border-theme-46"
                          />
                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-default py-3"
                          >
                            {{ $FormatDateMin(item.arrival_time) }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-default py-3"
                          >
                            {{
                              item.clinic_time && item.clinic_time > 0
                                ? $FormatDateMin(item.clinic_time)
                                : ' '
                            }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-default py-3"
                          >
                            {{
                              item.exit_time && item.exit_time > 0
                                ? $FormatDateMin(item.exit_time)
                                : ' '
                            }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <!-- END: Data List -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Documents-->
</template>
<script>
import { defineComponent } from 'vue'
import axios from '../../gateway/backend-api'
import Paginator from '../../components/paginator/Main.vue'
import Cell from '../../components/cvit-table/Cell.vue'

import THeader from '../../components/cvit-table/THeader.vue'
const forPageOption = [5, 10, 50]
export default defineComponent({
  components: {
    Paginator,
    THeader,
    Cell
  },
  data() {
    return {
      totalRecords: '',
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      list: [],
      filterActive: false,
      waiting_filter: {},
      active_filters: {},
      tableData: [
        // {
        //   key: 'type',
        //   title: 'Tipo',
        //   type: 'select',
        //   option: [
        //     { name: 'Attesa', value: 'waiting' },
        //     { name: 'Clinica', value: 'clinic' },
        //     { name: 'Uscita', value: 'exit' }
        //   ]
        // },
        {
          key: 'medical',
          title: 'Tipologia',
          type: 'select',
          option: [
            { name: 'Medico', value: true },
            { name: 'Non Medico', value: false }
          ]
        },
        {
          key: 'doctor',
          title: 'Medico',
          type: 'text'
        },
        {
          key: 'customer',
          title: 'Nome completo / Rag. Soc.',
          type: 'text'
        },
        {
          key: 'pet',
          title: 'Paziente',
          type: 'text'
        },
        {
          key: 'reason',
          title: 'Motivo della visita',
          type: 'text'
        },
        {
          key: 'arrival_time',
          title: 'Orario arrivo',
          type: 'date'
        },
        {
          key: 'clinic_time',
          title: 'Orario ingresso in clinica',
          type: 'date'
        },
        {
          key: 'exit_time',
          title: 'Orario uscita',
          type: 'date'
        }
      ]
    }
  },
  watch: {
    'waiting_filter.medical': function (elem) {
      this.filterSelect(elem, 'medical')
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    changePagination: function (value) {
      if (this.pagination.page != value) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        this.getList()
      }
    },
    changeNumPages: function (elem) {
      if (this.pagination.forPage != elem.target.value) {
        this.pagination.forPage = elem.target.value
        this.getList()
      }
    },
    getList() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/waiting-room/list', {
          type: null, //TUTTE -> waiting,clinic,exit
          active_filters: this.active_filters,
          page: this.pagination.page,
          per_page: this.pagination.forPage
        })
        .then((res) => {
          this.list = res.data.data
          this.totalRecords = res.data.total
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    // Metodo generico per handler filtro select
    filterSelect(elem, field) {
      if (elem) this.active_filters[field] = elem.value
      else this.active_filters[field] = undefined

      this.checkIfFilterActive()

      if (this.filterActive) this.getList()
    },
    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()

      if (this.filterActive) this.getList()
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    }
  }
})
</script>
