<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-20 p-2 mx-auto border w-4/5 sm:w-3/5 shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->

      <button type="button" class="closing-btn mr-2" @click="$emit('close')">
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>

      <div class="flex flex-col mt-10">
        <!-- Modal header -->
        <div class="flex modal-title justify-center">
          <h3 v-if="staffUserSelected">Utente</h3>
          <h3 v-else>Aggiungi Utente</h3>
        </div>

        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div class="px-6 py-2 mt-2 grid grid-cols-6 gap-2">
          <div class="mb-2 col-span-6">
            <p
              v-if="err"
              class="text-theme-41 fill-current font-bold"
              v-html="err"
            ></p>
          </div>

          <div class="col-span-6 md:col-span-3 flex-col pb-2">
            <label class="form-label pr-3">Nome </label>
            <input
              v-model="first_name"
              type="text"
              class="custom-input capitalize"
              :disabled="
                permissions && permissions?.Staff?.edit_staff?.enabled === false
              "
            />
          </div>
          <div class="col-span-6 md:col-span-3 flex-col pb-2">
            <label class="form-label">Cognome </label>
            <input
              v-model="last_name"
              type="text"
              class="custom-input capitalize"
              :disabled="
                permissions && permissions?.Staff?.edit_staff?.enabled === false
              "
            />
          </div>

          <div class="col-span-6 md:col-span-3 lg:col-span-2 flex-col pb-2">
            <label class="form-label">Username </label>
            <input
              v-model="username"
              type="text"
              class="custom-input disabled"
              disabled
            />
          </div>
          <div class="col-span-6 md:col-span-3 lg:col-span-2 flex-col pb-2">
            <label class="form-label">Email </label>
            <input
              v-model="email"
              type="email"
              class="custom-input lowercase"
              :disabled="
                permissions && permissions?.Staff?.edit_staff?.enabled === false
              "
            />
          </div>
          <div class="col-span-6 lg:col-span-2 flex-col pb-2">
            <label class="form-label">Ruolo: </label>

            <v-select
              v-if="staff_user_roles"
              v-model="role"
              label="name"
              placeholder="Selezionare il ruolo"
              :options="staff_user_roles"
              :disabled="
                permissions && permissions?.Staff?.edit_staff?.enabled === false
              "
            />
          </div>
        </div>

        <div
          :class="[
            !staffUserSelected
              ? 'flex justify-end items-center px-6 mt-5'
              : 'flex justify-between items-center px-6 mt-5'
          ]"
        >
          <button
            v-if="!staffUserSelected"
            type="button"
            class="orange-btn"
            @click="save"
          >
            <PlusCircleIcon class="h-5 w-5 mr-2" /> Aggiungi
          </button>

          <button
            v-if="
              staffUserSelected &&
              permissions &&
              permissions?.Staff?.delete_staff?.enabled === true
            "
            type="button"
            class="gray-btn-inverted"
            @click="deleteUser"
          >
            <TrashIcon class="h-5 w-5" />
          </button>
          <div>
            <button
              v-if="
                staffUserSelected &&
                permissions &&
                permissions?.Staff?.edit_staff?.enabled === true
              "
              type="button"
              class="orange-btn"
              @click="edit"
            >
              <EditIcon class="h-5 w-5 mr-2" /> Modifica
            </button>
            <button class="ml-3 gray-btn" type="button" @click="$emit('close')">
              <span class="h-5"></span>
              Annulla
            </button>
          </div>
        </div>
        <!-- Modal footer -->
        <div
          class="border-t-1 border-gray-400 w-full px-6 text-right flex justify-end items-end mb-2"
        ></div>

        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { mapGetters } from 'vuex'
import axios from '../../gateway/backend-api'

export default defineComponent({
  components: {},
  props: {
    staffUserSelected: {
      type: Object,
      default() {
        return null
      }
    },
    err: {
      type: String,
      default() {
        return ''
      }
    }
  },
  emits: ['close', 'save', 'edit', 'deleteUser', 'refresh'],
  setup(props, { emit }) {
    const role = ref(null)
    const first_name = ref('')
    const last_name = ref('')
    const email = ref('')
    const staff_user_roles = ref(null)

    const username = computed(() => {
      return (
        first_name.value.split(' ')[0].toLowerCase() +
        '.' +
        last_name.value.replaceAll(/[^A-Z0-9]/gi, '').toLowerCase()
      )
    })

    const save = () => {
      if (
        first_name.value &&
        last_name.value &&
        username.value &&
        email.value &&
        role.value
      ) {
        var params = {
          first_name: first_name.value,
          last_name: last_name.value,
          email: email.value,
          username: username.value,
          role_id: role.value.id
        }
        emit('save', params)
      }
    }

    const edit = () => {
      if (
        first_name.value &&
        last_name.value &&
        username.value &&
        email.value &&
        role.value &&
        props.staffUserSelected
      ) {
        var params = {
          staff_user_id: props.staffUserSelected.id,
          first_name: first_name.value,
          last_name: last_name.value,
          email: email.value,
          username: username.value,
          role_id: role.value.id
        }
        emit('edit', params)
      }
    }

    const deleteUser = () => {
      if (props.staffUserSelected.id) {
        emit('deleteUser', props.staffUserSelected.id)
      }
    }

    axios
      .get('/' + localStorage.getItem('clinic') + '/staff/roles')
      .then((res) => {
        staff_user_roles.value = res.data
      })

    return {
      role,
      first_name,
      last_name,
      email,
      username,
      staff_user_roles,
      save,
      edit,
      deleteUser
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions'
    })
  },
  mounted() {
    if (this.staffUserSelected) {
      this.role = this.staffUserSelected.role
      this.first_name = this.staffUserSelected.first_name
      this.last_name = this.staffUserSelected.last_name
      this.email = this.staffUserSelected.email
      this.username = this.staffUserSelected.username
    }
  },

  methods: {}
})
</script>
