<template>
  <!-- Main modal -->
  <div
    id="defaultModal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-18 mt-10 mx-auto px-3 pt-3 pb-7 border w-3/4 md:w-4/6 shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->

      <div class="mb-9">
        <button type="button" class="closing-btn" @click="$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <!-- Modal header -->
      <div class="m-2">
        <h3 class="modal-title">{{ title ? title : 'Seleziona paziente' }}</h3>
      </div>
      <!-- Modal body -->
      <div class="flex justify-end items-center p-2">
        <label class="form-label font-medium text-base text-right"
          >Data:
        </label>
        <p class="form-label font-medium text-gray-600 ml-2">
          {{ now }}
        </p>
      </div>

      <div class="py-2 px-12">
        <div v-if="client || newClient" class="flex flex-col mb-1">
          <!-- SELEZIONARE UTENTE nuevo  -->
          <div class="flex flex-col md:flex-row gap-6">
            <!-- SELEZIONARE CLIENTE nuevo -->
            <div
              v-if="!selected"
              id="select-client
            "
              class="flex-1 p-3"
            >
              <label class="font-semibold text-theme-43">
                {{
                  newClient
                    ? ' SELEZIONARE UN CLIENTE'
                    : ' SELEZIONARE UN PAZIENTE'
                }}
              </label>
              <div class="mt-3 relative">
                <div class="">
                  <input
                    v-model="searchBarPet"
                    type="text"
                    class="custom-input pl-10 rounded-xl py-2 z-10"
                    placeholder="Ricerca rapida nell'anagrafica"
                    @click.stop="dropDownSearchPet"
                  />
                  <svg
                    class="text-theme-44 fill-current w-6 absolute top-1 left-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 54.3 54.4"
                    @click="toggleModal"
                  >
                    <path
                      d="M16.4 4.7c9.2-7 22.3-6.1 30.8 2.1 8.4 8.1 9.4 21.1 2.4 31.1-5.5 7.9-19.5 14.2-33.5 4.9-3.2 3.2-6.5 6.5-9.7 9.8-2 2-3.8 2.3-5.4.8-1.6-1.5-1.4-3.4.7-5.6.3-.4.7-.7 1.1-1.1 2.9-2.9 5.9-5.8 8.7-8.6-8.7-12.9-4.1-26.5 4.9-33.4Zm31.2 19c0-9.2-7.8-17-17.2-17-9.5 0-17 7.7-17 17.3 0 9.4 7.6 16.9 17.2 16.9 9.5 0 17-7.6 17.1-17.3Z"
                    />
                  </svg>
                </div>
                <SearchDrop
                  v-show="isActiveSearchPet === true"
                  class="z-50 search-result bg-theme-44 bg-opacity-80 overflow-scroll scrollbar-none rounded-xl absolute invisible opacity-0 mt-4 ml-3 py-4 px-3 right-0 w-4/5 top-6"
                  :filter-categories="newClient ? ['customer'] : ['pet']"
                  :customer-invoice="client"
                  :filter="searchBarPet"
                  @childToParent="onChildClickPet"
                  @closeDropDownSearch="closeDropDownSearchPet"
                />
              </div>
            </div>
            <div
              v-if="selected && !newClient"
              class="flex-1 px-1 py-2 border-2 border-theme-44 border-opacity-10 shadow-md rounded-md"
            >
              <div
                class="bg-theme-2 rounded-md p-1 flex justify-between items-center mb-2"
              >
                <h2 class="font-bold nunito text-lg pl-1">Dati Pet</h2>
                <a
                  class="cursor-pointer text-theme-41 fill-current"
                  @click="removeSelectedPet"
                >
                  <i class="fas fa-times text-center p-3" />
                </a>
              </div>
              <div class="flex flex-col ml-5">
                <div>
                  <label class="form-label font-medium text-base col-span-1"
                    >Cliente:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 ml-2 col-span-2"
                  >
                    {{ selected.name }}
                  </p>
                </div>
                <div>
                  <label class="form-label font-medium text-base col-span-1"
                    >Indirizzo:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 ml-2 col-span-2"
                  >
                    {{ selected.owners[0].address.address }}
                  </p>
                </div>
                <div>
                  <label class="form-label font-medium text-base col-span-1"
                    >Sesso:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 ml-2 col-span-2"
                  >
                    {{ selected.gender }}
                  </p>
                </div>
                <div>
                  <label class="form-label font-medium text-base col-span-1"
                    >Microchip:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 ml-2 col-span-2"
                  >
                    {{ selected.microchip }}
                  </p>
                </div>
              </div>
            </div>
            <div
              v-else-if="selected && newClient"
              class="flex-1 px-1 py-2 border-2 border-theme-44 border-opacity-10 shadow-md rounded-md"
            >
              <div
                class="bg-theme-2 rounded-md p-1 flex justify-between items-center mb-2"
              >
                <h2 class="font-bold nunito text-lg pl-1">Dati Proprietario</h2>
                <a
                  class="cursor-pointer text-theme-41 fill-current"
                  @click="removeSelectedPet"
                >
                  <i class="fas fa-times text-center p-3" />
                </a>
              </div>
              <div class="flex flex-col ml-5">
                <div>
                  <label class="form-label font-medium text-base col-span-1"
                    >Cliente:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 ml-2 col-span-2"
                  >
                    {{
                      selected.ragione_sociale ? selected.ragione_sociale : ''
                    }}
                    {{ selected.last_name ? selected.last_name : '' }}
                    {{ selected.first_name ? selected.first_name : '' }}
                  </p>
                </div>
                <div>
                  <label class="form-label font-medium text-base col-span-1"
                    >Tipologia:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 ml-2 col-span-2"
                  >
                    {{ selected.type }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="vets" class="flex-1">
            <label for="new-appointment-vet" class="font-medium form-label"
              >Veterinario</label
            >
            <div id="multiple-select-vet" class="py-2">
              <div class="">
                <v-select
                  v-model="selectedVet"
                  :options="vets"
                  :reduce="
                    (value) => ({
                      id: value.id,
                      full_name: value.full_name
                    })
                  "
                  multiple
                  label="last_name"
                  placeholder="Seleziona veterinario"
                >
                  <template #selected-option="option">
                    {{ option.full_name }}
                  </template>
                  <template #option="option">
                    {{ option.full_name }}
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal footer -->

      <div class="px-6 flex items-center justify-end mt-3">
        <button
          ref="input4"
          type="button"
          class="orange-btn mr-3"
          @click="handleConfirm()"
        >
          <PlusCircleIcon class="h-5 w-5 mr-2" />
          Salva
        </button>
        <button type="button" class="gray-btn" @click="$emit('close')">
          <span class="h-5"></span>
          Annulla
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStore } from '@/store'
import axios from '../../gateway/backend-api'
import moment from 'moment'
import SearchDrop from '../../components/search-drop/SearchDrop.vue'

export default defineComponent({
  components: { SearchDrop },
  props: [
    'newDrugs',
    'pet',
    'client',
    'petInvoice',
    'title',
    'vetsSelected',
    'newClient'
  ],

  emits: [
    'addDrug',
    'removeDrug',
    'editDrug',
    'Drug',
    'close',
    'emitDrug',
    'selectClientPet',
    'selectPatient',
    'selectVet',
    'selectCustomer'
  ],
  setup(props, { emit }) {
    const store = useStore()
    const searchBarPet = ref('')
    const selected = ref('')
    const vets = ref(null)
    const selectedVet = ref(props.vetsSelected ?? null)

    const isActiveSearchPet = ref(false)

    const now = moment().format('D/M/YYYY')

    const allegatiConfig = [
      'Aic',
      'Farmaco / Tipo',
      'Confezione',
      'Qty',
      'Posologia',
      'Durata Tratt. (gg)'
    ]

    if (!props.client) {
      axios
        .post('/' + localStorage.getItem('clinic') + '/staff', {
          roles: [3, 2]
        })
        .then((res) => {
          vets.value = res.data
        })
    }

    const formatdate = (epoch) => {
      return moment.unix(epoch).format('DD/MM/YYYY')
    }

    const dropDownSearchPet = () => {
      isActiveSearchPet.value = true
    }
    const closeDropDownSearchPet = () => {
      isActiveSearchPet.value = false
    }

    const onChildClickPet = (value) => {
      emit('selectClientPet', 'petInvoice', value)
      selected.value = value
      closeDropDownSearchPet()
    }

    const removeSelectedPet = () => {
      emit('selectClientPet', 'petInvoice', null)
      selected.value = null
      searchBarPet.value = ''
    }

    const handleConfirm = () => {
      if (!selected.value && !selectedVet.value) {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di selezionare prima di continuare'
        })
        return
      }
      if (props.client) {
        emit('selectPatient', selected.value)
      } else {
        if (props.newClient) emit('selectCustomer', selected.value)
        else emit('selectVet', selectedVet.value)
      }
    }

    // const selected = computed(() => {
    //   return props.petInvoice
    // })

    return {
      allegatiConfig,
      searchBarPet,
      isActiveSearchPet,
      formatdate,
      now,
      dropDownSearchPet,
      closeDropDownSearchPet,
      onChildClickPet,
      removeSelectedPet,
      selected,
      vets,
      selectedVet,
      handleConfirm
    }
  },
  watch: {
    isActiveSearchPet: function (elem) {
      if (!elem) this.searchBarPet = ''
    }
  }
  // mounted() {
  //   document.addEventListener('click', this.closeDropDownSearchPet)
  // }
})
</script>
