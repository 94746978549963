<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black scrollbar-none"
    :class="
      background && background === false ? 'bg-opacity-0' : 'bg-opacity-50'
    "
  >
    <div
      class="w-4/5 sm:w-4/5 relative top-12 p-5 mx-auto overflow-scroll scrollbar-none border shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="flex flex-col content-end overflow-y-auto">
        <button type="button" class="closing-btn" @click="this.$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <!-- Modal header -->

        <div class="w-full">
          <h3 class="modal-title mt-8">{{ title }}</h3>
        </div>

        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div class="px-6 py-3">
          <div class="flex flex-col mb-1 text-center text-xl">
            <table
              class="w-full shadow overflow-scroll scrollbar-none h-12 sm:rounded-lg"
            >
              <thead class="border border-theme-40">
                <tr v-if="header">
                  <th
                    v-for="h in header"
                    :key="h"
                    class="t-header uppercase text-sm px-2"
                  >
                    {{ h }}
                  </th>
                </tr>
              </thead>
              <tbody v-if="data" class="rounded-md">
                <tr
                  v-for="(d, key) in data"
                  :key="d"
                  class="text-center text-sm border-r-2 border-theme-46 p-3 cursor-pointer"
                  :class="{ 'bg-theme-2': key % 2 === 0 }"
                >
                  <td
                    v-for="(e, index) in d"
                    :key="e"
                    class="text-center text-sm border-r-2 border-theme-46 p-3 cursor-pointer"
                  >
                    {{ e }} {{ append && append[index] ? append[index] : '' }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <span>Errore</span>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Modal footer -->

        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: ['title', 'header', 'data', 'append'],
  components: {},
  setup() {
    // ----- DATA -----

    // ----- METHODS -----

    // ----- COMPUTED -----

    return {}
  }
})
</script>
