<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black scrollbar-none bg-opacity-50"
  >
    <div
      class="w-5/5 sm:w-2/5 relative top-20 p-5 mx-auto overflow-scroll scrollbar-none border shadow-lg rounded-md bg-white"
    >
      <button type="button" class="closing-btn" @click="$emit('close')">
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>

      <!-- Modal content -->
      <div class="flex flex-col content-end overflow-y-auto">
        <!-- Modal header -->

        <div class="w-full">
          <h3 class="modal-title mt-8">Firma</h3>
        </div>

        <!-- Fine Modal header -->

        <!-- Modal body -->

        <div class="flex items-center justify-center gap-3 p-2">
          <vue-drawing-canvas
            ref="VueCanvasDrawing"
            v-model:image="signatureData"
            width="500"
            height="200"
            line-width="1"
            :styles="{
              border: 'solid 1px #DEDFE0',
              borderRadius: '5px',
              boxShadow:
                '0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.1)'
            }"
          />
        </div>

        <!-- Modal footer -->

        <div class="flex items-center gap-2 justify-end px-2">
          <button
            @click="$emit('confirm', signatureData)"
            type="button"
            class="orange-btn"
          >
            Firma
          </button>
          <button @click="$emit('decline')" type="button" class="gray-btn">
            Annulla
          </button>
        </div>

        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import VueDrawingCanvas from 'vue-drawing-canvas'

export default defineComponent({
  components: { VueDrawingCanvas },
  props: [],
  emits: ['confirm', 'decline'],

  setup() {
    // ----- DATA -----

    // ----- METHODS -----

    // ----- COMPUTED -----

    return {}
  }
})
</script>
