<template>
  <div
    class="p-5 text-start grid grid-cols-2 gap-5 px-6 overflow-auto scrollbar_custom bg-white tariff-box"
    style="align-content: flex-start"
  >
    <div class="paginator-container col-span-2">
      <div class="mr-5 my-2 flex justify-between">
        <div class="mt-1">
          <span class="flex-row text-md ml-2 font-bold mr-2 mt-1">Mostra </span>

          <select
            class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
            @change="(event) => changeNumPages(event)"
          >
            <option v-for="show in forPageOption" :key="show" :value="show">
              {{ show }}
            </option>
          </select>
          <span class="text-md font-bold ml-2 mt-1"> elementi</span>
        </div>
      </div>
      <Paginator
        :for-page-option="pagination.forPage"
        :total-records="totalRecords"
        @changePagination="(event) => changePagination(event)"
      />
    </div>

    <div class="flex flex-col w-full justify-start col-span-2">
      <span class="text-md ml-2 font-bold mr-2 mt-1"
        >Periodo di riferimento
      </span>
      <div class="flex gap-2 text-left">
        <div class="flex items-baseline">
          <span class="mr-2 font-bold">Da: </span
          ><input
            v-model="startDate"
            class="custom-input"
            type="date"
            @change="getData"
          />
        </div>
        <div class="flex items-baseline">
          <span class="mr-4 font-bold">A: </span
          ><input
            v-model="endDate"
            class="custom-input"
            type="date"
            @change="getData"
          />
        </div>
      </div>
    </div>

    <!-- BEGIN: Data List -->
    <div class="table-container overflow-scroll scrollbar-none pb-3 col-span-2">
      <table v-if="tableConfig_doc && docs" class="w-full">
        <THeader
          v-for="(item, itemKey) in tableConfig_doc"
          :key="itemKey"
          :name="item.title"
          class="t-header h-10"
        />
        <tbody class="mt-5">
          <tr class="">
            <td
              v-for="(elem, index) in tableConfig_doc"
              :key="index"
              class="text-center px-1 py-3"
            >
              <select
                v-if="elem.type === 'select'"
                v-model="elem.value"
                class="w-full border rounded-md h-7 text-gray-500"
                @change="filterHandler(elem)"
              >
                <option selected></option>
                <option v-for="(item, i) in elem.option" :key="i" :value="item">
                  {{ item.name ? item.name : item }}
                </option>
              </select>
              <input
                v-else
                v-model="elem.value"
                :type="elem.type"
                min="0"
                :name="elem.title"
                class="form-control h-7"
                :class="{
                  invisible: elem.type === 'none',
                  'p-0': elem.type === 'date'
                }"
                :placeholder="elem.title"
                @change="filterHandler(elem)"
              />
            </td>
          </tr>
          <tr
            v-for="(doc, docKey) in docs"
            :key="docKey"
            class="h-12 text-xs font-normal hover:bg-theme-45"
            :class="{ 'bg-theme-2': docKey % 2 === 0 }"
          >
            <td class="text-center text-sm border-r-2 border-theme-46">
              {{ doc.staff }}
            </td>
            <td class="text-center text-sm border-r-2 border-theme-46">
              {{ doc.tot_prestazioni_eseguite }}
            </td>
            <td class="text-center text-sm border-r-2 border-theme-46">
              {{ doc.tot_netto }}
            </td>
            <td class="text-center text-sm border-r-2 border-theme-46">
              {{ doc.tot_ivato }}
            </td>
            <td class="text-center text-sm border-r-2 border-theme-46">
              {{ doc.percentuale }}
            </td>
            <td class="text-center text-sm border-r-2 border-theme-46">
              {{ doc.tot_ivato_in_perc }} €
            </td>

            <td class="text-center text-sm">
              <a class="my-2 cursor-pointer" @click="handleInfo(doc)">
                <i class="fas fa-info cursor-pointer"></i>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="docs && totalRecords === 0"
        class="w-full flex justify-center mt-2"
      >
        Nessun Risultato
      </div>
    </div>
  </div>
  <!-- END: Data List -->
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { useStore } from '@/store'
import moment from 'moment'
import Paginator from '../../components/paginator/Main'
import THeader from '../../components/cvit-table/THeader.vue'
import axios from '../../gateway/backend-api'

export default defineComponent({
  components: {
    Paginator,
    THeader
  },
  props: [],
  setup() {
    const store = useStore()
    const forPageOption = [10, 20, 50]
    const tableConfig_doc = ref([
      {
        key: 'staff_user',
        title: 'Utente',
        type: 'string'
      },

      {
        key: 'tot_prest',
        title: 'Totale prestazioni',
        type: 'number'
      },
      {
        key: 'tot_n',
        title: 'Totale netto',
        type: 'number'
      },
      {
        key: 'tot_i',
        title: 'Totale ivato',
        type: 'number'
      },
      {
        key: 'percentage',
        title: 'Percentuale',
        type: 'number'
      },
      {
        key: 'tot_i_p',
        title: 'Totale ivato in percentuale',
        type: 'number'
      },

      {
        key: 'actions',
        title: 'Azioni rapide',
        type: 'none'
      }
    ])

    const pagination = ref({
      page: 1,
      forPage: forPageOption[0]
    })
    const totalRecords = ref(0)
    const docs = ref([])

    const active_filters = ref({})
    const filterActive = ref(false)

    const startDate = ref(null)
    const endDate = ref(null)

    const getData = () => {
      // tariffari
      store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/report/billing-staff', {
          startDate: moment.utc(startDate.value, 'YYYY-MM-DD').unix(),
          endDate: moment.utc(endDate.value, 'YYYY-MM-DD').unix(),
          active_filters: active_filters.value,
          per_page: pagination.value.forPage,
          page: pagination.value.page
        })
        .then((res) => {
          store.dispatch('commonComponents/hideLoadingModalAction')
          if (res.data) {
            docs.value = res.data.data
            totalRecords.value = res.data.total
          }
        })
        .catch(() => {
          store.dispatch('commonComponents/hideLoadingModalAction')
        })
    }

    const handleInfo = (row) => {
      let table_modal = []
      if (row) {
        row.dettaglio_prestazioni.forEach((element) => {
          let tmp = []
          Object.keys(element).forEach((el) => {
            tmp.push(element[el])
          })
          table_modal.push(tmp)
        })
      }
      store.dispatch('commonComponents/openTableModal', {
        tableData: {
          close: () => store.dispatch('commonComponents/closeTableModal'),
          title: row.staff,
          header: [
            'Quantità',
            'Nome',
            'Percentuale',
            'Pr. ivato',
            'Pr. netto',
            'Pr. unitario',
            'Sconto/magg',
            'Pr. ivato %',
            'Pr. ivato con sconto'
          ],
          append: ['', '', '%', '€', '€', '€', '', '€', '€'],
          data: table_modal
        }
      })
    }

    //  cambio elementi per pagina, e ritorno nella prima pagina
    const changePagination = (value) => {
      if (pagination.value.page != value.page) {
        pagination.value.page = value.page
        pagination.value.forPage = value.forPage
        getData()
      }
    }

    // metodo che cambia il numero della pagina
    const changeNumPages = (elem) => {
      if (pagination.value.forPage != elem.target.value) {
        pagination.value.page = 1
        pagination.value.forPage = elem.target.value
        getData()
      }
    }

    // ----- COMPUTED ----- //

    // ----- FINE COMPUTED ----- //

    onMounted(() => {
      let a = new Date()
      let b = new Date(a.getFullYear(), a.getMonth(), 1)

      startDate.value = moment(b).format('YYYY-MM-DD')
      endDate.value = moment(a).format('YYYY-MM-DD')
      getData()
    })

    return {
      tableConfig_doc,
      pagination,
      totalRecords,
      forPageOption,
      docs,
      changePagination,
      changeNumPages,
      active_filters,
      filterActive,
      getData,
      handleInfo,
      startDate,
      endDate
    }
  },
  methods: {
    // ----- METODI GESTIONE FILTRI ----- //
    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()

      if (this.filterActive) this.getData()
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    }
    // ----- FINE METODI GESTIONE FILTRI ----- //
  }
})
</script>
