<template>
  <div v-if="true">
    <div class="p-5 text-center bg-white rounded-lg">
      <div
        v-if="articoli"
        class="px-2 articoli_table_cont overflow-auto scrollbar_custom"
      >
        <ArticoliMovimentazioniTable
          :pagination="pagination"
          :for-page-option="forPageOption"
          :docs="articoli"
          :table-config-clinic-doc="magazzinoTableConfig"
          :total="num_articoli"
          :movimentazione="true"
          @change-pagination="changePagination"
          @update-filtri="updateFiltri"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch } from 'vue'
import { useStore } from 'vuex'
import axios from '../../gateway/backend-api'

import ArticoliMovimentazioniTable from '../../components/tables/ArticoliMovimentazioniTable.vue'

export default defineComponent({
  components: {
    ArticoliMovimentazioniTable
  },

  setup() {
    const store = useStore()
    const forPageOption = ref([10, 20, 50])

    const articoli = ref(null)
    const num_articoli = ref(null)
    const pagination = ref({
      page: 1,
      forPage: forPageOption.value[0]
    })

    const activeFilters = ref({})

    const magazzinoTableConfig = [
      {
        key: 'expiration_date',
        title: 'Data scadenza',
        type: 'date'
      },
      {
        key: 'category',
        title: 'Categoria',
        type: 'string'
      },

      {
        key: 'name',
        title: 'Nome Prodotto',
        type: 'string'
      },
      {
        key: 'lotto',
        title: 'Lotto',
        type: 'string'
      },

      {
        key: 'load_unload_quantity',
        title: 'Carico/Scarico',
        type: 'string'
      },
      {
        key: 'purchase_price',
        title: 'Prezzo acquisto',
        type: 'number'
      },
      {
        key: 'staff_users',
        title: 'Eseguito da',
        type: 'string'
      }
    ]

    watch(pagination, () => {
      getNomenclatoreMagazzino(null, pagination)
    })

    const changePagination = () => {
      getNomenclatoreMagazzino()
    }

    const updateFiltri = (filtri) => {
      activeFilters.value = filtri
      getNomenclatoreMagazzino()
    }

    const getNomenclatoreMagazzino = () => {
      store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/warehouse/lista-load', {
          page: pagination.value.page,
          per_page: pagination.value.forPage,
          active_filters: activeFilters.value
        })
        .then((res) => {
          store.dispatch('commonComponents/hideLoadingModalAction')
          articoli.value = res.data.data
          num_articoli.value = res.data.total
        })
        .catch(() => {
          store.dispatch('commonComponents/hideLoadingModalAction')
        })
    }

    return {
      articoli,
      num_articoli,
      magazzinoTableConfig,
      activeFilters,
      pagination,
      forPageOption,
      getNomenclatoreMagazzino,
      changePagination,
      updateFiltri
    }
  },
  mounted() {
    this.getNomenclatoreMagazzino()
  }
})
</script>
