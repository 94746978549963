import router from '@/router'

function goToPet(elem) {
  if (
    //this.getPetsDetails.length < 4 &&
    !this.getComparisonPets.includes(elem.id)
  ) {
    this.getPetAction(elem)
    this.searchBar = ''
    router.push({ name: 'side-menu-anagrafica-anagrafica-pazienti' })
    this.isActiveSearch = false
  } else if (this.getComparisonPets.includes(elem.id)) {
    this.tabAlreadyOpen()
    router.push({ name: 'side-menu-anagrafica-anagrafica-pazienti' })
    this.isActiveSearch = false
    this.searchBar = ''
  } else {
    this.maxTabOpen()
    router.push({ name: 'side-menu-anagrafica-anagrafica-pazienti' })
    this.isActiveSearch = false
    this.searchBar = ''
  }
}

function goToCustomer(elem) {
  if (
    this.getCustomersDetails &&
    //this.getCustomersDetails.length < 7 &&
    !this.getComparisonCustomer.includes(elem.id)
  ) {
    router.push({ name: 'side-menu-anagrafica-anagrafica-clienti' })
    this.getCustomerAction(elem)
    this.searchBar = ''
    this.isActiveSearch = false
  } else if (this.getComparisonCustomer.includes(elem.id)) {
    this.tabAlreadyOpen()
    router.push({ name: 'side-menu-anagrafica-anagrafica-clienti' })
    this.isActiveSearch = false
    this.searchBar = ''
  } else {
    this.maxTabOpen()
    router.push({ name: 'side-menu-anagrafica-anagrafica-clienti' })
    this.isActiveSearch = false
    this.searchBar = ''
  }
}

const install = (app) => {
  app.config.globalProperties.$goToPet = goToPet
  app.config.globalProperties.$goToCustomer = goToCustomer
}

export { install as default }
