<template>
  <EmailSendedTableVue />
</template>

<script>
import { defineComponent } from 'vue'
import EmailSendedTableVue from '../../components/invoice-table/EmailSendedTable.vue'

export default defineComponent({
  components: {
    EmailSendedTableVue
  },
  props: [],
  emits: [],

  setup() {
    return {}
  }
})
</script>
