<template>
  <addWaitingListModal
    v-if="showAddWaitingList"
    title=" Aggiungi paziente alla lista d'attesa"
    @close="closeModal"
    @specialisticSelected="handleAddPatient"
  />
  <div
    v-if="
      permissions &&
      permissions['sala d attesa'] &&
      permissions['sala d attesa'].view_waiting_room?.enabled === true
    "
    class="bg-white rounded-md content-container overflow-auto scrollbar_custom relative p-3"
  >
    <div
      v-if="
        permissions &&
        permissions['sala d attesa'] &&
        permissions['sala d attesa'].store_waiting_room?.enabled === true
      "
      class="flex justify-end pt-1 pr-4"
    >
      <button class="orange-btn" @click="openModal">
        <i class="fas fa-sign-in-alt mr-2" />Check-in
      </button>
    </div>

    <SalaAttesaTable
      v-if="waiting.waiting.inWaiting && tableConfigWaiting"
      :table-config-clinic-doc="tableConfigWaiting"
      :documents="waiting.waiting.inWaiting"
      :for-page-option="waiting.forPageOption"
      :pagination="waiting.waiting.pagination"
      :total-record="waiting.waiting.total"
      title="Clienti in sala d'attesa"
      button-text="Ammetti in clinica"
      @update="getHospitalizations"
      @changePagination="getHospitalizationType('waiting')"
      @go-to-pet="handleGoToPet"
      @go-to-customer="handleGoToCustomer"
      @filterHandler="(el) => filterHandler('waiting', el)"
    />
    <SalaAttesaTable
      v-if="waiting.clinic.inClinic && tableConfig"
      :table-config-clinic-doc="tableConfig"
      :documents="waiting.clinic.inClinic"
      :for-page-option="waiting.forPageOption"
      :pagination="waiting.clinic.pagination"
      :total-record="waiting.clinic.total"
      title="Clienti in clinica"
      button-text="Dimetti"
      @update="getHospitalizations"
      @changePagination="getHospitalizationType('clinic')"
      @go-to-pet="handleGoToPet"
      @go-to-customer="handleGoToCustomer"
      @filterHandler="(el) => filterHandler('clinic', el)"
    />
  </div>
  <div
    v-else-if="
      permissions &&
      permissions['sala d attesa'] &&
      permissions['sala d attesa'].view_waiting_room?.enabled === false
    "
  >
    <p>I permessi per visualizzare la sala clinica non sono abilitati</p>
  </div>
</template>

<script>
const forPageOption = [5, 10, 20, 50]

import { defineComponent } from 'vue'
import Toastify from 'toastify-js'
import { mapActions, mapGetters } from 'vuex'
import SalaAttesaTable from '../../components/tables/SalaAttesaTable.vue'
import addWaitingListModal from '../../components/modals/addWaitingListModal.vue'
import axios from '../../gateway/backend-api'
import router from '@/router'
export default defineComponent({
  components: {
    SalaAttesaTable,
    addWaitingListModal
  },
  data() {
    return {
      waiting: {
        forPageOption: forPageOption,
        waiting: {
          inWaiting: [],
          filterActive: false,
          table_filter: {},
          active_filters: {},
          pagination: {
            page: 1,
            forPage: forPageOption[0]
          },
          total: 0
        },
        clinic: {
          inClinic: [],
          filterActive: false,
          table_filter: {},
          active_filters: {},
          pagination: {
            page: 1,
            forPage: forPageOption[0]
          },
          total: 0
        }
      },
      tableConfigWaiting: [
        {
          key: 'code',
          title: 'Codice',
          type: 'none'
        },
        {
          key: 'medical',
          title: 'Tipologia',
          type: 'select',
          option: [
            { name: 'Medico', value: true },
            { name: 'Non Medico', value: false }
          ]
        },
        {
          key: 'arrival_time',
          title: 'Orario ingresso',
          type: 'date'
        },
        {
          key: 'waiting_for',
          title: 'In attesa da (min)',
          type: 'none'
        },
        {
          key: 'doctor',
          title: 'Medico assegnato',
          type: 'text'
        },
        {
          key: 'customer',
          title: 'Nome completo / Rag. Soc.',
          type: 'text'
        },
        {
          key: 'pet',
          title: 'Paziente',
          type: 'text'
        },
        {
          key: 'reason',
          title: 'Motivo',
          type: 'text'
        },
        {
          key: 'button_clinic',
          title: 'Ammetti',
          type: 'none',
          style: 'display: none'
        },
        {
          key: 'button_exit',
          title: 'Uscita',
          type: 'none',
          style: 'display: none'
        }
      ],
      tableConfig: [
        {
          key: 'code',
          title: 'Codice',
          type: 'none'
        },
        {
          key: 'medical',
          title: 'Tipologia',
          type: 'select',
          option: [
            { name: 'Medico', value: true },
            { name: 'Non Medico', value: false }
          ]
        },
        {
          key: 'arrival_time',
          title: 'Orario ingresso',
          type: 'date'
        },
        {
          key: 'waiting_for',
          title: 'Entrato da (min)',
          type: 'none'
        },
        {
          key: 'doctor',
          title: 'Medico assegnato',
          type: 'text'
        },
        {
          key: 'customer',
          title: 'Nome completo / Rag. Soc.',
          type: 'text'
        },
        {
          key: 'pet',
          title: 'Paziente',
          type: 'text'
        },
        {
          key: 'reason',
          title: 'Motivo',
          type: 'text'
        },
        {
          key: 'room',
          title: 'Stanza',
          type: 'text'
        },
        {
          key: 'button_clinic',
          title: 'Sala attesa',
          type: 'none',
          style: 'display: none'
        },
        {
          key: 'button_exit',
          title: 'Uscita',
          type: 'none',
          style: 'display: none'
        }
      ],
      showAddWaitingList: false
    }
  },
  computed: {
    ...mapGetters({
      getPetsDetails: 'anagraficaPazienti/getPetsDetails',
      getComparisonPets: 'anagraficaPazienti/getComparisonPets',
      getCustomersDetails: 'anagraficaPazienti/getCustomersDetails',
      getComparisonCustomer: 'anagraficaPazienti/getComparisonCustomer',
      getTab: 'anagraficaPazienti/getTab',
      getShow: 'anagraficaPazienti/getShow',
      permissions: 'reusableData/getPermissions'
    })
  },
  watch: {
    'waiting.waiting.pagination.page': function (elem) {
      if (elem) this.getHospitalizationType('waiting')
    },
    'waiting.waiting.pagination.forPage': function (elem) {
      if (elem) this.getHospitalizationType('waiting')
    },
    'waiting.clinic.pagination.page': function (elem) {
      if (elem) this.getHospitalizationType('clinic')
    },
    'waiting.clinic.pagination.forPage': function (elem) {
      if (elem) this.getHospitalizationType('clinic')
    }
  },
  mounted() {
    this.getHospitalizations()
  },
  methods: {
    ...mapActions({
      getPetAction: 'anagraficaPazienti/getPetAction',
      getCustomerAction: 'anagraficaClienti/getCustomerAction',
      goToOpenedClient: 'anagraficaClienti/goToOpenedClient'
    }),
    handleGoToCustomer: function (id) {
      axios
        .get('/' + localStorage.getItem('clinic') + '/customer/' + id)
        .then((res) => {
          this.getCustomerAction(res.data)
          this.goToOpenedClient(res.data.id)
          router.push({ name: 'side-menu-anagrafica-anagrafica-clienti' })
        })
    },
    handleGoToPet: function (id) {
      axios
        .get('/' + localStorage.getItem('clinic') + '/pet/' + id)
        .then((res) => {
          const pet = res.data

          if (
            this.getPetsDetails.length < 4 &&
            !this.getComparisonPets.includes(pet.id)
          ) {
            this.getPetAction(res.data)
            router.push({ name: 'side-menu-anagrafica-anagrafica-pazienti' })
          } else if (this.getComparisonPets.includes(pet.id)) {
            this.tabAlreadyOpen()
            router.push({ name: 'side-menu-anagrafica-anagrafica-pazienti' })
          } else {
            this.maxTabOpen()
            router.push({ name: 'side-menu-anagrafica-anagrafica-pazienti' })
          }
        })
    },

    openModal: function () {
      this.showAddWaitingList = true
    },
    closeModal: function () {
      this.getHospitalizationType('waiting')
      this.showAddWaitingList = false
    },
    handleAddPatient: function () {
      this.showAddWaitingList = false
    },
    getHospitalizationType(type) {
      // Type vale o waiting o clinic

      axios
        .post('/' + localStorage.getItem('clinic') + '/waiting-room/list', {
          type: type,
          active_filters: this.waiting[type].active_filters,
          page: this.waiting[type].pagination.page,
          per_page: this.waiting[type].pagination.forPage
        })
        .then((res) => {
          if (type == 'waiting') {
            this.waiting.waiting.inWaiting = res.data.data
          } else {
            this.waiting.clinic.inClinic = res.data.data
          }
          this.waiting[type].total = res.data.total
        })
    },
    getHospitalizations() {
      console.log('hola!')
      this.getHospitalizationType('waiting')
      this.getHospitalizationType('clinic')
    },
    // funzione che fa apparire pop-up quando provi ad aprire una tab già aperta
    tabAlreadyOpen() {
      Toastify({
        node: cash('#tab-already-open').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    },
    filterHandler(type, elem) {
      this.waiting[type].active_filters = elem

      this.checkIfFilterActive(type)

      if (this.waiting[type].filterActive) this.getHospitalizationType(type)
    },
    checkIfFilterActive(type) {
      var count = 0
      Object.entries(this.waiting[type].active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.waiting[type].filterActive = true
      else this.waiting[type].filterActive = false
    }
  }
})
</script>
