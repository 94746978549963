<template>
  <div class="flex items-center py-1">
    <!-- slide text and img -->
    <div
      v-if="
        layoutType && (layoutType === 'img_txt' || layoutType === 'txt_img')
      "
      class="shadow-lg bg-white rounded-lg card-cont flex items-center mx-1 md:mx-10 px-3"
    >
      <div class="flex items-center gap-3 py-1">
        <img :src="imgSrc" class="carousel-img flex-0 hidden lg:block" />

        <div class="flex-1 flex flex-col px-1">
          <div class="flex flex-col md:flex-row gap-4 items-center w-full">
            <img :src="imgSrc" class="carousel-img block lg:hidden" />
            <div>
              <div class="flex items-center justify-between">
                <div
                  v-if="occhiello"
                  class="text-xs uppercase nunito text-theme-40 font-semibold text-left col-span-3 px-1 pt-0.5 pb-1"
                >
                  {{ occhiello }}
                </div>
                <div class="text-gray-500 py-1 text-xs text-left">
                  {{ data }}
                </div>
              </div>

              <div v-if="link">
                <a
                  class="font-bold text-theme-40 hover:text-theme-41"
                  target="_blank"
                  :href="link"
                  ><div
                    class="capitalize text-sm xl:text-base font-bold text-center nunito px-1 py-0.5"
                  >
                    {{ title }}
                  </div>
                </a>
              </div>

              <div
                v-else
                class="capitalize text-sm xl:text-base font-bold text-center nunito px-1 py-0.5"
              >
                {{ title }}
              </div>
            </div>
          </div>

          <div class="text-gray-600 text-justify py-1 text-sm">
            <span v-html="content"></span>
            <span class="text-xxs font-medium">{{ font }} </span>
          </div>
        </div>
      </div>
    </div>
    <!-- {{ content.length > 300 ? content.slice(0, 300) + '...' : content }} -->
    <!-- slide only img -->

    <div v-if="layoutType && layoutType === 'img'" class="nota p-24">
      <img
        :src="require(`@/assets/images/${imgSrc}`)"
        alt=""
        class="rounded-lg shadow-md"
      />
    </div>

    <!-- slide only text -->

    <div
      v-if="layoutType && layoutType === 'txt'"
      class="nota flex flex-col justify-center px-10 border border-theme-44 border-opacity-30 shadow-md"
    >
      <div v-if="occhiello" class="text-sm uppercase font-medium">
        {{ occhiello }}
      </div>
      <div class="capitalize mb-2 text-base font-medium py-1">
        {{ title }}
      </div>
      <div class="text-gray-500 mt-2 py-1">{{ data }}</div>
      <div class="text-gray-600 text-justify mt-3 py-1">
        {{ content }}
        <span class="text-xxs font-medium">{{ font }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    'occhiello',
    'title',
    'imgSrc',
    'data',
    'content',
    'layoutType',
    'link',
    'font'
  ]
}
</script>

<style>
.notes-container {
  width: 100%;
}
.slide-animation {
  transition: transform 0.4s ease-in-out;
}

.carousel-img {
  width: 15%;
}

.card-cont {
  height: 11rem;
}

/* .card {
  @apply bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20;
} */

/* @media only screen and (max-width: 767px) {
  .carousel-img {
    width: 40%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1534px) {
  .carousel-img {
    width: 25%;
  }
} */

/* @media only screen and (max-width: 1024px) {
  .card-cont {
    width: 70vw;
    height: auto;
  }
} */

.carousel__prev,
.carousel__next {
  color: #ff8f1c !important;
  box-sizing: content-box;
  /* background-color: #ff8e1c81;
  border-radius: 100%; */
}

.carousel__pagination-button::after {
  background-color: #ff8f1c;
  width: 8px;
  height: 8px;
  border-radius: 100%;
}

.carousel__pagination-button:hover::after,
.carousel__pagination-button--active::after {
  background-color: #ff8e1c81;
}

@media only screen and (max-width: 670px) {
  .carousel__prev,
  .carousel__next {
    display: none;
  }
}
</style>
