<template>
  <!--BEGIN unpaid list-->
  <div
    class="my-2 mx-3 bg-white px-2 py-6 rounded-md shadow-md border border-theme-44 border-opacity-30"
  >
    <div class="flex justify-end">
      <button
        v-if="
          permissions &&
          permissions?.fatturazione?.store_preventivi?.enabled === true
        "
        class="orange-btn-inverted px-3 py-2"
        @click="openInvoiceModal"
      >
        Nuovo preventivo
      </button>
      <div v-if="invoiceModalOpen">
        <NewInvoiceModal
          title="Nuovo Preventivo"
          :services="services"
          :client="customerDetails"
          :documento="tipoDocumento"
          :show-footer="false"
          @close="closeInvoiceModal"
          @responseModal="
            (t, r) => {
              invoiceModalOpen = false
              $emit('showConfirm', t, r)
            }
          "
          @refreshTables="$emit('refreshTables')"
        />
      </div>
    </div>
    <div class="prova mr-5 my-2 flex justify-between" />
    <!-- BEGIN: Data List -->
    <div class="table-container">
      <PreventiviTable
        v-if="docs && tableConfig_clinic_doc"
        :table-config-clinic-doc="tableConfig_clinic_doc"
        :docs="docs"
        :pagination="pagination"
        :for-page-option="forPageOption"
        :total-records="totalRecords"
        :client="customerDetails"
        :selected-patient="true"
        @getBills="getBills"
        @refreshTable="$emit('refreshTables')"
        @fatturaPreventivo="fatturaPreventivo"
        @prenotaPreventivo="prenotaPreventivo"
        @showInvoice="(el) => $emit('showInvoice', el, 'Riepilogo Preventivo')"
        @editInvoice="(el) => $emit('editInvoice', el, 'Modifica Preventivo')"
        @showPdf="handleShowPdf"
        @filter-handler="filterHandler"
      />
    </div>
    <!-- END: Data List -->
  </div>
  <ConfirmModal
    v-if="confirmOpen"
    :title="confirmString"
    @close="closeConfirmModal"
  />
  <!--END unpaid list-->
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import axios from '../../gateway/backend-api'
import NewInvoiceModal from '../new-invoice/NewInvoiceModal.vue'
import ConfirmModal from '../modals/ConfirmModal.vue'
import PreventiviTable from '../tables/PreventiviTable.vue'
import moment from 'moment'

const forPageOption = [5, 10, 20, 50]
export default defineComponent({
  components: {
    PreventiviTable,
    NewInvoiceModal,
    ConfirmModal
  },
  props: {
    customerDetails: Object,
    active: {
      type: [Boolean],
      default: true
    }
  },
  emits: [
    'showConfirm',
    'showInvoice',
    'editInvoice',
    'refreshTables',
    'showInvoiceEditable'
  ],
  data() {
    return {
      elemFromPrev: {},
      invoiceModalOpen: false,
      confirmOpen: false,
      confirmString: '',
      tableConfig_clinic_doc: [
        {
          key: 'number',
          title: 'Numero',
          type: 'text'
        },
        {
          key: 'datetime',
          title: 'Data',
          type: 'date'
        },
        {
          key: 'customer_id',
          title: 'Cliente',
          type: 'text'
        },
        {
          key: 'buttons',
          title: 'Azioni rapide',
          type: 'none'
        }
      ],
      docs: null,
      totalRecords: '',
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      services: [],
      tipoDocumento: 'preventivo',
      active_filters: {},
      filterActive: false,
      startDateDocument: null,
      endDateDocument: null
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions'
    })
  },
  watch: {
    'pagination.page': function (elem) {
      if (elem) this.getBills()
    },
    'pagination.forPage': function (elem) {
      if (elem) this.getBills()
    }
  },
  mounted() {
    if (this.active) {
      axios
        .get('/preventivi_table_config') // scarico la configurazione della tabella
        .then((resp) => {
          const temp = []
          for (var elem of resp.data) {
            temp.push({ ...elem, value: '' }) // ai dati ricevuti aggiungo un campo per il valore del filtro che sarà inserito
          }
          this.tableConfig_clinic_doc = temp
        })
      this.getBills()
    }
  },
  methods: {
    getBills() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/bills/index-bills', {
          active_filters: this.active_filters,
          document_date_from: this.startDateDocument,
          document_date_to: this.endDateDocument,
          customer_id: this.customerDetails.id,
          code: 'TDP',
          page: this.pagination.page,
          per_page: this.pagination.forPage
        })
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.docs = res.data.data
          this.totalRecords = res.data.total
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    filterHandler(elem, start, end) {
      this.active_filters[elem.key] = elem.value
      this.startDateDocument = start
      this.endDateDocument = end

      this.checkIfFilterActive()

      if (this.filterActive) this.getBills()
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    },
    handleResponseSubmitInvoice(title, resp) {
      this.invoiceModalOpen = false
      this.$store.dispatch('commonComponents/openMessageModal', {
        title: title,
        subtitle: resp
      })
    },
    fatturaPreventivo(elem) {
      // this.confirmString = text
      this.elemFromPrev = elem
      // this.openConfirmModal()
      this.$emit('showInvoiceEditable', this.elemFromPrev)
    },
    prenotaPreventivo(elem, text) {
      this.confirmString = text
      this.openConfirmModal()
    },
    openInvoiceModal() {
      this.invoiceModalOpen = !this.invoiceModalOpen
    },
    closeInvoiceModal() {
      this.invoiceModalOpen = false
      this.getBills()
    },
    openConfirmModal() {
      this.confirmOpen = true
    },
    closeConfirmModal(value) {
      this.confirmOpen = false
      if (value) {
        // Apro fattura
        this.$emit('showInvoiceEditable', this.elemFromPrev)
      }
    },
    handleShowPdf(doc) {
      if (doc) {
        this.$store.dispatch('commonComponents/showLoadingModalAction')

        axios({
          url:
            '/' + localStorage.getItem('clinic') + '/bills/' + doc.id + '/pdf',
          method: 'POST',
          responseType: 'blob',
          params: { timezone: 'GMT' + moment().format('Z') }
        })
          .then((response) => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')

            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Preventivo_' + doc.id + '.pdf')
            document.body.appendChild(link)

            this.$store.dispatch('commonComponents/openPdfModal', {
              title: 'Preventivo_' + doc.id,
              link: url,
              background: false,
              print: () => {
                console.log('PRINT')
              },
              download: () => {
                link.click()
              },
              xml: null,
              table_reference: 'active_bill',
              id_reference: doc.id,
              pdf_signed: doc.signed,
              client: this.customerDetails,
              sign_done: () => {
                this.$emit('refreshTables')
              }
            })
          })
          .catch(() => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Ops, qualcosa è andato storto.'
            })
            this.getBills()
          })
      }
    }
  }
})
</script>
