<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-20 p-2 mx-auto border w-4/5 sm:w-3/5 shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->

      <button type="button" class="closing-btn mr-2" @click="handleclose">
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>

      <div class="flex flex-col mt-10">
        <!-- Modal header -->
        <div v-if="isRoom" class="flex modal-title justify-center">
          <h3 v-if="roomSelected">Stanza</h3>
          <h3 v-else>Aggiungi Stanza</h3>
        </div>
        <div v-else class="flex modal-title justify-center">
          <h3 v-if="roomSelected">Reparto</h3>
          <h3 v-else>Aggiungi Reparto</h3>
        </div>
        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div class="px-6 py-2 mt-2 grid grid-cols-6 gap-2">
          <div class="mb-2 col-span-6">
            <p
              v-if="err"
              class="text-theme-41 fill-current font-bold"
              v-html="err"
            ></p>
          </div>

          <div
            class="flex-col pb-2"
            :class="isRoom ? 'col-span-6 md:col-span-3' : 'col-span-6'"
          >
            <label class="form-label pr-3">Nome </label>
            <input
              v-model="name"
              type="text"
              class="custom-input capitalize"
              :disabled="
                roomSelected &&
                ((type === 'room' &&
                  permissions &&
                  permissions['reparti e sale cliniche'] &&
                  permissions['reparti e sale cliniche'].edit_room?.enabled ===
                    false) ||
                  (type === 'department' &&
                    permissions &&
                    permissions['reparti e sale cliniche'] &&
                    permissions['reparti e sale cliniche'].edit_department
                      ?.enabled === false))
              "
            />
          </div>
          <div
            v-if="isRoom"
            class="col-span-6 md:col-span-3 lg:col-span-2 flex-col pb-2"
          >
            <button
              class="my-3 flex gap-2 items-center w-full mx-auto"
              :class="[openPicker ? 'gray-btn' : 'gray-btn-inverted']"
              @click="togglePicker"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="h-4 fill-current"
              >
                <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M482.8 29.23C521.7 68.21 521.7 131.4 482.8 170.4L381.2 271.9L390.6 281.4C403.1 293.9 403.1 314.1 390.6 326.6C378.1 339.1 357.9 339.1 345.4 326.6L185.4 166.6C172.9 154.1 172.9 133.9 185.4 121.4C197.9 108.9 218.1 108.9 230.6 121.4L240.1 130.8L341.6 29.23C380.6-9.744 443.8-9.744 482.8 29.23L482.8 29.23zM55.43 323.3L176.1 202.6L221.4 247.9L100.7 368.6C97.69 371.6 96 375.6 96 379.9V416H132.1C136.4 416 140.4 414.3 143.4 411.3L264.1 290.6L309.4 335.9L188.7 456.6C173.7 471.6 153.3 480 132.1 480H89.69L49.75 506.6C37.06 515.1 20.16 513.4 9.373 502.6C-1.413 491.8-3.086 474.9 5.375 462.2L32 422.3V379.9C32 358.7 40.43 338.3 55.43 323.3L55.43 323.3z"
                />
              </svg>

              Scegli colore
            </button>
            <div
              v-if="openPicker"
              class="absolute top-24 left-4 mx-auto p-5 rounded-lg"
              style="transform: translate(-50%); left: 50%"
            >
              <color-picker
                v-model:pureColor="pureColor"
                isWidget="true"
                format="hex"
                disableHistory="true"
                roundHistory="true"
                disableAlpha="true"
              />
              <div class="flex justify-end py-2">
                <button class="orange-btn" @click="saveColor">
                  <SaveIcon class="h-5 w-5 mr-2 fill-curent" /> Salva
                </button>
              </div>
            </div>
          </div>
          <div v-if="isRoom" class="col-span-6 lg:col-span-2 flex-col pb-2">
            <label class="form-label">Reparto: </label>

            <v-select
              v-model="department_id"
              label="name"
              :reduce="(value) => value.id"
              placeholder="Selezionare il reparto"
              :options="departments"
              :disabled="
                roomSelected &&
                ((type === 'room' &&
                  permissions &&
                  permissions['reparti e sale cliniche'] &&
                  permissions['reparti e sale cliniche'].edit_room?.enabled ===
                    false) ||
                  (type === 'department' &&
                    permissions &&
                    permissions['reparti e sale cliniche'] &&
                    permissions['reparti e sale cliniche'].edit_department
                      ?.enabled === false))
              "
            />
          </div>
        </div>

        <div
          :class="[
            !roomSelected
              ? 'flex justify-end items-center px-6 mt-5'
              : 'flex justify-between items-center px-6 mt-5'
          ]"
        >
          <button
            v-if="!roomSelected"
            type="button"
            class="orange-btn"
            @click="save"
          >
            <PlusCircleIcon class="h-5 w-5 mr-2" /> Aggiungi
          </button>

          <button
            v-if="
              roomSelected &&
              ((type === 'room' &&
                permissions &&
                permissions['reparti e sale cliniche'] &&
                permissions['reparti e sale cliniche'].delete_room?.enabled ===
                  true) ||
                (type === 'department' &&
                  permissions &&
                  permissions['reparti e sale cliniche'] &&
                  permissions['reparti e sale cliniche'].delete_department
                    ?.enabled === true))
            "
            type="button"
            class="gray-btn-inverted"
            @click="deleteRoom"
          >
            <TrashIcon class="h-5 w-5" />
          </button>
          <div>
            <button
              v-if="
                roomSelected &&
                ((type === 'room' &&
                  permissions &&
                  permissions['reparti e sale cliniche'] &&
                  permissions['reparti e sale cliniche'].edit_room?.enabled ===
                    true) ||
                  (type === 'department' &&
                    permissions &&
                    permissions['reparti e sale cliniche'] &&
                    permissions['reparti e sale cliniche'].edit_department
                      ?.enabled === true))
              "
              type="button"
              class="orange-btn"
              @click="edit"
            >
              <EditIcon class="h-5 w-5 mr-2" /> Modifica
            </button>
            <button class="ml-3 gray-btn" type="button" @click="handleclose">
              <span class="h-5"></span>
              Annulla
            </button>
          </div>
        </div>
        <!-- Modal footer -->
        <div
          class="border-t-1 border-gray-400 w-full px-6 text-right flex justify-end items-end mb-2"
        ></div>

        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters } from 'vuex'
import { ColorPicker } from 'vue3-colorpicker'
import axios from '../../gateway/backend-api'

export default defineComponent({
  components: { ColorPicker },
  props: {
    type: String,
    roomSelected: {
      type: Object,
      default() {
        return null
      }
    },
    err: {
      type: String,
      default() {
        return ''
      }
    }
  },
  emits: ['close', 'save', 'edit', 'deleteRoom'],
  setup(props, { emit }) {
    const name = ref('')
    const department_id = ref('')
    const isRoom = ref('')

    const save = () => {
      if (name.value) {
        var params = {
          name: name.value,
          department_id: department_id.value,
          type: isRoom.value
        }
        emit('save', params)
      }
    }

    const edit = () => {
      if (name.value && props.roomSelected) {
        var params = {
          id: props.roomSelected.id,
          department_id: department_id.value,
          name: name.value,
          type: isRoom.value
        }
        emit('edit', params)
      }
    }

    const deleteRoom = () => {
      if (props.roomSelected.id) {
        emit('deleteRoom', props.roomSelected.id, isRoom.value)
      }
    }

    return {
      name,
      isRoom,
      department_id,
      save,
      edit,
      deleteRoom
    }
  },
  data() {
    return {
      openPicker: false,
      departments: [],
      reparto: [
        {
          id: 1,
          name: 'Medico'
        },
        {
          id: 2,
          name: 'Chirurgia'
        },
        {
          id: 3,
          name: 'Degenza'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions'
    })
  },
  mounted() {
    if (this.type != 'room') {
      this.isRoom = false
    } else {
      this.isRoom = true
      if (this.roomSelected) {
        this.department_id = this.roomSelected.department_id
      }

      this.getDepartments()
    }
    if (this.roomSelected) {
      this.name = this.roomSelected.name
    }
  },
  methods: {
    handleclose() {
      this.$emit('close')
    },

    togglePicker() {
      this.openPicker = !this.openPicker
    },
    saveColor() {
      this.openPicker = false
    },
    getDepartments() {
      axios
        .get('/' + localStorage.getItem('clinic') + '/departments')
        .then((res) => {
          this.departments = res.data
        })
    }
  }
})
</script>
