<template>
  <!-- Main modal -->
  <div
    id="defaultModal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-18 mt-1 mx-auto px-3 pt-3 pb-7 border w-3/4 md:w-4/6 shadow-lg rounded-md bg-white"
    >
      <div class="mb-9">
        <button type="button" class="closing-btn" @click="$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <div class="m-2">
        <h3 v-if="!editSelected" class="modal-title mb-3">Nuovo Consenso</h3>
        <h3 v-else class="modal-title mb-3">Modifica Consenso</h3>

        <div class="flex justify-between mb-5 border-b-4">
          <div class="w-3/4 mb-5">
            <div v-if="!editSelected">
              <label class="form-label required">Seleziona un consenso: </label>
              <div id="select-consent" class="">
                <v-select
                  ref="inputAic"
                  v-model="selectedConsent"
                  :options="consents"
                  label="name"
                  placeholder="Cerca..."
                  @search="searchConsent"
                >
                  <template #option="option">
                    {{ option.name }}
                  </template>
                </v-select>
              </div>
            </div>
            <div v-if="editSelected">
              <h2 class="font-medium text-xl ml-2">
                {{ editSelected.consent.name }}
              </h2>
            </div>
          </div>
          <div class="flex justify-end items-center p-2">
            <label class="form-label font-medium text-base text-right"
              >Data:
            </label>
            <p class="form-label font-medium text-gray-600 ml-2">
              {{ date }}
            </p>
          </div>
        </div>
        <div v-if="selectedConsent">
          <form @submit.prevent="save">
            <template v-for="elem in selectedConsent.data" :key="elem">
              <h3 v-if="elem.section_title" class="font-medium text-xl mb-3">
                {{ elem.section_title }}
                <hr />
              </h3>
              <div class="flex justify-between">
                <template v-if="elem.input">
                  <template v-for="(el, i) in elem.input" :key="el">
                    <div v-if="i == 0" class="w-1/3">
                      <label v-if="elem.extra && elem.input"
                        >{{ elem.extra }}
                      </label>
                      <label
                        v-if="elem.title && elem.input"
                        :class="
                          el.required ? 'form-label required' : 'form-label'
                        "
                        >{{ elem.title }}</label
                      >
                    </div>
                    <div class="w-2/3">
                      <input
                        v-if="el.type == 'text'"
                        v-model="el.data"
                        :name="i"
                        type="text"
                        :required="el.required"
                        :placeholder="el.placeholder ? el.placeholder : ''"
                        class="form-control h-7 mt-2 text-left border-theme-45 px-0 mb-3"
                      />

                      <input
                        v-else-if="el.type == 'number'"
                        v-model="el.data"
                        :name="i"
                        type="number"
                        step=".01"
                        :required="el.required"
                        class="form-control h-7 mt-2 text-left border-theme-45 px-0 mb-3"
                      />

                      <select
                        v-else-if="el.type === 'select'"
                        v-model="el.data"
                        :required="el.required"
                        :placeholder="
                          el.placeholder ? el.placeholder : 'Seleziona'
                        "
                        class="w-full border rounded-md bg-white focus:outline-none h-8 mb-3 mt-2"
                      >
                        <option
                          v-for="(item, index) in el.option"
                          :key="index"
                          :value="item"
                        >
                          {{ item }}
                        </option>
                      </select>

                      <select
                        v-else-if="
                          el.type === 'select_db' && el.table == 'staff_users'
                        "
                        v-model="el.data"
                        :required="el.required"
                        :placeholder="
                          el.placeholder ? el.placeholder : 'Seleziona'
                        "
                        class="w-full border rounded-md bg-white focus:outline-none h-8 mb-3 mt-2"
                      >
                        <option
                          v-for="(staff, is) in staff_users"
                          :key="is"
                          :value="staff.full_name"
                        >
                          {{ staff.full_name }}
                        </option>
                      </select>

                      <select
                        v-else-if="
                          el.type === 'select_db' && el.table == 'externals'
                        "
                        v-model="el.data"
                        :required="el.required"
                        class="w-full border rounded-md bg-white focus:outline-none h-8 mb-3 mt-2"
                      >
                        <option
                          v-for="(ex, ie) in externals"
                          :key="ie"
                          :value="ex.full_name"
                        >
                          {{ ex.full_name }}
                        </option>
                      </select>

                      <div
                        v-else-if="el.type === 'checkbox'"
                        class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 xl:grid-cols-4 gap-3"
                      >
                        <div
                          v-for="(item, index) in el.option"
                          :key="index"
                          class="col-span-1 flex gap-3 items-center justify-between px-2 py-2 shadow-md border border-theme-43 border-opacity-10 rounded-md"
                        >
                          <div class="">{{ item }}</div>
                          <div class="">
                            <a class="mx-auto my-2">
                              <input
                                type="checkbox"
                                class="form-check-switch"
                                :value="item"
                                :checked="el.data && el.data.includes(item)"
                                @click="(event) => setCheckbox(el, event)"
                              />
                            </a>
                          </div>
                        </div>
                      </div>

                      <div
                        v-else-if="el.type === 'radio'"
                        class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 xl:grid-cols-4 gap-3"
                      >
                        <div
                          v-for="(item, index) in el.option"
                          :key="index"
                          class="col-span-1 flex gap-3 items-center justify-between px-2 py-2 shadow-md border border-theme-43 border-opacity-10 rounded-md"
                        >
                          <div class="">{{ item }}</div>
                          <div class="">
                            <a class="mx-auto my-2">
                              <input
                                type="radio"
                                name="radio"
                                :value="item"
                                :checked="el.data == item"
                                class="form-check-switch"
                                @click="(event) => setRadio(el, event)"
                              />
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                </template>
              </div>
            </template>
            <div class="px-6 flex items-center justify-end mt-3">
              <button
                v-if="!editSelected"
                type="submit"
                class="orange-btn mr-3"
              >
                <PlusCircleIcon class="h-5 w-5 mr-1" /> Salva
              </button>
              <button v-else type="submit" class="orange-btn mr-3">
                <PlusCircleIcon class="h-5 w-5 mr-1" /> Modifica
              </button>
              <button type="button" class="gray-btn" @click="$emit('close')">
                <span class="h-5"></span>
                Annulla
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import moment from 'moment'
import axios from '../../gateway/backend-api'
import _ from 'lodash'

export default defineComponent({
  props: {
    petDetails: {
      type: [Object],
      default: function () {
        return {}
      }
    },
    editSelected: {
      type: [Object],
      default: function () {
        return null
      }
    }
  },
  emits: ['close', 'refresh'],
  data() {
    return {
      consents: [],
      date: moment().format('D/M/YYYY'),
      selectedConsent: null,
      idEditConsent: null,
      staff_users: null,
      externals: null
    }
  },
  mounted() {
    this.getConsents()
    this.getStaffUsers()
    this.getExternal()
    if (this.editSelected) {
      this.selectedConsent = this.editSelected.document_data
      this.idEditConsent = this.editSelected.id
    }
  },
  methods: {
    getConsents() {
      axios
        .post('/' + localStorage.getItem('clinic') + '/consents')
        .then((res) => {
          this.consents = res.data
        })
    },
    getStaffUsers() {
      axios
        .post('/' + localStorage.getItem('clinic') + '/staff')
        .then((res) => {
          this.staff_users = res.data
        })
    },
    getExternal() {
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/external/index?type=referent'
        )
        .then((res) => {
          this.externals = res.data.data
        })
    },
    setCheckbox(el, e) {
      if (!el.data) el.data = []

      if (e.target.checked) {
        el.data.push(e.target.value)
        console.log(e.target.checked, el.data)
      } else {
        el.data = el.data.filter((elem) => elem !== e.target.value)
        console.log(e.target.checked, el.data)
      }
    },
    setRadio(el, e) {
      el.data = e.target.value
    },
    save() {
      if (this.selectedConsent)
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/pet/' +
              this.petDetails.id +
              '/consents',
            {
              consent_id: this.selectedConsent.consents_id,
              data: this.selectedConsent,
              edit_consent_id: this.idEditConsent
            }
          )
          .then((res) => {
            this.consents = res.data
            this.$emit('refresh')
            this.$emit('close')
            if (this.idEditConsent) {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Consenso modificato con successo'
              })
            } else {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Consenso creato con successo'
              })
            }
          })
          .catch((err) => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: err
            })
            console.log(err)
          })

      return false
    },
    searchConsent(search, loading) {
      if (search.length > 1) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: _.debounce((loading, search, vm) => {
      axios
        .post('/' + localStorage.getItem('clinic') + '/consents', {
          search: search
        })
        .then((res) => {
          vm.staffs = res.data.data
          vm.totalRecords = res.data.total
          loading(false)
        })
    }, 350)
  }
})
</script>
