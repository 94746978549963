import axios from 'axios'
import { useStore } from '@/store'

const API = axios.create({
  crossDomain: true,
  mode: 'no-cors',
  // withCredentials: true,
  // credentials: 'same-origin',
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 60000,
  headers: {
    'Access-Control-Allow-Origin': '*'
  }
})

API.interceptors.response.use(
  (response) => {
    const store = useStore()
    if (response.data?.msg)
      store.dispatch('commonComponents/openNotificationModal', {
        message: response.data?.msg
      })

    return response
  },
  (error) => {
    const store = useStore()
    if (error.response?.data?.msg)
      store.dispatch('commonComponents/openNotificationModal', {
        message: error.response.data.msg
      })
    console.log(error.response)
    if (401 === error.response.status) {
      localStorage.removeItem('userToken')
      window.location = '/login'
    } else {
      return Promise.reject(error)
    }
  }
)

API.interceptors.request.use(
  function (config) {
    var fakeurl = ''
    if (
      !config.url.match('/auth/login')?.input &&
      config.baseURL.match(process.env.VUE_APP_API_URL)?.input
    ) {
      config.headers.Authorization =
        'Bearer ' + localStorage.getItem('userToken')
    }
    switch (config.url) {
      case config.url.match(/documenti_clinici/)?.input:
        fakeurl = 'cartelle.json'
        break
      case config.url.match(/cartelle_table_config/)?.input:
        fakeurl = 'tableConfig_cartelle.json'
        break
      case config.url.match(/vaccinazioni_table_config/)?.input:
        fakeurl = 'vaccinazioni_table_config.json'
        break
      case config.url.match(/vaccinazioni/)?.input:
        fakeurl = 'vaccinazioni.json'
        break
      case config.url.match(/preventivi_table_config/)?.input:
        fakeurl = 'preventivi_table_config.json'
        break
      case config.url.match(/preventivi/)?.input:
        fakeurl = 'preventivi.json'
        break
      case config.url.match(/annotazioni_table_config/)?.input:
        fakeurl = 'annotazioni_table_config.json'
        break
      case config.url.match(/annotazioni/)?.input:
        fakeurl = 'annotazioni.json'
        break
      case config.url.match(/scheda_clinica_model/)?.input:
        fakeurl = 'scheda_clinica_model.json'
        break
      case config.url.match(/schede_cliniche_table_config/)?.input:
        fakeurl = 'schede_cliniche_table_config.json'
        break
      case config.url.match(/schede_cliniche/)?.input:
        fakeurl = 'schede_cliniche.json'
        break
      case config.url.match(/reports_table_config/)?.input:
        fakeurl = 'reports_table_config.json'
        break
      case config.url.match(/reports/)?.input:
        fakeurl = 'reports.json'
        break
      case config.url.match(/body_score_table_config/)?.input:
        fakeurl = 'body_score_table_config.json'
        break
      // case config.url.match(/body_score/)?.input:
      //   fakeurl = 'body_score.json'
      //   break
      case config.url.match(/registri_table_config/)?.input:
        fakeurl = 'registri_table_config.json'
        break
      case config.url.match(/registri/)?.input:
        fakeurl = 'registri.json'
        break
      case config.url.match(/invoices/)?.input:
        fakeurl = 'fakefatturazioneattiva.json'
        break
      case config.url.match(/search-pharma/)?.input:
        fakeurl = 'fakePharmaSearch.json'
        break /*
      case config.url.match(/warehouse/)?.input:
        fakeurl = 'magazzino.json'
        break */
      /* case config.url.match(/search/)?.input:
        fakeurl = 'fakesearch.json'
        break */
      /*  case config.url.match(/customer-filter/)?.input:
        fakeurl = 'clientiprova.json'
        break

      case config.url.match(/pet-filter/)?.input:
        fakeurl = 'pazientiprova.json'
        break */
      /* case config.url.match(/staff/)?.input:
        fakeurl = 'veterinari.json'
         break */
      /*  case config.url.match(/clinic-room/)?.input:
        fakeurl = 'rooms.json'
        break
      case config.url.match(/departments/)?.input:
        fakeurl = 'departments.json'
        break */
      /* case config.url.match(/agenda/)?.input:
        fakeurl = 'calendarevents.json'
        break */
      // case config.url.match(/tariff/)?.input:
      //   fakeurl = 'faketariffario.json'
      //   break

      case config.url.match(/IVA/)?.input:
        fakeurl = 'iva.json'
        break
      /* case config.url.match(/note-pie/)?.input:
        fakeurl = 'notepiepagina.json'
        break */
      case config.url.match(/nextProgressivo/)?.input:
        fakeurl = 'nextProgressivo.json'
        break
      case config.url.match(/messages/)?.input:
        fakeurl = 'fakemessages.json'
        break
      /* case config.url.match(/tipi-imposta-bollo/)?.input:
        fakeurl = 'fakebollo.json'
        break */
      /* case config.url.match(/default-setup/)?.input:
        fakeurl = 'defaultsetup.json'
        break */
      /* case config.url.match(/global/)?.input:
        fakeurl = 'fake_types_global.json'
        break */
      case config.url.match(/hospitalizations/)?.input:
        fakeurl = 'waiting_list.json'
        break

      case config.url.match(/animal_stats/)?.input:
        fakeurl = 'animali_stats.json'
        break
      case config.url.match(/invoice_stats/)?.input:
        fakeurl = 'stats_fatturazione.json'
        break

      case config.url.match(/prestazioni_stats/)?.input:
        fakeurl = 'prestazioni_stats.json'
        break
      case config.url.match(/chiusura_cassa_config/)?.input:
        fakeurl = 'chiusura_cassa_config.json'
        break
      case config.url.match(/report_economici/)?.input:
        fakeurl = 'report_economici.json'
        break
      case config.url.match(/medical_device_magazzino/)?.input:
        fakeurl = 'farmaci.json'
        break
    }
    if (
      process.env.VUE_APP_FAKE_API.toLowerCase() === 'true' &&
      fakeurl != ''
    ) {
      if (config.method != 'get') config.method = 'get'
      config.baseURL =
        '//' +
        window.location.hostname +
        ':' +
        window.location.port +
        '/fakeapi/'
      config.url = fakeurl
    }
    return config
  },
  function (successfulReq) {
    return successfulReq
  },
  function (error) {
    return Promise.reject(error)
  }
)
export default API
