<template>
  <div
    id="dashboard"
    class="content-container bg-white rounded-md overflow-auto scrollbar_custom"
  >
    <div class="rounded-t-md px-6 w-full grid grid-cols-5 py-2 bg-theme-45 h-8">
      <div
        class="col-span-2 semilg:col-span-2 px-3 flex flex-col gap-2 justify-start"
      >
        <div class="text-theme-40 nunito flex gap-2">
          <router-link
            :to="{ name: 'last-update' }"
            tag="a"
            class="intro-x flex items-center hover:text-theme-41"
            >Ultimo Aggiornamento</router-link
          >
        </div>
      </div>

      <div
        class="col-span-2 semilg:col-span-3 px-3 flex flex-col gap-2 justify-end content-end text-right"
      >
        <div
          class="semilg:col-span-2 text-theme-40 nunito flex gap-2 justify-end content-end text-right"
        >
          <a
            href="https://www.gruppocvitsistemats.it/login.html"
            target="_blank"
            class="dash_link hover:text-theme-41"
            >Sistema TS</a
          >
          <a
            href="https://ws.gruppocvit.it/"
            target="_blank"
            class="dash_link hover:text-theme-41"
            >Market Place</a
          >
        </div>
      </div>
    </div>
    <!-- BEGIN: Important Notes -->
    <div class="w-full grid grid-cols-5 py-2">
      <div
        class="col-span-5 semilg:col-span-3 px-3 flex flex-col gap-2 justify-start"
      >
        <div class="">
          <div class="">
            <h2 class="nunito text-theme-40 text-base font-extrabold">
              Note importanti
            </h2>

            <!-- BEGIN: Carousel -->
            <div class="">
              <!-- :autoplay="3000" -->
              <Carousel :key="carouselKey" :wrap-around="true">
                <Slide v-for="slide in carousels" :key="slide">
                  <div class="">
                    <CarouselTxtImg
                      :occhiello="slide.occhiello"
                      :title="slide.title"
                      :img-src="slide.imgUrl"
                      :data="slide.data"
                      :content="slide.content"
                      :layout-type="slide.layoutType"
                      :link="slide.link"
                    />
                  </div>
                </Slide>

                <template #addons>
                  <Navigation />
                  <!-- <Pagination /> -->
                </template>
              </Carousel>
            </div>
          </div>
        </div>
        <!-- END: Carousel -->

        <!-- BEGIN: Appointments of the day -->
        <div class="mt-2">
          <h2 class="nunito text-theme-40 text-base font-extrabold">
            Appuntamenti del giorno
            {{
              lastAppointments && lastAppointments.length > 0
                ? ': ' + lastAppointments.length
                : ''
            }}
          </h2>

          <div class="py-1.5 flex flex-col gap-1">
            <div
              class="bg-theme-40 rounded-t-lg text-white px-2 py-1 text-sm text-right"
            >
              {{ currentDate() }}
            </div>
            <FullCalendarDraggable id="calendar-events" class="px-1.5">
              <div
                v-if="lastAppointments.length == 0"
                class="my-1.5 w-full px-3 py-1.5 rounded-2xl bg-white border border-theme-44 border-opacity-30 text-center"
              >
                Non ci sono appuntamenti per la data odierna
              </div>
              <div
                v-else
                class="px-3 overflow-y-auto"
                style="max-height: 8.5rem"
              >
                <div
                  v-for="(item, index) in lastAppointments"
                  :key="item.id"
                  class="my-1 w-full px-3 py-1 rounded-2xl grid grid-cols-7 gap-1 cursor-pointer"
                  :class="[
                    index % 2 == 0
                      ? 'bg-white border border-theme-44 border-opacity-30 hover:bg-theme-44'
                      : 'bg-theme-45 hover:bg-theme-44'
                  ]"
                  @click="showAppointment(item)"
                >
                  <div class="col-span-1 flex items-center gap-1">
                    <i class="fas fa-clock text-theme-41"></i>

                    <span class="nunito font-bold">
                      {{ $FormatdateCalendarShort(item.start_datetime) }}
                    </span>
                  </div>
                  <div
                    class="flex gap-1 items-center capitalize truncate"
                    :class="[
                      !item.customer && !item.pet_name
                        ? 'col-span-6'
                        : !item.customer || !item.pet_name
                        ? 'col-span-4'
                        : 'col-span-2'
                    ]"
                  >
                    <i class="fas fa-calendar-alt text-theme-41"></i>
                    <span class="nunito font-bold">{{
                      item.title ? item.title : '--'
                    }}</span>
                  </div>
                  <div
                    v-if="item.customer"
                    class="col-span-2 flex gap-1 items-center"
                  >
                    <i class="fas fa-user text-theme-41"></i>
                    <span class="nunito font-bold">
                      {{
                        item.customer.first_name
                          ? item.customer.first_name.charAt(0)
                          : ''
                      }}.
                      {{ item.customer.last_name ?? '' }}
                      {{ item.customer.ragione_sociale ?? '' }}
                    </span>
                  </div>
                  <div
                    v-if="item.pet_name"
                    class="col-span-2 flex items-center gap-1"
                  >
                    <i class="fas fa-paw text-theme-41"></i>
                    <span class="nunito font-bold">{{
                      item.pet_name ?? ''
                    }}</span>
                  </div>
                </div>
              </div>
            </FullCalendarDraggable>
          </div>
        </div>
        <!-- END: Appointments of the day -->
      </div>
      <!-- END: Important Notes -->

      <!-- BEGIN: Reminders -->
      <div class="col-span-5 semilg:col-span-2 px-3">
        <h2 class="nunito text-theme-40 text-base font-extrabold">
          Promemoria
        </h2>
        <div class="flex flex-col items-center rounded-t-lg bg-theme-40">
          <!-- BEGIN: Reminder Bar over -->
          <div
            class="pt-2 nav nav-tabs ml-auto hidden sm:flex text-white"
            role="tablist"
          >
            <a
              id="latest-tasks-mobile-new-tab"
              data-toggle="tab"
              data-target="#latest-tasks-new"
              href="javascript:;"
              class="py-1 ml-1 active"
              role="tab"
              @click="showAllFunction"
              >Tutti</a
            >
            <a
              id="latest-tasks-mobile-new-tab"
              data-toggle="tab"
              data-target="#latest-tasks-new"
              href="javascript:;"
              class="py-1 ml-3"
              role="tab"
              @click="showReceivedFunction"
              >Ricevuti</a
            >

            <a
              id="latest-tasks-mobile-new-tab"
              data-toggle="tab"
              data-target="#latest-tasks-new"
              href="javascript:;"
              class="py-1 ml-3"
              role="tab"
              aria-selected="false"
              @click="showSentFunction"
              >Inviati</a
            >
            <a
              id="latest-tasks-mobile-new-tab"
              data-toggle="tab"
              data-target="#latest-tasks-new"
              href="javascript:;"
              class="py-1 ml-3"
              role="tab"
              aria-selected="true"
              @click="showUncompletedFunction"
              >Da completare</a
            >
            <a
              id="latest-tasks-mobile-last-week-tab"
              data-toggle="tab"
              data-target="#latest-tasks-last-week"
              href="javascript:;"
              class="py-1 ml-3 mr-2"
              role="tab"
              aria-selected="false"
              @click="showCompletedFunction"
              >Completati</a
            >
          </div>
          <!-- END: Reminder Bar over -->
        </div>
        <div
          id="chat_dashboard"
          class="flex-1 bg-white rounded-b-md shadow-sm border-2 border-t-0 border-theme-44 border-opacity-30"
        >
          <div class="flex justify-end p-1">
            <button
              class="text-sm dash_link nunito text-center bg-white hover:text-theme-41"
              type="button"
              @click="getNextDay"
            >
              <div class="flex items-baseline gap-1 text-xs">
                <i class="fas fa-plus text-xxs"></i>

                Mostra altro
              </div>
            </button>
          </div>

          <div id="messagesContainer" class="chatdashboard_cont bg-theme-2">
            <div
              id="areascrolleable"
              ref="scrollContainer"
              class="overflow-auto scrollbar-none bg-white"
              :class="[!showReminderEditor ? 'hideChat_cont' : 'showChat_cont']"
            >
              <div
                v-for="(reminder, index) in messages"
                id="latest-tasks-last-week"
                :key="reminder.id"
                :class="'ReminderRow ' + reminder.myClass"
                class="my-1"
              >
                <!-- BEGIN: Reminder Received -->
                <div v-if="reminder.category == 'Received'" class="mb-2">
                  <div class="flex items-end">
                    <div
                      class="flex flex-col space-y-1 text-xs max-w-xs mx-2 order-2 items-start"
                    >
                      <div class="flex-col">
                        <div class="flex items-center gap-2">
                          <!-- BEGIN: Reminder Status Button -->
                          <div>
                            <input
                              v-if="reminder.type == 'Reminder'"
                              :id="'CheckComplete' + index"
                              :checked="
                                reminder.reminder_state[0].state == 'Completato'
                                  ? true
                                  : false
                              "
                              class="form-check-switch ml-auto"
                              type="checkbox"
                              @click="toggleCompleted(reminder.id, index)"
                            />
                          </div>
                          <!-- END: Reminder Status Button -->
                          <!-- BEGIN: Reminder States Details -->
                          <div
                            class="flex-col cursor-pointer"
                            @click="toggleDetailsFunction(index)"
                          >
                            <span class="message_cont bg-theme-44"
                              >{{ reminder.message }}
                              <div
                                :id="'details' + index"
                                :key="'details' + index"
                                class="hidden border-t border-gray-400 mt-1"
                              >
                                <div
                                  v-for="receivedState in reminder.reminder_state"
                                  :key="receivedState.id"
                                >
                                  <div
                                    class="text-xs italic font-bold text-theme-42 mt-1.5"
                                  >
                                    {{ receivedState.state }}
                                  </div>
                                </div>
                              </div>
                            </span>
                          </div>
                          <!-- END: Reminder States Details -->
                          <button
                            v-show="reminder.reminder_state[0].state == 'New'"
                            type="button"
                            class="orange-btn-inverted flex ml-5"
                          >
                            {{ reminder.reminder_state[0].state }}
                          </button>
                        </div>
                        <!-- BEGIN: Reminder Dates -->
                        <div class="flex">
                          <div class="mt-1 mr-2">
                            <span v-if="reminder.staff_user" class="italic"
                              >Da: {{ reminder.staff_user.first_name }}
                              {{ reminder.staff_user.last_name }}
                            </span>
                          </div>
                          <div class="text-gray-600 mt-1">
                            {{ $FormatDateMin(reminder.sent_date) }}
                          </div>
                        </div>
                        <!-- END: Reminder Dates -->
                      </div>
                    </div>
                  </div>
                </div>
                <!-- END: Reminder Received -->

                <!-- BEGIN: Reminder Sent -->
                <div v-if="reminder.category == 'Sent'" class="chat-message">
                  <div class="flex items-end justify-end">
                    <div class="flex flex-col text-xs mx-2 items-end">
                      <div
                        :id="'deletebutton' + index"
                        :key="'deletebutton' + index"
                        class="hidden"
                      >
                        <button
                          type="button"
                          class="fas fa-times cursor-pointer text-theme-41 mr-5 text-sm"
                          @click="handleDelete(reminder)"
                        ></button>
                      </div>
                      <div class="flex items-baseline gap-0.5 justify-between">
                        <!-- BEGIN: Reminder States Details -->
                        <div class="message_cont bg-theme-2">
                          <div
                            class="flex-col cursor-pointer"
                            @click="toggleDetailsFunction(index)"
                          >
                            <span
                              >{{ reminder.message }}
                              <div
                                :id="'details' + index"
                                :key="'details' + index"
                                class="hidden border-t-2 border-white mt-1"
                              >
                                <div
                                  v-for="sentState in reminder.reminder_state"
                                  :key="sentState.id"
                                >
                                  <div
                                    class="text-xs italic text-theme-48 mt-2"
                                  >
                                    <span class="font-semibold mr-1">
                                      A:
                                      {{
                                        sentState?.recipient?.first_name +
                                        ' ' +
                                        sentState?.recipient?.last_name
                                      }}
                                    </span>
                                    {{ sentState.state }}
                                  </div>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                        <!-- END: Reminder States Details -->

                        <div
                          v-for="sentState in reminder.reminder_state"
                          :key="sentState.id"
                        >
                          <i
                            id="doubleCheck"
                            class="fas"
                            :class="[
                              sentState.state === 'New'
                                ? 'text-theme-44'
                                : sentState.state === 'Letto' ||
                                  sentState.state === 'Completato'
                                ? 'text-theme-41'
                                : 'text-theme-44',
                              sentState.state === 'Completato'
                                ? 'fa-check-double text-theme-41 '
                                : 'fa-check'
                            ]"
                          />
                        </div>
                      </div>

                      <!-- BEGIN: Reminder Dates -->

                      <div class="text-gray-600">
                        {{ $FormatDateMin(reminder.sent_date) }}
                      </div>

                      <!-- END: Reminder Dates -->
                    </div>
                  </div>
                </div>
                <!-- END: Reminder Sent -->
              </div>
            </div>

            <!-- BEGIN: Scrivi Reminder -->
            <div class="w-full chat_shadow border-t-1 border-theme-44 relative">
              <div v-if="showReminderEditor" class="">
                <div
                  id="select_vet"
                  class="pt-1 px-4 flex items-center justify-between w-full gap-1"
                >
                  <!-- <span class=""> @ </span> -->
                  <v-select
                    ref="input10"
                    v-model="selectedVets"
                    :options="vets"
                    label="full_name"
                    class="w-full"
                    placeholder="Seleziona veterinario"
                    multiple
                  >
                    <template #option="option">
                      <div
                        v-if="option.id == 0"
                        class="mb-2 flex"
                        @click="selezionaTutti"
                      >
                        <input type="checkbox" />
                        <p class="ml-2">Seleziona tutti</p>
                      </div>
                      {{ option.first_name }}
                      {{ option.last_name }}
                    </template>
                  </v-select>
                  <button
                    v-if="showReminderEditor"
                    type="button"
                    class="text-white bg-theme-41 rounded-md cursor-pointer border border-theme-41 hover:bg-white hover:text-theme-41"
                    @click="showReminderEditor = false"
                  >
                    <svg
                      class="w-5 h-5"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                  </button>
                </div>
                <div
                  class="flex flex-col gap-1 items-end border-gray-400 rounded-lg pt-1 px-2"
                >
                  <div class="w-full flex flex-col" style="max-height: 3rem">
                    <QuillEditor
                      id="prova"
                      ref="input3"
                      v-model:content="newMessageText"
                      theme="bubble"
                      class="h-12 h- text-md ql-container ql-bubble bg-white bg-opacity-60 mx-2 rounded-md text-opacity-100"
                      :options="newMessageOptions"
                      content-type="html"
                      @textChange="conta"
                      @keyup.enter="switchFocus(4)"
                    />
                  </div>

                  <div
                    class="flex items-center gap-1 justify-between w-full pb-2"
                  >
                    <div class="flex items-center justify-between px-3 w-full">
                      <div class="">
                        <input
                          v-model="newMessageType"
                          type="checkbox"
                          class="form-checkbox mr-1 h-3 w-3 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
                        />
                        <label class="text-gray-700 text-xs font-semibold"
                          >Reminder?</label
                        >
                      </div>
                      <p class="ml-auto text-sm opacity-75">
                        {{ newMessageLenght }}<strong>/128</strong>
                      </p>
                    </div>
                    <button
                      id="sendNewMessage"
                      type="button"
                      :class="[
                        selectedVets.length > 0 && newMessageLenght > 2
                          ? ''
                          : 'disabilitato'
                      ]"
                      class="w-1/12 mr-3 orange-btn flex flex-row-reverse gap-1 items-center"
                      @click="sendMessage"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        class="h-4 w-4 transform rotate-90"
                      >
                        <path
                          d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z"
                        />
                      </svg>
                    </button>
                  </div>
                </div>

                <!-- END: Button Nuovo Reminder -->
              </div>
              <div v-else class="h-8 flex items-center justify-end p-1">
                <button
                  class="orange-btn py-0.5 flex gap-2 items-center"
                  @click="showReminderEditor = !showReminderEditor"
                >
                  <div class="flex items-center gap-1">
                    <svg
                      id="icon-message"
                      viewBox="0 0 40 32"
                      class="w-6 fill-current"
                    >
                      <path
                        d="M21.263 20.033c2.753-0.038 4.633-1.858 4.667-4.585 0.044-3.61 0.042-7.219 0-10.829-0.032-2.689-1.872-4.563-4.559-4.585-5.616-0.044-11.232-0.046-16.847 0.002-2.494 0.022-4.404 1.756-4.46 4.125-0.092 3.909-0.078 7.821-0.004 11.73 0.038 2.033 1.471 3.576 3.473 3.975 0.777 0.154 1.579 0.177 2.504 0.271 0 0.68-0.1 1.393 0.030 2.061 0.11 0.568 0.357 1.328 0.783 1.557 0.415 0.223 1.23 0.040 1.704-0.245 1.575-0.943 3.058-2.037 4.615-3.012 0.423-0.265 0.977-0.431 1.475-0.441 2.205-0.046 4.412 0.004 6.617-0.026zM14.361 17.108c-0.783 0.032-1.607 0.335-2.312 0.706-1.023 0.54-1.956 1.252-3.038 1.962q-0.423-2.701-3.062-2.701c-3 0-3.084-0.084-3.084-3.088 0-3.008-0.006-6.018 0.002-9.025 0.004-1.491 0.496-1.974 1.984-1.976 5.416-0.004 10.831-0.004 16.245 0 1.469 0 1.984 0.504 1.99 1.99 0.012 3.377 0.014 6.752 0 10.129-0.006 1.473-0.528 1.966-2.011 1.968-2.239 0.004-4.483-0.060-6.718 0.034z"
                      ></path>
                      <path
                        d="M39.966 12.477c-0.038-2.297-1.447-3.993-3.73-4.278-1.944-0.241-3.933-0.12-5.901-0.152-0.72-0.012-1.443 0-2.179 0v2.966c2.492 0 4.86-0.010 7.23 0.004 1.088 0.006 1.712 0.49 1.724 1.525 0.044 3.676 0.042 7.354 0 11.030-0.012 1.001-0.62 1.493-1.623 1.525-0.634 0.020-1.27 0.004-1.906 0.004-2.095 0-2.324 0.215-2.482 2.309-0.006 0.086-0.078 0.167-0.173 0.361-1.027-0.674-1.984-1.397-3.030-1.956-0.674-0.361-1.465-0.638-2.219-0.682-1.665-0.1-3.341-0.020-5.012-0.036-1.178-0.010-1.7-0.536-1.742-1.714-0.016-0.415-0.002-0.831-0.002-1.236h-2.729c-0.614 3.533 1.346 5.907 4.842 5.919 1.437 0.004 2.875-0.036 4.312 0.022 0.5 0.020 1.051 0.181 1.475 0.445 1.583 0.995 3.114 2.073 4.683 3.092 1.278 0.831 2.4 0.235 2.46-1.296 0.028-0.724 0.004-1.451 0.004-2.265 0.588 0 1.049 0.018 1.511-0.002 2.598-0.116 4.444-1.872 4.491-4.454 0.066-3.71 0.062-7.422 0-11.13z"
                      ></path>
                    </svg>

                    <span>Scrivi un messaggio</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
          <!-- END: Scrivi Reminder -->
        </div>
      </div>
    </div>
    <!-- END: Reminders -->

    <div
      v-if="permissions && permissions?.menu?.statistiche?.enabled === true"
      class="col-span-7 px-3 pb-1"
    >
      <h2 class="nunito text-theme-40 text-base font-extrabold">Statistiche</h2>

      <div class="grid grid-cols-3 gap-3">
        <div
          class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-30 p-2 col-span-3 lg:col-span-1"
          style="height: 29vh"
        >
          <div class="w-full chart-cont-w" style="min-height: 24vh">
            <h1 class="stats-title text-center text-base p-1">
              Fatturato annuo
            </h1>
            <ColumnChart
              :values="var1"
              :labels="var2"
              :options="chartOptions"
              class="flex justify-center"
            ></ColumnChart>
          </div>
        </div>
        <div
          class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-30 p-2 col-span-3 lg:col-span-1"
          style="height: 29vh"
        >
          <div class="w-full chart-cont-w" style="min-height: 24vh">
            <h1 class="stats-title text-center text-base p-1">
              Clienti attivi
            </h1>
            <PieChart
              :values="var4"
              :labels="var5"
              :options="pieOptions"
              class="flex justify-center"
            ></PieChart>
          </div>
        </div>
        <div
          class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-30 p-2 col-span-3 lg:col-span-1"
          style="height: 29vh"
        >
          <div class="w-full chart-cont-w" style="min-height: 24vh">
            <h1 class="stats-title text-center text-base p-1">
              Numero prime visite
            </h1>
            <BarChart
              :values="var7"
              :labels="var8"
              :options="chartOptions"
              class="flex justify-center"
            ></BarChart>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div><Scroll></Scroll></div> -->

  <div v-if="openModalAppointment">
    <NewAppointment
      :coming-from-client="customer"
      :appointment-data="selectedAppointment"
      :footer-modal-appointment="false"
      :preview="true"
      modal-appointment-title="Appuntamento"
      @close="showAppointment"
    />
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import axios from '../../gateway/backend-api'
import CarouselTxtImg from '../../components/carousel/CarouselTxtImg.vue'
import 'vue3-carousel/dist/carousel.css'
import { Carousel, Slide, Navigation } from 'vue3-carousel'
import { mapGetters } from 'vuex'
import PieChart from '../../components/pie-chart/Main.vue'
import ColumnChart from '../../components/column-chart/ColumnChart.vue'
import BarChart from '../../components/bar-chart/BarChart.vue'
import NewAppointment from '../calendar/NewAppointment.vue'

const chartOptions = {
  chartArea: { left: 60, top: 15, width: '70%', height: '80%' },
  width: 400,
  height: 250,
  colors: [
    '#059393',
    '#CA6715',
    '#1B4B77',
    '#F7CD65',
    '#9FD5DA',
    '#EF9C02',
    '#6295D3'
  ]
}

const pieOptions = {
  chartArea: { left: 80, top: 30, width: '80%', height: '90%' },
  width: 400,
  height: 210,
  colors: [
    '#059393',
    '#CA6715',
    '#1B4B77',
    '#F7CD65',
    '#9FD5DA',
    '#EF9C02',
    '#6295D3'
  ]
}

export default defineComponent({
  components: {
    Carousel,
    Slide,
    Navigation,
    // Pagination,
    CarouselTxtImg,
    ColumnChart,
    PieChart,
    BarChart,
    NewAppointment
  },
  props: {
    active: {
      type: [Boolean],
      default: false
    }
  },
  data() {
    return {
      reminderContainer: null,
      showReminderEditor: false,
      pieChart: 'pieChart',
      showOld: false,
      chartOptions,
      pieOptions,
      carousels: [],
      lastAppointments: [],
      selectedAppointment: null,
      openModalAppointment: false,
      messages: [],
      search: '',
      state: 'All',
      type: 'All',
      days: 0,
      active_filter: 'Tutti',
      vets: [],
      selectedVets: [],
      newMessageText: '',
      newMessageLenght: 0,
      newMessageType: true, //'Reminder' = true | 'Message' = false
      newMessageOptions: {
        modules: {
          toolbar: ['bold', 'italic', 'underline', 'link']
        },
        placeholder: 'Scrivi un messaggio',
        readOnly: false
      },
      var1: [
        { periodo: 'Gennaio', fatturato: 21000, fatturato2: 19000 },
        { periodo: 'Febbraio', fatturato: 30000, fatturato2: 32000 },
        { periodo: 'Marzo', fatturato: 35000, fatturato2: 0 },
        { periodo: 'Aprile', fatturato: 32000, fatturato2: 0 },
        { periodo: 'Maggio', fatturato: 34000, fatturato2: 0 },
        { periodo: 'Giugno', fatturato: 23000, fatturato2: 0 },
        { periodo: 'Luglio', fatturato: 28000, fatturato2: 0 },
        { periodo: 'Agosto', fatturato: 29000, fatturato2: 0 },
        { periodo: 'Settembre', fatturato: 39000, fatturato2: 0 },
        { periodo: 'Ottobre', fatturato: 40000, fatturato2: 0 },
        { periodo: 'Novembre', fatturato: 50000, fatturato2: 0 },
        { periodo: 'Dicembre', fatturato: 50000, fatturato2: 0 }
      ],
      var2: ['Anno', '2022', '2023'],

      var4: [
        {
          nomePrestazione: '2022',
          numeroPrestazioni: 100
        },
        {
          nomePrestazione: '2023',
          numeroPrestazioni: 20
        }
      ],
      var5: ['Anno', 'Nuovi Clienti'],

      var7: [
        {
          periodo: '2022',
          nuoviClienti: 100,
          nuoviClienti2: 0
        },
        {
          periodo: '2023',
          nuoviClienti: 0,
          nuoviClienti2: 20
        }
      ],
      var8: ['Anno', '2022', '2023'],
      interval: null,
      carouselKey: 0
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions',
      totalBell: 'reusableData/getTotalBell'
    })
  },
  watch: {
    // selectedVets: function () {
    //   // this.checkNewMessage()
    // },

    reminderContainer: function () {
      this.$nextTick(() => {
        this.scrollToBottom()
      })
    },
    messages: function () {
      if (this.showOld) {
        this.scrollToTop()
      } else {
        this.$nextTick(() => {
          this.scrollToBottom()
        })
      }
      var vm = this
      setTimeout(function () {
        if (vm.active_filter == 'Tutti') vm.showAllFunction()
        if (vm.active_filter == 'Ricevuti') vm.showReceivedFunction()
        if (vm.active_filter == 'Inviati') vm.showSentFunction()
        if (vm.active_filter == 'Da Completare') vm.showUncompletedFunction()
        if (vm.active_filter == 'Completati') vm.showCompletedFunction()
      }, 100)
    },
    totalBell: function () {
      if (this.totalBell > 0) this.getFirstDay(false)
    }
  },
  created() {},
  mounted() {
    if (this.active) {
      // ottengo da server il setup della clinica e la salvo nello store
      this.$store.dispatch('main/getClinicSetup')
    }
    this.getAgenda()
    setInterval(() => this.getCarousel(), 60000)
    this.getCarousel()
    this.getFirstDay(true)
    this.getVets()
    this.chartSize()
    this.$nextTick(() => {
      this.scrollToBottom()
    })
  },
  methods: {
    handleDelete(reminder) {
      console.log(reminder)
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler eliminare questo promemoria?',
        background: false,
        confirm: () => {
          axios
            .post('/' + localStorage.getItem('clinic') + '/reminder/delete', {
              reminder_id: reminder.id,
              staff_user_id: reminder.staff_user_id
            })
            .then(() => {
              this.getReminders()
            })
        },
        decline: () => {
          console.log('decline')
        }
      })
    },
    handleShowMessaggio() {
      this.scrollToBottom()
      this.showReminderEditor = false
    },
    currentDate() {
      const current = new Date()
      const options = {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        weekday: 'long',
        timeZone: 'UTC'
      }
      const dateParts = current.toLocaleDateString('it-IT', options).split(', ')

      const capitalizedWeekday =
        dateParts[0].charAt(0).toUpperCase() + dateParts[0].slice(1)

      return `${capitalizedWeekday}`
    },
    sortedAppointments() {
      const sorted = this.lastAppointments.slice().sort((a, b) => {
        return a.start_datetime - b.start_datetime
      })

      this.lastAppointments = sorted // Update the lastAppointments with sorted version

      return sorted
    },

    scrollToTop() {
      const container = this.$refs.scrollContainer
      container.scrollTop = 0
    },
    scrollToBottom() {
      this.showOld = false
      const container = this.$refs.scrollContainer
      const containerHeigt = container.scrollHeight
      container.scrollTop = containerHeigt
      this.reminderContainer = containerHeigt
    },

    showAppointment(app) {
      this.openModalAppointment = !this.openModalAppointment
      if (this.openModalAppointment) this.selectedAppointment = app
      else this.selectedAppointment = null
    },
    // Funzione che recupera l'Agenda
    getAgenda() {
      let today = this.$FormatEpoch(new Date())
      axios
        .post('/' + localStorage.getItem('clinic') + '/agenda/index', {
          active_filters: {
            staff_users: [parseInt(localStorage.getItem('userId'))]
          },
          startDate: today,
          endDate: today
        })
        .then((res) => {
          this.lastAppointments = res.data.data
          this.sortedAppointments()
        })
    },
    getCarousel() {
      axios
        .post('/' + localStorage.getItem('clinic') + '/carousel', {
          enabled: true
        })
        .then((res) => {
          this.carousels = []
          Object.entries(res.data.data).forEach((el) => {
            this.carousels.push({
              occhiello: el[1].header,
              title: el[1].title,
              imgUrl: el[1].img_src,
              data: el[1].published,
              content: el[1].short_description,
              link: el[1].url,
              font: 'Fonte:www.anmvioggi.it',
              type: 'text',
              layoutType: 'img_txt'
            })
          })
          this.carouselKey += 1
        })
    },
    // Funzione che imposta la select per i nuovi reminder
    getVets() {
      var vm = this

      axios
        .post('/' + localStorage.getItem('clinic') + '/staff')
        .then((res) => {
          vm.vets = res.data
          vm.vets.push({ id: 0, first_name: '', last_name: '', color: '' })
        })
    },

    /* Reminder List */
    // Funzione che recupera la lista di reminder
    getReminders() {
      var vm = this

      axios
        .post('/' + localStorage.getItem('clinic') + '/reminder', {
          // search: vm.search,
          // state: vm.state,
          // type: vm.type,
          days: vm.days
        })
        .then((res) => {
          setTimeout(function () {
            vm.messages = res.data

            vm.setClass()

            setTimeout(function () {
              vm.$store.dispatch('commonComponents/hideLoadingModalAction')
              // if (first) document.getElementById('endList').scrollIntoView()
            }, 100)
          }, 100)
        })
    },
    // Funzione che recupera il primo giorno utile(con Reminder Nuovo) -> dopo getReminders()
    getFirstDay(first = false) {
      var vm = this
      vm.$store.dispatch('commonComponents/showLoadingModalAction')

      axios
        .post('/' + localStorage.getItem('clinic') + '/reminder/firstDay', {
          days: vm.days
        })
        .then((res) => {
          vm.days = res.data.firstday ? res.data.firstday : vm.days
          vm.getReminders(first)
        })
    },
    // Funzione che recupera il prossimo giorno utile(con Reminder)
    getNextDay() {
      var vm = this
      vm.$store.dispatch('commonComponents/showLoadingModalAction')

      axios
        .post('/' + localStorage.getItem('clinic') + '/reminder/nextDay', {
          days: vm.days
        })
        .then((res) => {
          vm.days = res.data.nextday ? res.data.nextday : vm.days
          vm.getReminders(false)
          this.showOld = true
        })
    },
    // Funzione che imposta tutte le classi per filtrare i reminder
    setClass() {
      var vm = this

      Object.keys(vm.messages).forEach(function (k) {
        vm.messages[k].myClass =
          ' §_' + vm.messages[k].category + ' §_' + vm.messages[k].type
        Object.keys(vm.messages[k].reminder_state).forEach(function (y) {
          if (
            vm.messages[k].reminder_state[y].state == 'New' &&
            vm.messages[k].category == 'Received'
          )
            vm.toggleRead(vm.messages[k].id, k)
          vm.messages[k].myClass +=
            ' §_' + vm.messages[k].reminder_state[y].state.replace(/\s+/g, '')
        })
      })
    },
    // Funzione che mostra/nasconde state reminder
    toggleDetailsFunction: function (index) {
      if (
        this.messages[index].type == 'Reminder' ||
        this.messages[index].category == 'Sent'
      ) {
        var msg = document.getElementById('details' + index)
        var deletebutton = document.getElementById('deletebutton' + index)

        if (
          !msg.classList.contains('hidden') &&
          !deletebutton.classList.contains('hidden')
        ) {
          msg.classList.add('hidden')
          deletebutton.classList.add('hidden')
        } else {
          msg.classList.remove('hidden')
          deletebutton.classList.remove('hidden')
        }
      }
    },
    // Funzione che aggiorna al Backend lo status Da Completare|Completato
    toggleCompleted: function (id, index) {
      var state = document.getElementById('CheckComplete' + index).checked
        ? 'Completato'
        : 'Da Completare'

      this.markState(id, state, true)
      this.messages[index].reminder_state[0].state = state
    },
    // Funzione che aggiorna al Backend lo status da New
    toggleRead: function (id, index) {
      var vm = this
      var state =
        vm.messages[index].type == 'Reminder' ? 'Da Completare' : 'Letto'

      vm.markState(id, state, false)
      // vm.messages[index].reminder_state[0].state = state // Se le decommento il tag New non serve a nulla visivamente
    },
    // Funzione che aggiorna al Backend finale
    markState: function (id, state, callback = false) {
      axios
        .post('/' + localStorage.getItem('clinic') + '/reminder/mark', {
          reminder_id: id,
          state: state
        })
        .then((res) => {
          if (callback) this.setClass()
          console.log(res.data)
        })
    },
    showUncompletedFunction: function () {
      this.hideAllFunction()
      this.active_filter = 'Da Completare'
      var divchat = document.getElementById('chat_dashboard')
      var show = divchat.querySelectorAll('.§_DaCompletare, .§_New')
      var hide = divchat.querySelectorAll('.§_Sent, .§_Message')
      for (var y = 0; y < show.length; y++) {
        show[y].classList.remove('hidden')
      }
      for (var i = 0; i < hide.length; i++) {
        hide[i].classList.add('hidden')
      }
    },
    showCompletedFunction: function () {
      this.hideAllFunction()
      this.active_filter = 'Completati'
      var divchat = document.getElementById('chat_dashboard')
      var show = divchat.querySelectorAll('.§_Completato')
      var hide = divchat.querySelectorAll('.§_Sent, .§_Message')
      for (var y = 0; y < show.length; y++) {
        show[y].classList.remove('hidden')
      }
      for (var i = 0; i < hide.length; i++) {
        hide[i].classList.add('hidden')
      }
    },
    showReceivedFunction: function () {
      this.showAllFunction()
      this.active_filter = 'Ricevuti'
      var divchat = document.getElementById('chat_dashboard')
      var messaggi = divchat.querySelectorAll('.§_Sent')
      for (var i = 0; i < messaggi.length; i++) {
        messaggi[i].classList.add('hidden')
      }
    },
    showSentFunction: function () {
      this.showAllFunction()
      this.active_filter = 'Inviati'
      var divchat = document.getElementById('chat_dashboard')
      var messaggi = divchat.querySelectorAll('.§_Received')
      for (var i = 0; i < messaggi.length; i++) {
        messaggi[i].classList.add('hidden')
      }
    },
    showAllFunction: function () {
      this.active_filter = 'Tutti'
      var divchat = document.getElementById('chat_dashboard')
      var messaggi = divchat.querySelectorAll('.ReminderRow')
      for (var i = 0; i < messaggi.length; i++) {
        messaggi[i].classList.remove('hidden')
      }
    },
    hideAllFunction: function () {
      var divchat = document.getElementById('chat_dashboard')
      var messaggi = divchat.querySelectorAll('.ReminderRow')
      for (var i = 0; i < messaggi.length; i++) {
        messaggi[i].classList.add('hidden')
      }
    },

    /* Reminder Nuovi */
    selezionaTutti: function () {
      this.selectedVets = this.vets
    },
    // Conta caratteri per QuillEditor
    conta: function () {
      this.newMessageLenght = this.$refs.input3.getText().toString().length - 1

      if ((this.$refs.input3.getText().toString().length || ''.length) > 128) {
        this.$refs.input3.setContents(this.$refs.input3.getText().slice(0, 128))
      }
      if (this.$refs.input3.getText().includes('color: transparent;')) {
        this.$refs.input3.setContents(
          this.$refs.input3
            .getText()
            .replace('color: transparent;', 'color: black;')
        )
      }
    },

    // Funzione per mandare un nuovo Reminder
    sendMessage: function () {
      axios
        .post('/' + localStorage.getItem('clinic') + '/reminder/send', {
          recipients: this.selectedVets,
          message: this.$refs.input3.getText(),
          type: this.newMessageType ? 'Reminder' : 'Message'
        })
        .then((res) => {
          this.messages.push(res.data[0])
          this.resetNewMessage()
          this.$nextTick(() => {
            this.scrollToBottom()
            this.showReminderEditor = false
          })
        })
        .catch(function () {})
    },
    // Funzione per resettare tutte le variabili di nuovo Reminder
    resetNewMessage() {
      this.selectedVets = []
      this.$refs.input3.setContents('')
      this.newMessageType = true
      this.setClass()
    },

    chartSize() {
      let cont_w = document.querySelectorAll('.chart-cont-w')
      cont_w.forEach((container) => {
        this.pieOptions.width =
          container.clientWidth - (container.clientWidth * 10) / 100
        this.pieOptions.height =
          container.clientHeight - (container.clientHeight * 5) / 100

        this.chartOptions.width =
          container.clientWidth - (container.clientWidth * 10) / 100
        this.chartOptions.height =
          container.clientHeight - (container.clientHeight * 5) / 100
      })
    }
  }
})
</script>

<style>
.message_cont {
  @apply px-4 py-1.5 shadow-sm rounded-2xl inline-block  text-xs md:text-sm cursor-pointer;
  max-width: 27vw;
  word-wrap: break-word;
}

#select_vet .vs__dropdown-toggle {
  font-size: 12px;
  height: 2rem;
  background-color: rgba(255, 255, 255, 0.6);
  border: solid 1px rgb(186, 186, 186, 0.3);
  /* border-radius: 10px; */
  /* border-top-right-radius: 10px; */
  padding-right: 20px;
}

#select_vet .vs__dropdown-menu {
  border: solid 1px rgb(186, 186, 186, 0.3);
  font-size: 12px;
}

#select_vet .vs__search {
  @apply text-theme-43 text-sm;
}

.chat_shadow {
  box-shadow: 0px -2px 5px -3px rgb(0 0 0 / 36%);
}

@media (max-width: 1024px) {
  .message_cont {
    max-width: 60vw;
  }
}
</style>
