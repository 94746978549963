<template>
  <div>
    <div
      id="bollo-modal"
      class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50"
    >
      <div
        class="relative w-3/5 md:w-2/5 top-16 mt-1 mx-auto px-5 py-3 border shadow-lg rounded-md bg-white"
      >
        <button type="button" class="closing-btn" @click="$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <div class="flex flex-col">
          <div class="w-full">
            <h2 id="modal-title" class="modal-title mt-8">
              {{ titleText }}
            </h2>
            <div class="py-2 flex flex-col items-center">
              <p class="flex-1 text-gray-500 p-3">
                Attivando e compilando questa sezione, dichiari di aver assolto
                il bollo ai sensi dell'art. 15 del D.P.R. 642/1972 e del DM
                17/06/2014 e ss.mm. Ti ricordiamo che dovrai comunque effettuare
                il pagamento del bollo tramite F24.
              </p>

              <div
                v-if="!comingFromSetup"
                class="flex flex-col md:flex-row my-2 text-center items-center gap-2"
              >
                <input
                  v-model="checkBollo"
                  type="checkbox"
                  class="form-check-switch"
                  data-toggle="toggle"
                  data-onstyle="primary"
                  data-offstyle="secondary"
                />

                <label v-if="checkBollo == false" for="" class="form-label mt-2"
                  >Non attivo</label
                >
                <label v-else for="" class="form-label mt-2">Attivo</label>

                <div v-if="checkBollo == true" class="flex-1">
                  <button
                    v-if="modifica == false"
                    type="button"
                    class="orange-btn ml-3"
                    @click="modifBollo"
                  >
                    Modifica
                  </button>
                  <button
                    v-else
                    type="button"
                    class="gray-btn"
                    @click="modifBollo"
                  >
                    Annulla modifiche
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div v-if="modifica && checkBollo" class="">
            <div class="flex-1">
              <div
                class="text-lg nunito text-theme-40 font-bold text-center bg-theme-2 rounded-lg py-1 px-3 w-full"
              >
                {{ inputText }}
              </div>
              <!-- BEGIN: Seleziona bollo default o alias -->
              <div v-if="showNuovoBollo == false" class="flex flex-col mt-3">
                <p class="px-3 form-label">Metodo bollo</p>
                <div class="flex items-center px-3 gap-2">
                  <div v-if="bollo.alias != ''" class="flex-1">
                    <select
                      v-model="tipoBollo"
                      class="custom-input"
                      @change="prova"
                    >
                      <option v-for="n in bollo" :key="n" :value="n">
                        {{ n.name ? n.name : n.alias }} - {{ n.value }} €
                      </option>
                    </select>
                  </div>
                  <button type="button" @click="showAggiungiBollo">
                    <PlusIcon
                      class="h-5 w-5 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                    />
                  </button>
                </div>
              </div>
              <!-- END: Seleziona bollo default o alias -->
              <!-- BEGIN: Aggiungi nuovo bollo alias -->
              <div v-if="showNuovoBollo == true" class="mb-5">
                <div
                  class="flex flex-col md:flex-row gap-3 md:items-center mt-3"
                >
                  <div class="flex-1 flex flex-col">
                    <p class="form-label required">Nome metodo alias</p>
                    <input
                      v-model="newBollo.alias"
                      type="text"
                      class="custom-input"
                    />
                  </div>
                  <div class="flex-0 flex flex-col">
                    <p class="form-label required">Valore bollo</p>
                    <div class="relative">
                      <div class="absolute top-1.5 left-3">€</div>
                      <input
                        v-model="newBollo.value"
                        class="custom-input pl-6"
                        type="number"
                        min="0"
                        :placeholder="
                          metodoSelezionato != ''
                            ? metodoSelezionato.tax_stamp_value.value
                            : ''
                        "
                        step="0.01"
                        @change="cambiaValue"
                        @keyup="cambiaValue"
                      />
                    </div>
                  </div>
                  <!-- <div class="flex-1 flex flex-col">
                    <p class="form-label required">Metodo bollo</p>
                    <select
                      v-model="metodoSelezionato"
                      class="custom-input"
                      @change="setDefaultValue"
                    >
                      <option v-for="n in bolloDefault" :key="n.id" :value="n">
                        {{ n.name }}
                      </option>
                    </select>
                  </div> -->
                </div>

                <div v-if="isRequired" class="my-2">
                  <h2 class="text-theme-41 font-bold italic">
                    Compilare i campi obbligatori
                  </h2>
                </div>

                <div class="mt-4 flex items-center justify-end gap-2">
                  <button
                    type="button"
                    class="orange-btn"
                    @click="aggiungiAlias"
                  >
                    <PlusIcon class="w-4 h-4" /> Aggiungi
                  </button>

                  <button
                    type="button"
                    class="gray-btn"
                    @click="showAggiungiBollo"
                  >
                    Annulla
                  </button>
                </div>
              </div>
              <!-- END: Aggiungi nuovo bollo alias -->
            </div>
          </div>
          <!-- BEGIN: Footer per il modale in fatturazione -->

          <div v-if="!comingFromSetup" class="flex justify-end mt-4">
            <div v-if="!showNuovoBollo" class="flex itemas-center gap-2 mt-2">
              <button type="button" class="orange-btn" @click="aggiungiBollo">
                <SaveIcon class="h-5 w-5 mr-2" /> Salva
              </button>

              <button
                autofocus
                type="button"
                class="gray-btn"
                @click="$emit('close')"
              >
                Annulla
              </button>
            </div>
          </div>
          <!-- END: Footer per il modale in fatturazione -->

          <!-- BEGIN: Footer per il modale in setup clinica -->

          <div v-else class="flex justify-end mt-4">
            <div v-if="!showNuovoBollo" class="flex itemas-center gap-2 mt-2">
              <button
                id="dialogbox-delete-button-setup"
                type="button"
                class="orange-btn"
                @click="salvaBolloDefault"
              >
                <SaveIcon class="h-5 w-5 mr-2" />
                Salva
              </button>

              <button
                id="dialogbox-anulla-button"
                autofocus
                type="button"
                class="gray-btn"
                @click="$emit('close')"
              >
                Annulla
              </button>
            </div>
          </div>
          <!-- END: Footer per il modale in setup clinica -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../gateway/backend-api'
export default {
  event: ['close'],
  props: {
    comingFromSetup: Object,
    bolloVecchio: Object
  },
  data() {
    return {
      checkBollo: false,
      modifica: false,
      tipoBollo: [],
      bollo: [],
      bolloDefault: [],
      metodoSelezionato: '',
      newBollo: {
        tax_stamp_type_id: '',
        value: '',
        // type: '',
        alias: ''
      },
      titleText: '',
      inputText: '',
      showNuovoBollo: false,
      isRequired: false
    }
  },
  mounted() {
    axios
      .get(
        '/' +
          localStorage.getItem('clinic') +
          '/bills/tipi-imposta-bollo?type=alias_types'
      )
      .then((res) => {
        this.bollo = res.data
      })
    axios
      .get(
        '/' +
          localStorage.getItem('clinic') +
          '/bills/tipi-imposta-bollo?type=default_types'
      )
      .then((res) => {
        this.bolloDefault = res.data
      })
  },
  created() {
    this.titleText = 'Dati bollo'
    if (this.comingFromSetup != null && this.comingFromSetup == true) {
      this.modifica = true
      this.checkBollo = true
      this.inputText = 'Imposta un valore di bollo di default'
    }
  },
  methods: {
    cambiaValue: function () {
      if (this.metodoSelezionato.tax_stamp_value != undefined) {
        this.metodoSelezionato.tax_stamp_value.value = this.newBollo.value
      }
    },
    setDefaultValue: function () {
      if (this.metodoSelezionato.tax_stamp_value.value != null) {
        this.newBollo.value = this.metodoSelezionato.tax_stamp_value.value
      }
    },
    aggiungiAlias: function () {
      if (this.metodoSelezionato.tax_stamp_value != null) {
        this.newBollo.value = this.metodoSelezionato.tax_stamp_value.value
      }
      this.newBollo.tax_stamp_type_id = this.metodoSelezionato.id
      if (this.newBollo.value && this.newBollo.alias != '') {
        this.isRequired = false
        var newBolloToSend = this.newBollo
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/bills/tipi-imposta-bollo',
            newBolloToSend
          )
          .then((res) => {
            this.bollo.push(res.data)
            this.salvaBolloDefault()
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Nuovo bollo aggiunto'
            })
          })
      } else {
        this.isRequired = true
      }
    },
    showAggiungiBollo: function () {
      this.showNuovoBollo = !this.showNuovoBollo
    },
    modifBollo() {
      this.modifica = !this.modifica
    },
    aggiungiBollo: function () {
      if (!this.checkBollo) {
        this.bollo.value = ''
      }
      this.$emit('addBollo', this.tipoBollo)
    },
    salvaBolloDefault() {
      var tipoBolloToSend = this.tipoBollo

      this.$emit('addBolloSetup', tipoBolloToSend)
    }
  }
}
</script>
