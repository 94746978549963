<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-80 p-2 mx-auto overflow-scroll scrollbar-none border sm:w-3/5 w-full shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="flex flex-col content-end overflow-y-auto">
        <!-- Modal header -->
        <div class="flex items-start p-5 rounded-t">
          <h3
            class="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white"
          >
            {{ title }}
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="$emit('close')"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- Fine Modal header -->

        <!-- Modal body -->

        <div
          v-for="(elem, index) in NewData"
          :key="elem"
          class="flex flex-col content-start mb-1"
        >
          <div
            v-show="elem.range"
            class="flex content-center mb-1 w-full items-center justify-center gap-4"
          >
            <span>
              {{ elem.cod_tariffa_alias + '  -  ' + elem.description }}
            </span>
            <button
              type="button"
              class="w-10 sm:w-20 mb-2 inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 text-base font-medium text-theme-48 hover:bg-theme-44 focus:outline-none focus:ring-2 focus:ring-theme-41 sm:text-sm"
              @click="
                () => {
                  NewData[index]['finalValue'] = iva
                    ? elem.price * ivaPerc
                    : elem.price
                }
              "
            >
              {{
                iva
                  ? Math.round((elem.price * ivaPerc + Number.EPSILON) * 100) /
                    100
                  : Math.round((elem.price + Number.EPSILON) * 100) / 100
              }}
            </button>
            <input
              v-model="NewData[index]['finalValue']"
              type="number"
              class="w-20 custom-input"
              @change="checkValue(index)"
            />
            <button
              type="button"
              class="w-10 sm:w-20 mb-2 inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 text-base font-medium text-theme-48 hover:bg-theme-44 focus:outline-none focus:ring-2 focus:ring-theme-41 sm:text-sm"
              @click="
                () => {
                  NewData[index]['finalValue'] = iva
                    ? elem.taxable_max * ivaPerc
                    : elem.taxable_max * 1
                }
              "
            >
              {{
                iva
                  ? Math.round(
                      (elem.taxable_max * ivaPerc + Number.EPSILON) * 100
                    ) / 100
                  : Math.round(
                      (parseFloat(elem.taxable_max) + Number.EPSILON) * 100
                    ) / 100
              }}
            </button>
          </div>
        </div>

        <!-- Modal footer -->
        <div class="flex items-center gap-2 justify-end px-2">
          <button
            v-if="acceptConfirm"
            type="button"
            @click="handleConfirm"
            class="orange-btn"
          >
            Conferma
          </button>
          <button @click="$emit('close')" type="button" class="gray-btn">
            Annulla
          </button>
        </div>
        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, toRef, computed } from 'vue'
import { useStore } from '@/store'

import '@swoga/vue-collapsible-panel/dist/vue-collapsible-panel.css'

export default defineComponent({
  props: ['title', 'rows', 'iva'],
  emits: ['close', 'confirm'],
  setup(props, { emit }) {
    const store = useStore()
    const ivaPerc = computed(
      () => store.getters['reusableData/getRegimeFiscaleIvaPerc']
    )
    // ----- DATA -----
    const NewData = toRef(props, 'rows')

    // ----- METHODS -----
    const handleResponse = (value) => {
      console.log(value)
    }

    const checkValue = (index) => {
      let price = props.iva
        ? parseFloat(NewData.value[index]['price']) * ivaPerc.value
        : parseFloat(NewData.value[index]['price'])
      let taxable_max = props.iva
        ? parseFloat(NewData.value[index]['taxable_max']) * ivaPerc.value
        : parseFloat(NewData.value[index]['taxable_max'])

      if (
        NewData.value[index]['finalValue'] < price ||
        NewData.value[index]['finalValue'] > taxable_max
      ) {
        NewData.value[index]['finalValue'] = parseFloat(price)
      }
    }
    const handleConfirm = () => {
      NewData.value.map((el) => {
        if (props.iva) {
          el.finalValue = el.finalValue / ivaPerc.value
        }
      })

      emit('confirm')
    }

    // ----- COMPUTED -----

    const acceptConfirm = computed(() => {
      let show = true
      NewData.value.map((el) => {
        if (!el.finalValue && el.range) {
          show = false
        }
      })

      return show
    })

    return {
      handleResponse,
      NewData,
      checkValue,
      acceptConfirm,
      handleConfirm,
      ivaPerc
    }
  }
})
</script>
