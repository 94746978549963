<template>
  <div
    class="my-2 mx-3 bg-white px-2 py-6 rounded-md shadow-md border border-theme-44 border-opacity-30"
  >
    <InvoiceTable
      :type="['invoice', 'credit_note']"
      :client="customerDetails.id"
      :customer="customerDetails"
      :active="active"
      @showInvoice="(el) => $emit('showInvoice', el)"
      @editInvoice="(el) => $emit('editInvoice', el)"
      @handleSavePdfRev="handleSavePdfRev"
      @handleNotaDiCredito="handleNotaDiCredito"
      @refreshTables="$emit('refreshTables')"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from '../../gateway/backend-api'
import InvoiceTable from '../../components/invoice-table/InvoiceTable.vue'
import moment from 'moment'

export default defineComponent({
  components: {
    InvoiceTable
  },
  props: {
    customerDetails: Object,
    active: {
      type: [Boolean],
      default: true
    }
  },
  emits: ['showInvoice', 'handleNotaDiCredito', 'editInvoice', 'refreshTables'],
  data() {
    return {}
  },
  created() {},
  methods: {
    handleSavePdfRev(elem) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/bills/' + elem.id + '/pdf',
          {},
          {
            params: { timezone: 'GMT' + moment().format('Z') },
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/pdf'
            }
          }
        )
        .then((response) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Fattura' + elem.id + '.pdf')
          document.body.appendChild(link)
          this.$store.dispatch('commonComponents/openPdfModal', {
            title: elem.number,
            link: url,
            background: false,
            print: () => {
              console.log('PRINT')
            },
            download: () => {
              link.click()
            }
          })
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    handleNotaDiCredito(doc) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'ATTENZIONE',
        subtitle:
          'Sei sicuro di voler creare la nota di credito di questa fattura?',
        background: false,
        confirm: () => {
          this.$emit('handleNotaDiCredito', doc)
        },
        decline: () => {
          console.log('Ho declinato la risposta')
        }
      })
    }
  }
})
</script>
