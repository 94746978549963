<template>
  <div
    class="p-5 text-center content-container bg-theme-2 grid grid-cols-2 gap-5 px-6 content-container overflow-auto scrollbar_custom"
  >
    <!--<div class="col-span-2 flex gap-2 items-center justify-end">
      <span class="text-md ml-2 font-bold mr-2 mt-1"
        >Periodo di riferimento
      </span>

      <select
        class="custom-input w-40 text-left px-1"
        @change="(event) => changeStats(event)"
      >
        <option v-for="show in period" :key="show" :value="show.value">
          {{ show.title }}
        </option>
      </select>
    </div>-->

    <div class="col-span-2 flex gap-2 items-center justify-end">
      <span class="text-md ml-2 font-bold mr-2 mt-1"
        >Periodo di riferimento
      </span>
      <div class="flex gap-2 text-left mt-1">
        <div class="flex items-baseline">
          <span class="mr-2 font-bold">Da: </span
          ><input
            v-model="startDate"
            class="custom-input"
            type="date"
            @change="changeStats"
          />
        </div>
        <div class="flex items-baseline">
          <span class="mr-4 font-bold">A: </span
          ><input
            v-model="endDate"
            class="custom-input"
            type="date"
            @change="changeStats"
          />
        </div>
      </div>
    </div>

    <div
      class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-30 p-2 col-span-2"
    >
      <h1 class="stats-title">Statistiche Pazienti</h1>
      <div>
        <div class="grid grid-cols-4 gap-5 mt-5">
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <div class="stats-subs">
              <div class="tooltiptopstats">
                <label class="mb-0 mr-1">Nuovi pazienti</label>
                <button type="button ml-2">
                  <InfoIcon class="w-4 mb-0.5" />
                </button>
                <span class="tooltiptext" style="font-size: small"
                  >Si intendono i nuovi pazienti acquisiti nel periodo di
                  riferimento</span
                >
              </div>
            </div>
            <div class="stats-number">
              {{ animalStats['new_pets_year'] }}
            </div>
          </div>
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <div class="stats-subs">
              <div class="tooltiptopstats">
                <label class="mb-0 mr-1">Nuovi pazienti PdR</label>
                <button type="button ml-2">
                  <InfoIcon class="w-4 mb-0.5" />
                </button>
                <span class="tooltiptext" style="font-size: small"
                  >Si intendono tutti i pazienti acquisiti dall'inizio dell'anno
                  corrente</span
                >
              </div>
            </div>
            <div class="stats-number">
              {{ animalStats['new_pets_pdr'] }}
            </div>
          </div>
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <div class="stats-subs">
              <div class="tooltiptopstats">
                <label class="mb-0 mr-1">Pazienti attivi %</label>
                <button type="button ml-2">
                  <InfoIcon class="w-4 mb-0.5" />
                </button>
                <span class="tooltiptext" style="font-size: small"
                  >Si intende la percentuale di pazienti con almeno una fattura
                  a carico</span
                >
              </div>
            </div>
            <div class="stats-number">
              {{ animalStats['perc_prestazione_totale_pets_active_pdr'] }}%
            </div>
          </div>
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <div class="stats-subs">
              <div class="tooltiptopstats">
                <label class="mb-0 mr-1">Pazienti Attivi</label>
                <button type="button ml-2">
                  <InfoIcon class="w-4 mb-0.5" />
                </button>
                <span class="tooltiptext" style="font-size: small"
                  >Si intende il numero di pazienti con almeno una fattura a
                  carico</span
                >
              </div>
            </div>
            <div class="stats-number">
              {{ animalStats['tot_active_pets'] }}
            </div>
          </div>
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <div class="stats-subs">
              <div class="tooltiptopstats">
                <label class="mb-0 mr-1">Pazienti inattivi %</label>
                <button type="button ml-2">
                  <InfoIcon class="w-4 mb-0.5" />
                </button>
                <span class="tooltiptext" style="font-size: small"
                  >Si intende la percentuale di pazienti senza fatture a
                  carico</span
                >
              </div>
            </div>
            <div class="stats-number">
              {{
                animalStats[
                  'perc_prestazione_totale_pets_inactive_12_month_ago'
                ]
              }}%
            </div>
          </div>
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <div class="stats-subs">
              <div class="tooltiptopstats">
                <label class="mb-0 mr-1">Pazienti inattivi</label>
                <button type="button ml-2">
                  <InfoIcon class="w-4 mb-0.5" />
                </button>
                <span class="tooltiptext" style="font-size: small"
                  >Si intende il numero di pazienti senza fatture a carico</span
                >
              </div>
            </div>
            <div class="stats-number">
              {{ animalStats['pets_inactive'] }}
            </div>
          </div>
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <div class="stats-subs">
              <div class="tooltiptopstats">
                <label class="mb-0 mr-1">Pazienti struttura</label>
                <button type="button ml-2">
                  <InfoIcon class="w-4 mb-0.5" />
                </button>
                <span class="tooltiptext" style="font-size: small"
                  >Si intende il numero totale dei pazienti nella
                  struttura</span
                >
              </div>
            </div>
            <div class="stats-number">
              {{ animalStats['count'] }}
            </div>
          </div>
        </div>
      </div>
      <div class="grid mt-4">
        <h1 class="stats-title">Statistiche mortalità</h1>

        <div class="grid grid-cols-6 gap-2">
          <div
            v-for="animal in statistiche"
            :key="animal"
            class="mort-stat-wrap mt-2 bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 p-2"
          >
            <div class="stats-subs">
              <label class="mb-0 mr-1">{{ animal.specie }}</label>
            </div>

            <PieChart
              :values="animal.valori"
              :labels="deathLabels"
              :options="chartColOptions"
              class="flex justify-start"
            ></PieChart>
            <div
              class="flex flex-col text-left gap-1 mt-3 border-t-2 border-theme-44 border-opacity-20"
            >
              <div class="mt-3 ml-3 p-1">
                <div
                  v-for="dato in animal.valori"
                  :key="dato"
                  class="flex items-center gap-1"
                >
                  <div class="w-2 h-2 bg-theme-44 rounded-full mr-2"></div>
                  <span class="font-bold mr-1">{{ dato.label }}:</span>
                  {{ dato.value }}
                </div>
                <div class="flex items-center gap-1">
                  <div class="w-2 h-2 bg-theme-44 rounded-full mr-2"></div>
                  <label class="font-bold mr-1">Percentuale:</label
                  >{{ animal.perc }} %
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div>
          <h1 class="stats-title">Statistiche referenti esterni</h1>
        </div>
        <div
          class="table-container fatt-att-cont scrollbar-thin scrollbar-thumb-theme-44 scrollbar-track-theme-2 overflow-scroll"
        >
          <table class="w-full shadow custom-border rounded-lg">
            <thead class="h-10 t-header">
              <tr>
                <th
                  v-for="(item, itemKey) in tableConfig"
                  :key="itemKey"
                  :name="item.title"
                >
                  {{ item.title }}
                </th>
              </tr>
            </thead>
            <tbody class="">
              <tr class="border-theme-45">
                <td
                  v-for="(elem, index) in tableConfig"
                  :key="index"
                  class="text-center p-2"
                >
                  <select
                    v-if="elem.type === 'select'"
                    v-model="elem.value"
                    class="w-full border rounded-md h-7 text-gray-500"
                  >
                    <option selected value="">
                      {{ elem.title }}
                    </option>
                    <option
                      v-for="(item, i) in elem.option"
                      :key="i"
                      :value="item.id ? item.id : item"
                    >
                      {{ item.name ? item.name : item }}
                    </option>
                  </select>

                  <input
                    v-else
                    v-model="elem.value"
                    :type="elem.type"
                    :style="elem.style"
                    min="0"
                    :name="elem.title"
                    class="custom-input pl-4 h-7 text-left border-theme-45"
                    :class="{
                      invisible: elem.type === 'none',
                      'p-0': elem.type === 'date'
                    }"
                    :placeholder="
                      elem.key === 'price'
                        ? iva === false
                          ? elem.title + ' senza IVA'
                          : elem.title + ' con IVA'
                        : elem.title
                    "
                  />
                </td>
              </tr>
              <tr
                v-for="(value, key, index) in animalStats[
                  'pets_with_externals'
                ]"
                :key="index"
                class="h-12 text-xs font-normal hover:bg-theme-44 cursor-pointer"
                :class="{ 'bg-theme-2': index % 2 === 0 }"
              >
                <Cell
                  class="text-center border-r border-theme-45 capitalize"
                  :elementi="key"
                /><Cell
                  class="text-center border-r border-theme-45"
                  :elementi="value"
                />
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'

import PieChart from '../../components/pie-chart/Main.vue'
//import ColumnChart from '../../components/column-chart/ColumnChart.vue'

import Cell from '../../components/cvit-table/Cell.vue'
import moment from 'moment'
import axios from '../../gateway/backend-api'

const period = [
  { title: 'Ultimo mese', value: 1 },
  { title: 'Ultimo trimestre', value: 3 },
  { title: 'Ultimo anno', value: 12 }
]

const chartColOptions = {
  chart: {
    title: 'Company Performance',
    subtitle: 'Sales, Expenses, and Profit: 2014-2017'
  },
  legend: { position: 'bottom', alignment: 'start' },
  width: '100%',
  height: 150,
  colors: [
    '#059393',
    '#CA6715',
    '#1B4B77',
    '#F7CD65',
    '#9FD5DA',
    '#EF9C02',
    '#6295D3'
  ]
}

const chartBarOptions = {
  width: '100%',
  height: '50%',
  title: 'My amazing company performance',
  colors: [
    '#059393',
    '#CA6715',
    '#1B4B77',
    '#F7CD65',
    '#9FD5DA',
    '#EF9C02',
    '#6295D3'
  ]
}

export default defineComponent({
  components: {
    //ColumnChart,
    PieChart,
    Cell
  },
  setup() {},

  data() {
    return {
      tableConfig: [
        {
          key: 'name',
          title: 'Nome',
          type: 'text'
        },
        {
          key: 'number',
          title: 'Numero',
          type: 'number'
        }
      ],
      chartColOptions,
      chartBarOptions,
      period,
      statistiche: [],
      deathStats: {
        specie: [],
        valori: [],
        perc: []
      },
      deathLabels: ['Totale di animali', 'Animali morti'],
      animalStats: {},
      startDate: null,
      endDate: null
    }
  },
  mounted() {
    let b = new Date()
    let a = new Date(b.getFullYear(), b.getMonth(), 1)

    this.startDate = moment(a).format('YYYY-MM-DD')
    this.endDate = moment(b).format('YYYY-MM-DD')
    this.getStatsPet()
  },
  methods: {
    getStatsPet() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')

      axios
        .post('/' + localStorage.getItem('clinic') + '/report/pet', {
          startDate: this.$FormatEpochEnglish(this.startDate),
          endDate: this.$FormatEpochEnglish(this.endDate),
          timezone: 'GMT' + moment().format('Z')
        })
        .then((resp) => {
          console.log('report customer', resp.data)
          this.animalStats = resp.data
          console.log(this.animalStats.pets_with_externals)
          let death_list = resp.data.pets_death_type_list
          this.statistiche = []
          Object.entries(death_list).forEach((value) => {
            this.statistiche.push({
              specie: value[0],
              valori: [
                {
                  label: 'Totale di animali',
                  value: value[1].tot
                },
                {
                  label: 'Animali morti',
                  value: value[1].death
                }
              ],
              perc: value[1].perc
            })
          })
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    changeStats: function () {
      this.getStatsPet()
    },

    animateValue(obj, start, end, duration) {
      let startTimestamp = null
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp
        const progress = Math.min((timestamp - startTimestamp) / duration, 1)
        obj.innerHTML = Math.floor(progress * (end - start) + start)
        if (progress < 1) {
          window.requestAnimationFrame(step)
        }
      }
      window.requestAnimationFrame(step)
    },
    chartSize() {
      let cont_w = document.querySelectorAll('.chart-cont-w')
      cont_w.forEach((container) => {
        this.chartColOptions.width =
          container.clientWidth - (container.clientWidth * 10) / 100
        this.chartColOptions.height =
          container.clientHeight - (container.clientHeight * 5) / 100
      })
    }
  }
})
</script>
<style>
.mort-stat-wrap {
  grid-column: span 2 / span 2;
}
@media (max-width: 768px) {
  .mort-stat-wrap {
    grid-column: span 6 / span 6 !important;
  }
}
</style>
