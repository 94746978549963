<template>
  <div>
    <div
      id="scmag-modal"
      class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50"
    >
      <div
        class="relative top-48 mt-1 mx-auto px-5 pt-3 pb-7 border w-2/5 shadow-lg rounded-md bg-white"
      >
        <button type="button" class="closing-btn" @click="this.$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <div class="flex flex-col justify-center items-center">
          <div class="mt-8">
            <h2 class="modal-title" id="modal-title">Sconto e Maggiorazione</h2>
            <div class="py-2 flex items-center">
              <p class="flex-1 last:text-base text-gray-500 p-3">
                Attraverso questa sezione puoi abilitare la stampa della colonna
                sconto / maggiorazione sulla fattura
              </p>
            </div>
          </div>
          <div class="flex items-center justify-center w-3/5">
            <div class="flex-1 flex items-center gap-2">
              <input
                type="checkbox"
                class="form-checkbox rounded text-theme-41 text-sm focus:ring-0 focus:shadow-none"
                v-model="checkScMag"
                data-toggle="toggle"
                data-onstyle="primary"
                data-offstyle="secondary"
              />

              <div v-if="checkScMag == false" class="">DISATTIVO</div>
              <div v-else class="text-theme-41 font-semibold">ATTIVO</div>
            </div>

            <div class="flex-1">
              <div v-if="isSetup == false" class="flex items-center gap-2">
                <button
                  id="dialogbox-delete-button"
                  type="button"
                  @click="aggiungiScMag"
                  class="orange-btn w-28"
                >
                  <SaveIcon class="w-5 h-5 mr-2" /> Salva
                </button>

                <button
                  autofocus
                  id="dialogbox-anulla-button"
                  type="button"
                  @click="close"
                  class="gray-btn"
                >
                  Annulla
                </button>
              </div>

              <!-- BEGIN: footer setup clinica -->
              <div v-if="isSetup == true" class="flex items-center gap-2">
                <button
                  id="dialogbox-delete-button"
                  type="button"
                  @click="saveDefaultSconto"
                  class="orange-btn w-28"
                >
                  <SaveIcon class="w-5 h-5 mr-2" /> Salva
                </button>

                <button
                  autofocus
                  id="dialogbox-anulla-button"
                  type="button"
                  @click="close"
                  class="gray-btn"
                >
                  Annulla
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  event: ['close'],
  props: {
    oldActive: Object,
    isComingFromSetup: Object,
    checkStatusDiscount: Object
  },
  created() {
    // this.checkScMag = this.oldActive
    if (this.isComingFromSetup != null && this.isComingFromSetup == true) {
      this.isSetup = true
    }
    if (this.checkStatusDiscount != null) {
      if (this.checkStatusDiscount == true) {
        this.checkScMag = true
      } else {
        this.checkScMag = false
      }
    }
  },
  data() {
    return {
      checkScMag: false,
      isSetup: false
    }
  },
  methods: {
    saveDefaultSconto: function () {
      var checkSconto = {
        discountStatus: this.checkScMag
      }
      this.$emit('saveDefault', checkSconto)
    },
    aggiungiScMag() {
      this.$emit('activeScMag', this.checkScMag)
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>
