<template>
  <div
    id="last-update"
    class="px-2 content-container bg-white overflow-auto scrollbar_custom rounded-lg"
  >
    <h2 class="nunito text-theme-40 text-xl font-extrabold mb-3">
      Aggiornamenti
    </h2>
    <div v-if="userRoleId === 1" class="flex justify-end items-center m-3">
      <button
        v-if="!toggleShowEdit"
        type="button"
        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
        @click="editLastUpdates"
      >
        <EditIcon class="h-5 w-5 mr-2" /> Modifica
      </button>
      <button
        v-else
        type="button"
        class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
        @click="storeLastUpdates"
      >
        <EditIcon class="h-5 w-5 mr-2" /> Modifica
      </button>
    </div>
    <div
      v-if="!toggleShowEdit"
      style="list-style: circle"
      v-html="rawHtml"
    ></div>
    <div v-if="toggleShowEdit">
      <QuillEditor
        v-model:content="rawHtml"
        theme="snow"
        content-type="html"
        class="form-control h-100"
      />
    </div>
  </div>
</template>
<script>
import axios from '../../gateway/backend-api'
import { defineComponent, ref, computed } from 'vue'
import { useStore } from '@/store'

export default defineComponent({
  setup() {
    const rawHtml = ref()
    const toggleShowEdit = ref(false)
    const store = useStore()
    const userRoleId = computed(() => store.state.user.role.id)

    const editLastUpdates = () => {
      toggleShowEdit.value = !toggleShowEdit.value
    }

    const storeLastUpdates = () => {
      axios
        .post('/cvit/store-last-updates', {
          value: rawHtml.value
        })
        .then(() => {
          toggleShowEdit.value = false
        })
    }

    return {
      rawHtml,
      toggleShowEdit,
      editLastUpdates,
      storeLastUpdates,
      userRoleId
    }
  },
  created() {
    axios.get('/cvit/last-updates').then((res) => {
      this.rawHtml = res.data
    })
  }
})
</script>

<style></style>
