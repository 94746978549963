<template>
  <div class="flex flex-col gap-2">
    <!-- BEGIN: weigth report -->
    <vue-collapsible-panel-group accordion>
      <vue-collapsible-panel
        v-if="
          permissions &&
          permissions?.pet?.view_pet_vaccination?.enabled === true
        "
        :expanded="false"
      >
        <template #title>
          <p class="w-full text-center details-subtitle details-subtitle">
            Vaccinazioni
          </p>
        </template>
        <template #content> <Vaccinazioni :pet="petDetails" /> </template>
      </vue-collapsible-panel>
      <vue-collapsible-panel :expanded="false">
        <template #title>
          <p class="w-full text-center details-subtitle details-subtitle">
            Peso, Body Score e Superficie
          </p>
        </template>
        <template #content>
          <Weight
            :pet-details="petDetails"
            @refreshPet="$emit('refreshPetData')"
        /></template>
      </vue-collapsible-panel>

      <vue-collapsible-panel :expanded="false">
        <template #title>
          <p class="w-full text-center details-subtitle details-subtitle">
            Storico annotazioni
          </p>
        </template>
        <template #content>
          <StoricoAnnotazioni
            :notes="notes.annotations"
            :for-page-option="notes.forPageOption"
            :new-annotation-modal="newAnnotationModal"
            @toggleNewAnnotationModal="toggleNewAnnotationModal"
            @addNote="addNote"
            @deleteNote="deleteNote"
            @refreshAnnotazioni="
              (type, pagination, filters) =>
                refreshNotes(type, pagination, filters)
            "
          />
        </template>
      </vue-collapsible-panel>
      <vue-collapsible-panel :expanded="false">
        <template #title>
          <p class="w-full text-center details-subtitle details-subtitle">
            Eventi Veterinari
          </p>
        </template>
        <template #content>
          <EventiVeterinari
            :notes="notes.events"
            :for-page-option="notes.forPageOption"
            :new-annotation-modal="newAnnotationModal"
            @toggleNewAnnotationModal="toggleNewAnnotationModal"
            @addNote="addNote"
            @deleteNote="deleteNote"
            @refreshEventiVet="
              (type, pagination, filters) =>
                refreshNotes(type, pagination, filters)
            "
          />
        </template>
      </vue-collapsible-panel>
    </vue-collapsible-panel-group>

    <!-- END: medical events-->
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel
} from '@swoga/vue-collapsible-panel'
import '@swoga/vue-collapsible-panel/dist/vue-collapsible-panel.css'
import Weight from './libretto/Weight.vue'
import EventiVeterinari from './libretto/EventiVeterinari.vue'
import StoricoAnnotazioni from './libretto/StoricoAnnotazioni.vue'
import Vaccinazioni from './libretto/Vaccinazioni.vue'

export default defineComponent({
  components: {
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
    Weight,
    EventiVeterinari,
    StoricoAnnotazioni,
    Vaccinazioni
  },
  props: {
    petDetails: Object,
    notes: Object,
    newAnnotationModal: Boolean
  },
  emits: [
    'refreshNotes',
    'addNote',
    'deleteNote',
    'toggleNewAnnotationModal',
    'refreshPetData'
  ],
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions'
    })
  },
  methods: {
    refreshNotes(type, pagination, filters) {
      this.$emit('refreshNotes', type, pagination, filters)
    },
    addNote(type, note) {
      this.$emit('addNote', type, note)
    },
    deleteNote(type, note_id) {
      this.$emit('deleteNote', type, note_id)
    },
    toggleNewAnnotationModal(boolean) {
      this.$emit('toggleNewAnnotationModal', boolean)
    }
  }
})
</script>
<style>
.vcp__header-title p {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .vcp__header-icon {
    margin-right: 1%;
  }
  .vcp__header-title p {
    font-size: 0.85rem;
  }
}
</style>
