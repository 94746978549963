<template>
  <!-- <div class="flex justify-center">
    <h2 class="text-4xl text-theme-40 nunito mt-2 font-bold py-2">Degenza</h2>
  </div> -->
  <div class="intro-y box px-5 py-3 m-1 content-container">
    <div class="flex justify-end gap-3 p-5 items-center">
      <button class="orange-btn px-3 py-1">Nuova degenza</button>
      <button class="orange-btn px-3 py-1" @click="orderedHeader">
        Ordina
      </button>
    </div>
    <div class="flex items-center">
      <span class="block text-gray-700 text-sm font-bold ml-6"
        >Mostra gabbie vuote</span
      >
      <input
        type="checkbox"
        class="form-checkbox ml-3 h-3 w-3 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
      />
    </div>
    <div class="p-5">
      <!-- BEGIN: Data List -->
      <!-- <Paginator :forPageOption="forPageOption" :totalRecords="totalRecords"
                 @changePagination="changePagination"/> -->
      <div class="table-container">
        <table class="w-full bg-gray-100 rounded-xl">
          <THeader
            v-for="n in config_list"
            :key="n.id"
            :name="n.title"
            class="t-header"
          />
          <tbody>
            <tr>
              <td
                class="pb-3 pl-3 pr-3 text-center whitespace-nowrap"
                v-for="(elem, index) in config_list"
                :key="index"
              >
                <select
                  class="w-full text-gray-500 border rounded-md h-7"
                  v-if="elem.type === 'select'"
                >
                  <option selected>{{ elem.title }}</option>
                  <option
                    v-for="(item, index) in elem.options"
                    :key="index"
                    value=""
                  >
                    {{ item }}
                  </option>
                </select>
                <input
                  :type="elem.type"
                  min="0"
                  :name="elem.title"
                  class="form-control h-7"
                  v-else
                  :class="{
                    invisible: elem.type === 'none',
                    'p-0': elem.type === 'date'
                  }"
                  :placeholder="elem.title"
                />
              </td>
            </tr>
            <tr
              v-for="(item, index) in hospitalizations"
              :key="index"
              class="h-12 text-base font-normal cursor-default hover:bg-blue-200"
              :class="{ 'bg-blue-100': index % 2 === 0 }"
            >
              <td class="text-center border-r border-gray-500">
                {{ item.id }}
              </td>
              <td class="text-center border-r border-gray-500">
                {{ item.microchip }}
              </td>
              <td
                class="text-center border-r border-gray-500 cursor-pointer hover:underline"
              >
                {{ item.name }}
              </td>
              <td
                class="text-center border-r border-gray-500 cursor-pointer hover:underline"
              >
                {{ item.pet_info ? item.pet_info.owner : '--' }}
              </td>
              <td class="text-center border-r border-gray-500">
                {{ item.specie.name }}
              </td>
              <td class="text-center border-r border-gray-500">
                {{ item.pet_info ? item.pet_info.età : '' }}
              </td>
              <td class="text-center border-r border-gray-500">
                {{ item.gender }}
              </td>
              <td class="text-center border-r border-gray-500">
                <div v-if="item.sterilized === 0">No</div>
                <div v-else>Si</div>
              </td>
              <td class="flex text-xl text-center flex-nowrap">
                <a
                  class="mx-auto my-2 border-r border-gray-500 cursor-pointer"
                  data-toggle="modal"
                  data-target="#history-records-modal"
                  @click="handleHistoryRecords(index)"
                >
                  <i class="p-3 fas fa-history" />
                </a>
                <!-- <a class="mx-auto my-2 border-r border-gray-500 cursor-pointer">
                <i class="p-3 fas fa-eye" />
              </a> -->
                <a class="mx-auto my-2 border-r border-gray-500 cursor-pointer">
                  <i class="p-3 fas fa-edit" />
                </a>
                <a
                  class="mx-auto my-2 border-r border-gray-500 cursor-pointer"
                  :href="pdf"
                  target="_blank"
                  @click="handlePdfCreate(hospitalizations, index)"
                >
                  <i class="p-3 fas fa-file-pdf" />
                </a>
                <a
                  class="mx-auto my-2 cursor-pointer"
                  @click="handleDeleteHospitalization(index)"
                >
                  <i class="p-3 fas fa-sign-out-alt" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <!-- BEGIN: Modal Content -->
      <div
        id="history-records-modal"
        class="modal"
        data-backdrop="static"
        tabindex="-1"
        aria-hidden="true"
      >
        <div class="modal-dialog" style="width: 350px">
          <div class="modal-content">
            <div class="px-5 py-10 modal-body">
              <div class="text-center">
                <div class="mb-5">
                  <select
                    class="w-full h-12 px-3 py-2 border rounded-md shadow-sm cursor-pointer"
                    v-model.number="recordSelected"
                  >
                    <option selected disabled value="">
                      Storico degenze..
                    </option>
                    <option
                      v-for="(record, index) in historyRecords"
                      :key="index"
                      :value="index"
                    >
                      {{ record.created_at }}
                    </option>
                  </select>
                </div>
                <button
                  type="button"
                  data-dismiss="modal"
                  class="w-24 mr-4 btn btn-outline-secondary"
                >
                  Annulla
                </button>
                <a :href="pdf" target="_blank">
                  <button
                    type="button"
                    data-dismiss="modal"
                    class="w-24 btn btn-primary"
                    @click="handlePdfCreate(historyRecords, recordSelected)"
                  >
                    Ok
                  </button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Modal Content -->
  </div>
</template>

<script>
import { defineComponent } from 'vue-demi'
import axios from '../../gateway/backend-api'
import THeader from '../../components/cvit-table/THeader.vue'

export default defineComponent({
  data() {
    return {
      config_list: [
        {
          key: 'cage',
          title: '#Gabbia',
          type: 'number'
        },
        {
          key: 'sector',
          title: 'Reparto',
          type: 'select',
          options: ['Cani', 'Gatti', 'Infettivi']
        },
        {
          key: 'name',
          title: 'Nome',
          type: 'text'
        },
        {
          key: 'holder',
          title: 'Proprietario',
          type: 'text'
        },
        {
          key: 'species',
          title: 'Specie',
          type: 'select',
          options: []
        },
        {
          key: 'age',
          title: 'Età',
          type: 'number'
        },
        {
          key: 'gender',
          title: 'Sesso',
          type: 'select',
          options: ['F', 'M']
        },
        {
          key: 'sterilized',
          title: 'Sterilizzato',
          type: 'select',
          options: ['Si', 'No']
        },
        {
          key: 'buttons',
          title: 'Azioni rapide',
          type: 'none'
        }
      ],
      hospitalizations: [],
      pdf: '',
      historyRecords: [],
      recordSelected: '',
      titolo: [
        '#Gabbia',
        'Reparto',
        'Nome',
        'Proprietario',
        'Specie',
        'Età',
        'Sesso',
        'Sterilizzato'
      ],
      titoli: []
    }
  },
  components: {
    THeader
  },
  mounted() {
    axios
      .get('/' + localStorage.getItem('clinic') + '/hospitalizations')
      .then((res) => {
        if (res.status === 200) {
          this.hospitalizations = res.data
        }
      })
  },
  methods: {
    handlePdfCreate: function (array, index) {
      this.pdf = `'/' + localStorage.getItem('clinic') + '/${array[index].pet_id}/hospitalizationPdf/${array[index].id}`
      this.recordSelected = ''
    },
    orderedHeader: function () {
      for (let i = 0; i < this.config_list.length; i++) {
        this.titoli[i] = this.config_list[i].title + ' '
        this.titoli.sort()
      }
    },
    handleDeleteHospitalization: function (index) {
      const alert = confirm(
        `Attenzione! Stai dimettendo ${this.hospitalizations[index].name}. Si desidera continuare?`
      )
      if (alert) {
        axios
          .delete(
            `'/' + localStorage.getItem('clinic') + '/${this.hospitalizations[index].id}/hospitalization`
          )
          .then((res) => {
            if (res.status === 200) {
              axios
                .get('/' + localStorage.getItem('clinic') + '/hospitalizations')
                .then((res) => {
                  if (res.status === 200) {
                    this.hospitalizations = res.data
                  }
                })
            }
          })
      }
    },
    handleHistoryRecords: function (index) {
      axios
        .get(
          `'/' + localStorage.getItem('clinic') + '/${this.hospitalizations[index].id}/hospitalizations`
        )
        .then((res) => {
          if (res.status === 200) {
            this.historyRecords = res.data
          }
        })
    }
  }
})
</script>
