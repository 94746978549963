<template>
  <ClinicalPageModal
    v-if="ClinicalPageModal && ClinicalPageModalPet"
    :pet-details="ClinicalPageModalPet"
    @close="
      () => {
        ClinicalPageModal = false
      }
    "
  />
  <NotificationComponent />
  <MassiveMails
    v-if="sendMailsModal"
    :title="'Invio Mail Massivo'"
    :filters="active_filters"
    :complete-filters="input"
    :total="totalRecords"
    @close="() => (sendMailsModal = false)"
  />
  <div class="">
    <div class="grid grid-cols-12 gap-5">
      <div class="col-span-12">
        <div class="post overflow-hidden">
          <div
            class="post__tabs scrollbar_custom overflow-x-auto overflow-y-hidden nav nav-tabs flex-col sm:flex-row bg-theme-2 text-theme-48"
            role="tablist"
          >
            <button
              class="w-full sm:w-auto px-6 h-10 text-center flex justify-center items-center rounded-t-xl md:rounded-tr-xl"
              :class="[
                getTab.listTab === 0 ? 'bg-theme-40' : ' bg-theme-40',
                getTab.listTab === 0 ? 'text-white' : 'text-white',
                getTab.listTab === 0
                  ? 'hover:text-theme-41'
                  : 'hover:text-theme-41'
              ]"
              @click="getList"
            >
              <i class="fas fa-paw w-4 h-4 mr-2" />
              <span class="font-medium text-base whitespace-nowrap"
                >Lista Pets</span
              >
            </button>
            <button
              v-for="(elem, index) in getPetsDetails"
              :key="index"
              class="sm:w-40 h-10 pl-2 -mx-1 text-center text-theme-42 relative flex justify-center items-center rounded-none md:rounded-t-lg border border-theme-44 border-opacity-10 hover:font-bold hover:bg-theme-11 focus:bg-theme-41 hover:text-white focus:font-bold focus:z-40"
              :class="
                getShow.isActivePet === elem.id
                  ? 'bg-theme-41 text-theme-46 z-10 font-bold'
                  : 'bg-theme-45'
              "
              @click="getSinglePet(elem.id)"
            >
              <i class="flex-0 fas fa-paw mx-2" />
              <span class="sm:w-40 text-left pl-2 truncate capitalize">{{
                elem.name
              }}</span>
              <i
                class="fa fa-times absolute sm:relative sm:-top-2.5 right-2 sm:right-0 pr-4 pt-2"
                @click.stop="removeTabPet(index)"
              />
            </button>
          </div>

          <div
            v-show="getShow.isActive === 0"
            class="post__content tab-content bg-white rounded-b-lg overflow-auto scrollbar_custom anagrafcustomer-cont"
          >
            <div class="rounded-md py-2 md:p-5">
              <div class="box mt-1">
                <div id="responsive-table-list">
                  <!-- start hamburguer filters  -->
                  <div class="block md:hidden">
                    <div class="w-full flex items-center justify-end">
                      <span class="font-bold text-theme-43">Filtri</span>
                      <button
                        class="p-3 text-theme-41 fill-current"
                        @click="openFilters = !openFilters"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                          class="h-5 w-5"
                          :class="openFilters ? 'transform rotate-180' : ' '"
                        >
                          <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                          <path
                            d="M224 416c-8.188 0-16.38-3.125-22.62-9.375l-192-192c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L224 338.8l169.4-169.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-192 192C240.4 412.9 232.2 416 224 416z"
                          />
                        </svg>
                      </button>
                    </div>

                    <div
                      v-if="openFilters"
                      class="bg-theme-2 rounded-lg -intro-y"
                    >
                      <div
                        v-for="(elem, index) in input"
                        :key="index"
                        class="whitespace-nowrap py-2 px-4 flex items-center border-b-2 border-theme-45 last:border-b-0"
                      >
                        <label class="w-28 mr-6 font-bold">{{
                          elem.title
                        }}</label>
                        <select
                          v-if="elem.type === 'select'"
                          v-model="pets_filter[elem.key]"
                          class="block px-2 py-1 border-2 border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500 flex-1"
                        >
                          <option :value="null"></option>
                          <option
                            v-for="(item, i) in elem.option"
                            :key="i"
                            :value="item"
                          >
                            {{ item.name ? item.name : item }}
                          </option>
                        </select>

                        <input
                          v-else
                          v-model="elem.value"
                          :type="elem.type"
                          :name="elem.title"
                          class="block px-2 py-1 border-2 border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500 flex-1"
                          :class="{ invisible: elem.type === 'none' }"
                          :placeholder="elem.title"
                          @change="filterHandler(elem)"
                        />
                      </div>
                    </div>
                  </div>
                  <!-- end hamburguer filters  -->
                  <div class="paginator-container mb-2">
                    <div class="prova mr-5 my-2 flex justify-between">
                      <div class="mt-1">
                        <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
                          >Mostra
                        </span>
                        <select
                          id=""
                          v-model="pagination.forPage"
                          name=""
                          class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
                          @change="(event) => changeNumPages(event)"
                        >
                          <option
                            v-for="show in forPageOption"
                            :key="show"
                            :value="show"
                            class="form-select"
                          >
                            {{ show }}
                          </option>
                        </select>
                        <span class="text-md font-bold ml-2 mt-1">
                          elementi</span
                        >
                      </div>
                    </div>
                    <Paginator
                      class="mt-2 md:mt-0"
                      :for-page-option="pagination.forPage"
                      :total-records="totalRecords"
                      @changePagination="changePagination"
                    />
                  </div>

                  <!-- TABLE RESPONSIVE -->

                  <div class="flex flex-col gap-2 justify-center md:hidden">
                    <div
                      v-for="(item, index) in pets"
                      :key="index"
                      class="flex flex-col align-top items-start rounded-md overflow-hidden"
                    >
                      <div
                        v-for="(elem, i) in config"
                        :key="i"
                        :name="elem.title"
                        class="flex items-stretch w-full rounded-2xl"
                      >
                        <span
                          class="font-bold py-1 px-2 text-white bg-theme-40 w-28"
                          >{{
                            elem.title
                              ? elem.title === 'Gruppo Sanguigno'
                                ? 'Gr. Sanguigno '
                                : elem.title
                              : '-'
                          }}</span
                        >

                        <div
                          class="flex-1 p-1"
                          :class="i % 2 == 0 ? 'bg-theme-2' : 'bg-white'"
                        >
                          <!-- nome pet -->
                          <div
                            v-if="elem.title === 'Nome'"
                            class="rounded-tr-lg"
                          >
                            <div class="flex justify-between">
                              {{ item.name }}
                              <div class="" @click="getPet(item)">
                                <ChevronsRightIcon
                                  class="h-4 w-4 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                                />
                              </div>
                            </div>
                          </div>
                          <!-- owner -->
                          <div
                            v-if="elem.title === 'Proprietario'"
                            class="flex flex-col items-start gap-0.5"
                          >
                            <template
                              v-if="item.owners && item.owners.length > 0"
                            >
                              <template v-for="cust in item.owners" :key="cust">
                                <div
                                  class="flex items-center justify-between gap-1 w-full"
                                >
                                  <div class="">
                                    {{
                                      cust.ragione_sociale
                                        ? cust.ragione_sociale
                                        : ''
                                    }}
                                    {{
                                      cust.last_name +
                                      ' ' +
                                      cust.first_name +
                                      ''
                                    }}
                                  </div>
                                  <div @click="getCustomer(cust)">
                                    <ChevronsRightIcon
                                      class="h-4 w-4 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                                    />
                                  </div>
                                </div>
                              </template>
                            </template>
                          </div>
                          <!-- microchip -->
                          <div v-if="elem.title === 'Microchip'" class=" ">
                            {{ item.microchip ? item.microchip : '-' }}
                          </div>
                          <!-- specie -->

                          <div v-if="elem.title === 'Specie'" class="">
                            {{ item.type ? item.type : '-' }}
                          </div>
                          <!-- Razza -->
                          <div v-if="elem.title === 'Razza'" class=" ">
                            {{ item.breed ? item.breed : '-' }}
                          </div>
                          <!-- Sesso -->
                          <div v-if="elem.title === 'Sesso'" class="">
                            {{
                              item.gender
                                ? item.gender === 'M'
                                  ? 'M'
                                  : 'F'
                                : '-'
                            }}
                          </div>
                          <!-- Data di Nascita -->
                          <div
                            v-if="elem.title === 'Data di Nascita'"
                            class=" "
                          >
                            {{
                              item.pet_info.birth_date
                                ? $FormatDate(item.pet_info.birth_date)
                                : '-'
                            }}
                          </div>
                          <!-- Gruppo Sanguigno -->
                          <div
                            v-if="elem.title === 'Gruppo Sanguigno'"
                            class=""
                          >
                            {{
                              item.pet_info.blood_group
                                ? item.pet_info.blood_group
                                : '-'
                            }}
                          </div>
                          <!-- Donatore -->
                          <div v-if="elem.title === 'Donatore'" class=" ">
                            {{
                              item.donor
                                ? item.donor === true
                                  ? 'SI'
                                  : 'NO'
                                : '-'
                            }}
                          </div>
                          <!-- Sterilizzato -->

                          <div
                            v-if="elem.title === 'Sterilizzato'"
                            class="rounded-br-lg"
                          >
                            {{
                              item.sterilized
                                ? item.sterilized === true
                                  ? 'SI'
                                  : 'NO'
                                : '-'
                            }}
                          </div>
                        </div>
                      </div>

                      <!-- <div
                        class="rounded-r-md flex-1 border border-theme-45 border-opacity-60 border-l-0 "
                      >
                        <div class="py-1.5 pl-3 bg-theme-2">
                          <div class="flex justify-between">
                            {{ item.name }}
                            <div class="" @click="getPet(item)">
                              <ChevronsRightIcon
                                class="h-4 w-4 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                              />
                            </div>
                          </div>
                        </div>
                        <div class="py-1.5 pl-3 bg-white">
                          <div class="flex flex-col items-start gap-0.5">
                            <template
                              v-if="item.owners && item.owners.length > 0"
                            >
                              <template v-for="cust in item.owners" :key="cust">
                                <div
                                  class="flex items-center justify-between gap-1 w-full"
                                >
                                  <div class="">
                                    {{
                                      cust.ragione_sociale
                                        ? cust.ragione_sociale
                                        : ''
                                    }}
                                    {{
                                      cust.last_name +
                                      ' ' +
                                      cust.first_name +
                                      ''
                                    }}
                                  </div>
                                  <div @click="getCustomer(cust)">
                                    <ChevronsRightIcon
                                      class="h-4 w-4 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                                    />
                                  </div>
                                </div>
                              </template>
                            </template>
                          </div>
                        </div>
                        <div class="py-1.5 pl-3 bg-theme-2">
                          {{ item.microchip ? item.microchip : '-' }}
                        </div>
                        <div class="py-1.5 pl-3 bg-white">
                          {{ item.type ? item.type : '-' }}
                        </div>

                        <div class="py-1.5 pl-3 bg-theme-2">
                          {{ item.breed ? item.breed : '-' }}
                        </div>

                        <div class="py-1.5 pl-3 bg-white">
                          {{
                            item.gender
                              ? item.gender === 'M'
                                ? 'M'
                                : 'F'
                              : '-'
                          }}
                        </div>
                        <div class="py-1.5 pl-3 bg-theme-2">
                          {{
                            item.pet_info.birth_date
                              ? $FormatDate(item.pet_info.birth_date)
                              : '-'
                          }}
                        </div>
                        <div class="py-1.5 pl-3 bg-white">
                          {{
                            item.pet_info.blood_group
                              ? item.pet_info.blood_group
                              : '-'
                          }}
                        </div>
                        <div class="py-1.5 pl-3 bg-theme-2">
                          {{
                            item.donor
                              ? item.donor === true
                                ? 'SI'
                                : 'NO'
                              : '-'
                          }}
                        </div>

                        <div class="py-1.5 pl-3 bg-white">
                          {{
                            item.sterilized
                              ? item.sterilized === true
                                ? 'SI'
                                : 'NO'
                              : '-'
                          }}
                        </div>
                      </div> -->
                    </div>
                  </div>
                  <!-- END TABLE RESPONSIVE -->

                  <div class="pb-5 px-3 rounded-t-lg hidden md:block">
                    <table class="border-b border-gray-200 sm:rounded-lg">
                      <THeader
                        v-for="(elem, index) in config"
                        :key="index"
                        class="t-header"
                        :name="elem.title"
                      />

                      <tbody>
                        <tr>
                          <td
                            v-for="(elem, index) in input"
                            :key="index"
                            class="border-b whitespace-nowrap p-2 py-4"
                          >
                            <select
                              v-if="elem.type === 'select'"
                              v-model="pets_filter[elem.key]"
                              class="border rounded-md h-7"
                            >
                              <option :value="null"></option>
                              <option
                                v-for="(item, i) in elem.option"
                                :key="i"
                                :value="item"
                              >
                                {{ item.name ? item.name : item }}
                              </option>
                            </select>
                            <input
                              v-else
                              v-model="elem.value"
                              :type="elem.type"
                              :name="elem.title"
                              class="block px-2 py-1 border-2 border-gray-200 rounded-lg w-full focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500"
                              :class="{ invisible: elem.type === 'none' }"
                              :placeholder="elem.title"
                              @change="filterHandler(elem)"
                            />
                          </td>
                        </tr>
                        <tr
                          v-for="(item, index) in pets"
                          :key="index"
                          :class="{ 'bg-theme-2': index % 2 === 0 }"
                          class="row-custom text-black hover:bg-theme-45"
                        >
                          <td
                            class="border-b border-l whitespace-nowrap text-left py-3 px-1"
                          >
                            <div class="flex items-center px-2 gap-2">
                              <div class="tooltipbutton" @click="getPet(item)">
                                <span class="tooltipbuttontext"
                                  >Apri Anagrafica</span
                                >
                                <ChevronsRightIcon
                                  class="h-4 w-4 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                                />
                              </div>
                              <div class="flex-1 flex items-center gap-2">
                                {{ item.name }}
                                <div v-if="!item.alive">
                                  <svg
                                    id="icon-Risorsa-16"
                                    viewBox="0 0 27 32"
                                    class="w-4 fill-current text-theme-49"
                                  >
                                    <path
                                      d="M25.451 28.297h-23.6c-1.023 0-1.851 0.83-1.851 1.851s0.83 1.851 1.851 1.851h23.6c1.023 0 1.851-0.83 1.851-1.851s-0.83-1.851-1.851-1.851z"
                                    ></path>
                                    <path
                                      d="M25.423 11.082c0-6.12-4.961-11.082-11.082-11.082-6.883 0-12.463 5.58-12.463 12.463v13.734h23.545v-15.116zM17.774 10.41h-2.64v7.507c0 0.818-0.664 1.482-1.482 1.482s-1.482-0.664-1.482-1.482v-7.507h-2.64c-0.818 0-1.482-0.664-1.482-1.482s0.664-1.482 1.482-1.482h2.64v-2.534c0-0.818 0.664-1.482 1.482-1.482s1.482 0.664 1.482 1.482v2.534h2.64c0.818 0 1.482 0.664 1.482 1.482s-0.664 1.482-1.482 1.482z"
                                    ></path>
                                  </svg>
                                </div>
                              </div>
                            </div>
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-left py-3 px-1"
                          >
                            <div class="flex flex-col items-start px-2 gap-0.5">
                              <template
                                v-if="item.owners && item.owners.length > 0"
                              >
                                <template
                                  v-for="cust in item.owners"
                                  :key="cust"
                                >
                                  <div class="flex items-center gap-1">
                                    <div
                                      class="tooltipbutton"
                                      @click="getCustomer(cust)"
                                    >
                                      <span class="tooltipbuttontext"
                                        >Apri Anagrafica</span
                                      >
                                      <ChevronsRightIcon
                                        class="h-4 w-4 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                                      />
                                    </div>

                                    <div class="flex-1">
                                      {{
                                        cust.ragione_sociale
                                          ? cust.ragione_sociale
                                          : ''
                                      }}
                                      {{
                                        cust.last_name +
                                        ' ' +
                                        cust.first_name +
                                        ' '
                                      }}
                                    </div>
                                  </div>
                                </template>
                              </template>
                            </div>
                          </td>

                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-default px-2 py-3"
                          >
                            {{ item.microchip }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-default px-2 py-3"
                          >
                            {{ item.type }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-default px-2 py-3"
                          >
                            {{ item.breed }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-default px-2 py-3"
                          >
                            {{ item.gender }}
                          </td>

                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-default py-3"
                          >
                            {{
                              item.pet_info.birth_date
                                ? $FormatDate(item.pet_info.birth_date)
                                : ''
                            }}
                          </td>

                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-default truncate py-3"
                          >
                            {{ item.pet_info.blood_group }}
                          </td>
                          <td
                            v-if="item.donor === true"
                            class="border-b border-l whitespace-nowrap text-center cursor-default px-2 py-3"
                          >
                            Si
                          </td>
                          <td
                            v-else
                            class="border-b border-l whitespace-nowrap text-center cursor-default px-2 py-3"
                          >
                            No
                          </td>
                          <td
                            v-if="item.sterilized === true"
                            class="border-b border-l whitespace-nowrap text-center cursor-default px-2 py-3"
                          >
                            Si
                          </td>
                          <td
                            v-else
                            class="border-b border-l whitespace-nowrap text-center cursor-default px-2 py-3"
                          >
                            No
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <button
                v-if="false"
                type="file"
                class="orange-btn-inverted ml-4 mt-4"
                @click="() => (sendMailsModal = true)"
              >
                <MailIcon class="h-5 w-5" /> Invia Mail
              </button>
            </div>
          </div>

          <div
            v-for="(item, index) in getPetsDetails"
            v-show="getShow.isActivePet === item.id"
            :key="index"
            class="post__content tab-content"
          >
            <div class="rounded-b-md lista-pets-cont bg-white">
              <div class="">
                <div id="responsive-table-pet">
                  <div class="preview">
                    <div class="overflow-auto scrollbar_custom petdetails-cont">
                      <petDetails
                        :key="keyPetData"
                        :pet-details="item"
                        @openTest="(elem) => openClinicalPageModal(elem)"
                        @removeTab="removeTabPet(index)"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            v-for="(item, index) in getCustomersDetails"
            v-show="getShow.isActiveCustomer === item.id"
            :key="index"
            class="post__content tab-content"
          >
            <div
              class="border border-gray-200 dark:border-dark-5 rounded-md p-5"
            >
              <div class="intro-y box">
                <div id="responsive-table-customer">
                  <div class="preview">
                    <div class="overflow-hidden">
                      <customerDetails :customer-details="item" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Toastify from 'toastify-js'
import petDetails from '../../components/details-pet/PetMain.vue'
import THeader from '../../components/cvit-table/THeader.vue'
import Paginator from '../../components/paginator/Main.vue'
import axios from '../../gateway/backend-api'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'
import _ from 'lodash'
import ClinicalPageModal from '../../components/modals/ClinicalPageModal.vue'
import MassiveMails from '../../components/modals/MassiveMails.vue'

import NotificationComponent from '../../components/notification/NotificationComponent.vue'

const forPageOption = [10, 50, 100]
export default defineComponent({
  components: {
    petDetails,
    THeader,
    Paginator,
    ClinicalPageModal,
    NotificationComponent,
    MassiveMails
  },
  data() {
    return {
      keyPetData: 0,
      ClinicalPageModal: false,
      ClinicalPageModalPet: null,
      config: [
        {
          key: 'name',
          title: 'Nome'
        },
        {
          key: 'owners.first_name',
          title: 'Proprietario'
        },
        {
          key: 'microchip',
          title: 'Microchip'
        },
        {
          key: 'species',
          title: 'Specie'
        },
        {
          key: 'breed',
          title: 'Razza'
        },
        {
          key: 'gender',
          title: 'Sesso'
        },
        {
          key: 'birth_date',
          title: 'Data di Nascita'
        },
        {
          key: 'blood_group',
          title: 'Gruppo Sanguigno'
        },
        {
          key: 'donor',
          title: 'Donatore'
        },
        {
          key: 'sterilized',
          title: 'Sterilizzato'
        }
      ],
      input: [
        {
          key: 'name',
          title: 'Nome',
          type: 'text'
        },
        {
          key: 'owners',
          title: 'Proprietario',
          type: 'text'
        },
        {
          key: 'microchip',
          title: 'Microchip',
          type: 'text'
        },
        {
          key: 'species',
          title: 'Specie',
          type: 'select',
          option: []
        },
        {
          key: 'breed',
          title: 'Razza',
          type: 'select',
          option: []
        },
        {
          key: 'gender',
          title: 'Sesso',
          type: 'select',
          option: [
            { name: 'M', value: 'M' },
            { name: 'F', value: 'F' }
          ]
        },
        {
          key: 'birth_date',
          title: 'Data di Nascita',
          type: 'date',
          date: '',
          format: 'DD MM YYYY'
        },
        {
          key: 'blood_group',
          title: 'Gruppo Sanguigno',
          type: 'text'
        },
        {
          key: 'donor',
          title: 'Donatore',
          type: 'select',
          option: [
            { name: 'Si', value: true },
            { name: 'No', value: false }
          ]
        },
        {
          key: 'sterilized',
          title: 'Sterilizzato',
          type: 'select',
          option: [
            { name: 'Si', value: true },
            { name: 'No', value: false }
          ]
        }
      ],
      openFilters: false,
      pets: '',
      pets_filter: {},
      totalRecords: '',
      forPageOption,
      active_filters: {},
      filterActive: false,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      sendMailsModal: false
    }
  },
  computed: {
    ...mapGetters({
      getPetsDetails: 'anagraficaPazienti/getPetsDetails',
      getComparisonPets: 'anagraficaPazienti/getComparisonPets',
      getCustomersDetails: 'anagraficaClienti/getCustomersDetails', // NON CANCELLARE
      getComparisonCustomer: 'anagraficaClienti/getComparisonCustomer', // NON CANCELLARE
      // anche se non sono utilizzate in questo file, quando si utilizzano le funzioni globali di goTo se non vengono dichiarate qua sono inutilizzabili in quel file
      getTab: 'anagraficaPazienti/getTab',
      getShow: 'anagraficaPazienti/getShow'
    })
  },
  watch: {
    'getShow.isActive': function (elem) {
      if (elem == 0) this.getPetsApiList()
    },
    'pets_filter.species': function (elem) {
      this.filterSelect(elem, 'species')
    },
    'pets_filter.breed': function (elem) {
      this.filterSelect(elem, 'breed')
    },
    'pets_filter.gender': function (elem) {
      this.filterSelect(elem, 'gender')
    },
    'pets_filter.donor': function (elem) {
      this.filterSelect(elem, 'donor')
    },
    'pets_filter.sterilized': function (elem) {
      this.filterSelect(elem, 'sterilized')
    },
    getPetsDetails: function () {
      this.refreshKeyPetData()
    }
  },
  mounted() {
    this.getPetsApiList()

    axios
      .get('/' + localStorage.getItem('clinic') + '/pet/types_breeds')
      .then((res) => {
        this.input[3].option = res.data
      })
  },
  methods: {
    ...mapActions({
      getListAction: 'anagraficaPazienti/getListAction',
      getPetAction: 'anagraficaPazienti/getPetAction',
      getSinglePetAction: 'anagraficaPazienti/getSinglePetAction',
      getCustomerAction: 'anagraficaClienti/getCustomerAction',
      getSingleCustomerAction: 'anagraficaClienti/getSingleCustomerAction',
      removeTabPetAction: 'anagraficaPazienti/removeTabPetAction',
      removeTabCustomerAction: 'anagraficaClienti/removeTabCustomerAction'
    }),
    refreshKeyPetData() {
      this.keyPetData += 1
    },
    openClinicalPageModal(elem) {
      this.ClinicalPageModal = true
      this.ClinicalPageModalPet = elem
    },
    // Metodo generico per handler filtro select
    filterSelect(elem, field) {
      if (elem) {
        switch (field) {
          case 'species':
            if (
              this.active_filters.pet_type_id != null &&
              this.active_filters.pet_type_id != elem.id
            ) {
              this.active_filters.pet_breed_id = null
            }
            this.input[4].option = elem.breeds
            this.active_filters.pet_type_id = elem.id
            break
          case 'breed':
            this.active_filters.pet_breed_id = elem.id
            break
          default:
            this.active_filters[field] = elem.value
        }
      } else {
        switch (field) {
          case 'species':
            this.active_filters.pet_type_id = elem
            break
          case 'breeds':
            this.active_filters.pet_breed_id = elem
            break
          default:
            this.active_filters[field] = null
        }
      }
      if (field == 'species') this.pets_filter.breeds = null
      if (!this.active_filters.pet_type_id) {
        this.input[4].option = []
        this.active_filters.pet_breed_id = null
      }

      this.checkIfFilterActive()
      if (this.filterActive) this.searchAPI()
      else this.getPetsApiList()
    },
    // per il dettaglio di queste funzioni vedi nel file store/mutations/anagrafica-pazienti
    getList: function () {
      this.getListAction()
    },
    getPet: function (pet) {
      if (
        //this.getPetsDetails.length < 4 &&
        !this.getComparisonPets.includes(pet.id)
      ) {
        this.getPetAction(pet)
      } else if (this.getComparisonPets.includes(pet.id)) {
        this.tabAlreadyOpen()
      } else {
        this.maxTabOpen()
      }
    },
    getSinglePet: function (elem) {
      this.refreshKeyPetData()
      this.getSinglePetAction(elem)
    },
    filterHandler(elem) {
      if (elem.type == 'date') {
        this.active_filters[elem.key] = this.$FormatEpoch(
          moment(elem.value).format('DD/MM/YYYY')
        )
      } else {
        this.active_filters[elem.key] = elem.value
      }
      this.checkIfFilterActive()
      if (this.filterActive) this.searchAPI()
      else this.getPetsApiList()
    },
    searchAPI() {
      axios
        .post('/' + localStorage.getItem('clinic') + '/search/searchPet', {
          toSearch: this.active_filters,
          per_page: this.pagination.forPage,
          page: this.pagination.page
        })
        .then((res) => {
          this.pets = res.data.pet.data.items
          this.totalRecords = res.data.pet.total
        })
    },

    changeNumPages: function (elem) {
      this.pagination.forPage = elem.target.value
      this.searchAPI()
    },
    getCustomer: function (cust) {
      axios
        .get('/' + localStorage.getItem('clinic') + '/customer/' + cust.id)
        .then((res) => {
          const customer = res.data
          this.$goToCustomer(customer)
        })
    },
    getSingleCustomer: function (elem) {
      this.getSingleCustomerAction(elem)
    },
    removeTabPet: function (index) {
      this.removeTabPetAction(index)
    },
    removeTabCustomer: function (index) {
      this.removeTabCustomerAction(index)
    },
    // funzione che fa apparire pop-up quando superi il numero di tab che si possono aprire
    maxTabOpen: function () {
      Toastify({
        node: cash('#max-tab-open').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    },
    // funzione che fa apparire pop-up quando provi ad aprire una tab già aperta
    tabAlreadyOpen: function () {
      Toastify({
        node: cash('#tab-already-open').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    },
    getPetsApiList: function () {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      this.pets = []
      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/pet?per_page=' +
            this.pagination.forPage +
            '&page=' +
            this.pagination.page
        )
        .then((res) => {
          this.pets = _.sortBy(res.data.data, 'name')
          this.totalRecords = res.data.total
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    // funzione che da l'input al componente paginatore per cambiare pagina
    changePagination: function (value) {
      if (this.pagination.page != value.page) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        if (!this.filterActive) this.getPetsApiList()
        else this.searchAPI()
      }
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    }
    // getOwnerHeight() {
    //   var heightString = this.$refs.ownerCell
    //   console.log('heightString', heightString)
    // }
  }
})
</script>
