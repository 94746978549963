<template>
  <div class="rounded-lg content-container bg-white py-3 px-6">
    <label class="form-label required">Seleziona un utente: </label>
    <div id="select-aic" class="">
      <div class="">
        <v-select
          ref="inputAic"
          v-model="selectedUser"
          :options="staffs"
          label="full_name"
          placeholder="Cerca..."
        >
          <template #option="option">
            <table class="mb-2">
              <tr>
                <th>{{ option.last_name }} {{ option.first_name }}</th>
              </tr>
              <tr>
                <td>{{ option.role.name }}</td>
              </tr>
            </table>
          </template>
        </v-select>
      </div>
    </div>

    <div v-if="permissions">
      <label class="form-label required">Permessi: </label>
      <div class="py-2 permessi-cont overflow-auto scrollbar_custom px-3">
        <template v-for="(permission, i) in permissions" :key="i">
          <Acordeon
            id="tariffario"
            class="capitalize mb-1"
            :full-header="true"
            :title="i"
            :stato="false"
            :swich="false"
            :setup="false"
            :open="true"
            @handleOpen="open(i)"
          />

          <div v-if="isOpen[i]" class="px-2 py-4">
            <div
              class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 xl:grid-cols-4 gap-3"
            >
              <div
                v-for="(p, k) in permission"
                :key="k"
                class="col-span-1 flex gap-3 items-center justify-between px-3 py-2 shadow-md border border-theme-43 border-opacity-20 rounded-md"
              >
                <div class="">{{ p.name }}</div>
                <div class="">
                  <a class="mx-auto my-2">
                    <input
                      type="checkbox"
                      class="form-check-switch"
                      :checked="p.enabled"
                      @click="(event) => setPermission(p.id, event)"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from '../../gateway/backend-api'
import Acordeon from '../../views/setup-clinica/AcordeonCustom.vue'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default defineComponent({
  components: {
    Acordeon
  },
  data() {
    return {
      permissions: [],
      isOpen: {},
      staffs: [],
      selectedUser: null,
      pagination: {
        page: 1,
        forPage: 50
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser'
    })
  },
  watch: {
    selectedUser: function (user) {
      this.isOpen = {}
      this.permissions = []
      if (user) this.getPermessi()
    }
  },

  mounted() {
    axios
      .get(
        '/' +
          localStorage.getItem('clinic') +
          '/staff/' +
          localStorage.getItem('userId')
      )
      .then((res) => {
        this.selectedUser = res.data
      })

    this.getStaffs()
  },
  methods: {
    getStaffs() {
      axios
        .post('/' + localStorage.getItem('clinic') + '/staff', {})
        .then((res) => {
          this.staffs = res.data
        })
    },
    getPermessi() {
      axios
        .post('/permission', {
          staff_user_id: this.selectedUser.id,
          clinic_id: localStorage.getItem('clinic')
        })
        .then((res) => {
          this.permissions = res.data
          for (var key of Object.keys(this.permissions)) {
            this.isOpen[key] = false
          }
        })
    },
    setPermission(id, e) {
      axios
        .post('/' + localStorage.getItem('clinic') + '/permission/give', {
          staff_user_ids: [this.selectedUser.id],
          permission: id,
          action: e.target.checked
        })
        .then(() => {
          if (this.user.id === this.selectedUser.id) {
            this.$store.dispatch(
              'reusableData/getPermissionsAction',
              this.$store.getters.getUser.id
            )

            axios
              .get(
                '/' +
                  localStorage.getItem('clinic') +
                  '/auth/' +
                  this.user.id +
                  '/get-menu'
              )
              .then((res) => {
                this.$store.dispatch('setMenu', res.data)
              })
          }
        })
        .catch((err) => {
          if (err.response.data.msg) {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: err.response.data.msg
            })
          }
        })
    },
    open(type) {
      for (var key of Object.keys(this.isOpen))
        if (type != key) this.isOpen[key] = false
      this.isOpen[type] = !this.isOpen[type]
    },
    searchStaff(search, loading) {
      if (search.length > 1) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: _.debounce((loading, search, vm) => {
      axios
        .get('/' + localStorage.getItem('clinic') + '/staff', {
          per_page: this.pagination.forPage,
          page: this.pagination.page,
          search: search
        })
        .then((res) => {
          vm.staffs = res.data.data
          vm.totalRecords = res.data.total
          loading(false)
        })
    }, 350)
  }
})
</script>
