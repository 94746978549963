<template>
  <!-- BEGIN: Attachments list -->
  <div class="">
    <AttachementComponent
      :customer_id="customerDetails.id"
      @refreshTable="getDocs"
    />
    <div class="bg-white p-5 rounded-lg my-5">
      <!-- BEGIN: Data List -->

      <div class="paginator-container">
        <div class="prova mr-5 my-2 flex justify-between flex-1">
          <div class="my-1">
            <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
              >Mostra
            </span>

            <select
              class="form-select w-16 h-6 pr-2 pl-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
              @change="(event) => changeNumPages(event)"
            >
              <option v-for="show in forPageOption" :key="show" :value="show">
                {{ show }}
              </option>
            </select>

            <span class="text-md font-bold ml-2 mt-1"> elementi</span>
          </div>
        </div>

        <Paginator
          :for-page-option="pagination.forPage"
          :total-records="totalRecords"
          @changePagination="(event) => changePagination(event)"
        />
      </div>

      <div class="">
        <table class="overflow-hidden sm:rounded-lg w-full">
          <THeader
            v-for="(item, itemKey) in tableData.config"
            :key="itemKey"
            :name="item.title"
            class="text-white border-b-2 border-theme-41 whitespace-nowrap text-center font-bold bg-theme-40"
          />
          <tbody class="bg-white">
            <tr>
              <td
                v-for="(elem, index) in tableData.input"
                :key="index"
                class="whitespace-nowrap text-center px-3 py-3"
              >
                <select
                  v-if="elem.type === 'select'"
                  v-model="elem.value"
                  class="w-full text-gray-500 rounded-md h-7 mt-2"
                >
                  <option selected>{{ elem.title }}</option>
                  <option v-for="(item, i) in elem.option" :key="i" value="">
                    {{ item }}
                  </option>
                </select>
                <input
                  v-else
                  v-model="elem.value"
                  :type="elem.type"
                  min="0"
                  :name="elem.title"
                  class="form-control h-7 mt-2"
                  :class="{
                    invisible: elem.type === 'none',
                    'p-0': elem.type === 'date'
                  }"
                  :placeholder="elem.title"
                  @change="filterHandler(elem)"
                />
              </td>
            </tr>
            <tr
              v-for="(doc, k) in docs"
              :key="k"
              class="border-r border-theme-2 h-12 text-center text-base font-normal hover:bg-theme-45"
              :class="{ 'bg-theme-2': k % 2 === 0 }"
            >
              <Cell
                :elementi="$FormatDate(doc.last_update)"
                class="whitespace-nowrap py-3 px-1"
              />
              <Cell
                :elementi="
                  doc.description ? doc.description : doc.file_original_name
                "
                class="whitespace-nowrap py-3 px-1"
              />
              <Cell
                :elementi="
                  doc.staff_user.first_name && doc.staff_user.last_name
                    ? doc.staff_user.last_name + ' ' + doc.staff_user.first_name
                    : doc.staff_user.username
                "
                class="whitespace-nowrap py-3 px-1"
              />
              <td class="flex text-xl text-center lg:block">
                <a
                  :href="doc.file_url"
                  target="blank"
                  class="cursor-pointer tooltipbutton"
                >
                  <i class="p-3 fas fa-file-pdf" />
                  <span
                    class="tooltipbuttontext"
                    style="margin-top: 2rem; margin-left: 3rem"
                    >Apri PDF</span
                  >
                </a>
                <a class="cursor-pointer" @click="handleDeleteFile(doc)">
                  <i class="p-3 fas fa-times" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
    </div>
  </div>
  <!-- END: Attachments list -->
</template>

<script>
import { defineComponent } from 'vue'
import Paginator from '../paginator/Main'
import THeader from '../cvit-table/THeader.vue'
import Cell from '../../components/cvit-table/Cell.vue'
import AttachementComponent from './AttachementComponent.vue'
import axios from '../../gateway/backend-api'

const forPageOption = [5, 10, 50]
export default defineComponent({
  components: {
    Paginator,
    THeader,
    Cell,
    AttachementComponent
  },
  props: {
    customerDetails: Object,
    active: {
      type: [Boolean],
      default: true
    }
  },

  data() {
    return {
      filterActive: false,
      active_filters: {},
      // conferma_remove: false,
      // show_fileModal: false,
      docs: null,
      tableData: {
        config: [
          {
            key: 'last_update',
            title: 'Data'
          },
          {
            key: 'file_original_name',
            title: 'Descrizione documento'
          },
          {
            key: 'staff_user',
            title: 'Medico'
          },
          {
            key: 'quick-actions',
            title: ''
          }
        ],
        input: [
          {
            key: 'last_update',
            title: 'Emessa il',
            type: 'date'
          },
          {
            key: 'file_original_name',
            title: 'Descrizione documento',
            type: 'text'
          },
          {
            key: 'staff_user',
            title: 'Medico',
            type: 'text'
          }
        ]
      },
      totalRecords: '',
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      option: {
        url: 'https://httpbin.org/post',
        createImageThumbnails: true,
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        maxFiles: 1,
        uploadMultiple: false,
        // headers: { 'My-Awesome-Header': 'header value' },
        addRemoveLinks: true,
        dictCancelUpload: 'Rimuovi file',
        dictRemoveFile: 'Rimuovi file',
        // dictRemoveFileConfirmation:
        //   'Attenzione! \nStai rimuovendo il file caricato',
        clickable: true,
        processing(file) {
          const modal = document.getElementById('static-backdrop-modal-preview')

          modal.classList.remove('hidden')

          if (file.previewElement) {
            file.previewElement.classList.add('dz-processing')
            if (file._removeLink) {
              return (file._removeLink.innerHTML =
                this.options.dictCancelUpload)
            }
          }
        },
        removedfile(file) {
          file.previewElement.remove()
          const modal = document.getElementById('static-backdrop-modal-preview')
          modal.classList.add('hidden')
        }
      }
    }
  },
  mounted() {
    if (this.active) this.getDocs()
  },
  methods: {
    getDocs() {
      axios
        .post('/' + localStorage.getItem('clinic') + '/attachments', {
          customer_id: this.customerDetails.id,
          type: 'documents',
          active_filters: this.active_filters,
          per_page: this.pagination.forPage,
          page: this.pagination.page
        })
        .then((res) => {
          this.docs = res.data.data
          this.totalRecords = res.data.total
        })
    },
    closeModal: function () {
      const modal = document.getElementById('static-backdrop-modal-preview')
      modal.classList.add('hidden')
    },
    changePagination: function (value) {
      if (this.pagination.page != value) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        this.getDocs()
      }
    },
    changeNumPages: function (elem) {
      if (this.pagination.forPage != elem.target.value) {
        this.pagination.forPage = elem.target.value
        this.getDocs()
      }
    },
    handleDeleteFile: function (doc) {
      axios
        .delete(
          '/' +
            localStorage.getItem('clinic') +
            '/attachments/' +
            doc.id +
            '/delete-file'
        )
        .then(() => {
          this.getDocs()
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'File rimosso con successo!'
          })
        })
    },

    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()

      if (this.filterActive) this.getDocs()
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    }
  }
})
</script>
