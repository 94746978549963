<template>
  <!-- BEGIN: Attachments list -->
  <div class="pb-4 my-2">
    <div
      class="flex flex-col items-center md:justify-center relative md:px-5 md:py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5"
    >
      <h2 class="font-medium text-base mb-2 md:mb-0">
        Allegati di {{ petDetails.name }}
      </h2>
      <Dropzone :options="option" class="dropzone w-full mt-4">
        <div class="text-lg font-medium">
          Trascina un documento in questa zona per aggiungerlo o clicca qui per
          aggiungerlo manualmente
        </div>
        <div class="text-gray-600">
          Trascinando qui un documento potrai aggiungerlo direttamente agli
          allegati di {{ petDetails.name }}.
        </div>
      </Dropzone>
    </div>
    <div class="p-5">
      <!-- BEGIN: Data List -->

      <div class="paginator-container">
        <div class="prova mr-5 my-2 flex justify-between flex-1">
          <div class="my-1">
            <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
              >Mostra
            </span>

            <select
              id=""
              name=""
              class="form-select w-16 h-6 pr-2 pl-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
              @click="(event) => changeNumPages(event)"
            >
              <option v-for="show in forPageOption" :key="show" :value="show">
                {{ show }}
              </option>
            </select>

            <span class="text-md font-bold ml-2 mt-1"> elementi</span>
          </div>
        </div>

        <Paginator
          :for-page-option="pagination.forPage"
          :total-records="totalRecords"
          @changePagination="(event) => changePagination(event)"
        />
      </div>
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table
          class="w-full shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <THeader
            v-for="(item, itemKey) in tableData.config"
            :key="itemKey"
            :name="item.title"
          />
          <tbody>
            <tr>
              <td
                class="whitespace-nowrap text-center pr-3 pb-3"
                v-for="(elem, index) in tableData.input"
                :key="index"
              >
                <select
                  class="w-full border rounded-md h-7 text-gray-500 mt-2"
                  v-if="elem.type === 'select'"
                >
                  <option selected>{{ elem.title }}</option>
                  <option
                    v-for="(item, index) in elem.option"
                    :key="index"
                    value=""
                  >
                    {{ item }}
                  </option>
                </select>
                <input
                  :type="elem.type"
                  min="0"
                  :name="elem.title"
                  class="form-control h-7 mt-2"
                  v-else
                  :class="{
                    invisible: elem.type === 'none',
                    'p-0': elem.type === 'date'
                  }"
                  :placeholder="elem.title"
                />
              </td>
            </tr>
            <tr
              v-for="(faker, fakerKey) in $_.take($f(), 6)"
              :key="fakerKey"
              class="h-12 text-base font-normal hover:bg-blue-200 cursor-pointer"
              :class="{ 'bg-blue-100': fakerKey % 2 === 0 }"
            >
              <Cell
                @click="testClick()"
                :elementi="faker.dates[0]"
                class="border-r border-gray-500"
              />
              <Cell
                :elementi="faker.products[0].category"
                class="border-r border-gray-500"
              />
              <Cell
                :elementi="faker.products[0].category"
                class="border-r border-gray-500"
              />
              <td class="flex lg:block text-center text-xl">
                <!--                <a class="cursor-pointer">-->
                <!--                  <i class="fas fa-mobile-alt w-6"/>-->
                <!--                </a>-->
                <a class="cursor-pointer border-r border-gray-500">
                  <i class="fas fa-file-pdf p-3" />
                </a>
                <!--                <a class="cursor-pointer border-r border-gray-500">-->
                <!--                  <i class="fas fa-envelope p-3"/>-->
                <!--                </a>-->
                <a
                  data-toggle="modal"
                  data-target="#static-backdrop-modal-preview"
                  class="cursor-pointer border-r border-gray-500"
                >
                  <i class="fas fa-eye p-3" />
                </a>
                <a class="cursor-pointer">
                  <i class="fas fa-times p-3" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
    </div>
  </div>
  <!-- END: Attachments list -->
</template>

<script>
import { defineComponent } from 'vue'
import Paginator from '../../components/paginator/Main.vue'
import THeader from '../../components/cvit-table/THeader.vue'
import Cell from '../../components/cvit-table/Cell.vue'

const forPageOption = [10, 50, 100]
export default defineComponent({
  props: {
    petDetails: Object
  },
  components: {
    Paginator,
    THeader,
    Cell
  },
  data() {
    return {
      // test: 0,
      tableData: {
        config: [
          {
            key: 'release',
            title: 'Data'
          },
          {
            key: 'description',
            title: 'Descrizione documento'
          },
          {
            key: 'doctor',
            title: 'Medico'
          },
          {
            key: 'quick-actions',
            title: ''
          }
        ],
        input: [
          {
            key: 'release',
            title: 'Emessa il',
            type: 'date'
          },
          {
            key: 'description',
            title: 'Descrizione documento',
            type: 'text'
          },
          {
            key: 'doctor',
            title: 'Medico',
            type: 'text'
          }
        ]
      },
      totalRecords: '',
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      option: {
        url: 'https://httpbin.org/post',
        createImageThumbnails: true,
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        // maxFiles: 1,
        // headers: { 'My-Awesome-Header': 'header value' },
        addRemoveLinks: true,
        dictCancelUpload: 'Rimuovi file',
        dictRemoveFile: 'Rimuovi file',
        dictRemoveFileConfirmation:
          'Attenzione! \nStai rimuovendo il file caricato',
        clickable: true
      }
    }
  },
  methods: {
    testClick: function () {
      this.test = 1
    },
    closeModal: function () {
      const modal = document.getElementById('static-backdrop-modal-preview')
      modal.classList.add('hidden')
    },
    changePagination: function (value) {
      this.pagination.page = value.page
      this.pagination.forPage = value.forPage
    }
  }
})
</script>
