<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-12 p-2 mx-auto overflow-scroll scrollbar-none border sm:w-3/5 w-full shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="flex flex-col content-end overflow-y-auto">
        <!-- Modal header -->
        <div class="flex items-start p-5 rounded-t">
          <h3
            class="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white"
          >
            {{ title }}
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="this.$emit('close')"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div class="p-6 space-y-6">
          <div class="flex flex-col content-start mb-1">
            <button
              v-for="x in options"
              :key="x"
              type="button"
              class="w-20 mb-2 inline-flex justify-center rounded-md border bg-theme-8 border-transparent shadow-sm px-3 py-2 text-base font-medium text-theme-48 hover:bg-theme-30 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              :class="selected === x ? 'bg-theme-9' : ''"
              @click="select(x)"
            >
              {{ x.document_type ? x.document_type : '--' }}
            </button>
          </div>
        </div>
        <!-- Modal footer -->
        <div
          class="border-t-1 border-gray-400 w-full px-6 text-right flex justify-end items-end mb-2"
        >
          <button
            @click="salva"
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
            ref="input4"
          >
            <SaveIcon class="h-5 w-5 mr-2" /> Seleziona
          </button>
          <button
            @click="this.$emit('close')"
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            <span class="h-5"></span>
            Annulla
          </button>
        </div>
        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

import '@swoga/vue-collapsible-panel/dist/vue-collapsible-panel.css'

export default defineComponent({
  props: ['options', 'title'],
  components: {},
  setup(_, { emit }) {
    // ----- DATA -----
    const selected = ref('')

    // contiene la copia della configurazione, mappando però i valori con quelli inseriti in input

    // ----- METHODS -----

    // abilito la modifica del modale

    const select = (x) => {
      // salva dati
      selected.value = x
    }

    const salva = () => {
      // salva dati
      emit('specialisticSelected', selected.value)
    }

    // ----- COMPUTED -----

    // righe di config senza le sottosezioni

    return {
      selected,
      select,
      salva
    }
  }
})
</script>
