<template>
  <div class="login w-screen">
    <div class="h-screen flex items-center mx-24">
      <div
        class="flex-1 grid grid-cols-10 gap-5 lg:gap-1 items-center -mt-10 place-content-center justify-center"
      >
        <!-- BEGIN: Login Info -->
        <div
          class="col-span-10 lg:col-span-6 mx-36 mb-8 lg:mx-16 -mt-20 lg:-mt-10"
        >
          <img
            class="login_logo"
            alt="logo-vetsgo"
            src="@/assets/images/logo-blue.svg"
          />
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->

        <div class="col-span-10 lg:col-span-4 mx-3 md:mx-10 flex flex-col">
          <h2
            class="nunito text-theme-40 intro-x text-xl lg:text-2xl text-center"
          >
            Imposta la tua nuova password
          </h2>
          <div
            class="intro-x w-full mt-8 flex flex-col gap-5 place-content-center"
          >
            <input type="hidden" name="_token" :value="csrf" />
            <input
              type="password"
              class="custom-input h-10 rounded-full w-3/5 lg:w-full mx-auto"
              name="newPassword"
              id="newPassword"
              v-model="new_psw"
              placeholder="Nuova password"
            />
            <input
              type="password"
              class="custom-input h-10 rounded-full w-3/5 lg:w-full mx-auto"
              name="confirmPassword"
              v-model="new_psw_confirm"
              id="confirmPassword"
              placeholder="Conferma nuova password"
            />
            <div class="py-2">
              <h2 class="text-xs font-semibold">
                La password deve essere composta da:
              </h2>
              <div class="flex flex-wrap items-center mt-1">
                <pre class="text-xs mr-1">
• Almeno 8 caratteri
• Almeno un carattere appartenente alle lettere maiuscole
• Almeno un carattere appartenente alle lettere minuscole
• Almeno un carattere numerico
• Almeno un carattere speciale
                </pre>
              </div>
            </div>
            <p v-if="errorePassword" class="text-red-600 text-s">
              {{ errorePassword }}
            </p>
          </div>
          <div class="intro-x mt-5 xl:mt-8 text-center xl:text-center">
            <button class="orange-btn w-48 text-lg" @click="changePwd">
              Cambia password
            </button>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from 'vue'

import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
/* import router from '@/router'
import axios from '../../gateway/backend-api' */

export default defineComponent({
  components: {},

  setup() {
    const new_psw = ref(null)
    const new_psw_confirm = ref(null)
    const passwordDone = ref(false)
    const errorePassword = ref(null)

    const store = useStore()
    const route = useRoute()

    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login_body')
    })

    const checkPsw = () => {
      let charValidator = new RegExp(
        '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
      )

      errorePassword.value = null
      if (
        !new_psw.value ||
        !new_psw_confirm.value ||
        new_psw.value === '' ||
        new_psw_confirm.value === ''
      ) {
        errorePassword.value = 'Si prega di compilare tutti i campi'
        return false
      }
      if (new_psw.value !== new_psw_confirm.value) {
        errorePassword.value = 'I due campi non corrispondono'
        return false
      }

      if (
        !charValidator.test(new_psw.value) ||
        !charValidator.test(new_psw_confirm.value)
      ) {
        errorePassword.value = 'I requisiti della password non sono soddisfatti'
        return false
      }

      return true
    }

    const changePwd = () => {
      let controlPsw = checkPsw()
      if (!controlPsw) return false
      store.dispatch('changePassword', {
        token: route.query.token,
        email: route.query.email,
        password: new_psw.value,
        password_confirmation: new_psw_confirm.value
      })
    }

    return {
      new_psw,
      new_psw_confirm,
      errorePassword,
      passwordDone,
      changePwd
    }
  },
  data() {
    return {
      csrf: document.head.querySelector('meta[name="csrf-token"]')
        ? document.head.querySelector('meta[name="csrf-token"]').content
        : ''
    }
  },
  methods: {
    changePassword: function () {
      this.$store.dispatch('changePassword', {
        token: this.$route.query.token,
        email: this.$route.query.email,
        password: this.new_psw,
        password_confirmation: this.new_psw_confirm
      })
    }
  }
})
</script>
