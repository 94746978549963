<!--Cell general component created from tailwind ui table component -->
<template>
  <td class="">
    <div class="text-sm">
      <p class="break-words">{{ elementi }}</p>
    </div>
  </td>
</template>

<script>
/* eslint-disable eol-last */
export default {
  props: {
    elementi: Object
  },
  created() {}
}
</script>

<style></style>
