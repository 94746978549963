<template>
  <!-- BEGIN: Responsive Table -->
  <div
    v-if="permissions && permissions?.pet?.view_pet_type?.enabled === true"
    class="p-2 pb-4 col-span-5 md:col-span-3 rounded-lg shadow-md mx-2 bg-white"
  >
    <div class="flex flex-col md:flex-row items-center justify-center py-2">
      <h2 class="modal-title w-full">Elenco specie e razze gestite</h2>
    </div>

    <!-- BEGIN: Elenco specie e razze con DIV -->
    <div class="">
      <!-- <div
          class="px-6 py-3 text-center text-xs uppercase tracking-wider font-bold"
        >
          Specie
        </div> -->

      <div class="px-1 py-2">
        <div
          v-if="
            permissions && permissions?.pet?.store_pet_type?.enabled === true
          "
          class="flex-1 text-right mx-1 my-2"
        >
          <button
            v-if="!showNewType"
            class="orange-btn px-2 py-1"
            @click="showNewType = true"
          >
            <PlusIcon class="h-5 w-5 mr-2" /> Aggiungi specie
          </button>
        </div>
        <div v-if="showNewType" class="px-6 text-right flex items-center">
          <v-select
            v-model="filter_type"
            :options="petTypesGlobal"
            label="name"
            placeholder="Seleziona specie"
            :reduce="(value) => value.id"
            class="py-2 flex-1"
          >
            <template #option="option">
              {{ option.name }}
            </template>
          </v-select>
          <div class="flex gap-1 justify-end">
            <button class="orange-btn" @click="newType()">Conferma</button>
            <button @click="showNewType = false" class="gray-btn">
              Annulla
            </button>
          </div>
        </div>
      </div>

      <div
        v-for="petType in petTypes"
        :key="petType"
        class="flex items-start gap-2 px-4 relative"
      >
        <vue-collapsible-panel-group accordion>
          <vue-collapsible-panel :id="petType.pet_type_id" :expanded="false">
            <template #title>
              <p class="w-full text-center nunito font-bold text-base">
                {{ petType.pet_type_name }}
              </p>
              <button
                v-if="
                  permissions &&
                  permissions?.pet?.delete_pet_type?.enabled === true
                "
                class="hover:text-theme-41"
                @click="deleteType(petType)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  class="h-4 fill-current absolute top-4 right-8"
                >
                  <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M160 400C160 408.8 152.8 416 144 416C135.2 416 128 408.8 128 400V192C128 183.2 135.2 176 144 176C152.8 176 160 183.2 160 192V400zM240 400C240 408.8 232.8 416 224 416C215.2 416 208 408.8 208 400V192C208 183.2 215.2 176 224 176C232.8 176 240 183.2 240 192V400zM320 400C320 408.8 312.8 416 304 416C295.2 416 288 408.8 288 400V192C288 183.2 295.2 176 304 176C312.8 176 320 183.2 320 192V400zM317.5 24.94L354.2 80H424C437.3 80 448 90.75 448 104C448 117.3 437.3 128 424 128H416V432C416 476.2 380.2 512 336 512H112C67.82 512 32 476.2 32 432V128H24C10.75 128 0 117.3 0 104C0 90.75 10.75 80 24 80H93.82L130.5 24.94C140.9 9.357 158.4 0 177.1 0H270.9C289.6 0 307.1 9.358 317.5 24.94H317.5zM151.5 80H296.5L277.5 51.56C276 49.34 273.5 48 270.9 48H177.1C174.5 48 171.1 49.34 170.5 51.56L151.5 80zM80 432C80 449.7 94.33 464 112 464H336C353.7 464 368 449.7 368 432V128H80V432z"
                  />
                </svg>
              </button>
              <div class="text-xs absolute top-4 right-16 ml-2">
                <button
                  v-if="
                    permissions &&
                    permissions?.pet?.store_pet_type?.enabled === true
                  "
                  class="hover:text-theme-41"
                  @click="newBreed(petType.pet_type_id)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    class="h-4 fill-current"
                  >
                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                    />
                  </svg>
                </button>
              </div>
            </template>
            <template #content>
              <div class="grid grid-cols-6 mx-2 p-3">
                <div
                  v-for="breed in petType.breeds"
                  :key="breed"
                  class="col-span-1 bg-theme-2 py-1 px-2 m-1 flex gap-1 justify-between items-start"
                >
                  <div>
                    <span class="text-theme-41 mr-1">• </span>
                    {{
                      breed && typeof breed.name !== 'undefined' && breed.name
                        ? breed.name
                        : ''
                    }}
                  </div>

                  <button
                    v-if="
                      permissions &&
                      permissions?.pet?.delete_pet_type?.enabled === true
                    "
                    @click="deleteBreed(petType, breed)"
                  >
                    <i
                      class="fas fa-times cursor-pointer text-theme-41 fill-current text-xxs"
                    />
                  </button>
                </div>
              </div>
            </template>
          </vue-collapsible-panel>
        </vue-collapsible-panel-group>
      </div>
    </div>
    <!-- END: Elenco specie e razze con DIV -->
  </div>
  <div v-if="showNewBreed">
    <NewBreedModal
      :typeId="newBreedTypeId"
      @close="handleShowNewBreed"
      @refresh="getTypes"
    />
  </div>
  <!-- END: Responsive Table -->
</template>
<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import axios from '../../../gateway/backend-api'
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel
} from '@swoga/vue-collapsible-panel'
// import '@swoga/vue-collapsible-panel/dist/vue-collapsible-panel.css'
import NewBreedModal from './NewBreedModal.vue'

export default defineComponent({
  components: {
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
    NewBreedModal
  },
  data() {
    return {
      showNewType: false,
      showNewBreed: false,
      newBreedTypeId: '',
      tableData: {
        config: [
          {
            key: 'species',
            title: 'Specie'
          }
        ],
        input: [
          {
            key: 'species',
            title: 'Specie',
            type: 'text'
          }
        ]
      },
      petTypes: {},
      petTypesGlobal: {}
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions'
    })
  },
  mounted() {
    this.getTypes()
    axios
      .get('/' + localStorage.getItem('clinic') + '/pet/global/pet-types')
      .then((res) => {
        this.petTypesGlobal = res.data
      })
  },
  methods: {
    getTypes() {
      axios
        .get('/' + localStorage.getItem('clinic') + '/pet/pet-types')
        .then((res) => {
          this.petTypes = res.data
        })
    },
    newType() {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'Aggiungi specie',
        subtitle: 'Sei sicuro di aggiungere le specie selezionate?',
        background: false,
        confirm: () => {
          axios
            .post('/' + localStorage.getItem('clinic') + '/pet/new-types', {
              pet_type_id: this.filter_type
            })
            .then((res) => {
              this.getTypes()
              this.$store.dispatch('commonComponents/openMessageModal', {
                title: 'Conferma',
                subtitle:
                  res.status === 201 || res.status === 200
                    ? 'Specie inserita/e con successo'
                    : 'Errore inserimento'
              })
            })
          this.showNewType = false
        },
        decline: () => {
          return false
        }
      })
    },
    newBreed(idType) {
      this.newBreedTypeId = this.petTypesGlobal.find((elem) => {
        if (elem.id == idType) return elem
      })
      this.handleShowNewBreed()
    },
    deleteType(type) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'Cancella specie',
        subtitle:
          'Sei sicuro di voler cancellare la specie ' +
          type.pet_type_name +
          '?',
        background: false,
        confirm: () => {
          axios
            .post(
              '/' + localStorage.getItem('clinic') + '/pet/delete-type-breed',
              {
                pet_type_id: type.pet_type_id
              }
            )
            .then((res) => {
              this.getTypes()
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message:
                  res.status === 200 ? 'Specie cancellata con successo' : res
              })
            })
        },
        decline: () => {
          return false
        }
      })
    },
    handleShowNewBreed() {
      this.showNewBreed = !this.showNewBreed
    },

    deleteBreed(type, breed) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'Cancella razza',
        subtitle: 'Sei sicuro di voler cancellare la razza ' + breed.name + '?',
        background: false,
        confirm: () => {
          axios
            .post(
              '/' + localStorage.getItem('clinic') + '/pet/delete-type-breed',
              {
                //pet_type_id: type.pet_type_id,
                pet_types_breed_id: breed.pet_types_breed_id
              }
            )
            .then((res) => {
              this.getTypes()
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message:
                  res.status === 200 ? 'Razza cancellata con successo' : res
              })
            })
        },
        decline: () => {
          return false
        }
      })
    }
  }
})
</script>

<style>
.vcp__header-icon {
  margin-right: 60px;
}
.vcp__header-icon:hover {
  color: #ff8f1c;
}
</style>
