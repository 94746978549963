<template>
  <div>
    <div
      id="iva-modal"
      class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50"
    >
      <div
        class="relative top-16 mt-1 mx-auto px-5 pt-3 pb-7 border shadow-lg rounded-md bg-white"
        :class="[isSetup == false ? 'w-3/5' : 'w-2/5']"
      >
        <button type="button" class="closing-btn" @click="$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <div class="flex flex-col">
          <div class="w-full">
            <h2 id="modal-title" class="modal-title mt-8">Seleziona IVA</h2>
            <!-- <div v-if="isSetup == false" class=""> -->
            <div class="py-2 flex flex-col items-center">
              <p class="flex-1 text-gray-500 p-3">
                Attraverso questa sezione puoi impostare l'iva
              </p>
            </div>
            <div
              class="flex flex-col justify-center md:flex-row my-2 text-center items-center gap-2"
            >
              <v-select
                ref="listIVa"
                v-model="iva_selected"
                style="width: 150px"
                class="py-2"
                placeholder="Seleziona una tra le opzioni"
                :options="filteredListIva"
                :reduce="(value) => value.vat_percentage"
                label="vat_percentage"
              >
                <template #selected-option="option">
                  Iva {{ option.vat_percentage }}%
                </template>
                <template #option="option">
                  Iva {{ option.vat_percentage }}%
                </template>
              </v-select>
            </div>
            <div
              class="flex flex-col justify-end md:flex-row my-2 text-center items-center gap-2"
            >
              <button
                type="button"
                class="orange-btn ml-3"
                @click="$emit('changeIva', iva_selected)"
              >
                Salva
              </button>
              <button
                type="button"
                class="gray-btn ml-3"
                @click="$emit('close')"
              >
                Annulla
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  event: ['close', 'changeCassaFattura'],
  props: {
    listIva: {
      type: Array,
      default() {
        return []
      }
    },
    ivaSelected: {
      type: String,
      default() {
        return null
      }
    }
  },
  setup(props) {
    const excludedValues = [10, 5, 4]
    const sortedListIva = ref(
      [...props.listIva].sort((a, b) => a.vat_percentage - b.vat_percentage)
    )
    const filteredListIva = ref(
      sortedListIva.value.filter(
        (item) => !excludedValues.includes(item.vat_percentage)
      )
    )

    const iva_selected = ref(props.ivaSelected)

    return {
      iva_selected,
      filteredListIva
    }
  }
})
</script>

<style></style>
