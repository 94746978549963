<template>
  <SelectPatientModal
    v-if="selectPetModal || selectVetModal"
    :title="selectModalTitle"
    :client="selectPetModal ? customerDetailsModal : null"
    :vets-selected="selectedVets"
    @close="closeSelectPet"
    @selectPatient="selectPatientConto"
    @selectVet="selectVetConto"
  />
  <div
    v-if="
      permissions &&
      permissions['conto aperto'] &&
      permissions['conto aperto']?.view_account?.enabled === true
    "
  >
    <div class="paginator-container">
      <div class="flex items">
        <div class="mr-5">
          <span class="flex-row text-sm ml-2 font-bold mr-2 mt-1">Mostra </span>
          <select
            v-model="pagination.forPage"
            class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
            @change="(event) => changeNumPages(event)"
          >
            <option
              v-for="show in forPageOption"
              :key="show"
              :value="show"
              class="form-select"
            >
              {{ show }}
            </option>
          </select>
          <span class="text-sm font-bold ml-2 mt-1"> elementi</span>
        </div>

        <input
          v-model="ricerca"
          type="text"
          placeholder="Cerca"
          class="pl-3 pr-10 py-1 border-2 border-gray-200 rounded-full focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500"
        />
      </div>
      <Paginator
        :for-page-option="pagination.forPage"
        :total-records="totalRecords"
        @changePagination="changePagination"
      />
    </div>
  </div>
  <div
    v-if="
      permissions &&
      permissions['conto aperto'] &&
      permissions['conto aperto']?.view_account?.enabled === true
    "
    class="contoaperto-cont overflow-auto scrollbar_custom"
  >
    <table
      class="w-full shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mt-2"
    >
      <thead class="">
        <tr>
          <th
            v-for="(item, itemKey) in tableHeader_unpaid.config"
            :key="itemKey"
            class="t-header"
          >
            <div v-if="item.icon">
              <i :class="item.icon" class="text-sm" />
            </div>
            <div v-else>
              {{
                item.key === 'total'
                  ? iva === false
                    ? item.title + ' senza IVA'
                    : item.title + ' con IVA'
                  : item.title
              }}
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="serviceRows">
          <td
            v-for="(elem, index) in tableHeader_unpaid.input"
            :key="index"
            class="text-center pr-3 pb-3"
          >
            <select
              v-if="elem.type === 'select'"
              v-model="table_filter[elem.key]"
              class="border rounded-md h-7 text-gray-500 mt-2"
            >
              <option selected></option>
              <option v-for="(item, i) in elem.option" :key="i" :value="item">
                {{ item.name ? item.name : item }}
              </option>
            </select>
            <input
              v-else
              v-model="elem.value"
              :type="elem.type"
              min="0"
              :name="elem.title"
              class="form-control h-7 mt-2"
              :class="{
                invisible: elem.type === 'none',
                'p-0': elem.type === 'date'
              }"
              :placeholder="elem.title"
              @change="filterHandler(elem)"
            />
          </td>
        </tr>
        <tr
          v-for="(row, index) in serviceRows"
          :key="index"
          class="text-center text-base font-normal hover:bg-theme-44 cursor-pointer py-1"
          :class="{ 'bg-theme-2': index % 2 === 0 }"
        >
          <td class="text-center px-1 border-r border-theme-45">
            <TableShotColor :stato="row.status" />
          </td>
          <td class="border-r px-1 border-theme-45 text-center text-sm">
            {{ $FormatDateMin(row.last_update) }}
          </td>
          <td class="text-left px-1 border-r border-theme-45 text-sm">
            <p>
              {{
                row.tariffario_alias && row.tariffario_alias !== ''
                  ? row.tariffario_alias
                  : row.tariffario_description
              }}
              <br />
              <span v-if="row.type !== 'alias'" class="text-xs">{{
                row.tariffario_description
              }}</span>
            </p>
          </td>
          <td class="whitespace-nowrap px-1 border-r border-theme-45">
            <div
              v-if="row.customer_id"
              class="text-left"
              @click="handleGoToCustomer(row.customer_id)"
            >
              <ChevronsRightIcon class="orange-round" />

              {{
                row.customer_first_name && row.customer_last_name
                  ? row.customer_first_name + ' ' + row.customer_last_name
                  : row.customer_ragione_sociale
                  ? row.customer_ragione_sociale
                  : '--'
              }}
            </div>
          </td>

          <!-- <td class="text-center px-1  border-r border-theme-45">
              <i class="fas fa-truck text-gray-400" />
            </td> -->
          <td class="text-center px-1 border-r border-theme-45 text-sm">
            <template v-if="row.pet_name">
              {{ row.pet_name }}
            </template>
            <i
              v-else
              class="fas fa-paw w-4 h-4 cursor-pointer hover:text-theme-41 fill-current"
              @click="
                row.status != 'completed' &&
                row.status != 'debito' &&
                permissions['conto aperto'] &&
                permissions['conto aperto']?.edit_account?.enabled === true
                  ? openSelectPet(row)
                  : () => {}
              "
            />
          </td>
          <td
            class="text-center px-1 border-r border-theme-45 text-sm"
            @click="
              row.status != 'completed' &&
              row.status != 'debito' &&
              permissions['conto aperto'] &&
              permissions['conto aperto']?.edit_account?.enabled === true
                ? openSelectVet(row.id, row.performed_by)
                : () => {}
            "
          >
            <template v-if="row.performed_by">
              <span v-for="p in row.performed_by" :key="p">
                {{ p.full_name }} <br />
              </span>
            </template>
            <template v-else>
              <button v-if="!selectedVet[index]">
                <i
                  class="fas fa-user-md text-base cursor-pointer hover:text-theme-41 fill-current"
                />
              </button>
              <select v-else>
                <option v-for="vet in vets" :key="vet.id">
                  {{ vet.last_name }} {{ vet.first_name }}
                </option>
              </select>
            </template>
          </td>
          <!-- <td class="text-center px-1  border-r border-theme-45">
              <span v-if="row.deleted_at"
                ><i class="fas fa-check text-gray-400"
              /></span>
              <span v-else><i class="fas fa-times text-theme-41" /></span>
            </td> -->

          <td class="border-r px-1 border-theme-45 text-center">
            <div class="flex items-center whitespace-nowrap justify-center">
              € {{ Math.round((row.price * 1 + Number.EPSILON) * 100) / 100 }}
            </div>
          </td>
          <td class="px-1 border-r border-theme-45 text-center">
            <input
              v-if="
                row.status != 'completed' &&
                row.status != 'debito' &&
                permissions &&
                permissions['conto aperto'] &&
                permissions['conto aperto']?.edit_account?.enabled === true &&
                !global
              "
              type="number"
              :value="row.qty"
              class="w-12 custom-input"
              :readonly="row.status == 'completed' && row.status == 'debito'"
              @change="(event) => updateQty(row.id, event.target.value)"
            />
            <span v-else>{{ row.qty }}</span>
          </td>
          <td
            class="px-1 border-r border-theme-45 text-center items-center justify-center"
          >
            <input
              v-if="
                row.status != 'completed' &&
                row.status != 'debito' &&
                permissions &&
                permissions['conto aperto'] &&
                permissions['conto aperto']?.edit_account?.enabled === true &&
                !global
              "
              type="number"
              :value="row.sconto"
              class="w-12 custom-input"
              :readonly="row.status == 'completed' && row.status == 'debito'"
              @change="(event) => updateSconto(row.id, event.target.value)"
            />
            <span v-else>{{ row.sconto }}</span> %
          </td>

          <td class="px-1 border-r border-theme-45 text-center">
            <div class="flex items-center justify-center whitespace-nowrap">
              € {{ parseFloat(row.total).toFixed(2) }}
            </div>
          </td>
          <td class="">
            <div class="flex items-center justify-center">
              <span
                class="whitespace-nowrap flex items-center justify-center text-center"
              >
                {{ row.n_bill ? 'Nr. ' + row.n_bill : ' - - -' }}
              </span>

              <div
                v-if="row.from_active_bill"
                class="ml-2"
                @click="$emit('showPdf', row.from_active_bill, row.n_bill)"
              >
                <a class="cursor-pointer hover:text-theme-41 fill-current">
                  <i class="fas fa-file-pdf fill-current text-lg" />
                </a>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- END: Data List -->
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import axios from '../../gateway/backend-api'
import Paginator from '../../components/paginator/Main'
import SelectPatientModal from '../modals/SelectPatientModal.vue'
import TableShotColor from '../../components/details-customer/TableShotColor.vue'

const forPageOption = [5, 10, 20, 50]
export default defineComponent({
  components: {
    Paginator,
    TableShotColor,
    SelectPatientModal
  },

  props: {
    global: {
      type: [Boolean],
      default: function () {
        return false
      }
    },
    client: {
      type: [Object],
      default: function () {
        return null
      }
    },
    docs: {
      type: [Array],
      default: function () {
        return null
      }
    }
  },
  emits: [
    'prenotaPreventivo',
    'fatturaPreventivo',
    'showInvoice',
    'getBills',
    'refreshTable',
    'showPdf',
    'editInvoice'
  ],

  setup() {
    return {}
  },
  data() {
    return {
      selectPetModal: false,
      customerDetailsModal: null,
      selectVetModal: false,
      selectedVets: null,
      selectModalTitle: null,
      vets: [],
      selectedVet: [],
      filterActive: false,
      active_filters: {},
      table_filter: [],
      tableHeader_unpaid: {
        config: [
          {
            key: 'status',
            title: 'Stato'
          },
          {
            key: 'created',
            title: 'Creato il'
          },
          {
            key: 'product',
            title: 'Prestazione/Prodotto'
          },
          // {
          //   key: 'warehouse',
          //   icon: 'fas fa-truck'
          // },
          { key: 'client', title: 'Cliente' },
          {
            key: 'patient',
            title: 'Paziente'
          },
          {
            key: 'staff_user',
            title: 'Eseguito da'
          },
          // {
          //   key: 'att',
          //   title: 'Att.'
          // },
          {
            key: 'price',
            title: 'Prezzo'
          },
          {
            key: 'qty',
            title: 'Qtà'
          },
          {
            key: 'discount',
            title: 'Sconto/Magg.'
          },
          {
            key: 'total',
            title: 'Totale'
          },
          { key: 'n_bill', title: 'Fattura', type: 'number' }
        ],
        input: [
          {
            key: 'status',
            title: 'Stato',
            type: 'select',
            option: []
          },
          {
            key: 'created',
            title: 'Creato il',
            type: 'date'
          },
          {
            key: 'product',
            title: 'Prestazione/Prodotto',
            type: 'text'
          },
          {
            key: 'client',
            title: 'Cliente',
            type: 'text'
          },
          // {
          //   key: 'warehouse',
          //   title: '',
          //   type: 'none'
          // },
          {
            key: 'patient',
            title: 'Paziente',
            type: 'none'
          },
          {
            key: 'staff_user',
            title: 'Eseguito da',
            type: 'text'
          },
          // {
          //   key: 'att',
          //   type: 'none'
          // },
          {
            key: 'price',
            title: 'Prezzo',
            type: 'number'
          },
          {
            key: 'qty',
            title: 'Quantità',
            type: 'number'
          },
          {
            key: 'discount',
            title: 'Sconto',
            type: 'number'
          },
          {
            key: 'total',
            title: 'Totale',
            type: 'number'
          },
          { key: 'n_bill', title: 'Fattura', type: 'number' }
        ]
      },
      totalRecords: '',
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      serviceRows: [],
      documentsToShow: [],
      multipleChecks: [],
      invoiceModalOpen: false,
      servizio: '',
      checkedServices: [],
      services: [],
      clientForInvoice: null,
      openTariffario: false,
      accontoModal: false,
      selected_row: null,
      newAccontoFattura: false,
      notaDebito: false,
      newInvoiceTitle: null
    }
  },
  computed: {
    ...mapGetters({
      iva: 'reusableData/getShowIva',
      permissions: 'reusableData/getPermissions',
      ivaPerc: 'reusableData/getRegimeFiscaleIvaPerc',
      getPetsDetails: 'anagraficaPazienti/getPetsDetails',
      getComparisonPets: 'anagraficaPazienti/getComparisonPets',
      getCustomersDetails: 'anagraficaClienti/getCustomersDetails',
      getComparisonCustomer: 'anagraficaClienti/getComparisonCustomer'
    })
  },
  watch: {
    'table_filter.status': function (elem) {
      this.filterSelect(elem, 'status')
    }
  },
  created() {
    const vm = this
    axios
      .get('/' + localStorage.getItem('clinic') + '/account/accountStatusMap')
      .then((res) => {
        Object.keys(res.data).forEach(function (k) {
          vm.tableHeader_unpaid.input[0].option.push({
            name: k,
            value: res.data[k]
          })
        })
      })
  },
  mounted() {
    this.getServices()
  },
  methods: {
    ...mapActions({
      getPetAction: 'anagraficaPazienti/getPetAction',
      getCustomerAction: 'anagraficaClienti/getCustomerAction',
      updateClientData: 'anagraficaClienti/updateClientData'
    }),
    filterSelect(elem, field) {
      if (elem) {
        this.active_filters[field] = elem.id ? elem.id : elem.value
      } else this.active_filters[field] = undefined

      this.checkIfFilterActive()

      if (this.filterActive) this.getServices()
    },
    handleGoToCustomer(customer_id) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .get('/' + localStorage.getItem('clinic') + '/customer/' + customer_id)
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$goToCustomer(res.data)
        })
        .catch(() =>
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        )
    },
    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()

      if (this.filterActive) this.getServices()
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    },
    getServices() {
      var params = {
        active_filters: this.active_filters,
        iva: this.iva,
        page: this.pagination.page, //this.pagination.page,
        per_page: this.pagination.forPage //this.pagination.forPage
      }
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/account', params)
        .then((res) => {
          this.serviceRows = res.data.data
          this.totalRecords = res.data.total
          //this.fakePagination()
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    changePagination: function (value) {
      if (this.pagination.page != value.page) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        this.getServices()
      }
    },
    changeNumPages: function (elem) {
      this.pagination.forPage = elem.target.value
      this.getServices()

      // if (this.pagination.forPage != elem.target.value) {
      //   this.pagination.forPage = elem.target.value
      //   this.getServices()
      // }
    },
    openSelectPet(row) {
      this.selectPetModal = true
      this.selectModalTitle = 'Selezionare paziente'
      this.selected_row = row.id
      // inserire  client in customerDetailsModal
      this.customerDetailsModal = { id: row.customer_id }
    },
    openSelectVet(id_row, performed_by) {
      this.selectVetModal = true
      this.selectModalTitle = 'Selezionare Veterinario'
      this.selected_row = id_row
      if (performed_by) this.selectedVets = performed_by
    },
    closeSelectPet() {
      this.selectPetModal = false
      this.selectVetModal = false
      this.selectedVets = null
      this.selected_row = null
      this.selectModalTitle = null
    },
    selectPatientConto(patient) {
      axios
        .post('/' + localStorage.getItem('clinic') + '/account/edit', {
          pet_id: patient.id,
          account_ids: [this.selected_row]
        })
        .then(() => {
          this.getServices()
        })
      this.closeSelectPet()
    },
    selectVetConto(vets) {
      let send = []
      if (vets)
        vets.forEach((el) => {
          send.push(el.id)
        })
      axios
        .post('/' + localStorage.getItem('clinic') + '/account/edit', {
          //staff_user_id: vet.id,
          performed_by: send,
          account_ids: [this.selected_row]
        })
        .then(() => {
          this.getServices()
        })
      this.closeSelectPet()
    }
  }
})
</script>
