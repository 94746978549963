<template>
  <!-- Main modal -->
  <div
    id="defaultModal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none s"
  >
    <div
      class="w-4/5 sm:w-3/5 relative top-12 p-5 mx-auto overflow-scroll scrollbar-none border shadow-lg rounded-md bg-white"
    >
      <button type="button" class="closing-btn" @click="$emit('close')">
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>

      <!-- Modal content -->
      <div class="flex flex-col">
        <!-- Modal header -->
        <div class="w-full">
          <h3 class="modal-title mt-8">Note a piè di pagina</h3>
        </div>
        <!-- Modal body -->

        <div class="flex flex-col content-start my-2">
          <div
            v-if="showNewTemplate == false && freeTemplate === false"
            class="flex items-center mt-2 gap-2"
          >
            <div class="flex-1">
              <label class="form-label mt-2">Template: </label>
              <select
                v-if="listNote != ''"
                id=""
                v-model="selectedTemplate"
                name=""
                class="custom-input"
              >
                <option
                  v-for="n in listNote"
                  :key="n.id"
                  :value="n"
                  :selected="n.id === selectedTemplate?.id"
                >
                  {{ n.title }}
                </option>
              </select>
            </div>
            <div class="flex-0 mt-4">
              <button type="button" @click="showAggiungiTemplate">
                <PlusIcon
                  class="h-5 w-5 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                />
                Crea template
              </button>
              <button type="button" @click="() => (freeTemplate = true)">
                <PlusIcon
                  class="h-5 w-5 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                />
                Nota libero
              </button>
            </div>
          </div>

          <p
            v-if="selectedTemplate && !showNewTemplate & !freeTemplate"
            class="p-4 pt-8"
            v-html="selectedTemplate.text"
          ></p>

          <div v-if="showNewTemplate == true" class="flex flex-col mt-3">
            <div class="flex flex-col">
              <label class="form-label mt-2 required">Titolo </label>
              <input
                ref="input2"
                v-model="titolo"
                type="text"
                class="custom-input"
                required
                @keyup.enter="switchFocus(3)"
              />
            </div>

            <div class="flex flex-col">
              <label class="form-label mt-2 required">Messaggio </label>
            </div>
            <div class="h-50 col-span-12 mt-2 flex flex-col">
              <QuillEditor
                ref="input3"
                v-model:content="message"
                theme="snow"
                content-type="html"
                class="form-control h-36"
                @keyup.enter="switchFocus(4)"
              />
            </div>

            <div v-if="isRequired == true" class="my-2">
              <h2 class="text-theme-41 font-bold italic">
                Compilare i campi obbligatori
              </h2>
            </div>

            <div class="mt-5 flex items-center justify-end gap-2">
              <button type="button" class="orange-btn" @click="addNoteSetup">
                <PlusIcon class="w-4 h-4" /> Aggiungi
              </button>

              <button
                type="button"
                class="gray-btn"
                @click="showAggiungiTemplate"
              >
                Annulla
              </button>
            </div>
          </div>
          <div v-if="freeTemplate === true" class="flex flex-col mt-3">
            <div class="flex flex-col">
              <label class="form-label mt-2 required">Messaggio </label>
            </div>
            <div class="h-50 col-span-12 mt-2 flex flex-col">
              <QuillEditor
                v-model:content="new_message"
                theme="snow"
                content-type="html"
                class="form-control h-36"
              />
            </div>

            <div v-if="isRequired == true" class="my-2">
              <h2 class="text-theme-41 font-bold italic">
                Compilare i campi obbligatori
              </h2>
            </div>

            <div class="mt-5 flex items-center justify-end gap-2">
              <button type="button" class="orange-btn" @click="addFreeNote">
                <PlusIcon class="w-4 h-4" /> Aggiungi
              </button>

              <button
                type="button"
                class="gray-btn"
                @click="() => (freeTemplate = false)"
              >
                Annulla
              </button>
            </div>
          </div>
        </div>

        <!-- Modal footer -->

        <!-- BEGIN: Footer per il modale in fatturazione -->
        <div
          v-if="!comingFromSetup && !showNewTemplate && !freeTemplate"
          class="mt-5 w-full px-6 text-right flex justify-end items-end mb-2 gap-3"
        >
          <button
            id="dialogbox-delete-button-fatturazione"
            ref="input4"
            type="button"
            class="orange-btn"
            @click="addNote"
          >
            <SaveIcon class="h-5 w-5 mr-2" /> Salva
          </button>
          <button
            v-if="!isFirst"
            type="button"
            class="gray-btn"
            @click="deleteNote"
          >
            Cancella
          </button>
          <button type="button" class="gray-btn" @click="$emit('close')">
            <span class="h-5"></span>
            Annulla
          </button>
        </div>
        <!-- END: Footer per il modale in fatturazione -->

        <!-- BEGIN: Footer per il modale in setup clinica -->
        <div
          v-if="!showNewTemplate && comingFromSetup"
          class="flex items-center gap-2 justify-end mt-4"
        >
          <button
            id="dialogbox-delete-button-setup"
            ref="input4"
            type="button"
            class="orange-btn"
            @click="addNote"
          >
            <SaveIcon class="h-5 w-5 mr-2" /> Salva
          </button>
          <button
            v-if="!isFirst"
            type="button"
            class="gray-btn-inverted"
            @click="deleteNote"
          >
            Cancella
          </button>
          <button type="button" class="gray-btn" @click="$emit('close')">
            <span class="h-5"></span>
            Annulla
          </button>
        </div>
        <!-- END: Footer per il modale in setup clinica -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../gateway/backend-api'
export default {
  props: {
    comingFromSetup: Object,
    oldNote: Object,
    oldTemplate: Object
  },
  emits: ['addNote'],
  data() {
    return {
      text: '',
      title: '',
      textMessage: '',
      listNote: [],
      selectedTemplate: '',
      message: '',
      isFirst: true,
      titleText: '',
      showNewTemplate: false,
      id: null,
      isRequired: false,
      freeTemplate: false,
      new_message: ''
    }
  },
  watch: {
    selectedTemplate: function (elem) {
      this.title = elem.title
      this.textMessage = elem.text
      if (elem.id) {
        this.id = elem.id
      }
    }
  },
  created() {
    if (this.comingFromSetup != null && this.comingFromSetup == true) {
      if (this.oldTemplate != null) {
        this.selectedTemplate = this.oldTemplate
      }
    }
  },
  mounted() {
    axios
      .get('/' + localStorage.getItem('clinic') + '/bills/footnotes')
      .then((res) => {
        this.listNote = res.data
        if (this.listNote && this.listNote.length > 0)
          this.selectedTemplate = this.listNote[0]
      })
  },
  methods: {
    showAggiungiTemplate: function () {
      this.showNewTemplate = !this.showNewTemplate
    },
    addFreeNote() {
      this.$emit('addNote', { text: this.new_message }, true)
    },
    addNote() {
      let newTemplate = {}
      if (this.selectedTemplate != '') {
        if (this.selectedTemplate.id) {
          newTemplate.id = this.selectedTemplate.id
          this.$emit('addNote', newTemplate, true)
          return
        }

        newTemplate.title = this.selectedTemplate.title
        newTemplate.text = this.selectedTemplate.text
        if (
          this.selectedTemplate.title == this.title &&
          this.selectedTemplate.text == this.textMessage
        ) {
          this.$emit('addNote', this.selectedTemplate, true)
        } else {
          if (this.selectedTemplate.text != this.textMessage)
            newTemplate.text = this.textMessage
          if (this.selectedTemplate.title != this.title)
            newTemplate.title = this.title
          this.$emit('addNote', newTemplate, true)
        }
      } else {
        newTemplate = {
          title: this.titolo,
          text: this.message
        }
        this.$emit('addNote', newTemplate, true)
      }
    },
    addNoteSetup() {
      let newTemplate = {
        title: this.titolo,
        text: this.message
      }
      if (newTemplate.title != '' && newTemplate.text != '') {
        this.isRequired = false
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/bills/footnotes',
            newTemplate
          )
          .then((res) => {
            this.listNote.push(res.data)
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Nuovo template aggiunto'
            })
            this.selectedTemplate = res.data

            // this.showNewTemplate = false
            this.showAggiungiTemplate()
          })
        this.$emit('addNoteSetup', newTemplate)
      } else {
        this.isRequired = true
      }
    },
    deleteNote() {
      let emptyNote = {
        id: '',
        title: '',
        text: ''
      }
      this.$emit('addNote', emptyNote, false)
    }
  }
}
</script>
