<template>
  <div
    v-if="
      permissions && permissions?.tariffario?.view_tariffario?.enabled === true
    "
    class="rounded-lg flex flex-col justify-between content-container bg-white"
  >
    <div class="h-12 mx-6 grid grid-cols-5">
      <div class="col-span-2 flex items-center justify-start gap-3">
        <input
          v-model="onlyAlias"
          type="checkbox"
          class="form-check-switch ml-1"
          required
        />
        <label class="form-label mt-3"
          >Mostra categoria / prestazioni valorizzate
        </label>
      </div>
      <div class="col-span-1"></div>
      <div
        v-if="!distinti && !pacchetti"
        class="col-span-2 flex items-center justify-end"
      >
        <div v-if="selectTariffarioClinic" class="relative w-96">
          <input
            v-model="searchText"
            type="text"
            class="custom-input"
            placeholder="Cerca tra tutte le prestazioni..."
            @change="(event) => startSearch(event.target.value)"
          />

          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 54.3 54.4"
            class="text-theme-44 fill-current w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            @click="toggleModal"
          >
            <path
              d="M16.4 4.7c9.2-7 22.3-6.1 30.8 2.1 8.4 8.1 9.4 21.1 2.4 31.1-5.5 7.9-19.5 14.2-33.5 4.9-3.2 3.2-6.5 6.5-9.7 9.8-2 2-3.8 2.3-5.4.8-1.6-1.5-1.4-3.4.7-5.6.3-.4.7-.7 1.1-1.1 2.9-2.9 5.9-5.8 8.7-8.6-8.7-12.9-4.1-26.5 4.9-33.4Zm31.2 19c0-9.2-7.8-17-17.2-17-9.5 0-17 7.7-17 17.3 0 9.4 7.6 16.9 17.2 16.9 9.5 0 17-7.6 17.1-17.3Z"
            />
          </svg>
        </div>
      </div>
    </div>
    <div class="flex gap-3 tariff_container px-3 pb-1">
      <!-- primer div -->

      <div
        class="rounded-md"
        style="position: relative"
        :class="{
          'flex-1 ': openBlock === 0,
          'w-10 bg-theme-44 hover:bg-theme-40 text-theme-40 hover:text-white':
            openBlock !== 0
        }"
      >
        <h2
          v-if="openBlock !== 0"
          class="nunito cursor-pointer tariff_title flex items-center gap-2"
          @click="handleBlock(0)"
        >
          <h2 class="text-xl">Tariffario</h2>
          <h4 class="uppercase text-sm">{{ tariffario_title }}</h4>
        </h2>

        <div v-if="openBlock === 0" class="flex items-center text-theme-48">
          <button
            class="w-32 h-10 text-center rounded-tr-xl shadow-md"
            :class="[
              tariffario
                ? 'z-50 bg-theme-40 text-white'
                : 'z-40 bg-theme-44 text-theme-42'
            ]"
            @click="
              ;(tariffario = true),
                (magazzino = false),
                (selectedType = TARIFFARIO),
                (selectedCategoria = null),
                (selectTariffarioClinic = null)
            "
          >
            Tariffario
          </button>
          <button
            class="w-32 h-10 text-center rounded-t-xl -ml-5 shadow-md"
            :class="[
              magazzino
                ? 'z-50 bg-theme-40 text-white'
                : 'z-40 bg-theme-44 text-theme-42'
            ]"
            @click="
              ;(magazzino = true),
                (tariffario = false),
                (selectedType = TARIFFARIO_MAG),
                (selectedCategoria = null),
                (selectTariffarioClinic = null),
                getData()
            "
          >
            Magazzino
          </button>
        </div>

        <div
          v-if="tariffario && openBlock === 0"
          class="tariff-box bg-white flex flex-col gap-1 rounded-b-md rounded-tr-md px-2 py-3 overflow-auto scrollbar_custom"
        >
          <button
            v-for="x in tariffari"
            :key="x"
            type="button"
            class="tarif_btn"
            :class="
              selectTariffarioClinic && selectTariffarioClinic.id === x.id
                ? 'bg-theme-44 border border-theme-41'
                : 'bg-theme-45'
            "
            @click="setSelectTariffarioClinic(x)"
          >
            <p class="break-words text-xs uppercase">{{ x.name }}</p>
          </button>
        </div>

        <div
          v-if="magazzino && openBlock === 0"
          class="tariff-box bg-white flex flex-col gap-1 rounded-b-md rounded-tr-md px-2 py-3 overflow-auto scrollbar_custom"
        >
          <button
            type="button"
            class="w-20 bg-theme-44 sm:w-auto mb-2 rounded-md border border-transparent shadow-sm px-3 py-2 text-xs md:text-sm xl:text-base font-medium text-theme-42 hover:bg-theme-44 focus:outline-none focus:ring-2 focus:ring-theme-41"
            @click="setSelectTariffarioClinic(x)"
          >
            <p class="break-words text-xs uppercase">Prezziario Magazzino</p>
          </button>
          <!-- <button
            v-for="x in tariffari_mag"
            :key="x"
            type="button"
            class="py-2 uppercase text-xs rounded-lg font-semibold border hover:border-theme-41 hover:bg-theme-44 active:bg-theme-44"
            :class="
              selectTariffarioClinic && selectTariffarioClinic.id === x.id
                ? 'bg-theme-44 border border-theme-41'
                : 'bg-theme-45'
            "
            @click="setSelectTariffarioClinic(x)"
          >
            {{ x.name }}
          </button> -->
        </div>
        <div
          v-if="
            permissions &&
            permissions?.tariffario?.store_tariffario_clinica?.enabled ===
              true &&
            openBlock === 0
          "
          class="col-span-1 flex items-center justify-end pr-7"
        >
          <button
            class="orange-btn w-32"
            type="file"
            @click="openNewTariffarioClinic"
          >
            <PlusIcon class="h-5 w-5 mr-2" /> Tariffario
          </button>
          <button
            class="orange-btn flex items-center gap-3 ml-3"
            @click="handleExportTariffarioModal"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
              class="w-5 fill-current mx-2"
            >
              <path
                d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V288H216c-13.3 0-24 10.7-24 24s10.7 24 24 24H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM384 336V288H494.1l-39-39c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39H384zm0-208H256V0L384 128z"
              />
            </svg>
            Esporta
          </button>
        </div>
      </div>
      <!-- segundo div -->

      <!-- <div
        v-if="selectTariffarioClinic && !create_distinta && !create_pacchetto"
        class="rounded-md"
        style="position: relative"
        :class="{
          'flex-1': openBlock === 1,
          '  w-10 bg-theme-44 hover:bg-theme-40 text-theme-40 hover:text-white':
            openBlock !== 1
        }"
      > -->

      <div
        v-if="selectTariffarioClinic"
        class="rounded-md"
        style="position: relative"
        :class="{
          'flex-1': openBlock === 1,
          '  w-10 bg-theme-44 hover:bg-theme-40 text-theme-40 hover:text-white':
            openBlock !== 1
        }"
      >
        <h2
          v-if="openBlock !== 1"
          class="nunito cursor-pointer tariff_title flex items-center gap-2"
          @click="handleBlock(1)"
        >
          <h2 class="text-xl capitalize">{{ selectedType2 }}</h2>
          <h4 class="uppercase text-sm">
            {{ categoria_selected }}
          </h4>
        </h2>

        <div v-if="openBlock === 1" class="flex items-center text-theme-48">
          <button
            class="w-32 h-10 text-center shadow-md"
            :class="[
              categoriee
                ? 'z-50 rounded-tr-xl bg-theme-40 text-white'
                : pacchetti
                ? 'z-20 bg-theme-44  text-theme-42 border border-theme-43 border-opacity-10'
                : distinti
                ? 'z-10 bg-theme-44  text-theme-42 border border-theme-43 border-opacity-10'
                : ''
            ]"
            @click="
              ;(categoriee = true),
                (pacchetti = false),
                (distinti = false),
                (selectedType2 = CATEGORIE)
            "
          >
            Categorie
          </button>
          <button
            v-if="!magazzino"
            class="w-32 h-10 text-center rounded-t-xl -ml-5 shadow-md"
            :class="[
              pacchetti
                ? 'z-50 rounded-tr-xl bg-theme-40 text-white'
                : 'z-40 bg-theme-44  text-theme-42 border border-theme-43 border-opacity-10'
            ]"
            @click="
              ;(categoriee = false),
                (pacchetti = true),
                (distinti = false),
                (selectedType2 = PACCHETTI)
            "
          >
            Pacchetti
          </button>
          <button
            v-if="!magazzino"
            class="w-32 h-10 text-center rounded-t-xl -ml-5 shadow-md"
            :class="[
              distinti
                ? 'z-50 rounded-tr-xl bg-theme-40 text-white'
                : pacchetti
                ? 'z-20 bg-theme-44  text-theme-42 border border-theme-43 border-opacity-10'
                : categoriee
                ? 'z-10 bg-theme-44  text-theme-42 border border-theme-43 border-opacity-10'
                : ''
            ]"
            @click="
              ;(categoriee = false),
                (pacchetti = false),
                (distinti = true),
                (selectedType2 = DISTINTE)
            "
          >
            Distinte
          </button>
        </div>

        <div>
          <div
            v-if="
              searchResult &&
              openBlock === 1 &&
              pacchetti === false &&
              distinti === false
            "
            class="tarif_box"
          >
            <button
              v-for="(x, y) in searchResult.data"
              :key="x"
              type="button"
              class="tarif_btn"
              :class="selectedCategoria == x ? 'bg-theme-44' : 'bg-theme-45'"
              @click="selectSearchCategory(x)"
            >
              {{ y }}
            </button>
          </div>
          <div v-else-if="categoriee && openBlock === 1">
            <div class="tariff-box">
              <button
                v-for="x in categorie"
                :key="x"
                type="button"
                class="tarif_btn"
                :class="selectedCategoria == x ? 'bg-theme-44' : 'bg-theme-45'"
                @click="selectCategoria(x)"
              >
                <p class="break-words text-xs uppercase">
                  {{ selectedType === TARIFFARIO ? x.category : x.name }}
                </p>
              </button>
            </div>
          </div>

          <!-- visualizzazione pacchetto -->
          <div
            v-if="pacchetti && openBlock === 1"
            class="tariff-box overflow-auto scrollbar_custom"
          >
            <div
              v-for="x in pacchetti_data.data"
              :key="x"
              class="w-full flex flex-col justify-center items-center content-center"
            >
              <div
                type="button"
                class="tarif_btn grid grid-cols-12"
                :class="selectedPackage == x ? 'bg-theme-44' : 'bg-theme-45'"
              >
                <button
                  class="col-span-11 uppercase font-semibold"
                  @click="selectPacchetto(x, PACCHETTI)"
                >
                  {{ x.alias }}
                </button>

                <a
                  class="cursor-pointer col-span-1 text-theme-41"
                  @click="removePacchetto(x)"
                >
                  <i class="fas fa-times" />
                </a>
              </div>
            </div>
          </div>

          <div
            v-if="distinti && openBlock === 1"
            class="tariff-box overflow-auto scrollbar_custom"
          >
            <button
              v-for="x in distinta_data.data"
              :key="x"
              type="button"
              class="tarif_btn"
              :class="selectedCategoria == x ? 'bg-theme-44' : 'bg-theme-45'"
              @click="selectPacchetto(x, DISTINTE)"
            >
              {{ x.alias }}
            </button>
          </div>
        </div>
        <div
          v-if="
            selectTariffarioClinic &&
            !create_distinta &&
            !create_pacchetto &&
            permissions &&
            permissions?.tariffario?.store_tariffario_packages?.enabled === true
          "
          class="flex items-center justify-end pr-6 gap-1 mt-2"
        >
          <button
            v-if="selectedType2 === 'pacchetti' && openBlock === 1"
            class="orange-btn w-32"
            type="file"
            @click="createNewPachetto(true)"
          >
            <PlusIcon class="h-5 w-5 mr-2" /> Pacchetto
          </button>
          <button
            v-if="selectedType2 === 'distinte' && openBlock === 1"
            class="orange-btn w-32"
            type="file"
            @click="createNewPachetto(false)"
          >
            <PlusIcon class="h-5 w-5 mr-2" /> Distinta
          </button>
        </div>
      </div>

      <!-- tercer div -->

      <div
        v-if="
          searchNomenclatori &&
          openBlock === 2 &&
          pacchetti === false &&
          distinti === false
        "
        class=""
        style="position: relative"
        :class="{ 'flex-1': openBlock === 2, 'w-10': openBlock !== 2 }"
      >
        <h2
          v-if="openBlock !== 2 && searchNomenclatori"
          class="nunito text-theme-40 hover:text-theme-41 cursor-pointer transform rotate-90 mt-12"
          @click="handleBlock(2)"
        >
          Prestazione
        </h2>
        <div v-if="openBlock === 2" class="flex items-center text-theme-48">
          <button
            class="w-32 h-10 text-center rounded-tr-xl shadow-md"
            :class="[
              prestazione
                ? 'z-50 bg-theme-40 text-white'
                : 'z-40 bg-theme-44 text-theme-42'
            ]"
          >
            Prestazione
          </button>
        </div>

        <div
          :class="{ 'flex-1': openBlock === 2, 'w-10': openBlock !== 2 }"
          class="flex flex-col content-start mb-1 post bg-white rounded-b-md rounded-tr-md shadow-md overflow-scroll tariff-box"
        >
          <NomenclatoreTable
            v-if="nomenclatore"
            :documents="searchNomenclatori"
            :alias-to-save="aliasToSave"
            :total-records="searchNomenclatori.length"
            :pagination="{
              page: 1,
              forPage: searchNomenclatori.length
            }"
            :for-page-option="nomenclatore.length"
            :selected-categoria="selectedCategoria"
            @openPriceModal="handlePriceModal"
            @addAlias="addAlias"
            @changePagination="changePagination"
            @changeNumPages="changeNumPages"
            @getAliasData="getAliasData"
          />
        </div>
        <div
          id="salva-btn"
          class="col-span-1 flex items-center justify-end mt-2"
        >
          <button
            type="button"
            class="orange-btn"
            @click="
              create_pacchetto === true || create_distinta === true
                ? openConfirmPrestazione()
                : confirmNewPrices()
            "
          >
            <i class="fas fa-save mr-1"></i> Salva
          </button>
          <button
            v-if="create_pacchetto || create_distinta"
            type="button"
            class="orange-btn"
            @click="interruptNewPacchetto"
          >
            Annulla
          </button>
        </div>
      </div>
      <div
        v-if="
          selectedCategoria &&
          selectTariffarioClinic &&
          selectedType2 === CATEGORIE &&
          openBlock === 2 &&
          !searchNomenclatori
        "
        :class="{ 'flex-1': openBlock === 2, 'w-10': openBlock !== 2 }"
        class="w-full"
      >
        <div class="flex items-center text-theme-48">
          <button
            class="w-32 h-10 text-center rounded-tr-xl shadow-md"
            :class="[
              prestazione
                ? 'z-50 bg-theme-40 text-white'
                : 'z-40 bg-theme-44 text-theme-42'
            ]"
            @click="
              ;(prestazione = true),
                (pacchetti = false),
                (distinti = false),
                (openBlock = 2)
            "
          >
            Prestazione
          </button>
        </div>

        <div
          class="flex flex-col content-start bg-white rounded-b-md rounded-tr-md shadow-md px-2 pt-3 tariff-box overflow-auto scrollbar_custom"
        >
          <div
            v-if="prestazione && nomenclatore && openBlock === 2"
            class="px-2"
          >
            <NomenclatoreTable
              v-if="selectedType === TARIFFARIO"
              :documents="nomenclatore.data"
              :alias-to-save="aliasToSave"
              :total-records="nomenclatore.total"
              :pagination="pagination"
              :for-page-option="forPageOption"
              :selected-categoria="selectedCategoria"
              @openPriceModal="handlePriceModal"
              @addAlias="addAlias"
              @changePagination="changePagination"
              @changeNumPages="changeNumPages"
              @getAliasData="getAliasData"
            />

            <FarmaciPriceTable
              v-else
              :documents="nomenclatore"
              :alias-to-save="aliasToSave"
              :total-records="nomenclatore.total"
              :pagination="pagination"
              :for-page-option="forPageOption"
              :selected-categoria="selectedCategoria"
              @openPriceModal="handlePriceModal"
              @addAlias="updateAlias"
              @changePagination="changePagination"
              @changeNumPages="changeNumPages"
              @getAliasData="getAliasData"
            />
            <!-- <ArticoliTable
              v-else
              :documents="nomenclatore.data"
              :alias-to-save="aliasToSave"
              :total-records="nomenclatore.total"
              :pagination="pagination"
              :for-page-option="forPageOption"
              :selected-categoria="selectedCategoria"
              @openPriceModal="handlePriceModal"
              @addAlias="updateAlias"
              @changePagination="changePagination"
              @changeNumPages="changeNumPages"
              @getAliasData="getAliasData"
            /> -->
          </div>
        </div>
        <div
          v-if="
            (selectedCategoria && selectTariffarioClinic) ||
            (searchResult && searchNomenclatori) ||
            create_pacchetto === true ||
            create_distinta === true
          "
          id="salva-btn"
          class="col-span-1 flex items-center justify-end mt-2"
        >
          <button
            type="button"
            class="orange-btn"
            @click="
              create_pacchetto === true || create_distinta === true
                ? openConfirmPrestazione()
                : confirmNewPrices()
            "
          >
            <i class="fas fa-save mr-1"></i> Salva
          </button>
          <button
            v-if="create_pacchetto || create_distinta"
            type="button"
            class="orange-btn"
            @click="interruptNewPacchetto"
          >
            Annulla
          </button>
        </div>
      </div>
      <div
        v-if="
          (selectedType2 === PACCHETTI || selectedType2 === DISTINTE) &&
          openBlock === 2 &&
          !create_pacchetto &&
          !create_distinta
        "
        :class="{ 'flex-1': openBlock === 2, 'w-10': openBlock !== 2 }"
        class="w-full"
      >
        <div class="flex items-center text-theme-48">
          <button
            class="w-32 h-10 text-center rounded-tr-xl shadow-md"
            :class="[
              new_prestazione
                ? 'z-50 bg-theme-40 text-white'
                : 'z-40 bg-theme-44 text-theme-42'
            ]"
            @click="
              ;(new_farmaco = false), (new_prestazione = true), (openBlock = 2)
            "
          >
            Prestazione
          </button>
          <!-- <button
            class="w-32 h-10 text-center rounded-tr-xl shadow-md -ml-5"
            :class="[
              new_farmaco
                ? 'z-50 bg-theme-40 text-white'
                : 'z-40 bg-theme-44 text-theme-42'
            ]"
            @click="
              ;(new_farmaco = true), (new_prestazione = false), (openBlock = 2)
            "
          >
            Farmaco
          </button> -->
        </div>
        <div class="flex flex-col justify-between">
          <div class="overflow-auto scrollbar_custom modif_pachetto_cont">
            <div class="flex items-center gap-3 justify-between">
              <div class="flex flex-col 0">
                <div
                  v-if="editPackage && editPackageTitle !== null"
                  class="flex items-center gap-2"
                >
                  <label class="form-label mb-0">Pacchetto:</label>
                  <input
                    v-model="editPackageTitle"
                    type="text"
                    placeholder="Modifica nome pacchetto"
                    class="custom-input"
                  />
                </div>
                <div v-else class="flex items-center gap-2">
                  <label class="form-label mb-0">Pacchetto:</label>
                  <p class="font-bold uppercase nunito text-sm">
                    {{ selectedPackage.alias }}
                  </p>
                </div>
              </div>

              <div v-if="selectedPackage" class="w-auto max-w-1/2">
                <div class="flex justify-end">
                  <div
                    class="parent2 text-center whitespace-nowrap border-b border-theme-44 text-xs"
                  >
                    <div
                      class="div1_2 bg-theme-44 font-black flex items-center justify-center px-2 rounded-tl-md"
                    >
                      Taglia selezionata
                    </div>
                    <div
                      class="div2_2 bg-theme-44 font-black flex items-center justify-center px-2"
                    >
                      Diurno
                    </div>
                    <div
                      class="div3_2 bg-theme-44 font-black flex items-center justify-center px-2"
                    >
                      Notturno
                    </div>
                    <div
                      class="div4_2 bg-theme-44 font-black flex items-center justify-center px-2"
                    >
                      Festivo
                    </div>
                    <div
                      class="div5_2 bg-theme-44 font-black flex items-center justify-center px-2 rounded-tr-md"
                    >
                      Assicurazione
                    </div>

                    <div class="div6_2 border-l border-r border-theme-44 px-2">
                      {{ selectedPackage.taglia }}
                    </div>
                    <div
                      v-if="!editPackage"
                      class="div7_2 border-r border-theme-44"
                    >
                      {{
                        distinti
                          ? Math.round(
                              (pricesSumCompiled.t1 * (showIva ? ivaPerc : 1) +
                                Number.EPSILON) *
                                100
                            ) / 100
                          : selectedPackage.prices
                          ? Math.round(
                              ((selectedPackage.prices['t1'] ?? 0) *
                                (showIva ? ivaPerc : 1) +
                                Number.EPSILON) *
                                100
                            ) / 100
                          : 0
                      }}
                    </div>
                    <div v-else class="div7_2 border-r border-theme-44 p-1">
                      <input
                        v-model="editPackagePrices['t1']"
                        type="number"
                        class="custom-input h-5 text-center"
                      />
                    </div>
                    <div
                      v-if="!editPackage"
                      class="div8_2 border-r border-theme-44 p-1"
                    >
                      {{
                        distinti
                          ? Math.round(
                              (pricesSumCompiled.t2 * (showIva ? ivaPerc : 1) +
                                Number.EPSILON) *
                                100
                            ) / 100
                          : selectedPackage.prices
                          ? Math.round(
                              ((selectedPackage.prices['t2'] ?? 0) *
                                (showIva ? ivaPerc : 1) +
                                Number.EPSILON) *
                                100
                            ) / 100
                          : 0
                      }}
                    </div>
                    <div v-else class="div8_2 border-r border-theme-44 p-1">
                      <input
                        v-model="editPackagePrices['t2']"
                        type="number"
                        class="custom-input h-5 text-center"
                      />
                    </div>
                    <div
                      v-if="!editPackage"
                      class="div9_2 border-r border-theme-44 p-1"
                    >
                      {{
                        distinti
                          ? Math.round(
                              (pricesSumCompiled.t3 * (showIva ? ivaPerc : 1) +
                                Number.EPSILON) *
                                100
                            ) / 100
                          : selectedPackage.prices
                          ? Math.round(
                              ((selectedPackage.prices['t3'] ?? 0) *
                                (showIva ? ivaPerc : 1) +
                                Number.EPSILON) *
                                100
                            ) / 100
                          : 0
                      }}
                    </div>
                    <div v-else class="div9_2 border-r border-theme-44 p-1">
                      <input
                        v-model="editPackagePrices['t3']"
                        type="number"
                        class="custom-input h-5 text-center"
                      />
                    </div>
                    <div
                      v-if="!editPackage"
                      class="div10_2 border-r border-theme-44 p-1"
                    >
                      {{
                        distinti
                          ? Math.round(
                              (pricesSumCompiled.t4 * (showIva ? ivaPerc : 1) +
                                Number.EPSILON) *
                                100
                            ) / 100
                          : selectedPackage.prices
                          ? Math.round(
                              ((selectedPackage.prices['t4'] ?? 0) *
                                (showIva ? ivaPerc : 1) +
                                Number.EPSILON) *
                                100
                            ) / 100
                          : 0
                      }}
                    </div>
                    <div v-else class="div10_2 border-r border-theme-44 p-1">
                      <input
                        v-model="editPackagePrices['t4']"
                        type="number"
                        class="custom-input h-5 text-center"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div
                v-if="
                  selectedPackage &&
                  selectedPackage.discount &&
                  selectedPackage.discount !== 0 &&
                  selectedPackage.discount !== '0' &&
                  openBlock === 2
                "
                class="flex justify-end relative w-full p-2"
              >
                <span
                  >( Sconto applicato: {{ selectedPackage.discount }} % )</span
                >
              </div>
              <div
                v-if="
                  selectedPackage &&
                  selectedPackage.greatest &&
                  selectedPackage.greatest !== 0 &&
                  selectedPackage.greatest !== '0'
                "
                class="col-span-12 flex justify-end relative w-full p-2"
              >
                <span
                  >( Maggiorazione applicata: {{ selectedPackage.greatest }} %
                  )</span
                >
              </div>
            </div>
            <div
              v-if="new_prestazione && !editPackage"
              class="grid grid-cols-4 px-2"
            >
              <NomenclatoreViewTable
                v-if="
                  selectedPackage && selectedPackage.sub_packages.tariffario_ids
                "
                :documents="
                  selectedPackage.sub_packages.tariffario_ids.tariffario_price
                "
                :total-records="10"
                :taglia-selected="
                  selected_taglia ??
                  selectedPackage?.id_tariffario_taglia ??
                  null
                "
                :taglia-selected-label="
                  selected_taglia ?? selectedPackage?.taglia ?? null
                "
                :discount="
                  selectedPackage.discount &&
                  selectedPackage.discount !== 0 &&
                  selectedPackage.discount !== '0'
                    ? selectedPackage.discount
                    : null
                "
                :greatest="
                  selectedPackage.greatest &&
                  selectedPackage.greatest !== 0 &&
                  selectedPackage.greatest !== '0'
                    ? selectedPackage.greatest
                    : null
                "
                :no-price-prop="!distinti"
                @changePagination="changePagination"
                @changeNumPages="changeNumPages"
                @openPriceModal="handlePriceModal"
              />
              <span v-else class="col-span-4">
                Non sono presenti prestazioni in questo pacchetto
              </span>
            </div>

            <div v-if="new_farmaco && !editPackage" class="grid grid-cols-4">
              <ArticoliViewTable
                v-if="
                  selectedPackage && selectedPackage.sub_packages.warehouse_ids
                "
                :documents="
                  selectedPackage.sub_packages.warehouse_ids.warehouse_price
                "
                :total-records="10"
                @changePagination="changePagination"
                @changeNumPages="changeNumPages"
              />
              <span v-else class="col-span-4">
                Non sono presenti farmaci in questo pacchetto
              </span>
            </div>
            <div
              v-if="editPackage"
              class="modif_pachetto_cont overflow-hidden flex gap-1 mt-2"
            >
              <div class="flex-1 flex gap-2">
                <div
                  class="flex-1 flex flex-col pacchetti_cont overflow-auto scrollbar_custom gap-1 pb-12 px-1 rounded-md shadow-md border border-theme-44 border-opacity-30"
                >
                  <h2
                    class="nunito bg-theme-40 text-white text-center text-lg font-bold rounded-md mt-2 py-0.5"
                  >
                    Prestazioni inserite
                  </h2>
                  <div
                    class="newmodif_pacchetti_cont overflow-auto scrollbar_custom px-2"
                  >
                    <button
                      v-for="x in editPackagePrestazioni"
                      :key="x"
                      type="button"
                      class="tarif_btn_modif bg-theme-45 mt-1"
                    >
                      <div class="flex gap-2 justify-between px-2 items-start">
                        <p
                          class="flex-1 break-words text-xs uppercase text-center"
                        >
                          {{
                            x
                              ? x.alias && x.alias !== ''
                                ? x.alias
                                : x.description
                              : ''
                          }}
                        </p>
                        <a
                          class="flex-0 cursor-pointer text-theme-41"
                          @click="removeSingleEditPrestazione(x)"
                        >
                          <i class="fas fa-times" />
                        </a>
                      </div>
                    </button>
                  </div>
                </div>
                <div
                  v-if="edit_prestazione_categorie"
                  class="flex-1 flex flex-col gap-1 px-1 rounded-md shadow-md border border-theme-44 border-opacity-30 pacchetti_cont"
                >
                  <h2
                    class="nunito bg-theme-40 text-white text-center text-lg font-bold rounded-md mt-2 py-0.5"
                  >
                    Categorie
                  </h2>
                  <div
                    class="newmodif_pacchetti_cont overflow-auto scrollbar_custom px-2"
                  >
                    <button
                      v-for="x in edit_prestazione_categorie"
                      :key="x"
                      type="button"
                      class="tarif_btn_modif bg-theme-45 mt-1"
                      @click="selectCategoriaEditPrest(x)"
                    >
                      {{ x.category }}
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="edit_prestazione_nomenclatore"
                class="px-2 pacchetti_cont overflow-auto scrollbar_custom"
              >
                <h2
                  class="nunito bg-theme-40 text-white text-center text-lg font-bold rounded-md my-1 py-0.5"
                >
                  Prestazione
                </h2>

                <NomenclatoreSelectTable
                  :key="editPackagePrestazioni.length + editPrestKey"
                  :documents="edit_prestazione_nomenclatore.data"
                  :total-records="edit_prestazione_nomenclatore.total"
                  :pagination="pagination"
                  :for-page-option="forPageOption"
                  :selected-categoria="edit_prestazione_categoria_selected"
                  :taglia-selected="selectedPackage.id_tariffario_taglia"
                  :no-price-prop="true"
                  :edit-prestazioni="editPackagePrestazioni"
                  @changePagination="changePaginationEditPrest"
                  @changeNumPages="changeNumPagesEditPrest"
                  @addElement="addEditPrestazione"
                  @removeElement="removeEditPrestazione"
                  @openPriceModal="handlePriceModal"
                  @addAlias="addAlias"
                  @getAliasData="getAliasData"
                />
              </div>
            </div>
          </div>
          <div class="mt-2">
            <div
              v-if="
                !editPackage &&
                !distinti &&
                permissions &&
                permissions.tariffario &&
                permissions.tariffario?.edit_pkg_dist_tariffario?.enabled ===
                  true
              "
              class="col-span-1 flex items-center justify-end"
            >
              <button type="button" class="orange-btn" @click="modifyPackage">
                <i class="fas fa-edit mr-1"></i>Modifica Pacchetto
              </button>
            </div>
            <div
              v-if="editPackage && !distinti"
              class="col-span-1 flex items-center justify-end"
            >
              <button
                type="button"
                class="orange-btn"
                @click="saveModifyPackage"
              >
                <i class="fas fa-save mr-1"></i> Salva modifiche
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        v-if="(create_pacchetto || create_distinta) && openBlock === 2"
        class="w-full"
      >
        <div class="flex items-center text-theme-48">
          <button
            class="w-32 h-10 text-center rounded-tr-xl shadow-md"
            :class="[
              new_prestazione
                ? 'z-50 bg-theme-40 text-white'
                : 'z-40 bg-theme-44 text-theme-42'
            ]"
            @click=";(new_farmaco = false), (new_prestazione = true)"
          >
            Prestazione
          </button>
          <!-- <button
            class="w-32 h-10 text-center rounded-tr-xl shadow-md -ml-5"
            :class="[
              new_farmaco
                ? 'z-50 bg-theme-40 text-white'
                : 'z-40 bg-theme-44 text-theme-42'
            ]"
            @click=";(new_farmaco = true), (new_prestazione = false)"
          >
            Farmaco
          </button> -->
        </div>

        <div class="tariff-box-pachetto pt-1">
          <div v-if="new_prestazione" class="grid grid-cols-4 gap-2">
            <div class="col-span-4 px-2">
              <div class="grid grid-cols-5 gap-2">
                <div class="col-span-2 flex flex-col">
                  <label v-if="pacchetti" for="" class="form-label mb-0"
                    >Nome pachetto</label
                  >
                  <label v-else for="" class="form-label mb-0"
                    >Nome distinta</label
                  >
                  <input
                    v-model="newPackageName"
                    type="text"
                    placeholder="Inserisci nome"
                    class="custom-input"
                  />
                </div>
                <div class="col-span-3 flex flex-col">
                  <div v-if="pacchetti" class="grid grid-cols-4 gap-1">
                    <div class="col-span-1">
                      <span class="form-label mb-0">Prezzo diurno</span>
                      <input
                        v-model="newPackagePrices['t1']"
                        type="number"
                        placeholder="Prezzo Diurno"
                        class="custom-input"
                      />
                    </div>
                    <div class="col-span-1">
                      <span class="form-label mb-0">Prezzo notturno</span>

                      <input
                        v-model="newPackagePrices['t2']"
                        type="number"
                        placeholder="Prezzo Notturno"
                        class="custom-input"
                      />
                    </div>
                    <div class="col-span-1">
                      <span class="form-label mb-0">Prezzo festivo</span>

                      <input
                        v-model="newPackagePrices['t3']"
                        type="number"
                        placeholder="Prezzo Festivo"
                        class="custom-input"
                      />
                    </div>
                    <div class="col-span-1">
                      <span class="form-label mb-0">Prezzo Assicurazione</span>
                      <input
                        v-model="newPackagePrices['t4']"
                        type="number"
                        placeholder="Prezzo Assicurazione"
                        class="custom-input"
                      />
                    </div>
                  </div>
                  <div v-else class="grid grid-cols-4 gap-1">
                    <div class="col-span-2">
                      <span class="form-label mb-0">Sconto</span>
                      <input
                        v-model="sconto"
                        type="number"
                        placeholder="Inserisci sconto"
                        class="custom-input col-span-1"
                      />
                    </div>

                    <div class="col-span-2">
                      <span class="form-label mb-0">Maggiorazione</span>
                      <input
                        v-model="maggiorazione"
                        type="number"
                        placeholder="Inserisci maggiorazione"
                        class="custom-input col-span-1"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-span-4 flex gap-5 items-center px-2">
              <v-select
                v-if="elenco_taglie"
                v-model="selected_taglia"
                label="description"
                class="w-full"
                :reduce="(value) => value.id"
                placeholder="selezionare la taglia"
                :options="elenco_taglie"
              />
              <div v-if="create_distinta" class="flex justify-end">
                <div
                  class="parent text-center whitespace-nowrap border-b border-theme-44 text-xs"
                >
                  <div class="div1 bg-theme-44 font-black px-2 rounded-tl-md">
                    Somma prestazioni
                  </div>
                  <div class="div2 bg-theme-44 font-black py-0.5 px-2">
                    Diurno
                  </div>
                  <div class="div3 bg-theme-44 font-black py-0.5 px-2">
                    Notturno
                  </div>
                  <div class="div4 bg-theme-44 font-black py-0.5 px-2">
                    Festivo
                  </div>
                  <div
                    class="div5 bg-theme-44 font-black py-0.5 px-2 rounded-tr-md"
                  >
                    Assicurazione
                  </div>

                  <div class="div6 border-r border-theme-44">
                    {{
                      Math.round(
                        (newPricesSum.t1 * (showIva ? ivaPerc : 1) +
                          Number.EPSILON) *
                          100
                      ) / 100
                    }}
                  </div>
                  <div class="div7 border-r border-theme-44">
                    {{
                      Math.round(
                        (newPricesSum.t2 * (showIva ? ivaPerc : 1) +
                          Number.EPSILON) *
                          100
                      ) / 100
                    }}
                  </div>
                  <div class="div8 border-r border-theme-44">
                    {{
                      Math.round(
                        (newPricesSum.t3 * (showIva ? ivaPerc : 1) +
                          Number.EPSILON) *
                          100
                      ) / 100
                    }}
                  </div>
                  <div class="div9 border-r border-theme-44">
                    {{
                      Math.round(
                        (newPricesSum.t4 * (showIva ? ivaPerc : 1) +
                          Number.EPSILON) *
                          100
                      ) / 100
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="new_prestazione_categorie"
              class="modif_pachetto_cont overflow-hidden flex gap-1 mt-1 col-span-4"
            >
              <div class="flex-1 flex gap-1">
                <div
                  class="flex-1 flex flex-col new_pacchetti_container overflow-auto scrollbar_custom gap-1 px-1 rounded-md shadow-md border border-theme-44 border-opacity-30"
                >
                  <h2
                    class="nunito bg-theme-40 text-white text-center text-lg font-bold rounded-md mt-2 py-0.5"
                  >
                    Categorie
                  </h2>
                  <div
                    class="px-2 new_pacchetti_cont overflow-auto scrollbar_custom"
                  >
                    <button
                      v-for="x in new_prestazione_categorie"
                      :key="x"
                      type="button"
                      class="tarif_btn_modif mt-1 px-2"
                      :class="
                        new_prestazione_selected_categoria == x
                          ? 'bg-theme-44'
                          : 'bg-theme-45'
                      "
                      @click="selectCategoriaNewPrest(x)"
                    >
                      {{ x.category }}
                    </button>
                  </div>
                </div>
                <div
                  class="flex-1 flex flex-col new_pacchetti_container overflow-auto scrollbar_custom gap-1 px-1 rounded-md shadow-md border border-theme-44 border-opacity-30"
                >
                  <h2
                    class="nunito bg-theme-40 text-white text-center text-lg font-bold rounded-md mt-2 py-0.5"
                  >
                    Prestazioni inserite
                  </h2>
                  <div
                    class="newmodif_pacchetti_cont overflow-auto scrollbar_custom px-2"
                  >
                    <button
                      v-for="x in new_prestazione_newData"
                      :key="x"
                      type="button"
                      class="tarif_btn_modif bg-theme-45 mt-1"
                    >
                      <div class="flex gap-2 justify-between px-2 items-start">
                        <p
                          class="flex-1 break-words text-xs uppercase text-center"
                        >
                          {{
                            x
                              ? x.alias && x.alias !== ''
                                ? x.alias
                                : x.description
                              : ''
                          }}
                        </p>
                        <a
                          class="flex-0 cursor-pointer text-theme-41"
                          @click="removeSingleNewPrestazione(x)"
                        >
                          <i class="fas fa-times" />
                        </a>
                      </div>
                    </button>
                  </div>
                </div>
              </div>

              <div
                v-if="new_prestazione_nomenclatore"
                class="px-2 mt-1 new_pacchetti_container"
              >
                <h2
                  class="nunito bg-theme-40 text-white text-center text-lg font-bold rounded-md my-1 py-0.5"
                >
                  Prestazione
                </h2>
                <div
                  class="new_pacchetti_cont overflow-auto scrollbar_custom px-2"
                >
                  <NomenclatoreSelectTable
                    :key="new_prestazione_newids.length + newPrestKey"
                    :documents="new_prestazione_nomenclatore.data"
                    :total-records="new_prestazione_nomenclatore.total"
                    :pagination="pagination"
                    :for-page-option="forPageOption"
                    :selected-categoria="new_prestazione_selected_categoria"
                    :selected-new="new_prestazione_newids"
                    :taglia-selected="selected_taglia"
                    :no-price-prop="create_pacchetto"
                    @changePagination="changePaginationNewPrest"
                    @changeNumPages="changeNumPagesNewPrest"
                    @addElement="addNewPrestazione"
                    @removeElement="removeNewPrestazione"
                    @openPriceModal="handlePriceModal"
                    @addAlias="addAlias"
                    @getAliasData="getAliasData"
                  />
                </div>
              </div>
            </div>

            <ConfirmNewPacchetto
              v-if="confirm_package"
              :title="
                create_pacchetto
                  ? 'Completa nuovo pacchetto'
                  : 'Completa nuova distinta'
              "
              :pack="create_pacchetto"
              @close="
                () => {
                  confirm_package = false
                }
              "
              @confirm="confirmNewPrestazione"
            />
          </div>

          <div v-if="new_farmaco" class="grid grid-cols-4">
            <div v-if="new_farmaco_categorie" class="col-span-1 flex flex-col">
              new farmaco categorie
              <button
                v-for="x in new_farmaco_categorie"
                :key="x"
                type="button"
                class="w-10 sm:w-auto mb-2 inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 text-base font-medium text-theme-48 hover:bg-theme-44 focus:outline-none focus:ring-2 focus:ring-theme-41 sm:text-sm"
                :class="
                  new_farmaco_selected_categoria == x
                    ? 'bg-theme-44'
                    : 'bg-theme-45'
                "
                @click="selectCategoriaNewPharma(x)"
              >
                {{ x.name }}
              </button>
            </div>
            <div v-if="new_farmaco_selected_categoria" class="col-span-3">
              new farmaco selected categoria
              <ArticoliSelectTable
                v-if="new_farmaco_nomenclatore"
                :documents="new_farmaco_nomenclatore.data"
                :total-records="new_farmaco_nomenclatore.total"
                :pagination="pagination"
                :for-page-option="forPageOption"
                :selected-categoria="new_farmaco_selected_categoria"
                :selected-new="new_farmaco_newids"
                @openPriceModal="handlePriceModal"
                @addAlias="updateAlias"
                @changePagination="changePagination"
                @changeNumPages="changeNumPages"
                @getAliasData="getAliasData"
                @addElement="addNewPharma"
                @removeElement="removeNewPharma"
              />
            </div>
          </div>
        </div>
        <div
          v-if="
            (selectedCategoria && selectTariffarioClinic) ||
            (searchResult && searchNomenclatori) ||
            create_pacchetto === true ||
            create_distinta === true
          "
          id="salva-btn"
          class="col-span-1 flex items-center justify-end gap-3 mt-1"
        >
          <button
            v-if="create_pacchetto || create_distinta"
            type="button"
            class="orange-btn"
            @click="interruptNewPacchetto"
          >
            Annulla
          </button>
          <button
            type="button"
            class="orange-btn"
            @click="
              create_pacchetto === true || create_distinta === true
                ? confirmNewPrestazione()
                : confirmNewPrices()
            "
          >
            <i class="fas fa-save mr-1"></i> Salva
          </button>
        </div>
      </div>
      <PrestazionePriceModalVue
        v-if="priceModal && selectedNomenclatore"
        :title="
          selectedNomenclatore.alias
            ? selectedNomenclatore.alias
            : selectedNomenclatore.description
        "
        :data="
          selectedNomenclatore.code
            ? selectedNomenclatore.code
            : selectedNomenclatore.price
        "
        :full-data="selectedNomenclatore"
        :tariffario-id="selectTariffarioClinic.id"
        :direct-save="create_distinta === true || create_pacchetto === true"
        :taglia-show="
          create_distinta === true || create_pacchetto === true
            ? selected_taglia
            : selectedPackage &&
              (selectedType2 === PACCHETTI || selectedType2 === DISTINTE)
            ? selectedPackage.id_tariffario_taglia
            : null
        "
        :taglia-label="priceModalTagliaLabel"
        :read-only="priceReadOnly"
        :netto="!showIva"
        :cassa="cassaPrevidenziale"
        :discount="priceModalDiscount"
        :greatest="priceModalGreatest"
        @decline="closePriceModal"
        @confirm="addPrice"
      />
    </div>

    <AddNewTariffarioClinic
      v-if="newTariffarioClinic"
      title="Aggiungi tariffario"
      @close="closeNewTariffarioClinic"
      @confirm="addTariffario"
    />

    <ExportTariffarioModal
      v-if="showExportInvoiceModal"
      :tariffari="tariffari"
      @close="handleExportTariffarioModal"
    />
  </div>
</template>

<script>
import { defineComponent, ref, watch, computed } from 'vue'
import { mapGetters, useStore } from 'vuex'
import axios from '../../gateway/backend-api'
import NomenclatoreTable from '../../components/tables/NomenclatoreTable.vue'
import NomenclatoreSelectTable from '../../components/tables/NomenclatoreSelectTable.vue'
import NomenclatoreViewTable from '../../components/tables/NomenclatoreViewTable.vue'
//import ArticoliTable from '../../components/tables/ArticoliTable.vue'
import ArticoliViewTable from '../../components/tables/ArticoliViewTable.vue'
import ArticoliSelectTable from '../../components/tables/ArticoliSelectTable.vue'
import PrestazionePriceModalVue from '../../components/modals/PrestazionePriceModal.vue'
import AddNewTariffarioClinic from '../../components/modals/AddNewTariffarioClinic.vue'
import ConfirmNewPacchetto from '../../components/modals/ConfirmNewPacchetto.vue'
import FarmaciPriceTable from '../../components/tables/FarmaciPriceTable.vue'
import ExportTariffarioModal from '../../components/modals/ExportTariffarioModal.vue'

export default defineComponent({
  components: {
    NomenclatoreTable,
    //ArticoliTable,
    PrestazionePriceModalVue,
    AddNewTariffarioClinic,
    NomenclatoreSelectTable,
    ArticoliSelectTable,
    ConfirmNewPacchetto,
    NomenclatoreViewTable,
    ArticoliViewTable,
    FarmaciPriceTable,
    ExportTariffarioModal
  },

  setup() {
    const store = useStore()
    const showExportInvoiceModal = ref(false)
    const openBlock = ref(0)
    const TARIFFARIO = 'Tariffario'
    const TARIFFARIO_MAG = 'Tariffario Magazzino'

    const CATEGORIE = 'categorie'
    const PACCHETTI = 'pacchetti'
    const DISTINTE = 'distinte'

    const selectedType = ref(TARIFFARIO)

    const selectedType2 = ref(CATEGORIE)
    // ----- TARIFFARIO -----
    const tariffari = ref([])
    const tariffari_mag = ref([])
    const aliasToSave = ref({})
    const selectTariffarioClinic = ref(null)
    const selectedPackage = ref(null)

    const forPageOption = ref([10, 20, 50])
    const pagination = ref({
      page: 1,
      forPage: forPageOption.value[0]
    })

    const searchText = ref('')

    const onlyAlias = ref(false)

    watch(onlyAlias, () => {
      selectedCategoria.value = null
      nomenclatore.value = null
      openBlock.value = 1
      getData()
    })

    // ----- CATEGORIA -----
    const categorie = ref([])
    const categorie_mag = ref([])
    const selectedCategoria = ref(null)

    // ----- CATEGORIA -----
    const nomenclatore = ref([])
    const selectedNomenclatore = ref(null)

    const new_prestazione_data = ref([])
    const new_farmaco_data = ref([])
    const new_prestazione_categorie = ref([])
    const new_farmaco_categorie = ref([])
    const pacchetti_data = ref([])
    const distinta_data = ref([])
    const new_prestazione_selected_categoria = ref(null)
    const new_farmaco_selected_categoria = ref(null)
    const new_prestazione_nomenclatore = ref(null)
    const new_farmaco_nomenclatore = ref(null)
    const new_prestazione_newids = ref([])
    const new_prestazione_newData = ref([])
    const new_farmaco_newids = ref([])
    const confirm_package = ref(false)
    const elenco_taglie = ref(null)
    const selected_taglia = ref(null)
    const priceModalTagliaLabel = ref(null)

    const searchResult = ref(null)
    const searchNomenclatori = ref(null)

    const editPackage = ref(false)
    const editPackagePrices = ref({ t1: 0, t2: 0, t3: 0, t4: 0 })
    const editPackagePrestazioni = ref([])
    const editPackageTitle = ref(null)
    const edit_prestazione_categorie = ref([])
    const edit_prestazione_categoria_selected = ref(null)
    const edit_prestazione_nomenclatore = ref(null)
    const newPackageName = ref('')
    const newPackagePrices = ref({ t1: 0, t2: 0, t3: 0, t4: 0 })
    const editPrestKey = ref(0)
    const newPrestKey = ref(0)

    const sconto = ref(null)
    const maggiorazione = ref(null)

    const permissions = computed(() => {
      return store.state.reusableData.permissions
        ? store.state.reusableData.permissions
        : null
    })

    const ivaPerc = computed(
      () => store.getters['reusableData/getRegimeFiscaleIvaPerc']
    )

    const showIva = computed(() => store.getters['reusableData/getShowIva'])

    const getData = () => {
      // tariffari

      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/elenco-tariffari-clinica'
        )
        .then((res) => {
          tariffari.value = res.data
        })

      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/elenco-pacchetti-distinte',
          {
            tariffario_clinic_id: selectTariffarioClinic.value
              ? selectTariffarioClinic.value.id
              : null,
            type: 'package'
          }
        )
        .then((res) => {
          pacchetti_data.value = res.data
        })

      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/elenco-pacchetti-distinte',
          {
            tariffario_clinic_id: selectTariffarioClinic.value
              ? selectTariffarioClinic.value.id
              : null,
            type: 'distinta'
          }
        )
        .then((res) => {
          distinta_data.value = res.data
        })

      // axios
      //   .get(
      //     '/' +
      //       localStorage.getItem('clinic') +
      //       '/tariffario/elenco-tariffari-clinica'
      //   )
      //   .then((res) => {
      //     tariffari_mag.value = res.data
      //   })
      // categorie

      // selectedCategoria.value = null

      if (selectedType.value === TARIFFARIO) {
        let payload = {
          only_alias: onlyAlias.value,
          tariffario_clinic_id: selectTariffarioClinic.value
            ? selectTariffarioClinic.value.id
            : null
        }
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/tariffario/elenco-tariffari-categorie',
            payload
          )
          .then((res) => {
            categorie.value = res.data
          })
      } else {
        // axios
        //   .get(
        //     '/' + localStorage.getItem('clinic') + '/warehouse/lista-categorie'
        //     // {
        //     //   only_alias: onlyAlias.value,
        //     //   tariffario_clinic_id: selectTariffarioClinic.value
        //     //     ? selectTariffarioClinic.value.id
        //     //     : null
        //     // }
        //   )
        //   .then((res) => {
        //     categorie.value = res.data
        //   })
        let payload = {
          only_alias: false,
          tariffario_clinic_id: selectTariffarioClinic.value
            ? selectTariffarioClinic.value.id
            : null
        }
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/tariffario/elenco-tariffari-categorie',
            payload
          )
          .then((res) => {
            categorie.value = res.data
          })
        // axios
        //   .get(
        //     '/' + localStorage.getItem('clinic') + '/warehouse/lista-categorie'
        //     // {
        //     //   only_alias: onlyAlias.value,
        //     //   tariffario_clinic_id: selectTariffarioClinic.value
        //     //     ? selectTariffarioClinic.value.id
        //     //     : null
        //     // }
        //   )
        //   .then((res) => {
        //     categorie.value = res.data
        //   })
      }

      // categorie
    }

    const deletePackageDistinte = (id, type) => {
      store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/delete-pacchetti-distinte',
          {
            id: id,
            type: type
          }
        )
        .then((res) => {
          if (type == 'package') {
            var text = 'Pacchetto eliminato correttamente'
            if (res.data != 1) text = "Errore, contattare l'assistenza"

            selectedPackage.value = null
            store.dispatch('commonComponents/openNotificationModal', {
              message: text
            })
            store.dispatch('commonComponents/hideLoadingModalAction')
          }
          getData()
        })
        .catch(() => {
          store.dispatch('commonComponents/hideLoadingModalAction')
        })
    }

    const modifyPackage = () => {
      editPackageTitle.value = selectedPackage.value.alias
      editPackagePrices.value['t1'] =
        Math.round(
          (selectedPackage.value.prices['t1'] *
            (showIva.value ? ivaPerc.value : 1) +
            Number.EPSILON) *
            100
        ) / 100

      editPackagePrices.value['t2'] =
        Math.round(
          (selectedPackage.value.prices['t2'] *
            (showIva.value ? ivaPerc.value : 1) +
            Number.EPSILON) *
            100
        ) / 100
      editPackagePrices.value['t3'] =
        Math.round(
          (selectedPackage.value.prices['t3'] *
            (showIva.value ? ivaPerc.value : 1) +
            Number.EPSILON) *
            100
        ) / 100
      editPackagePrices.value['t4'] =
        Math.round(
          (selectedPackage.value.prices['t4'] *
            (showIva.value ? ivaPerc.value : 1) +
            Number.EPSILON) *
            100
        ) / 100

      Object.keys(
        selectedPackage.value.sub_packages.tariffario_ids.tariffario_price
      ).forEach((tariff) => {
        let tmp =
          selectedPackage.value.sub_packages.tariffario_ids.tariffario_price[
            tariff
          ]
        tmp.id = parseInt(tmp.id)
        editPackagePrestazioni.value.push(tmp)
      })

      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/elenco-tariffari-categorie',
          {
            only_alias: true,
            tariffario_clinic_id: selectTariffarioClinic.value
              ? selectTariffarioClinic.value.id
              : null
          }
        )
        .then((res) => {
          edit_prestazione_categorie.value = res.data
        })

      editPackage.value = true
    }

    const addEditPrestazione = (element) => {
      console.log('add edit', element)
      if (element && element !== undefined) {
        element.forEach((e) => {
          let add = true
          editPackagePrestazioni.value.forEach((el) => {
            if (el.id * 1 === e.id * 1) {
              add = false
            }
          })
          if (add === true) editPackagePrestazioni.value.push(e)
        })
      }
    }

    const removeEditPrestazione = (element) => {
      console.log(element)
      editPackagePrestazioni.value = editPackagePrestazioni.value.filter(
        (el) => {
          let remove = false
          element.forEach((e) => {
            if (e.id * 1 === el.id * 1) remove = true
          })
          return !remove
        }
      )
    }

    const removeSingleEditPrestazione = (element) => {
      console.log(element)
      editPackagePrestazioni.value = editPackagePrestazioni.value.filter(
        (el) => {
          return el.id !== element.id
        }
      )
    }

    const removeSingleNewPrestazione = (element) => {
      console.log(element)
      new_prestazione_newids.value = new_prestazione_newids.value.filter(
        (el) => {
          return el !== element.id
        }
      )
      new_prestazione_newData.value = new_prestazione_newData.value.filter(
        (el) => {
          return el.id !== element.id
        }
      )
    }

    const saveModifyPackage = () => {
      if (!editPackageTitle.value || editPackageTitle.value === '') {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire il nome'
        })
        return
      }

      if (selected_taglia.value) {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire la taglia'
        })
        return
      }
      if (
        editPackagePrestazioni.value == null ||
        editPackagePrestazioni.value.length === 0
      ) {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di selezionare almeno una prestazione',
          background: false
        })
        return
      }
      let ids = []
      editPackagePrestazioni.value.forEach((e) => {
        ids.push(e.id)
      })

      editPackagePrices.value['t1'] =
        editPackagePrices.value['t1'] / (showIva.value ? ivaPerc.value : 1)
      editPackagePrices.value['t2'] =
        editPackagePrices.value['t2'] / (showIva.value ? ivaPerc.value : 1)
      editPackagePrices.value['t3'] =
        editPackagePrices.value['t3'] / (showIva.value ? ivaPerc.value : 1)
      editPackagePrices.value['t4'] =
        editPackagePrices.value['t4'] / (showIva.value ? ivaPerc.value : 1)
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/edit-pacchetti-distinte',
          {
            prices: editPackagePrices.value,
            sub_packages: { tariffario_ids: ids },
            alias: editPackageTitle.value,
            type: 'package',
            id: selectedPackage.value.id
          }
        )
        .then(() => {
          editPackage.value = false
          editPackagePrestazioni.value = []
          edit_prestazione_categoria_selected.value = null
          edit_prestazione_categorie.value = []
          editPackagePrices.value = { t1: 0, t2: 0, t3: 0, t4: 0 }
          edit_prestazione_nomenclatore.value = null
          openBlock.value = 1
          getData()
          store.dispatch('commonComponents/openNotificationModal', {
            message: 'Pacchetto modificato con successo'
          })
        })
    }

    const selectCategoriaEditPrest = (x) => {
      getNomenclatoreEditPrest(x, pagination.value)
    }

    const changePaginationEditPrest = (value) => {
      pagination.value = value
      getNomenclatoreEditPrest(null, pagination.value)
    }

    const changeNumPagesEditPrest = (value) => {
      pagination.value.forPage = value
      pagination.value.page = 1
      getNomenclatoreEditPrest(null, pagination.value)
    }

    const getNomenclatoreEditPrest = (elem, pagination) => {
      if (elem) edit_prestazione_categoria_selected.value = elem
      store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/tariffario/nomenclatore',
          {
            tariffario_clinic_id: selectTariffarioClinic.value.id,
            category_id: edit_prestazione_categoria_selected.value
              ? edit_prestazione_categoria_selected.value.id
              : null,
            per_page: pagination.forPage,
            page: pagination.page,
            only_alias: onlyAlias.value,
            search: searchText.value
          }
        )
        .then((res) => {
          store.dispatch('commonComponents/hideLoadingModalAction')
          editPrestKey.value += 1
          edit_prestazione_nomenclatore.value = res.data
        })
        .catch(() => {
          store.dispatch('commonComponents/hideLoadingModalAction')
        })
    }

    // modale gestione prezzo
    const priceModal = ref(false)
    const priceReadOnly = ref(false)
    const priceModalDiscount = ref(null)
    const priceModalGreatest = ref(null)

    const handlePriceModal = (
      doc,
      index,
      readOnly,
      discount,
      greatest,
      tagliaLabel
    ) => {
      if (discount) priceModalDiscount.value = discount
      if (greatest) priceModalGreatest.value = greatest
      if (tagliaLabel) priceModalTagliaLabel.value = tagliaLabel
      selectedNomenclatore.value = doc
      selectedNomenclatore.value.indexNewDocs = index
      console.log(permissions.value?.tariffario?.store_tariffario)
      if (readOnly) {
        priceReadOnly.value = readOnly
      }

      if (
        permissions.value &&
        permissions.value?.tariffario?.store_tariffario?.enabled === false
      ) {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'I permessi di modifica sono disattivati'
        })
        priceReadOnly.value = true
      }

      priceModal.value = true
    }

    const newTariffarioClinic = ref(false)
    const openNewTariffarioClinic = () => {
      newTariffarioClinic.value = true
    }
    const closeNewTariffarioClinic = () => {
      newTariffarioClinic.value = false
    }

    const closePriceModal = () => {
      priceModalDiscount.value = null
      priceModalGreatest.value = null
      selectedNomenclatore.value = null
      priceModal.value = false
      priceModalTagliaLabel.value = null
      priceReadOnly.value = false
    }

    const addPrice = (price) => {
      if (searchNomenclatori.value) {
        searchNomenclatori.value[
          selectedNomenclatore.value.indexNewDocs
        ].price_added = true
      } else {
        nomenclatore.value.data[
          selectedNomenclatore.value.indexNewDocs
        ].price_added = true
      }

      selectedNomenclatore.value.code = price
      // aliasToSave.value.push(selectedNomenclatorce.value)
      aliasToSave.value[selectedNomenclatore.value.id] =
        selectedNomenclatore.value
      priceModal.value = false
      priceReadOnly.value = false
    }

    const addAlias = (doc) => {
      if (aliasToSave.value && Object.keys(aliasToSave.value).length > 0) {
        // Object.keys(props.elem).forEach(function (k) {})
        aliasToSave.value[doc.id].alias = doc.alias
        //var alias = aliasToSave.value.find((elem) => elem.id === doc.id)
        // alias.alias = doc.alias
      } else {
        aliasToSave.value[doc.id] = doc
      }
    }

    const updateAlias = (newDoc) => {
      aliasToSave.value = newDoc
    }

    const addNewPrestazione = (elements) => {
      elements.forEach((element) => {
        if (
          new_prestazione_newids.value &&
          !new_prestazione_newids.value.includes(element.id)
        ) {
          new_prestazione_newids.value.push(element.id)
          new_prestazione_newData.value.push(element)
        }
      })
    }

    const removeNewPrestazione = (elements) => {
      elements.forEach((element) => {
        new_prestazione_newids.value = new_prestazione_newids.value.filter(
          (elem) => {
            return elem !== element.id
          }
        )
        new_prestazione_newData.value = new_prestazione_newData.value.filter(
          (elem) => {
            return elem.id !== element.id
          }
        )
      })
    }

    const addNewPharma = (elements) => {
      elements.forEach((element) => {
        if (!new_farmaco_newids.value.includes(element.id)) {
          new_farmaco_newids.value.push(element.id)
          new_farmaco_data.value.push(element)
        }
      })
    }

    const removeNewPharma = (elements) => {
      elements.forEach((element) => {
        new_farmaco_newids.value = new_farmaco_newids.value.filter((elem) => {
          return elem !== element.id
        })

        new_farmaco_data.value = new_farmaco_data.value.filter((elem) => {
          return elem.id !== element.id
        })
      })
    }

    const handleExportTariffarioModal = () => {
      showExportInvoiceModal.value = !showExportInvoiceModal.value
    }

    return {
      TARIFFARIO,
      TARIFFARIO_MAG,
      CATEGORIE,
      PACCHETTI,
      DISTINTE,
      pagination,
      forPageOption,
      tariffari,
      tariffari_mag,
      selectTariffarioClinic,
      categorie,
      categorie_mag,
      selectedCategoria,
      nomenclatore,
      selectedNomenclatore,
      aliasToSave,
      getData,
      deletePackageDistinte,
      priceModal,
      selectedPackage,
      handlePriceModal,
      searchResult,
      closePriceModal,
      addPrice,
      addAlias,
      priceReadOnly,
      updateAlias,
      newTariffarioClinic,
      openNewTariffarioClinic,
      closeNewTariffarioClinic,
      selectedType,
      onlyAlias,
      new_prestazione_data,
      new_farmaco_data,
      new_prestazione_categorie,
      pacchetti_data,
      distinta_data,
      new_farmaco_selected_categoria,
      new_prestazione_selected_categoria,
      new_prestazione_nomenclatore,
      new_farmaco_nomenclatore,
      addNewPrestazione,
      removeNewPrestazione,
      addNewPharma,
      removeNewPharma,
      new_prestazione_newids,
      new_prestazione_newData,
      new_farmaco_newids,
      new_farmaco_categorie,
      confirm_package,
      elenco_taglie,
      selected_taglia,
      selectedType2,
      searchText,
      searchNomenclatori,
      permissions,
      priceModalDiscount,
      priceModalGreatest,
      priceModalTagliaLabel,
      openBlock,
      editPackage,
      editPackagePrices,
      editPackagePrestazioni,
      modifyPackage,
      showIva,
      ivaPerc,
      edit_prestazione_categorie,
      selectCategoriaEditPrest,
      edit_prestazione_categoria_selected,
      edit_prestazione_nomenclatore,
      getNomenclatoreEditPrest,
      changePaginationEditPrest,
      changeNumPagesEditPrest,
      addEditPrestazione,
      removeEditPrestazione,
      saveModifyPackage,
      editPackageTitle,
      removeSingleEditPrestazione,
      newPackagePrices,
      newPackageName,
      removeSingleNewPrestazione,
      editPrestKey,
      newPrestKey,
      sconto,
      maggiorazione,
      handleExportTariffarioModal,
      showExportInvoiceModal
    }
  },

  data() {
    return {
      tariffario_title: null,
      categoria_selected: null,
      tariffario: true,
      magazzino: false,
      prestazione: true,
      pacchetti: false,
      distinti: false,
      categoriee: true,
      new_prestazione: true,
      new_farmaco: false,
      create_pacchetto: false,
      create_distinta: false,
      t_all: ['t1', 't2', 't3', 't4']
    }
  },
  computed: {
    ...mapGetters({
      cassaPrevidenziale: 'reusableData/getCassaPrevidenzialeActive'
    }),
    newPricesSum: function () {
      let result = {
        t1: 0,
        t2: 0,
        t3: 0,
        t4: 0
      }

      if (
        this.new_prestazione_newData &&
        this.new_prestazione_newData.length > 0 &&
        this.selected_taglia
      ) {
        this.new_prestazione_newData.forEach((elem) => {
          Object.keys(elem.code).forEach((elem_2) => {
            Object.keys(elem.code[elem_2]).forEach((taglia) => {
              if (
                elem.code[elem_2][taglia].id_tariffario_taglie ===
                this.selected_taglia
              ) {
                if (elem.code[elem_2][taglia].data)
                  result[elem_2] +=
                    (elem.code[elem_2][taglia].data_netto ??
                      elem.code[elem_2][taglia].data) *
                    (this.showIva === true ? this.ivaPerc ?? 1 : 1)
                // if (this.showIva === true)
                //   result[elem_2] = result[elem_2] * this.ivaPerc
                // if (
                //   this.cassaPrevidenziale &&
                //   this.cassaPrevidenziale.is_active
                // )
                //   result[elem_2] +=
                //     result[elem_2] *
                //     (this.cassaPrevidenziale.vat
                //       ? this.cassaPrevidenziale.vat / 100
                //       : 0.02)
              }
            })
          })
        })
      }

      if (this.new_farmaco_data) {
        this.new_farmaco_data.forEach((el) => {
          this.t_all.forEach((t) => {
            result[t] +=
              el.price * (this.showIva === true ? this.ivaPerc ?? 1 : 1)
          })
        })
      }

      return result
    },
    pricesSumCompiled: function () {
      let result = {
        t1: 0,
        t2: 0,
        t3: 0,
        t4: 0
      }

      if (
        this.selectedPackage &&
        this.selectedPackage.sub_packages &&
        this.selectedPackage.sub_packages.tariffario_ids &&
        this.selectedPackage.sub_packages.tariffario_ids.tariffario_price
      ) {
        Object.keys(
          this.selectedPackage.sub_packages.tariffario_ids.tariffario_price
        ).forEach((tariff) => {
          if (
            this.selectedPackage.sub_packages.tariffario_ids.tariffario_price[
              tariff
            ].price
          )
            Object.keys(
              this.selectedPackage.sub_packages.tariffario_ids.tariffario_price[
                tariff
              ].price
            ).forEach((t) => {
              Object.keys(
                this.selectedPackage.sub_packages.tariffario_ids
                  .tariffario_price[tariff].price[t]
              ).forEach((taglia) => {
                if (
                  this.selectedPackage.sub_packages.tariffario_ids
                    .tariffario_price[tariff].price[t][taglia]
                    .id_tariffario_taglie ===
                  this.selectedPackage.id_tariffario_taglia
                ) {
                  if (
                    this.selectedPackage.sub_packages.tariffario_ids
                      .tariffario_price[tariff].price[t][taglia].data
                  )
                    result[t] +=
                      this.selectedPackage.sub_packages.tariffario_ids
                        .tariffario_price[tariff].price[t][taglia].data_netto ??
                      this.selectedPackage.sub_packages.tariffario_ids
                        .tariffario_price[tariff].price[t][taglia].data
                }
              })
            })
        })
      }

      if (
        this.selectedPackage?.discount &&
        this.selectedPackage?.discount !== 0 &&
        this.selectedPackage?.discount !== '0'
      ) {
        result['t1'] -=
          result['t1'] * ((this.selectedPackage?.discount * 1) / 100)
        result['t2'] -=
          result['t2'] * ((this.selectedPackage?.discount * 1) / 100)
        result['t3'] -=
          result['t3'] * ((this.selectedPackage?.discount * 1) / 100)
        result['t4'] -=
          result['t4'] * ((this.selectedPackage?.discount * 1) / 100)
      }

      if (
        this.selectedPackage?.greatest &&
        this.selectedPackage?.greatest !== 0 &&
        this.selectedPackage?.greatest !== '0'
      ) {
        result['t1'] +=
          result['t1'] * ((this.selectedPackage?.greatest * 1) / 100)
        result['t2'] +=
          result['t2'] * ((this.selectedPackage?.greatest * 1) / 100)
        result['t3'] +=
          result['t3'] * ((this.selectedPackage?.greatest * 1) / 100)
        result['t4'] +=
          result['t4'] * ((this.selectedPackage?.greatest * 1) / 100)
      }

      return result
    }
  },
  watch: {
    // searchText: function () {
    //   this.getNomenclatore(this.selectedCategoria, this.pagination)
    // }
  },
  mounted() {
    this.getData()
  },

  methods: {
    handleBlock(index) {
      this.openBlock = index
      console.log(this.openBlock)
      if (index === 1 || index === 0) {
        this.create_pacchetto = false
        this.create_distinta = false
        this.new_farmaco_nomenclatore = null
        this.new_prestazione_nomenclatore = null
        this.new_prestazione_categorie = null
        this.new_farmaco_data = null
        this.new_prestazione_data = null
        this.new_prestazione_newData = []
        this.new_farmaco_newids = []
        this.new_prestazione_newids = []
        this.new_prestazione_selected_categoria = null
        this.new_farmaco_selected_categoria = null
        this.selected_taglia = null
        this.editPackage = false
        this.editPackagePrestazioni = []
        this.edit_prestazione_categoria_selected = null
        this.edit_prestazione_categorie = []
        this.editPackagePrices = { t1: 0, t2: 0, t3: 0, t4: 0 }
        this.edit_prestazione_nomenclatore = null
        this.pagination = { forPage: this.forPageOption[0], page: 1 }
        this.aliasToSave = {}
      }
    },
    startSearch(text) {
      if (text !== '' && text.length > 1) {
        this.getNomenclatore(
          null,
          {
            page: 1,
            forPage: 1000
          },
          true
        )
        this.searchNomenclatori = null
      } else {
        this.searchResult = null
        this.searchNomenclatori = null
      }
    },
    createNewPachetto(pacchetto) {
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/elenco-tariffari-categorie',
          {
            only_alias: this.onlyAlias,
            tariffario_clinic_id: this.selectTariffarioClinic
              ? this.selectTariffarioClinic.id
              : null
          }
        )
        .then((res) => {
          this.new_prestazione_categorie = res.data
        })

      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/medical_device/list-categories'
        )
        .then((res) => {
          this.new_farmaco_categorie = res.data
        })

      axios
        .get('/' + localStorage.getItem('clinic') + '/tariffario/elenco-taglie')
        .then((res) => {
          this.elenco_taglie = res.data
        })

      if (pacchetto === true) {
        this.create_pacchetto = true
      } else {
        this.create_distinta = true
      }

      this.selectedCategoria = null
      this.nomenclatore = null
      // this.selectedType2 = this.CATEGORIE
      this.openBlock = 2
      console.log('este es el click que quiero', this.openBlock)
    },
    createNewDistinta() {
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/elenco-tariffari-categorie',
          {
            only_alias: true,
            tariffario_clinic_id: this.selectTariffarioClinic
              ? this.selectTariffarioClinic.id
              : null
          }
        )
        .then((res) => {
          this.new_prestazione_categorie = res.data
        })

      axios
        .get(
          '/' + localStorage.getItem('clinic') + '/warehouse/lista-categorie'
        )
        .then((res) => {
          this.new_farmaco_categorie = res.data
        })

      this.create_distinta = true
      this.nomenclatore = null
      this.openBlock = 2
    },
    setSelectTariffarioClinic(elem) {
      this.tariffario_title = elem.name
      this.openBlock = 1
      this.create_pacchetto = false
      this.create_distinta = false
      if (Object.keys(this.aliasToSave).length > 0) {
        if (
          this.selectTariffarioClinic &&
          this.selectTariffarioClinic.id != elem.id
        ) {
          this.$store.dispatch('commonComponents/openConfirmModal', {
            title: 'Attenzione',
            subtitle:
              'Cambiando tariffario perderai i progressi non salvati, continuare?',
            background: false,
            confirm: () => {
              this.aliasToSave = {}
              this.nomenclatore = []
              this.getData()
              this.selectTariffarioClinic = elem
            },
            decline: () => {}
          })
        }
      } else {
        this.nomenclatore = []
        this.selectTariffarioClinic = elem
        this.getData()
      }
    },
    selectSearchCategory(j) {
      this.searchNomenclatori = j
      this.openBlock = 2
    },
    selectCategoria(elem) {
      this.openBlock = 2
      this.selectedCategoria = elem
      if (Object.keys(this.aliasToSave).length > 0) {
        if (this.selectedCategoria && this.selectedCategoria.id != elem.id) {
          this.$store.dispatch('commonComponents/openConfirmModal', {
            title: 'Attenzione',
            subtitle:
              'Cambiando categoria perderai i progressi non salvati, continuare?',
            background: false,
            confirm: () => {
              this.aliasToSave = {}
              this.getNomenclatore(elem, this.pagination)
            },
            decline: () => {}
          })
        }
      } else this.getNomenclatore(elem, this.pagination)
      this.categoria_selected = elem.category
    },
    selectPacchetto(elem, type) {
      this.openBlock = 2
      this.selectedPackage = elem
      this.selectedType2 = type
    },
    removePacchetto(elem) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle:
          "Confermi di voler eliminare il pacchetto '" + elem.alias + "' ?",
        background: false,
        confirm: () => {
          console.log('confermo')

          this.deletePackageDistinte(elem.id, 'package')
          this.getData()
        },
        decline: () => {}
      })
    },
    editPacchetto(elem) {
      this.editPackageData = {
        alias: elem.alias,
        discount: elem.discount,
        greatest: elem.greatest
      }

      this.confirm_package = true
    },
    selectCategoriaNewPrest(elem) {
      this.getNomenclatoreNewPrest(elem, this.pagination)
    },
    selectCategoriaNewPharma(elem) {
      this.getNomenclatoreNewPharma(elem, this.pagination)
    },
    selectType(selected) {
      this.selectedType = selected
      this.selectedCategoria = null
      this.nomenclatore = null
      this.getData()
    },
    changePagination(value) {
      this.pagination = value
      this.getNomenclatore(this.selectedCategoria, this.pagination)
    },
    changePaginationNewPrest(value) {
      this.pagination = value
      this.getNomenclatoreNewPrest(null, this.pagination)
    },
    changeNumPages(value) {
      console.log(
        'ho capito che devo cambiare pagina',
        this.selectedCategoria,
        this.pagination
      )
      this.pagination.forPage = value
      this.getNomenclatore(this.selectedCategoria, this.pagination)
    },
    changeNumPagesNewPrest(value) {
      console.log(
        'ho capito che devo cambiare pagina',
        this.selectedCategoria,
        this.pagination
      )
      this.pagination.forPage = value
      this.pagination.page = 1
      this.getNomenclatoreNewPrest(null, this.pagination)
    },
    getNomenclatore(elem, pagination, fromSearch) {
      this.selectedCategoria = elem

      if (this.selectedType === this.TARIFFARIO) {
        this.$store.dispatch('commonComponents/showLoadingModalAction')
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/tariffario/nomenclatore',
            {
              tariffario_clinic_id: this.selectTariffarioClinic.id,
              category_id: elem ? elem.id : null,
              per_page: pagination.forPage * 1,
              page: pagination.page,
              only_alias: this.onlyAlias,
              search: this.searchText
            }
          )
          .then((res) => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            if (fromSearch) {
              this.searchResult = res.data
            } else {
              this.nomenclatore = res.data
            }
          })
          .catch(() => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
          })
      } else {
        this.$store.dispatch('commonComponents/showLoadingModalAction')
        axios
          .post(
            '/' +
              //localStorage.getItem('clinic') +
              '/medical_device_magazzino',
            {
              //tariffario_clinic_id: this.selectTariffarioClinic.id,
              category_id: elem.category_id,
              per_page: pagination.forPage,
              page: pagination.page
              //only_alias: this.onlyAlias,
              //search: this.searchText
            }
          )
          .then((res) => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            //z this.new_farmaco_nomenclatore = res.data
            this.nomenclatore = res.data.data
          })
          .catch(() => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
          })
        // axios
        //   .post(
        //     '/' + localStorage.getItem('clinic') + '/warehouse/lista-articoli',
        //     {
        //       warehouses_tariffario_clinic_id: this.selectTariffarioClinic.id,
        //       category_id: elem.id,
        //       per_page: pagination.forPage,
        //       page: pagination.page
        //     }
        //   )
        //   .then((res) => {
        //     this.$store.dispatch('commonComponents/hideLoadingModalAction')
        //     if (fromSearch) {
        //       this.searchResult = res.data
        //     } else {
        //       this.nomenclatore = res.data
        //     }
        //     // this.nomenclatore = res.data
        //   })
        //   .catch(() => {
        //     this.$store.dispatch('commonComponents/hideLoadingModalAction')
        //   })
      }
    },

    getNomenclatoreNewPrest(elem, pagination) {
      if (elem) this.new_prestazione_selected_categoria = elem
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/tariffario/nomenclatore',
          {
            tariffario_clinic_id: this.selectTariffarioClinic.id,
            category_id: this.new_prestazione_selected_categoria
              ? this.new_prestazione_selected_categoria.id
              : null,
            per_page: pagination.forPage,
            page: pagination.page,
            only_alias: this.onlyAlias,
            search: this.searchText
          }
        )
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.newPrestKey += 1
          this.new_prestazione_nomenclatore = res.data
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    getNomenclatoreNewPharma(elem, pagination) {
      this.new_farmaco_selected_categoria = elem
      this.$store.dispatch('commonComponents/showLoadingModalAction')

      axios
        .post(
          '/' +
            // localStorage.getItem('clinic') +
            '/medical_device_magazzino',
          {
            //tariffario_clinic_id: this.selectTariffarioClinic.id,
            category_id: elem.category_id,
            per_page: pagination.forPage,
            page: pagination.page
            //only_alias: this.onlyAlias,
            //search: this.searchText
          }
        )
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.new_farmaco_nomenclatore = res.data
          this.new_farmaco_nomenclatore.data =
            this.new_farmaco_nomenclatore.data.map((el) => {
              return { ...el, package_qty: 1 }
            })
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })

      // axios
      //   .post(
      //     '/' + localStorage.getItem('clinic') + '/tariffario/nomenclatore',
      //     {
      //       tariffario_clinic_id: this.selectTariffarioClinic.id,
      //       category_id: elem.id,
      //       per_page: pagination.forPage,
      //       page: pagination.page
      //     }
      //   )
      //   .then((res) => {
      //     this.new_prestazione_nomenclatore = res.data
      //   })
    },
    confirmNewPrices() {
      var sendData = {}
      if (this.aliasToSave) {
        if (this.selectedType === this.TARIFFARIO) {
          sendData = {
            tariffario_clinic_id: this.selectTariffarioClinic.id,
            price: this.aliasToSave
          }
        } else {
          // let products = []
          // this.aliasToSave.forEach((elem) => {
          //   products.push({
          //     warehouse_id: elem.id,
          //     qty: elem.qty_in_tariffario,
          //     price: elem.price
          //   })
          // })
          // sendData = {
          //   warehouses_tariffario_clinic_id: this.selectTariffarioClinic.id,
          //   products: products
          // }
        }
      }

      if (this.selectedType === this.TARIFFARIO) {
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/tariffario/salva-tariffario',
            sendData
          )
          .then((res) => {
            if (res.data.status == 'success') {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Prezzi salvati correttamente'
              })
              this.aliasToSave = {}
              this.selectedNomenclatore = null
              this.priceModal = false
              this.selectCategoria(this.selectedCategoria)
            }
          })
      } else {
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/medical_device/edit_articoli',
            this.nomenclatore
          )
          .then(() => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Prezzi salvati correttamente'
            })
            this.aliasToSave = {}

            // this.selectCategoria(this.selectedCategoria)
          })
      }
    },
    openConfirmPrestazione() {
      if (
        this.new_prestazione_newids == null ||
        this.new_prestazione_newids.length === 0
      ) {
        this.$store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di selezionare almeno una prestazione',
          background: false
        })
        return
      }
      if (!this.selected_taglia) {
        this.$store.dispatch('commonComponents/openNotificationModal', {
          message:
            'Si prega di selezionare la taglia prima di confermare il nuovo pacchetto',
          background: false
        })
        return
      }

      console.log(this.t1)
      console.log(this.t2)
      console.log(this.t3)
      console.log(this.t4)
      console.log(this.name)
    },
    confirmNewPrestazione() {
      if (!this.newPackageName || this.newPackageName === '') {
        this.$store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire il nome'
        })
        return
      }

      if (!this.selected_taglia) {
        this.$store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire la taglia'
        })
        return
      }
      if (
        this.new_prestazione_newids == null ||
        this.new_prestazione_newids.length === 0
      ) {
        this.$store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di selezionare almeno una prestazione',
          background: false
        })
        return
      }

      this.newPackagePrices['t1'] =
        this.newPackagePrices['t1'] / (this.showIva ? this.ivaPerc : 1)
      this.newPackagePrices['t2'] =
        this.newPackagePrices['t2'] / (this.showIva ? this.ivaPerc : 1)
      this.newPackagePrices['t3'] =
        this.newPackagePrices['t3'] / (this.showIva ? this.ivaPerc : 1)
      this.newPackagePrices['t4'] =
        this.newPackagePrices['t4'] / (this.showIva ? this.ivaPerc : 1)

      let payload = {
        type: this.create_pacchetto ? 'package' : 'distinta',
        alias: this.newPackageName,
        tariffario_clinic_id: this.selectTariffarioClinic.id,
        tariffario_ids: this.new_prestazione_newids,
        warehouse_ids: this.new_farmaco_newids,
        // discount: this.sconto,
        // greatest: this.maggiorazione,
        id_tariffario_taglia: this.selected_taglia
      }

      if (this.create_pacchetto) {
        payload.prices = this.newPackagePrices
      }
      if (this.create_distinta) {
        payload.discount = this.sconto
        payload.greatest = this.maggiorazione
      }
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/salva-pacchetto-distinta',
          payload
        )
        .then(() => {
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Pacchetto salvato correttamente'
          })
          this.aliasToSave = {}

          this.confirm_package = false

          this.interruptNewPacchetto()
          this.getData()

          // this.selectCategoria(this.selectedCategoria)
        })
    },

    interruptNewPacchetto() {
      this.openBlock = 1
      this.create_pacchetto = false
      this.create_distinta = false
      this.new_farmaco_nomenclatore = null
      this.new_prestazione_nomenclatore = null
      this.new_prestazione_categorie = null
      this.new_farmaco_data = null
      this.new_prestazione_data = null
      this.new_prestazione_newData = []
      this.new_farmaco_newids = []
      this.new_prestazione_newids = []
      this.new_prestazione_selected_categoria = null
      this.new_farmaco_selected_categoria = null
      this.selected_taglia = null
      this.selectedType2 = this.PACCHETTI
      this.newPackagePrices = { t1: 0, t2: 0, t3: 0, t4: 0 }
      this.newPackageName = ''
      this.pagination = { forPage: this.forPageOption[0], page: 1 }
      this.sconto = null
      this.maggiorazione = null
    },
    getAliasData(docKey, id) {
      if (this.searchNomenclatori) {
        if (this.aliasToSave[id].alias)
          this.searchNomenclatori[docKey].alias = this.aliasToSave[id].alias

        if (this.aliasToSave[id].code) {
          this.searchNomenclatori[docKey].code = this.aliasToSave[id].code
          this.searchNomenclatori[docKey].price_added = true
        }
      } else {
        if (this.aliasToSave[id].alias)
          this.nomenclatore.data[docKey].alias = this.aliasToSave[id].alias

        if (this.aliasToSave[id].code) {
          this.nomenclatore.data[docKey].code = this.aliasToSave[id].code
          this.nomenclatore.data[docKey].price_added = true
        }
      }
    },
    addTariffario(name) {
      this.newTariffarioClinic = false
      if (this.selectedType === this.TARIFFARIO) {
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/tariffario/new-tariffario',
            {
              name: name
            }
          )
          .then(() => {
            this.getData()
          })
      } else {
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/warehouse/add-tariffario',
            {
              name: name
            }
          )
          .then(() => {
            this.getData()
          })
      }
    }

    // searchNomenclatore(elem, pagination) {
    //   this.new_farmaco_selected_categoria = elem

    //   axios
    //     .post(
    //       '/' + localStorage.getItem('clinic') + '/warehouse/lista-articoli',
    //       {
    //         tariffario_clinic_id: this.selectTariffarioClinic.id,
    //         category_id: elem.id,
    //         per_page: pagination.forPage,
    //         page: pagination.page
    //       }
    //     )
    //     .then((res) => {
    //       this.new_farmaco_nomenclatore = res.data
    //     })

    //   // axios
    //   //   .post(
    //   //     '/' + localStorage.getItem('clinic') + '/tariffario/nomenclatore',
    //   //     {
    //   //       tariffario_clinic_id: this.selectTariffarioClinic.id,
    //   //       category_id: elem.id,
    //   //       per_page: pagination.forPage,
    //   //       page: pagination.page
    //   //     }
    //   //   )
    //   //   .then((res) => {
    //   //     this.new_prestazione_nomenclatore = res.data
    //   //   })
    // },
  }
})
</script>
