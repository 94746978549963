<template>
  <div class="paginator-container">
    <div class="prova mr-5 my-2 flex justify-between">
      <div class="mt-1">
        <span class="flex-row text-md ml-2 font-bold mr-2 mt-1">Mostra </span>

        <select
          class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
          @change="(event) => changeNumPages(event)"
        >
          <option v-for="show in forPageOption" :key="show" :value="show">
            {{ show }}
          </option>
        </select>
        <span class="text-md font-bold ml-2 mt-1"> elementi</span>
      </div>
    </div>
    <Paginator
      :for-page-option="pagination.forPage"
      :total-records="notes.total"
      @changePagination="(event) => changePagination(event)"
    />
  </div>

  <!-- BEGIN: Data List -->
  <div class="table-container overflow-scroll scrollbar-none pb-3">
    <table v-if="tableConfigClinicDoc && notes.note" class="w-full">
      <THeader
        v-for="(item, itemKey) in table_config"
        :key="itemKey"
        :name="item.title"
        class="t-header h-10"
      />
      <th class="t-header h-10"></th>
      <tbody>
        <tr>
          <td
            v-for="(elem, index) in table_config"
            :key="index"
            class="text-center px-1 py-3"
          >
            <select
              v-if="elem.type === 'select'"
              v-model="elem.value"
              class="w-full border rounded-md h-7 text-gray-500"
              @change="filterHandler(elem)"
            >
              <option selected></option>
              <option v-for="(item, i) in elem.option" :key="i" :value="item">
                {{ item.name ? item.name : item }}
              </option>
            </select>
            <input
              v-else
              v-model="elem.value"
              :type="elem.type"
              min="0"
              :name="elem.title"
              class="form-control h-7"
              :class="{
                invisible: elem.type === 'none',
                'p-0': elem.type === 'date'
              }"
              :placeholder="elem.title"
              @change="filterHandler(elem)"
            />
          </td>
        </tr>
        <tr
          v-for="(doc, docKey) in notes.note"
          :key="docKey"
          class="h-12 text-xs font-normal hover:bg-theme-45"
          :class="{ 'bg-theme-2': docKey % 2 === 0 }"
        >
          <td class="text-center text-sm border-r-2 border-theme-46">
            {{ $FormatDate(doc.last_update) }}
          </td>
          <td class="text-center text-sm border-r-2 border-theme-46">
            {{
              doc.first_name && doc.last_name
                ? doc.first_name + ' ' + doc.last_name
                : doc.username
            }}
          </td>

          <td
            class="text-center text-sm border-r-2 border-theme-46"
            v-html="doc.note"
          ></td>
          <td class="text-center text-sm border-r-2 border-theme-46">
            <a
              class="hover:text-theme-41 cursor-pointer"
              @click="$emit('modifyNote', doc, true)"
            >
              <i class="fa fa-cog text-base"></i>
            </a>
            <a
              class="cursor-pointer hover:text-theme-41"
              @click="deleteNote(doc.id)"
            >
              <i class="p-3 fas fa-times fill-current" />
            </a>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="notes.note && notes.total === 0"
      class="w-full flex justify-center mt-2"
    >
      Nessun Risultato
    </div>
  </div>
  <!-- END: Data List -->
</template>

<script>
import { defineComponent, computed, toRef, ref, toRaw } from 'vue'
import Paginator from '../../components/paginator/Main'
import THeader from '../../components/cvit-table/THeader.vue'

export default defineComponent({
  components: {
    Paginator,
    THeader
  },
  props: [
    'tableConfigClinicDoc',
    'pagination',
    'forPageOption',
    'selectedPatient',
    'notes'
  ],
  emits: ['addNote', 'deleteNote', 'modifyNote', 'refreshAnnotazioni'],
  setup(props, { emit }) {
    const pagination = toRef(props, 'pagination')

    // variabili paginazione
    const active_filters = ref({})
    const filterActive = ref(false)

    //  cambio elementi per pagina, e ritorno nella prima pagina
    const changePagination = (value) => {
      if (pagination.value.page != value.page) {
        pagination.value.page = value.page
        pagination.value.forPage = value.forPage
        emit(
          'refreshAnnotazioni',
          pagination.value,
          toRaw(active_filters.value)
        )
      }
    }

    // metodo che cambia il numero della pagina
    const changeNumPages = (elem) => {
      if (pagination.value.forPage != elem.target.value) {
        pagination.value.page = 1
        pagination.value.forPage = elem.target.value
      }
    }

    const deleteNote = (note_id) => {
      emit('deleteNote', 'annotations', note_id)
    }

    // ----- COMPUTED ----- //
    const table_config = computed(function () {
      // configurazione tabella, filtrando i dati del paziente nel caso in cui siamo nella sezione anagrafica paziente
      return props.tableConfigClinicDoc.filter(
        (elem) => !(elem.key === 'patient' && props.selectedPatient)
      )
    })

    return {
      table_config,
      changePagination,
      changeNumPages,
      deleteNote,

      active_filters,
      filterActive
    }
  },
  methods: {
    // ----- METODI GESTIONE FILTRI ----- //
    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()

      if (this.filterActive)
        this.$emit(
          'refreshAnnotazioni',
          this.pagination,
          toRaw(this.active_filters)
        )
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    }
    // ----- FINE METODI GESTIONE FILTRI ----- //
  }
})
</script>
