<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-12 p-2 mx-auto overflow-scroll scrollbar-none border sm:w-4/5 w-full shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="flex flex-col content-end overflow-y-auto">
        <!-- Modal header -->
        <div class="flex items-start p-5 rounded-t">
          <h3
            class="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white"
          >
            {{ title }}
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="$emit('close')"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- Fine Modal header -->
        <!-- Inizio Bottoni header (funzionalità da creare) -->
        <div
          v-if="headerButtons"
          class="flex justify-between items-start pb-1 px-3 rounded-t border-b dark:border-gray-600"
        >
          <button
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
          >
            <SaveIcon class="h-5 w-5 mr-2" /> Chiusura
          </button>
          <button
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
          >
            <RotateCwIcon class="h-5 w-5 mr-2" /> Aperto
          </button>
          <button
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
          >
            <PlusIcon class="h-5 w-5 mr-2 fill-black" /> Metti in conto
          </button>
          <button
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
          >
            <PlusIcon class="h-5 w-5 mr-2 fill-black" /> Lettura semplificata
          </button>
          <button
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
          >
            <PlusIcon class="h-5 w-5 mr-2 fill-black" /> Controllo
          </button>
        </div>
        <!-- Inizio Bottoni header -->
        <div
          class="border-t-1 border-gray-400 w-full px-6 text-right flex justify-end items-end"
        >
          <div>
            <button
              @click="expandSections"
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-1 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
            >
              <span class="h-5"></span>
              Espandi Tutto
            </button>
            <button
              @click="collapseSections"
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-1 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              <span class="h-5"></span>
              Riduci tutto
            </button>
          </div>
        </div>
        <!-- Modal body -->
        <div class="p-6 space-y-6">
          <div class="flex flex-col content-start mb-1">
            <div class="items-center">
              <v-select
                v-if="arguments"
                v-model="argumentRef"
                class="col-span-4 w-60 mb-5"
                :options="arguments"
                placeholder="seleziona l'argomento"
                label="name"
              />
              <div
                v-for="(elemRow, indexx) in sections"
                :key="elemRow.title"
                class="flex flex-col"
              >
                <vue-collapsible-panel-group accordion>
                  <vue-collapsible-panel :id="indexx" :expanded="false">
                    <template #title>
                      <p class="w-full text-center">
                        {{ elemRow.title }}
                      </p>
                    </template>
                    <template #content>
                      <div
                        v-for="(elem, index) in elemRow.value"
                        :key="elem.title"
                        class="flex flex-col -my-3"
                      >
                        <DetailModalRow
                          :id="elem.id"
                          :ref="elem.name ? elem.name : elem.description"
                          :elem="
                            elem.elemento_0.type === 'table'
                              ? {
                                  elemento_0: {
                                    type: 'table',
                                    headers: [
                                      'Colonna 5',
                                      'Colonna 2',
                                      'Colonna 9',
                                      'Colonna 4'
                                    ]
                                  },
                                  label: Table + addrow
                                }
                              : elem
                          "
                          :title="elem.label ? elem.label : null"
                          :id-clinic-row-temp="elem.idClinicRowTemp"
                          :value="elem.value || elem.didascalia"
                          :type="elem.type"
                          :buttons="elem.buttons ?? null"
                          :options="elem.options ?? null"
                          :image-bg="elem.imageBg"
                          :modify="modify"
                          :anteprima="elem.anteprima"
                          :bio="elem.bio"
                          :gray="index % 2 === 1"
                          @updateData="
                            (title, value, type, id) =>
                              updateData(
                                title,
                                value,
                                type,
                                id,
                                index,
                                elemRow.title
                              )
                          "
                          @openSpecialisticModal="openSpecialisticModal"
                          @scroll.passive="true"
                        /></div
                    ></template>
                  </vue-collapsible-panel>
                </vue-collapsible-panel-group>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal footer -->

        <div
          class="border-t-1 border-gray-400 w-full px-6 text-right flex justify-between items-end mb-2"
        >
          <div>
            <button
              @click="expandSections"
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
            >
              <span class="h-5"></span>
              Espandi Tutto
            </button>
            <button
              @click="collapseSections"
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
            >
              <span class="h-5"></span>
              Riduci tutto
            </button>
          </div>
          <div>
            <button
              v-if="modifySchedaClinica"
              ref="input4"
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
              @click="enableModify"
            >
              <PlusCircleIcon class="h-5 w-5 mr-2" /> Modifica
            </button>
            <button
              v-if="!modifySchedaClinica"
              ref="input4"
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
              @click="salva"
            >
              <SaveIcon class="h-5 w-5 mr-2" /> Salva
            </button>
            <button
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              @click="$emit('close')"
            >
              <span class="h-5"></span>
              Annulla
            </button>
          </div>
        </div>
        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
  <SpecialisticChoice
    v-if="specialisticModal"
    title=" Schede Specialistiche"
    :options="[
      'Dolore zampa dx anteriore',
      'Gesso per frattura',
      'Controllo ossa'
    ]"
    @close="closeSpecialisticModal"
    @specialisticSelected="handleSpecialisticChoice"
  />
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import DetailModalRow from './DetailModalRow.vue'
import SpecialisticChoice from './SpecialisticChoice.vue'
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel
} from '@swoga/vue-collapsible-panel'
import '@swoga/vue-collapsible-panel/dist/vue-collapsible-panel.css'
import axios from '../../gateway/backend-api'
import { useStore } from '@/store'

export default defineComponent({
  components: {
    DetailModalRow,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
    SpecialisticChoice
  },
  props: [
    'configuration',
    'title',
    'arguments',
    'new',
    'modifySchedaClinica',
    'headerButtons',
    'pet_id',
    'idTemplate',
    'medicalTemplatesId'
  ],
  emits: ['close', 'refresh'],
  setup(props, { emit }) {
    // ----- DATA -----
    const modifyRef = ref(false)
    const argumentRef = ref(null)
    const specialisticModal = ref(false)

    const store = useStore()

    // contiene la copia della configurazione, mappando però i valori con quelli inseriti in input
    const configurationData = ref(props.configuration)

    // ----- METHODS -----

    // abilito la modifica del modale
    const enableModify = () => {
      // modifyRef.value = true
      var data = {}

      sections.value.forEach((section, x) => {
        section.value.forEach((row, i) => {
          if (row.elemento_0) {
            if (row.value[0])
              data['sezione_' + x + '.oggetto_' + i + '.elemento_0.data'] =
                row.value[0]
            else
              data['sezione_' + x + '.oggetto_' + i + '.elemento_0.data'] =
                row.elemento_0.data
          }
          if (row.elemento_1) {
            if (row.value[0])
              data['sezione_' + x + '.oggetto_' + i + '.elemento_1.data'] =
                row.value[1]
            else
              data['sezione_' + x + '.oggetto_' + i + '.elemento_1.data'] =
                row.elemento_1.data
          }
        })
      })

      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/medical/' +
            props.pet_id +
            '/' +
            props.medicalTemplatesId +
            '/edit',
          { data }
        )
        .then(() => {
          store.dispatch('commonComponents/openNotificationModal', {
            message: 'Scheda clinica modificata con successo!'
          })
          emit('refresh')
          emit('close')
        })
        .catch(() => {
          store.dispatch('commonComponents/openMessageModal', {
            title: 'Attenzione',
            subtitle: 'Errore inserimento!',
            background: false
          })
        })
    }

    const salva = () => {
      var data = {}

      sections.value.forEach((section, x) => {
        section.value.forEach((row, i) => {
          if (row.elemento_0) {
            if (row.elemento_0.type === 'table') {
              let temp = []
              data['sezione_' + x + '.oggetto_' + i + '.elemento_0.data'] = temp
            } else {
              data['sezione_' + x + '.oggetto_' + i + '.elemento_0.data'] =
                row.value[0]
            }
          }

          if (row.elemento_1) {
            if (row.elemento_1.type === 'table') {
              let temp = []
              data['sezione_' + x + '.oggetto_' + i + '.elemento_1.data'] = temp
            } else {
              data['sezione_' + x + '.oggetto_' + i + '.elemento_1.data'] =
                row.value[1]
            }
          }
        })
      })

      var send_data = {
        layout_id: props.configuration.id,
        pet_id: props.pet_id,
        data: data
      }

      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/medical/' +
            props.pet_id +
            '/save',
          send_data
        )
        .then((res) => {
          console.log('res save clinic', res)
          store.dispatch('commonComponents/openNotificationModal', {
            message: 'Scheda clinica salvata con successo!'
          })
          emit('refresh')
          emit('close')
        })
        .catch(() => {
          store.dispatch('commonComponents/openMessageModal', {
            title: 'Attenzione',
            subtitle: 'Errore inserimento!',
            background: false
          })
        })
    }

    const updateData = (idClinicRowTemp, value, type, id, index, section) => {
      if (!section) {
        configurationData.value = configurationData.value.map((elem) => {
          if (
            elem.idClinicRowTemp === idClinicRowTemp &&
            elem.type !== 'section'
          ) {
            if (elem.type === 'image') {
              elem.didascalia = value
            } else {
              elem.value = value
            }
          }
          return elem
        })
      } else {
        // !section --> il dato è contenuto in una sottosezioni
        sections.value = sections.value.map((elem) => {
          // identifico la sottosezioni in base alla variabile
          if (elem.type === 'section') {
            // mappo tutti i dati della sottosezione e modifico il dato inserito in input
            elem.value = elem.value.map((el) => {
              if (el.idClinicRowTemp === idClinicRowTemp) {
                if (el.type === 'image') {
                  el.didascalia = value
                } else {
                  el.value = value
                }
              }
              return el
            })
          }
          return elem
        })
      }
    }

    const openSpecialisticModal = () => {
      specialisticModal.value = true
    }

    const handleSpecialisticChoice = () => {
      specialisticModal.value = false
    }

    const closeSpecialisticModal = () => {
      specialisticModal.value = false
    }
    const configurationNoSections = computed(() => {
      return []
    })

    const sections = computed(() => {
      let sec = []
      let temp = []
      const settings = JSON.parse(JSON.stringify(configurationData.value))

      for (let i = 0; settings['sezione_' + i] !== undefined; i++) {
        temp = []

        for (
          let j = 0;
          settings['sezione_' + i]['oggetto_' + j] !== undefined;
          j++
        ) {
          temp.push({
            ...settings['sezione_' + i]['oggetto_' + j],
            value: '',
            idClinicRowTemp: i + '_' + j
          })
        }
        sec.push({
          type: 'section',
          title: settings['sezione_' + i].label,
          value: temp
        })
      }
      return sec
    })

    // ritorno modifica a true se indico nelle props che sto creando una nuova scheda clinica e non visualizzando una già creata
    const modify = computed(() => {
      return props.new === true ? true : modifyRef.value
    })

    return {
      argumentRef,
      enableModify,
      modify,
      salva,
      configurationData,
      updateData,
      configurationNoSections,
      sections,
      specialisticModal,
      openSpecialisticModal,
      handleSpecialisticChoice,
      closeSpecialisticModal
    }
  },
  methods: {
    expandSections() {
      for (var i = 0; i < this.sections.length; i++) {
        if (!document.getElementById(i).className.includes('expanded')) {
          document.getElementById(i).getElementsByTagName('header')[0].click()
        }
      }
    },
    collapseSections() {
      for (var i = 0; i < this.sections.length; i++) {
        if (document.getElementById(i).className.includes('expanded')) {
          document.getElementById(i).getElementsByTagName('header')[0].click()
        }
      }
    }
  }
})
</script>
