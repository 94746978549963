import faker from './faker'
import helper from './helper'
import lodash from './lodash'
import FormatDate from './FormatDate'
import goToFunction from './goToFunctions'

export default (app) => {
  app.use(faker)
  app.use(helper)
  app.use(lodash)
  app.use(FormatDate)
  app.use(goToFunction)
}
