<template>
  <div class="">
    <div
      v-if="backgroundImage || noBg === true"
      class="source flex flex-col justify-center"
    >
      <div class="flex-1">
        <vue-drawing-canvas
          ref="VueCanvasDrawing"
          v-model:image="image"
          :canvasId="canvasId"
          :width="canvasWidth"
          :height="backgroundImage ? canvasHeight : 100"
          :stroke-type="strokeType"
          :line-cap="lineCap"
          :line-join="lineJoin"
          :fill-shape="fillShape"
          :eraser="eraser"
          :lineWidth="line"
          :color="color"
          :background-color="backgroundColor"
          :background-image="backgroundImage"
          :watermark="watermark"
          :initial-image="initialImage"
          saveAs="png"
          :styles="{
            border: 'solid 1px #DEDFE0',
            borderRadius: '5px',
            boxShadow:
              '0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 1px 1px 0 rgba(0, 0, 0, 0.1)'
          }"
          :lock="disabled"
          :additional-images="additionalImages"
        />
      </div>

      <div
        v-if="editable"
        class="flex justify-center flex-wrap gap-1 py-3 bg-theme-2"
        :style="handleWidth"
      >
        <!-- <div>
          <p style="margin-bottom: 0">Upload Background Image:</p>
          <input type="file" @change="setImage($event)" />
        </div> -->
        <button @click="handlePanel">
          <ChevronDownIcon v-if="!showPanel" class="h-6 hover:text-theme-41" />
          <ChevronUpIcon v-if="showPanel" class="h-6 hover:text-theme-41" />
        </button>
        <div
          v-if="showPanel"
          class="flex items-center flex-wrap justify-center gap-1"
        >
          <button
            type="button"
            @click.prevent="disabled = !disabled"
            class="flex gap-1 border border-theme-45 px-2 py-1 rounded-lg items-center text-xs hover:text-theme-41 hover:border-theme-41"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="bi bi-lock fill-current"
              viewBox="0 0 16 16"
            >
              <path
                v-if="!disabled"
                d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z"
              />
              <path
                v-else
                d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2zM5 8h6a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V9a1 1 0 0 1 1-1z"
              />
            </svg>
            <span v-if="!disabled">Unlock</span>
            <span v-else>Lock</span>
          </button>
          <button
            type="button"
            @click.prevent="$refs.VueCanvasDrawing.undo()"
            class="flex gap-1 border border-theme-45 px-2 py-1 rounded-lg items-center text-xs hover:text-theme-41 hover:border-theme-41"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="bi bi-arrow-counterclockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 1-4.546 2.914.5.5 0 0 0-.908-.417A6 6 0 1 0 8 2v1z"
              />
              <path
                d="M8 4.466V.534a.25.25 0 0 0-.41-.192L5.23 2.308a.25.25 0 0 0 0 .384l2.36 1.966A.25.25 0 0 0 8 4.466z"
              />
            </svg>
            Undo
          </button>
          <button
            type="button"
            @click.prevent="$refs.VueCanvasDrawing.redo()"
            class="flex gap-1 border border-theme-45 px-2 py-1 rounded-lg items-center text-xs hover:text-theme-41 hover:border-theme-41"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="bi bi-arrow-clockwise"
              viewBox="0 0 16 16"
            >
              <path
                fill-rule="evenodd"
                d="M8 3a5 5 0 1 0 4.546 2.914.5.5 0 0 1 .908-.417A6 6 0 1 1 8 2v1z"
              />
              <path
                d="M8 4.466V.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384L8.41 4.658A.25.25 0 0 1 8 4.466z"
              />
            </svg>
            Redo
          </button>
          <button
            type="button"
            @click.prevent="$refs.VueCanvasDrawing.reset()"
            class="flex gap-1 border border-theme-45 px-2 py-1 rounded-lg items-center text-xs hover:text-theme-41 hover:border-theme-41"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              fill="currentColor"
              class="bi bi-file-earmark"
              viewBox="0 0 16 16"
            >
              <path
                d="M14 4.5V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h5.5L14 4.5zm-3 0A1.5 1.5 0 0 1 9.5 3V1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V4.5h-2z"
              />
            </svg>
            Reset
          </button>
          <button
            type="button"
            @click.prevent="eraser = !eraser"
            class="flex gap-1 border border-theme-45 px-2 py-1 rounded-lg items-center text-xs hover:text-theme-41 hover:border-theme-41"
            style="width: 72px"
          >
            <div v-if="eraser" class="flex items-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                class="bi bi-eraser"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8.086 2.207a2 2 0 0 1 2.828 0l3.879 3.879a2 2 0 0 1 0 2.828l-5.5 5.5A2 2 0 0 1 7.879 15H5.12a2 2 0 0 1-1.414-.586l-2.5-2.5a2 2 0 0 1 0-2.828l6.879-6.879zm2.121.707a1 1 0 0 0-1.414 0L4.16 7.547l5.293 5.293 4.633-4.633a1 1 0 0 0 0-1.414l-3.879-3.879zM8.746 13.547 3.453 8.254 1.914 9.793a1 1 0 0 0 0 1.414l2.5 2.5a1 1 0 0 0 .707.293H7.88a1 1 0 0 0 .707-.293l.16-.16z"
                />
              </svg>
              <span>Eraser</span>
            </div>
            <div v-else class="flex items-center gap-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                fill="currentColor"
                class="bi bi-pencil"
                viewBox="0 0 16 16"
              >
                <path
                  d="M12.146.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-10 10a.5.5 0 0 1-.168.11l-5 2a.5.5 0 0 1-.65-.65l2-5a.5.5 0 0 1 .11-.168l10-10zM11.207 2.5 13.5 4.793 14.793 3.5 12.5 1.207 11.207 2.5zm1.586 3L10.5 3.207 4 9.707V10h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.293l6.5-6.5zm-9.761 5.175-.106.106-1.528 3.821 3.821-1.528.106-.106A.5.5 0 0 1 5 12.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.468-.325z"
                />
              </svg>
              <span class="ml-1"> Draw</span>
            </div>
          </button>
          <select
            v-model="line"
            class="border border-theme-45 px-2 py-1 rounded-lg"
          >
            <option v-for="n in 25" :key="'option-' + n" :value="n">
              {{ n }}
            </option>
          </select>
          <input
            v-model="color"
            type="color"
            class="border border-theme-45 px-2 py-1 rounded-lg"
          />
          <select
            v-model="strokeType"
            class="border border-theme-45 px-2 py-1 rounded-lg"
          >
            <option value="dash">Linea</option>
            <option value="line">Retta</option>
            <option value="circle">Cerchio</option>
            <option value="square">Quadrato</option>
            <option value="triangle">Triangolo</option>
            <option value="half_triangle">Triangolo Rettangolo</option>
          </select>
        </div>
      </div>
      <div>
        <input
          v-model="insert"
          type="checkbox"
          class="form-checkbox rounded text-theme-41 text-sm focus:ring-0 focus:shadow-none"
        />
        <label for="flexCheckDefault" class="ml-1"
          >Inserire nel cartaceo?</label
        >
      </div>
    </div>
    <div v-else>
      <RotateCwIcon class="animate-spin h-full w-5 mr-3 scale-150" />
    </div>
  </div>
</template>

<script>
import VueDrawingCanvas from 'vue-drawing-canvas'
import axios from '../../gateway/backend-api'

export default {
  components: {
    VueDrawingCanvas
  },
  emits: ['setValue'],
  props: [
    'width',
    'column4',
    'column3',
    'column2',
    'canvasId',
    'idClinicRowTemp',
    'inputValues',
    'conditionsInput',
    'conditionalInputValues',
    'id',
    'index',
    'schedaType',
    'pet_id',
    'indexR',
    'type',
    'idSection',
    'modify',
    'noBg'
  ],

  data() {
    return {
      showPanel: false,
      insert: false,
      initialImage: [],
      x: 0,
      y: 0,
      image: '',
      eraser: false,
      disabled: false,
      fillShape: false,
      line: 2,
      color: '#000000',
      strokeType: 'dash',
      lineCap: 'round',
      lineJoin: 'round',
      backgroundColor: '#FFFFFF',
      backgroundImage: null,
      watermark: null,
      additionalImages: [],
      editable: true,
      canvasWidth: '300',
      canvasHeight: '0'
    }
  },
  computed: {
    handleWidth() {
      return {
        width: `${this.canvasWidth}px`
      }
    }
  },
  watch: {
    image: function (elem) {
      var strokes = this.$refs.VueCanvasDrawing.getAllStrokes()

      this.$emit(
        'setValue',
        elem,
        JSON.stringify(strokes),
        this.index,
        this.indexR,
        this.idClinicRowTemp,
        'image',
        this.id,
        this.insert
      )
    },
    insert: function (elem) {
      if (elem && this.$refs.VueCanvasDrawing) {
        var strokes = this.$refs.VueCanvasDrawing.getAllStrokes()
        this.$emit(
          'setValue',
          this.image,
          JSON.stringify(strokes),
          this.index,
          this.indexR,
          this.idClinicRowTemp,
          'image',
          this.id,
          this.insert
        )
      } else {
        this.$emit(
          'setValue',
          null,
          null,
          this.index,
          this.indexR,
          this.idClinicRowTemp,
          'image',
          this.id,
          this.insert
        )
      }
    }
  },
  mounted() {
    if (this.type == 'image') {
      this.editable = false
      this.disabled = true
    }
    if (typeof this.index != 'undefined') {
      this.getImageUrl()
      if (
        this.conditionsInput &&
        typeof this.conditionsInput[this.indexR] != 'undefined' &&
        typeof this.conditionsInput[this.indexR][this.index] != 'undefined' &&
        this.conditionsInput[this.indexR][this.index].data &&
        this.conditionsInput[this.indexR][this.index].data.strokes
      ) {
        this.initialImage = JSON.parse(
          this.conditionsInput[this.indexR][this.index].data.strokes
        )
        this.editable = false
        this.disabled = true

        if (
          this.conditionsInput[this.indexR][this.index].data &&
          this.conditionsInput[this.indexR][this.index].data.display_image
        )
          this.insert =
            this.conditionsInput[this.indexR][this.index].data.display_image
      } else if (
        this.inputValues &&
        typeof this.inputValues[this.index] != 'undefined'
      ) {
        if (typeof this.inputValues[this.index]?.strokes != 'undefined')
          this.initialImage = JSON.parse(this.inputValues[this.index].strokes)
        this.editable = false
        this.disabled = true
      }

      if (this.modify && this.modify === true) {
        this.editable = true
        this.disabled = false
      }
      if (
        this.inputValues[this.index] &&
        this.inputValues[this.index].display_image
      )
        this.insert = this.inputValues[this.index].display_image
    }
  },
  created() {
    // if (this.inputValues[this.index] && this.inputValues[this.index].strokes)
    //   this.initialImage = JSON.parse(this.inputValues[this.index].strokes)
    // if (
    //   this.inputValues[this.index] &&
    //   this.inputValues[this.index].display_image
    // )
    //   this.insert = this.inputValues[this.index].display_image
  },
  methods: {
    checkCols() {
      if (this.column4 === true) {
        this.canvasWidth = 250
      } else if (this.column3 === true) {
        this.canvasWidth = 280
      } else if (this.column2 === true) {
        this.canvasWidth = 300
      } else if (
        this.column4 === false &&
        this.column3 === false &&
        this.column2 === false
      ) {
        this.canvasWidth = 600
      }
    },

    handlePanel() {
      this.showPanel = !this.showPanel
    },
    getImageUrl() {
      if (this.idSection && !this.noBg) {
        var vm = this
        axios
          .get(
            '/' +
              localStorage.getItem('clinic') +
              '/medical/' +
              this.pet_id +
              '/getBackgroundSchedeCliniche?idSection=' +
              this.idSection +
              '&index=' +
              this.index
          ) // scarico la lista di documenti
          .then((res) => {
            vm.backgroundImage = res.data
            const img = new Image()
            this.checkCols()
            img.onload = () => {
              let ratio = img.width / img.height
              let newHeight = this.canvasWidth / ratio
              this.canvasHeight = parseInt(newHeight)
              vm.$refs.VueCanvasDrawing.redraw()
            }
            img.src = vm.backgroundImage
          })
      }
    }
  }
}
</script>
