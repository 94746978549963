<template>
  <div>
    <div class="pos intro-y grid grid-cols-12 gap-5">
      <div class="intro-y col-span-12">
        <div class="post intro-y bg-white rounded-b-2xl">
          <div
            class="post__tabs nav nav-tabs flex-col sm:flex-row bg-theme-2 text-theme-42"
            role="tablist"
          >
            <button
              class="w-full sm:w-40 h-10 text-center flex justify-center items-center rounded-tr-xl z-50 bg-theme-40 text-white"
            >
              <i class="fas fa-user w-4 h-4 mr-2" />
              <span class="font-medium text-base">Referenti esterni</span>
            </button>
            <!-- <button
              v-for="(elem, index) in getCustomersDetails"
              id="client-details-tab"
              :key="index"
              class="sm:w-40 h-10 pl-2 -mx-1 text-center text-theme-42 relative flex justify-center items-center rounded-t-lg border border-theme-44 border-opacity-10 hover:font-bold hover:bg-theme-44 focus:bg-theme-44 focus:font-bold focus:z-40"
              :class="
                getShow.isActiveCustomer === elem.id
                  ? 'bg-theme-44 '
                  : 'bg-theme-45'
              "
              @click="getSingleCustomer(elem.id)"
            >
              <i
                class="fas fa-user mx-2"
                :class="getShow.isActiveCustomer === elem.id ? '' : ''"
              />
              <span
                class="sm:w-40 truncate"
                :class="
                  getShow.isActiveCustomer === elem.id ? ' font-bold' : ''
                "
                >{{ elem.first_name ? elem.first_name : '' }}
                {{
                  elem.last_name ? elem.last_name : elem.ragione_sociale
                }}</span
              >
              <i
                class="fa fa-times absolute sm:relative sm:-top-2.5 right-2 sm:right-0 pr-4 pt-2"
                @click.stop="removeTabCustomer(index)"
              />
            </button> -->
          </div>

          <!-- END TAB WITH CLIENT DETAILS -->

          <div
            class="bg-white rounded-b-lg overflow-auto scrollbar_custom anagrafcustomer-cont flex flex-col justify-between"
          >
            <div class="rounded-md px-5 py-3">
              <div class="box mt-1">
                <div id="responsive-table-list">
                  <div class="paginator-container">
                    <div class="prova mr-5 my-2 flex justify-between">
                      <div class="mt-1">
                        <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
                          >Mostra
                        </span>
                        <select
                          id=""
                          v-model="pagination.forPage"
                          name=""
                          class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
                        >
                          <option
                            v-for="show in forPageOption"
                            :key="show"
                            :value="show"
                            class="form-select"
                          >
                            {{ show }}
                          </option>
                        </select>
                        <span class="text-md font-bold ml-2 mt-1">
                          elementi</span
                        >
                      </div>
                    </div>
                    <Paginator
                      :for-page-option="pagination.forPage"
                      :total-records="totalRecords"
                      @changePagination="changePagination"
                    />
                  </div>
                  <div class="scrollbar_custom pb-5">
                    <table
                      class="overflow-hidden border-b border-gray-200 sm:rounded-lg w-full"
                    >
                      <THeader
                        v-for="(elem, index) in config"
                        :key="index"
                        class="t-header"
                        :name="elem.title"
                      />
                      <!-- <Cell
                          class="border-b-2 border-theme-13 font-bold bg-theme-33"
                        /> -->

                      <tbody>
                        <tr>
                          <td
                            v-for="(elem, index) in input"
                            :key="index"
                            class="border-b whitespace-nowrap p-2 py-4"
                          >
                            <select
                              v-if="elem.type === 'select'"
                              class="border rounded-md h-7"
                            >
                              <option value></option>

                              <option
                                v-for="(item, i) in elem.option"
                                :key="i"
                                value
                              >
                                {{ item }}
                              </option>
                            </select>

                            <input
                              v-else
                              v-model="elem.value"
                              :type="elem.type"
                              :name="elem.title"
                              class="block px-2 py-1 border-2 border-gray-200 rounded-lg w-full focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500"
                              :class="{ invisible: elem.type === 'none' }"
                              :placeholder="elem.title"
                              @change="filterHandler(elem)"
                            />
                          </td>
                        </tr>

                        <tr
                          v-for="(item, index) in customers"
                          :key="index"
                          :class="{ 'bg-theme-2': index % 2 === 0 }"
                          class="row-custom hover:bg-theme-45"
                        >
                          <td
                            class="border-b border-l whitespace-nowrap text-left py-3 px-1"
                          >
                            <div class="flex items-center px-2 gap-2">
                              <!-- <div @click="editExternal(item)">
                                <Edit2Icon
                                  class="h-4 w-4 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                                />
                              </div> -->

                              <div class="flex-1">
                                {{
                                  item.first_name
                                    ? item.first_name
                                    : item.ragione_sociale
                                }}
                              </div>
                            </div>
                          </td>

                          <td
                            class="border-b border-l whitespace-nowrap text-left py-3 px-1"
                          >
                            <div class="flex items-center px-2">
                              <div class="flex-1">
                                {{ item.last_name ? item.last_name : '--' }}
                              </div>
                            </div>
                          </td>

                          <td
                            v-if="
                              item.phones &&
                              item.phones.phone1 &&
                              item.phones.phone1.phone
                            "
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{ item.phones.phone1.prefisso }}

                            {{ item.phones.phone1.phone }}
                          </td>

                          <td
                            v-else
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            --
                          </td>

                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{
                              item.emails &&
                              item.emails.email1 &&
                              item.emails.email1.email
                                ? item.emails.email1.email
                                : '--'
                            }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{
                              item.address && item.address.address
                                ? item.address.address
                                : '--'
                            }}

                            {{
                              item.address && item.address.house_number
                                ? item.address.house_number
                                : '--'
                            }}
                          </td>

                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{
                              item.address && item.address.city
                                ? item.address.city
                                : '--'
                            }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{
                              item.address && item.address.zip_code
                                ? item.address.zip_code
                                : '--'
                            }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            <button
                              type="button"
                              @click="handleEdit(item)"
                              class="tooltipbutton hover:text-theme-41 mx-5 text-lg"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                                class="w-3"
                              >
                                <path
                                  d="M362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32zM421.7 220.3L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3z"
                                />
                              </svg>
                              <span
                                class="tooltipbuttontext"
                                style="
                                  width: 90px;
                                  margin-top: -25px;
                                  margin-left: -10px;
                                "
                                >Modifica</span
                              >
                            </button>
                            <button
                              type="button"
                              class="p-3 fas fa-times text-lg"
                              @click="handleDelete(item)"
                            ></button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-end mt-2">
              <button class="orange-btn" @click="toggleNewReferent">
                <PlusIcon class="h-5 w-5 mr-2" /> Aggiungi Referente esterno
              </button>
            </div>
          </div>
          <div v-if="newReferent">
            <NewReferente
              :title="'referente'"
              :external="externalToEdit"
              @close="update"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Paginator from '../../components/paginator/Main.vue'
import THeader from '../../components/cvit-table/THeader.vue'
import NewReferente from '../../views/nuovo-pet/NewReferente.vue'
import axios from '../../gateway/backend-api'
import { mapGetters, mapActions } from 'vuex'
const forPageOption = [10, 50, 100]
export default defineComponent({
  components: {
    THeader,
    NewReferente,
    Paginator
  },
  data() {
    return {
      active_filters: {},
      filterActive: false,
      customersFiltered: null,
      newReferent: false,
      config: [
        {
          key: 'first_name',
          title: 'Nome  / Rag. Soc.'
        },
        {
          key: 'last_name',
          title: 'Cognome'
        },
        {
          key: 'phone',
          title: 'Telefono'
        },
        {
          key: 'email',
          title: 'Mail'
        },
        {
          key: 'adress',
          title: 'Indirizzo'
        },
        {
          key: 'city',
          title: 'Città'
        },
        {
          key: 'zip_code',
          title: 'CAP'
        },
        {
          key: 'action',
          title: 'Azioni Rapide'
        }
      ],
      input: [
        {
          key: 'first_name',
          title: 'Nome',
          type: 'text'
        },
        {
          key: 'last_name',
          title: 'Cognome',
          type: 'text'
        },
        {
          key: 'phone',
          title: 'Telefono',
          type: 'text'
        },
        {
          key: 'email',
          title: 'Mail',
          type: 'text'
        },
        {
          key: 'address',
          title: 'Indirizzo',
          type: 'text'
        },
        {
          key: 'city',
          title: 'Città',
          type: 'text'
        },
        {
          key: 'zip_code',
          title: 'CAP',
          type: 'text'
        },
        {
          key: 'action',
          title: 'Azioni Rapide',
          type: 'none'
        }
      ],
      customers: [],
      totalRecords: '',
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      }
    }
  },
  computed: {
    ...mapGetters({})
  },
  watch: {
    'getShow.isActive': function (elem) {
      if (elem == 0) this.searchAPI()
    }
  },
  mounted() {
    this.searchAPI()
  },
  methods: {
    ...mapActions({}),

    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()
      if (this.filterActive) this.searchAPI()
      else this.searchAPI()
    },
    handleEdit(referent) {
      this.externalToEdit = referent
      this.newReferent = true
    },
    handleDelete(referent) {
      console.log(referent)
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler eliminare questo referente esterno?',
        background: false,
        confirm: () => {
          axios
            .post('/' + localStorage.getItem('clinic') + '/external/delete', {
              external_id: referent.id
            })
            .then(() => {
              this.searchAPI()
            })
        },
        decline: () => {
          console.log('decline')
        }
      })
    },
    searchAPI() {
      this.customers = []
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/external/index', {
          type: 'referent',
          per_page: this.pagination.forPage,
          page: this.pagination.page,
          active_filters: this.active_filters
        })
        .then((res) => {
          this.customers = res.data.data
          this.totalRecords = res.data.total
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    getExternalsApiList: function () {
      this.customers = []

      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/external?type=referent&per_page=' +
            this.pagination.forPage +
            '&page=' +
            this.pagination.page
        )
        .then((res) => {
          this.customers = res.data.data
          this.totalRecords = res.data.total
        })
    },
    // funzione che da l'input al componente paginatore per cambiare pagina
    changePagination: function (value) {
      this.pagination.page = value.page
      this.pagination.forPage = value.forPage
      this.searchAPI()
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    },
    toggleNewReferent() {
      this.newReferent = !this.newReferent
    },
    editExternal(external) {
      this.externalToEdit = external
      this.toggleNewReferent()
    },
    update() {
      this.externalToEdit = null
      this.newReferent = false
      this.searchAPI()
    }
  }

  // function that changes dynamicly the z-ind of the clients tab
})
</script>
