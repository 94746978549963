<template>
  <div
    class="bg-white rounded-md content-container overflow-auto scrollbar_custom px-3"
  >
    <!-- START MODAL NEW APPOINTMENTS -->
    <div v-if="openModalAppointment">
      <NewAppointment
        :appointment-data="newEvent"
        :footer-modal-appointment="false"
        modal-appointment-title="Nuovo appuntamento"
        :pet="selectedPet"
        :preview="false"
        @close="toggleModal"
        @openConfirmModal="toggleConfirm"
        @addAppointment="toggleModal"
        @render="update"
      />
    </div>
    <!-- END MODAL NEW APPOINTMENTS -->

    <div class="gap-1 flex items-center justify-end mt-4">
      <!-- Appuntamento rapido -->
      <FullCalendarDraggable
        v-if="
          permissions &&
          permissions.agenda &&
          permissions.agenda.store_agenda &&
          permissions.agenda.store_agenda.enabled === true
        "
        id="calendar-events"
        :options="dragableQuickOptions"
      >
        <div class="relative z-20 sm:flex-row">
          <div
            class="event cursor-pointer transition duration-900 ease-in-out zoom-in"
          >
            <button
              v-show="!isActiveFilter"
              id="new-appointment-modal-button"
              type="button"
              class="orange-btn-inverted flex"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 217.59 109.98"
                class="w-9 md:mr-2 fill-current"
              >
                <g id="Layer_2" data-name="Layer 2">
                  <g id="Layer_1-2" data-name="Layer 1">
                    <rect y="29.56" width="94.24" height="13.7" rx="6.85" />
                    <rect
                      x="12.43"
                      y="56.72"
                      width="79.18"
                      height="13.7"
                      rx="6.85"
                    />
                    <rect
                      x="33.56"
                      y="83.88"
                      width="58.05"
                      height="13.7"
                      rx="6.85"
                    />
                    <path
                      class="cls-1"
                      d="M199.06 90.57a3.92 3.92 0 0 1-3.9 3.9h-9.08a3.88 3.88 0 0 1-3.85-3.9 3.83 3.83 0 0 1 3.85-3.85h9.08a3.87 3.87 0 0 1 3.9 3.85Zm-30.41 0a3.92 3.92 0 0 1-3.9 3.9h-9.07a3.87 3.87 0 0 1-3.85-3.9 3.82 3.82 0 0 1 3.85-3.85h9.07a3.87 3.87 0 0 1 3.9 3.85Zm-30.41 0a3.92 3.92 0 0 1-3.9 3.9h-9.07a3.91 3.91 0 0 1-3.85-3.9 3.86 3.86 0 0 1 3.85-3.85h9.07a3.87 3.87 0 0 1 3.9 3.85Zm0-16.39a3.92 3.92 0 0 1-3.9 3.9h-9.07a3.91 3.91 0 0 1-3.85-3.9 3.86 3.86 0 0 1 3.85-3.85h9.07a3.88 3.88 0 0 1 3.9 3.85Zm30.41 0a3.92 3.92 0 0 1-3.9 3.9h-9.07a3.87 3.87 0 0 1-3.85-3.9 3.83 3.83 0 0 1 3.85-3.85h9.07a3.88 3.88 0 0 1 3.9 3.85Zm30.41 0a3.92 3.92 0 0 1-3.9 3.9h-9.08a3.88 3.88 0 0 1-3.85-3.9 3.83 3.83 0 0 1 3.85-3.85h9.08a3.88 3.88 0 0 1 3.9 3.85Zm0-16.39a3.92 3.92 0 0 1-3.9 3.91h-9.08a3.88 3.88 0 0 1-3.85-3.91 3.83 3.83 0 0 1 3.85-3.85h9.08a3.88 3.88 0 0 1 3.9 3.85Zm-30.41 0a3.92 3.92 0 0 1-3.9 3.91h-9.07a3.87 3.87 0 0 1-3.85-3.91 3.83 3.83 0 0 1 3.85-3.85h9.07a3.88 3.88 0 0 1 3.9 3.85Zm-30.41 0a3.92 3.92 0 0 1-3.9 3.91h-9.07a3.91 3.91 0 0 1-3.85-3.91 3.86 3.86 0 0 1 3.85-3.85h9.07a3.88 3.88 0 0 1 3.9 3.85Z"
                    />
                    <path
                      class="cls-1"
                      d="M204.45 6.93h-7a9 9 0 0 0-17.54 0h-40.7a9 9 0 0 0-17.54 0h-7a13.14 13.14 0 0 0-13.14 13.14v76.77A13.16 13.16 0 0 0 114.71 110h89.74a11.32 11.32 0 0 0 1.43-.06 13.71 13.71 0 0 0 7-3 13.25 13.25 0 0 0 4.73-10.11V20.07a13.14 13.14 0 0 0-13.16-13.14Zm-20.13 2.14A4.41 4.41 0 0 1 185.61 6a4.49 4.49 0 0 1 6.17 0 4.45 4.45 0 0 1 1.29 3.12v9.08a4.38 4.38 0 1 1-8.75 0Zm-58.23 0A4.44 4.44 0 0 1 127.37 6a4.48 4.48 0 0 1 6.18 0 4.4 4.4 0 0 1 1.28 3.11v9.08a4.37 4.37 0 1 1-8.74 0Zm85.45 87.77a7 7 0 0 1-6.15 7 5.22 5.22 0 0 1-.94.06h-89.74a7 7 0 0 1-4.84-1.93 7.15 7.15 0 0 1-2.25-5.16V43.55h103.92Z"
                    />
                  </g>
                </g>
              </svg>

              <!-- <ClockIcon class="w-4 h-4 mr-2" />Appuntamento Rapido -->
              <h2 class="event__title hidden mr-0 md:inline-block">
                Appuntamento Rapido
              </h2>
            </button>
            <span class="event__days hidden">1</span>
          </div>
        </div>
      </FullCalendarDraggable>

      <!-- DRAGGABLE BUTTON APPOINTMENT START-->

      <!-- Make button draggable -->
      <FullCalendarDraggable
        v-if="
          permissions &&
          permissions.agenda &&
          permissions.agenda.store_agenda &&
          permissions.agenda.store_agenda.enabled === true
        "
        id="new-appointment"
        :options="dragableOptions"
      >
        <div class="relative z-20 sm:flex-row">
          <div
            class="event stroke-2.5 cursor-pointer transition duration-900 ease-in-out"
          >
            <button
              v-show="!isActiveFilter"
              id="new-appointment-modal-button"
              type="button"
              class="orange-btn-inverted flex"
              @click="toggleModal"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 117.85 109.04"
                class="w-5 md:mr-2 fill-current"
              >
                <g data-name="Layer 2">
                  <g data-name="Layer 1">
                    <path
                      d="M85.21 22.06a4.31 4.31 0 0 0 4.3-4.31V8.88a4.35 4.35 0 0 0-1.26-3.06 4.4 4.4 0 0 0-6 0A4.31 4.31 0 0 0 81 8.88v8.87a4.28 4.28 0 0 0 4.21 4.31Zm-56.92 0a4.28 4.28 0 0 0 4.25-4.31V8.88a4.31 4.31 0 0 0-1.25-3.05 4.38 4.38 0 0 0-6 0A4.3 4.3 0 0 0 24 8.88v8.87a4.31 4.31 0 0 0 4.29 4.31Z"
                      style="fill: none"
                    />
                    <path
                      d="M61.81 84.83h-8.88a3.74 3.74 0 0 0-3.76 3.77 3.79 3.79 0 0 0 3.76 3.82h8.88a3.84 3.84 0 0 0 3.82-3.82 3.79 3.79 0 0 0-3.82-3.77Zm-29.75 0h-8.88a3.78 3.78 0 0 0-3.76 3.77 3.83 3.83 0 0 0 3.76 3.82h8.88a3.84 3.84 0 0 0 3.82-3.82 3.79 3.79 0 0 0-3.82-3.77Zm0-16.03h-8.88a3.78 3.78 0 0 0-3.76 3.77 3.83 3.83 0 0 0 3.76 3.82h8.88a3.84 3.84 0 0 0 3.82-3.82 3.79 3.79 0 0 0-3.82-3.77Zm29.75 0h-8.88a3.74 3.74 0 0 0-3.76 3.77 3.79 3.79 0 0 0 3.76 3.82h8.88a3.84 3.84 0 0 0 3.82-3.82 3.79 3.79 0 0 0-3.82-3.77Zm20.87-8.44h8.87a3.84 3.84 0 0 0 3.82-3.82 3.79 3.79 0 0 0-3.82-3.77h-8.87a3.75 3.75 0 0 0-3.77 3.77 3.8 3.8 0 0 0 3.77 3.82Zm-20.87-7.59h-8.88a3.74 3.74 0 0 0-3.76 3.77 3.79 3.79 0 0 0 3.76 3.82h8.88a3.84 3.84 0 0 0 3.82-3.82 3.79 3.79 0 0 0-3.82-3.77Zm-29.75 0h-8.88a3.78 3.78 0 0 0-3.76 3.77 3.83 3.83 0 0 0 3.76 3.82h8.88a3.84 3.84 0 0 0 3.82-3.82 3.79 3.79 0 0 0-3.82-3.77Z"
                    />
                    <path
                      d="M12.86 101.67a6.84 6.84 0 0 1-4.74-1.88 7 7 0 0 1-2.2-5.06V42.6h101.66v20.92a27.13 27.13 0 0 1 5.92 3.41v-47.3a12.85 12.85 0 0 0-12.85-12.85h-6.84a8.82 8.82 0 0 0-17.16 0h-39.8a8.82 8.82 0 0 0-17.16 0h-6.83A12.85 12.85 0 0 0 0 19.63v75.1a12.93 12.93 0 0 0 6.83 11.35 12.8 12.8 0 0 0 6 1.5H77.6a27.13 27.13 0 0 1-4.38-5.91ZM81 8.88a4.31 4.31 0 0 1 1.25-3.05 4.4 4.4 0 0 1 6 0 4.35 4.35 0 0 1 1.26 3.06v8.87a4.28 4.28 0 1 1-8.55 0Zm-57 0a4.3 4.3 0 0 1 1.26-3.05 4.38 4.38 0 0 1 6 0 4.31 4.31 0 0 1 1.25 3.05v8.87a4.28 4.28 0 1 1-8.55 0Z"
                    />
                    <path
                      d="M117.81 88a4.8 4.8 0 0 0-4.31-4.3 10 10 0 0 0-1.5-.15h-9.91v-9.37c0-3.65-1.88-5.88-4.85-6a4.48 4.48 0 0 0-3.43 1.31 6.76 6.76 0 0 0-1.7 4.72v9.31c-3.56 0-6.76-.08-9.95 0-3.82.1-6.2 2.51-5.75 5.66.4 2.76 2.42 4.37 5.84 4.45 3.18.07 6.37 0 9.85 0v8c.14 2.86.6 4.76 1.52 5.91A4.13 4.13 0 0 0 97 109a4 4 0 0 0 3.51-1.46c.92-1.18 1.4-3.15 1.52-6.06v-7.79h10a8.78 8.78 0 0 0 1.41-.17c3.05-.6 4.7-2.66 4.37-5.52Z"
                    />
                  </g>
                </g>
              </svg>

              <span class="event__days hidden">1</span>
              <h2 class="event__title hidden md:inline-block">
                {{ newAppointment }}
              </h2>
            </button>
          </div>
        </div>
      </FullCalendarDraggable>

      <button
        v-if="
          !isActiveFilter &&
          permissions &&
          permissions.agenda &&
          permissions.agenda.view_agenda &&
          permissions.agenda.view_agenda.enabled === true
        "
        class="orange-btn-inverted"
        @click="isActiveFilter = !isActiveFilter"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          class="h-5 fill-current"
        >
          <path
            d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"
          />
        </svg>
      </button>

      <!-- DRAGGABLE BUTTON APPOINTMENT END-->
      <!-- Button for printing the calendar -->
      <button id="print_btn" v-print="printObj" class="hidden"></button>

      <button
        v-if="
          permissions &&
          permissions.agenda &&
          permissions.agenda.view_agenda &&
          permissions.agenda.view_agenda.enabled === true
        "
        v-show="!isActiveFilter"
        type="button"
        class="orange-btn-inverted"
        @click="handlePrint"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          class="h-5 fill-current"
        >
          <path
            d="M128 0C92.7 0 64 28.7 64 64v96h64V64H354.7L384 93.3V160h64V93.3c0-17-6.7-33.3-18.7-45.3L400 18.7C388 6.7 371.7 0 354.7 0H128zM384 352v32 64H128V384 368 352H384zm64 32h32c17.7 0 32-14.3 32-32V256c0-35.3-28.7-64-64-64H64c-35.3 0-64 28.7-64 64v96c0 17.7 14.3 32 32 32H64v64c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V384zm-16-88c-13.3 0-24-10.7-24-24s10.7-24 24-24s24 10.7 24 24s-10.7 24-24 24z"
          />
        </svg>
      </button>
    </div>

    <!-- BEGIN: Filters-->

    <div
      v-show="isActiveFilter"
      class="relative flex flex-col gap-1 md:gap-3 md:flex-row items-start rounded-md shadow-md border border-theme-44 border-opacity-20 p-2"
    >
      <!-- BEGIN: Multiple Select for rooms  -->
      <button
        type="button"
        class="closing-btn mr-1 top-1"
        @click="isActiveFilter = false"
      >
        <svg
          class="w-4 h-4"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>

      <div class="w-full p-1">
        <h2 class="form-label">Seleziona Sala</h2>
        <div id="multiple-select-room">
          <v-select
            v-model="filter_rooms"
            multiple
            :options="rooms"
            label="name"
            placeholder="Seleziona sala"
            class="uppercase text-xs"
          >
            <template #option="option">
              {{ option.name }}
            </template>
          </v-select>
        </div>
      </div>
      <!-- END: Multiple Select for rooms -->

      <!-- BEGIN: Multiple Select for doctors -->
      <div class="w-full p-1">
        <h2 class="form-label">Seleziona veterinario</h2>
        <div id="multiple-select-vet">
          <v-select
            v-model="filter_vets"
            multiple
            :options="vets"
            label="name"
            placeholder="Seleziona veterinario"
            class="uppercase text-xs"
          >
          </v-select>
        </div>
      </div>

      <div class="w-full p-1">
        <h2 class="form-label">Seleziona Cliente</h2>
        <div id="multiple-select-room">
          <v-select
            v-model="filter_clients"
            multiple
            :options="clients"
            :loading="loading_clients"
            label="name"
            placeholder="Seleziona cliente"
            class="uppercase text-xs"
          >
            <template #option="option">
              {{ option.name }}
            </template>
          </v-select>
        </div>
      </div>
      <!-- END: Multiple Select for rooms -->

      <!-- BEGIN: Multiple Select for doctors -->
      <div class="w-full p-1">
        <h2 class="form-label">Seleziona Paziente</h2>
        <div id="multiple-select-vet">
          <v-select
            v-model="filter_pets"
            multiple
            :options="pets"
            :loading="loading_pets"
            label="name"
            placeholder="Seleziona paziente"
            class="uppercase text-xs"
          >
          </v-select>
        </div>
      </div>

      <!-- END: Multiple Select for doctors -->
      <!-- END: Filters-->
    </div>

    <!-- AGENDA START -->
    <div class="mx-2 mb-4 z-40">
      <div
        v-if="
          permissions &&
          permissions.agenda &&
          permissions.agenda.view_agenda &&
          permissions.agenda.view_agenda.enabled === true
        "
        class=""
      >
        <h2 v-if="isPrinting" class="bg-red-500 text-center text-xl">
          PRINTING STARTING
        </h2>
        <Agenda
          id="calendar_print"
          ref="agendaRef"
          :key="componentKey"
          :new-appointment="newAppointment"
          :filter-vets="filter_vets"
          :filter-rooms="filter_rooms"
          :view-calendar="viewCalendar"
          :clients="filter_clients"
          :pets="filter_pets"
          @changeViewFormat="saveFormatView"
          @setClientsPets="setClientsPets"
        />
      </div>

      <div v-else>
        <h1>I permessi per visualizzare l'agenda non sono abilitati</h1>
      </div>
    </div>

    <!--AGENDA END -->

    <!-- START MODAL CONFIRM DELETE -->
    <!-- <div v-if="openConfirmDelete">
        <ConfirmDelete :id="1" @closeconfirm="toggleConfirm" />
      </div> -->
    <!-- END MODAL CONFIRM DELETE -->
  </div>
</template>
<script>
import axios from '../../gateway/backend-api'
import Agenda from '../../global-components/agenda/Main.vue'
import NewAppointment from './NewAppointment.vue'
import { mapGetters } from 'vuex'
import print from 'vue3-print-nb'
import { ref } from 'vue'
import { useStore } from '@/store'

/*import { mapGetters } from 'vuex'
import ConfirmDelete from './ConfirmDelete.vue' */

// Title button New Appointment control
const newAppointment = 'Nuovo Appuntamento'

// set the bgcolor of events
const setupColor = (eventType) => {
  switch (eventType) {
    case 'Appuntamento Rapido':
      return '#f78b00'
    case newAppointment:
      return '#c3e07f'
    case 'Ricovero urgente':
      return '#d32929'
    case 'Appuntamento con fornitore':
      return '#c3e07f'
    case 'Riunione interna':
      return '#fbc500'
  }
}

export default {
  directives: {
    print
  },
  components: {
    Agenda,
    NewAppointment /*
    ConfirmDelete */
  },
  props: {
    selectedPet: Object
  },
  setup() {
    const agendaRef = ref(null)
    const store = useStore()

    var counterID = 2
    const dragableQuickOptions = {
      itemSelector: '.event',
      eventData(eventEl) {
        counterID = counterID + 1
        return {
          title: cash(eventEl).find('.event__title').html(),
          id: counterID,
          allDay: false,
          duration: {
            days: 0,
            hours: 1
          },
          backgroundColor: setupColor(
            cash(eventEl).find('.event__title').html()
          ),
          borderColor: setupColor(cash(eventEl).find('.event__title').html()),
          textColor: 'white'
        }
      }
    }
    const dragableOptions = {
      itemSelector: '.event',
      eventData(eventEl) {
        counterID = counterID + 1
        return {
          title: cash(eventEl).find('.event__title').html(),
          id: counterID,
          allDay: false,
          duration: {
            days: 0,
            hours: 1
          },
          backgroundColor: setupColor(
            cash(eventEl).find('.event__title').html()
          ),
          borderColor: setupColor(cash(eventEl).find('.event__title').html()),
          textColor: 'white'
        }
      }
    }

    const loading = (enabled) => {
      if (enabled) store.dispatch('commonComponents/showLoadingModalAction')
      else store.dispatch('commonComponents/hideLoadingModalAction')
    }

    return {
      dragableQuickOptions,
      dragableOptions,
      agendaRef,
      loading
    }
  },

  data() {
    return {
      isPrinting: false,
      componentKey: 0,
      newEvent: [],
      openConfirmDelete: false,
      isActiveFilter: false,
      openModalAppointment: false,
      newAppointment: newAppointment,
      rooms: [],
      vets: [],
      clients: [],
      pets: [],
      filter_vets: [],
      filter_rooms: [],
      filter_clients: [],
      filter_pets: [],
      viewCalendar: 'timeGridDay',
      loading_clients: false,
      loading_pets: false,
      searchTicket: 0,
      printObj: {
        id: 'print',
        popTitle: ' ',

        openCallback(vue) {
          vue.loading(false)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions'
    })
  },
  watch: {},
  mounted() {},
  methods: {
    handlePrint() {
      this.loading(true)
      setTimeout(() => {
        const button2 = document.getElementById('print_btn')
        if (button2) {
          button2.click()
        }
      }, 200)
    },

    setClientsPets(clients, pets, vets, rooms) {
      this.clients = clients
      this.pets = pets
      this.vets = vets
      this.rooms = rooms
    },
    searchClient(text) {
      this.loading_clients = true
      var send_data = {
        searchText: text,
        filterParameter: ['customer'],
        ticket: ++this.searchTicket,
        pet_id: null,
        customer_id: null,
        per_page_customer: 100,
        page_customer: 1,
        per_page_pet: 100,
        page_pet: 1
      }
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/search/globalSearch',
          send_data
        )
        .then((res) => {
          if (res.data.ticket == this.searchTicket) {
            this.clients = res.data?.customers?.data?.items
          }
          this.loading_clients = false
        })
        .catch(() => {
          this.loading_clients = false
        })
    },
    saveFormatView(e) {
      this.viewCalendar = e
    },
    dropQuickEvent() {},
    update() {
      this.forceRerender()
    },
    forceRerender() {
      this.componentKey += 1
    },
    toggleModal() {
      this.openModalAppointment = !this.openModalAppointment
      this.newEvent = []
    }
  }
}
</script>

<style>
/* .fc-event-main {
  height: 2rem !important;
} */

.fc-event-resizer {
  display: none !important;
}

.fc-event-main-frame {
  flex-direction: row !important;
}
</style>
