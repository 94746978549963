<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-44 p-2 mx-auto overflow-scroll scrollbar-none border sm:w-3/5 w-full shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="flex flex-col content-end overflow-y-auto">
        <!-- Modal header -->
        <div class="flex items-start p-5 rounded-t">
          <h3
            class="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white"
          >
            {{ title }}
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="$emit('close')"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div class="flex flex-col p-6 space-y-6">
          <div>
            <p>Filtri applicati:</p>
            <ul class="list-disc">
              <li
                v-for="(filter, index) in filtersPretty"
                :key="index"
                class="ml-8"
              >
                {{ filter.name }} : {{ filter.value }}
              </li>
            </ul>
          </div>
          <p>Oggetto:</p>
          <input
            v-model="object"
            type="text"
            class="mt-1 shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
          />
          <p>Inserire messaggio per l'invio:</p>
          <textarea
            v-model="message"
            class="mt-1 shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
            rows="6"
          />
          <p>(La mail verrà inviata a {{ total }} pazienti )</p>
        </div>
        <!-- Modal footer -->
        <div
          class="border-t-1 border-gray-400 w-full px-6 text-right flex justify-end items-end mb-2"
        >
          <div class="mt-2 flex items-center justify-end pr-4 gap-3">
            <button
              id="dialogbox-delete-button"
              type="button"
              class="orange-btn"
              @click="handleResponse"
            >
              <SaveIcon class="w-5 h-5 mr-2 justify-center" />
              Invia
            </button>
          </div>
        </div>
        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import myAxios from '../../gateway/backend-api'
import axios from 'axios'
import '@swoga/vue-collapsible-panel/dist/vue-collapsible-panel.css'
import { useStore } from 'vuex'

export default defineComponent({
  props: ['title', 'filters', 'total', 'completeFilters'],
  emits: ['close'],
  setup(props, { emit }) {
    const store = useStore()
    // ----- DATA -----
    const message = ref('')
    const object = ref('')

    // ----- METHODS -----
    const handleResponse = () => {
      console.log(message)
      if (!message.value || message.value === '') {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire il testo'
        })
        return
      }
      if (!object.value || object.value === '') {
        store.dispatch('commonComponents/openNotificationModal', {
          message: "Si prega di inserire l'oggetto del messaggio"
        })
        return
      }
      store.dispatch('commonComponents/showLoadingModalAction')
      myAxios
        .post(
          '/' + localStorage.getItem('clinic') + '/mailersend/massive_send',
          { filters: props.filters, body: message.value, object: object.value }
        )
        .then(() => {
          // creo un oggetto axios diverso dal classico interceptor perchè la base route è senza api
          const myApi = axios.create({
            crossDomain: true,
            mode: 'no-cors',
            // withCredentials: true,
            // credentials: 'same-origin',
            baseURL: 'https://backend.vetsgo.it',
            timeout: 60000,
            headers: {
              'Access-Control-Allow-Origin': '*'
            }
          })
          // chiamo la functino che esegue la job queue
          myApi
            .post('/functions/queue-launcher-script', {
              token: '1|ztsyfsLfWpyh2BT4Bwc2ZRYd5VtAsMTQgAn26KKC',
              type: 'massive-emails'
            })
            .then(() => {
              store.dispatch('commonComponents/hideLoadingModalAction')
              store.dispatch('commonComponents/openNotificationModal', {
                message: 'Mail inviate!'
              })
              emit('close')
            })
            .catch(() => {
              store.dispatch('commonComponents/hideLoadingModalAction')
              store.dispatch('commonComponents/openNotificationModal', {
                message: 'Errore'
              })
            })
        })
        .catch(() => {
          store.dispatch('commonComponents/hideLoadingModalAction')
          store.dispatch('commonComponents/openNotificationModal', {
            message: 'Errore'
          })
        })
    }

    // ----- COMPUTED -----

    // faccio un merge tra il template degli input e i valori di input per ottenere la label da mostrare
    const filtersPretty = computed(() => {
      const res = []
      props.completeFilters.forEach((el) => {
        switch (el.key) {
          case 'species':
            if (props.filters['pet_type_id']) {
              el.option.forEach((e) => {
                if (e.id === props.filters['pet_type_id'])
                  res.push({ name: el.title, value: e.name })
              })
            }
            break
          case 'breed':
            if (props.filters['pet_breed_id']) {
              el.option.forEach((e) => {
                if (e.id === props.filters['pet_breed_id'])
                  res.push({ name: el.title, value: e.name })
              })
            }
            break
          default:
            if (props.filters[el.key])
              res.push({ name: el.title, value: props.filters[el.key] })
            break
        }
      })
      return res
    })

    return { handleResponse, message, object, filtersPretty }
  }
})
</script>
