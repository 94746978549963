<!--Table Header global component -->
<template>
  <th
    scope="col"
    class="px-6 py-3 text-center text-xs uppercase tracking-wider font-bold"
  >
    {{ name }}
  </th>
</template>
<script>
/* eslint-disable eol-last */
export default {
  props: {
    name: Array
  }
}
</script>
