<template>
  <div class="login w-screen">
    <div class="h-screen flex items-center mx-24">
      <div
        class="flex-1 grid grid-cols-10 gap-5 lg:gap-1 items-center -mt-10 place-content-center justify-center"
      >
        <!-- BEGIN: Login Info -->
        <div
          class="col-span-10 lg:col-span-6 sm:mx-20 mb-8 lg:mx-16 -mt-20 lg:-mt-10"
        >
          <img
            class="login_logo"
            alt="logo-vetsgo"
            src="@/assets/images/logo-blue.svg"
          />
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <div class="col-span-10 lg:col-span-4 mx-3 md:mx-10 flex flex-col">
          <h2
            class="nunito text-theme-40 intro-x text-xl lg:text-2xl text-center xl:text-3xl"
          >
            Password dimenticata?
          </h2>
          <div
            class="intro-x w-full mt-8 flex flex-col gap-5 place-content-center"
          >
            <input type="hidden" name="_token" :value="csrf" />
            <input
              type="text"
              class="custom-input h-10 rounded-full w-full md:w-3/5 lg:w-full mx-auto"
              name="username"
              id="username"
              v-model="username"
              placeholder="Email"
            />
          </div>
          <div class="intro-x mt-5 xl:mt-8 text-center xl:text-center">
            <button
              class="nunito rounded-full bg-theme-41 text-white text-base px-3 font-bold py-2 mt-2 w-48"
              @click="recoverPassword()"
            >
              Recupera password
            </button>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
/* import router from '@/router'
import axios from '../../gateway/backend-api' */

export default defineComponent({
  components: {},
  data() {
    return {
      csrf: document.head.querySelector('meta[name="csrf-token"]')
        ? document.head.querySelector('meta[name="csrf-token"]').content
        : '',
      username: '',
      password: ''
    }
  },
  setup() {
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login_body')
    })
  },
  methods: {
    recoverPassword: function () {
      if (this.username == '') {
        console.log('Email missing')
      } else {
        this.$store.dispatch('recoverPassword', {
          email: this.username
        })
      }
    }
  }
})
</script>
