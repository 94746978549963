<template>
  <div class="overflow-hidden">
    <NewAnnotationModal
      v-if="showAnnotationModal && annotationData"
      :type="annotationData.type"
      :annotation="annotationData"
      :modify="annotationData.to_modify"
      @close="annotationData.close"
      @addNote="annotationData.addNote"
      @modifyNote="annotationData.modifyNote"
    />
    <TableModal
      v-if="showTableModal && tableData"
      :title="tableData.title"
      :header="tableData.header"
      :data="tableData.data"
      :append="tableData.append"
      @close="tableData.close"
    />
    <BlockModal v-if="showModalBlock" />
    <NewVaccineModal
      v-if="showVaccineModal && vaccineData"
      :pet="vaccineData.pet"
      :client="vaccineData.client"
      :elem="vaccineData.elem"
      :to-modify="vaccineData.to_modify"
      @close="vaccineData.close"
      @confirm="vaccineData.confirm"
    />
    <WeightModal
      v-if="showWeightModal && weightData"
      @close="weightData.close"
      @save="weightData.confirm"
    />

    <SimpleMessageModal
      v-if="showMessageModal"
      :title="messageTitle"
      :subtitle="messageSubtitle"
      :backgroud="messageModalBackground"
      @close="store.dispatch('commonComponents/closeMessageModal')"
    />

    <TemplateModal
      v-if="showTemplateModal"
      :data="templateData"
      @close="store.dispatch('commonComponents/closeTemplateModal')"
      @confirm="
        (text) => {
          store.dispatch('commonComponents/templateModalConfirm', text)
        }
      "
    />

    <PdfModal
      v-if="showPdfModal"
      :title="pdfTitle"
      :link="pdfLink"
      :xml="xml"
      :table_reference="pdfTableReference"
      :id_reference="pdfIdReference"
      :client="pdfClient"
      :signed="pdfSigned"
      :pdf_date="pdfDate"
      :confirmations="pdfConfirmations"
      @close="store.dispatch('commonComponents/closePdfModal')"
      @print="store.dispatch('commonComponents/pdfModalPrint')"
      @download="store.dispatch('commonComponents/pdfModalDownload')"
      @sign_done="store.dispatch('commonComponents/signDoneMethod')"
    />
    <LoadingIconModal v-if="showLoadingModal" />
    <SendMessageModal
      v-if="showSendMessageModal"
      :elem="selectedElem"
      :data="sendMessageData"
      @close="store.dispatch('commonComponents/closeSendMessageModal')"
      @confirm="
        (data) => store.dispatch('commonComponents/messageSendConfirm', data)
      "
    />
    <SendSMSModal
      v-if="showSendSmsEmailModal"
      :elem="selectedElem"
      :type="smsEmailModalType"
      @close="store.dispatch('commonComponents/closeSendSMSModal')"
      @confirm="confirmMessage"
    />

    <SimpleConfirmModal
      v-if="showConfirmModal"
      :title="confirmTitle"
      :subtitle="confirmSubtitle"
      :backgroud="messageModalBackground"
      :confirm-text="confirmText"
      :decline-text="declineText"
      @close="store.dispatch('commonComponents/closeConfirmModal')"
      @confirm="store.dispatch('commonComponents/messageModalConfirm')"
      @decline="store.dispatch('commonComponents/messageModalDecline')"
    />
    <ChangePasswordModal
      v-if="showPasswordModal"
      :show-password="passwordModalPassword"
      :show-consensi="passwordModalConsensi"
      :show-licenza-uso="passwordModalLicenza"
    />
    <SignatureModal
      v-if="showSignatureModal"
      @close="store.dispatch('commonComponents/closeSignatureModal')"
      @confirm="
        (data) => store.dispatch('commonComponents/signatureModalConfirm', data)
      "
      @decline="store.dispatch('commonComponents/signatureModalDecline')"
    />
    <!-- <DarkModeSwitcher /> -->
    <MobileMenu />

    <div class="flex items-center mt-0 md:mt-5">
      <div class="sidebar-wider">
        hola
        <img
          src="@/assets/images/newLogoWhite.png"
          alt="logo-vanadium"
          class="w-auto hidden xl:block p-2 cursor-pointer"
          @click="goToDash"
        />

        <img
          src="@/assets/images/isologo.png"
          alt="isologo-vanadium"
          class="w-auto block xl:hidden p-2 cursor-pointer"
          @click="goToDash"
        />
      </div>
      <div class="flex-1">
        <TopBar />
      </div>

      <!-- <div
        id="mob-bar"
        class="flex-1 scrollbar-none z-30 relative"
        :class="[hide_nav ? '' : '']"
      >
        <div
          v-if="hide_nav"
          class="bg-theme-40 pl-3 rounded-tr-3xl hidden sm:inline-block absolute left-0 md:top-0 z-20"
          style="height: 75px; width: 110px"
        >
          <img
            id="iso-logo"
            src="@/assets/images/isologo.png"
            class="pt-4"
            style="width: 75px"
            alt
          />
        </div>
      </div> -->
    </div>

    <div class="fixed-content md:h-screen md:pb-24 sm:mr-0 md:mx-0">
      <!-- BEGIN: Side Menu -->
      <nav class="pb-10 flex flex-col justify-between" style="height: 96%">
        <!-- BEGIN: Logo -->

        <div
          id="side-bar"
          class="side-nav flex-1 hidden md:block overflow-auto scrollbar_sidebar direction_rtl mb-6 scroll-smooth"
          :class="[slide_nav ? 'sidebar-narrow' : 'sidebar-wider']"
          :style="[hide_nav ? 'display:none' : '']"
        >
          <router-link
            :to="{ name: 'side-menu-dashboard' }"
            tag="a"
            class="intro-x flex items-center"
          ></router-link>
          <!-- END: Logo -->

          <ul class="pl-3 direction_ltr">
            <!-- BEGIN: First Child -->
            <!-- <template v-for="(menu, menuKey) in formattedMenu"> -->
            <!-- <li
              v-if="menu == 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
          ></li>-->
            <li v-for="(menu, menuKey) in formattedMenu" :key="menu + menuKey">
              <SideMenuTooltip
                tag="a"
                :content="menu.title"
                :href="
                  menu.subMenu
                    ? 'javascript:;'
                    : router.resolve({ name: menu.pageName }).path
                "
                class="side-menu text-white hover:text-theme-41 hover:font-semibold"
                :class="{
                  'side-menu--active': menu.active,
                  'side-menu--open': menu.activeDropdown
                }"
                @click="linkTo(menu, router, $event), getCrumbs(menu)"
              >
                <div class="side-menu__icon">
                  <i :class="menu.icon" class="w-6 text-xl"></i>
                </div>
                <div
                  id="side-nav_title"
                  :class="[
                    slide_nav ? 'side-menu__title_hide ' : 'side-menu__title'
                  ]"
                  class=""
                >
                  {{ menu.title }}
                  <div
                    v-if="menu.subMenu"
                    class="side-menu__sub-icon"
                    :class="{ 'transform rotate-180': menu.activeDropdown }"
                  >
                    <ChevronDownIcon />
                  </div>
                </div>
              </SideMenuTooltip>
              <!-- BEGIN: Second Child -->
              <transition @enter="enter" @leave="leave">
                <ul v-if="menu.subMenu && menu.activeDropdown">
                  <li
                    v-for="(subMenu, subMenuKey) in menu.subMenu"
                    :key="subMenuKey"
                  >
                    <SideMenuTooltip
                      tag="a"
                      :content="subMenu.title"
                      :href="
                        subMenu.subMenu
                          ? 'javascript:;'
                          : router.resolve({ name: subMenu.pageName }).path
                      "
                      class="side-menu hover:text-theme-41 hover:font-semibold"
                      :class="[
                        subMenu.active
                          ? 'text-theme-41 font-semibold'
                          : 'text-white'
                      ]"
                      @click="
                        linkTo(subMenu, router, $event), getCrumbs(subMenu)
                      "
                    >
                      <div class="side-menu__icon">
                        <!-- <component :is="subMenu.icon" /> -->
                        <i :class="subMenu.icon" class="w-6 text-xl"></i>
                      </div>
                      <div
                        class="side-menu__title"
                        :class="[
                          slide_nav
                            ? 'side-menu__title_hide '
                            : 'side-menu__title'
                        ]"
                      >
                        {{ subMenu.title }}
                        <div
                          v-if="subMenu.subMenu"
                          class="side-menu__sub-icon"
                          :class="{
                            'transform rotate-180': subMenu.activeDropdown
                          }"
                        >
                          <ChevronDownIcon />
                        </div>
                      </div>
                    </SideMenuTooltip>
                    <!-- BEGIN: Third Child -->
                    <transition @enter="enter" @leave="leave">
                      <ul v-if="subMenu.subMenu && subMenu.activeDropdown">
                        <li
                          v-for="(
                            lastSubMenu, lastSubMenuKey
                          ) in subMenu.subMenu"
                          :key="lastSubMenuKey"
                        >
                          <SideMenuTooltip
                            tag="a"
                            :content="lastSubMenu.title"
                            :href="
                              lastSubMenu.subMenu
                                ? 'javascript:;'
                                : router.resolve({ name: lastSubMenu.pageName })
                                    .path
                            "
                            class="side-menu text-white hover:text-theme-41 hover:font-semibold active:text-theme-41"
                            :class="{ 'text-theme-41': lastSubMenu.active }"
                            @click="
                              linkTo(lastSubMenu, router, $event),
                                getCrumbs(lastSubMenu)
                            "
                          >
                            <div class="side-menu__icon">
                              <i
                                :class="lastSubMenu.icon"
                                class="w-6 text-xl"
                              ></i>
                            </div>
                            <div
                              class="side-menu__title"
                              :class="[
                                slide_nav
                                  ? 'side-menu__title_hide '
                                  : 'side-menu__title'
                              ]"
                            >
                              {{ lastSubMenu.title }}
                            </div>
                          </SideMenuTooltip>
                        </li>
                      </ul>
                    </transition>
                    <!-- END: Third Child -->
                  </li>
                </ul>
              </transition>
              <!-- END: Second Child -->
            </li>

            <!-- </template> -->
            <!-- END: First Child -->
          </ul>
        </div>

        <div v-if="!hide_nav" class="hidden flex-0 md:flex flex-col items-end">
          <div
            class="w-8 hidden xl:block bg-theme-2 py-0.5 rounded-l-full cursor-pointer"
            @click="slide_nav = !slide_nav"
          >
            <div v-if="!slide_nav" class="pl-2">
              <ChevronsLeftIcon
                class="h-6 w-6 text-theme-41 hover:text-theme-47"
              />
            </div>
            <div v-if="slide_nav" class="pl-2">
              <ChevronsRightIcon
                class="h-6 w-6 text-theme-41 hover:text-theme-47"
              />
            </div>
          </div>
          <div
            v-if="!hide_nav"
            class="w-6 hidden md:block bg-theme-2 py-0.5 rounded-l-full cursor-pointer"
            @click="hide_nav = !hide_nav"
          >
            <div class="pl-1 pr-0.5">
              <ChevronLeftIcon
                class="h-6 w-6 text-theme-41 hover:text-theme-47"
              />
            </div>
          </div>
        </div>
      </nav>
      <!-- END: Side Menu -->
      <!-- BEGIN: Content -->
      <div id="content-id" class="content h-auto w-full overflow-hidden">
        <div class="flex flex-row items-end justify-between">
          <div
            v-if="hide_nav"
            class="mr-3 w-6 py-0.5 rounded-l-full cursor-pointer"
          >
            <div
              class="bg-theme-40 pl-3 py-0.5 rounded-r-full hidden xl:block absolute bottom-44 left-0 w-12"
              @click="navSlide"
            >
              <div v-if="hide_nav" class="pl-1 pr-05">
                <ChevronsRightIcon
                  class="h-6 w-6 text-theme-41 hover:text-theme-47"
                />
              </div>
              <!-- <div v-if="slide_nav" class="pl-1 pr-0.5">
                <ChevronsLeftIcon
                  class="h-6 w-6 text-theme-41 hover:text-theme-47"
                />
              </div> -->
            </div>

            <div
              class="bg-theme-40 pl-3 py-0.5 rounded-r-full hidden md:block absolute bottom-36 mb-2 left-0 w-10"
              @click="hide_nav = !hide_nav"
            >
              <div v-if="hide_nav" class="pl-1 pr-0.5">
                <ChevronRightIcon
                  class="h-6 w-6 text-theme-41 hover:text-theme-47"
                />
              </div>
            </div>
          </div>
          <div class="p-1 w-full">
            <router-view />
          </div>
        </div>
        <!-- <SimpleMessageModal
          v-if="showMessageModal"
          :title="messageTitle"
          :subtitle="messageSubtitle"
          @close="store.dispatch('commonComponents/closeMessageModal')"
        /> -->
      </div>

      <!-- END: Content -->
    </div>
  </div>
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'
import TopBar from '@/components/top-bar/Main.vue'
import MobileMenu from '@/components/mobile-menu/Main.vue'
import SideMenuTooltip from '@/components/side-menu-tooltip/Main.vue'
import { linkTo, nestedMenu, enter, leave } from './index'
import { mapGetters } from 'vuex'
import SimpleMessageModal from '../../components/modals/SimpleMessageModal.vue'
import PdfModal from '../../components/modals/PdfModal.vue'
import SimpleConfirmModal from '../../components/modals/SimpleConfirmModal.vue'
import LoadingIconModal from '../../components/modals/LoadingIconModal.vue'
import SendMessageModal from '../../components/modals/SendMessageModal.vue'
import SendSMSModal from '../../components/modals/SendSMSModal.vue'
import ChangePasswordModal from '../../components/modals/ChangePasswordModal.vue'
import BlockModal from '../../components/modals/BlockModal.vue'
import SignatureModal from '../../components/modals/SignatureModal.vue'
import NewVaccineModal from '../../components/modals/NewVaccineModal.vue'
import WeightModal from '../../components/modals/WeightModal.vue'
import NewAnnotationModal from '../../components/modals/NewAnnotationModal.vue'
import TableModal from '../../components/modals/TableModal.vue'
import TemplateModal from '../../components/modals/TemplateModal.vue'
import router from '@/router'

import { useToast } from 'vue-toastification'

export default defineComponent({
  components: {
    TopBar,
    MobileMenu,
    SideMenuTooltip,
    SimpleMessageModal,
    SimpleConfirmModal,
    LoadingIconModal,
    SendMessageModal,
    SendSMSModal,
    PdfModal,
    ChangePasswordModal,
    SignatureModal,
    BlockModal,
    NewVaccineModal,
    WeightModal,
    NewAnnotationModal,
    TableModal,
    TemplateModal
  },
  setup() {
    const toast = useToast()
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const formattedMenu = ref([])
    const sideMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    )
    /* prendo questi array dal file inde.js situato nella folder store */
    const crumbs = computed(function () {
      return store.state.crumbs
    })
    const titleArray = computed(function () {
      return store.state.titleArray
    })
    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      }
    )

    watch(
      sideMenu.value,
      () => {
        formattedMenu.value = $h.toRaw(sideMenu.value)
      },
      { deep: true }
    )

    onMounted(() => {
      cash('body')
        .removeClass('error-page')
        .removeClass('login')
        .addClass('main')
      formattedMenu.value = $h.toRaw(sideMenu.value)
    })

    return {
      formattedMenu,
      router,
      linkTo,
      enter,
      leave,
      crumbs,
      titleArray,
      store,
      toast
    }
  },
  data() {
    return {
      slide_nav: false,
      hide_nav: false,
      ignoreMenu: [
        // 'Segreteria',
        // 'Area medica',
        // 'Magazzino',
        // 'Gestioni clienti',
        // 'Amministrazione e statistiche',
        // 'Setup della clinica',
        // 'Staff e sale clinica',
        // 'Fatturazione',
        // 'Pet',
        // 'Area medica'
      ]
    }
  },
  computed: {
    ...mapGetters({
      getProfile: 'getProfile',
      messageTitle: 'commonComponents/getMessageModalTitle',
      messageSubtitle: 'commonComponents/getMessageModalSubtitle',
      showMessageModal: 'commonComponents/getShowMessageModal',
      showSignatureModal: 'commonComponents/getShowSignatureModal',
      showLoadingModal: 'commonComponents/getShowLoadingModal',
      messageModalBackground: 'commonComponents/getMessageModalBackground',
      confirmTitle: 'commonComponents/getConfirmModalTitle',
      confirmSubtitle: 'commonComponents/getConfirmModalSubtitle',
      confirmText: 'commonComponents/getConfirmModalConfirmText',
      declineText: 'commonComponents/getConfirmModalDeclineText',
      showConfirmModal: 'commonComponents/getShowConfirmModal',
      showSendMessageModal: 'commonComponents/getSendMessageModal',
      sendMessageData: 'commonComponents/getSendMessageData',
      showSendSmsEmailModal: 'commonComponents/getSendSmsEmailModal',
      smsEmailModalType: 'commonComponents/getSmsEmailModalType',
      showNotificationModal: 'commonComponents/getShowNotificationModal',
      notificationMessage: 'commonComponents/getNotificationMessage',
      showPdfModal: 'commonComponents/getShowPdfModal',
      pdfTitle: 'commonComponents/getPdfModalTitle',
      pdfLink: 'commonComponents/getPdfModalLink',
      pdfTableReference: 'commonComponents/getPdfTableReference',
      pdfIdReference: 'commonComponents/getPdfIdReference',
      pdfConfirmations: 'commonComponents/getPdfConfirmations',
      pdfClient: 'commonComponents/getPdfClient',
      pdfSigned: 'commonComponents/getPdfSigned',
      xml: 'commonComponents/getXmlModal',
      showPasswordModal: 'commonComponents/getShowPasswordModal',
      passwordModalPassword: 'commonComponents/getPasswordModalPassword',
      passwordModalConsensi: 'commonComponents/getPasswordModalConsensi',
      passwordModalLicenza: 'commonComponents/getPasswordModalLicenza',
      showModalBlock: 'commonComponents/getShowBlockModal',
      vaccineData: 'commonComponents/getVaccineData',
      showVaccineModal: 'commonComponents/getShowVaccineModal',
      weightData: 'commonComponents/getWeightData',
      showWeightModal: 'commonComponents/getShowWeightModal',
      showAnnotationModal: 'commonComponents/getAnnotationModal',
      annotationData: 'commonComponents/getAnnotationData',
      showTableModal: 'commonComponents/getTableModal',
      tableData: 'commonComponents/getTableData',
      pdfDate: 'commonComponents/getPdfDate',
      showTemplateModal: 'commonComponents/getShowTemplateModal',
      templateData: 'commonComponents/getTemplateModalData'
    })
  },
  watch: {
    showNotificationModal: function (elem) {
      if (elem === true) {
        this.toast.success(this.notificationMessage, {
          position: 'bottom-right',
          timeout: 3000,
          closeOnClick: false,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: false,
          icon: true,
          rtl: false
        })

        this.$store.dispatch('commonComponents/closeNotificationModal', {
          message: 'Scheda clinica salvata con successo!'
        })
      }
    }
  },

  methods: {
    goToDash() {
      router.push({ name: 'side-menu-dashboard' })
    },
    navSlide() {
      this.hide_nav = false
      this.slide_nav = !this.slide_nav
    },

    getCrumbs: function () {}
  }
})
</script>

<style>
.Vue-Toastification__container {
  right: 3em !important;
  bottom: 3em !important;
}

.Vue-Toastification__toast-body {
  color: white;
  font-family: roboto;
  font-weight: bold;
}

.Vue-Toastification__toast--success {
  background-color: #ff8f1c !important;
}
</style>
