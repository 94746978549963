<template>
  <div>
    <div
      id="ritenuta-modal"
      class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50"
    >
      <div
        class="relative top-12 mt-1 mx-auto w-3/5 lg:w-2/5 px-5 pt-3 pb-7 border shadow-lg rounded-md bg-white"
      >
        <button type="button" class="closing-btn" @click="this.$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
        <div class="flex flex-col">
          <div class="">
            <h2 id="modal-title" class="modal-title mt-8">Ritenute</h2>
            <!-- v-if="isSetup == false" -->
            <div
              v-if="isSetup == false"
              class="py-2 flex flex-col items-center"
            >
              <p class="flex-1 text-gray-500 p-3">
                Inserisci in questa sezione i dati relativi alla ritenuta
                d'acconto ed alle ritenute previdenziali da applicare di default
                alle tue fatture, nel caso in cui la tua cassa previdenziale di
                appartenenza vi sia soggetta. Potrai modificare le ritenute
                quando vorrai.
              </p>

              <div
                class="flex flex-col md:flex-row my-2 text-center items-center gap-2"
              >
                <input
                  v-model="checkRitenuta"
                  type="checkbox"
                  class="form-check-switch"
                />

                <label
                  v-if="checkRitenuta == false"
                  for=""
                  class="form-label mt-2"
                  >Non attivo</label
                >
                <label v-else for="" class="form-label mt-2">Attivo</label>

                <div v-if="checkRitenuta == true">
                  <button
                    v-if="modifica == false"
                    type="button"
                    class="orange-btn ml-3"
                    @click="modifRitenuta"
                  >
                    Modifica
                  </button>
                  <button
                    v-else
                    type="button"
                    class="gray-btn ml-3"
                    @click="modifRitenuta"
                  >
                    Annulla modifiche
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- BEGIN: CALCOLO RITENUTA SU SERVIZI -->
        <div v-if="modifica && checkRitenuta" class="px-4 py-3">
          <div class="flex flex-col lg:flex-row my-2 mb-5">
            <div class="flex-1 text-sm">
              <div class="px-3">
                <label for="" class="form-label">Tipo ritenuta</label>
                <select
                  v-model="ritenutaTypeSelected"
                  class="custom-input mt-2"
                  disabled
                >
                  <option
                    v-for="n in ritenutaTypeOptions"
                    :key="n.code"
                    :value="n"
                  >
                    {{ n.code }} - {{ n.description }}
                  </option>
                </select>
              </div>
              <div class="flex flex-col lg:flex-row my-2 gap-1 items-end">
                <div class="w-full lg:flex-1 flex-col px-3">
                  <label class="form-label">Aliquota ritenuta</label>

                  <div class="relative">
                    <input
                      v-model="ritenutaAliq"
                      class="custom-input mt-2 px-8"
                      disabled
                      type="number"
                      min="0"
                      max="100"
                      step="0.01"
                      style="text-align: right"
                      @change="calcRitenuta"
                    />
                    <div class="ml-1 font-semibold absolute top-3 right-5">
                      %
                    </div>
                  </div>
                </div>
                <div class="w-full lg:flex-1 flex-col px-3">
                  <label class="form-label">su</label>

                  <input
                    v-model="ritenutaPerc"
                    class="custom-input mt-2 px-8"
                    disabled
                    type="number"
                    min="0"
                    max="100"
                    step="0.01"
                    style="text-align: right"
                    @change="calcRitenuta"
                  />
                </div>
              </div>
              <div class="">
                <label for="" class="form-label px-3">Causale pagamento</label>

                <div class="flex flex-col relative text-left mt-2">
                  <div class="px-3">
                    <button
                      id="menu-button"
                      type="button"
                      class="custom-input flex items-center justify-between"
                      aria-expanded="true"
                      aria-haspopup="true"
                      @click="showDropDown = !showDropDown"
                    >
                      <div class="pr-3 text-left">
                        {{
                          ritenutaCausaleSelected
                            ? ritenutaCausaleSelected.code
                              ? ritenutaCausaleSelected.description
                                ? ritenutaCausaleSelected.code +
                                  ' ' +
                                  '-' +
                                  ' ' +
                                  ritenutaCausaleSelected.description
                                : ''
                              : ritenutaCausaleSelected.code
                            : ''
                        }}
                      </div>
                      <div class="ml-2">
                        <svg
                          class="h-5 w-5"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </button>
                  </div>

                  <div
                    v-if="showDropDown"
                    class="chil-centered absolute border border-theme-45 bg-white w-full top-16 shadow-md h-48 overflow-y-scroll rounded-md scrollbar-none"
                    role="menu"
                    aria-orientation="vertical"
                    aria-labelledby="menu-button"
                    tabindex="-1"
                  >
                    <div
                      v-for="p in ritenutaCausaleOptions"
                      :key="p"
                      class="py-1"
                      role="none"
                    >
                      <a
                        id="menu-item-0"
                        href="#"
                        class="text-gray-700 block px-4 py-2 text-sm hover:bg-theme-44 hover:text-white"
                        role="menuitem"
                        tabindex="-1"
                        @click="selectRitenutaCausale(p)"
                        >{{ p.code }} - {{ p.description }}</a
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="isSetup == false" class="flex-1">
              <DropServices
                :services="this.services"
                @taxable="updateTaxable"
                :oldChecks="this.oldChecks"
              />
              <div class="text-center my-3">
                Totale imponibile {{ this.taxable }} €
              </div>
            </div> -->
          </div>
          <!-- END: CALCOLO RITENUTA SU SERVIZI -->
          <!-- BEGIN: RIEPILOGO RITENUTA -->
          <div v-if="isSetup != false" class="mt-2">
            <div class="col-span-12 flex-1">
              <div class="text-center">
                <h3
                  class="text-xl font-bold text-center bg-theme-2 rounded-md w-full p-1 mt-2"
                >
                  Riepilogo
                </h3>
                <div
                  class="flex flex-col md:flex-row items-center gap-3 my-2 px-3"
                >
                  <div class="flex-1 shadow-md rounded-md my-2 p-4">
                    <h4 class="text-lg font-bold">Imponibile ritenuta</h4>

                    <div
                      class="flex-1 py-1 flex items-center gap-1 justify-center"
                    >
                      <span class="font-semibold"> € </span>
                      {{ this.taxable }}
                    </div>
                  </div>

                  <div class="flex-1 shadow-md rounded-md my-2 p-4">
                    <h4 class="text-lg font-bold">Importo ritenuta</h4>

                    <div
                      class="flex-1 py-1 flex items-center gap-1 justify-center"
                    >
                      <span class="font-semibold"> € </span>
                      {{ this.ritenutaValue }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END: RIEPILOGO RITENUTA -->
        </div>

        <div
          v-if="isSetup == false"
          class="flex items-baseline w-full justify-end my-2 gap-3 px-3"
        >
          <button
            id="dialogbox-delete-button"
            class="orange-btn"
            type="button"
            @click="addRitenuta"
          >
            <SaveIcon class="w-5 h-5 mr-2 justify-center" />
            Salva
          </button>

          <button
            id="dialogbox-anulla-button"
            autofocus
            type="button"
            class="gray-btn"
            @click="this.$emit('close')"
          >
            Annulla
          </button>
        </div>
        <!-- BEGIN: footer setup della clinica -->
        <div
          v-if="isSetup == true"
          class="mt-2 flex items-center justify-end pr-4 gap-3"
        >
          <button
            id="dialogbox-delete-button"
            class="orange-btn"
            type="button"
            @click="saveDefaultRitenuta"
          >
            <SaveIcon class="w-5 h-5 mr-2 justify-center" />
            Salva
          </button>

          <button
            id="dialogbox-anulla-button"
            autofocus
            type="button"
            class="gray-btn"
            @click="this.$emit('close')"
          >
            Annulla
          </button>
        </div>
        <!-- END: footer setup della clinica -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../gateway/backend-api'
//import DropServices from '../../components/drop-services/DropServices.vue'
// import { elementClosest } from '@fullcalendar/common'

export default {
  // Eventi personalizzati da richiamare nel componente FatturazioneAttiva
  components: {
    // DropServices
  },
  event: ['close', 'addRitenuta'],
  props: {
    services: [],
    ritenutaVecchia: Object,
    currentRitenuta: Object,
    oldChecks: [],
    isComingFromSetup: Object,
    active: Boolean
  },

  data() {
    return {
      showDropDown: false,
      checkRitenuta: false,
      modifica: false,
      ritenutaTypeSelected: [],
      ritenutaTypeOptions: [],
      ritenutaCausaleSelected: [],
      ritenutaCausaleOptions: [],
      taxable: '',
      ritenutaValue: '',
      ritenutaAliq: '',
      ritenutaPerc: 100,
      checks: [],
      isSetup: false
    }
  },
  watch: {
    services: {
      deep: true
    },
    checkRitenuta: function (el) {
      console.log('sono qua', el)

      if (this.currentRitenuta) {
        this.modifica = true
        this.ritenutaTypeSelected = {
          code: this.currentRitenuta.type.code,
          description: this.currentRitenuta.type.description,
          id: this.currentRitenuta.type.id,
          version: this.currentRitenuta.type.version,
          withholding_tax_types_id: null
        }
        this.ritenutaCausaleSelected = this.currentRitenuta.causale
        this.ritenutaAliq = this.currentRitenuta.aliquota
      }
    }
  },
  created() {
    this.getListRitenute()
    if (this.currentRitenuta) {
      if (this.active === true) {
        this.checkRitenuta = true
        this.modifica = true
      }

      this.ritenutaTypeSelected = {
        code: this.currentRitenuta.type.code,
        description: this.currentRitenuta.type.description,
        id: this.currentRitenuta.type.id,
        version: this.currentRitenuta.type.version,
        withholding_tax_types_id: null
      }
      this.ritenutaCausaleSelected = this.currentRitenuta.causale
      this.ritenutaAliq = this.currentRitenuta.aliquota
    }
    if (this.ritenutaVecchia != null) {
      if (this.ritenutaVecchia.value > 0) {
        this.modifica = true
        this.checkRitenuta = true
        //this.ritenutaTypeSelected = this.ritenutaVecchia.type
        this.ritenutaTypeSelected = {
          code: this.ritenutaVecchia.type.code,
          description: this.ritenutaVecchia.type.description,
          id: this.ritenutaVecchia.type.id,
          version: this.ritenutaVecchia.type.version,
          withholding_tax_types_id: null
        }
        this.ritenutaCausaleSelected = this.ritenutaVecchia.causale
        this.ritenutaValue = this.ritenutaVecchia.value
        this.ritenutaPerc = this.ritenutaVecchia.onPerc
        this.ritenutaAliq = this.ritenutaVecchia.aliquota
      }
    }
    if (this.isComingFromSetup != null && this.isComingFromSetup == true) {
      this.isSetup = true
      this.modifica = true
      this.checkRitenuta = true
      // this.ritenutaTypeSelected = this.ritenutaVecchia.type
      this.ritenutaTypeSelected = {
        code: this.ritenutaVecchia.type.code,
        description: this.ritenutaVecchia.type.description,
        id: this.ritenutaVecchia.type.id,
        version: this.ritenutaVecchia.type.version,
        withholding_tax_types_id: null
      }
      this.ritenutaCausaleSelected = this.ritenutaVecchia.causale

      this.ritenutaPerc =
        this.ritenutaVecchia.onPerc == undefined
          ? 100
          : this.ritenutaVecchia.onPerc
      this.ritenutaAliq = this.ritenutaVecchia.aliquota
    }
  },

  methods: {
    selectRitenutaCausale: function (elem) {
      this.showDropDown = false
      this.ritenutaCausaleSelected = elem
    },
    saveDefaultRitenuta: function () {
      if (
        this.ritenutaTypeSelected == null ||
        this.ritenutaTypeSelected == '' ||
        this.ritenutaPerc == null ||
        this.ritenutaPerc == '' ||
        this.ritenutaCausaleSelected == null ||
        this.ritenutaCausaleSelected == '' ||
        this.ritenutaAliq == null ||
        this.ritenutaAliq == ''
      ) {
        alert('Compila tutti i campi')
      } else {
        var ritenuta = {
          type: this.ritenutaTypeSelected,
          onPerc: this.ritenutaPerc,
          causale: this.ritenutaCausaleSelected,
          aliquota: this.ritenutaAliq
        }
        this.$emit('saveDefault', ritenuta)
      }
    },
    modifRitenuta() {
      this.modifica = !this.modifica
    },
    // Funzione che ritorna le scelte selezionabili come tipo documento
    getListRitenute() {
      axios
        .get('/' + localStorage.getItem('clinic') + '/bills/ritenuta')
        .then((res) => {
          this.ritenutaTypeOptions = res.data.ritenuta
          this.ritenutaCausaleOptions = res.data.causale
        })
    },
    // Aggiorna il valore dell'imponibile
    updateTaxable(value, checks) {
      this.taxable = value
      this.checks = checks
      this.calcRitenuta()
    },
    // Calcola l'importo della ritenuta in base ai valori impostati
    calcRitenuta() {
      let full_taxable = 0
      this.services.forEach((el) => {
        full_taxable += el.taxable * 1
      })
      this.ritenutaValue =
        (((full_taxable / 100) * this.ritenutaPerc) / 100) * this.ritenutaAliq
      this.ritenutaValue =
        Math.round((this.ritenutaValue + Number.EPSILON) * 100) / 100
    },
    //Funzione che ritorna alla fatturazione passando i dati della ritenuta inseriti
    addRitenuta: function () {
      //if (this.ritenutaValue > 0) {
      let ritenuta
      if (this.checkRitenuta) {
        ritenuta = {
          type: this.ritenutaTypeSelected,
          aliquota: this.ritenutaAliq,
          onPerc: this.ritenutaPerc,
          causale: this.ritenutaCausaleSelected,
          value: this.ritenutaValue
        }
      } else {
        ritenuta = {
          type: '',
          aliquota: '',
          onPerc: 100,
          causale: '',
          value: 0
        }
      }
      this.$emit('addRitenuta', ritenuta, this.checks, this.checkRitenuta)
      // } else {
      //   alert('Devi impostare i valori')
      // }
    }
  }
}
</script>

<style>
.style-chooser .vs__dropdown-menu ul {
  display: block;
  max-width: 50%; /* limit width */
  word-break: break-all; /* wrap extended text*/
  border: 1px solid red;
}

/*>>> {
  --vs-controls-size: 0.7;
  --vs-controls-color: #94a3b8;
  --vs-border-color: none;
  --vs-dropdown-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
  --vs-actions-padding: 1px 0 1px -2px;
  --vs-dropdown-option--active-color: #333435;
  --vs-dropdown-option-padding: 3px 20px;
  --vs-dropdown-option--active-bg: #edf2f7;
  --vs-selected-bg: #edf2f7;
  --vs-selected-color: #333435;
  --vs-dropdown-min-width: 100%;
  --vs-dropdown-option--active-bg: #aebfcf;
  --vs-search-input-placeholder-color: #d4d8e1;
  --vs-font-size: 0.8 rem;
  --vs-transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
  --vs-transition-duration: 150ms;
} */
</style>
