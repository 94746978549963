<template>
  <!-- Modal background -->

  <div
    id="new-appointment-modal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none text-left"
  >
    <!-- Modal body -->

    <div
      class="z-40 relative top-12 px-2 md:px-6 py-8 h-vh mx-auto overflow-scroll scrollbar-none border w-4/5 md:w-3/5 shadow-lg rounded-md bg-white"
    >
      <button type="button" class="closing-btn" @click="closeNewClient">
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <div
        id="new-client-title"
        class="mx-auto justify-center my-1 bg-theme-40 nunito rounded-md p-3 mt-5"
      >
        <h2 class="font-bold text-center text-xl text-white">
          {{ title }}
        </h2>
      </div>
      <div
        class="mx-auto bg-white p-2 rounded-2xl ricerca_avanzata-cont overflow-auto scrollbar_custom"
      >
        <form id="formNewCustomer" class="" method="post">
          <!-- header anagrafica -->
          <div class="">
            <div class="mr-3 mt-3 gap-3 flex justify-end">
              <button
                :class="[
                  isPrivate
                    ? 'orange-btn hover:bg-theme-41 hover:text-white hover:border-theme-41'
                    : 'orange-btn-disable'
                ]"
                @click.prevent="isPrivate = true"
              >
                <div class="font-semibold">Privato</div>
              </button>

              <button
                :class="[
                  !isPrivate
                    ? 'orange-btn hover:bg-theme-41 hover:text-white hover:border-theme-41'
                    : 'orange-btn-disable'
                ]"
                @click.prevent="isPrivate = false"
              >
                <div class="font-semibold">Azienda</div>
              </button>
            </div>
            <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
              <h2 class="ml-3 text-base font-bold">Anagrafica</h2>
            </div>
            <!-- first row -->

            <div class="mx-3 flex flex-col justify-between gap-3 lg:flex-row">
              <div v-if="isPrivate" class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newclient-lastname"
                >
                  Cognome
                </label>
                <input
                  id="newclient-lastname"
                  ref="input1"
                  v-model="formData['last_name']"
                  :class="{
                    notapproved:
                      !inputcontrol.last_name || !inputcontrol.last_name_length
                  }"
                  class="custom-input"
                  type="text"
                  required
                  maxlength="80"
                  placeholder="Scrivi il cognome"
                  @keyup.enter="switchFocus(2)"
                  @change="validateForm"
                />
                <span
                  v-if="!inputcontrol.last_name && calcoloCF_data"
                  class="text-theme-41 mt-1"
                >
                  {{ error_message }}</span
                >
                <span
                  v-if="!inputcontrol.last_name_length"
                  class="text-theme-41 mt-1"
                >
                  Il cognome deve avere almeno 2 caratteri</span
                >
                <span
                  v-if="
                    !calcoloCF_data &&
                    (!inputcontrol.last_name || !inputcontrol.last_name_length)
                  "
                  class="text-theme-41 mt-1"
                >
                  {{ error_message_cf }}</span
                >
              </div>
              <div v-else class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newclient-ragsoc"
                >
                  Ragione sociale
                </label>
                <input
                  id="newclient-ragione-sociale"
                  v-model="formData['ragione_sociale']"
                  class="custom-input"
                  :class="{
                    notapproved: !inputcontrol.ragione_sociale
                  }"
                  type="text"
                  placeholder="Scrivi la denominazione"
                />
                <span
                  v-if="!inputcontrol.ragione_sociale"
                  class="text-theme-41 mt-1"
                >
                  {{ error_message }}</span
                >
              </div>

              <div v-if="isPrivate" class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newclient-name"
                >
                  Nome
                </label>
                <input
                  id="newclient-name"
                  ref="input2"
                  v-model="formData['first_name']"
                  class="custom-input"
                  :class="{
                    notapproved:
                      !inputcontrol.first_name ||
                      !inputcontrol.first_name_length
                  }"
                  type="text"
                  maxlength="80"
                  placeholder="Scrivi il nome"
                  @keyup.enter="switchFocus(3)"
                  @keyup.left="switchFocus(1)"
                />

                <span
                  v-if="!inputcontrol.first_name && calcoloCF_data"
                  class="text-theme-41 mt-1"
                >
                  {{ error_message }}</span
                >
                <span
                  v-if="!inputcontrol.first_name_length"
                  class="text-theme-41 mt-1"
                >
                  Il nome deve avere almeno 2 caratteri</span
                >
                <span
                  v-if="
                    !calcoloCF_data &&
                    (!inputcontrol.first_name ||
                      !inputcontrol.first_name_length)
                  "
                  class="text-theme-41 mt-1"
                >
                  {{ error_message_cf }}</span
                >
              </div>
            </div>

            <!-- second row -->
            <div
              v-if="isPrivate"
              class="mx-3 flex flex-col justify-between gap-3 lg:flex-row"
            >
              <div class="flex-1 py-2 scrollbar-none">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newclient-nationality"
                >
                  Nazionalità
                </label>
                <Places
                  :types="['country']"
                  :addressPlaceholderProps="formData['nationality']"
                  @place_changed="nationalityChanged"
                />
                <!-- <v-select
                  id="newclient-nationality"
                  ref="input3"
                  v-model="select_data['nationality']"
                  class="shadow appearance-none border border-gray-300 rounded w-full px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none placeholder-gray-400"
                  :class="{
                    notapproved: !inputcontrol.nationality
                  }"
                  :options="countries"
                  label="common"
                  placeholder="Seleziona la nazionalità"
                  @keyup.enter="switchFocus(4)"
                  @keyup.left="switchFocus(2)"
                >
                  <template #no-options> Inserire ... </template>
                </v-select>
                -->
                <span
                  v-if="!inputcontrol.nationality && calcoloCF_data"
                  class="text-theme-41 mt-1"
                >
                  {{ error_message }}</span
                >
                <span
                  v-if="!calcoloCF_data && !inputcontrol.nationality"
                  class="text-theme-41 mt-1"
                >
                  {{ error_message_cf }}</span
                >
              </div>

              <!-- is Italian? -->

              <div class="flex-1 py-2 scrollbar-none">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newclient-comune"
                >
                  Paese di nascita
                </label>
                <Places
                  :types="['country']"
                  :addressPlaceholderProps="formData['country_of_birth']"
                  @place_changed="countryChanged"
                />
                <span
                  v-if="!inputcontrol.country_of_birth"
                  class="text-theme-41 mt-1"
                >
                  {{ error_message }}</span
                >
                <span
                  v-if="!inputcontrol.country_of_birth"
                  class="text-theme-41 mt-1"
                >
                  {{ error_message_cf }}</span
                >
              </div>

              <div v-if="!isForeign" class="flex-1 py-2 scrollbar-none">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newclient-comune"
                >
                  Comune di nascita
                </label>
                <Places
                  :types="['locality']"
                  :restrictions="['it']"
                  :addressPlaceholderProps="formData['city_of_birth']"
                  @place_changed="cityChanged"
                />

                <!-- <v-select
                  id="newclient-comune"
                  ref="selectComuni"
                  v-model="comune"
                  class="shadow appearance-none border border-gray-300 rounded w-full px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none placeholder-gray-400"
                  :class="{
                    notapproved: !inputcontrol.city_of_birth
                  }"
                  placeholder="Seleziona il comune di nascita"
                  label="nome"
                  :options="comuneOptions"
                  @keyup.enter="switchFocus(5)"
                  @keyup.left="switchFocus(3)"
                  @search="
                    (elem) => {
                      inputChanged(elem)
                    }
                  "
                >
                  <template #no-options> Inserire ... </template>
                </v-select>
                -->
                <span
                  v-if="!inputcontrol.city_of_birth && calcoloCF_data"
                  class="text-theme-41 mt-1"
                >
                  {{ error_message }}</span
                >
                <span
                  v-if="!inputcontrol.city_of_birth && !calcoloCF_data"
                  class="text-theme-41 mt-1"
                >
                  {{ error_message_cf }}</span
                >
              </div>

              <div v-if="!isForeign" class="flex-1 py-2 scrollbar-none">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newclient-provincia"
                >
                  Provincia
                </label>

                <input
                  id="newclient-provincia"
                  ref="input5"
                  v-model="provinciaSelected"
                  class="custom-input"
                  placeholder="Seleziona provincia di nascita"
                  disabled
                  @keyup.enter="switchFocus(6)"
                  @keyup.left="switchFocus(4)"
                />
              </div>

              <!-- is Foreign? -->

              <div v-if="isForeign" class="flex-1 py-2">
                <label
                  for="newclient-numeroprevidenzaestero"
                  class="block text-gray-700 text-xs font-bold mb-2"
                  >Numero di Previdenza Estero</label
                >
                <input
                  id="newclient-numeroprevidenzaestero"
                  v-model="formData['codice_previdenza_estero']"
                  type="text"
                  class="custom-input"
                  placeholder="Inserisci il numero di previdenza estero"
                />
              </div>
            </div>

            <!-- third row -->

            <div class="mx-3 flex flex-col justify-between gap-3 lg:flex-row">
              <div class="flex-0 flex justify-between">
                <div v-if="isPrivate" class="py-2 mr-3 flex-1">
                  <label
                    for="newclient-birth"
                    class="block text-gray-700 text-xs font-bold mb-2"
                    >Data di nascita</label
                  >

                  <input
                    id="newclient-birth"
                    v-model="formData['date_of_birth']"
                    class="w-full shadow appearance-none border border-gray-300 rounded py-1.5 px-5 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none placeholder-gray-400"
                    :class="{ notapproved: !inputcontrol.date_of_birth }"
                    type="date"
                    placeholder="Seleziona data di nascita"
                  />
                  <!--
                    <div
                      class="absolute top-1.5 right-5 items-center pointer-events-none"
                    >
                      <svg
                        class="w-5 h-5 text-gray-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div> -->

                  <span
                    v-if="!inputcontrol.date_of_birth && calcoloCF_data"
                    class="text-theme-41 mt-1"
                  >
                    {{ error_message }}</span
                  >
                  <span
                    v-if="!inputcontrol.date_of_birth && !calcoloCF_data"
                    class="text-theme-41 mt-1"
                  >
                    {{ error_message_cf }}</span
                  >
                </div>

                <div v-if="isPrivate" class="py-2 flex-1">
                  <label
                    for="newclient-gender"
                    class="block text-gray-700 text-xs font-bold mb-2"
                  >
                    Genere</label
                  >

                  <v-select
                    id="newclient-gender"
                    ref="genders"
                    v-model="formData['gender']"
                    class="w-full lg:w-32"
                    :class="{
                      notapproved: !inputcontrol.gender
                    }"
                    placeholder="Selezionare il genere"
                    taggable
                    :options="['F', 'M']"
                  />
                  <span
                    v-if="!inputcontrol.gender && calcoloCF_data"
                    class="text-theme-41 mt-1"
                  >
                    {{ error_message }}</span
                  >
                  <span
                    v-if="!inputcontrol.gender && !calcoloCF_data"
                    class="text-theme-41 mt-1"
                  >
                    {{ error_message_cf }}</span
                  >
                </div>
              </div>

              <div class="py-2 flex-1">
                <label
                  for="newclient-fiscalcode"
                  class="block text-gray-700 text-xs font-bold mb-2"
                  >Codice Fiscale</label
                >

                <div class="flex items-center">
                  <div class="flex-1">
                    <input
                      id="newclient-fiscalcode"
                      v-model="formData['fiscal_code']"
                      type="text"
                      class="custom-input"
                      :class="{
                        notapproved:
                          !inputcontrol.fiscal_code ||
                          !inputcontrol.fiscal_code_length
                      }"
                      maxlength="16"
                      placeholder="Inserisci Codice Fiscale"
                      @change="checkExistingFiscalCode"
                    />

                    <span
                      v-if="!inputcontrol.fiscal_code"
                      class="text-theme-41 mt-1"
                    >
                      {{ error_message }}</span
                    >
                    <span
                      v-if="!inputcontrol.fiscal_code_length"
                      class="text-theme-41 mt-1"
                    >
                      Il codice fiscale deve contenere 16 caratteri</span
                    >
                    <span
                      v-if="!isPrivate && !inputcontrol.fiscal_code_length"
                      class="text-theme-41 mt-1"
                    >
                      Compilare almeno uno dei due campi fiscali</span
                    >
                  </div>
                  <div v-if="isPrivate" class="flex-0 ml-3">
                    <div class="flex items-center">
                      <button
                        class="orange-btn py-1 px-3"
                        @click.prevent="calcolaCF"
                      >
                        <i class="fas fa-calculator"></i>
                        Calcola
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- fourth row -->

            <div
              v-if="!isPrivate"
              class="mx-3 flex flex-col justify-between gap-3 lg:flex-row"
            >
              <div class="flex-1 py-2">
                <label
                  for="newclient-piva"
                  class="block text-gray-700 text-xs font-bold mb-2"
                  >P. Iva</label
                >
                <input
                  id="newclient-piva"
                  v-model="formData['vat_number']"
                  type="text"
                  class="custom-input"
                  :class="{
                    notapproved:
                      !inputcontrol.vat_number ||
                      !inputcontrol.vat_number_length
                  }"
                  placeholder="Inserisci la partita iva"
                  @change="checkExistingFiscalCode"
                />
                <span
                  v-if="!inputcontrol.vat_number"
                  class="text-theme-41 mt-1"
                >
                  {{ error_message }}</span
                >
                <span
                  v-if="!isPrivate && !inputcontrol.vat_number_length"
                  class="text-theme-41 mt-1"
                >
                  Compilare almeno uno dei due campi fiscali</span
                >
              </div>
              <div class="flex-1 py-2">
                <label
                  for="newclient-pec"
                  class="block text-gray-700 text-xs font-bold mb-2"
                  >PEC</label
                >
                <input
                  id="newclient-pec"
                  v-model="formData['pec']"
                  type="text"
                  class="custom-input"
                  placeholder="Inserisci la PEC"
                />
              </div>
              <div class="flex-1 py-2">
                <label
                  for="newclient-codicedestinatario"
                  class="block text-gray-700 text-xs font-bold mb-2"
                  >Codice Destinatario o Univoco</label
                >
                <input
                  id="newclient-codicedestinatario"
                  v-model="formData['codice_destinatario']"
                  type="text"
                  class="custom-input"
                  placeholder="Inserisci codice destinatario"
                />
              </div>
            </div>
            <!-- Fifth row -->
            <div
              v-if="!isPrivate"
              class="mx-3 flex flex-col justify-between gap-3 lg:flex-row"
            >
              <div class="flex-1 py-2">
                <!-- <label
                  for="newclient-riferimento-amministrazione"
                  class="block text-gray-700 text-xs font-bold mb-2"
                  >Riferimento Amministrazione</label
                >
                <input
                  id="newclient-riferimento-amministrazione"
                  v-model="formData['riferimento_amministrazione']"
                  type="text"
                  class="custom-input"
                  placeholder="Inserisci il riferimento Amministrazione"
                /> -->
                <div class="flex mr-3 gap-3 mt-3 items-center justify-start">
                  <input
                    v-model="pubblicaAmministrazione"
                    class="form-check-switch"
                    type="checkbox"
                  />

                  <p
                    :class="[
                      pubblicaAmministrazione
                        ? 'font-bold'
                        : 'font-normal italic text-theme-44'
                    ]"
                  >
                    Pubblica Amministrazione
                  </p>

                  <!-- <div v-if="pubblicaAmministrazione">
                    Pubblica Amministrazione
                  </div>
                  <div v-else><s>Pubblica Amministrazione</s></div> -->
                </div>
              </div>
            </div>
          </div>
          <!-- end anagrafica -->

          <!-- header Residenza -->
          <div class="">
            <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
              <h2 v-if="isPrivate" class="ml-3 text-base font-bold">
                Residenza
              </h2>
              <h2 v-else class="ml-3 text-base font-bold">Sede</h2>
            </div>
            <div class="mx-3 flex-1 mt-2">
              <Places
                @place_changed="residencyChanged"
                :addressPlaceholderProps="addressLabel"
              />
            </div>
            <div class="mx-3 flex flex-col justify-between gap-3 lg:flex-row">
              <div class="flex-1 py-2 flex">
                <label class="block text-gray-700 text-xs font-bold mb-2"
                  >Non trovi l'indirizzo su Google?</label
                >
                <input
                  v-model="checkResidency"
                  type="checkbox"
                  class="form-checkbox ml-3 h-3 w-3 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
                />
              </div>
            </div>
            <!-- first row -->
            <div v-if="checkResidency">
              <div class="mx-3 flex flex-col justify-between gap-3 lg:flex-row">
                <!-- country -->

                <div
                  class="py-2 flex-1"
                  :class="{ disabilitato: checkResidency == false }"
                >
                  <label
                    class="block text-gray-700 text-xs font-bold mb-2"
                    for="newclient-country"
                  >
                    Nazione
                  </label>
                  <input
                    id="newclient-country"
                    v-model="select_data['country']"
                    placeholder="Seleziona la nazione"
                    :class="{
                      notapproved: !inputcontrol.country
                    }"
                    class="custom-input"
                  />
                  <span v-if="!inputcontrol.country" class="text-theme-41 mt-1">
                    {{ error_message }}</span
                  >
                </div>
                <!-- regione -->

                <div
                  class="flex-1 py-2"
                  v-if="showRegione"
                  :class="{ disabilitato: checkResidency == false }"
                >
                  <label
                    class="block text-gray-700 text-xs font-bold mb-2"
                    for="newclient-regione"
                  >
                    Regione
                  </label>
                  <input
                    id="newclient-regione"
                    v-model="formData['region']"
                    :class="{
                      notapproved: !inputcontrol.region,
                      disabled:
                        formData['country'] != labelItaly ||
                        formData['country'] ||
                        formData['country']['common'] != labelItaly
                    }"
                    placeholder="Seleziona la regione"
                    class="custom-input"
                  />
                  <span v-if="!inputcontrol.region" class="text-theme-41 mt-1">
                    {{ error_message }}</span
                  >
                </div>
                <div
                  v-if="showRegione"
                  class="flex-1 py-2"
                  :class="{ disabilitato: checkResidency == false }"
                >
                  <label
                    class="block text-gray-700 text-xs font-bold mb-2"
                    for="newclient-provincia"
                  >
                    Sigla Provincia (in maiuscolo es. TO)
                  </label>
                  <input
                    id="newclient-provincia"
                    v-model="formData['district']"
                    :class="{
                      notapproved: !inputcontrol.district,
                      disabled:
                        formData['country'] != labelItaly ||
                        formData['country'] ||
                        formData['country']['common'] != labelItaly
                    }"
                    placeholder="Seleziona la provincia"
                    class="custom-input"
                  /><!--
                <v-select
                  id="newclient-provincia"
                  v-model="formData['district']"
                  :class="{
                    notapproved: !inputcontrol.district,
                    disabled:
                      formData['country'] != labelItaly ||
                      formData['country'] ||
                      formData['country']['common'] != labelItaly
                  }"
                  class="shadow appearance-none border border-gray-300 rounded w-full px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none placeholder-gray-400"
                  :options="filteredProvince ? filteredProvince : province"
                  placeholder="Seleziona la provincia"
                  label="sigla"
                /> -->
                  <span
                    v-if="!inputcontrol.district"
                    class="text-theme-41 mt-1"
                  >
                    {{ error_message }}</span
                  >
                </div>
              </div>

              <!-- second row -->

              <div class="mx-3 flex flex-col justify-between gap-3 lg:flex-row">
                <div
                  class="flex-1 py-2"
                  :class="{ disabilitato: checkResidency == false }"
                >
                  <label
                    class="block text-gray-700 text-xs font-bold mb-2"
                    for="newclient-citta"
                  >
                    Città
                  </label>
                  <input
                    id="newclient-citta"
                    v-model="formData['city']"
                    :class="{
                      notapproved: !inputcontrol.city
                    }"
                    class="custom-input"
                    type="text"
                    placeholder="Inserisci la città"
                  />
                  <!-- <v-select
                  v-else
                  id="newclient-citta"
                  v-model="formData['city']"
                  :class="{
                    notapproved: !inputcontrol.city
                  }"
                  class="shadow appearance-none border border-gray-300 rounded w-full px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none placeholder-gray-400"
                  placeholder="Inserisci la città"
                  label="nome"
                  :options="comuneOptions"
                  @search="
                    (elem) => {
                      inputChanged(elem)
                    }
                  "
                >
                  <template #no-options> Inserire ... </template>
                </v-select> -->
                  <span v-if="!inputcontrol.city" class="text-theme-41 mt-1">
                    {{ error_message }}</span
                  >
                </div>
                <div
                  class="py-2 flex-0 w-20"
                  :class="{ disabilitato: checkResidency == false }"
                >
                  <label
                    class="block text-gray-700 text-xs font-bold mb-2"
                    for="newclient-cap"
                  >
                    CAP
                  </label>
                  <input
                    id="newclient-cap"
                    v-model="formData['zip_code']"
                    :class="{
                      notapproved: !inputcontrol.zip_code
                    }"
                    class="custom-input"
                    placeholder="Scrivi il CAP"
                  />
                  <span
                    v-if="!inputcontrol.zip_code"
                    class="text-theme-41 mt-1"
                  >
                    {{ error_message }}</span
                  >
                </div>
                <div
                  class="flex-1 py-2"
                  :class="{ disabilitato: checkResidency == false }"
                >
                  <label
                    class="block text-gray-700 text-xs font-bold mb-2"
                    for="newclient-indirizzo"
                  >
                    Indirizzo
                  </label>
                  <input
                    id="newclient-indirizzo"
                    v-model="formData['address']"
                    :class="{
                      notapproved: !inputcontrol.address
                    }"
                    class="custom-input"
                    type="text"
                    placeholder="Inserisci l'indirizzo"
                  />
                  <span v-if="!inputcontrol.address" class="text-theme-41 mt-1">
                    {{ error_message }}</span
                  >
                </div>
                <div
                  class="py-2 flex-0 w-20"
                  :class="{ disabilitato: checkResidency == false }"
                >
                  <label
                    class="block text-gray-700 text-xs font-bold mb-2"
                    for="newclient-numerocivico"
                  >
                    Numero civico
                  </label>
                  <input
                    id="newclient-numerocivico"
                    v-model="formData['house_number']"
                    :class="{
                      notapproved: !inputcontrol.house_number
                    }"
                    class="custom-input"
                    placeholder="Scrivi il numero civico"
                  />
                  <span
                    v-if="!inputcontrol.house_number"
                    class="text-theme-41 mt-1"
                  >
                    {{ error_message }}</span
                  >
                </div>
              </div>
            </div>
          </div>

          <!-- end residenza -->

          <!-- header anTelefono -->
          <div class="">
            <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
              <h2 class="ml-3 text-base font-bold">Telefono</h2>
            </div>
            <div class="mx-3 items-center">
              <input
                v-model="checkPhone"
                type="checkbox"
                class="form-checkbox mr-1 h-3 w-3 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
                @click="provaFunction"
              />
              <label class="text-gray-700 text-xs font-semibold"
                >Non ho un telefono</label
              >
              <!-- <p>{{ checkPhone }}</p> -->
            </div>
            <!-- first row -->

            <div
              v-for="(elem, elemKey) in phoneConfig"
              :key="elemKey"
              class="mx-3 flex flex-col justify-between gap-3 mt-3 lg:flex-row"
            >
              <!-- CAMBIARE E INSERIRE V-FOR -->
              <div
                class="flex-1 py-2"
                :class="{ disabilitato: checkPhone == true }"
              >
                <label
                  for="newclient-telefono"
                  class="block text-gray-700 text-xs font-bold mb-2"
                >
                  {{ elem.name }}
                </label>

                <div class="flex" :class="{ disabilitato: checkPhone == true }">
                  <input
                    id="newclient-telefono-prefisso"
                    v-model="formData.phone[elemKey]['prefisso']"
                    type="phone"
                    maxlength="5"
                    class="custom-input w-2/5 mr-1"
                    :class="{
                      notapproved: !inputcontrol[`prefisso${elemKey + 1}`]
                    }"
                    placeholder="+ prefisso"
                  />

                  <input
                    id="newclient-telefono"
                    v-model="formData.phone[elemKey]['phone']"
                    type="phone"
                    maxlength="16"
                    class="custom-input"
                    :class="{
                      notapproved: !inputcontrol[`phone${elemKey + 1}`]
                    }"
                    placeholder="Inserisci il telefono"
                  />
                </div>
                <div class="flex">
                  <div class="flex flex-col">
                    <span
                      v-if="!inputcontrol[`prefisso${elemKey + 1}`]"
                      class="text-theme-41 pt-2 mr-2"
                    >
                      Il formato del prefisso è: "+" "numeri"</span
                    >
                  </div>
                  <div class="flex flex-col">
                    <span
                      v-if="!inputcontrol[`phone${elemKey + 1}`]"
                      class="text-theme-41 mt-1"
                    >
                      {{ error_message }}</span
                    >

                    <span
                      v-if="!inputcontrol[`phone${elemKey + 1}`]"
                      class="text-theme-41 pt-2"
                    >
                      Inserire un telefono giusto</span
                    >
                  </div>
                </div>
              </div>
              <div class="flex">
                <div
                  class="flex-1 py-2"
                  :class="{ disabilitato: checkPhone == true }"
                >
                  <label
                    for="newclient-etichetta"
                    class="block text-gray-700 text-xs font-bold mb-2"
                  >
                    Etichetta
                  </label>
                  <input
                    id="newclient-etichetta"
                    v-model="formData.phone[elemKey]['label']"
                    :type="elem.type"
                    class="custom-input"
                    placeholder="Inserisci etichetta"
                  />
                </div>
                <div
                  class="flex items-center mt-8 ml-2"
                  :class="{ disabilitato: checkPhone == true }"
                >
                  <div class="flex items-baseline">
                    <label class="block text-gray-700 text-sm font-bold mb-2">
                      {{ elem.buttonText }}
                    </label>
                    <a class="cursor-pointer" @click="elem.function(elemKey)">
                      <PlusIcon
                        v-if="elem.button === 'add'"
                        class="w-4 h-4 ml-2.5 text-white rounded-sm bg-theme-41 font-bold"
                      />

                      <MinusIcon
                        v-else
                        class="w-4 h-4 ml-2.5 text-white rounded-sm bg-theme-41 font-bold"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end phone -->

          <!-- header email -->
          <div class="">
            <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
              <h2 class="ml-3 text-base font-bold">Email</h2>
            </div>
            <div class="mx-3 items-center">
              <input
                v-model="checkMail"
                type="checkbox"
                class="form-checkbox mr-1 h-3 w-3 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
                @click="provaFunction"
              />
              <label class="text-gray-700 text-xs font-semibold"
                >Non ho un'email</label
              >
              <!-- <p>{{ checkMail }}</p> -->
            </div>
            <!-- first row -->

            <div
              v-for="(elem, elemKey) in emailConfig"
              :key="elemKey"
              class="mx-3 flex flex-col justify-between gap-3 mt-3 lg:flex-row"
            >
              <div
                class="flex-1 py-2"
                :class="{ disabilitato: checkMail == true }"
              >
                <label
                  for="newclient-email"
                  class="block text-gray-700 text-xs font-bold mb-2"
                >
                  {{ elem.name }}
                </label>
                <input
                  id="newclient-email"
                  v-model="formData.email[elemKey]['email']"
                  class="custom-input"
                  :class="{
                    notapproved: !inputcontrol[`email${elemKey + 1}`]
                  }"
                  :type="elem.type"
                  placeholder="Inserisci l'email"
                />
                <span
                  v-if="!inputcontrol[`email${elemKey + 1}`]"
                  class="flex flex-col text-theme-41 mt-1"
                >
                  {{ error_message }}</span
                >
                <span
                  v-if="!inputcontrol[`email${elemKey + 1}`]"
                  class="text-theme-41 pt-2"
                >
                  Inserire una email giusta</span
                >
              </div>

              <div class="flex" :class="{ disabilitato: checkMail == true }">
                <div class="flex-1 py-2">
                  <label
                    for="newclient-etichetta"
                    class="block text-gray-700 text-xs font-bold mb-2"
                  >
                    Etichetta
                  </label>
                  <input
                    id="newclient-etichetta"
                    v-model="formData.email[elemKey]['label']"
                    type="text"
                    class="custom-input"
                    placeholder="Inserisci etichetta"
                  />
                </div>

                <div class="flex items-center mt-8 ml-2">
                  <div class="flex items-baseline">
                    <label class="block text-gray-700 text-sm font-bold mb-2">
                      {{ elem.buttonText }}
                    </label>
                    <a class="cursor-pointer" @click="elem.function(elemKey)">
                      <PlusIcon
                        v-if="elem.button === 'add'"
                        class="w-4 h-4 ml-2.5 text-white rounded-sm bg-theme-41 font-bold"
                      />
                      <MinusIcon
                        v-else
                        class="w-4 h-4 ml-2.5 text-white rounded-sm bg-theme-41 font-bold"
                      />
                    </a>
                  </div>
                  <!-- <div class="flex items-baseline">
                  <label
                    for="newclient-telefono-consenso"
                    class="block text-gray-700 text-xs font-bold mb-2"
                  >
                    Consenso
                  </label>
                  <div class="">
                    <input
                      style="box-shadow: none"
                      id="newclient-telefono-consenso"
                      type="checkbox"
                      class="form-checkbox ml-2 h-4 w-4 text-theme-15 rounded-sm text-sm focus:ring-0 focus:shadow-none"
                      v-model="formData.email[elemKey]['consent']"
                    />
                  </div>
                </div> -->
                </div>
              </div>
            </div>
          </div>
          <!-- end email -->

          <!-- header Altro -->
          <div class="">
            <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
              <h2 class="ml-3 text-base font-bold">Altro</h2>
            </div>

            <!-- first row -->

            <div class="mx-3 flex flex-col justify-between gap-3 lg:flex-row">
              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newclient-note  "
                >
                  Note
                </label>

                <textarea
                  id="newclient-note"
                  v-model="formData['notes']"
                  class="custom-input"
                  type="text"
                  placeholder="Inserisci le note"
                />
              </div>
            </div>

            <!-- second row -->

            <div class="mx-3 flex flex-col justify-between gap-3 lg:flex-row">
              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newclient-tags"
                >
                  Tags
                </label>
                <input
                  id="newclient-tags"
                  v-model="formData['tags']"
                  class="custom-input"
                  type="text"
                  placeholder="Inserisci le tags"
                />
              </div>

              <div class="py-2 flex-1">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newclient-note-interne"
                >
                  Note interne sul cliente
                </label>
                <input
                  id="newclient-note-interne"
                  v-model="formData['internal_notes']"
                  class="shadow border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none placeholder-gray-400"
                  type="text"
                  placeholder="Note interne sul cliente"
                />
              </div>
            </div>
            <div class="mx-3 mt-0.5 flex flex-col justify-end lg:flex-row">
              <p class="text-theme-41 text-xs">
                (ATTENZIONE! saranno visibili sulla scheda del cliente)
              </p>
            </div>
          </div>

          <!-- end altro -->

          <!-- header consensi -->
          <div class="">
            <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
              <h2 class="ml-3 text-base font-bold">Consensi</h2>
            </div>
            <!-- first row -->
            <div class="mx-3 flex flex-col gap-3 lg:flex-row">
              <div class="flex-1 flex items-baseline py-2">
                <p class="block text-gray-700 text-sm font-bold">
                  Acconsento al trattamento dei dati (GDPR 2016/679)
                </p>
                <input
                  id="newclient-gdpr"
                  v-model="formData['gdpr_consent']"
                  class="form-checkbox ml-3 h-3 w-3 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
                  type="checkbox"
                  required
                  style="box-shadow: none"
                />
              </div>
            </div>
          </div>
          <!-- end consensi -->

          <!-- Footer -->

          <div
            class="mt-5 flex flex-col gap-3 px-0 sm:px-10 md:px-24 justify-end lg:gap-0 lg:flex-row lg:px-0"
          >
            <button
              v-if="clientSaved"
              type="button"
              class="orange-btn px-3 py-1 mx-1"
              @click="setClientFullName"
            >
              <FileIcon class="w-4 h-5 mr-1" />Scheda Cliente
              <!-- <ClockIcon class="w-4 h-4 mr-2" />Appuntamento Rapido -->
            </button>

            <button
              type="button"
              class="orange-btn px-3 py-1 mx-1"
              @click.prevent="sendForm()"
            >
              <i class="fas fa-save mr-1"></i>
              {{ buttonPrivacy }}
            </button>
            <button
              type="button"
              class="orange-btn px-3 py-1 mx-1"
              @click.prevent="addPaziente"
            >
              <i v-if="client != null" class="fas fa-save mr-1"></i>
              <i v-if="client == null" class="fas fa-plus mr-1"></i>
              {{ buttonText }}
            </button>
            <button
              type="button"
              class="gray-btn px-3 py-1 ml-1"
              @click="closeNewClient"
            >
              <span class="h-5" />
              Annulla
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div v-if="openWarning">
    <Warning @closeWarning="closeWarning" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import axios from '../../gateway/backend-api'
import flatpickr from 'flatpickr'
import { Italian } from 'flatpickr/dist/l10n/it.js'
import CodiceFiscale from 'codice-fiscale-js'
import Warning from './Warning'
import { mapGetters, mapActions } from 'vuex'
import Places from '../../components/places/Places.vue'
//import router from '@/router'

const defaultInputControl = {
  address: true,
  house_number: true,
  country: true,
  region: true,
  city: true,
  district: true,
  zip_code: true,
  last_name: true,
  last_name_length: true,
  first_name: true,
  first_name_length: true,
  nationality: true,
  gender: true,
  fiscal_code: true,
  fiscal_code_length: true,
  date_of_birth: true,
  ragione_sociale: true,
  vat_number: true,
  vat_number_length: true,
  city_of_birth: true,
  country_of_birth: true,
  email1: true,
  email2: true,
  email3: true,
  phone1: true,
  phone2: true,
  phone3: true,
  prefisso1: true,
  prefisso2: true,
  prefisso3: true
}

export default defineComponent({
  components: {
    Warning,
    Places
    // VueTelInput
  },
  props: {
    title: String,
    client: Object,
    continueClient: Object
  },
  emits: ['edit_close', 'close', 'addPaziente'],
  data() {
    return {
      checkResidency: false,
      showRegione: true,
      checkPhone: '',
      checkMail: '',
      error_message: 'Questo è un campo obbligatorio',
      error_message_cf:
        'Questo è un campo necessario per calcolare il codice fiscale',
      calcoloCF_data: true,
      idToShow: null,
      labelItaly: 'Italia',
      openWarning: false,
      inputcontrol: defaultInputControl,
      value: '',
      clientFullName: '',
      clientSaved: false,
      dataToComplete: false,
      dayData: 0,
      monthData: 0,
      yearData: 0,
      birthplaceCF: 'select nationality',
      siglaProvincia: '',
      isForeign: false,
      isPrivate: true,
      countries: [],
      comune: {},
      elencoComuni: [],
      elencoRegioni: [],
      comuneOptions: [],
      province: [],
      filteredProvince: null,
      phoneConfig: [
        {
          name: 'Telefono',
          type: 'text',
          button: 'add',
          buttonText: 'Aggiungi',
          function: this.addPhone
        }
      ],
      emailConfig: [
        {
          name: 'Email',
          type: 'text',
          button: 'add',
          buttonText: 'Aggiungi',
          function: this.addEmail
        }
      ],
      select_data: {
        nationality: {},
        country_of_birth: {},
        country: ''
      },
      formData: {
        address: null,
        house_number: '',
        region: null,
        city: null,
        district: null,
        zip_code: '',
        id: null,
        last_name: '',
        first_name: '',
        date_of_birth: null,
        gender: '',
        fiscal_code: '',
        ragione_sociale: '',
        nationality: this.labelItaly,
        addressLabel: '',
        vat_number: '',
        city_of_birth: '',
        country_of_birth: this.labelItaly,
        phone: [{ prefisso: '+39', phone: '', label: '' }],
        email: [
          {
            email: '',
            label: ''
          }
        ],
        type: 'customer',
        country: this.labelItaly
      },
      pubblicaAmministrazione: false
    }
  },
  computed: {
    ...mapGetters({
      getPetsDetails: 'anagraficaPazienti/getPetsDetails',
      getComparisonPets: 'anagraficaPazienti/getComparisonPets',
      getCustomersDetails: 'anagraficaPazienti/getCustomersDetails',
      getComparisonCustomer: 'anagraficaPazienti/getComparisonCustomer',
      getTab: 'anagraficaPazienti/getTab',
      getShow: 'anagraficaPazienti/getShow'
    })
  },
  watch: {
    comune: function (elem) {
      this.formData['city_of_birth'] = null
      if (elem != null) {
        // Con API Google
        this.birthplaceCF = elem[1].long_name
        this.formData['city_of_birth'] = elem[1].long_name
        this.provinciaSelected = elem[2].short_name
        this.siglaProvincia = elem[2].short_name
        this.formData['provincia'] = this.provinciaSelected

        this.formData['nationality'] = this.labelItaly
      } else {
        this.birthplaceCF = null
        this.provinciaSelected = null
        this.formData['provincia'] = null
        this.formData['city_of_birth'] = null
      }
    },

    'select_data.country_of_birth': function (elem) {
      this.isForeign = false
      this.formData['country_of_birth'] = ''
      if (elem != null) {
        this.formData['country_of_birth'] = elem
        if (elem != this.labelItaly) {
          this.isForeign = true
        }
      } else {
        this.birthplaceCF = null
        this.comune = null
        this.formData['city_of_birth'] = ''
      }
    },

    'select_data.country': function (elem) {
      //if (elem != null && elem.toLowerCase() != this.labelItaly.toLowerCase()) {
      //  this.showRegione = false
      //} else {
      //  this.showRegione = true
      //}
      this.formData['country'] = elem
    },

    'formData.district'(elem) {
      // quando la provincia di residenza viene selezionata assegno automaticamente la regione
      if (
        elem &&
        elem.regione &&
        this.elencoRegioni &&
        this.elencoRegioni.length !== 0
      )
        this.formData['region'] = elem.regione
    },
    'formData.region'(elem) {
      // quando la provincia di residenza viene selezionata assegno automaticamente la regione
      if (elem && this.province && this.province.length !== 0) {
        //this.formData['country'] = this.labelItaly
        this.formData['region'] = elem

        this.filteredProvince = this.province.filter(
          (el) => el.regione === elem
        )

        if (this.formData['district']) {
          if (!this.filteredProvince.includes(this.formData['district']))
            this.formData['district'] = null
        }
      }
      if (!elem) {
        this.filteredProvince = null
      }
    }
  },

  created() {
    // Se il form è aperto in modalità modifica i campi del form vengono settati con i dati del cliente già esistenti
    if (this.client != null) {
      if (this.client.type === 'Persona giuridica') {
        this.isPrivate = false
      }
      if (this.client.type === 'PA') {
        this.isPrivate = false
        this.pubblicaAmministrazione = true
      }
      if (this.client.ragione_sociale) {
        this.formData['ragione_sociale'] = this.client.ragione_sociale
      }
      if (this.client.tags) {
        this.formData['tags'] = this.client.tags
      }
      if (this.client.internal_notes) {
        this.formData['internal_notes'] = this.client.internal_notes
      }
      if (this.client.address && this.client.address.address) {
        this.buttonText = 'Salva modifiche'
        this.buttonPrivacy = 'Stampa privacy'
      } else {
        this.buttonText = 'Aggiungi paziente'
        this.buttonPrivacy = 'Salva e stampa privacy'
      }

      this.idToShow = this.client.id
      this.formData['last_name'] = this.client.last_name
      this.formData['first_name'] = this.client.first_name

      if (this.client.birth_info) {
        this.checkCountryOfBirth(
          this.client.birth_info.country_of_birth || null
        )
        this.formData['date_of_birth'] = this.$FormatDateEnglish(
          this.client.birth_info.birth_date
        )
        this.formData['city_of_birth'] = this.client.birth_info.city
      } else {
        this.formData['date_of_birth'] = this.$FormatDateEnglish(
          this.client.date_of_birth
        )
      }
      if (this.client.address && this.client.address.address) {
        // let tmp = this.client.address.address
        // if (this.client.address.house_number)
        //   tmp += ' ' + this.client.address.house_number
        // if (this.client.address.city) tmp += ', ' + this.client.address.city
        // if (this.client.address.country)
        //   tmp += ', ' + this.client.address.country

        this.formData['address'] = this.client.address.address
        this.formData['city'] = this.client.address.city || ''
        this.formData['region'] = this.client.address.region || ''
        this.formData['zip_code'] = this.client.address.zip_code || ''
        this.formData['district'] = this.client.address.district || ''
        this.birthplaceCF =
          this.client.address.city && this.client.address.city.nome
            ? this.client.address.city.nome
            : ''

        this.formData['country'] = this.client.address.country || ''
        this.select_data['country'] = this.formData['country'] || ''
        this.formData['house_number'] = this.client.address.house_number
          ? this.client.address.house_number
          : ''

        let address_formatted = this.client.address.address
          ? this.client.address.address
          : ''

        address_formatted += this.client.address.house_number
          ? ', ' + this.client.address.house_number
          : ''
        address_formatted += this.client.address.city
          ? ', ' + this.client.address.city
          : ''
        address_formatted += this.client.address.district
          ? ', ' + this.client.address.district
          : ''
        address_formatted += this.client.address.country
          ? ', ' + this.client.address.country
          : ''

        this.addressLabel = address_formatted

        // console.log(
        //   date_formatted,
        //   this.client.address.address
        //     ? this.client.address.address
        //     : '' + ', ' + this.client.address.house_number
        //     ? this.client.address.house_number
        //     : '' + ', ' + this.client.address.city
        //     ? this.client.address.city
        //     : '' + ', ' + this.client.address.district
        //     ? this.client.address.district
        //     : '' + ', ' + this.client.address.country
        //     ? this.client.address.country
        //     : ''
        // )
      } else {
        this.formData['address'] = this.client.address
        this.formData['city_of_birth'] = this.client.city_of_birth
        this.formData['country_of_birth'] = this.client.country_of_birth
        this.formData['city'] = this.client.city
        this.formData['region'] = this.client.region
        this.formData['zip_code'] = this.client.zip_code
        this.formData['district'] = this.client.district
        this.formData['country'] = this.client.country
        this.select_data.country = this.client.country
        this.formData['house_number'] = this.client.house_number
        this.birthplaceCF = this.client?.city?.nome
          ? this.client.house_number
          : ''

        let address_formatted = this.client.address ? this.client.address : ''

        address_formatted += this.client.house_number
          ? ', ' + this.client.house_number
          : ''
        address_formatted += this.client.city ? ', ' + this.client.city : ''
        address_formatted += this.client.district
          ? ', ' + this.client.district
          : ''
        address_formatted += this.client.country
          ? ', ' + this.client.country
          : ''

        this.addressLabel = address_formatted
      }

      this.formData['gender'] = this.client.gender
      this.formData['fiscal_code'] = this.client.fiscal_code
      this.formData['vat_number'] =
        this.client.vat_number && typeof this.client.vat_number == 'object'
          ? this.client.vat_number.vat_number
          : this.client.vat_number
      this.formData['pec'] = this.client.pec
      this.formData['codice_destinatario'] = this.client.codice_destinatario
      this.formData['codice_previdenza_estero'] =
        this.client.codice_previdenza_estero

      this.formData['notes'] = this.client.notes
      if (this.client.country_of_birth == this.labelItaly) {
        if (this.client && this.client.birth_info) {
          this.formData['city_of_birth'] = this.client.birth_info.city
        }

        this.provinciaSelected = this.client.district
      } else {
        // Cliente straniero?
        this.formData['nationality'] = this.client.nationality
      }

      if (this.client.phones && this.client.phones['phone1']) {
        for (let i = 1; this.client.phones['phone' + i] != null; i++) {
          if (i > 1) {
            this.addPhone()
          }
          if (this.client.phones['phone' + i].phone === null) {
            this.checkPhone = true
          }

          this.formData.phone[i - 1]['phone'] =
            this.client.phones['phone' + i].phone
          this.formData.phone[i - 1]['prefisso'] =
            this.client.phones['phone' + i].prefisso
          this.formData.phone[i - 1]['label'] =
            this.client.phones['phone' + i].label
        }
      } else {
        this.checkPhone = true
      }

      if (this.client.emails && this.client.emails['email1']) {
        for (let i = 1; this.client.emails['email' + i] != null; i++) {
          if (i > 1) {
            this.addEmail()
          }
          if (this.client.emails['email' + i].email === null) {
            this.checkMail = true
          }
          this.formData.email[i - 1]['email'] = this.client.emails['email' + i]
            .email
            ? this.client.emails['email' + i].email
            : ''
          this.formData.email[i - 1]['label'] = this.client.emails['email' + i]
            .label
            ? this.client.emails['email' + i].label
            : ''
        }
      } else {
        this.checkMail = true
      }

      if (this.client.gdpr_consent == 1) {
        this.formData['gdpr_consent'] = true
      }
    } else {
      this.buttonText = 'Aggiungi paziente'
      this.buttonPrivacy = 'Salva e stampa privacy'
    }
  },

  mounted() {
    //get the countries

    if (this.client) {
      this.formData['nationality'] = this.client.nationality
    } else {
      this.formData['nationality'] = this.labelItaly
      this.formData['country'] = this.labelItaly
    }

    flatpickr.localize(Italian)
    flatpickr('.date', {
      maxDate: 'today',
      dateFormat: 'd/m/Y',
      allowInput: true,
      allowInvalidPreload: false
    })

    this.switchFocus(1)
  },

  methods: {
    ...mapActions({
      getPetAction: 'anagraficaPazienti/getPetAction',
      getCustomerAction: 'anagraficaClienti/getCustomerAction'
    }),
    checkExistingFiscalCode() {
      if (
        (this.formData['fiscal_code'] &&
          this.formData['fiscal_code'] !== '' &&
          this.formData['nationality'] === 'Italia' &&
          this.formData['fiscal_code'].length === 16) ||
        (this.formData['vat_number'] &&
          this.formData['vat_number'].length !== 0) ||
        (this.formData['fiscal_code'] &&
          this.formData['fiscal_code'] !== '' &&
          this.formData['nationality'] !== 'Italia')
      ) {
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/customer/check-fiscal-code',
            {
              fiscal_code:
                this.formData['fiscal_code'] &&
                this.formData['fiscal_code'] !== ''
                  ? this.formData['fiscal_code']
                  : null,
              vat_number:
                this.formData['vat_number'] &&
                this.formData['vat_number'] !== ''
                  ? this.formData['vat_number']
                  : null
            }
          )
          .then((res) => {
            if (
              this.formData['fiscal_code'] !== null &&
              this.formData['fiscal_code'] !== ''
            ) {
              this.$store.dispatch('commonComponents/openConfirmModal', {
                title: 'Attenzione',
                subtitle:
                  'Il Codice Fiscale è già presente nel sistema. Vuoi Andare nella pagina cliente?',
                background: false,
                confirm: () => {
                  const client = res.data
                  this.$goToCustomer(client)

                  this.$emit('close')
                },
                decline: () => {
                  this.formData['fiscal_code'] = ''
                }
              })
            }
          })
      }
    },
    clearResidency() {
      this.formData['house_number'] = ''
      this.formData['country'] = ''
      this.formData['region'] = ''
      this.formData['district'] = ''
      this.formData['city'] = ''
      this.formData['address'] = ''
      this.formData['zip_code'] = ''
      this.formData['lat'] = ''
      this.formData['lng'] = ''
    },
    // Method called by Places emit to bind the address from the Google API to our residency's fields
    residencyChanged: function (elem) {
      console.log(elem)
      this.clearResidency()
      this.formData['lat'] = elem.lat
      this.formData['lng'] = elem.lng
      let includeRoute = false
      for (let i = 0; i < elem.length; i++) {
        if (elem[i].types.includes('route')) includeRoute = true
        if (elem[i].types.includes('street_number'))
          this.formData['house_number'] = elem[i].long_name
      }
      if (includeRoute) {
        elem.find((element) => {
          if (element.types.includes('country')) {
            this.formData['country'] = element.long_name
            this.select_data['country'] = element.long_name
          }
          if (element.types.includes('administrative_area_level_1')) {
            this.formData['region'] = element.long_name
          }
          if (element.types.includes('administrative_area_level_2')) {
            this.formData['district'] = element.short_name
          }
          if (element.types.includes('locality')) {
            this.formData['city'] = element.long_name
          }
          if (element.types.includes('route')) {
            this.formData['address'] = element.long_name
          }
          if (element.types.includes('postal_code')) {
            this.formData['zip_code'] = element.long_name
          }
        })
      }
    },
    nationalityChanged: function (elem) {
      // If the nationality is Italy set the filter at the city's field
      this.formData['nationality'] = elem[0].long_name
    },
    cityChanged: function (elem) {
      this.formData['city_of_birth'] = elem
      this.comune = elem
    },
    countryChanged: function (elem) {
      this.select_data['country_of_birth'] = elem[0].long_name
    },
    formValidation() {
      let is_correct,
        var_length,
        major_equal = false,
        count_error = 0

      var tag_html_label = [
        'country',
        'city',
        'zip_code',
        'address',
        'house_number'
      ]

      // Aggiunge ai tag da controllare nome e cognome nel caso di persona fisica, e raggione sociale e part iva nel caso di persona giuridica

      if (this.isPrivate) {
        tag_html_label.push('last_name', 'first_name')
      } else {
        tag_html_label.push('ragione_sociale', 'vat_number')

        // Controlla i tag della partuita iva si è presente, se no controlla il codice fiscale
        if (
          this.formData['vat_number'] &&
          this.formData['vat_number'] &&
          this.formData['vat_number'].length > 0
        ) {
          tag_html_label.splice(tag_html_label.indexOf('fiscal_code'), 1)
          this.inputcontrol['fiscal_code_length'] = true
          this.inputcontrol['fiscal_code'] = true
        } else if (this.formData['fiscal_code']?.length > 0) {
          tag_html_label.splice(tag_html_label.indexOf('vat_number'), 1)
          this.inputcontrol['vat_number_length'] = true
          this.inputcontrol['vat_number'] = true
        }
      }
      // Aggiunge controllo cod fisc se la nazionalità è italiana
      if (
        this.formData['nationality'] &&
        this.formData['nationality'].toLowerCase() ===
          this.labelItaly.toLowerCase() &&
        this.formData['nationality'].length > 0 &&
        this.isPrivate
      ) {
        tag_html_label.push('fiscal_code')
      }

      // Se non è straniero fa il controllo su regione e provincia
      if (
        (this.select_data['country'] &&
          this.select_data['country'].toLowerCase() ===
            this.labelItaly.toLowerCase()) ||
        !this.select_data['country'] ||
        (this.select_data['country'] &&
          this.select_data['country'].length === 0)
      ) {
        tag_html_label.push('region', 'district')
      }

      // controllo  Per ogni tag aggiunto

      tag_html_label.forEach((html_tag) => {
        major_equal = true

        switch (html_tag) {
          case html_tag.match('fiscal_code')?.input:
            var_length = 16
            major_equal = false
            break
          case html_tag.match('first_name')?.input:
          case html_tag.match('last_name')?.input:
            var_length = 2
            break
          default:
            var_length = 1
        }

        if (
          this.formData[html_tag] !== null &&
          this.formData[html_tag] &&
          this.formData[html_tag].length !== undefined
        ) {
          is_correct = this.formData[html_tag].length
          is_correct =
            is_correct == var_length
              ? true
              : major_equal
              ? is_correct >= var_length
                ? true
                : false
              : false
        } else {
          is_correct = this.formData[html_tag]
          is_correct = is_correct ? true : false
        }
        if (this.inputcontrol[html_tag + '_length'] !== undefined)
          this.inputcontrol[html_tag + '_length'] = is_correct
        this.inputcontrol[html_tag] = is_correct

        if (!is_correct) count_error++
      })

      if (this.inputcontrol.house_number == false) {
        this.checkResidency = true
      }

      return count_error > 0 ? false : true
    },

    falseInputsControl() {
      var obj = this.inputcontrol

      const isAnyKeyValueFalse = (obj) =>
        !!Object.keys(obj).find((k) => !obj[k])

      if (isAnyKeyValueFalse(obj) == true) {
        document.getElementById('new-client-title').scrollIntoView()
        this.dataToComplete = true
        this.openWarning = true
      } else {
        this.dataToComplete = false
      }
    },
    validateEmail() {
      let i = 0
      this.formData.email.forEach((email_element) => {
        if (email_element != null) {
          this.inputcontrol['email' + (i + 1)] = true
          if (
            !email_element['email']
              .toLowerCase()
              .match(
                /^[a-zA-Z0-9.!#$%&'*+\\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
              ) &&
            this.checkMail == false
          ) {
            this.inputcontrol['email' + (i + 1)] = false
          }
        }
        i++
      })
    },

    validatePhone() {
      let i = 0
      this.formData.phone.forEach((phone_element) => {
        if (phone_element.phone != '') {
          this.inputcontrol['phone' + (i + 1)] = true
          if (isNaN(phone_element.phone) && this.checkPhone == false) {
            this.inputcontrol['phone' + (i + 1)] = false
          } else {
            this.inputcontrol['phone' + (i + 1)] = true
          }
        } else {
          if (this.checkPhone == false) {
            this.inputcontrol['phone' + (i + 1)] = false
          } else {
            this.inputcontrol['phone' + (i + 1)] = true
          }
        }
        i++
      })

      for (let i = 0; i < this.formData.phone.length; i++) {
        if (this.formData.phone[i]['prefisso']) {
          {
            if (/^\+[1-9]\d{1,14}$/.test(this.formData.phone[i]['prefisso'])) {
              this.inputcontrol['prefisso' + (i + 1)] = true
            } else {
              this.inputcontrol['prefisso' + (i + 1)] = false
            }
          }
        }
      }
    },
    setClientFullName() {
      axios
        .get(
          '/' + localStorage.getItem('clinic') + '/customer/' + this.idToShow
        )
        .then((res) => {
          const customer = res.data
          this.$goToCustomer(customer)
          this.$emit('close')
        })
    },
    findCity(city) {
      var tempCity = this.elencoComuni.find((elem) => elem.nome == city)
      this.comune = tempCity
    },
    findProvincia(city) {
      var provinciaTemp = this.province.find(
        (value) => value.codice_provincia == city.codice_provincia
      )
      if (provinciaTemp != undefined) {
        this.provinciaSelected = provinciaTemp.nome
        this.siglaProvincia = provinciaTemp.sigla
        this.formData['provincia'] = this.provinciaSelected
      }
    },
    closeWarning() {
      this.openWarning = false
    },

    birthPlaceControl() {
      let is_correct,
        var_length,
        major_equal = false,
        count_error = 0

      var tag_html_label = [
        'last_name',
        'first_name',
        'country_of_birth',
        'nationality',
        'gender',
        'date_of_birth',
        'city_of_birth'
      ]

      if (
        this.formData['country_of_birth'] != this.labelItaly &&
        this.formData['country_of_birth'].length > 0
      ) {
        this.birthplaceCF = this.formData['country_of_birth']
        this.siglaProvincia = 'EE'
        tag_html_label.splice(tag_html_label.indexOf('city_of_birth'), 1)
        this.inputcontrol['city_of_birth'] = true
      }

      tag_html_label.forEach((html_tag) => {
        major_equal = true

        switch (html_tag) {
          case html_tag.match('first_name')?.input:
          case html_tag.match('last_name')?.input:
            var_length = 2
            break
          default:
            var_length = 1
        }

        if (
          this.formData[html_tag] !== null &&
          this.formData[html_tag].length !== undefined
        ) {
          is_correct = this.formData[html_tag].length
          is_correct =
            is_correct == var_length
              ? true
              : major_equal
              ? is_correct >= var_length
                ? true
                : false
              : false
        } else {
          is_correct = this.formData[html_tag]
          is_correct = is_correct ? true : false
        }
        if (this.inputcontrol[html_tag + '_length'] !== undefined)
          this.inputcontrol[html_tag + '_length'] = is_correct
        this.inputcontrol[html_tag] = is_correct

        if (!is_correct) count_error++
      })
      this.calcoloCF_data = true
      if (count_error > 0) this.calcoloCF_data = false

      return this.calcoloCF_data
    },
    formatDate() {
      let dateInEpoch = this.$FormatEpoch(this.formData['date_of_birth'])
      console.log('Data formattata ', dateInEpoch)
    },

    inputChanged(value) {
      if (value.length > 2) {
        this.comuneOptions = this.elencoComuni.filter((elem) => {
          if (elem.nome.toLowerCase().includes(value.toLowerCase()))
            return elem.nome
        })
      } else {
        this.comuneOptions = []
      }
    },

    calcolaCF() {
      if (!this.birthPlaceControl()) return false

      let newDate = new Date(
        this.$FormatEpochEnglish(this.formData['date_of_birth']) * 1000
      )

      this.dayData = newDate.getDate() // x.substring(0, 2)
      this.monthData = newDate.getMonth() + 1 // x.substring(3, 5)
      this.yearData = newDate.getFullYear() // x.substring(7)
      var cf = new CodiceFiscale({
        name: this.formData['first_name'],
        surname: this.formData['last_name'],
        gender: this.formData['gender'],
        day: this.dayData,
        month: this.monthData,
        year: this.yearData,
        birthplace: this.birthplaceCF.toLowerCase(),
        birthplaceProvincia: this.siglaProvincia.toLowerCase()
      })

      this.formData['fiscal_code'] = cf.code
      this.checkExistingFiscalCode()
    },

    closeNewClient() {
      this.$emit('close')
    },

    addPhone: function () {
      if (this.formData.phone.length > 2) {
        alert('Puoi aggiungere solo 3 campi')
      } else {
        const newPhone = {
          name: 'Telefono',
          type: 'text',
          button: 'remove',
          buttonText: 'Rimuovi',
          function: this.removePhone
        }
        this.formData.phone.push({ phone: '', label: '', prefisso: '+39' })
        this.phoneConfig.push(newPhone)
      }
    },
    removePhone: function (elemKey) {
      this.formData.phone.splice(elemKey, 1)
      this.phoneConfig.splice(elemKey, 1)
    },
    addEmail: function () {
      if (this.formData.email.length > 2) {
        alert('Puoi aggiungere solo 3 campi')
      } else {
        const newEmail = {
          name: 'Email',
          type: 'text',
          button: 'remove',
          buttonText: 'Rimuovi',
          function: this.removeEmail
        }
        this.formData.email.push({ email: '', label: '' })
        this.emailConfig.push(newEmail)
      }
    },
    removeEmail: function (elemKey) {
      this.formData.email.splice(elemKey, 1)
      this.emailConfig.splice(elemKey, 1)
    },
    checkNationality(elem) {
      this.isForeign = false
      if (elem != null) {
        this.formData['nationality'] = elem
        if (elem != this.labelItaly) {
          this.isForeign = true
        }
      }
    },
    checkCountryOfBirth(elem) {
      this.isForeign = false
      if (elem != null) {
        this.formData['country_of_birth'] = elem
        if (elem != this.labelItaly) {
          this.isForeign = true
        }
      }
    },
    sendForm: function () {
      if (!this.checkForm()) return false
      let paziente = this.createNewClient()
      if (this.client == null) {
        this.clientSaved = true
        axios
          .post('/' + localStorage.getItem('clinic') + '/customer', paziente)
          .then((res) => {
            axios
              .get(
                '/' +
                  localStorage.getItem('clinic') +
                  '/customer/' +
                  res.data.id
              )
              .then((res) => {
                this.getCustomerAction(res.data)
              })

            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Dati inseriti con successo!'
            })
            this.idToShow = res.data.id

            this.handleShowPdfPrivacy(res.data)

            this.$emit('close')
          })
          .catch(() => {
            // this.$store.dispatch('commonComponents/openMessageModal', {
            //   title: 'Attenzione',
            //   subtitle: err.response.data.message,
            //   background: false
            // })
          })
      } else {
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/customer/' +
              this.client.id +
              '/edit',
            paziente
          )
          .then(() => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Cliente modificato con successo!'
            })
            this.$emit('edit_close')
            this.handleShowPdfPrivacy(this.client)
          })
      }
    },

    addPaziente() {
      if (!this.checkForm()) return false
      let paziente = this.createNewClient()
      if (this.client == null) {
        if (this.idToShow != null) {
          axios
            .get(
              '/' +
                localStorage.getItem('clinic') +
                '/customer/' +
                this.idToShow
            )
            .then((res) => {
              const customer = res.data
              this.$emit('addPaziente', customer, this.dataToComplete)
            })
        } else {
          this.$emit('addPaziente', paziente, this.dataToComplete)
        }
      } else {
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/customer/' +
              this.client.id +
              '/edit',
            paziente
          )
          .then(() => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Cliente modificato con successo!'
            })
            this.$emit('edit_close')
          })
      }
    },
    switchFocus(value) {
      console.log(value)
    },
    checkForm() {
      let check_error = 0
      this.validateEmail()
      this.validatePhone()

      if (!this.formValidation()) check_error++
      this.falseInputsControl()
      if (!this.checkInputControls()) return false
      if (check_error > 0) return false
      return true // todo: chiedere a giuseppe se va bene
    },
    checkInputControls() {
      const to_check = [
        'email1',
        'email2',
        'email3',
        'phone1',
        'phone2',
        'phone3'
      ]
      let correct = true
      to_check.forEach((elem) => {
        if (this.inputcontrol[elem] === false) {
          correct = false
        }
      })
      return correct
    },
    createNewClient() {
      let phones = {}
      for (let i = 0; i < this.formData.phone.length; i++) {
        phones['phone' + (i + 1)] = this.formData.phone[i]
      }

      let emails = {}
      if (this.checkMail === true) {
        emails['email1'] = null
      } else {
        for (let i = 0; i < this.formData.email.length; i++) {
          emails['email' + (i + 1)] = this.formData.email[i]
        }
      }

      let paziente = { ...this.formData }
      console
      if (this.formData['gdpr_consent'] == null) {
        paziente.gdpr_consent = false
      }
      let tempDate = this.$FormatEpochEnglish(paziente.date_of_birth)
      paziente.date_of_birth = tempDate
      let tempPhones = {}
      let tempEmails = {}
      for (let i = 0; paziente.phone[i] != null; i++) {
        tempPhones['phone' + (i + 1)] = paziente.phone[i]
      }
      for (let i = 0; paziente.email[i] != null; i++) {
        tempEmails['email' + (i + 1)] = paziente.email[i]
      }

      if (this.checkPhone === true) {
        paziente.phones = { phone1: null }
      } else {
        paziente.phones = tempPhones
      }

      if (this.checkMail === true) {
        paziente.emails = { email1: null }
      } else {
        paziente.emails = tempEmails
      }

      if (this.isPrivate) {
        paziente.type = 'Persona fisica'
      } else {
        if (this.pubblicaAmministrazione === true) {
          paziente.type = 'PA'
        } else {
          paziente.type = 'Persona giuridica'
        }
      }
      delete paziente.phone
      delete paziente.email
      // paziente.vat_number = null
      if (paziente.fiscal_code === '') paziente.fiscal_code = null // imposta null su fiscal_code se vuoto
      //if (paziente.vat_number === '') paziente.vat_number = null // imposta null su vat_number_id se vuoto
      return paziente
    },
    handleShowPdfPrivacy(customer) {
      let data_to_send = {}
      if (customer.privacy) {
        data_to_send['privacy_id'] = customer.privacy.id
      }
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios({
        url:
          '/' +
          localStorage.getItem('clinic') +
          '/customer/' +
          customer.id +
          '/privacy/pdf',
        method: 'POST',
        data: data_to_send
      })
        .then(async (response) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          const base64Response = await fetch(
            `data:application/pdf;base64,${response.data.pdf}`
          )

          const url = window.URL.createObjectURL(
            new Blob([await base64Response.blob()])
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            'Privacy_' + customer.last_name + '_' + customer.first_name + '.pdf'
          )
          document.body.appendChild(link)
          this.$store.dispatch('commonComponents/openPdfModal', {
            title: customer.ragione_sociale
              ? 'Privacy_' + customer.ragione_sociale.replace(/\s/g, '_')
              : 'Privacy_' + customer.last_name + '_' + customer.first_name,
            link: url,
            background: false,
            confirmations: [
              {
                title: 'Consenso marketing',
                description: '',
                options: [
                  {
                    label: 'Accetto',
                    value: true
                  },
                  {
                    label: 'Non accetto',
                    value: false
                  }
                ],
                onChange: (res) => {
                  axios
                    .post(
                      '/' +
                        localStorage.getItem('clinic') +
                        '/customer/' +
                        customer.id +
                        '/consents/save',
                      {
                        marketing: res == 'true' ? true : false
                      }
                    )
                    .then(() => {
                      console.log('then')
                    })
                    .catch(() => {
                      console.log('catch')
                    })
                }
              }
            ],
            print: () => {
              console.log('PRINT')
            },
            download: () => {
              link.click()
            },
            xml: null,
            table_reference: 'privacy',
            id_reference: response.data.privacy_id,
            pdf_signed:
              customer.privacy && customer.privacy.status === 'signed',
            client: customer,
            sign_done: () => {
              this.$emit('edit_close')
            }
          })
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Errore dati utente. Controllare indirizzo di residenza'
          })
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    }
  }
})
</script>

<style scoped>
.notapproved {
  border: 1px solid #ff8f1c;
}

/* >>> {
  --vs-controls-size: 0.7;
  --vs-controls-color: #94a3b8;
  --vs-border-color: none;
  --vs-dropdown-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
  --vs-actions-padding: 1px 0 1px -2px;
  --vs-dropdown-option--active-color: #333435;
  --vs-dropdown-option-padding: 3px 20px;
  --vs-dropdown-option--active-bg: #edf2f7;
  --vs-selected-bg: #edf2f7;
  --vs-selected-color: #333435;
  --vs-dropdown-option--active-bg: #aebfcf;
  --vs-search-input-placeholder-color: #d4d8e1;
  --vs-font-size: 0.8 rem;
  --vs-transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
  --vs-transition-duration: 150ms;
} */
</style>
