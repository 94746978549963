<template>
  <!-- Main modal -->
  <div
    id="defaultModal"
    class="absolute z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
    style="top: 0%; z-index: 1070"
  >
    <div
      class="relative top-12 p-5 mx-auto overflow-scroll scrollbar-none border w-4/5 shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->

      <button type="button" class="closing-btn" @click="$emit('close')">
        <svg
          class="w-4 h-4"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <!-- Modal header -->
      <div class="">
        <h3 class="modal-title w-full mt-8">
          {{ toModify ? 'Modifica Vaccino' : 'Nuovo Vaccino' }}
        </h3>
      </div>
      <!-- Modal body -->

      <div class="flex flex-col content-start mb-1">
        <div class="flex flex-col md:flex-row gap-6">
          <div v-if="!selectedClient" id="select-utente" class="flex-1 p-3">
            <label class="font-semibold text-theme-43">
              SELEZIONARE UN UTENTE
            </label>
            <div class="mt-3 relative">
              <div class="">
                <input
                  v-model="searchBarClient"
                  type="text"
                  class="custom-input pl-10 rounded-xl py-2 z-10"
                  placeholder="Ricerca rapida nell'anagrafica"
                  @click.stop="dropDownSearchClient"
                />
                <svg
                  class="text-theme-44 fill-current w-6 absolute top-1 left-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 54.3 54.4"
                  @click="toggleModal"
                >
                  <path
                    d="M16.4 4.7c9.2-7 22.3-6.1 30.8 2.1 8.4 8.1 9.4 21.1 2.4 31.1-5.5 7.9-19.5 14.2-33.5 4.9-3.2 3.2-6.5 6.5-9.7 9.8-2 2-3.8 2.3-5.4.8-1.6-1.5-1.4-3.4.7-5.6.3-.4.7-.7 1.1-1.1 2.9-2.9 5.9-5.8 8.7-8.6-8.7-12.9-4.1-26.5 4.9-33.4Zm31.2 19c0-9.2-7.8-17-17.2-17-9.5 0-17 7.7-17 17.3 0 9.4 7.6 16.9 17.2 16.9 9.5 0 17-7.6 17.1-17.3Z"
                  />
                </svg>
              </div>
              <SearchDrop
                v-show="isActiveSearchClient === true"
                class="z-50 search-result bg-theme-44 bg-opacity-80 overflow-scroll scrollbar-none rounded-xl absolute invisible opacity-0 mt-4 ml-3 py-4 px-3 right-0 w-4/5 top-6"
                :filter-categories="['customer']"
                :pet-invoice="petInvoice"
                :filter="searchBarClient"
                @childToParent="onChildClickClient"
                @closeDropDownSearch="closeDropDownSearchClient"
              />
            </div>
          </div>
          <div
            v-if="selectedClient"
            class="flex-1 px-1 py-2 border-2 border-theme-44 border-opacity-10 shadow-md rounded-md"
          >
            <div
              class="bg-theme-2 rounded-md p-1 flex justify-between items-center mb-2"
            >
              <h2 class="font-bold nunito text-lg pl-1">Dati Proprietario</h2>
              <a
                class="cursor-pointer text-theme-41 fill-current"
                @click="removeSelectedClient"
              >
                <i class="fas fa-times text-center p-3" />
              </a>
            </div>

            <div class="flex flex-col ml-5">
              <div>
                <label class="form-label font-medium text-base col-span-1"
                  >Cliente:
                </label>
                <p class="form-label font-medium text-gray-600 ml-2 col-span-2">
                  {{
                    selectedClient.first_name + ' ' + selectedClient.last_name
                  }}
                </p>
              </div>
              <div>
                <label class="form-label font-medium text-base col-span-1"
                  >Indirizzo:
                </label>
                <p class="form-label font-medium text-gray-600 ml-2 col-span-2">
                  {{
                    selectedClient.address
                      ? selectedClient.address.address +
                        ' (' +
                        selectedClient.address.district +
                        ')'
                      : ''
                  }}
                </p>
              </div>
              <div>
                <label class="form-label font-medium text-base col-span-1"
                  >Email:
                </label>
                <p class="form-label font-medium text-gray-600 ml-2 col-span-2">
                  {{
                    selectedClient.emails
                      ? selectedClient.emails.email1.email
                      : '--'
                  }}
                </p>
              </div>
              <div>
                <label class="form-label font-medium text-base col-span-1"
                  >Codice Fiscale:
                </label>
                <p class="form-label font-medium text-gray-600 ml-2 col-span-2">
                  {{ selectedClient.fiscal_code }}
                </p>
              </div>
            </div>
          </div>

          <!-- SELEZIONARE CLIENTE nuevo -->
          <div
            v-if="!selected"
            id="select-client
            "
            class="flex-1 p-3"
          >
            <label class="font-semibold text-theme-43">
              SELEZIONARE UN CLIENTE
            </label>
            <div class="mt-3 relative">
              <div class="">
                <input
                  v-model="searchBarPet"
                  type="text"
                  class="custom-input pl-10 rounded-xl py-2 z-10"
                  placeholder="Ricerca rapida nell'anagrafica"
                  @click.stop="dropDownSearchPet"
                />
                <svg
                  class="text-theme-44 fill-current w-6 absolute top-1 left-2"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 54.3 54.4"
                  @click="toggleModal"
                >
                  <path
                    d="M16.4 4.7c9.2-7 22.3-6.1 30.8 2.1 8.4 8.1 9.4 21.1 2.4 31.1-5.5 7.9-19.5 14.2-33.5 4.9-3.2 3.2-6.5 6.5-9.7 9.8-2 2-3.8 2.3-5.4.8-1.6-1.5-1.4-3.4.7-5.6.3-.4.7-.7 1.1-1.1 2.9-2.9 5.9-5.8 8.7-8.6-8.7-12.9-4.1-26.5 4.9-33.4Zm31.2 19c0-9.2-7.8-17-17.2-17-9.5 0-17 7.7-17 17.3 0 9.4 7.6 16.9 17.2 16.9 9.5 0 17-7.6 17.1-17.3Z"
                  />
                </svg>
              </div>
              <SearchDrop
                v-show="isActiveSearchPet === true"
                class="z-50 search-result bg-theme-44 bg-opacity-80 overflow-scroll scrollbar-none rounded-xl absolute invisible opacity-0 mt-4 ml-3 py-4 px-3 right-0 w-4/5 top-6"
                :filter-categories="['pet']"
                :customer-invoice="clientInvoice"
                :filter="searchBarPet"
                @childToParent="onChildClickPet"
                @closeDropDownSearch="closeDropDownSearchPet"
              />
            </div>
          </div>
          <div
            v-if="selected"
            class="flex-1 px-1 py-2 border-2 border-theme-44 border-opacity-10 shadow-md rounded-md"
          >
            <div
              class="bg-theme-2 rounded-md p-1 flex justify-between items-center mb-2"
            >
              <h2 class="font-bold nunito text-lg pl-1">Dati Pet</h2>
              <a
                class="cursor-pointer text-theme-41 fill-current"
                @click="removeSelectedPet"
              >
                <i class="fas fa-times text-center p-3" />
              </a>
            </div>
            <div class="flex flex-col ml-5">
              <div>
                <label class="form-label font-medium text-base col-span-1"
                  >Cliente:
                </label>
                <p class="form-label font-medium text-gray-600 ml-2 col-span-2">
                  {{ selected.name }}
                </p>
              </div>
              <div>
                <label class="form-label font-medium text-base col-span-1"
                  >Indirizzo:
                </label>
                <p class="form-label font-medium text-gray-600 ml-2 col-span-2">
                  {{
                    selected.owners ? selected.owners[0].address.address : ''
                  }}
                </p>
              </div>
              <div>
                <label class="form-label font-medium text-base col-span-1"
                  >Sesso:
                </label>
                <p class="form-label font-medium text-gray-600 ml-2 col-span-2">
                  {{ selected.gender }}
                </p>
              </div>
              <div>
                <label class="form-label font-medium text-base col-span-1"
                  >Microchip:
                </label>
                <p class="form-label font-medium text-gray-600 ml-2 col-span-2">
                  {{ selected.microchip }}
                </p>
              </div>
            </div>
          </div>
        </div>
        <!-- Inizio layout Grid -->
        <div class="grid grid-cols-8 gap-2 my-3">
          <!-- Inizio Sezione Ricerca -->

          <!-- Fine Sezione Ricerca -->

          <!-- Inizio  inserimento dati -->
          <div class="col-span-8 md:col-span-4 flex flex-col">
            <label class="form-label">Medico: </label>
            <v-select
              v-model="medicoRef"
              class="w-full"
              label="last_name"
              placeholder="Selezionare il medico"
              :options="staff"
            >
              <template #selected-option="option">
                {{ option.first_name }} {{ option.last_name }}
              </template>
              <template #option="option">
                {{ option.last_name }} {{ option.first_name }}
              </template></v-select
            >
          </div>
          <div class="col-span-8 md:col-span-4 flex flex-col">
            <label class="form-label">Vaccino: </label>
            <v-select
              ref="inputDrugs"
              v-model="vaccinoRef"
              :options="drugs"
              label="name"
              placeholder="Cerca denominazione"
              @search="searchDrugs"
            >
              <template #option="option">
                <table class="mb-2">
                  <tr>
                    <th>
                      {{ option.name }} - {{ option.lot ? option.lot : '' }}
                    </th>
                  </tr>
                  <tr>
                    <td>
                      {{ option.expiry_date ? option.expiry_date : '' }}
                    </td>
                  </tr>
                </table>
              </template>
            </v-select>
          </div>
          <div class="flex flex-col col-span-8 xs:col-span-4 md:col-span-2">
            <label class="form-label">Lotto: </label>
            <div class="custom-input">
              <label class="form-label">
                {{ vaccinoRef ? vaccinoRef.lot : '--' }}
              </label>
            </div>
          </div>

          <!-- <input
                type="text"
                class="form-control p-2 col-span-2 disabled"
                required
                v-model="lottoRef"
              /> -->

          <div class="flex flex-col col-span-8 xs:col-span-4 md:col-span-2">
            <label class="form-label">Scadenza Lotto: </label>
            <div class="custom-input">
              <label class="form-label">
                {{
                  vaccinoRef ? $FormatDate(vaccinoRef.expiry_date) : '--'
                }}</label
              >
            </div>
          </div>

          <!-- <input
                type="date"
                class="form-control p-2 col-span-2"
                required
                v-model="scadenzaRef"
              /> -->

          <div class="flex flex-col col-span-8 xs:col-span-4 md:col-span-2">
            <label class="form-label">Data vaccinazione: </label>

            <input
              v-model="dataVacc"
              type="date"
              class="custom-input"
              required
            />
          </div>
          <div class="flex flex-col col-span-8 xs:col-span-4 md:col-span-2">
            <label class="form-label">Richiamo: </label>

            <input
              v-model="dataRef"
              type="date"
              class="custom-input"
              required
            />
          </div>

          <!-- <label class="form-label font-medium text-base p-2 m-2 col-span-2"
                >Eseguito in altra struttura
              </label>
              <input
                type="checkbox"
                class="form-check-input p-2 col-span-1"
                required
                v-model="altraStrutturaRef"
              /> -->

          <!--  -->

          <!-- <label class="form-label font-medium text-base p-2 m-2 col-span-1"
                >Peso:
              </label>
              <input
                type="text"
                class="form-control p-2 col-span-2"
                required
                v-model="pesoRef"
              /> -->
          <!-- <label class="form-label font-medium text-base p-2 m-2 col-span-1"
                >Body Score:
              </label>
              <input
                type="number"
                class="form-control p-2 col-span-2"
                required
                v-model="bsRef"
              /> -->

          <!-- <label class="form-label font-medium text-base p-2 m-2 col-span-1"
                >Notifiche
              </label>
              <label class="form-label font-medium text-base p-2 m-2 col-span-1"
                >Via SMS
              </label>
              <input
                type="checkbox"
                class="form-check-input p-2 col-span-1"
                required
                v-model="smsRef"
              />
              <label class="form-label font-medium text-base p-2 m-2 col-span-1"
                >Via Mail
              </label>
              <input
                type="checkbox"
                class="form-check-input p-2 col-span-1"
                required
                v-model="mailRef"
              /> -->
          <!-- Fine  inserimento dati -->
        </div>

        <div v-show="false" class="flex items-center gap-3 justify-end">
          <label class="form-label">Completato: </label>
          <input
            v-model="completedRef"
            type="checkbox"
            class="custom-checkbox mb-2"
            required
          />
        </div>
        <!-- Fine layout Grid -->
      </div>

      <!-- Modal footer -->
      <div class="mt-5 flex items-center justify-end gap-3">
        <button
          type="button"
          class="orange-btn"
          @click="
            confirmVaccine(
              selectedClient ? selectedClient.id : null,
              vaccinoRef ? vaccinoRef.id : null,
              $FormatEpochEnglish(dataVacc),
              $FormatEpochEnglish(dataRef),
              medicoRef ? medicoRef.id : null,
              selected ? selected.id : null
            )
          "
        >
          <PlusCircleIcon class="h-5 w-5 mr-2" />
          {{ toModify ? 'Modifica' : 'Aggiungi' }}
        </button>
        <button type="button" class="gray-btn" @click="$emit('close')">
          <span class="h-5"></span>
          Annulla
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch, onMounted } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import SearchDrop from '../../components/search-drop/SearchDrop.vue'
import axios from '../../gateway/backend-api'
import _ from 'lodash'

export default defineComponent({
  components: { SearchDrop },
  props: ['pet', 'client', 'toModify'],
  emits: ['confirm', 'close'],
  setup(props, { emit }) {
    const now = moment().format('D/M/YYYY')

    // variabili per tenere traccia degli input inseriti
    const medicoRef = ref(null)
    const vaccinoRef = ref(null)
    const lottoRef = ref('')
    const scadenzaRef = ref('')
    const richiamoRef = ref(null)
    const richiamoPeriodoRef = ref('')
    const dataVacc = ref('')
    const dataRef = ref('')
    const pesoRef = ref('')
    const bsRef = ref('')
    const smsRef = ref(false)
    const mailRef = ref(false)
    const completedRef = ref(true)
    const altraStrutturaRef = ref(false)
    const drugs = ref([])
    const staff = ref([])

    // Gestione Ricerca Proprietario/Paziente
    const searchBarClient = ref('')
    const searchBarPet = ref('')
    const clientInvoice = ref(null)
    const petInvoice = ref(null)
    const isActiveSearchClient = ref(false)
    const isActiveSearchPet = ref(false)

    const store = useStore()

    const dropDownSearchClient = () => {
      isActiveSearchClient.value = !isActiveSearchClient.value
      isActiveSearchPet.value = false
    }
    const closeDropDownSearchClient = () => {
      isActiveSearchClient.value = false
    }
    const dropDownSearchPet = () => {
      isActiveSearchPet.value = !isActiveSearchPet.value
      isActiveSearchClient.value = false
    }
    const closeDropDownSearchPet = () => {
      isActiveSearchPet.value = false
    }
    const onChildClickClient = (value) => {
      clientInvoice.value = value
      closeDropDownSearchPet()
      closeDropDownSearchClient()
    }
    const onChildClickPet = (value) => {
      petInvoice.value = value
      closeDropDownSearchPet()
      closeDropDownSearchClient()
    }

    const removeSelectedClient = () => {
      clientInvoice.value = null
    }

    const removeSelectedPet = () => {
      petInvoice.value = null
    }

    if (props.toModify) {
      if (props.toModify.execution_date) {
        dataVacc.value = moment
          .unix(props.toModify.execution_date)
          .format('YYYY-MM-DD')
      }

      if (props.toModify.richiamo) {
        dataRef.value = moment
          .unix(props.toModify.richiamo)
          .format('YYYY-MM-DD')
      }
      if (props.toModify.staff_user) {
        let copy = { ...props.toModify.staff_user }
        medicoRef.value = copy
      }
      if (props.toModify.vaccine) {
        let copy = { ...props.toModify.vaccine }
        vaccinoRef.value = copy
      }
    }

    const confirmVaccine = (
      client_id,
      vaccine_id,
      execution_date,
      date_cnverted,
      medico_id,
      id_pet
    ) => {
      if (
        vaccine_id !== null &&
        date_cnverted !== null &&
        medico_id !== null &&
        vaccine_id !== '' &&
        execution_date !== '' &&
        !isNaN(execution_date) &&
        execution_date !== '' &&
        !isNaN(date_cnverted) &&
        medico_id !== '' &&
        id_pet !== null
      ) {
        emit(
          'confirm',
          client_id,
          vaccine_id,
          execution_date,
          date_cnverted,
          medico_id,
          completedRef.value,
          id_pet
        )
      } else {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire tutti i campi'
        })
      }
    }

    // Se il paziente vviene passato nelle props utilizzo quello e non permetto di cambiarlo
    const selected = computed(() => {
      return props.pet ? props.pet : petInvoice.value
    })

    const selectedClient = computed(() => {
      return props.client ? props.client : clientInvoice.value
    })

    const currentUser = computed(() => {
      return store.state.userId
    })

    if (props.pet) {
      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/vaccination?pet_type_id=' +
            props.pet.pet_type_id
        )
        .then((res) => {
          drugs.value = res.data
        })
    }

    axios.post('/' + localStorage.getItem('clinic') + '/staff').then((res) => {
      staff.value = res.data
      if (!props.toModify) {
        staff.value.forEach((el) => {
          if (el.id.toString() === currentUser.value.toString()) {
            medicoRef.value = el
          }
        })
      }
    })

    // FINE Gestione Ricerca Proprietario/Paziente

    watch(vaccinoRef, (elem, newElem) => {
      if (newElem) {
        lottoRef.value = newElem.lot
      } else {
        lottoRef.value = null
      }
    })

    onMounted(() => {
      document.addEventListener('click', closeDropDownSearchClient)
      document.addEventListener('click', closeDropDownSearchPet)
      if (props.toModify) {
        if (props.toModify.customer)
          clientInvoice.value = props.toModify.customer
        if (props.toModify.pet) petInvoice.value = props.toModify.pet
      }
    })

    return {
      searchBarClient,
      searchBarPet,
      clientInvoice,
      petInvoice,
      isActiveSearchClient,
      isActiveSearchPet,
      dropDownSearchClient,
      closeDropDownSearchClient,
      dropDownSearchPet,
      closeDropDownSearchPet,
      onChildClickClient,
      onChildClickPet,
      removeSelectedClient,
      removeSelectedPet,
      selected,
      now,
      drugs,
      medicoRef,
      staff,
      vaccinoRef,
      lottoRef,
      scadenzaRef,
      richiamoRef,
      richiamoPeriodoRef,
      dataVacc,
      dataRef,
      pesoRef,
      confirmVaccine,
      bsRef,
      smsRef,
      mailRef,
      completedRef,
      altraStrutturaRef,
      selectedClient,
      currentUser
    }
  },
  watch: {
    'selected.pet_type_id': function () {
      this.getVaccini()
    }
  },
  methods: {
    getVaccini() {
      if (this.selected && this.selected.pet_type_id) {
        axios
          .get(
            '/' +
              localStorage.getItem('clinic') +
              '/vaccination?pet_type_id=' +
              this.selected.pet_type_id
          )
          .then((res) => {
            this.drugs = res.data
          })
      }
    },
    searchDrugs(search, loading) {
      if (search.length > -1) {
        if (this.selected) {
          loading(true)
          this.search(loading, search, this)
        } else {
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Si prega di inserire il paziente per ricercare il vaccino'
          })
        }
      }
    },
    search: _.debounce((loading, search, vm) => {
      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/vaccination?pet_type_id=' +
            vm.selected.pet_type_id +
            '&search_text=' +
            search
        )
        .then((res) => {
          vm.drugs = res.data
          loading(false)
        })
    }, 350)
  }
})
</script>
