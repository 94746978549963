<template>
  <div
    class="bg-white rounded-md content-container overflow-auto scrollbar_custom p-3"
  >
    <div class="bg-white">
      <div class="flex flex-col md:flex-row items-center justify-end my-5">
        <div class="flex-1 flex items-center gap-2">
          <div class="flex items-center justify-start gap-3">
            <button
              :class="[
                !daily
                  ? 'orange-btn hover:bg-theme-41 hover:text-white hover:border-theme-41'
                  : 'orange-btn-disable'
              ]"
              @click.prevent="changePeriod(false)"
            >
              Mensile
            </button>
            <button
              :class="[
                daily
                  ? 'orange-btn hover:bg-theme-41 hover:text-white hover:border-theme-41'
                  : 'orange-btn-disable'
              ]"
              @click.prevent="changePeriod(true)"
            >
              Giornaliero
            </button>
          </div>

          <div id="picker-div" style="position: relative">
            <input
              v-show="false"
              id="report-date-day"
              ref="dailyPicker"
              v-model="reportDate"
              type="text"
              class="date mt-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Seleziona data del report"
              @click="handleClick($event)"
            />
            <input
              v-show="false"
              id="report-date-month"
              ref="monthPicker"
              v-model="reportDate"
              type="text"
              class="date-month mt-1 shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              placeholder="Seleziona il mese del report"
            />
          </div>
          <div class="flex items-center gap-3">
            <div
              v-if="daily"
              class="nunito font-bold text-base bg-theme-45 rounded-lg py-1 px-3 text-theme-40"
            >
              Report giornaliero:
            </div>
            <div
              v-else
              class="nunito font-bold text-base bg-theme-45 rounded-lg py-1 px-3 text-theme-40"
            >
              Report mensile:
            </div>

            <span class="nunito font-bold text-base">{{ reportDate }}</span>
          </div>
        </div>

        <div class="flex-1 flex justify-end">
          <button
            class="orange-btn hover:bg-theme-41 hover:text-white hover:border-theme-41"
            @click="handleLoadCassa(reportDate, true)"
          >
            Stampa report
          </button>
        </div>
      </div>

      <ChiusuraCassaTable
        :loading-search="loadingSearch"
        :search-daily="searchType == 'daily' ? true : false"
        :documents="docs"
        :total-records="totalRecords"
      />
      <div
        v-if="!docs"
        class="flex w-full h-screen content-center items-center justify-center"
      >
        <RotateCwIcon class="animate-spin h-full w-5 mr-3 scale-150" />
      </div>

      <div class="flex flex-col lg:flex-row gap-3">
        <div class="col-span-12 sm:col-span-6 flex-1 flex-col gap-3 mt-6">
          <h3 class="stats-subs">% Pagamenti nel periodo</h3>

          <div class="flex flex-col lg:flex-row items-center justify-center">
            <PieChart
              :values="prestazioniNumber"
              :labels="prestazioniNumberLabel"
              :options="chartColOptions"
              class="flex justify-center"
            ></PieChart>
          </div>
        </div>

        <div class="col-span-12 sm:col-span-6 flex-1 flex-col gap-3 mt-6">
          <h3 class="stats-subs">Riepilogo dati periodo</h3>
          <div class="flex flex-col lg:flex-row justify-between p-5">
            <table class="w-full mr-3">
              <tbody
                v-if="
                  summary['types'] &&
                  summary['types']['number'] &&
                  summary['types']['tot']
                "
              >
                <tr
                  v-for="(tot, docKey) in summary['types']['number']"
                  :key="docKey"
                  class="text-xs font-normal"
                >
                  <td
                    class="text-center text-sm border-2 border-theme-45 py-1.5 bg-theme-46"
                  >
                    N. {{ docKey }}
                  </td>
                  <td
                    class="text-center text-sm border-2 border-theme-45 py-1.5"
                  >
                    {{ tot }}
                  </td>
                </tr>
                <tr
                  v-for="(tot, docKey) in summary['types']['tot']"
                  :key="docKey"
                  class="text-xs font-normal"
                >
                  <td
                    class="text-center text-sm border-2 border-theme-45 py-1.5 bg-theme-46"
                  >
                    Tot.{{ docKey }}
                  </td>
                  <td
                    class="text-center text-sm border-2 border-theme-45 py-1.5"
                  >
                    €{{ tot }}
                  </td>
                </tr>
              </tbody>
            </table>

            <table class="w-full">
              <tbody>
                <tr
                  v-for="(tot, docKey) in summary['incassi']"
                  :key="docKey"
                  class="text-xs font-normal"
                >
                  <td
                    class="text-center text-sm border-2 border-theme-45 py-1.5 bg-theme-46"
                  >
                    {{ docKey }}
                  </td>
                  <td
                    class="text-center text-sm border-2 border-theme-45 py-1.5"
                  >
                    €{{ tot }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChiusuraCassaTable from './ChiusuraCassaTable.vue'
import axios from '../../gateway/backend-api'
import PieChart from '../../components/pie-chart/Main.vue'
import flatpickr from 'flatpickr'
import 'flatpickr/dist/flatpickr.css'
import { Italian } from 'flatpickr/dist/l10n/it.js'
import moment from 'moment'

import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect/index.js'
import 'flatpickr/dist/plugins/monthSelect/style.css'

const chartColOptions = {
  chart: {
    title: 'Company Performance',
    subtitle: 'Sales, Expenses, and Profit: 2014-2017'
  },
  width: 600,
  height: 400,
  colors: [
    '#059393',
    '#CA6715',
    '#1B4B77',
    '#F7CD65',
    '#9FD5DA',
    '#EF9C02',
    '#6295D3'
  ]
}

export default {
  components: {
    ChiusuraCassaTable,
    PieChart
  },
  setup() {},
  data() {
    return {
      pagination: {
        page: 1,
        forPage: 10,
        totalRecords: 0
      },
      daily: true,
      docs: [],
      docs_total: null,
      summary: [],
      searchType: '',
      loadingSearch: true,
      chartColOptions,
      prestazioniNumber: [],
      prestazioniNumberLabel: ['Prestazione', 'Numero'],
      reportDate: null
    }
  },
  watch: {
    reportDate: function (elem) {
      this.handleLoadCassa(elem)
    }
  },
  mounted() {
    flatpickr.localize(Italian)

    flatpickr('.date', {
      appendTo: document.getElementById('picker-div'),
      plugins: [
        require('shortcut-buttons-flatpickr')({
          button: [
            {
              label: 'Oggi'
            },
            {
              label: 'Domani'
            },
            { label: 'Chiudi' }
          ],

          label: '',
          onClick: (index, fp) => {
            let date
            switch (index) {
              case 0:
                date = new Date()
                break
              case 1:
                date = new Date(Date.now() + 24 * 60 * 60 * 1000)
                break

              case 2:
                fp.close()
                break
            }
            fp.setDate(date)
          }
        })
      ],
      maxDate: 'today',
      dateFormat: 'd/m/Y'
    })

    flatpickr('.date-month', {
      appendTo: document.getElementById('picker-div'),
      plugins: [
        new monthSelectPlugin({
          shorthand: true, //defaults to false
          dateFormat: 'm/Y' //defaults to "F Y"
        })
      ],
      maxDate: 'today',
      altFormat: 'm/Y',
      ariaDateFormat: 'm/Y'
    })
    this.handleLoadCassa(moment().format('DD/MM/YYYY'))
  },
  methods: {
    handleClick(event) {
      document.getElementById(event.target.id).style.position = 'absolute'
    },
    changePagination(value) {
      this.pagination = value
      this.handleLoadCassa(this.reportDate)
    },
    handleLoadCassa(date, pdf = false) {
      if (date == null) {
        // Stampiamo il report odierno
        date = moment().format('DD/MM/YYYY')
      }
      this.loadingSearch = true
      this.$store.dispatch('commonComponents/showLoadingModalAction')

      if (date.length == 7) date = '01/' + date
      date = moment.utc(moment(date, 'DD/MM/YYYY')).unix()

      let startDate = date
      let endDate = date
      if (!this.daily) {
        endDate = moment.unix(date).endOf('month').unix()
      }
      // } else {
      //   endDate = moment.unix(date).add(1, 'days').unix()
      // }

      if (!pdf) {
        axios
          .post('/' + localStorage.getItem('clinic') + '/bills/getReportBill', {
            per_page: this.pagination.forPage,
            page: this.pagination.page,
            startDate: startDate,
            endDate: endDate
          })
          .then((res) => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            this.loadingSearch = false
            if (res.data.data) this.totalRecords = 1
            else this.totalRecords = 0
            this.searchType = res.data.search_type
            this.docs = res.data.data
            this.docs_total = res.data.total
            this.prestazioniNumber = res.data.stats
            this.summary = res.data.summary
          })
          .catch(() => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
          })
      } else {
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/bills/getPdfReportBill',
            {
              startDate: startDate,
              endDate: endDate,
              timezone: 'GMT' + moment().format('Z')
            },
            {
              responseType: 'arraybuffer',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf'
              }
            }
          )
          .then((response) => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Fattura25.pdf')
            document.body.appendChild(link)
            this.$store.dispatch('commonComponents/openPdfModal', {
              title: 'Prova',
              link: url,
              background: false,
              print: () => {},
              download: () => {
                link.click()
              }
            })
          })
          .catch(() => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
          })
      }
    },

    changePeriod(bool) {
      if (bool) {
        this.daily = true
        document.getElementById('report-date-day').style.position = 'absolute'
        document.getElementById('report-date-day').click()
      } else {
        document.getElementById('report-date-month').style.position = 'absolute'
        document.getElementById('report-date-month').click()
        this.daily = false
      }
    },
    handlePrintPdf() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/bills/4596/pdf',
          {},
          {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/pdf'
            }
          }
        )
        .then((response) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Fattura25.pdf')
          document.body.appendChild(link)
          this.$store.dispatch('commonComponents/openPdfModal', {
            title: 'Prova',
            link: url,
            background: false,
            print: () => {},
            download: () => {
              link.click()
            }
          })
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    }
  }
}
</script>
