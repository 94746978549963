<template>
  <div>
    <div class="h-screen">
      <div
        class="flex flex-col px-10 pt-32 lg:mx-48 lg:flex-row items-center justify-center xl:pt-44 xxl:pt-52 xxxl:pt-60"
      >
        <!-- BEGIN: Login Info -->

        <img
          class="login_logo w-full mb-8 z-40 md:w-3/6 lg:w-5/6 lg:mr-10 xl:w-4/6 xxl:w-3/6"
          alt="logo-vetsgo"
          src="@/assets/images/logo-blue.svg"
        />

        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <!-- <div class="sm:hidden flex justify-center mt-8 py-5">
          <img
            alt="logo-vanadium"
            class="w-7/12 z-40"
            src="@/assets/images/VetsGo.png"
          />
        </div> -->
        <div class="w-full flex flex-col items-center">
          <div class="rounded-md w-full">
            <h2
              class="mb-12 py-5 nunito text-theme-40 text-2xl intro-x lg:text-2xl text-center xl:text-3xl"
            >
              Si è verificato un problema...
            </h2>

            <p class="mb-4 text-theme-48 intro-x text-base text-center">
              Siamo spiacenti, ma il servizio non è al momento disponibile a
              causa di un problema tecnico. <br />
              Ti invitiamo a riprovare più tardi. Ci scusiamo per il disguido.
            </p>
            <h3 class="text-theme-48 intro-x text-lg text-center mb-16 lg:mb-0">
              Per problemi e segnalazioni scrivere a:
              <b>assistenzaweb@gruppocvit.it</b>
            </h3>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-center">
              <button
                class="nunito rounded-full bg-theme-41 text-white text-xl font-bold py-3 mt-5 w-40"
                @click="$store.dispatch('goToLogin')"
              >
                Torna al login
              </button>
            </div>
          </div>
        </div>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
export default {
  setup() {
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login')
    })
  }
}
</script>
