<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-12 p-2 mx-auto mt-20 border sm:w-3/5 w-full shadow-lg rounded-md bg-white"
    >
      <!-- Modal content --><button
        type="button"
        class="closing-btn"
        @click="$emit('close')"
      >
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <div class="flex flex-col">
        <div class="w-full">
          <h2 id="modal-title" class="modal-title mt-8">Sposta in clinica</h2>
        </div>
        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div v-if="!skip">
          <div class="bg-white py-3 px-6 rounded-2xl mt-3">
            <div>
              <div class="flex">
                <div class="flex-1 pl-2">
                  <label
                    for="new-enter-room"
                    class="block text-gray-700 text-xs font-bold mb-2"
                  >
                    Stanza
                  </label>
                  <v-select
                    v-model="selected_room"
                    :options="rooms"
                    label="name"
                    :reduce="(value) => value.id"
                    placeholder="Seleziona la stanza"
                    class="py-2"
                  >
                    <template #option="option">
                      {{ option.department_name }} - {{ option.name }}
                    </template>
                  </v-select>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div
            class="border-t-1 border-gray-400 w-full px-6 text-right flex justify-end items-end mb-2"
          >
            <button
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
              @click="skip = true"
            >
              Aggiungi sala
            </button>
            <button
              type="button"
              class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              @click="skip = true"
            >
              <span class="h-5"></span>
              Salta
            </button>
          </div>
        </div>
        <div v-else class="flex-col place-content-center">
          <span class="text-lg text-center"
            >Vuoi procedere con l'apertura della scheda paziente?</span
          >
          <div class="text-right">
            <button type="button" class="orange-btn" @click="goToPet">
              Si
            </button>
            <button type="button" class="gray-btn ml-3" @click="moveToClinic">
              No
            </button>
          </div>
        </div>
        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import axios from '../../gateway/backend-api'
import '@swoga/vue-collapsible-panel/dist/vue-collapsible-panel.css'

export default defineComponent({
  props: ['room_id', 'pet_id', 'waiting_id'],
  emits: ['close'],
  data() {
    return {
      rooms: [],
      skip: false,
      selected_room: '',
      reparto: [
        {
          id: 1,
          name: 'Medico'
        },
        {
          id: 2,
          name: 'Chirurgia'
        },
        {
          id: 3,
          name: 'Degenza'
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      getPetsDetails: 'anagraficaPazienti/getPetsDetails',
      getComparisonPets: 'anagraficaPazienti/getComparisonPets'
    })
  },
  mounted() {
    if (this.room_id == '' || typeof this.room_id == 'undefined') {
      // Se non hanno ancora scelto una stanza si propone nuovamente di selezionarla
      axios
        .get('/' + localStorage.getItem('clinic') + '/departments/clinic-room')
        .then((res) => {
          this.rooms = res.data
        })
    } else {
      this.selected_room = this.room_id

      //this.skip = true
    }
  },
  methods: {
    goToPet() {
      axios
        .get('/' + localStorage.getItem('clinic') + '/pet/' + this.pet_id)
        .then((res) => {
          const pet = res.data
          this.moveToClinic()
          this.$goToPet(pet)
        })
    },
    moveToClinic() {
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/waiting-room/' +
            this.waiting_id +
            '/move',
          {
            type: 'clinic',
            clinic_room_id: this.selected_room
          }
        )
        .then(() => {
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: "Paziente inserito correttamento in sala d'attesa"
          })
          this.$emit('close')
        })
        .catch((e) => {
          if (e.response.status == 403) {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: "Il paziente è già stato inserito in sala d'attesa"
            })
          }

          this.$emit('close')
        })
    },

    ...mapActions({
      getPetAction: 'anagraficaPazienti/getPetAction'
    })
  }
})
</script>
