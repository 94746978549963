<template>
  <div>
    <input
      id="searchInput"
      ref="searchInput"
      class="custom-input"
      type="text"
      placeholder="Inserisci l'indirizzo"
      v-model="addressPlaceholder"
    />
  </div>
  <div id="map"></div>
</template>

<script>
import { Loader } from '@googlemaps/js-api-loader'

const loader = new Loader({
  apiKey: 'AIzaSyARTTqdvGFay55dyHfMscyiClwuUGYy_Ww', // Gruppo CVit
  libraries: ['places'],
  language: 'it'
})

export default {
  props: {
    types: [],
    restrictions: [],
    addressPlaceholderProps: String
  },
  data() {
    return {
      map: null,
      location: '',
      address: null,
      // addressPlaceholder: null,
      service: null
    }
  },
  computed: {
    addressPlaceholder: function () {
      if (this.addressPlaceholderProps) return this.addressPlaceholderProps
      else return ''
    }
  },
  mounted() {
    let options = {}
    let countryRestrictions = []

    // Setting filters and restrictions if they exists in the props
    if (this.types != null) {
      options.types = this.types
    }
    if (this.restrictions != null) {
      countryRestrictions = this.restrictions
    }

    let centerMap = { lat: 45.0703, lng: 7.68685 }

    loader.load().then(() => {
      this.map = new window.google.maps.Map(document.getElementById('map'), {
        center: centerMap,
        zoom: 8
      })

      this.service = new window.google.maps.places.Autocomplete(
        this.$refs['searchInput'],
        options
      )

      this.service.setComponentRestrictions({
        country: countryRestrictions
      })

      this.service.addListener('place_changed', () => {
        this.address = this.service.getPlace().address_components
        this.address.lat = this.service.getPlace().geometry.location.lat()
        this.address.lng = this.service.getPlace().geometry.location.lng()
        this.$emit('place_changed', this.address)
      })
    })
  }
}
</script>
