<template>
  <div>
    <div
      class="p-4"
      :class="[
        weightValues &&
        bodyScoreValues &&
        (weightValues.length > 0 || bodyScoreValues.length > 0)
          ? 'grid grid-cols-2 gap-5'
          : 'grid grid-cols-1'
      ]"
    >
      <div class="col-span-2 xl:col-span-1">
        <BodyScoreTable
          v-if="docs && tableConfig_vaccine_doc"
          :table-config-clinic-doc="tableConfig_vaccine_doc"
          :documents="docs"
          :total-records="totalRecords"
          :pagination="pagination"
          :for-page-option="forPageOption"
          :selected-patient="true"
          @handleSendMessage="handleSendMessage"
          @removeWeight="removeWeight"
          @getWeight="(el) => getWeight(el)"
        />
        <div class="flex justify-end">
          <button class="orange-btn" @click="openWeightModal">
            <PlusIcon class="h-5 w-5 mr-2" /> Aggiungi Peso
          </button>
        </div>
      </div>
      <div
        v-if="
          weightValues &&
          bodyScoreValues &&
          (weightValues.length > 0 || bodyScoreValues.length > 0)
        "
        class="col-span-2 xl:col-span-1 flex flex-col md:flex-row items-center justify-between gap-5 xl:flex-col xl:gap-1"
      >
        <div class="flex-1 shadow-md rounded-md border-2 border-theme-2 my-1">
          <Chart
            v-if="weightValues.length > 0"
            label="Peso"
            :values="weightValues"
            lineLabel="kg"
          />
        </div>
        <div class="flex-1 shadow-md rounded-md border-2 border-theme-2 my-1">
          <Chart
            v-if="bodyScoreValues.length > 0"
            label="Body Score"
            :values="bodyScoreValues"
            lineLabel="bs"
          />
        </div>
      </div>
    </div>
    <!--END: Da Effettuare-->

    <div
      v-if="!tableConfig_vaccine_doc || !docs"
      class="flex w-full h-screen content-center items-center justify-center"
    >
      <RotateCwIcon class="animate-spin h-full w-5 mr-3 scale-150" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from '../../../gateway/backend-api'
import Chart from '../../line-chart/Main.vue'
// import SendMessageModal from '../../modals/SendMessageModal.vue'

import BodyScoreTable from '../../tables/BodyScoreTable.vue'

const forPageOption = [5, 10, 20]
export default defineComponent({
  components: {
    BodyScoreTable,
    Chart
  },
  props: {
    petDetails: {
      type: [Object],
      default: function () {
        return {}
      }
    }
  },
  emits: ['refreshPet'],
  data() {
    return {
      tableConfig_vaccine_doc: null,
      docs: null,
      totalRecords: 0,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      forPageOption: forPageOption,
      messageModal: false,
      selectedElem: null,
      weightValues: null,
      bodyScoreValues: null,
      weightModalOpen: false
    }
  },
  watch: {
    docs: function (value) {
      let v = []
      let x = []
      if (value !== null) {
        for (let i = 0; i < this.docs.length; i++) {
          v.push(this.docs[i].weight)
          x.push(this.docs[i].body_score)
        }

        this.weightValues = v
        this.bodyScoreValues = x
      }
    }
  },
  mounted() {
    axios
      .get('/body_score_table_config') // scarico la configurazione della tabella
      .then((resp) => {
        const temp = []
        for (var elem of resp.data) {
          temp.push({ ...elem, value: '' }) // ai dati ricevuti aggiungo un campo per il valore del filtro che sarà inserito
        }
        this.tableConfig_vaccine_doc = temp
      })

    this.getWeight()
  },
  methods: {
    openWeightModal: function () {
      this.$store.dispatch('commonComponents/openWeightModal', {
        weightData: {
          confirm: this.addWeight,
          close: this.closeWeightModal
        }
      })
      // this.weightModalOpen = true
      // document.body.scrollTop = 300 // For Safari
      // document.documentElement.scrollTop = 300 // For Chrome, Firefox, IE and Opera
    },
    closeWeightModal: function () {
      this.$store.dispatch('commonComponents/closeWeightModal')
      // this.weightModalOpen = false
    },
    getWeight: function (value = {}) {
      axios
        .post('/' + localStorage.getItem('clinic') + '/weight/indexPetWeight', {
          pet_id: this.petDetails.id,
          active_filters: value,
          per_page: this.pagination.forPage,
          page: this.pagination.page
        })
        .then((res) => {
          this.docs = res.data.data
          this.totalRecords = res.data.total
        })
    },
    addWeight: function (weight, bodyScore) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/weight/storePetWeight', {
          pet_id: this.petDetails.id,
          weight: weight,
          body_score: bodyScore
        })
        .then(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')

          this.closeWeightModal()
          this.getWeight()
          this.$emit('refreshPet')
        })
    },
    removeWeight: function (weight) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/weight/deletePetWeight',
          {
            weight_id: weight.id
          }
        )
        .then(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')

          this.getWeight()
          this.$emit('refreshPet')
        })
        .catch((e) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Errore:' + e
          })
        })
    }
  }
})
</script>
