<template>
  <div v-if="tableConfigClinicDoc && documents" class="paginator-container">
    <div class="flex justify-between">
      <div class="mt-1">
        <span class="flex-row text-md ml-2 font-bold mr-2">Mostra </span>

        <select
          v-model="forPage"
          class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
          @change="(event) => changeNumPages(event)"
        >
          <option v-for="show in forPageOption" :key="show" :value="show">
            {{ show }}
          </option>
        </select>
        <span class="text-md font-bold ml-2 mt-1"> elementi</span>
      </div>
    </div>
    <Paginator
      v-if="totalRecords > pagination.forPage"
      :for-page-option="pagination.forPage"
      :total-records="totalRecords"
      :page-default="pagination.page"
      @changePagination="(event) => changePagination(event)"
    />
  </div>

  <!-- BEGIN: Data List -->
  <div class="pb-3 mt-2">
    <table v-if="tableConfigClinicDoc && documents" class="w-full">
      <thead class="t-header h-7">
        <tr>
          <th v-for="(item, itemKey) in tableConfigClinicDoc" :key="itemKey">
            {{ item.title }}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody class="mt-1">
        <tr class="">
          <td
            v-for="(elem, index) in tableConfigClinicDoc"
            :key="index"
            :style="elem.style ?? ''"
            class="text-center px-1 py-1.5"
          >
            <select
              v-if="elem.type === 'select'"
              v-model="elem.value"
              class="w-full border rounded-md h-7 text-gray-500"
            >
              <option selected value="">{{ elem.title }}</option>
              <option v-for="(item, i) in elem.option" :key="i" :value="item">
                {{ item }}
              </option>
            </select>
            <input
              v-else
              v-model="elem.value"
              :type="elem.type"
              min="0"
              :name="elem.title"
              class="custom-input h-7"
              :class="{
                invisible: elem.type === 'none',
                'p-0': elem.type === 'date'
              }"
              :placeholder="elem.title"
            />
          </td>
        </tr>
        <tr
          v-for="(doc, docKey) in newDoc"
          :key="docKey"
          class="h-7 font-normal hover:bg-theme-45"
          :class="{ 'bg-theme-2': docKey % 2 === 0 }"
        >
          <td class="" style="width: 10rem">
            <input
              v-model="newDoc[docKey].send"
              type="checkbox"
              class="form-check-switch block mx-auto"
              required
            />
          </td>

          <td
            class="text-center text-xs border-r-2 border-theme-46 td_hideoverflow"
          >
            {{ doc.alias && doc.alias !== '' ? doc.alias : doc.description }}
          </td>
          <!-- <td
            class="text-center align-center justify-center text-sm border-r-2 border-theme-46"
            style="width: 12rem"
          >
            <button
              v-if="tagliaSelected"
              type="button"
              :class="havePrice(doc) ? 'orange-btn' : 'gray-btn '"
              @click="$emit('openPriceModal', doc, docKey, id_taglia)"
            >
              <span> Visualizza prezzi</span>
            </button>
            <span v-else> Selezionare la taglia </span>
          </td> -->

          <td
            v-if="!noPrice"
            class="text-center align-center justify-center text-xs border-r-2 border-theme-46"
            style="width: 10rem"
          >
            <button
              v-if="tagliaSelected"
              type="button"
              :class="[
                havePrice(doc)
                  ? 'orange-btn hover:bg-theme-41 hover:text-white'
                  : 'gray-btn-inverted'
              ]"
              @click="$emit('openPriceModal', doc, docKey, id_taglia)"
            >
              <span v-if="havePrice(doc)"> Visualizza prezzo</span>
              <span v-else> Inserisci prezzo</span>
            </button>
            <div v-else class="text-xs">Selezionare la taglia</div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="documents && documents.length === 0"
      class="w-full flex justify-center mt-2"
    >
      Nessun Risultato
    </div>
  </div>
  <!-- END: Data List -->
</template>

<script>
import { defineComponent, ref, toRef, watch } from 'vue'
import Paginator from '../../components/paginator/Main.vue'

export default defineComponent({
  components: { Paginator },
  props: [
    'documents',
    'totalRecords',
    'pagination',
    'forPageOption',
    'selectedCategoria',
    'aliasToSave',
    'selectedNew',
    'tagliaSelected',
    'noPriceProp',
    'editPrestazioni'
  ],
  emits: [
    'getNomenclatore',
    'changePagination',
    'getAliasData',
    'changeNumPages',
    'addElement',
    'removeElement'
  ],

  setup(props, { emit }) {
    // variabili paginazione
    const newDoc = toRef(props, 'documents')

    if (props.editPrestazioni) {
      newDoc.value = newDoc.value.map((elem) => {
        elem.send = false
        props.editPrestazioni.forEach((e) => {
          if (elem.id * 1 === e.id * 1) {
            elem.send = true
          }
        })

        return elem
      })
    }

    if (props.selectedNew) {
      newDoc.value = newDoc.value.map((elem) => {
        if (props.selectedNew.includes(elem.id)) {
          elem.send = true
        } else {
          elem.send = false
        }
        return elem
      })
    }
    const noPrice = ref(props.noPriceProp ?? false)
    const forPage = ref(props.pagination.forPage)

    const tableConfigClinicDoc = ref([
      {
        key: 'selezionato',
        title: 'Selezionato',
        type: 'none',
        style: 'display: none'
      },

      {
        key: 'alias',
        title: 'Alias',
        type: 'none',
        style: 'display: none'
      }
    ])

    // Metodo per eliminare un elemento dalla lista

    //  cambio elementi per pagina, e ritorno nella prima pagina
    const changePagination = (value) => {
      emit('changePagination', value)
    }

    // metodo che cambia il numero della pagina
    const changeNumPages = (elem) => {
      emit('changeNumPages', elem.target.value)
    }

    const addAlias = (doc) => {
      emit('addAlias', doc)
    }

    const handleSwitch = (doc, event) => {
      console.log(event.target.value)
    }

    const havePrice = (doc) => {
      let res = false

      Object.keys(doc.code).forEach((t) => {
        Object.keys(doc.code[t]).forEach((el) => {
          if (
            doc.code[t][el].data &&
            doc.code[t][el].id_tariffario_taglie === props.tagliaSelected
          )
            res = true
        })
      })

      return res
    }

    watch(
      newDoc,
      (elem, newElem) => {
        let send = newElem.filter((x) => x.send)
        let notsend = newElem.filter((x) => !x.send)
        emit('addElement', send)
        emit('removeElement', notsend)
      },
      { deep: true }
    )

    // ----- FINE COMPUTED ----- //

    return {
      changePagination,
      changeNumPages,
      tableConfigClinicDoc,
      newDoc,
      addAlias,
      handleSwitch,
      havePrice,
      noPrice,
      forPage
    }
  },
  watch: {
    // documents: function () {
    //   if (!this.documents || this.documents === []) return
    //   if (this.selectedNew) {
    //     this.newDoc = this.newDoc.map((elem) => {
    //       if (this.selectedNew.includes(elem.id)) {
    //         elem.send = true
    //       } else {
    //         elem.send = false
    //       }
    //       return elem
    //     })
    //   }
    //   if (this.editPrestazioni) {
    //     //console.log('watch documents - if prestazioni')
    //     this.newDoc = this.newDoc.map((elem) => {
    //       elem.send = false
    //       // console.log('watch documents - if prestazioni - map new')
    //       this.editPrestazioni.forEach((e) => {
    //         if (elem.id * 1 === e.id * 1) {
    //           elem.send = true
    //         }
    //       })
    //       return elem
    //     })
    //   }
    // }
  }
})
</script>
