<template>
  <div class="tab-content overflow-hidden relative">
    <!--BEGIN: invoice list-->
    <div class="flex flex-col md:flex-row items-center justify-end my-5">
      <button
        type="file"
        class="orange-btn px-3 py-1 flex mr-2"
        @click="handleCarouselModal"
      >
        <i class="pt-1 far fa-images mr-1"></i>

        Nuovo Articolo
      </button>
    </div>
    <div
      id="carousel"
      class="tab-pane active"
      role="tabpanel"
      aria-labelledby="invoices_mobile-tab"
    >
      <!-- Show elements and global search-->
      <div class="paginator-container">
        <div class="flex items">
          <div class="mr-5">
            <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
              >Mostra
            </span>
            <select
              v-model="pagination.forPage"
              class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
              @change="(event) => changeNumPages(event)"
            >
              <option
                v-for="show in forPageOption"
                :key="show"
                :value="show"
                class="form-select"
              >
                {{ show }}
              </option>
            </select>
            <span class="text-md font-bold ml-2 mt-1"> elementi</span>
          </div>
        </div>
        <Paginator
          :for-page-option="pagination.forPage"
          :total-records="totalRecords"
          @changePagination="changePagination"
        />
      </div>
      <!-- BEGIN: Data List -->
      <div
        class="table-container fatt-att-cont scrollbar-thin scrollbar-thumb-theme-44 scrollbar-track-theme-2 overflow-scroll"
      >
        <table class="w-full shadow custom-border rounded-lg">
          <thead class="h-10 t-header">
            <tr>
              <th
                v-for="(item, itemKey) in tableConfig_invoices"
                :key="itemKey"
                :name="item.title"
              >
                {{ item.title }}
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody class="overflow-scroll scrollbar-none">
            <tr class="">
              <td
                v-for="(elem, index) in tableConfig_invoices"
                :key="index"
                class="text-center pr-3 pb-3"
              >
                <select
                  v-if="elem.type === 'select'"
                  v-model="elem.value"
                  class="w-full border rounded-md h-7 text-gray-500 mt-2"
                  @change="filterHandler(elem)"
                >
                  <option selected value=""></option>
                  <option
                    v-for="(item, i) in elem.option"
                    :key="i"
                    :value="item.id ? item.id : item"
                  >
                    {{ item.name ? item.name : item }}
                  </option>
                </select>

                <input
                  v-else
                  v-model="elem.value"
                  :type="elem.type"
                  :style="elem.style"
                  min="0"
                  :name="elem.title"
                  class="form-control h-7 mt-2 text-left border-theme-45 px-0"
                  :class="{
                    invisible: elem.type === 'none',
                    'p-0': elem.type === 'date'
                  }"
                  :placeholder="elem.title"
                  @change="filterHandler(elem)"
                />
              </td>
            </tr>
            <tr
              v-for="(n, index) in data"
              :key="index"
              class="h-12 text-xs font-normal hover:bg-theme-44 cursor-pointer"
              :class="{ 'bg-theme-2': index % 2 === 0 }"
            >
              <Cell
                class="text-center border-r border-theme-45"
                :elementi="$FormatDateMin(n.added_at)"
              />
              <Cell
                class="text-center border-r border-theme-45"
                :elementi="n.header"
              />
              <Cell
                class="text-center border-r border-theme-45"
                :elementi="n.title"
              />
              <Cell
                class="text-center border-r border-theme-45"
                :elementi="n.published"
              />
              <Cell
                class="text-center border-r border-theme-45"
                :elementi="n.short_description"
              />
              <td
                class="text-center border-r border-theme-45 text-sm px-3 relative"
              >
                <img
                  style="
                    width: auto;
                    height: auto;
                    max-width: 200px;
                    max-height: 100px;
                  "
                  :src="n.img_src"
                />
              </td>
              <td
                class="text-center border-r border-theme-45 text-sm px-3 relative"
              >
                {{ n.staff_user.first_name }} {{ n.staff_user.last_name }}
              </td>
              <td
                class="text-center border-r border-theme-45 text-sm px-3 relative"
              >
                <a class="mx-auto my-2">
                  <input
                    :id="n.id"
                    v-model="n.enabled"
                    type="checkbox"
                    class="form-check-switch"
                    @click="toggleEnabled"
                  />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
    </div>
    <!--END: invoice list-->
  </div>

  <CarouselModal
    v-if="showCarouselModal"
    @close="handleCarouselModal"
    @update="getData"
  />
</template>
<script>
const forPageOption = [5, 10, 20, 50]

import Paginator from '../paginator/Main.vue'
import Cell from '../../components/cvit-table/Cell.vue'
import axios from '../../gateway/backend-api'
import CarouselModal from '../../components/modals/CarouselModal.vue'

export default {
  components: {
    Paginator,
    Cell,
    CarouselModal
  },
  data() {
    return {
      data: [],
      totalRecords: 0,
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      tableConfig_invoices: [
        {
          key: 'added_at',
          title: 'Data Inserimento',
          type: 'date'
        },
        {
          key: 'header',
          title: 'Occhiello',
          type: 'text'
        },
        {
          key: 'title',
          title: 'Titolo',
          type: 'text'
        },
        {
          key: 'published',
          title: 'Pubblicato',
          type: 'none'
        },
        {
          key: 'short_description',
          title: 'Descrizione',
          type: 'text'
        },
        {
          key: 'img_src',
          title: 'Immagine',
          type: 'none'
        },
        {
          key: 'uploaded_by',
          title: 'Caricato da',
          type: 'select',
          option: []
        },
        {
          key: 'enabled',
          title: 'Visibile',
          type: 'none'
        }
      ],
      showCarouselModal: false
    }
  },

  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/carousel')
        .then((res) => {
          this.data = res.data.data
          this.totalRecords = res.data.total

          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },

    toggleEnabled(e) {
      var value = ''
      this.data.forEach((el) => {
        if (el.id == e.target.id) {
          value = !el.enabled
          if (!value) el.enabled = false
        }
      })

      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/carousel/' +
            e.target.id +
            '/toggle-enabled',
          {
            enabled: value
          }
        )
        .then()
    },
    changePagination: function (value) {
      if (this.pagination.page != value.page) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        this.getData()
      }
    },
    changeNumPages: function (elem) {
      this.pagination.forPage = elem.target.value
      this.getData()
    },
    handleCarouselModal: function () {
      this.showCarouselModal = !this.showCarouselModal
    }
  }
}
</script>
