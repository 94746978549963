<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-10 p-2 mx-auto border w-11/12 lg:w-11/12 shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="flex flex-col gap-1 justify-between">
        <!-- Modal header -->
        <button type="button" class="closing-btn" @click="$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <div class="mt-5">
          <h3 class="modal-title w-full mt-5">Seleziona un tariffario</h3>

          <!-- first section -->
          <div
            class="mt-3 py-2 px-6 grid grid-cols-6 gap-1 gap-y-2 items-center"
          >
            <div class="col-span-6 lg:col-span-2">
              <v-select
                v-if="tariffari"
                v-model="selectedTariffario"
                class=""
                label="name"
                placeholder="Seleziona il tariffario"
                :options="tariffari"
              />
            </div>

            <div
              class="col-span-3 lg:col-span-1 flex justify-center items-center gap-1"
            >
              <input
                v-model="iva"
                class="form-check-switch"
                disabled
                type="checkbox"
              />

              <p class="form-label mb-0 whitespace-nowrap">
                {{ iva ? 'IVA PRESENTE' : 'IVA ASSENTE' }}
              </p>
            </div>

            <div
              class="col-span-3 lg:col-span-1 flex justify-center items-center gap-1"
            >
              <input
                checked
                class="form-check-switch"
                disabled
                type="checkbox"
              />

              <p class="form-label mb-0 whitespace-nowrap">
                {{ cassa ? 'CASSA PRESENTE' : 'CASSA ASSENTE' }}
              </p>
            </div>
            <div
              v-if="selectedTipologia === 'Prestazioni'"
              class="relative col-span-6 lg:col-span-2"
            >
              <input
                type="text"
                class="custom-input"
                placeholder="Cerca tra tutte le prestazioni..."
                @input="
                  (event) => $h.debounce(() => startSearch(event.target.value))
                "
              />

              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 54.3 54.4"
                class="text-theme-44 fill-current w-4 h-4 absolute top-2 right-4 cursor-pointer"
              >
                <path
                  d="M16.4 4.7c9.2-7 22.3-6.1 30.8 2.1 8.4 8.1 9.4 21.1 2.4 31.1-5.5 7.9-19.5 14.2-33.5 4.9-3.2 3.2-6.5 6.5-9.7 9.8-2 2-3.8 2.3-5.4.8-1.6-1.5-1.4-3.4.7-5.6.3-.4.7-.7 1.1-1.1 2.9-2.9 5.9-5.8 8.7-8.6-8.7-12.9-4.1-26.5 4.9-33.4Zm31.2 19c0-9.2-7.8-17-17.2-17-9.5 0-17 7.7-17 17.3 0 9.4 7.6 16.9 17.2 16.9 9.5 0 17-7.6 17.1-17.3Z"
                />
              </svg>
            </div>
          </div>

          <!-- Modal body -->

          <div
            v-if="!searchingPage"
            class="mt-3 py-2 px-6 flex flex-col gap-2 md:flex-row md:items-center text-left"
          >
            <div class="flex-1">
              <div class="flex flex-col">
                <span class="form-label mb-0">Tipologie</span>
                <v-select
                  v-if="tariffari"
                  v-model="selectedTipologia"
                  class="flex-1 uppercase text-xs"
                  label="name"
                  placeholder="Seleziona il tariffario"
                  :options="tipologie"
                />
              </div>
            </div>

            <!-- CATEGORIE -->

            <div
              v-if="
                ((searchResult &&
                  Object.keys(searchResult).length > 0 &&
                  searching) ||
                  (categorie && searching === false)) &&
                selectedTipologia === 'Prestazioni'
              "
              class="flex-1"
            >
              <div class="flex flex-col">
                <span class="form-label mb-0">Categorie</span>
                <v-select
                  v-if="
                    searchResult &&
                    Object.keys(searchResult).length > 0 &&
                    searching &&
                    selectedTipologia === 'Prestazioni'
                  "
                  v-model="selectedCategoriaSearch"
                  class="flex-1 uppercase text-xs"
                  label="category"
                  placeholder="Seleziona le categorie"
                  :options="Object.keys(searchResult)"
                />

                <v-select
                  v-else-if="
                    categorie &&
                    searching === false &&
                    selectedTipologia === 'Prestazioni'
                  "
                  v-model="selectedCategoria"
                  class="flex-1 uppercase text-xs"
                  label="category"
                  placeholder="Seleziona le categorie"
                  :options="categorie"
                />
              </div>
            </div>

            <!-- PRESTAZIONI -->

            <div
              v-if="
                ((nomenclatoreSearch &&
                  nomenclatoreSearch.length > 0 &&
                  searching) ||
                  (nomenclatore && !searching)) &&
                selectedTipologia === 'Prestazioni'
              "
              class="flex-1"
            >
              <div class="flex flex-col">
                <span class="form-label mb-0"
                  >Prestazioni della categoria selezionata</span
                >
              </div>
              <v-select
                v-if="
                  nomenclatoreSearch &&
                  nomenclatoreSearch.length > 0 &&
                  searching &&
                  selectedTipologia === 'Prestazioni'
                "
                v-model="selectedNomenclatoreSearch"
                class="flex-1 uppercase text-xs"
                placeholder="Seleziona le categorie"
                label="description"
                :options="nomenclatoreSearch"
                :filter="
                  (options, search) => {
                    return options.filter((option) => {
                      let label = getOptionLabel(option)
                      if (typeof label === 'number') {
                        label = label.toString()
                      }
                      return filterBy(option, label, search)
                    })
                  }
                "
              >
                <template #selected-option="option">
                  {{
                    option.alias && option.alias !== ''
                      ? option.alias
                      : option.description
                  }}
                </template>
                <template #option="option">
                  {{
                    option.alias && option.alias !== ''
                      ? option.alias
                      : option.description
                  }}
                </template>
              </v-select>
              <v-select
                v-else-if="
                  nomenclatore &&
                  !searching &&
                  selectedTipologia === 'Prestazioni'
                "
                v-model="selectedNomenclatore"
                class="flex-1 uppercase text-xs"
                placeholder="Seleziona le categorie"
                label="description"
                :options="nomenclatore"
              >
                <template #selected-option="option">
                  {{
                    option.alias && option.alias !== ''
                      ? option.alias
                      : option.description
                  }}
                </template>
                <template #option="option">
                  {{
                    option.alias && option.alias !== ''
                      ? option.alias
                      : option.description
                  }}
                </template>
              </v-select>
            </div>

            <!-- Pacchetti -->

            <div v-if="selectedTipologia === 'Pacchetti'" class="flex-1">
              <div class="flex flex-col">
                <span class="form-label mb-0">Pacchetti</span>
                <v-select
                  v-if="pacchetti && selectedTipologia === 'Pacchetti'"
                  v-model="selectedPacchetto"
                  class="flex-1 uppercase text-xs"
                  label="alias"
                  placeholder="Seleziona il pacchetto"
                  :options="pacchetti"
                >
                  <template #selected-option="option">
                    {{
                      option.alias && option.alias !== ''
                        ? option.alias
                        : option.description
                    }}
                    {{
                      (option.discount &&
                      option.discount !== 0 &&
                      option.discount !== '0'
                        ? '(Sconto applicato:' + option.discount + '%)'
                        : '') +
                      (option.greatest &&
                      option.greatest !== 0 &&
                      option.greatest !== '0'
                        ? '(Maggiorazione applicata:' + option.greatest + '%)'
                        : '')
                    }}
                  </template>
                  <template #option="option">
                    {{
                      option.alias && option.alias !== ''
                        ? option.alias
                        : option.description
                    }}
                    {{
                      (option.discount &&
                      option.discount !== 0 &&
                      option.discount !== '0'
                        ? '(Sconto applicato:' + option.discount + '%)'
                        : '') +
                      (option.greatest &&
                      option.greatest !== 0 &&
                      option.greatest !== '0'
                        ? '(Maggiorazione applicata:' + option.greatest + '%)'
                        : '')
                    }}
                  </template>
                </v-select>
              </div>
            </div>

            <!-- DISTINTE -->

            <div v-if="selectedTipologia === 'Distinte'" class="flex-1">
              <div class="flex flex-col">
                <span class="form-label mb-0">distinte</span>
                <v-select
                  v-if="distinte && selectedTipologia === 'Distinte'"
                  v-model="selectedDistinta"
                  class="flex-1 uppercase text-xs"
                  label="alias"
                  placeholder="Seleziona le categorie"
                  :options="distinte"
                >
                  <template #selected-option="option">
                    {{
                      option.alias && option.alias !== ''
                        ? option.alias
                        : option.description
                    }}
                  </template>
                  <template #option="option">
                    {{
                      option.alias && option.alias !== ''
                        ? option.alias
                        : option.description
                    }}
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <!-- <div class="flex justify-between gap-2 font-bold px-6 pt-5">
            <span class="flex-1">Tipologia</span>
            <span
              v-if="
                ((searchResult &&
                  Object.keys(searchResult).length > 0 &&
                  searching) ||
                  (categorie && searching === false)) &&
                selectedTipologia === 'Prestazioni'
              "
              class="flex-1 form-label mb-0"
              >Categoria</span
            >
            <span
              v-if="
                ((nomenclatoreSearch &&
                  nomenclatoreSearch.length > 0 &&
                  searching) ||
                  (nomenclatore && !searching)) &&
                selectedTipologia === 'Prestazioni'
              "
              class="flex-1 form-label mb-0"
              >Prestazione</span
            >
            <span
              v-if="selectedTipologia === 'Pacchetti'"
              class="flex-1 form-label mb-0"
              >Pacchetti</span
            >
            <span
              v-if="selectedTipologia === 'Distinte'"
              class="flex-1 form-label mb-0"
              >Distinte</span
            >
          </div>
          <div class="flex justify-between gap-2 px-6 pb-3">
            <v-select
              v-if="tariffari"
              v-model="selectedTipologia"
              class="flex-1"
              label="name"
              placeholder="Seleziona il tariffario"
              :options="tipologie"
            />
            <v-select
              v-if="
                searchResult &&
                Object.keys(searchResult).length > 0 &&
                searching &&
                selectedTipologia === 'Prestazioni'
              "
              v-model="selectedCategoriaSearch"
              class="flex-1"
              label="category"
              placeholder="Seleziona le categorie"
              :options="Object.keys(searchResult)"
            />

            <v-select
              v-else-if="
                categorie &&
                searching === false &&
                selectedTipologia === 'Prestazioni'
              "
              v-model="selectedCategoria"
              class="flex-1"
              label="category"
              placeholder="Seleziona le categorie"
              :options="categorie"
            />
            <v-select
              v-if="
                nomenclatoreSearch &&
                nomenclatoreSearch.length > 0 &&
                searching &&
                selectedTipologia === 'Prestazioni'
              "
              v-model="selectedNomenclatoreSearch"
              class="flex-1"
              placeholder="Seleziona le categorie"
              :options="nomenclatoreSearch"
            >
              <template #selected-option="option">
                {{
                  option.alias && option.alias !== ''
                    ? option.alias
                    : option.description
                }}
              </template>
              <template #option="option">
                {{
                  option.alias && option.alias !== ''
                    ? option.alias
                    : option.description
                }}
              </template>
            </v-select>
            <v-select
              v-else-if="
                nomenclatore &&
                !searching &&
                selectedTipologia === 'Prestazioni'
              "
              v-model="selectedNomenclatore"
              class="flex-1"
              placeholder="Seleziona le categorie"
              :options="nomenclatore"
            >
              <template #selected-option="option">
                {{
                  option.alias && option.alias !== ''
                    ? option.alias
                    : option.description
                }}
              </template>
              <template #option="option">
                {{
                  option.alias && option.alias !== ''
                    ? option.alias
                    : option.description
                }}
              </template>
            </v-select>
            <v-select
              v-if="pacchetti && selectedTipologia === 'Pacchetti'"
              v-model="selectedPacchetto"
              class="flex-1"
              label="alias"
              placeholder="Seleziona il pacchetto"
              :options="pacchetti"
            >
              <template #selected-option="option">
                {{
                  option.alias && option.alias !== ''
                    ? option.alias
                    : option.description
                }}
                {{
                  (option.discount &&
                  option.discount !== 0 &&
                  option.discount !== '0'
                    ? '(Sconto applicato:' + option.discount + '%)'
                    : '') +
                  (option.greatest &&
                  option.greatest !== 0 &&
                  option.greatest !== '0'
                    ? '(Maggiorazione applicata:' + option.greatest + '%)'
                    : '')
                }}
              </template>
              <template #option="option">
                {{
                  option.alias && option.alias !== ''
                    ? option.alias
                    : option.description
                }}
                {{
                  (option.discount &&
                  option.discount !== 0 &&
                  option.discount !== '0'
                    ? '(Sconto applicato:' + option.discount + '%)'
                    : '') +
                  (option.greatest &&
                  option.greatest !== 0 &&
                  option.greatest !== '0'
                    ? '(Maggiorazione applicata:' + option.greatest + '%)'
                    : '')
                }}
              </template>
            </v-select>
            <v-select
              v-if="distinte && selectedTipologia === 'Distinte'"
              v-model="selectedDistinta"
              class="flex-1"
              label="alias"
              placeholder="Seleziona le categorie"
              :options="distinte"
            >
              <template #selected-option="option">
                {{
                  option.alias && option.alias !== ''
                    ? option.alias
                    : option.description
                }}
              </template>
              <template #option="option">
                {{
                  option.alias && option.alias !== ''
                    ? option.alias
                    : option.description
                }}
              </template>
            </v-select>
          </div> -->
          <div
            v-if="selectedTipologia === 'Prestazioni' && !searchingPage"
            class="mt-5 overflow-auto scrollbar_custom"
          >
            <table
              class="w-full shadow overflow-scroll scrollbar-none sm:rounded-lg"
              style="min-width: 650px"
            >
              <thead class="border border-theme-40">
                <tr class="">
                  <th class="t-header uppercase text-sm">Prestazione</th>
                  <th class="t-header uppercase text-sm">Taglia</th>
                  <th class="t-header uppercase text-sm">Diurno</th>
                  <th class="t-header uppercase text-sm">Notturno</th>
                  <th class="t-header uppercase text-sm">Festivo</th>
                  <th class="t-header uppercase text-sm">Assicurazione</th>
                </tr>
              </thead>
              <tbody v-if="nomenclatoreSelectedComputed" class="rounded-md">
                <tr class="h-12 text-xs font-normal bg-white col-span-1">
                  <td
                    class="text-center text-sm border-r-2 border-theme-46 p-3 cursor-pointer"
                  >
                    {{
                      nomenclatoreSelectedComputed.alias &&
                      nomenclatoreSelectedComputed.alias !== ''
                        ? nomenclatoreSelectedComputed.alias
                        : nomenclatoreSelectedComputed.description
                    }}
                  </td>

                  <td
                    class="text-center text-sm border-r-2 border-theme-46 cursor-pointer col-span-2"
                  >
                    <div
                      v-for="x in taglieShow"
                      :key="x"
                      class="h-10 my-1 flex flex-col justify-center items-center gap-2"
                    >
                      <div class="">
                        {{ x.text }}
                      </div>
                    </div>
                  </td>

                  <td
                    v-for="(el, index) in nomenclatoreSelectedComputed.code"
                    :key="el"
                    class="text-center text-sm border-r-2 border-theme-46 cursor-pointer col-span-2"
                  >
                    <div
                      v-for="pr in taglieShow"
                      :key="pr"
                      class="h-10 my-1 flex justify-center items-center"
                    >
                      <div v-if="el[pr.key].data" class="">
                        <button
                          type="button"
                          class="gray-btn whitespace-nowrap"
                          :class="
                            selectedPrice == el[pr.key]
                              ? 'bg-theme-44 text-theme-43'
                              : 'bg-theme-45 text-theme-43'
                          "
                          @click="
                            selectPrice(
                              el[pr.key],
                              index,
                              pr.key,
                              nomenclatoreSelectedComputed
                            )
                          "
                        >
                          {{
                            el[pr.key].data
                              ? ' €' +
                                Math.round(
                                  (el[pr.key].data * (iva ? ivaPerc : 1) +
                                    Number.EPSILON) *
                                    100
                                ) /
                                  100
                              : '--'
                          }}
                        </button>
                      </div>

                      <div
                        v-else
                        class="h-10 flex justify-center items-center text-xl"
                      >
                        {{ '--' }}
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <span>Selezionare tariffario e categoria</span>
              </tbody>
            </table>
          </div>
          <div
            v-if="selectedTipologia === 'Pacchetti' && !searchingPage"
            class="overflow-auto scrollbar_custom"
          >
            <table
              class="w-full shadow overflow-scroll scrollbar-none sm:rounded-lg"
              style="min-width: 650px"
            >
              <thead class="border border-theme-40">
                <tr class="">
                  <th class="t-header uppercase text-sm">Pacchetto</th>
                  <th class="t-header uppercase text-sm">Taglia</th>
                  <th class="t-header uppercase text-sm">Diurno</th>
                  <th class="t-header uppercase text-sm">Notturno</th>
                  <th class="t-header uppercase text-sm">Festivo</th>
                  <th class="t-header uppercase text-sm">Assicurazione</th>
                </tr>
              </thead>
              <tbody v-if="selectedPacchetto" class="rounded-md">
                <!-- <tr
                v-for="(pack, indexPack) in selectedPacchetto.sub_packages
                  .tariffario_ids.tariffario_price"
                :key="indexPack"
                class="h-12 text-xs font-normal bg-white col-span-1"
              >
                <td
                  class="text-center text-sm border-r-2 border-theme-46 p-3 cursor-pointer"
                >
                  {{
                    pack.alias && pack.alias !== ''
                      ? pack.alias
                      : pack.description
                  }}
                </td>

                <td
                  class="flex flex-col text-center text-sm border-r-2 border-theme-46 cursor-pointer col-span-2"
                >
                  <button
                    type="button"
                    class="mb-2 px-3 py-2 text-bsm font-medium text-theme-43 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm uppercase"
                  >
                    {{ selectedPacchetto.taglia }}
                  </button>
                </td>

                <td
                  v-for="(el, i) in t_all"
                  :key="el + i"
                  class="text-center text-sm border-r-2 border-theme-46 cursor-pointer col-span-2"
                >
                  <div v-if="pack.price[el]">
                    <div v-for="pr in pack.price[el]" :key="pr">
                      <span
                        v-if="
                          pr.id_tariffario_taglie ===
                          selectedPacchetto.id_tariffario_taglia
                        "
                        type="button"
                        class="mb-2 px-3 py-2 text-bsm font-medium text-theme-43 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm uppercase"
                      >
                        {{
                          pr.data
                            ? calculateRawPrice(
                                pr.data_netto ?? pr.data,
                                selectedPacchetto.discount,
                                selectedPacchetto.greatest
                              )
                            : '--'
                        }}
                      </span>
                    </div>
                  </div>
                </td>
              </tr> -->
                <tr>
                  <td
                    class="text-center text-sm border-r-2 border-theme-46 p-3 cursor-pointer"
                  >
                    {{ selectedPacchetto.alias ?? '' }}
                    <button type="button" @click="handleTableModal">
                      <InfoIcon class="w-5 ml-2" />
                    </button>
                  </td>
                  <td
                    class="text-center text-sm border-r-2 border-theme-46 p-3 cursor-pointer"
                  >
                    {{ selectedPacchetto.taglia ?? '' }}
                  </td>
                  <td
                    v-for="(el, t) in pacchettoSum"
                    :key="el + t"
                    class="text-center text-sm border-r-2 border-theme-46 cursor-pointer col-span-2"
                  >
                    <button
                      type="button"
                      class="gray-btn whitespace-nowrap"
                      :class="
                        selectedPrice ==
                        { alias: selectedPacchetto.alias, data: el }
                          ? 'bg-theme-44 text-theme-43'
                          : 'bg-theme-45 text-theme-43'
                      "
                      :disabled="el === 0"
                      :style="el === 0 ? 'background-color:white; ' : ''"
                      @click="
                        selectPrice(
                          {
                            alias: selectedPacchetto.taglia,
                            data: el
                          },
                          t,
                          `el_${selectedPacchetto.id_tariffario_taglia - 1}`,
                          selectedPacchetto
                        )
                      "
                    >
                      {{
                        calculateRawPrice(
                          el,
                          selectedPacchetto.discount,
                          selectedPacchetto.greatest
                        ) + ' €'
                      }}
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <span>Selezionare tariffario e categoria</span>
              </tbody>
            </table>
          </div>
          <div
            v-if="selectedTipologia === 'Distinte' && !searchingPage"
            class="overflow-auto scrollbar_custom"
          >
            <table
              class="w-full shadow overflow-scroll scrollbar-none sm:rounded-lg"
              style="min-width: 650px"
            >
              <thead class="border border-theme-40">
                <tr class="">
                  <th class="t-header uppercase text-sm">Prestazione</th>
                  <th class="t-header uppercase text-sm">Taglia</th>
                  <th class="t-header uppercase text-sm">Diurno</th>
                  <th class="t-header uppercase text-sm">Notturno</th>
                  <th class="t-header uppercase text-sm">Festivo</th>
                  <th class="t-header uppercase text-sm">Assicurazione</th>
                </tr>
              </thead>
              <tbody v-if="selectedDistinta" class="rounded-md">
                <tr
                  v-for="(pack, indexPack) in selectedDistinta.sub_packages
                    .tariffario_ids.tariffario_price"
                  :key="indexPack"
                  class="h-12 text-xs font-normal bg-white col-span-1"
                >
                  <td
                    class="text-center text-sm border-r-2 border-theme-46 p-3 cursor-pointer"
                  >
                    {{ pack.alias }}
                  </td>

                  <td
                    class="flex flex-col text-center text-sm border-r-2 border-theme-46 cursor-pointer col-span-2"
                  >
                    <button
                      type="button"
                      class="mb-2 px-3 py-2 text-bsm font-medium text-theme-43 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm uppercase"
                    >
                      {{ selectedDistinta.taglia }}
                    </button>
                  </td>

                  <td
                    v-for="(el, i) in t_all"
                    :key="el + i"
                    class="text-center text-sm border-r-2 border-theme-46 cursor-pointer col-span-2"
                  >
                    <div v-if="pack.price[el]">
                      <div v-for="pr in pack.price[el]" :key="pr">
                        <span
                          v-if="
                            pr.id_tariffario_taglie ===
                            selectedDistinta.id_tariffario_taglia
                          "
                          type="button"
                          class="mb-2 px-3 py-2 text-bsm font-medium text-theme-43 focus:outline-none sm:ml-3 sm:w-auto sm:text-sm uppercase"
                        >
                          {{
                            pr.data
                              ? calculateRawPrice(
                                  pr.data_netto ?? pr.data,
                                  selectedDistinta.discount,
                                  selectedDistinta.greatest
                                ) + ' €'
                              : '--'
                          }}
                        </span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td></td>

                  <td
                    v-for="(el, t) in distintaSum"
                    :key="el + t"
                    class="text-center text-sm border-r-2 border-theme-46 cursor-pointer col-span-2"
                  >
                    <button
                      type="button"
                      class="gray-btn whitespace-nowrap"
                      :disabled="el === 0"
                      :style="el === 0 ? 'background-color:white; ' : ''"
                      @click="
                        selectPrice(
                          { alias: selectedDistinta.alias, data: el },
                          t,
                          `el_${selectedDistinta.id_tariffario_taglia - 1}`,
                          selectedDistinta
                        )
                      "
                    >
                      {{
                        calculateRawPrice(
                          el,
                          selectedDistinta.discount,
                          selectedDistinta.greatest
                        ) + ' €'
                      }}
                    </button>
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <span>Selezionare tariffario e categoria</span>
              </tbody>
            </table>
          </div>
          <div v-if="searchingPage">
            <SearchTable
              :selected-tariffario="selectedTariffario.id"
              :search-text="searchText"
              @selectPrestazione="selectSearch"
            />
          </div>
          <div
            v-if="prestazioniAggiunte && prestazioniAggiunte.length > 0"
            class="grid grid-cols-6 mx-2 p-3"
          >
            <span class="col-span-6 form-label mb-0 mt-5 uppercase">
              Prestazioni aggiunte
            </span>
            <div
              v-for="elem in prestazioniAggiunte"
              :key="elem"
              class="col-span-2 bg-theme-2 py-1 px-2 m-1 flex gap-1 justify-between items-start"
            >
              <div>
                <span class="text-theme-41 mr-1">• </span>
                {{
                  elem.elem.alias && elem.elem.alias !== ''
                    ? elem.elem.alias
                    : elem.elem.description
                }}
                |
                {{
                  Math.round(
                    ((elem.price.data_netto ?? elem.price.data_netto) *
                      (iva ? ivaPerc : 1) +
                      Number.EPSILON) *
                      100
                  ) /
                    100 +
                  ' €'
                }}
              </div>

              <button @click="removePrice(elem.elem, 'Prestazioni')">
                <i
                  class="fas fa-times cursor-pointer text-theme-41 fill-current text-xxs"
                />
              </button>
            </div>
          </div>
          <div
            v-if="pacchettiAggiunti && pacchettiAggiunti.length > 0"
            class="grid grid-cols-6 mx-2 p-3"
          >
            <span class="col-span-6 form-label mb-0 mt-5 uppercase">
              Pacchetti aggiunti
            </span>
            <div
              v-for="elem in pacchettiAggiunti"
              :key="elem"
              class="col-span-2 bg-theme-2 py-1 px-2 m-1 flex gap-1 justify-between items-start"
            >
              <div>
                <span class="text-theme-41 mr-1">• </span>
                {{
                  elem.elem.alias && elem.elem.alias !== ''
                    ? elem.elem.alias
                    : elem.elem.description
                }}
                |
                {{
                  calculateRawPrice(
                    elem.price.data_netto ?? elem.price.data,
                    elem.elem.discount,
                    elem.elem.greatest
                  ) + ' €'
                }}
              </div>

              <button @click="removePrice(elem.elem, 'Pacchetti')">
                <i
                  class="fas fa-times cursor-pointer text-theme-41 fill-current text-xxs"
                />
              </button>
            </div>
          </div>
          <div
            v-if="distinteAggiunte && distinteAggiunte.length > 0"
            class="grid grid-cols-6 mx-2 p-3"
          >
            <span class="col-span-6 form-label mb-0 mt-5 uppercase">
              Distinte aggiunte
            </span>
            <div
              v-for="elem in distinteAggiunte"
              :key="elem"
              class="col-span-2 bg-theme-2 py-1 px-2 m-1 flex gap-1 justify-between items-start"
            >
              <div>
                <span class="text-theme-41 mr-1">• </span>
                {{
                  elem.elem.alias && elem.elem.alias !== ''
                    ? elem.elem.alias
                    : elem.elem.description
                }}
                |
                {{
                  calculateRawPrice(
                    elem.price.data_netto ?? elem.price.data,
                    selectedDistinta.discount,
                    selectedDistinta.greatest
                  ) + ' €'
                }}
              </div>

              <button @click="removePrice(elem.elem, 'Distinte')">
                <i
                  class="fas fa-times cursor-pointer text-theme-41 fill-current text-xxs"
                />
              </button>
            </div>
          </div>
          <div class="p-6 space-y-6"></div>
          <!-- Modal footer -->

          <!-- Fine Modal footer -->
        </div>
        <div class="flex justify-end items-center mx-3 my-2">
          <button
            v-if="
              (selectedNomenclatore ||
                selectedNomenclatoreSearch ||
                selectedPacchetto ||
                selectedDistinta) &&
              selectedPrice
            "
            type="button"
            class="orange-btn"
            @click="handleConfirm"
          >
            <PlusCircleIcon class="h-5 w-5 mr-2" /> Aggiungi
          </button>

          <button class="ml-3 gray-btn" type="button" @click="$emit('close')">
            <span class="h-5"></span>
            Annulla
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, computed } from 'vue'
import SearchTable from '../tables/SearchTable.vue'
import { mapGetters } from 'vuex'
import axios from '../../gateway/backend-api'
import { useStore } from '@/store'

export default defineComponent({
  components: { SearchTable },
  emits: ['remove', 'close', 'addRow'],
  setup() {
    const store = useStore()
    const tariffari = ref([])
    const categorie = ref([])
    const nomenclatore = ref(null)
    const selectedCategoria = ref(null)
    const selectedTariffario = ref(null)
    const selectedNomenclatore = ref(null)
    const selectedPrice = ref(null)
    const price_path = ref(null)

    const tipologie = ['Prestazioni', 'Pacchetti', 'Distinte']

    const t_all = ['t1', 't2', 't3', 't4']

    const selectedTipologia = ref(tipologie[0])

    const pagination = ref({
      page: 1,
      forPage: 5
    })
    const selectedTaglia = ref(null)

    const getData = () => {
      // tariffari
      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/elenco-tariffari-clinica'
        )
        .then((res) => {
          tariffari.value = res.data
          selectedTariffario.value = res.data[0]
        })
    }

    const searchText = ref('')
    const searchResult = ref(null)
    const selectedCategoriaSearch = ref(null)
    const selectedNomenclatoreSearch = ref(null)
    const nomenclatoreSearch = ref(null)
    const searching = ref(false)
    const searchingPage = ref(false)

    const closeTableModal = () => {
      store.dispatch('commonComponents/closeTableModal')
    }
    const handleTableModal = () => {
      let data = []

      Object.keys(
        selectedPacchetto.value.sub_packages.tariffario_ids.tariffario_price
      ).forEach((p) => {
        data.push([
          selectedPacchetto.value.sub_packages.tariffario_ids.tariffario_price[
            p
          ].description,
          selectedPacchetto.value.sub_packages.tariffario_ids.tariffario_price[
            p
          ].category_name
        ])
      })

      store.dispatch('commonComponents/openTableModal', {
        tableData: {
          close: closeTableModal,
          title: selectedPacchetto.value.alias,
          header: ['Prestazione', 'Categoria'],
          data: data
        }
      })
    }

    const getNomenclatore = (category, forSearch) => {
      store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/tariffario/nomenclatore',
          {
            tariffario_clinic_id: selectedTariffario.value.id,
            category_id: forSearch ? null : selectedCategoria.value.id,
            per_page: 1000,
            page: 1,
            only_alias: true,
            search: searchText.value
          }
        )
        .then((res) => {
          store.dispatch('commonComponents/hideLoadingModalAction')
          if (!forSearch) {
            nomenclatore.value = res.data.data
            if (!searchingPage.value) {
              selectedNomenclatore.value = res.data.data[0]
            }
            searchingPage.value = false
          } else {
            searchResult.value = res.data.data
            if (Object.keys(searchResult.value)[0]) {
              selectedCategoriaSearch.value = Object.keys(searchResult.value)[0]
            } else {
              selectedCategoriaSearch.value = null
              selectedNomenclatoreSearch.value = null
            }

            //selectedNomenclatore.value = null
          }

          //totalRecords.value = res.data.total
        })
        .catch(() => {
          store.dispatch('commonComponents/hideLoadingModalAction')
        })
    }

    const pacchetti = ref(null)
    const distinte = ref(null)
    const selectedPacchetto = ref(null)
    const selectedDistinta = ref(null)

    const pacchettiAggiunti = ref([])
    const prestazioniAggiunte = ref([])
    const distinteAggiunte = ref([])

    const getPackageAndDistint = () => {
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/elenco-pacchetti-distinte',
          {
            tariffario_clinic_id: selectedTariffario.value.id,
            type: 'package',
            per_page: 1000,
            page: 1
          }
        )
        .then((res) => {
          pacchetti.value = res.data.data
          if (res.data && res.data.data && res.data.data.length > 0) {
            selectedPacchetto.value = res.data.data[0]
          }
        })
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/elenco-pacchetti-distinte',
          {
            tariffario_clinic_id: selectedTariffario.value.id,
            type: 'distinta',
            per_page: 1000,
            page: 1
          }
        )
        .then((res) => {
          distinte.value = res.data.data
          if (res.data && res.data.data && res.data.data.length > 0) {
            selectedDistinta.value = res.data.data[0]
          }
        })
    }

    const selectTaglia = (taglia) => {
      selectedTaglia.value = taglia
    }

    const selectPrice = (price, t, el, selectedElem) => {
      switch (selectedTipologia.value) {
        case 'Prestazioni':
          if (
            selectedTariffario.value &&
            !prestazioniAggiunte.value.find(
              (el) => el.elem.id === selectedElem.id
            )
          ) {
            prestazioniAggiunte.value.push({
              elem: selectedElem,
              price: price,
              price_path: { t: t, el: el }
            })
          } else {
            store.dispatch('commonComponents/openNotificationModal', {
              message: 'Prestazione già inserita'
            })
          }

          break
        case 'Pacchetti':
          if (
            selectedPacchetto.value &&
            !pacchettiAggiunti.value.find(
              (el) => el.elem.id === selectedElem.id
            )
          ) {
            pacchettiAggiunti.value.push({
              elem: selectedElem,
              price: price,
              price_path: { t: t, el: el }
            })
          } else {
            store.dispatch('commonComponents/openNotificationModal', {
              message: 'Pacchetto già inserito'
            })
          }

          break
        case 'Distinte':
          if (
            selectedDistinta.value &&
            !distinteAggiunte.value.find((el) => el.elem.id === selectedElem.id)
          ) {
            distinteAggiunte.value.push({
              elem: selectedElem,
              price: price,
              price_path: { t: t, el: el }
            })
          } else {
            store.dispatch('commonComponents/openNotificationModal', {
              message: 'Distinta già inserita'
            })
          }

          break
      }

      selectedPrice.value = price
      price_path.value = { t: t, el: el }
    }

    const removePrice = (selectedElem, type) => {
      switch (type) {
        case 'Prestazioni':
          prestazioniAggiunte.value = prestazioniAggiunte.value.filter(
            (el) => el.elem.id !== selectedElem.id
          )
          break
        case 'Pacchetti':
          pacchettiAggiunti.value = pacchettiAggiunti.value.filter(
            (el) => el.elem.id !== selectedElem.id
          )
          break
        case 'Distinte':
          distinteAggiunte.value = distinteAggiunte.value.filter(
            (el) => el.elem.id !== selectedElem.id
          )
          break
      }
    }

    const selectSearch = (pr) => {
      console.log(pr)
      selectedCategoria.value = { id: pr.category_id, category: pr.category }
      selectedNomenclatore.value = pr
      //searchText.value = null
      // searchingPage.value = false
    }

    watch([selectedTariffario, selectedCategoria], (newVal) => {
      if (newVal[0] && newVal[1]) {
        getNomenclatore()
        getPackageAndDistint()
      } else {
        nomenclatore.value = null
      }
    })

    watch(selectedCategoriaSearch, (newVal) => {
      if (newVal) {
        nomenclatoreSearch.value = searchResult.value[newVal]
        selectedNomenclatoreSearch.value = searchResult.value[newVal][0]
      } else {
        nomenclatoreSearch.value = null
        selectedNomenclatoreSearch.value = null
      }
    })

    watch(selectedTipologia, () => {
      selectedPrice.value = null
      price_path.value = null
    })

    const pacchettoSum = computed(() => {
      let result = {
        t1: 0,
        t2: 0,
        t3: 0,
        t4: 0
      }
      if (selectedPacchetto.value) {
        if (selectedPacchetto.value && selectedPacchetto.value.prices) {
          result.t1 = selectedPacchetto.value.prices['t1']
          result.t2 = selectedPacchetto.value.prices['t2']
          result.t3 = selectedPacchetto.value.prices['t3']
          result.t4 = selectedPacchetto.value.prices['t4']
        }

        return result
      }
      if (selectedPacchetto.value) {
        if (
          selectedPacchetto.value &&
          selectedPacchetto.value.sub_packages &&
          selectedPacchetto.value.sub_packages.tariffario_ids &&
          selectedPacchetto.value.sub_packages.tariffario_ids.tariffario_price
        ) {
          Object.keys(
            selectedPacchetto.value.sub_packages.tariffario_ids.tariffario_price
          ).forEach((tariff) => {
            if (
              selectedPacchetto.value.sub_packages.tariffario_ids
                .tariffario_price[tariff].price
            )
              Object.keys(
                selectedPacchetto.value.sub_packages.tariffario_ids
                  .tariffario_price[tariff].price
              ).forEach((t) => {
                Object.keys(
                  selectedPacchetto.value.sub_packages.tariffario_ids
                    .tariffario_price[tariff].price[t]
                ).forEach((taglia) => {
                  if (
                    selectedPacchetto.value.sub_packages.tariffario_ids
                      .tariffario_price[tariff].price[t][taglia]
                      .id_tariffario_taglie ===
                    selectedPacchetto.value.id_tariffario_taglia
                  ) {
                    if (
                      selectedPacchetto.value.sub_packages.tariffario_ids
                        .tariffario_price[tariff].price[t][taglia].data ||
                      selectedPacchetto.value.sub_packages.tariffario_ids
                        .tariffario_price[tariff].price[t][taglia].data_netto
                    )
                      result[t] +=
                        selectedPacchetto.value.sub_packages.tariffario_ids
                          .tariffario_price[tariff].price[t][taglia]
                          .data_netto ??
                        selectedPacchetto.value.sub_packages.tariffario_ids
                          .tariffario_price[tariff].price[t][taglia].data
                  }
                })
              })
          })
        }
      }
      return result
    })

    const distintaSum = computed(() => {
      let result = {
        t1: 0,
        t2: 0,
        t3: 0,
        t4: 0
      }
      if (selectedDistinta.value) {
        if (
          selectedDistinta.value &&
          selectedDistinta.value.sub_packages &&
          selectedDistinta.value.sub_packages.tariffario_ids &&
          selectedDistinta.value.sub_packages.tariffario_ids.tariffario_price
        ) {
          Object.keys(
            selectedDistinta.value.sub_packages.tariffario_ids.tariffario_price
          ).forEach((tariff) => {
            if (
              selectedDistinta.value.sub_packages.tariffario_ids
                .tariffario_price[tariff].price
            )
              Object.keys(
                selectedDistinta.value.sub_packages.tariffario_ids
                  .tariffario_price[tariff].price
              ).forEach((t) => {
                Object.keys(
                  selectedDistinta.value.sub_packages.tariffario_ids
                    .tariffario_price[tariff].price[t]
                ).forEach((taglia) => {
                  if (
                    selectedDistinta.value.sub_packages.tariffario_ids
                      .tariffario_price[tariff].price[t][taglia]
                      .id_tariffario_taglie ===
                    selectedDistinta.value.id_tariffario_taglia
                  ) {
                    if (
                      selectedDistinta.value.sub_packages.tariffario_ids
                        .tariffario_price[tariff].price &&
                      selectedDistinta.value.sub_packages.tariffario_ids
                        .tariffario_price[tariff].price[t][taglia].data
                    )
                      result[t] +=
                        selectedDistinta.value.sub_packages.tariffario_ids
                          .tariffario_price[tariff].price[t][taglia]
                          .data_netto ??
                        selectedDistinta.value.sub_packages.tariffario_ids
                          .tariffario_price[tariff].price[t][taglia].data
                  }
                })
              })
          })
        }
      }
      return result
    })

    watch(selectedTariffario, (newVal) => {
      if (newVal) {
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/tariffario/elenco-tariffari-categorie',
            { only_alias: true, tariffario_clinic_id: newVal.id }
          )
          .then((res) => {
            categorie.value = res.data
            selectedCategoria.value = res.data[0]
          })
      } else {
        categorie.value = null
        selectedCategoria.value = null
      }
    })
    const nomenclatoreSelectedComputed = computed(() => {
      let tmp = null
      if (searching.value === true) {
        tmp = selectedNomenclatoreSearch.value
      } else {
        tmp = selectedNomenclatore.value
      }
      if (tmp) {
        Object.keys(tmp.code).forEach(function (t) {
          Object.keys(tmp.code[t]).forEach(function (el) {
            tmp.code[t][el].data = tmp.code[t][el].data_netto
          })
        })
      }

      return tmp
    })

    const taglieShow = computed(() => {
      let res = []
      let tmp = []

      if (nomenclatore.value) {
        Object.keys(nomenclatoreSelectedComputed.value.code).forEach(function (
          t
        ) {
          Object.keys(nomenclatoreSelectedComputed.value.code[t]).forEach(
            function (e) {
              if (nomenclatoreSelectedComputed.value.code[t][e].data) {
                if (!tmp.includes(e)) {
                  tmp.push(e)
                  res.push({
                    key: e,
                    text: nomenclatoreSelectedComputed.value.code[t][e].alias
                  })
                }
              }
            }
          )
        })
      }

      return res
    })

    return {
      tariffari,
      categorie,
      nomenclatore,
      selectedCategoria,
      selectedTariffario,
      selectedNomenclatore,
      selectedPrice,
      pagination,
      getData,
      getNomenclatore,
      selectTaglia,
      selectedTaglia,
      selectPrice,
      taglieShow,
      searchText,
      searchResult,
      selectedCategoriaSearch,
      nomenclatoreSearch,
      selectedNomenclatoreSearch,
      searching,
      nomenclatoreSelectedComputed,
      price_path,
      pacchetti,
      distinte,
      tipologie,
      selectedTipologia,
      selectedPacchetto,
      selectedDistinta,
      pacchettoSum,
      distintaSum,
      pacchettiAggiunti,
      prestazioniAggiunte,
      distinteAggiunte,
      removePrice,
      t_all,
      handleTableModal,
      searchingPage,
      selectSearch
    }
  },
  data() {
    return {
      tariffario: [],
      totalRecords: 0
    }
  },
  computed: {
    ...mapGetters({
      iva: 'reusableData/getShowIva',
      cassa: 'reusableData/getCassaPrevidenzialeActive',
      ivaPerc: 'reusableData/getRegimeFiscaleIvaPerc'
    })
  },
  mounted() {
    this.getData()
  },
  methods: {
    startSearch(text) {
      console.log(text)
      if (text && text !== '') {
        this.searchText = text
        this.searchingPage = true
      } else {
        this.searchText = text
        this.searchingPage = false
      }

      // if (text !== '') {
      //   this.searching = true
      //   this.getNomenclatore(null, true)
      // } else {
      //   this.searching = false
      //   this.searchResult = null
      //   this.nomenclatoreSearch = null
      //   this.selectedCategoriaSearch = null
      //   this.selectedNomenclatoreSearch = null
      // }
    },
    changePagination: function (value) {
      if (this.pagination.page != value.page) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        this.getNomenclatore()
      }
    },
    setSelectedTariffario(t, k) {
      this.selectedTariffario = JSON.parse(JSON.stringify(t))
      this.selectedTariffario.code = this.selectedTariffario.code[k]

      this.selectedTariffario.price = this.selectedTariffario.price[k]
    },
    filterHandler: function () {
      this.searchData()
    },

    handleConfirm() {
      var vm = this
      // prestazioni
      this.prestazioniAggiunte.forEach((element) => {
        this.$emit(
          'addRow',
          element.elem,
          element.price,
          element.price_path,
          'Prestazioni',
          true
        )
      })

      // pacchetti
      this.pacchettiAggiunti.forEach((element) => {
        this.$emit(
          'addRow',
          element.elem,
          element.price,
          element.price_path,
          'Pacchetti',
          true
        )
      })

      // distinte
      this.distinteAggiunte.forEach((element) => {
        Object.keys(
          element.elem.sub_packages.tariffario_ids.tariffario_price
        ).forEach(function (e) {
          if (
            element.elem.sub_packages.tariffario_ids.tariffario_price[e][
              'price'
            ][element.price_path.t]
          )
            Object.keys(
              element.elem.sub_packages.tariffario_ids.tariffario_price[e][
                'price'
              ][element.price_path.t]
            ).forEach(function (p) {
              if (
                element.elem.sub_packages.tariffario_ids.tariffario_price[e][
                  'price'
                ] &&
                element.elem.sub_packages.tariffario_ids.tariffario_price[e][
                  'price'
                ][element.price_path.t] &&
                element.elem.sub_packages.tariffario_ids.tariffario_price[e][
                  'price'
                ][element.price_path.t][p].id_tariffario_taglie ===
                  element.elem.id_tariffario_taglia
              ) {
                if (
                  element.elem.sub_packages.tariffario_ids.tariffario_price[e][
                    'price'
                  ][element.price_path.t][p].data_netto
                )
                  element.elem.sub_packages.tariffario_ids.tariffario_price[e][
                    'price'
                  ][element.price_path.t][p].data =
                    element.elem.sub_packages.tariffario_ids.tariffario_price[
                      e
                    ]['price'][element.price_path.t][p].data_netto
                vm.$emit(
                  'addRow',
                  {
                    ...element.elem.sub_packages.tariffario_ids
                      .tariffario_price[e],
                    discount: element.elem.discount,
                    greatest: element.elem.greatest
                  },
                  element.elem.sub_packages.tariffario_ids.tariffario_price[e][
                    'price'
                  ][element.price_path.t][p],
                  { t: element.price_path.t, el: p },
                  'Distinte',
                  true
                )
              }
            })
        })
      })

      this.$emit('close')
    },
    calculateRawPrice(price, discount, greatest) {
      let res = price

      if (this.iva) res = res * this.ivaPerc
      if (discount && discount !== 0 && discount !== '0')
        res -= res * ((discount * 1) / 100)
      if (greatest && greatest !== 0 && greatest !== '0')
        res += res * ((greatest * 1) / 100)
      res = Math.round((res + Number.EPSILON) * 100) / 100
      return res
    }
  }
})
</script>
