<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-hidden transition-opacity scrollbar-none bg-theme-2"
  >
    <div class="px-3" style="height: 85vh">
      <AgendaModal
        v-if="showAgenda"
        title="AGENDA"
        :pet="pet"
        @close="() => (showAgenda = false)"
      />
      <TariffarioModal
        v-if="showTariffario"
        title="TARIFFARIO"
        :pet="pet"
        @close="() => (showTariffario = false)"
      />
      <VaccinazioniModal
        v-if="showVaccinazioni"
        :title="
          pet && pet.name ? 'Vaccinazioni di ' + pet.name : 'Vaccinazioni di '
        "
        :pet="pet"
        @close="closeVaccinazioniModal"
      />
      <div v-if="userRoleId != 5" class="h-9">
        <button type="button" class="closing-btn top-1" @click="$emit('close')">
          <svg
            class="w-4 h-4"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>
      <div v-else class="h-9 flex justify-end">
        <a
          class="block p-2 cursor-pointer hover:text-theme-41"
          @click="$store.dispatch('logout')"
        >
          <i class="fas fa-power-off mr-2" />
          Logout
        </a>
      </div>
      <div class="grid grid-cols-5 bg-theme-40 bg-opacity-95 rounded-t-lg">
        <div class="col-span-5 xl:col-span-2 text-white fill-current">
          <h2 class="nunito font-bold text-xl text-center uppercase">
            {{ pet.name }}
          </h2>
          <div
            class="flex flex-col sm:flex-row items-center gap-2 justify-center"
          >
            <div id="pet_specie" class="flex items-center gap-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="w-4"
              >
                <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M226.5 92.85C240.8 135.7 226.2 179.1 193.9 189.7C161.6 200.2 123.8 174 109.5 131.1C95.19 88.26 109.8 44.92 142.1 34.34C174.4 23.77 212.2 49.96 226.5 92.85zM100.4 198.6C119.2 231 114.7 268.7 90.16 282.7C65.65 296.8 30.49 281.9 11.63 249.4C-7.237 216.1-2.664 179.3 21.84 165.3C46.35 151.2 81.51 166.1 100.4 198.6zM69.21 401.2C121.6 259.9 214.7 224 256 224C297.3 224 390.4 259.9 442.8 401.2C446.4 410.9 448 421.3 448 431.7V433.3C448 459.1 427.1 480 401.3 480C389.8 480 378.4 478.6 367.3 475.8L279.3 453.8C263.1 450 248 450 232.7 453.8L144.7 475.8C133.6 478.6 122.2 480 110.7 480C84.93 480 64 459.1 64 433.3V431.7C64 421.3 65.6 410.9 69.21 401.2H69.21zM421.8 282.7C397.3 268.7 392.8 231 411.6 198.6C430.5 166.1 465.7 151.2 490.2 165.3C514.7 179.3 519.2 216.1 500.4 249.4C481.5 281.9 446.3 296.8 421.8 282.7zM310.1 189.7C277.8 179.1 263.2 135.7 277.5 92.85C291.8 49.96 329.6 23.77 361.9 34.34C394.2 44.92 408.8 88.26 394.5 131.1C380.2 174 342.4 200.2 310.1 189.7z"
                />
              </svg>

              <h3 class="font-bold text-base uppercase mr-2">
                {{ pet.type }}
                <span v-if="pet.breed" class="font-semibold"
                  >( {{ pet.breed }} )</span
                >
              </h3>
            </div>
            <div id="proprietario" class="flex items-center gap-3 nunito">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                class="w-3"
              >
                <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M224 256c70.7 0 128-57.31 128-128s-57.3-128-128-128C153.3 0 96 57.31 96 128S153.3 256 224 256zM274.7 304H173.3C77.61 304 0 381.6 0 477.3c0 19.14 15.52 34.67 34.66 34.67h378.7C432.5 512 448 496.5 448 477.3C448 381.6 370.4 304 274.7 304z"
                />
              </svg>
              <h3 class="font-bold text-base uppercase">
                Proprietario:
                <span
                  v-if="pet.owners && pet.owners[0]"
                  class="font-semibold capitalize text-base"
                >
                  {{ pet.owners[0].first_name }}
                  {{ pet.owners[0].last_name }}
                  {{ pet.owners[0].ragione_sociale }}
                </span>
              </h3>
            </div>
          </div>

          <!-- <h3
            class="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white"
          >
            {{ pet.name }} - {{ pet.type }} ( {{ pet.breed }} ) -
          </h3> -->
        </div>

        <div class="col-span-5 xl:col-span-3">
          <div
            class="flex gap-2 sm:gap-8 justify-between xl:mt-4 text-white fill-current px-3"
          >
            <div
              id="vax"
              class="flex items-center text-theme-41 hover:text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 text-center fill-current cursor-pointer mr-3 ml-1"
                viewBox="0 0 512 512"
                @click="openVaccinazioniModal"
              >
                <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                  d="M441 7l32 32 32 32c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-15-15L417.9 128l55 55c9.4 9.4 9.4 24.6 0 33.9s-24.6 9.4-33.9 0l-72-72L295 73c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l55 55L422.1 56 407 41c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0zM210.3 155.7l61.1-61.1c.3 .3 .6 .7 1 1l16 16 56 56 56 56 16 16c.3 .3 .6 .6 1 1l-191 191c-10.5 10.5-24.7 16.4-39.6 16.4H97.9L41 505c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l57-57V325.3c0-14.9 5.9-29.1 16.4-39.6l43.3-43.3 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57 41.4-41.4 57 57c6.2 6.2 16.4 6.2 22.6 0s6.2-16.4 0-22.6l-57-57z"
                />
              </svg>
              <span
                class="font-bold text-sm xl:text-base cursor-pointer hidden sm:block"
                @click="openVaccinazioniModal"
              >
                Vaccinazioni
              </span>

              <!-- <ArrowUpRightIcon
                class="h-8 text-theme-41 mx-2"
                @click="openVaccinazioniModal"
              /> -->
            </div>

            <div
              v-if="pet.weight"
              id="weight"
              class="text-left flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 113.66 114.16"
                class="h-4 text-center mr-3 ml-1 fill-current"
              >
                <g data-name="Layer 2">
                  <g data-name="Layer 1">
                    <path
                      d="M56.82 114.16H16.09c-10.24 0-16.08-5.8-16.08-16Q0 64.27 0 30.37c0-10.2 5.86-16.22 16.1-15.91 6.38.19 11.37-1 16.67-5.57C48-4.32 70.81-2.46 84.87 12.17a6.67 6.67 0 0 0 5.39 2.26 76.75 76.75 0 0 1 12.42.57c5.94 1 10.3 6.17 10.86 12.18.1 1.08.11 2.18.11 3.26v67.49c0 10.64-5.66 16.23-16.4 16.23Zm.09-46.26a28.62 28.62 0 1 0 .2-57.23C41.09 10.66 28 23.6 28 39.33A28.86 28.86 0 0 0 56.91 67.9Z"
                    />
                    <path
                      d="M65.47 49.11c0 5.43-3.53 9.26-8 9.7-4 .39-8.14-2.87-9-7a8.34 8.34 0 0 1 5.89-9.94 5.59 5.59 0 0 0 4.21-3.91c1.64-4.24 3.49-8.39 5.28-12.56.91-2.13 1.82-4.46 4.85-3.48 2.31.76 2.7 2.83 1.34 6-1.9 4.45-3.76 8.92-5.76 13.32-.75 1.64-1.06 3 .15 4.6a9.52 9.52 0 0 1 1.04 3.27Z"
                    />
                  </g>
                </g>
              </svg>
              <span class="font-bold text-sm xl:text-base hidden sm:block"
                >Peso:
              </span>
              <span class="text-xs xl:text-sm font-normal ml-1">
                {{ pet.weight }}
              </span>
              <div v-if="pet.weight">
                <span
                  v-if="pet.weight === 1"
                  class="text-xs xl:text-sm font-normal ml-1 hidden sm:block"
                >
                  kg</span
                >
                <span
                  v-else
                  class="text-xs xl:text-sm font-normal ml-1 hidden sm:block"
                >
                  kgs</span
                >
              </div>
            </div>

            <div
              v-if="pet.body_surface || pet.body_score"
              id="bodyscore-surface"
              class="flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="w-4 text-center mr-3 ml-1 fill-current"
              >
                <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 64C238.3 64 224 78.33 224 96C224 113.7 238.3 128 256 128C273.7 128 288 113.7 288 96C288 78.33 273.7 64 256 64zM256 416C291.3 416 320 387.3 320 352C320 334.6 313.1 318.9 301.9 307.4L365.1 161.7C371.3 149.5 365.8 135.4 353.7 130C341.5 124.7 327.4 130.2 322 142.3L257.9 288C257.3 288 256.6 287.1 256 287.1C220.7 287.1 192 316.7 192 352C192 387.3 220.7 416 256 416V416zM144 112C126.3 112 112 126.3 112 144C112 161.7 126.3 176 144 176C161.7 176 176 161.7 176 144C176 126.3 161.7 112 144 112zM96 288C113.7 288 128 273.7 128 256C128 238.3 113.7 224 96 224C78.33 224 64 238.3 64 256C64 273.7 78.33 288 96 288zM416 224C398.3 224 384 238.3 384 256C384 273.7 398.3 288 416 288C433.7 288 448 273.7 448 256C448 238.3 433.7 224 416 224z"
                />
              </svg>

              <div class="text-left flex items-center">
                <template v-if="pet.body_score">
                  <span class="font-bold text-sm xl:text-base hidden sm:block"
                    >Body Score:
                  </span>
                  <span class="text-xs xl:text-sm font-normal ml-1">
                    {{ pet.body_score ? pet.body_score : '' }}
                  </span>
                </template>
                <template v-else-if="pet.body_surface">
                  <span class="font-bold text-sm xl:text-base hidden sm:block"
                    >Body Surface:
                  </span>
                  <span class="text-xs xl:text-sm font-normal ml-1">
                    {{ pet.body_surface ? pet.body_surface : '' }}
                  </span>
                </template>
              </div>
            </div>

            <div v-if="pet.microchip" id="microchip" class="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 109.72 109.77"
                class="h-4 text-center mr-2 ml-1 fill-current"
              >
                <g data-name="Layer 2">
                  <g data-name="Layer 1">
                    <path
                      d="M58.09 96.31v9.25c0 2.29-.74 4.19-3.23 4.2s-3.22-1.89-3.17-4.2c.06-3 0-6.06 0-9.25H37.44v9.22c0 2.28-.71 4.2-3.19 4.24s-3.26-1.85-3.21-4.17c.06-3 0-6.07 0-9.38-5 0-9.57-.59-13.17-4.17s-4.27-7.94-4-13.12c-3.31 0-6.43.19-9.51-.08A5.62 5.62 0 0 1 .54 77c-1.49-2.17.28-4.67 3.27-4.79 3.22-.12 6.45 0 9.86 0V58.29h-9C1.52 58.25 0 57.11.14 54.81.31 52 2.32 51.53 4.6 51.53h9.05V37.64H4.46c-3.02 0-4.41-1.19-4.32-3.42.13-2.82 2.1-3.33 4.4-3.34h9.17c-.35-11.76 5.49-17.46 17.36-17.31V4.01c0-2.23.79-3.93 3.13-4s3.26 1.67 3.26 3.87v9.57h14.25V4.29c0-2.27.56-4.06 3.11-4.31 2.05-.21 3.25 1.5 3.29 4.44s0 5.89 0 9h14.25v-9.4c0-2.2.71-4 3.06-4 2.5 0 3.34 1.78 3.33 4.08v9.42c4.89.08 9.49.57 13.07 4.17s4.39 7.91 4.1 13.15c3.25 0 6.36-.15 9.45.08a5.64 5.64 0 0 1 3.69 1.59c1.7 2.09 0 4.92-3.08 5.05-3.22.14-6.45 0-9.85 0v13.9h8.94c3.12 0 4.77 1.23 4.58 3.47-.24 2.72-2.18 3.3-4.5 3.28h-9v13.97h8.91c3.14 0 4.78 1.22 4.6 3.46-.22 2.72-2.15 3.31-4.48 3.3h-9.17c.15 5-.53 9.53-4.13 13.1s-8 4.27-13.12 4.12v8.59c0 .94.18 2.17-.32 2.77-.81 1-2.29 2.31-3.17 2.11a4.33 4.33 0 0 1-2.72-3 98.68 98.68 0 0 1-.13-10.35ZM54.87 82.5H77.8c3.77 0 4.86-1 4.87-4.66V31.98c0-3.58-1.1-4.66-4.59-4.66H31.65c-3.45 0-4.51 1-4.52 4.37v46.43c0 3.38 1.07 4.37 4.52 4.38q11.61.02 23.22 0Z"
                    />
                    <path d="M34.62 75.27V34.55h40.47v40.72Z" />
                  </g>
                </g>
              </svg>

              <div class="text-left flex items-center">
                <span class="font-bold text-sm xl:text-base hidden sm:block"
                  >Microchip:
                </span>
                <span class="text-xs xl:text-sm font-normal ml-1">
                  {{ pet.microchip }}
                </span>
              </div>
            </div>

            <div
              v-if="pet.pet_info.blood_group"
              id="blood"
              class="flex items-center"
            >
              <i class="fas fa-tint text-lg text-center w-5 mr-1" />
              <div class="text-left flex items-center">
                <span class="font-bold text-sm xl:text-base hidden sm:block"
                  >:
                </span>
                <span class="text-xs xl:text-sm font-normal ml-1">
                  {{ pet.pet_info ? pet.pet_info.blood_group : '--' }}
                </span>
              </div>
            </div>

            <div id="sterilized" class="flex items-center">
              <i class="fas fa-stethoscope text-sm mr-2"></i>
              <div class="text-left flex items-center">
                <span class="font-bold text-sm xl:text-base hidden sm:block"
                  >{{ pet.sterilized ? 'Sterilizzato' : 'Non sterilizzato' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal content -->
      <div class="grid grid-cols-5 grid-rows-1 scheda-container relative">
        <div
          v-if="
            (!selectedAction && (!selectedModal || !pdfPreviewSource)) ||
            (selectedAction && !selectedModal && !pdfPreviewSource)
          "
          class="col-span-2 mob-mode flex items-start flex-row-reverse"
        >
          <div class="icon-chev-cont" @click="handleMoveBar">
            <div class="icon-chev">
              <ChevronsRightIcon
                v-if="showMobNav"
                class="text-theme-41 hover:text-theme-47"
              />
              <ChevronsLeftIcon
                v-if="!showMobNav"
                class="text-theme-41 hover:text-theme-47"
              />
            </div>
          </div>
          <div
            class="overflow-auto scrollbar_custom scheda_leftside semilg:w-full semilg:overflow-auto"
            :class="isMobile ? (showMobNav ? 'show' : 'hide') : 'show'"
          >
            <div
              v-if="
                permissions &&
                permissions['schede cliniche'] &&
                permissions['schede cliniche'].view_medicaltemplate &&
                permissions['schede cliniche'].view_medicaltemplate.enabled ===
                  true
              "
              class="flex items-center gap-2 p-2 bg-white border border-theme-45 rounded-sm"
            >
              <h3 class="font-semibold flex-0">Filtra per key</h3>
              <v-select
                v-model="selectedKeys"
                class="flex-1"
                multiple
                label="key"
                placeholder="Seleziona una o più key"
                :options="keys"
                :reduce="(value) => value.id"
                @option:selected="refreshTable"
                @option:deselected="refreshTable"
              />
            </div>
            <vue-collapsible-panel-group
              v-if="
                permissions &&
                permissions['schede cliniche'] &&
                permissions['schede cliniche'].view_medicaltemplate &&
                permissions['schede cliniche'].view_medicaltemplate.enabled ===
                  true
              "
              class="mb-28"
            >
              <vue-collapsible-panel :expanded="false">
                <template #title>
                  <p class="w-full text-center nunito font-bold text-sm">
                    Casi Clinici
                    <span class="text-sm">({{ ccDocsTotalRecords }})</span>
                  </p>
                </template>
                <template #content>
                  <CasiCliniciTable
                    v-if="tablesRefresh === false"
                    class="col-span-2 row-span-1"
                    :table-config-clinic-doc="tableConfig_clinic_cc_doc"
                    :total-records="ccDocsTotalRecords"
                    :pagination="paginationCcDocs"
                    :for-page-option="forPageOptionCcDocs"
                    :documents="ccDocs"
                    :selected-patient="true"
                    :searching="searchingCcDocs"
                    @getMedical="setFiltersCcDocs"
                    @handleDetailsModal="openDetailsModal"
                    @handleEdit="openModifyScheda"
                    @changePagination="changePaginationCcDocs"
                    @changeNumPages="changeNumPagesCcDocs"
                    @showPdfPreview="handlePdfPreview"
                    @showCasePdfPreview="handleCasePdfPreview"
                  />
                  <span v-else class="flex flex-col items-center m-2">
                    <RotateCwIcon class="animate-spin w-full h-15 scale-150" />
                    Caricamento in corso...
                  </span>
                </template>
              </vue-collapsible-panel>
              <vue-collapsible-panel :expanded="false">
                <template #title>
                  <p class="w-full text-center nunito font-bold text-sm">
                    Schede cliniche
                    <span class="text-sm">({{ docsTotalRecords }})</span>
                  </p>
                </template>
                <template #content>
                  <SchedeClinicheTable
                    v-if="tablesRefresh === false"
                    class="col-span-2 row-span-1"
                    :table-config-clinic-doc="tableConfig_clinic_doc"
                    :total-records="docsTotalRecords"
                    :pagination="paginationDocs"
                    :for-page-option="forPageOptionDocs"
                    :documents="docs"
                    :selected-patient="true"
                    :searching="searchingDocs"
                    @getMedical="setFiltersDocs"
                    @handleDetailsModal="openDetailsModal"
                    @handleEdit="openModifyScheda"
                    @changePagination="changePaginationDocs"
                    @changeNumPages="changeNumPagesDocs"
                    @showPdfPreview="handlePdfPreview"
                  />
                  <span v-else class="flex flex-col items-center m-2">
                    <RotateCwIcon class="animate-spin w-full h-15 scale-150" />
                    Caricamento in corso...
                  </span>
                </template>
              </vue-collapsible-panel>
              <vue-collapsible-panel :expanded="false">
                <template #title>
                  <p class="w-full text-center nunito font-bold text-sm">
                    Referti strumentali
                    <span class="text-sm">({{ reportDocsTotalRecords }})</span>
                  </p>
                </template>
                <template #content>
                  <SchedeClinicheTable
                    v-if="tablesRefresh === false"
                    class="col-span-2 row-span-1"
                    :table-config-clinic-doc="tableConfig_clinic_doc"
                    :total-records="reportDocsTotalRecords"
                    :pagination="paginationReportDocs"
                    :for-page-option="forPageOptionReports"
                    :documents="reportDocs"
                    :selected-patient="true"
                    :searching="searchingReportsDocs"
                    @getMedical="setFilterReportDocs"
                    @handleDetailsModal="openDetailsModal"
                    @handleEdit="openModifyScheda"
                    @changePagination="changePaginationReports"
                    @changeNumPages="changeNumPagesReports"
                    @showPdfPreview="handlePdfPreview"
                  />
                  <span v-else class="flex flex-col items-center m-2">
                    <RotateCwIcon class="animate-spin w-full h-15 scale-150" />
                    Caricamento in corso...
                  </span>
                </template>
              </vue-collapsible-panel>
              <vue-collapsible-panel :expanded="false">
                <template #title>
                  <p class="w-full text-center nunito font-bold text-sm">
                    Documenti di supporto
                    <span class="text-sm">({{ supportDocsTotalRecords }})</span>
                  </p>
                </template>
                <template #content>
                  <SchedeClinicheTable
                    v-if="tablesRefresh === false"
                    class="col-span-2 row-span-1"
                    :table-config-clinic-doc="tableConfig_clinic_doc"
                    :total-records="supportDocsTotalRecords"
                    :pagination="paginationSupportDocs"
                    :for-page-option="forPageOptionSupport"
                    :documents="supportDocs"
                    :selected-patient="true"
                    :searching="searchingSupportDocs"
                    @getMedical="setFilterSupportDocs"
                    @handleDetailsModal="openDetailsModal"
                    @handleEdit="openModifyScheda"
                    @changePagination="changePaginationSupport"
                    @changeNumPages="changeNumPagesSupport"
                    @showPdfPreview="handlePdfPreview"
                  />
                  <span v-else class="flex flex-col items-center m-2">
                    <RotateCwIcon class="animate-spin w-full h-15 scale-150" />
                    Caricamento in corso...
                  </span>
                </template>
              </vue-collapsible-panel>
              <vue-collapsible-panel :expanded="false">
                <template #title>
                  <p class="w-full text-center nunito font-bold text-sm">
                    Allegati clinici
                    <span v-if="totalAllegatiClinici" class="text-sm"
                      >({{ totalAllegatiClinici }})</span
                    >
                  </p>
                </template>
                <template #content>
                  <PetDocuments
                    :pet-details="pet"
                    @setTotalRecords="(event) => setTotalRecords(event)"
                  />
                </template>
              </vue-collapsible-panel>
              <vue-collapsible-panel :expanded="false">
                <template #title>
                  <p class="w-full text-center nunito font-bold text-sm">
                    Referti di laboratorio
                    <span class="text-sm">({{ labDocsTotalRecords }})</span>
                  </p>
                </template>
                <template #content>
                  <SchedeClinicheTable
                    v-if="tablesRefresh === false"
                    class="col-span-2 row-span-1"
                    :table-config-clinic-doc="tableConfig_clinic_doc"
                    :total-records="labDocsTotalRecords"
                    :pagination="paginationLabDocs"
                    :for-page-option="forPageOptionLab"
                    :documents="labDocs"
                    :selected-patient="true"
                    :searching="searchingLabDocs"
                    :confirm-check="true"
                    @getMedical="setFilterLabDocs"
                    @handleDetailsModal="openDetailsModal"
                    @handleEdit="openModifyScheda"
                    @changePagination="changePaginationLab"
                    @changeNumPages="changeNumPagesLab"
                    @showPdfPreview="handlePdfPreview"
                  />
                  <span v-else class="flex flex-col items-center m-2">
                    <RotateCwIcon class="animate-spin w-full h-15 scale-150" />
                    Caricamento in corso...
                  </span>
                </template>
              </vue-collapsible-panel>
            </vue-collapsible-panel-group>
          </div>
        </div>

        <!-- End Tabelle -->
        <!-- Start Scheda clinica visualizzazione parte sinistra pagina -->
        <div
          v-else-if="pdfPreviewSource && (selectedAction || selectedModal)"
          class="col-span-2"
        >
          <div class="w-full flex items-center gap-2 justify-end mt-6">
            <button
              type="button"
              class="orange-btn-inverted"
              @click="handleSendMail"
            >
              MAIL
            </button>
            <button
              type="button"
              class="orange-btn-inverted"
              @click="handlePrintPdf"
            >
              STAMPA
            </button>
            <button
              type="button"
              class="orange-btn-inverted"
              @click="handleDownloadPdf"
            >
              SCARICA
            </button>
            <button
              type="button"
              class="orange-btn-inverted"
              @click="handleIndietroPdf"
            >
              INDIETRO
            </button>
          </div>
          <VuePdfEmbed ref="pdfComponent" :source="pdfPreviewSource" />
        </div>
        <div
          v-else
          class="col-span-2 row-span-1 overflow-auto scrollbar-none"
          style="max-height: 95vh"
        >
          <DetailsNoModal
            v-if="selectedModal && selectedAction"
            :id-template="selectedModal.id"
            :title="
              selectedModal.document_type +
              ' Ultima modifica: ' +
              $FormatDateMin(selectedModal.last_update)
            "
            :modify-scheda-clinica="false"
            :New="false"
            :configuration="selectedModal.layout_id"
            :pet_id="pet.id"
            :keys_name="selectedModal.keys_name"
            :keys_ids="selectedModal.keys_id"
            :casi_options="casi"
            :selected_caso="
              selectedModal ? selectedModal.clinical_case_id : null
            "
            @getCasi="getCasi"
            @close="removeSelectedModal"
            @refreshPetData="refreshPetData"
          />
        </div>
        <!-- End Scheda clinica visualizzazione parte sinistra pagina -->

        <!-- Inizio parte destra della pagina -->
        <div
          v-if="
            permissions &&
            permissions['schede cliniche'] &&
            ((permissions['schede cliniche'].store_medicaltemplate &&
              permissions['schede cliniche'].store_medicaltemplate.enabled ===
                true) ||
              (permissions['schede cliniche'].view_medicaltemplate &&
                permissions['schede cliniche'].view_medicaltemplate.enabled ===
                  true))
          "
          class="col-span-5 semilg:col-span-3"
          :class="
            pdfPreviewSource
              ? 'overflow-scroll scrollbar-none'
              : 'overflow-hidden'
          "
          style="max-height: 85vh"
        >
          <!-- Bottoni sopra scheda -->

          <!-- <button
            v-if="selectedModal && !selectedAction"
            ref="input4"
            type="button"
            class="h-10 mt-5 row-span-1 col-span-3 inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
            @click="removeSelectedModal"
          >
            <SkipBackIcon class="h-5 w-5 mr-2" /> Interrompi visualizzazione
          </button> -->
          <!-- Scheda parte destra pagina (visualizzazione + creazione nuovo) -->
          <DetailsNoModal
            v-if="
              ((selectedAction && newTemplate) ||
                (selectedModal && !selectedAction)) &&
              permissions &&
              permissions['schede cliniche'] &&
              ((permissions['schede cliniche'].store_medicaltemplate &&
                permissions['schede cliniche'].store_medicaltemplate.enabled ===
                  true) ||
                (permissions['schede cliniche'].edit_medicaltemplate &&
                  permissions['schede cliniche'].edit_medicaltemplate
                    .enabled === true))
            "
            :key="schedaKey"
            :id-template="
              selectedModal && !selectedAction
                ? selectedModal.id
                : newTemplate.id
            "
            :pet_id="pet.id"
            :petType="pet.type"
            :medical-templates-id="
              selectedModal && !selectedAction
                ? selectedModal.id
                : newTemplate.id
            "
            :title="
              selectedModal && !selectedAction
                ? selectedModal.layout_id.document_type +
                  ' Ultima modifica: ' +
                  $FormatDateMin(selectedModal.last_update)
                : newTemplate.document_type
            "
            :modify-scheda-clinica="modifyScheda"
            :New="selectedModal && !selectedAction ? false : true"
            :configuration="
              selectedModal && !selectedAction
                ? selectedModal.layout_id
                : newTemplate
            "
            :keys_name="
              selectedModal && !selectedAction ? selectedModal.keys_name : null
            "
            :keys_ids="selectedModal ? selectedModal.keys_id : null"
            :casi_options="casi"
            :keys="keys"
            :selected_caso="
              selectedModal ? selectedModal.clinical_case_id : null
            "
            @close="
              selectedModal && !selectedAction
                ? removeSelectedModal()
                : terminateAction()
            "
            @refreshPetData="refreshPetData"
            @back="goBackSpecialistic"
            @refresh="refreshTable()"
            @openAgenda="openAgendaModal"
            @openTariffario="openTariffatioModal"
            @getCasi="getCasi"
            @refreshKeys="getKeys()"
          />
          <!-- Scelta template da utilizzare -->
          <SpecialisticChoiceNoModal
            v-else-if="
              specialisticChoice === true &&
              permissions &&
              permissions['schede cliniche'] &&
              permissions['schede cliniche'].store_medicaltemplate &&
              permissions['schede cliniche'].store_medicaltemplate.enabled ===
                true
            "
            :title="
              selectedAction === 'clinical_card'
                ? 'Schede Specialistiche'
                : selectedAction === 'report'
                ? 'Referti Strumentali'
                : selectedAction === 'support'
                ? 'Documenti di supporto'
                : selectedAction === 'referti_di_laboratorio'
                ? 'Referti di laboratorio'
                : 'Scheda clinica generale'
            "
            :options="
              selectedAction === 'report'
                ? templateReferti
                : selectedAction === 'support'
                ? templateSupport
                : selectedAction === 'referti_di_laboratorio'
                ? templateLab
                : templateSchede
            "
            @close="terminateAction"
            @specialisticSelected="handleSpecialisticChoice"
          />
          <div
            v-else-if="
              pdfPreviewSource &&
              permissions &&
              permissions['schede cliniche'] &&
              permissions['schede cliniche'].view_medicaltemplate &&
              permissions['schede cliniche'].view_medicaltemplate.enabled ===
                true
            "
          >
            <div class="w-full flex items-center gap-2 justify-end mt-6">
              <button
                type="button"
                class="orange-btn-inverted"
                @click="handleSendMail"
              >
                MAIL
              </button>
              <button
                type="button"
                class="orange-btn-inverted"
                @click="handlePrintPdf"
              >
                STAMPA
              </button>
              <button
                type="button"
                class="orange-btn-inverted"
                @click="handleDownloadPdf"
              >
                SCARICA
              </button>
              <button
                type="button"
                class="orange-btn-inverted"
                @click="handleIndietroPdf"
              >
                INDIETRO
              </button>
            </div>
            <VuePdfEmbed :source="pdfPreviewSource" />
          </div>
          <!-- Azioni disponibili nella parte destra -->
          <div
            v-else-if="
              permissions &&
              permissions['schede cliniche'] &&
              permissions['schede cliniche'].store_medicaltemplate &&
              permissions['schede cliniche'].store_medicaltemplate.enabled ===
                true
            "
            class="flex flex-col px-0 md:px-20 mt-5 gap-3"
          >
            <button
              type="button"
              class="h-10 rounded-md w-full nunito text-white font-semibold text-lg bg-theme-40"
              :class="!templateSchede ? 'disabled' : 'disabled'"
              @click="selectAction('eog')"
            >
              <ChevronsRightIcon class="h-8 text-theme-41" />
              Scheda visita generale
            </button>
            <button
              type="button"
              class="h-10 rounded-md w-full nunito text-white font-semibold text-lg bg-theme-40"
              @click="selectAction('clinical_card')"
            >
              <ChevronsRightIcon class="h-8 text-theme-41" /> Schede
              specialistiche
            </button>

            <button
              type="button"
              class="h-10 rounded-md w-full nunito text-white font-semibold text-lg bg-theme-40"
              @click="selectAction('report')"
            >
              <ChevronsRightIcon class="h-8 text-theme-41" /> Referti
              Strumentali
            </button>

            <button
              type="button"
              class="h-10 rounded-md w-full nunito text-white font-semibold text-lg bg-theme-40"
              @click="selectAction('support')"
            >
              <ChevronsRightIcon class="h-8 text-theme-41" /> Documenti di
              supporto
            </button>
            <button
              type="button"
              class="h-10 rounded-md w-full nunito text-white font-semibold text-lg bg-theme-40"
              @click="selectAction('referti_di_laboratorio')"
            >
              <ChevronsRightIcon class="h-8 text-theme-41" /> Referti di
              laboratorio
            </button>

            <!-- <button
              type="button"
              class="h-10 rounded-md w-full nunito text-white font-semibold text-lg bg-theme-40"
              @click="testAgendaModal"
            >
              <ChevronsRightIcon class="h-8 text-theme-41" /> Documenti di
              supporto
            </button> -->
          </div>
        </div>
        <!-- Fine parte destra della pagina -->

        <!-- Bottone sotto parte sinistra scheda -->
      </div>
      <div class="mb-1 py-1">
        <button
          v-if="selectedModal"
          ref="input4"
          type="button"
          class="orange-btn"
          @click="() => (selectedModal = null)"
        >
          <SkipBackIcon class="h-5 w-5 mr-2" /> Seleziona altra scheda
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import { useStore } from 'vuex'
import moment from 'moment'
import axios from '../../gateway/backend-api'
import printJS from 'print-js'
import '@swoga/vue-collapsible-panel/dist/vue-collapsible-panel.css'
import SchedeClinicheTable from '../tables/SchedeClinicheTable.vue'
import CasiCliniciTable from '../tables/CasiCliniciTable.vue'
import DetailsNoModal from './DetailsNoModal.vue'
import AgendaModal from './AgendaModal.vue'
import VaccinazioniModal from './VaccinazioniModal.vue'
import TariffarioModal from './TariffarioModal.vue'
import SpecialisticChoiceNoModal from './SpecialisticChoiceNoModal.vue'
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel
} from '@swoga/vue-collapsible-panel'
import '@swoga/vue-collapsible-panel/dist/vue-collapsible-panel.css'
import VuePdfEmbed from 'vue-pdf-embed'
import PetDocuments from '../details-pet/PetDocuments.vue'

const EOG = 'eog'
const SPECIALISTIC = 'clinical_card'
const CC = 'clinical_case'
const REPORT = 'report'
const SUPPORT = 'support'
const LAB = 'referti_di_laboratorio'

export default defineComponent({
  components: {
    SchedeClinicheTable,
    CasiCliniciTable,
    DetailsNoModal,
    SpecialisticChoiceNoModal,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
    AgendaModal,
    TariffarioModal,
    VuePdfEmbed,
    PetDocuments,
    VaccinazioniModal
  },
  props: {
    petDetails: {
      type: [Object],
      default: function () {
        return {}
      }
    }
  },
  emits: ['close'],
  setup(props) {
    const pet = ref(props.petDetails)
    const showMobNav = ref(false)
    const viewportWidth = ref(window.innerWidth)
    const isMobile = ref(false)

    const store = useStore()
    const userRoleId = ref(localStorage.getItem('userRoleId'))
    // ----- DATA -----
    const searchingDocs = ref(false)
    const searchingCcDocs = ref(false)
    const searchingDocsAll = ref(false)
    const searchingReportsDocs = ref(false)
    const searchingSupportDocs = ref(false)
    const searchingLabDocs = ref(false)

    const docs = ref([])
    const ccDocs = ref([])
    const reportDocs = ref([])
    const supportDocs = ref([])
    const labDocs = ref([])
    const docsAll = ref([])

    const filtersDocs = ref([])
    const filtersCcDocs = ref([])
    const filterReportDocs = ref([])
    const filterSupportDocs = ref([])
    const filterLabDocs = ref([])
    const filterDocsAll = ref([])

    const docsTotalRecords = ref(0)
    const reportDocsTotalRecords = ref(0)
    const supportDocsTotalRecords = ref(0)
    const labDocsTotalRecords = ref(0)
    const docsAllTotalRecords = ref(0)
    const ccDocsTotalRecords = ref(0)

    const forPageOptionDocs = ref([5, 10, 15])
    const forPageOptionCcDocs = ref([5, 10, 15])
    const forPageOptionReports = ref([5, 10, 15])
    const forPageOptionSupport = ref([5, 10, 15])
    const forPageOptionLab = ref([5, 10, 15])
    const forPageOptionAll = ref([5, 10, 15])

    const showAgenda = ref(false)
    const showTariffario = ref(false)

    const refreshPetData = () => {
      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/pet?pet_id=' +
            props.petDetails.id
        )
        .then((res) => {
          pet.value = res.data.data[0]
        })
    }

    const paginationDocs = ref({
      page: 1,
      forPage: forPageOptionDocs.value[0]
    })
    const paginationCcDocs = ref({
      page: 1,
      forPage: forPageOptionCcDocs.value[0]
    })
    const paginationReportDocs = ref({
      page: 1,
      forPage: forPageOptionReports.value[0]
    })
    const paginationSupportDocs = ref({
      page: 1,
      forPage: forPageOptionReports.value[0]
    })
    const paginationLabDocs = ref({
      page: 1,
      forPage: forPageOptionReports.value[0]
    })
    const paginationDocsAll = ref({
      page: 1,
      forPage: forPageOptionDocs.value[0]
    })

    const tableConfig_clinic_doc = ref([
      {
        key: 'date',
        title: 'Creazione/Modifica',
        type: 'date'
      },

      {
        key: 'document',
        title: 'Documento',
        type: 'string'
      },
      {
        key: 'author',
        title: 'Autore',
        type: 'string'
      },
      {
        key: 'reason',
        title: 'Motivo della visita',
        type: 'none'
      },
      {
        key: 'petcorner',
        title: 'Mostra Petcorner',
        type: 'select',
        option: [
          { name: 'Si', value: true },
          { name: 'No', value: false }
        ]
      }
    ])

    const tableConfig_clinic_cc_doc = ref([
      {
        key: 'none',
        title: '',
        type: 'none'
      },
      {
        key: 'date',
        title: 'Creazione / Modifica',
        type: 'date'
      },

      {
        key: 'document',
        title: 'Documento',
        type: 'string'
      },
      {
        key: 'author',
        title: 'Autore',
        type: 'string'
      },
      {
        key: 'reason',
        title: 'Motivo della visita',
        type: 'none'
      },
      {
        key: 'petcorner',
        title: 'Mostra Petcorner',
        type: 'select',
        option: [
          { name: 'Si', value: true },
          { name: 'No', value: false }
        ]
      },
      {
        key: 'actions',
        title: 'Azioni rapide',
        type: 'none'
      }
    ])
    const selectedModal = ref(null)
    const selectedAction = ref(null)
    const newTemplate = ref(null)
    const specialisticChoice = ref(false)
    const modifyScheda = ref(false)
    const schedaKey = ref(0)

    const keys = ref([])
    const casi = ref([])
    const selectedKeys = ref(null)

    const tablesRefresh = ref(false)

    const showVaccinazioni = ref(false)

    const openVaccinazioniModal = () => {
      showVaccinazioni.value = true
    }

    const closeVaccinazioniModal = () => {
      showVaccinazioni.value = false
    }

    const totalAllegatiClinici = ref(null)
    const setTotalRecords = (value) => {
      totalAllegatiClinici.value = value
    }

    // axios
    //   .get('/schede_cliniche_table_config') // scarico la configurazione della tabella
    //   .then((resp) => {
    //     const temp = []
    //     for (var elem of resp.data) {
    //       temp.push({ ...elem, value: '' })
    //     }
    //     tableConfig_clinic_doc.value = temp
    //   })

    // ----- METHODS -----

    onMounted(() => {
      if (viewportWidth.value < 900) {
        isMobile.value = true
      }
      console.log('isMobile.value', isMobile.value)
    })

    const handleSendMail = () => {
      store.dispatch('commonComponents/openSendMessageModal', {
        data: {
          type: 'mail',
          client: props.petDetails.owners[0],
          attachements: [
            {
              id: pdfPreview.value.medicaltemplate_id,
              table: 'medicaltemplate',
              name: 'Scheda Clinica ' + pdfPreview.value.medicaltemplate_id,
              date: pdfPreview.value.lastUpdate
            }
          ]
        },
        confirm: (data) => {
          console.log('invio la mail', data)
          let attachements = []
          if (data && data.selectedFile) {
            data.selectedFile.forEach((el) => {
              attachements.push({
                id: el.id,
                type: el.table,
                file_name: el.name
              })
            })
          }
          store.dispatch('commonComponents/showLoadingModalAction')
          axios
            .post('/' + localStorage.getItem('clinic') + '/mailersend/send', {
              customer_id: props.petDetails.owners[0]?.id,
              type: 'email',
              email: data.destinatario,
              //text: 'Documento: ' + props.title,
              object: data.titolo,
              body: data.message,
              email_type: 'comunication',
              attachments: attachements,
              pet_id: props.petDetails.id,
              cc: data.cc
            })
            .then(() => {
              store.dispatch('commonComponents/openNotificationModal', {
                message: 'Email inviata con successo!'
              })
              store.dispatch('commonComponents/hideLoadingModalAction')
            })
            .catch(() => {
              store.dispatch('commonComponents/openNotificationModal', {
                message: "Errore durante l'invio della mail"
              })
              store.dispatch('commonComponents/hideLoadingModalAction')
            })
        }
      })
    }

    const handleMoveBar = () => {
      showMobNav.value = !showMobNav.value
    }

    const openDetailsModal = (elem) => {
      // chiamata per aprire  la scheda clinica da visualizzare
      removeSelectedModal()
      setTimeout(function () {
        selectedModal.value = elem
      }, 1000)
    }

    const openModifyScheda = (elem) => {
      // chiamata per aprire  la scheda clinica da modificare
      modifyScheda.value = true
      selectedModal.value = elem
      schedaKey.value += 1
    }

    const selectAction = (action) => {
      // gestisce cosa fare come nuova azione nel componente
      selectedAction.value = action
      switch (action) {
        case EOG:
          newTemplate.value = schedaGeneraleTemplate.value
          break
        case SPECIALISTIC:
          specialisticChoice.value = true
          break
        case REPORT:
          specialisticChoice.value = true
          break
        case SUPPORT:
          specialisticChoice.value = true
          break
        case LAB:
          specialisticChoice.value = true
          break
        default:
          console.log('Azione non riconosciuta')
      }
    }

    const handleSpecialisticChoice = (elem) => {
      // apro la nuova scheda da compilare scelta tra quelle disponibili
      newTemplate.value = elem
      specialisticChoice.value = false
    }

    const terminateAction = () => {
      // interrompo azioni di creazione o scelta nuova scheda
      selectedAction.value = null
      newTemplate.value = null
      selectedModal.value = null
      specialisticChoice.value = false
      modifyScheda.value = false
      pdfPreviewSource.value = null
      pdfPreview.value = null
    }

    const removeSelectedModal = () => {
      // rimuovo scheda in visualizzazione

      selectedModal.value = null
      pdfPreviewSource.value = null
      pdfPreview.value = null
      modifyScheda.value = false
    }

    const goBackSpecialistic = () => {
      //selectedAction.value = 'specialistic'
      newTemplate.value = null

      if (selectedAction.value !== EOG) specialisticChoice.value = true
    }

    const getMedical = (type) => {
      var filters = null

      let params = { pet_id: props.petDetails.id, type: type }

      if (type == SPECIALISTIC) {
        params['page'] = paginationDocs.value.page
        params['per_page'] = paginationDocs.value.forPage
        params['filters'] = filtersDocs.value

        // page = paginationDocs.value.page
        // per_page = paginationDocs.value.forPage
        // filters = filtersDocs.value
      } else if (type == REPORT) {
        params['page'] = paginationReportDocs.value.page
        params['per_page'] = paginationReportDocs.value.forPage
        params['filters'] = filterReportDocs.value
      } else {
        if (type == SUPPORT) {
          params['page'] = paginationSupportDocs.value.page
          params['per_page'] = paginationSupportDocs.value.forPage
          params['filters'] = filterSupportDocs.value
        } else {
          if (type == LAB) {
            params['page'] = paginationLabDocs.value.page
            params['per_page'] = paginationLabDocs.value.forPage
            params['filters'] = filterLabDocs.value
          } else {
            if (type == CC) {
              params['page'] = paginationCcDocs.value.page
              params['per_page'] = paginationCcDocs.value.forPage
              params['filters'] = filtersCcDocs.value
            } else {
              params['page'] = paginationDocsAll.value.page
              params['per_page'] = paginationDocsAll.value.forPage
              params['filters'] = filterDocsAll.value
            }
          }
        }
      }

      if (filters != null) {
        Object.entries(filters).forEach(([k, v]) => {
          if (v) {
            params[k] = v
          }
        })
      }

      if (selectedKeys.value && selectedKeys.value.length > 0) {
        params['keys_id'] = selectedKeys.value
      }

      axios
        .post('/' + localStorage.getItem('clinic') + '/medical', params) // scarico la lista di documenti
        .then((res) => {
          if (type == SPECIALISTIC) {
            docs.value = res.data.data
            docsTotalRecords.value = res.data.total
            searchingDocs.value = false
          } else if (type == REPORT) {
            reportDocs.value = res.data.data
            reportDocsTotalRecords.value = res.data.total
            searchingReportsDocs.value = false
          } else {
            if (type == SUPPORT) {
              supportDocs.value = res.data.data
              supportDocsTotalRecords.value = res.data.total
              searchingSupportDocs.value = false
            } else {
              if (type == LAB) {
                labDocs.value = res.data.data
                labDocsTotalRecords.value = res.data.total
                searchingLabDocs.value = false
              } else {
                if (type == CC) {
                  ccDocs.value = res.data.data
                  ccDocsTotalRecords.value = res.data.total
                  searchingCcDocs.value = false
                } else {
                  docsAll.value = res.data.data
                  docsAllTotalRecords.value = res.data.total
                  searchingDocsAll.value = false
                }
              }
            }
          }
          tablesRefresh.value = false
        })
    }

    const changePaginationDocs = (value) => {
      changePagination(value, CC)
    }

    const changePaginationCcDocs = (value) => {
      changePagination(value, SPECIALISTIC)
    }
    const changePaginationReports = (value) => {
      changePagination(value, REPORT)
    }

    const changePaginationSupport = (value) => {
      changePagination(value, SUPPORT)
    }

    const changePaginationLab = (value) => {
      changePagination(value, LAB)
    }

    const changePaginationDocsAll = (value) => {
      changePagination(value, 'all')
    }

    const changePagination = (value, type) => {
      if (type == SPECIALISTIC) {
        paginationDocs.value.page = value.page
        paginationDocs.value.forPage = value.forPage
        docs.value = []
        searchingDocs.value = true
        getMedical(SPECIALISTIC)
      } else if (type == REPORT) {
        paginationReportDocs.value.page = value.page
        paginationReportDocs.value.forPage = value.forPage
        reportDocs.value = []
        searchingReportsDocs.value = true
        getMedical(REPORT)
      } else {
        if (type == SUPPORT) {
          paginationSupportDocs.value.page = value.page
          paginationSupportDocs.value.forPage = value.forPage
          supportDocs.value = []
          searchingSupportDocs.value = true
          getMedical(SUPPORT)
        } else {
          if (type == LAB) {
            paginationLabDocs.value.page = value.page
            paginationLabDocs.value.forPage = value.forPage
            labDocs.value = []
            searchingLabDocs.value = true
            getMedical(LAB)
          } else {
            if (type == CC) {
              paginationCcDocs.value.page = value.page
              paginationCcDocs.value.forPage = value.forPage
              ccDocs.value = []
              searchingCcDocs.value = true
              getMedical(CC)
            } else {
              paginationDocsAll.value.page = value.page
              paginationDocsAll.value.forPage = value.forPage
              docsAll.value = []
              searchingDocsAll.value = true
              getMedical('all')
            }
          }
        }
      }
    }

    const setFiltersDocs = (value) => {
      filtersDocs.value = value
      docs.value = []
      searchingDocs.value = true
      getMedical(SPECIALISTIC)
    }

    const setFiltersCcDocs = (value) => {
      filtersCcDocs.value = value
      ccDocs.value = []
      searchingCcDocs.value = true
      getMedical(CC)
    }
    const setFilterReportDocs = (value) => {
      filterReportDocs.value = value
      reportDocs.value = []
      searchingReportsDocs.value = true
      getMedical(REPORT)
    }
    const setFilterSupportDocs = (value) => {
      filterSupportDocs.value = value
      supportDocs.value = []
      searchingSupportDocs.value = true
      getMedical(SUPPORT)
    }
    const setFilterLabDocs = (value) => {
      filterLabDocs.value = value
      labDocs.value = []
      searchingLabDocs.value = true
      getMedical(LAB)
    }
    const setFilterDocsAll = (value) => {
      filterDocsAll.value = value
      docsAll.value = []
      searchingDocsAll.value = true
      getMedical('all')
    }

    const changeNumPagesDocs = (value) => {
      changeNumPages(value, SPECIALISTIC)
    }
    const changeNumPagesCcDocs = (value) => {
      changeNumPages(value, CC)
    }
    const changeNumPagesReports = (value) => {
      changeNumPages(value, REPORT)
    }
    const changeNumPagesSupport = (value) => {
      changeNumPages(value, SUPPORT)
    }
    const changeNumPagesLab = (value) => {
      changeNumPages(value, LAB)
    }
    const changeNumPagesDocsAll = (value) => {
      changeNumPages(value, 'all')
    }
    const changeNumPages = (elem, type) => {
      if (type == SPECIALISTIC) {
        paginationDocs.value.forPage = elem.target.value
        docs.value = []
        searchingDocs.value = true
        getMedical(SPECIALISTIC)
      } else if (type == REPORT) {
        paginationReportDocs.value.forPage = elem.target.value
        reportDocs.value = []
        searchingReportsDocs.value = true
        getMedical(REPORT)
      } else if (type == SUPPORT) {
        paginationSupportDocs.value.forPage = elem.target.value
        supportDocs.value = []
        searchingSupportDocs.value = true
        getMedical(SUPPORT)
      } else if (type == LAB) {
        paginationSupportDocs.value.forPage = elem.target.value
        supportDocs.value = []
        searchingSupportDocs.value = true
        getMedical(LAB)
      } else if (type == CC) {
        paginationCcDocs.value.forPage = elem.target.value
        ccDocs.value = []
        searchingCcDocs.value = true
        getMedical(CC)
      } else if (type == 'all') {
        paginationDocsAll.value.forPage = elem.target.value
        docsAll.value = []
        searchingDocsAll.value = true
        getMedical('all')
      }
    }

    const refreshTable = () => {
      tablesRefresh.value = true
      getKeys()
      getMedical(CC)
      getMedical(SPECIALISTIC)
      getMedical(REPORT)
      getMedical(SUPPORT)
      getMedical(LAB)
      getMedical('all')
      // aggiorno le tabelle

      terminateAction()
    }

    const openAgendaModal = () => {
      showAgenda.value = true
    }

    const openTariffatioModal = () => {
      showTariffario.value = true
    }

    const getKeys = () => {
      axios
        .get('/' + localStorage.getItem('clinic') + '/medicalkey') // scarico la lista di documenti
        .then((res) => {
          keys.value = res.data
        })
    }

    const getCasi = () => {
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/medical/clinicalCaseList',
          { pet_id: props.petDetails.id }
        ) // scarico la lista di documenti
        .then((res) => {
          casi.value = res.data
        })
    }

    const pdfPreviewSource = ref(null)
    const pdfLink = ref(null)
    const pdfComponent = ref(null)
    const pdfPreview = ref(null)

    const handlePdfPreview = (doc) => {
      console.log('voglio vedere il pdf')

      store.dispatch('commonComponents/showLoadingModalAction')
      axios({
        url:
          '/' +
          localStorage.getItem('clinic') +
          '/download/' +
          props.petDetails.id +
          '/medical/' +
          doc.medicaltemplate_id +
          '/pdf',
        method: 'POST',
        responseType: 'blob'
      })
        .then((resp) => {
          const url = window.URL.createObjectURL(
            new Blob([resp.data], { type: 'application/pdf' })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            doc.document_type +
              '_' +
              props.petDetails.name +
              '_' +
              moment.unix(doc.last_update).format('DD-MM-YYYY') +
              '.pdf'
          )
          document.body.appendChild(link)
          pdfPreview.value = doc
          pdfPreviewSource.value = url
          pdfLink.value = link
          store.dispatch('commonComponents/hideLoadingModalAction')
        })
        .catch(() => {
          store.dispatch('commonComponents/hideLoadingModalAction')
        })
    }

    const handleCasePdfPreview = (doc) => {
      console.log('voglio vedere il pdf')

      store.dispatch('commonComponents/showLoadingModalAction')
      axios({
        url:
          '/' +
          localStorage.getItem('clinic') +
          '/download/' +
          props.petDetails.id +
          '/medical/' +
          doc.medicaltemplate_id +
          '/pdf',
        method: 'POST',
        responseType: 'blob',
        data: { clinical_case: true }
      })
        .then((resp) => {
          const url = window.URL.createObjectURL(
            new Blob([resp.data], { type: 'application/pdf' })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute(
            'download',
            doc.document_type +
              '_' +
              props.petDetails.name +
              '_' +
              moment.unix(doc.last_update).format('DD-MM-YYYY') +
              '.pdf'
          )
          document.body.appendChild(link)
          pdfPreview.value = doc
          pdfPreviewSource.value = url
          pdfLink.value = link
          store.dispatch('commonComponents/hideLoadingModalAction')
        })
        .catch(() => {
          store.dispatch('commonComponents/hideLoadingModalAction')
        })
    }

    const handleIndietroPdf = () => {
      pdfPreviewSource.value = null
      pdfPreview.value = null
      pdfLink.value = null
    }

    const handleDownloadPdf = () => {
      pdfLink.value.click()
    }

    const handlePrintPdf = () => {
      printJS(pdfPreviewSource.value)
    }

    // ----- COMPUTED -----

    // template salvati in axios
    const templateSchede = computed(() => {
      return store.state.reusableData.templateSchede
        ? store.state.reusableData.templateSchede
        : null
    })

    const permissions = computed(() => {
      return store.state.reusableData.permissions
        ? store.state.reusableData.permissions
        : null
    })

    const schedaGeneraleTemplate = computed(() => {
      let found = null
      Object.keys(store.state.reusableData.templateSchede).forEach(function (
        arr
      ) {
        store.state.reusableData.templateSchede[arr].forEach((elem) => {
          if (elem.id === 1) found = elem
        })
      })
      // store.state.reusableData.templateSchede.forEach((arr) => {
      //   arr.forEach((elem) => {
      //     if (elem.id === 1) found = elem
      //   })
      // })

      return found
      // return store.state.reusableData.templateSchede.find(
      //   (elem) => elem.id === 1
      // )
    })

    const templateReferti = computed(() => {
      return store.state.reusableData.templateReferti
    })

    const templateSupport = computed(() => {
      return store.state.reusableData.templateSupport
    })

    const templateLab = computed(() => {
      return store.state.reusableData.templateLab
    })
    // fine template salvati in axios

    return {
      setTotalRecords,
      totalAllegatiClinici,
      viewportWidth,
      isMobile,
      handleMoveBar,
      showMobNav,
      docs,
      ccDocs,
      tableConfig_clinic_doc,
      tableConfig_clinic_cc_doc,
      templateSchede,
      openDetailsModal,
      selectedModal,
      selectedAction,
      selectAction,
      newTemplate,
      templateReferti,
      templateLab,
      specialisticChoice,
      terminateAction,
      refreshTable,
      handleSpecialisticChoice,
      reportDocs,
      removeSelectedModal,
      tablesRefresh,
      selectedKeys,
      modifyScheda,
      schedaKey,
      openModifyScheda,
      schedaGeneraleTemplate,
      docsTotalRecords,
      ccDocsTotalRecords,
      getMedical,
      reportDocsTotalRecords,
      paginationSupportDocs,
      paginationDocs,
      paginationCcDocs,
      paginationReportDocs,
      setFilterSupportDocs,
      changePagination,
      changePaginationReports,
      labDocsTotalRecords,
      labDocs,
      changePaginationDocs,
      changePaginationCcDocs,
      searchingDocs,
      searchingCcDocs,
      searchingReportsDocs,
      forPageOptionDocs,
      forPageOptionCcDocs,
      forPageOptionReports,
      changeNumPagesDocs,
      changeNumPagesCcDocs,
      changeNumPagesReports,
      searchingDocsAll,
      forPageOptionAll,
      docsAll,
      docsAllTotalRecords,
      paginationDocsAll,
      changePaginationDocsAll,
      changeNumPagesDocsAll,
      filtersDocs,
      filterReportDocs,
      filterDocsAll,
      setFiltersDocs,
      setFiltersCcDocs,
      filtersCcDocs,
      setFilterReportDocs,
      setFilterDocsAll,
      paginationLabDocs,
      goBackSpecialistic,
      supportDocs,
      forPageOptionLab,
      supportDocsTotalRecords,
      searchingSupportDocs,
      forPageOptionSupport,
      changePaginationSupport,
      templateSupport,
      changeNumPagesSupport,
      openAgendaModal,
      openTariffatioModal,
      showAgenda,
      showTariffario,
      getKeys,
      getCasi,
      userRoleId,
      keys,
      casi,
      changePaginationLab,
      setFilterLabDocs,
      changeNumPagesLab,
      handlePdfPreview,
      handleCasePdfPreview,
      pdfPreviewSource,
      handleIndietroPdf,
      handleDownloadPdf,
      handlePrintPdf,
      pdfComponent,
      permissions,
      showVaccinazioni,
      openVaccinazioniModal,
      closeVaccinazioniModal,
      handleSendMail,
      pdfPreview,
      pet,
      refreshPetData
    }
  },
  mounted() {
    this.getKeys()
    this.getCasi()
    this.getMedical(CC)
    this.getMedical(SPECIALISTIC)
    this.getMedical(REPORT)
    this.getMedical(SUPPORT)
    this.getMedical(LAB)
  }
})
</script>
