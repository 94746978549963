<template>
  <!-- BEGIN: Notification Content -->
  <div class="preview">
    <div class="text-center">
      <div
        id="max-tab-open"
        class="toastify-content hidden flex flex-col sm:flex-row"
      >
        <div class="font-medium">
          Puoi aprire massimo quattro tab alla volta!! Chiudine una per poterne
          aprire di nuove.
        </div>
      </div>
    </div>
    <div class="text-center">
      <div
        id="tab-already-open"
        class="toastify-content hidden flex flex-col sm:flex-row"
      >
        <div class="font-medium">Attenzione hai già aperto questa tab!!</div>
      </div>
    </div>
    <!--        long search notification-->
    <div class="text-center">
      <div
        id="notification-with-buttons-below-content"
        class="toastify-content hidden flex"
      >
        <FileTextIcon class="text-theme-43" />
        <div class="ml-4 mr-5 sm:mr-20">
          <div class="font-medium">Arnold Schwarzenegger</div>
          <div class="text-gray-600 mt-1">
            La ricerca del report è appena terminata!
          </div>
          <div class="mt-2.5">
            <a class="btn btn-primary py-1 px-2 mr-2" href>Anteprima</a>
            <a class="btn btn-outline-secondary py-1 px-2" href>Scarica</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Notification Content -->
</template>
<script>
export default {}
</script>
