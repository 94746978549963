<template>
  <div
    class="rounded-lg flex flex-col justify-between content-container bg-white"
  >
    <div class="flex gap-3 tariff_container px-3 pt-4 pb-1">
      <!-- primer div -->

      <div
        class="rounded-md"
        style="position: relative"
        :class="{
          'flex-1 ': openBlock === 0,
          'w-10 bg-theme-44 hover:bg-theme-40 text-theme-40 hover:text-white':
            openBlock !== 0
        }"
      >
        <h2
          v-if="openBlock !== 0"
          class="nunito cursor-pointer tariff_title flex items-center gap-2"
          @click="handleBlock(0)"
        >
          <h2 class="text-xl">Magazzini</h2>
          <h4 class="uppercase text-sm">{{ tariffario_title }}</h4>
        </h2>

        <div v-if="openBlock === 0" class="flex items-center text-theme-48">
          <button
            class="w-32 h-10 text-center rounded-tr-xl shadow-md"
            :class="[
              tariffario
                ? 'z-50 bg-theme-40 text-white'
                : 'z-40 bg-theme-44 text-theme-42'
            ]"
            @click="
              ;(tariffario = true),
                (magazzino = false),
                (selectedType = TARIFFARIO),
                (selectedCategoria = null),
                (selectTariffarioClinic = null)
            "
          >
            Magazzino
          </button>
        </div>

        <div
          v-if="magazzini && openBlock === 0"
          class="tariff-box bg-white flex flex-col gap-1 rounded-b-md rounded-tr-md px-2 py-3 mb-1.5 overflow-auto scrollbar_custom"
        >
          <button
            v-for="x in magazzini"
            :key="x"
            type="button"
            class="tarif_btn"
            :class="
              selectTariffarioClinic && selectTariffarioClinic.id === x.id
                ? 'bg-theme-44 border border-theme-41'
                : 'bg-theme-45'
            "
            @click="setSelectMagazzino(x)"
          >
            <p class="break-words text-xs uppercase">{{ x.name }}</p>
          </button>
        </div>

        <div
          v-if="openBlock === 0"
          class="col-span-1 flex items-center justify-end pr-7"
        >
          <button class="orange-btn w-32" type="file" @click="openNewMagazzino">
            <PlusIcon class="h-5 w-5 mr-2" /> Magazzino
          </button>
        </div>
      </div>
      <!-- segundo div -->

      <!-- <div
        v-if="selectTariffarioClinic && !create_distinta && !create_pacchetto"
        class="rounded-md"
        style="position: relative"
        :class="{
          'flex-1': openBlock === 1,
          '  w-10 bg-theme-44 hover:bg-theme-40 text-theme-40 hover:text-white':
            openBlock !== 1
        }"
      > -->

      <div
        v-if="selectTariffarioClinic"
        class="rounded-md"
        style="position: relative"
        :class="{
          'flex-1': openBlock === 1,
          '  w-10 bg-theme-44 hover:bg-theme-40 text-theme-40 hover:text-white':
            openBlock !== 1
        }"
      >
        <h2
          v-if="openBlock !== 1"
          class="nunito cursor-pointer tariff_title flex items-center gap-2"
          @click="handleBlock(1)"
        >
          <h2 class="text-xl capitalize">{{ selectedType2 }}</h2>
          <h4 class="uppercase text-sm">
            {{ categoria_selected }}
          </h4>
        </h2>

        <div v-if="openBlock === 1" class="flex items-center text-theme-48">
          <button
            class="w-32 h-10 text-center shadow-md"
            :class="[
              categoriee
                ? 'z-50 rounded-tr-xl bg-theme-40 text-white'
                : pacchetti
                ? 'z-20 bg-theme-44  text-theme-42 border border-theme-43 border-opacity-10'
                : distinti
                ? 'z-10 bg-theme-44  text-theme-42 border border-theme-43 border-opacity-10'
                : ''
            ]"
            @click="
              ;(categoriee = true),
                (pacchetti = false),
                (distinti = false),
                (selectedType2 = CATEGORIE)
            "
          >
            Categorie
          </button>
        </div>

        <div>
          <div v-if="categoriee && openBlock === 1">
            <div class="tariff-box">
              <button
                v-for="x in categorie"
                :key="x"
                type="button"
                class="tarif_btn"
                :class="selectedCategoria == x ? 'bg-theme-44' : 'bg-theme-45'"
                @click="selectCategoriaMagazzino(x)"
              >
                <p class="break-words text-xs uppercase">
                  {{ x.name }}
                </p>
              </button>
            </div>
            <div
              id="salva-btn"
              class="col-span-1 flex items-center justify-end mt-2"
            >
              <button
                type="button"
                class="orange-btn"
                @click="openNewCategoria"
              >
                <i class="fas fa-save mr-1"></i> Aggiungi Categoria
              </button>
            </div>
          </div>

          <!-- visualizzazione pacchetto -->
        </div>
      </div>

      <div
        v-if="
          selectedCategoria &&
          selectTariffarioClinic &&
          selectedType2 === CATEGORIE &&
          openBlock === 2 &&
          !searchNomenclatori
        "
        :class="{ 'flex-1': openBlock === 2, 'w-10': openBlock !== 2 }"
        class="w-full"
      >
        <div class="flex items-center text-theme-48">
          <button
            class="w-32 h-10 text-center rounded-tr-xl shadow-md"
            :class="[
              prestazione
                ? 'z-50 bg-theme-40 text-white'
                : 'z-40 bg-theme-44 text-theme-42'
            ]"
            @click="
              ;(prestazione = true),
                (pacchetti = false),
                (distinti = false),
                (openBlock = 2)
            "
          >
            Articoli
          </button>
        </div>

        <div
          class="flex flex-col content-start justify-between rounded-b-md rounded-tr-md px-2 pt-3 magg_art_cont"
        >
          <div
            v-if="selectedCategoria && openBlock === 2"
            class="px-2 articoli_table_cont overflow-auto scrollbar_custom"
          >
            <ArticoliMagazzinoTable
              :pagination="pagination"
              :for-page-option="forPageOption"
              :docs="articoli"
              :table-config-clinic-doc="magazzinoTableConfig"
              :total="num_articoli"
              @edit-prodotto="openEditArticolo"
              @add-lotto="openAddLotto"
              @delete-prodotto="deleteArticolo"
              @change-pagination="changePagination"
              @update-filtri="updateFiltri"
            />
          </div>
          <div
            id="salva-btn"
            class="col-span-1 flex items-center justify-end my-2"
          >
            <button
              @click="
                () => {
                  showNewArticle = true
                }
              "
              type="button"
              class="orange-btn"
            >
              <i class="fas fa-save mr-1"></i> Aggiungi articolo
            </button>
          </div>
        </div>
      </div>
    </div>

    <AddNewTariffarioClinic
      v-if="newMagazzino || newCategoria"
      :title="newMagazzino ? 'Aggiungi Magazzino' : 'Aggiungi Categoria'"
      @close="closeNewMagazzinoCategoria"
      @confirm="
        (name) => (newMagazzino ? addMagazzino(name) : addCategoria(name))
      "
    />
  </div>
  <AddWarehouseArticle
    v-if="showNewArticle"
    :articolo="articolo"
    :add-lotto="addLotto"
    @save="addNewArticle"
    @edit="editArticolo"
    @add="addNewLotto"
    @close="closeAddArticolo"
  />
</template>

<script>
import { defineComponent, ref, computed, watch } from 'vue'
import { mapGetters, useStore } from 'vuex'
import axios from '../../gateway/backend-api'

import AddNewTariffarioClinic from '../../components/modals/AddNewTariffarioClinic.vue'
import AddWarehouseArticle from '../../components/modals/addWarehouseArticle.vue'
import ArticoliMagazzinoTable from '../../components/tables/ArticoliMagazzinoTable.vue'

export default defineComponent({
  components: {
    // ArticoliTable,
    AddNewTariffarioClinic,
    AddWarehouseArticle,
    ArticoliMagazzinoTable
  },

  setup() {
    const store = useStore()

    const showNewArticle = ref(false)
    const newMagazzino = ref(false)
    const newCategoria = ref(false)
    const categoria_selected = ref(null)

    const magazzini = ref(null)
    const selectedMagazzino = ref(null)
    const selectedCategoria = ref(null)
    const articoli = ref(null)
    const num_articoli = ref(null)

    const articolo = ref(null)

    const activeFilters = ref({})

    const magazzinoTableConfig = [
      {
        key: 'product_status',
        title: 'Stato',
        type: 'select',
        option: [
          { name: 'Scaduto', value: 'expired' },
          { name: 'Valido', value: 'valid' },
          { name: 'Scorte limitate', value: 'qty_limit' }
        ]
      },
      {
        key: 'expiration_date',
        title: 'Data scadenza',
        type: 'date'
      },
      {
        key: 'days_expiration',
        title: 'Residui',
        type: 'none'
      },
      // {
      //   key: 'name',
      //   title: 'Nome Prodotto',
      //   type: 'string'
      // },
      {
        key: 'lotto',
        title: 'Lotto',
        type: 'string'
      },

      {
        key: 'quantity',
        title: 'Quantità',
        type: 'string'
      },
      {
        key: 'price',
        title: 'Prezzo',
        type: 'string'
      },

      {
        key: 'actions',
        title: 'Azioni rapide',
        type: 'none'
      }
    ]

    const openBlock = ref(0)
    const TARIFFARIO = 'Tariffario'
    const TARIFFARIO_MAG = 'Tariffario Magazzino'

    const CATEGORIE = 'categorie'
    const PACCHETTI = 'pacchetti'
    const DISTINTE = 'distinte'

    const selectedType = ref(TARIFFARIO)

    const selectedType2 = ref(CATEGORIE)
    // ----- TARIFFARIO -----
    const tariffari = ref([])
    const tariffari_mag = ref([])
    const aliasToSave = ref({})
    const selectTariffarioClinic = ref(null)
    const selectedPackage = ref(null)

    const forPageOption = ref([10, 20, 50])
    const pagination = ref({
      page: 1,
      forPage: forPageOption.value[0]
    })

    const searchText = ref('')

    const onlyAlias = ref(false)

    watch(pagination, () => {
      getNomenclatoreMagazzino()
    })

    // ----- CATEGORIA -----
    const categorie = ref([])
    const categorie_mag = ref([])

    // ----- CATEGORIA -----
    const nomenclatore = ref([])
    const selectedNomenclatore = ref(null)

    const new_prestazione_data = ref([])
    const new_farmaco_data = ref([])
    const new_prestazione_categorie = ref([])
    const new_farmaco_categorie = ref([])
    const pacchetti_data = ref([])
    const distinta_data = ref([])
    const new_prestazione_selected_categoria = ref(null)
    const new_farmaco_selected_categoria = ref(null)
    const new_prestazione_nomenclatore = ref(null)
    const new_farmaco_nomenclatore = ref(null)
    const new_prestazione_newids = ref([])
    const new_prestazione_newData = ref([])
    const new_farmaco_newids = ref([])
    const confirm_package = ref(false)
    const elenco_taglie = ref(null)
    const selected_taglia = ref(null)
    const priceModalTagliaLabel = ref(null)

    const searchResult = ref(null)
    const searchNomenclatori = ref(null)

    const editPackage = ref(false)
    const editPackagePrices = ref({ t1: 0, t2: 0, t3: 0, t4: 0 })
    const editPackagePrestazioni = ref([])
    const editPackageTitle = ref(null)
    const edit_prestazione_categorie = ref([])
    const edit_prestazione_categoria_selected = ref(null)
    const edit_prestazione_nomenclatore = ref(null)
    const newPackageName = ref('')
    const newPackagePrices = ref({ t1: 0, t2: 0, t3: 0, t4: 0 })
    const editPrestKey = ref(0)
    const newPrestKey = ref(0)

    const addLotto = ref(false)

    const permissions = computed(() => {
      return store.state.reusableData.permissions
        ? store.state.reusableData.permissions
        : null
    })

    const ivaPerc = computed(
      () => store.getters['reusableData/getRegimeFiscaleIvaPerc']
    )

    const showIva = computed(() => store.getters['reusableData/getShowIva'])

    const getData = () => {
      // tariffari

      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/elenco-tariffari-clinica'
        )
        .then((res) => {
          tariffari.value = res.data
        })

      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/warehouse/lista-magazzini',
          { page: 1, per_page: 100 }
        )
        .then((res) => {
          magazzini.value = res.data.data
        })

      if (selectedMagazzino.value) {
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/warehouse/lista-categorie',
            {
              warehouse_id: selectedMagazzino.value.id,
              page: 1,
              per_page: 500
            }
          )
          .then((res) => {
            categorie.value = res.data.data
          })
      }
    }

    const deletePackageDistinte = (id, type) => {
      store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/delete-pacchetti-distinte',
          {
            id: id,
            type: type
          }
        )
        .then((res) => {
          if (type == 'package') {
            var text = 'Pacchetto eliminato correttamente'
            if (res.data != 1) text = "Errore, contattare l'assistenza"

            selectedPackage.value = null
            store.dispatch('commonComponents/openNotificationModal', {
              message: text
            })
            store.dispatch('commonComponents/hideLoadingModalAction')
          }
          getData()
        })
        .catch(() => {
          store.dispatch('commonComponents/hideLoadingModalAction')
        })
    }

    const removeSingleEditPrestazione = (element) => {
      console.log(element)
      editPackagePrestazioni.value = editPackagePrestazioni.value.filter(
        (el) => {
          return el.id !== element.id
        }
      )
    }

    const saveModifyPackage = () => {
      if (!editPackageTitle.value || editPackageTitle.value === '') {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire il nome'
        })
        return
      }

      if (selected_taglia.value) {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire la taglia'
        })
        return
      }
      if (
        editPackagePrestazioni.value == null ||
        editPackagePrestazioni.value.length === 0
      ) {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di selezionare almeno una prestazione',
          background: false
        })
        return
      }
      let ids = []
      editPackagePrestazioni.value.forEach((e) => {
        ids.push(e.id)
      })

      editPackagePrices.value['t1'] =
        editPackagePrices.value['t1'] / (showIva.value ? ivaPerc.value : 1)
      editPackagePrices.value['t2'] =
        editPackagePrices.value['t2'] / (showIva.value ? ivaPerc.value : 1)
      editPackagePrices.value['t3'] =
        editPackagePrices.value['t3'] / (showIva.value ? ivaPerc.value : 1)
      editPackagePrices.value['t4'] =
        editPackagePrices.value['t4'] / (showIva.value ? ivaPerc.value : 1)
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/edit-pacchetti-distinte',
          {
            prices: editPackagePrices.value,
            sub_packages: { tariffario_ids: ids },
            alias: editPackageTitle.value,
            type: 'package',
            id: selectedPackage.value.id
          }
        )
        .then(() => {
          editPackage.value = false
          editPackagePrestazioni.value = []
          edit_prestazione_categoria_selected.value = null
          edit_prestazione_categorie.value = []
          editPackagePrices.value = { t1: 0, t2: 0, t3: 0, t4: 0 }
          edit_prestazione_nomenclatore.value = null
          openBlock.value = 1
          getData()
          store.dispatch('commonComponents/openNotificationModal', {
            message: 'Pacchetto modificato con successo'
          })
        })
    }

    const selectCategoriaMagazzino = (x) => {
      openBlock.value = 2
      categoria_selected.value = x.name
      selectedCategoria.value = x
      activeFilters.value = {}
      getNomenclatoreMagazzino()
    }

    // const changePaginationEditPrest = (value) => {
    //   pagination.value = value
    //   getNomenclatoreEditPrest(null, pagination.value)
    // }

    // const changeNumPagesEditPrest = (value) => {
    //   pagination.value.forPage = value
    //   pagination.value.page = 1
    //   getNomenclatoreEditPrest(null, pagination.value)
    // }

    const updateFiltri = (filtri) => {
      activeFilters.value = filtri
      getNomenclatoreMagazzino()
    }

    const getNomenclatoreMagazzino = () => {
      //if (elem) selectedCategoria.value = elem
      if (selectedCategoria.value) {
        store.dispatch('commonComponents/showLoadingModalAction')
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/warehouse/lista-prodotti',
            {
              warehouse_category_id: selectedCategoria.value.id,
              page: pagination.value.page,
              per_page: pagination.value.forPage,
              active_filters: activeFilters.value
            }
          )
          .then((res) => {
            store.dispatch('commonComponents/hideLoadingModalAction')
            articoli.value = res.data.data
            num_articoli.value = res.data.total
          })
          .catch(() => {
            store.dispatch('commonComponents/hideLoadingModalAction')
          })
      }
    }

    const openEditArticolo = (a) => {
      articolo.value = a
      showNewArticle.value = true
    }

    const openAddLotto = (a) => {
      articolo.value = a
      addLotto.value = true
      showNewArticle.value = true
    }

    const deleteArticolo = (articolo) => {
      store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/warehouse/' +
            articolo.id +
            '/delete-prodotto'
        )
        .then(() => {
          store.dispatch('commonComponents/hideLoadingModalAction')
          getNomenclatoreMagazzino(null, pagination)
        })
        .catch(() => {
          store.dispatch('commonComponents/hideLoadingModalAction')
        })
    }

    const closeAddArticolo = () => {
      articolo.value = null
      addLotto.value = false
      showNewArticle.value = false
    }

    // modale gestione prezzo
    const priceModal = ref(false)
    const priceReadOnly = ref(false)
    const priceModalDiscount = ref(null)
    const priceModalGreatest = ref(null)

    const newTariffarioClinic = ref(false)
    const openNewMagazzino = () => {
      newMagazzino.value = true
    }
    const openNewCategoria = () => {
      newCategoria.value = true
    }

    const addMagazzino = (name) => {
      console.log('aggiungo il magazzino')
      newMagazzino.value = false
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/warehouse/add-warehouse',
          { name: name }
        )
        .then(() => {
          getData()
        })
    }
    const addCategoria = (name) => {
      console.log('aggiungo la categoria')
      newCategoria.value = false
      newMagazzino.value = false
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/warehouse/add-category',
          { name: name, warehouse_id: selectedMagazzino.value.id }
        )
        .then(() => {
          getData()
        })
    }
    const closeNewMagazzinoCategoria = () => {
      newMagazzino.value = false
      newCategoria.value = false
    }

    const closePriceModal = () => {
      priceModalDiscount.value = null
      priceModalGreatest.value = null
      selectedNomenclatore.value = null
      priceModal.value = false
      priceModalTagliaLabel.value = null
      priceReadOnly.value = false
    }

    const addPrice = (price) => {
      if (searchNomenclatori.value) {
        searchNomenclatori.value[
          selectedNomenclatore.value.indexNewDocs
        ].price_added = true
      } else {
        nomenclatore.value.data[
          selectedNomenclatore.value.indexNewDocs
        ].price_added = true
      }

      selectedNomenclatore.value.code = price
      // aliasToSave.value.push(selectedNomenclatorce.value)
      aliasToSave.value[selectedNomenclatore.value.id] =
        selectedNomenclatore.value
      priceModal.value = false
      priceReadOnly.value = false
    }

    const addAlias = (doc) => {
      if (aliasToSave.value && Object.keys(aliasToSave.value).length > 0) {
        // Object.keys(props.elem).forEach(function (k) {})
        aliasToSave.value[doc.id].alias = doc.alias
        //var alias = aliasToSave.value.find((elem) => elem.id === doc.id)
        // alias.alias = doc.alias
      } else {
        aliasToSave.value[doc.id] = doc
      }
    }

    const updateAlias = (newDoc) => {
      aliasToSave.value = newDoc
    }

    const addNewPrestazione = (elements) => {
      elements.forEach((element) => {
        if (
          new_prestazione_newids.value &&
          !new_prestazione_newids.value.includes(element.id)
        ) {
          new_prestazione_newids.value.push(element.id)
          new_prestazione_newData.value.push(element)
        }
      })
    }

    const removeNewPrestazione = (elements) => {
      elements.forEach((element) => {
        new_prestazione_newids.value = new_prestazione_newids.value.filter(
          (elem) => {
            return elem !== element.id
          }
        )
        new_prestazione_newData.value = new_prestazione_newData.value.filter(
          (elem) => {
            return elem.id !== element.id
          }
        )
      })
    }

    const addNewArticle = (articolo) => {
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/warehouse/add-prodotto',
          {
            warehouse_id: selectedMagazzino.value.id,
            warehouse_category_id: selectedCategoria.value.id,
            //aic: articolo.aic,
            //marchio: articolo.marchio ?? null,
            alias: articolo.alias ?? articolo.marchio,
            lotto: articolo.lotto,
            expiration_date: articolo.expiration_date,
            total_quantity: articolo.qty,
            purchase_price: articolo.price,
            descrizione_dispensazione: articolo.description,
            ...articolo
          }
        )
        .then(() => {
          showNewArticle.value = false
          addLotto.value = false
          getNomenclatoreMagazzino(null, pagination)
        })
    }

    const addNewLotto = (articolo) => {
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/warehouse/add-prodotto',
          {
            warehouse_id: selectedMagazzino.value.id,
            warehouse_category_id: selectedCategoria.value.id,
            //aic: articolo.aic,
            marchio: articolo.aic ? articolo.name : null,
            alias: articolo.alias ?? articolo.marchio,
            lotto: articolo.lotto,
            expiration_date: articolo.expiration_date,
            total_quantity: articolo.qty,
            purchase_price: articolo.price,
            descrizione_dispensazione: articolo.description,
            ...articolo
          }
        )
        .then(() => {
          showNewArticle.value = false
          addLotto.value = false
          getNomenclatoreMagazzino(null, pagination)
        })
    }

    const editArticolo = (a) => {
      const payload = {
        total_quantity: a.qty,
        purchase_price: a.price,
        description: a.description,
        note: a.note,
        expiration_date: a.expiration_date,
        lotto: a.lotto
      }

      if (!a.aic) payload.alias = a.alias
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/warehouse/' +
            a.id +
            '/edit-prodotto',
          payload
        )
        .then(() => {
          showNewArticle.value = false
          articolo.value = null
          addLotto.value = false
          getNomenclatoreMagazzino(null, pagination)
        })
    }

    const removeNewPharma = (elements) => {
      elements.forEach((element) => {
        new_farmaco_newids.value = new_farmaco_newids.value.filter((elem) => {
          return elem !== element
        })
      })
    }

    return {
      newCategoria,
      newMagazzino,
      magazzini,
      articoli,
      num_articoli,
      magazzinoTableConfig,
      updateFiltri,
      activeFilters,
      categoria_selected,
      TARIFFARIO,
      TARIFFARIO_MAG,
      CATEGORIE,
      PACCHETTI,
      DISTINTE,
      pagination,
      forPageOption,
      tariffari,
      tariffari_mag,
      selectTariffarioClinic,
      selectedMagazzino,
      categorie,
      categorie_mag,
      selectedCategoria,
      nomenclatore,
      selectedNomenclatore,
      aliasToSave,
      getData,
      deletePackageDistinte,
      priceModal,
      selectedPackage,
      searchResult,
      closePriceModal,
      addPrice,
      addAlias,
      priceReadOnly,
      updateAlias,
      newTariffarioClinic,
      openNewMagazzino,
      openNewCategoria,
      addMagazzino,
      addCategoria,
      closeNewMagazzinoCategoria,
      selectedType,
      onlyAlias,
      new_prestazione_data,
      new_farmaco_data,
      new_prestazione_categorie,
      pacchetti_data,
      distinta_data,
      new_farmaco_selected_categoria,
      new_prestazione_selected_categoria,
      new_prestazione_nomenclatore,
      new_farmaco_nomenclatore,
      addNewPrestazione,
      removeNewPrestazione,
      addNewArticle,
      addNewLotto,
      removeNewPharma,
      new_prestazione_newids,
      new_prestazione_newData,
      new_farmaco_newids,
      new_farmaco_categorie,
      confirm_package,
      elenco_taglie,
      selected_taglia,
      selectedType2,
      searchText,
      searchNomenclatori,
      permissions,
      priceModalDiscount,
      priceModalGreatest,
      priceModalTagliaLabel,
      openBlock,
      editPackage,
      editPackagePrices,
      editPackagePrestazioni,
      showIva,
      ivaPerc,
      edit_prestazione_categorie,
      selectCategoriaMagazzino,
      edit_prestazione_categoria_selected,
      edit_prestazione_nomenclatore,
      getNomenclatoreMagazzino,
      // changePaginationEditPrest,
      // changeNumPagesEditPrest,
      saveModifyPackage,
      editPackageTitle,
      removeSingleEditPrestazione,
      newPackagePrices,
      newPackageName,
      editPrestKey,
      newPrestKey,
      showNewArticle,
      openEditArticolo,
      openAddLotto,
      deleteArticolo,
      closeAddArticolo,
      editArticolo,
      articolo,
      addLotto
    }
  },

  data() {
    return {
      tariffario_title: null,

      tariffario: true,
      magazzino: false,
      prestazione: true,
      pacchetti: false,
      distinti: false,
      categoriee: true,
      new_prestazione: true,
      new_farmaco: false,
      create_pacchetto: false,
      create_distinta: false
    }
  },
  computed: {
    ...mapGetters({
      cassaPrevidenziale: 'reusableData/getCassaPrevidenzialeActive'
    }),
    newPricesSum: function () {
      let result = {
        t1: 0,
        t2: 0,
        t3: 0,
        t4: 0
      }

      if (
        this.new_prestazione_newData &&
        this.new_prestazione_newData.length > 0 &&
        this.selected_taglia
      ) {
        this.new_prestazione_newData.forEach((elem) => {
          Object.keys(elem.code).forEach((elem_2) => {
            Object.keys(elem.code[elem_2]).forEach((taglia) => {
              if (
                elem.code[elem_2][taglia].id_tariffario_taglie ===
                this.selected_taglia
              ) {
                if (elem.code[elem_2][taglia].data)
                  result[elem_2] +=
                    (elem.code[elem_2][taglia].data_netto ??
                      elem.code[elem_2][taglia].data) *
                    (this.showIva === true ? this.ivaPerc ?? 1 : 1)
                // if (this.showIva === true)
                //   result[elem_2] = result[elem_2] * this.ivaPerc
                // if (
                //   this.cassaPrevidenziale &&
                //   this.cassaPrevidenziale.is_active
                // )
                //   result[elem_2] +=
                //     result[elem_2] *
                //     (this.cassaPrevidenziale.vat
                //       ? this.cassaPrevidenziale.vat / 100
                //       : 0.02)
              }
            })
          })
        })
      }

      return result
    },
    pricesSumCompiled: function () {
      let result = {
        t1: 0,
        t2: 0,
        t3: 0,
        t4: 0
      }

      if (
        this.selectedPackage &&
        this.selectedPackage.sub_packages &&
        this.selectedPackage.sub_packages.tariffario_ids &&
        this.selectedPackage.sub_packages.tariffario_ids.tariffario_price
      ) {
        Object.keys(
          this.selectedPackage.sub_packages.tariffario_ids.tariffario_price
        ).forEach((tariff) => {
          if (
            this.selectedPackage.sub_packages.tariffario_ids.tariffario_price[
              tariff
            ].price
          )
            Object.keys(
              this.selectedPackage.sub_packages.tariffario_ids.tariffario_price[
                tariff
              ].price
            ).forEach((t) => {
              Object.keys(
                this.selectedPackage.sub_packages.tariffario_ids
                  .tariffario_price[tariff].price[t]
              ).forEach((taglia) => {
                if (
                  this.selectedPackage.sub_packages.tariffario_ids
                    .tariffario_price[tariff].price[t][taglia]
                    .id_tariffario_taglie ===
                  this.selectedPackage.id_tariffario_taglia
                ) {
                  if (
                    this.selectedPackage.sub_packages.tariffario_ids
                      .tariffario_price[tariff].price[t][taglia].data
                  )
                    result[t] +=
                      this.selectedPackage.sub_packages.tariffario_ids
                        .tariffario_price[tariff].price[t][taglia].data_netto ??
                      this.selectedPackage.sub_packages.tariffario_ids
                        .tariffario_price[tariff].price[t][taglia].data
                }
              })
            })
        })
      }

      if (
        this.selectedPackage?.discount &&
        this.selectedPackage?.discount !== 0 &&
        this.selectedPackage?.discount !== '0'
      ) {
        result['t1'] -=
          result['t1'] * ((this.selectedPackage?.discount * 1) / 100)
        result['t2'] -=
          result['t2'] * ((this.selectedPackage?.discount * 1) / 100)
        result['t3'] -=
          result['t3'] * ((this.selectedPackage?.discount * 1) / 100)
        result['t4'] -=
          result['t4'] * ((this.selectedPackage?.discount * 1) / 100)
      }

      if (
        this.selectedPackage?.greatest &&
        this.selectedPackage?.greatest !== 0 &&
        this.selectedPackage?.greatest !== '0'
      ) {
        result['t1'] +=
          result['t1'] * ((this.selectedPackage?.greatest * 1) / 100)
        result['t2'] +=
          result['t2'] * ((this.selectedPackage?.greatest * 1) / 100)
        result['t3'] +=
          result['t3'] * ((this.selectedPackage?.greatest * 1) / 100)
        result['t4'] +=
          result['t4'] * ((this.selectedPackage?.greatest * 1) / 100)
      }

      return result
    }
  },
  // watch: {
  //   // searchText: function () {
  //   //   this.getNomenclatore(this.selectedCategoria, this.pagination)
  //   // }
  // },
  mounted() {
    this.getData()
  },

  methods: {
    handleBlock(index) {
      this.openBlock = index
      console.log(this.openBlock)
      if (index === 1 || index === 0) {
        this.create_pacchetto = false
        this.create_distinta = false
        this.new_farmaco_nomenclatore = null
        this.new_prestazione_nomenclatore = null
        this.new_prestazione_categorie = null
        this.new_farmaco_data = null
        this.new_prestazione_data = null
        this.new_prestazione_newData = []
        this.new_farmaco_newids = []
        this.new_prestazione_newids = []
        this.new_prestazione_selected_categoria = null
        this.new_farmaco_selected_categoria = null
        this.selected_taglia = null
        this.editPackage = false
        this.editPackagePrestazioni = []
        this.edit_prestazione_categoria_selected = null
        this.edit_prestazione_categorie = []
        this.editPackagePrices = { t1: 0, t2: 0, t3: 0, t4: 0 }
        this.edit_prestazione_nomenclatore = null
        this.pagination = { forPage: this.forPageOption[0], page: 1 }
        this.aliasToSave = {}
      }
    },
    startSearch(text) {
      if (text !== '' && text.length > 1) {
        this.getNomenclatore(
          null,
          {
            page: 1,
            forPage: 1000
          },
          true
        )
        this.searchNomenclatori = null
      } else {
        this.searchResult = null
        this.searchNomenclatori = null
      }
    },
    createNewPachetto(pacchetto) {
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/elenco-tariffari-categorie',
          {
            only_alias: this.onlyAlias,
            tariffario_clinic_id: this.selectTariffarioClinic
              ? this.selectTariffarioClinic.id
              : null
          }
        )
        .then((res) => {
          this.new_prestazione_categorie = res.data
        })

      axios
        .get(
          '/' + localStorage.getItem('clinic') + '/warehouse/lista-categorie'
        )
        .then((res) => {
          this.new_farmaco_categorie = res.data
        })

      axios
        .get('/' + localStorage.getItem('clinic') + '/tariffario/elenco-taglie')
        .then((res) => {
          this.elenco_taglie = res.data
        })

      if (pacchetto === true) {
        this.create_pacchetto = true
      } else {
        this.create_distinta = true
      }

      this.selectedCategoria = null
      this.nomenclatore = null
      // this.selectedType2 = this.CATEGORIE
      this.openBlock = 2
      console.log('este es el click que quiero', this.openBlock)
    },
    createNewDistinta() {
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/tariffario/elenco-tariffari-categorie',
          {
            only_alias: true,
            tariffario_clinic_id: this.selectTariffarioClinic
              ? this.selectTariffarioClinic.id
              : null
          }
        )
        .then((res) => {
          this.new_prestazione_categorie = res.data
        })

      axios
        .get(
          '/' + localStorage.getItem('clinic') + '/warehouse/lista-categorie'
        )
        .then((res) => {
          this.new_farmaco_categorie = res.data
        })

      this.create_distinta = true
      this.nomenclatore = null
      this.openBlock = 2
    },
    setSelectMagazzino(elem) {
      this.tariffario_title = elem.name
      this.openBlock = 1
      this.nomenclatore = []
      this.selectTariffarioClinic = elem
      this.selectedMagazzino = elem
      this.getData()
    },
    setSelectTariffarioClinic(elem) {
      this.tariffario_title = elem.name
      this.openBlock = 1
      this.create_pacchetto = false
      this.create_distinta = false
      if (Object.keys(this.aliasToSave).length > 0) {
        if (
          this.selectTariffarioClinic &&
          this.selectTariffarioClinic.id != elem.id
        ) {
          this.$store.dispatch('commonComponents/openConfirmModal', {
            title: 'Attenzione',
            subtitle:
              'Cambiando tariffario perderai i progressi non salvati, continuare?',
            background: false,
            confirm: () => {
              this.aliasToSave = {}
              this.nomenclatore = []

              this.getData()
              this.selectTariffarioClinic = elem
            },
            decline: () => {}
          })
        }
      } else {
        this.nomenclatore = []
        this.selectTariffarioClinic = elem
        this.getData()
      }
    },
    selectSearchCategory(j) {
      this.searchNomenclatori = j
      this.openBlock = 2
    },
    selectCategoria(elem) {
      this.openBlock = 2
      this.selectedCategoria = elem
      if (Object.keys(this.aliasToSave).length > 0) {
        if (this.selectedCategoria && this.selectedCategoria.id != elem.id) {
          this.$store.dispatch('commonComponents/openConfirmModal', {
            title: 'Attenzione',
            subtitle:
              'Cambiando categoria perderai i progressi non salvati, continuare?',
            background: false,
            confirm: () => {
              this.aliasToSave = {}
              this.getNomenclatore(elem, this.pagination)
            },
            decline: () => {}
          })
        }
      } else this.getNomenclatore(elem, this.pagination)
      this.categoria_selected = elem.category
    },
    selectPacchetto(elem, type) {
      this.openBlock = 2
      this.selectedPackage = elem
      this.selectedType2 = type
    },
    removePacchetto(elem) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle:
          "Confermi di voler eliminare il pacchetto '" + elem.alias + "' ?",
        background: false,
        confirm: () => {
          console.log('confermo')

          this.deletePackageDistinte(elem.id, 'package')
          this.getData()
        },
        decline: () => {}
      })
    },

    selectType(selected) {
      this.selectedType = selected
      this.selectedCategoria = null
      this.nomenclatore = null
      this.getData()
    },
    changePagination() {
      //this.pagination = value
      this.getNomenclatoreMagazzino()
    },

    changeNumPages(value) {
      this.pagination.forPage = value
      this.getNomenclatore(this.selectedCategoria, this.pagination)
    },

    getNomenclatore(elem, pagination, fromSearch) {
      this.selectedCategoria = elem

      if (this.selectedType === this.TARIFFARIO) {
        this.$store.dispatch('commonComponents/showLoadingModalAction')
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/tariffario/nomenclatore',
            {
              tariffario_clinic_id: this.selectTariffarioClinic.id,
              category_id: elem ? elem.id : null,
              per_page: pagination.forPage * 1,
              page: pagination.page,
              only_alias: this.onlyAlias,
              search: this.searchText
            }
          )
          .then((res) => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            if (fromSearch) {
              this.searchResult = res.data
            } else {
              this.nomenclatore = res.data
            }
          })
          .catch(() => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
          })
      } else {
        this.$store.dispatch('commonComponents/showLoadingModalAction')
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/warehouse/lista-articoli',
            {
              warehouses_tariffario_clinic_id: this.selectTariffarioClinic.id,
              category_id: elem.id,
              per_page: pagination.forPage,
              page: pagination.page
            }
          )
          .then((res) => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            if (fromSearch) {
              this.searchResult = res.data
            } else {
              this.nomenclatore = res.data
            }
            // this.nomenclatore = res.data
          })
          .catch(() => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
          })
      }
    },

    addTariffario(name) {
      this.newTariffarioClinic = false
      if (this.selectedType === this.TARIFFARIO) {
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/tariffario/new-tariffario',
            {
              name: name
            }
          )
          .then(() => {
            this.getData()
          })
      } else {
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/warehouse/add-tariffario',
            {
              name: name
            }
          )
          .then(() => {
            this.getData()
          })
      }
    }
  }
})
</script>
