<template>
  <NewInvoiceModal
    v-if="invoiceModalOpen"
    :read-only="true"
    :read-only-elem="invoiceElem"
    :client="customer"
    title="Riepilogo fattura"
    @close="closeInvoiceModal"
    @responseModal="handleResponseSubmitInvoice"
  />
  <FirstNoteTable v-if="true" @showInvoice="openInvoiceModal" />
</template>

<script>
import { defineComponent, ref } from 'vue'
import FirstNoteTable from '../../components/tables/FirstNoteTable.vue'
import NewInvoiceModal from '../../components/new-invoice/NewInvoiceModal.vue'
import axios from '../../gateway/backend-api'

export default defineComponent({
  components: {
    FirstNoteTable,
    NewInvoiceModal
  },
  setup() {
    const invoiceModalOpen = ref(false)
    const invoiceElem = ref(null)
    const customer = ref(null)

    const closeInvoiceModal = () => {
      invoiceModalOpen.value = false
      customer.value = false
      invoiceElem.value = false
    }

    const openInvoiceModal = (doc) => {
      axios
        .get(
          '/' + localStorage.getItem('clinic') + '/customer/' + doc.customer_id
        )
        .then((res) => {
          invoiceElem.value = doc
          customer.value = res.data
          invoiceModalOpen.value = true
          this.designModal()
        })
    }

    return {
      invoiceModalOpen,
      customer,
      invoiceElem,
      closeInvoiceModal,
      openInvoiceModal
    }
  },
  methods: {
    designModal() {}
  }
})
</script>
