<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black scrollbar-none bg-opacity-50"
  >
    <div
      class="relative top-20 p-2 mx-auto overflow-scroll scrollbar-none border sm:w-4/5 w-full shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="flex flex-col content-end overflow-y-auto">
        <button type="button" class="closing-btn" @click="this.$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <!-- Modal header -->
        <div class="modal-title">
          <h3 class="text-3xl font-bold nunito text-white text-center">
            {{ title }}
          </h3>
        </div>
      </div>
      <div class="flex flex-col content-end overflow-hidden">
        <VaccinazioniTable
          v-if="docs"
          :table-config-clinic-doc="vaccine_table_config"
          :documents="docs"
          :total-records="totalRecords"
          :pagination="pagination"
          :for-page-option="forPageOption"
          :hide-actions="true"
          :selected-patient="true"
          @getList="(el) => getList(el)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import axios from '../../gateway/backend-api'
import VaccinazioniTable from '../../views/area-medica/VaccinazioniTable.vue'

export default defineComponent({
  components: { VaccinazioniTable },
  props: ['title', 'pet'],
  emits: ['close'],
  setup(props) {
    const forPageOption = [10, 20, 50]
    const vaccine_table_config = [
      {
        key: 'state',
        title: 'Stato',
        type: 'select',
        option: [
          { name: 'Effettuato', value: true },
          { name: 'Da Effettuare', value: false }
        ]
      },

      {
        key: 'execution_date',
        title: 'Data esecuzione',
        type: 'date'
      },
      {
        key: 'recall_date',
        title: 'Data Richiamo',
        type: 'date'
      },
      {
        key: 'client',
        title: 'Cliente',
        type: 'string'
      },
      {
        key: 'patient',
        title: 'Paziente',
        type: 'string'
      },
      {
        key: 'species',
        title: 'Specie',
        type: 'select',
        option: []
      },
      {
        key: 'vaccine',
        title: 'Vaccino',
        type: 'string'
      },

      {
        key: 'buttons',
        title: 'Azioni rapide',
        type: 'none'
      }
    ]

    const pagination = ref({
      page: 1,
      forPage: forPageOption[0]
    })
    const totalRecords = ref(0)
    const docs = ref(null)

    const getList = (value = {}) => {
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/vaccination/indexPetVaccination',
          {
            pet_id: props.pet.id,
            active_filters: value,
            per_page: pagination.value.forPage,
            page: pagination.value.page
          }
        )
        .then((resp) => {
          docs.value = resp.data.data
          totalRecords.value = resp.data.total
        })
    }

    return {
      vaccine_table_config,
      totalRecords,
      pagination,
      docs,
      getList,
      forPageOption
    }
  },
  mounted() {
    this.getList()
  }
})
</script>
