<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black scrollbar-none bg-opacity-50"
  >
    <div
      class="w-3/5 sm:w-3/5 relative top-1/4 p-5 mx-auto overflow-scroll scrollbar-none border shadow-lg rounded-md bg-white"
    >
      <div class="w-full">
        <h3 class="modal-title mt-2">Aggiornamento in corso ...</h3>
      </div>
      <div class="flex justify-center items-center p-4">
        <div>
          <!-- <RotateCwIcon
            class="animate-spin h-full w-10 mr-3 scale-150 fill-black text-black mb-8"
            autofocus
          /> -->
          <i class="fas fa-cog fa-spin p-8 text-2xl"></i>
        </div>
        <p class="text-xl">
          Ci scusiamo per il disagio. <br />Il sito sarà di nuovo operativo il
          prima possibile.
        </p>
      </div>
      <!-- Modal content -->
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: [],
  emits: [],
  setup() {
    // ----- DATA -----

    // ----- METHODS -----

    // ----- COMPUTED -----

    return {}
  },
  beforeUnmount() {
    location.reload()
  }
})
</script>
