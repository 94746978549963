<template>
  <div class="paginator-container">
    <div class="mr-5 my-2 flex justify-between">
      <div class="mt-1">
        <span class="flex-row text-md ml-2 font-bold mr-2 mt-1">Mostra </span>
        <select
          v-if="forPageOption"
          class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
          @change="(event) => changeNumPages(event)"
        >
          <option
            v-for="show in forPageOption"
            :key="show"
            :value="show"
            class="form-select"
          >
            {{ show }}
          </option>
        </select>
        <span class="text-md font-bold ml-2 mt-1"> elementi</span>
      </div>
    </div>
    <Paginator
      :for-page-option="pagination.forPage"
      :total-records="totalRecords"
      @changePagination="(event) => changePagination(event)"
    />
  </div>

  <!-- BEGIN: Data List -->
  <div class="table-container overflow-scroll scrollbar-none pb-3">
    <table v-if="tableConfigClinicDoc && documents" class="w-full">
      <thead class="">
        <tr>
          <th
            v-for="(item, itemKey) in table_config"
            :key="itemKey"
            class="t-header"
          >
            {{ item.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- Blocco commentato in attesa di implementazione filtri -->

        <tr>
          <td
            v-for="(elem, index) in table_config"
            :key="index"
            class="border-b whitespace-nowrap px-1 py-4"
          >
            <select
              v-if="elem.type === 'select'"
              v-model="elem.value"
              class="block px-2 py-1 border-2 border-gray-200 rounded-lg w-full focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500"
              @change="filterHandler(elem)"
            >
              <option selected value="">{{ elem.title }}</option>
              <option v-for="(item, i) in elem.option" :key="i" :value="item">
                {{ item }}
              </option>
            </select>
            <input
              v-else
              v-model="elem.value"
              :type="elem.type"
              min="0"
              :name="elem.title"
              class="block px-2 py-1 border-2 border-gray-200 rounded-lg w-full focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500"
              :class="{
                invisible: elem.type === 'none',
                'p-0': elem.type === 'date'
              }"
              :placeholder="elem.title"
              @change="filterHandler(elem)"
            />
          </td>
        </tr>
        <tr
          v-for="(doc, docKey) in documents"
          :key="docKey"
          class="h-12 text-xs font-normal hover:bg-theme-45"
          :class="{ 'bg-theme-2': docKey % 2 === 0 }"
        >
          <td class="text-center text-sm border-r-2 border-theme-46">
            {{ doc.type }}
          </td>
          <td class="text-center text-sm border-r-2 border-theme-46">
            {{ doc.state }}
          </td>
          <td class="text-center text-sm border-r-2 border-theme-46">
            {{ $FormatDate(doc.date) }}
          </td>
          <td class="text-center text-sm border-r-2 border-theme-46">
            {{ doc.number }}
          </td>
          <td class="text-center text-sm border-r-2 border-theme-46">
            {{ doc.pin }}
          </td>
          <td class="text-center text-sm border-r-2 border-theme-46">
            {{ doc.doctor }}
          </td>
          <td class="text-center text-sm border-r-2 border-theme-46">
            {{ doc.client }}
          </td>
          <td
            v-if="!selectedPatient"
            class="text-center text-sm border-r-2 border-theme-46"
          >
            {{ doc.patient }}
          </td>
          <td class="text-center text-sm border-r-2 border-theme-46">
            <span
              style="height: 60px; overflow: hidden; display: block"
              v-html="doc.note"
            ></span>
          </td>

          <td
            class="flex gap-4 items-center text-center justify-center"
            style="height: inherit"
          >
            <a
              v-if="doc.emit && doc.number"
              class="hover:text-theme-41"
              :class="[
                doc.state == 'ANNULLATO'
                  ? 'opacity-40 cursor-default pointer-events-none'
                  : 'cursor-pointer',
                ''
              ]"
              @click="() => $emit('handleSavePdfRev', doc)"
            >
              <i class="fas fa-file-pdf text-base" />
            </a>

            <a
              v-else-if="emitRev && !doc.emit"
              class="tooltipbutton hover:text-theme-41 cursor-pointer"
              @click="emettiRev(doc.id)"
            >
              <i class="fas fa-cloud-upload-alt text-base"></i>
              <span
                class="tooltipbuttontext"
                style="width: 80px; margin-top: 5px; margin-left: 20px"
                >Emetti</span
              >
            </a>
            <a
              v-if="!doc.emit && doc.type == 'Elettronico' && editRev"
              class="tooltipbutton hover:text-theme-41 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="h-4 w-4 fill-current"
                @click="() => $emit('edit', doc)"
              >
                <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                  d="M471.6 21.7c-21.9-21.9-57.3-21.9-79.2 0L362.3 51.7l97.9 97.9 30.1-30.1c21.9-21.9 21.9-57.3 0-79.2L471.6 21.7zm-299.2 220c-6.1 6.1-10.8 13.6-13.5 21.9l-29.6 88.8c-2.9 8.6-.6 18.1 5.8 24.6s15.9 8.7 24.6 5.8l88.8-29.6c8.2-2.7 15.7-7.4 21.9-13.5L437.7 172.3 339.7 74.3 172.4 241.7zM96 64C43 64 0 107 0 160V416c0 53 43 96 96 96H352c53 0 96-43 96-96V320c0-17.7-14.3-32-32-32s-32 14.3-32 32v96c0 17.7-14.3 32-32 32H96c-17.7 0-32-14.3-32-32V160c0-17.7 14.3-32 32-32h96c17.7 0 32-14.3 32-32s-14.3-32-32-32H96z"
                />
              </svg>
              <span
                class="tooltipbuttontext"
                style="width: 80px; margin-top: -10px; margin-left: 10px"
                >Emetti</span
              >
            </a>

            <a
              v-if="
                permissions &&
                permissions['REV - Ricetta Elettonica Veterinaria'] &&
                permissions['REV - Ricetta Elettonica Veterinaria']
                  .duplica_rev &&
                permissions['REV - Ricetta Elettonica Veterinaria'].duplica_rev
                  .enabled === true
              "
              class="tooltipbutton hover:text-theme-41 cursor-pointer"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 fill-current"
                viewBox="0 0 512 512"
                @click="
                  () => $emit('duplicaRev', doc, active_filters, pagination)
                "
              >
                <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                  d="M288 448H64V224h64V160H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H288c35.3 0 64-28.7 64-64V384H288v64zm-64-96H448c35.3 0 64-28.7 64-64V64c0-35.3-28.7-64-64-64H224c-35.3 0-64 28.7-64 64V288c0 35.3 28.7 64 64 64z"
                />
              </svg>
              <span
                class="tooltipbuttontext"
                style="width: 80px; margin-top: -10px; margin-left: 10px"
                >Duplica</span
              >
            </a>

            <a
              class="tooltipbutton hover:text-theme-41"
              :class="[
                doc.state == 'ANNULLATO'
                  ? 'opacity-40 cursor-default pointer-events-none'
                  : 'cursor-pointer',
                ''
              ]"
              @click="handleSendMessage(doc)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="fill-current h-4"
              >
                <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M480 32c0-12.9-7.8-24.6-19.8-29.6s-25.7-2.2-34.9 6.9L381.7 53c-48 48-113.1 75-181 75H192 160 64c-35.3 0-64 28.7-64 64v96c0 35.3 28.7 64 64 64l0 128c0 17.7 14.3 32 32 32h64c17.7 0 32-14.3 32-32V352l8.7 0c67.9 0 133 27 181 75l43.6 43.6c9.2 9.2 22.9 11.9 34.9 6.9s19.8-16.6 19.8-29.6V300.4c18.6-8.8 32-32.5 32-60.4s-13.4-51.6-32-60.4V32zm-64 76.7V240 371.3C357.2 317.8 280.5 288 200.7 288H192V192h8.7c79.8 0 156.5-29.8 215.3-83.3z"
                />
              </svg>
              <span
                class="tooltipbuttontext"
                style="width: 80px; margin-top: -10px; margin-left: 10px"
                >Invia</span
              >
            </a>

            <a
              class="hover:text-theme-41"
              :class="[
                doc.state == 'ANNULLATO'
                  ? 'opacity-40 cursor-default pointer-events-none'
                  : 'cursor-pointer',
                ''
              ]"
              @click="handleDeleteRecord(doc)"
            >
              <i class="fas fa-times text-base" />
            </a>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-if="documents && totalRecords === 0"
      class="w-full flex justify-center mt-2"
    >
      <span v-if="loadingSearchRev">
        <RotateCwIcon class="animate-spin h-full w-5 mr-3 scale-150" /> Ricerca
        ricette in corso...</span
      >
      <span v-else> Nessun Risultato</span>
    </div>
  </div>
  <!-- END: Data List -->
</template>

<script>
import { defineComponent, computed, ref, toRaw } from 'vue'
import Paginator from '../../components/paginator/Main'
import { useStore } from '@/store'
import axios from '../../gateway/backend-api'

export default defineComponent({
  components: {
    Paginator
  },
  props: {
    documents: {
      type: [Object],
      default: function () {
        return {}
      }
    },
    tableConfigClinicDoc: {
      type: [Array],
      default: function () {
        return []
      }
    },
    loadingSearchRev: Boolean,
    selectedPatient: Boolean,
    totalRecords: {
      type: [Number],
      default: function () {
        return 0
      }
    },
    emitRev: {
      type: [Boolean],
      default: true
    },
    editRev: {
      type: [Boolean],
      default: true
    },
    forPageOption: {
      type: [Array],
      default: function () {
        return [10, 15, 20]
      }
    }
  },
  emits: [
    'handleSavePdfRev',
    'handleSendMessage',
    'handleDeleteRev',
    'duplica',
    'edit',
    'handleLoadRev'
  ],
  setup(props, { emit }) {
    const store = useStore()
    const pagination = ref({
      page: 1,
      forPage: 10
    })

    const permissions = computed(() => {
      return store.state.reusableData.permissions
        ? store.state.reusableData.permissions
        : null
    })

    // variabili filtri
    const filtered_documents = ref(null)
    const active_filters = ref({})
    const filterActive = ref(false)

    // Metodo per eliminare un elemento dalla lista
    const handleDeleteRecord = async (elem) => {
      emit('handleDeleteRev', elem, toRaw(active_filters.value))
      pagination.value.page = 1
    }

    //  cambio elementi per pagina, e ritorno nella prima pagina
    const changePagination = (value) => {
      if (pagination.value.page != value.page) {
        pagination.value.page = value.page
        pagination.value.forPage = value.forPage
        emit('handleLoadRev', toRaw(active_filters.value), pagination.value)
      }
    }

    const changeNumPages = (elem) => {
      if (pagination.value.forPage != elem.target.value) {
        pagination.value.page = 1
        pagination.value.forPage = elem.target.value
        emit('handleLoadRev', toRaw(active_filters.value), pagination.value)
      }
    }

    const emettiRev = (id) => {
      if (id) {
        store.dispatch('commonComponents/openConfirmModal', {
          title: 'CONFERMA',
          subtitle: 'Sei sicuro di voler emettere questa ricetta?',
          background: false,
          confirm: () => {
            store.dispatch('commonComponents/showLoadingModalAction')
            axios
              .post('/' + localStorage.getItem('clinic') + '/rev/emettiRev', {
                rev_id: id
              })
              .then(() => {
                store.dispatch('commonComponents/hideLoadingModalAction')
                emit(
                  'handleLoadRev',
                  toRaw(active_filters.value),
                  pagination.value
                )
              })
              .catch((error) => {
                store.dispatch('commonComponents/hideLoadingModalAction')
                emit(
                  'handleLoadRev',
                  toRaw(active_filters.value),
                  pagination.value
                )
                if (error.response && error.response.status === 403)
                  store.dispatch('commonComponents/openNotificationModal', {
                    message:
                      error?.response?.data?.msg?.errors[0]?.message ??
                      error.response.data.message
                  })
              })
          },
          decline: () => {
            console.log('Ho declinato la risposta di invio fattura')
          }
        })
      }
    }

    // ----- COMPUTED ----- //
    const table_config = computed(function () {
      // configurazione tabella, filtrando i dati del paziente nel caso in cui siamo nella sezione anagrafica paziente
      return props.tableConfigClinicDoc.filter(
        (elem) => !(elem.key === 'patient' && props.selectedPatient)
      )
    })

    // ----- FINE COMPUTED ----- //

    return {
      table_config,
      pagination,
      changePagination,
      filtered_documents,
      changeNumPages,
      active_filters,
      filterActive,
      handleDeleteRecord,
      emettiRev,
      permissions
    }
  },
  watch: {
    documents(value) {
      this.filtered_documents = value
      //this.active_filters.push('delete')
    }
  },
  methods: {
    handleSendMessage(doc) {
      this.$emit('handleSendMessage', doc)
    },
    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()

      if (this.filterActive)
        this.$emit('handleLoadRev', toRaw(this.active_filters), this.pagination)
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    }
  }
})
</script>
