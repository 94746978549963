<template>
  <!-- Main modal -->
  <div
    id="defaultModal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-12 p-5 mx-auto drug-cont overflow-scroll scrollbar-none border w-4/5 lg:w-3/5 shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->

      <div class="mb-9">
        <button type="button" class="closing-btn m" @click="$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <!-- Modal header -->
      <div class="my-2 mx-2">
        <h3 class="modal-title">Aggiungi Farmaco</h3>
      </div>
      <!-- Modal body -->
      <div class="mx-2 py-3">
        <div class="flex flex-col mb-1">
          <!--  START GRID DIV -->

          <div
            class="flex flex-col sm:flex-row sm:items-center justify-start mb-3 mx-3"
          >
            <h2 class="font-bold nunito text-base flex-0 mr-6 mb-3 sm:mb-0">
              Tipologia Farmaco
            </h2>
            <div class="flex-1">
              <select
                v-model="selectedDrugType"
                class="shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400 ;"
                :disabled="editDrugPet"
              >
                <option v-for="show in drugsTypes" :key="show" :value="show">
                  {{ show }}
                </option>
              </select>
            </div>
          </div>

          <div v-if="selectedDrugType === 'Farmaco'" class="w-full">
            <div
              class="bg-theme-2 w-full py-2 rounded-lg details-subtitle text-left my-3"
            >
              <h2 class="pl-2">Farmaco</h2>
            </div>
            <div
              class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mx-3 mb-9"
            >
              <div class="col-span-4">
                <label class="form-label required">Codice AIC: </label>
                <div id="select-aic" class="">
                  <div class="">
                    <v-select
                      ref="inputAic"
                      v-model="denominazione"
                      :options="drugs"
                      label="AIC"
                      placeholder="Inserisci almeno 2 caratteri"
                      @search="searchDrugs"
                    >
                      <template #option="option">
                        <table class="mb-2">
                          <tr>
                            <th>{{ option.AIC }} - {{ option.MARCHIO }}</th>
                          </tr>
                          <tr>
                            <td>{{ option.CONFEZIONE }}</td>
                          </tr>
                        </table>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
              <div class="col-span-4">
                <label class="form-label required">Denominazione: </label>
                <div id="multiple-select-drugs" class="">
                  <div class="">
                    <v-select
                      ref="inputDrugs"
                      v-model="denominazione"
                      :options="drugs"
                      label="MARCHIO"
                      placeholder="Seleziona denominazione"
                      @search="searchDrugs"
                    >
                      <template #option="option">
                        <table class="mb-2">
                          <tr>
                            <th>{{ option.AIC }} - {{ option.MARCHIO }}</th>
                          </tr>
                          <tr>
                            <td>{{ option.CONFEZIONE }}</td>
                          </tr>
                        </table>
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
              <div class="col-span-3">
                <label class="form-label">Confezione: </label>
                <input
                  v-model="confezione"
                  type="text"
                  class="shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                  required
                  disabled
                />
              </div>
              <div class="col-span-1">
                <label class="form-label">Categoria Farmaco: </label>
                <select
                  v-model="selectedDrugCategory"
                  class="shadow-sm border appearance-none border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                  disabled
                >
                  <option
                    v-for="show in drugsCategories"
                    :key="show"
                    :value="show"
                  >
                    {{ show }}
                  </option>
                </select>
              </div>
              <div class="col-span-2">
                <label class="form-label">Deroga: </label>
                <select
                  v-model="selectedDeroghe"
                  class="shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                >
                  <option v-for="show in deroghe" :key="show" :value="show">
                    {{ show }}
                  </option>
                </select>
              </div>
              <div class="col-span-2">
                <label class="form-label">Ormonale o vaccinale: </label>
                <select
                  v-model="selectedOrmonal"
                  class="shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                >
                  <option
                    v-for="show in ormonalCategories"
                    :key="show"
                    :value="show"
                  >
                    {{ show }}
                  </option>
                </select>
              </div>
            </div>

            <div
              class="bg-theme-2 w-full py-2 rounded-lg details-subtitle text-left my-3"
            >
              <h2 class="pl-2">Trattamento</h2>
            </div>

            <div class="mb-2 mx-3">
              <div class="flex flex-col">
                <label class="form-label flex-1"
                  >Trattamento ai sensi degli artt. 4 e 5 del D.Lgs. 158/2006 o
                  Vaccino?
                </label>

                <select
                  id=""
                  v-model="selectedTrattamento"
                  name=""
                  class="flex-1 shadow-sm border border-gray-300 rounded py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                >
                  <option v-for="show in trattamento" :key="show" :value="show">
                    {{ show }}
                  </option>
                </select>
                <p class="text-xs font-medium text-gray-600 mt-1 mb-2">
                  Selezionare solo per i farmaci per cui è obbligatoria la
                  segnalazione alle ASL
                </p>
              </div>
            </div>
          </div>

          <div v-else-if="selectedDrugType === 'Estero'" class="mx-3 mt-3 mb-9">
            <div
              class="bg-theme-2 w-full py-2 rounded-lg details-subtitle text-left mb-3"
            >
              <h2 class="pl-2">Trattamento</h2>
            </div>

            <div class="mb-2">
              <div class="flex flex-col md:flex-row mt-4 gap-3">
                <div class="flex-1">
                  <label class="form-label required">Descrizione: </label>

                  <textarea
                    v-model="description"
                    class="mt-1 shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                    rows="6"
                    required
                  />
                </div>
              </div>
              <p class="mt-2 py-2">
                <span class="text-theme-41"
                  >Per i farmaci esteri degli stati membri, visita la Banca dei
                  Medicinali Veterinari dell'Unione al sito
                </span>
                <a
                  class="text-theme-41 italic font-bold"
                  href="https://medicines.health.europa.eu/veterinary/it"
                  target="blank"
                  >www.medicinesinfo.eu/it</a
                >
              </p>
            </div>

            <div
              class="mb-2 flex flex-col md:flex-row justify-between gap-3 items-start"
            >
              <div class="w-full md:w-2/5 flex flex-col">
                <label class="form-label flex-1"
                  >Si tratta di un farmaco Antimicrobico?
                </label>

                <select
                  id=""
                  v-model="selectedAntimicrobico"
                  name=""
                  class="flex-1 shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                >
                  <option
                    v-for="show in antimicrobico"
                    :key="show"
                    :value="show"
                  >
                    {{ show }}
                  </option>
                </select>
              </div>

              <div class="w-full md:w-3/5 flex flex-col">
                <label class="form-label text-sm"
                  >Trattamento ai sensi degli artt. 4 e 5 del D.Lgs. 158/2006 o
                  Vaccino?
                </label>

                <select
                  id=""
                  v-model="selectedTrattamento"
                  name=""
                  class="flex-1 shadow-sm border border-gray-300 rounded py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                >
                  <option v-for="show in trattamento" :key="show" :value="show">
                    {{ show }}
                  </option>
                </select>
                <p
                  class="text-xs font-medium text-gray-600 flex items-end mt-1"
                >
                  Selezionare solo per i farmaci per cui è obbligatoria la
                  segnalazione alle ASL
                </p>
              </div>
            </div>
            <div></div>
          </div>

          <div
            v-else-if="selectedDrugType === 'Galenico'"
            class="mx-3 mt-3 mb-9"
          >
            <div
              class="bg-theme-2 w-full py-2 rounded-lg details-subtitle text-left mb-3"
            >
              <h2 class="pl-2">Trattamento</h2>
            </div>

            <div class="flex flex-col md:flex-row mt-4 gap-3">
              <div class="flex-1">
                <label class="form-label required">Descrizione: </label>

                <textarea
                  v-model="description"
                  class="mt-1 shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                  rows="6"
                  required
                />
              </div>
            </div>
          </div>

          <div
            v-else-if="selectedDrugType === 'Omeopatico'"
            class="mx-3 mt-3 mb-9"
          >
            <div
              class="bg-theme-2 w-full py-2 rounded-lg details-subtitle text-left mb-3"
            >
              <h2 class="pl-2">Trattamento</h2>
            </div>

            <div class="flex flex-col md:flex-row mt-4 gap-3">
              <div class="flex-1">
                <label class="form-label required">Descrizione: </label>

                <textarea
                  v-model="description"
                  class="mt-1 shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                  rows="6"
                  required
                />
              </div>
            </div>
          </div>

          <!-- START TRATTAMENTO GENRALE -->
          <div class="mx-3 mb-2">
            <div
              class="grid grid-cols-1 lg:grid-cols-7 items-start justify-between gap-3"
            >
              <div
                class="col-span-1"
                :class="[
                  chronical !== true ? 'lg:col-span-2' : 'lg:col-span-3'
                ]"
              >
                <label class="form-label">Ripetibile: </label>

                <select
                  id=""
                  v-model="selectedRepeatable"
                  name=""
                  class="shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                >
                  <option v-for="show in repeatable" :key="show" :value="show">
                    {{ show }}
                  </option>
                </select>
              </div>
              <div
                class="col-span-1"
                :class="[
                  chronical !== true ? 'lg:col-span-2' : 'lg:col-span-3'
                ]"
              >
                <label class="form-label required">N. Confezioni: </label>
                <input
                  v-model="cNumber"
                  type="number"
                  min="1"
                  class="shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                  required
                  :disabled="selectedDrugType == 'Galenico'"
                />
              </div>
              <div v-if="chronical !== true" class="col-span-1 lg:col-span-2">
                <label
                  class="form-label"
                  :class="[chronical === true ? 'text-gray-500' : 'required']"
                  >Trattamento (gg):
                </label>
                <input
                  v-model="tNumber"
                  :disabled="chronical === true"
                  type="number"
                  min="0"
                  class="shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                  :class="[
                    chronical === true ? 'text-gray-500' : 'text-gray-700'
                  ]"
                  required
                />
              </div>
              <!-- <div v-else class="col-span-1 lg:col-span-2"></div> -->

              <div class="col-span-1 flex flex-col items-end">
                <label class="form-label">Cronico </label>
                <input
                  v-model="chronical"
                  type="checkbox"
                  class="form-check-switch ml-1"
                  required
                  :value="chronical"
                />
              </div>
            </div>
            <div class="flex flex-col md:flex-row mt-4 gap-3">
              <div class="flex-1">
                <label class="form-label required">Posologia: </label>

                <textarea
                  v-model="posologia"
                  class="shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                  rows="6"
                  required
                />
              </div>
            </div>

            <div v-if="selectedDrugType === 'Farmaco'">
              <div
                class="bg-theme-2 w-full py-2 rounded-lg details-subtitle text-left my-3"
              >
                <h2 class="pl-2">
                  Utilizzo Antimicrobici ai sensi e nel rispetto dell'art. 107
                  del Regolamento (EU) 2019/6
                </h2>
              </div>

              <div class="mb-2">
                <div class="flex flex-col">
                  <label class="form-label required"
                    >Si dichiara che la REV è redatta per
                  </label>

                  <select
                    id=""
                    v-model="selectedRevOption"
                    name=""
                    class="shadow-sm border border-gray-300 rounded py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                  >
                    <option v-for="show in revOption" :key="show" :value="show">
                      {{ show }}
                    </option>
                  </select>
                </div>
              </div>

              <div
                v-if="
                  selectedRevOption == 'PROFILASSI' ||
                  selectedRevOption == 'METAFILASSI'
                "
                class="mb-2"
              >
                <div class="flex flex-col">
                  <label class="form-label required"
                    >Giustificazione per Profilassi/Metafilassi
                  </label>

                  <select
                    id=""
                    v-model="selectedWhyProfilassiMetafilassi"
                    name=""
                    class="shadow-sm border border-gray-300 rounded py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                  >
                    <option
                      v-for="show in whyProfilassiMetafilassi"
                      :key="show"
                      :value="show"
                    >
                      {{ show }}
                    </option>
                  </select>
                </div>
                <div class="flex flex-col md:flex-row mt-4 gap-3">
                  <div class="flex-1">
                    <label
                      class="form-label"
                      :class="[
                        selectedWhyProfilassiMetafilassi === 'Altro'
                          ? 'required'
                          : ''
                      ]"
                      >Note alla giustificazione:
                    </label>

                    <textarea
                      v-model="noteWhyProfilassiMetafilassi"
                      class="shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                      rows="6"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>

            <div
              class="bg-theme-2 w-full py-2 rounded-lg details-subtitle text-left my-3"
            >
              <h2 class="pl-2">Altro</h2>
            </div>
            <div class="flex flex-col md:flex-row mt-4 gap-3">
              <div class="flex-1">
                <label class="form-label">Altre Annotazioni: </label>

                <textarea
                  v-model="note"
                  class="shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                  rows="6"
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row justify-end">
          <input
            ref="file"
            type="file"
            class="form-control p-2 hidden"
            required
            @change="handleFileChange"
          />
        </div>
        <div></div>
      </div>
      <!-- Modal footer -->
      <div class="flex justify-end items-center mx-3">
        <button
          v-if="!editDrugPet"
          type="button"
          class="orange-btn"
          @click="handleConfirm"
        >
          <PlusCircleIcon class="h-5 w-5 mr-2" /> Aggiungi
        </button>

        <button v-else type="button" class="orange-btn" @click="handleEdit">
          <EditIcon class="h-5 w-5 mr-2" /> Modifica
        </button>

        <button class="ml-3 gray-btn" type="button" @click="$emit('close')">
          <span class="h-5"></span>
          Annulla
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStore } from '@/store'
import _ from 'lodash'
import axios from '../../gateway/backend-api'

export default defineComponent({
  props: ['editDrugPet', 'editableDrugPet', 'drugsTypes'],
  emits: ['close', 'confirm', 'edit'],
  setup(props, { emit }) {
    const store = useStore()

    const denominazione = ref('')
    const aic = ref('')
    const confezione = ref('')
    const cNumber = ref(1)
    const tNumber = ref(1)
    const chronical = ref(false)
    const posologia = ref('')
    const description = ref('')
    const noteWhyProfilassiMetafilassi = ref('')
    const note = ref('')
    const drugs = ref([])
    const farmaci = ref([])
    const indexDrugsListEditable = ref(null) //index NewDrugsList dell'item da modificare

    // const drugsTypes = ['Farmaco', 'Galenico', 'Omeopatico', 'Estero']
    const selectedDrugType = ref(props.drugsTypes[0])

    const drugsCategories = ['', 'Farmaci Veterinari', 'Farmaci Umani']
    const selectedDrugCategory = ref(drugsCategories[0])

    const deroghe = [
      'No Deroga',
      'Deroga per Altro Motivo',
      'Deroga per Specie',
      'Deroga per Specie e Patologia',
      'Deroga per Patologia'
    ]
    const selectedDeroghe = ref(deroghe[0])

    const ormonalCategories = ['No', 'Ormonale', 'Vaccinale']
    const selectedOrmonal = ref(ormonalCategories[0])

    const repeatable = ['No', 'Si']
    const selectedRepeatable = ref(repeatable[0])

    const trattamento = ['No', 'Vaccino']
    const selectedTrattamento = ref(trattamento[0])

    const antimicrobico = ['No', 'Si']
    const selectedAntimicrobico = ref(antimicrobico[0])

    const revOption = ['TERAPIA', 'PROFILASSI', 'METAFILASSI']
    const selectedRevOption = ref(revOption[0])

    const whyProfilassiMetafilassi = [
      'Altro',
      'Non risultano disponibili alternative adeguate',
      'Sulla base delle conoscenze epidemiologiche',
      'Sulla base di riscontri analitici',
      'Sulla base di diagnosi clinica di malattia infettiva'
    ]
    const selectedWhyProfilassiMetafilassi = ref(whyProfilassiMetafilassi[0])

    //aggiungere patologia in tab farmaco
    const addDrug = () => {}

    const handleConfirm = () => {
      if (
        !posologia.value ||
        (!chronical.value && tNumber.value == 0) ||
        cNumber.value == 0 ||
        (selectedDrugType.value == 'Farmaco' && !denominazione.value) ||
        (selectedRevOption.value != 'TERAPIA' &&
          selectedWhyProfilassiMetafilassi.value == 'Altro' &&
          !noteWhyProfilassiMetafilassi.value)
      ) {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Inserire tutti i campi obbligatori!'
        })
      } else if (selectedDrugType.value != 'Farmaco' && !description.value) {
        console.log('Descrizione')
      } else {
        if (selectedDrugType.value == 'Farmaco') {
          farmaci.value = {
            aic: denominazione.value['AIC'],
            farmaco: denominazione.value['MARCHIO'],
            confezione: denominazione.value['CONFEZIONE'],
            qty: cNumber.value,
            posologia: posologia.value,
            durata: tNumber.value,
            tipo: selectedDrugType.value
          }
        }

        const newPharma = {
          type: selectedDrugType,
          category: selectedDrugCategory,
          deroga: selectedDeroghe,
          ormonal: selectedOrmonal,
          repeatable: selectedRepeatable,
          antimicrobico: selectedAntimicrobico,
          revOption: selectedRevOption,
          whyProfilassiMetafilassi: selectedWhyProfilassiMetafilassi,
          trattamento: selectedTrattamento,
          denominazione,
          aic,
          confezione,
          cNumber,
          tNumber,
          chronical,
          posologia,
          description,
          noteWhyProfilassiMetafilassi,
          note,
          farmaci,
          indexDrugsListEditable
        }
        emit('confirm', newPharma)
      }
    }

    const handleEdit = () => {
      if (denominazione.value) {
        aic.value = denominazione.value.AIC
        farmaci.value.aic = denominazione.value.AIC
        farmaci.value.farmaco = denominazione.value.MARCHIO
        farmaci.value.confezione = denominazione.value.CONFEZIONE
      }

      farmaci.value.qty = cNumber.value
      farmaci.value.posologia = posologia.value
      farmaci.value.durata = tNumber.value
      farmaci.value.tipo = selectedDrugType.value

      const newPharma = {
        type: selectedDrugType,
        category: selectedDrugCategory,
        deroga: selectedDeroghe,
        ormonal: selectedOrmonal,
        repeatable: selectedRepeatable,
        antimicrobico: selectedAntimicrobico,
        revOption: selectedRevOption,
        whyProfilassiMetafilassi: selectedWhyProfilassiMetafilassi,
        trattamento: selectedTrattamento,
        denominazione,
        aic,
        confezione,
        cNumber,
        tNumber,
        chronical,
        posologia,
        description,
        noteWhyProfilassiMetafilassi,
        note,
        farmaci,
        indexDrugsListEditable
      }
      emit('edit', newPharma)
    }

    return {
      denominazione,
      aic,
      confezione,
      selectedDrugType,
      farmaci,
      drugs,
      drugsCategories,
      selectedDrugCategory,
      deroghe,
      selectedDeroghe,
      ormonalCategories,
      selectedOrmonal,
      repeatable,
      antimicrobico,
      trattamento,
      selectedRepeatable,
      selectedAntimicrobico,
      selectedTrattamento,
      selectedRevOption,
      revOption,
      whyProfilassiMetafilassi,
      selectedWhyProfilassiMetafilassi,
      cNumber,
      tNumber,
      chronical,
      posologia,
      note,
      description,
      noteWhyProfilassiMetafilassi,
      indexDrugsListEditable,
      addDrug,
      handleConfirm,
      handleEdit
    }
  },
  watch: {
    denominazione(value) {
      if (value && value['AIC']) {
        this.aic = value['AIC']
        this.confezione = value['CONFEZIONE']
        if (value['TIPO_PRODOTTO'] == 'UMANO')
          this.selectedDrugCategory = 'Farmaci Umani'
        else this.selectedDrugCategory = 'Farmaci Veterinari'
      } else {
        this.confezione = ''
        this.aic = ''
        this.selectedDrugCategory = ''
      }
    },
    selectedDrugType(value) {
      if (value == 'Galenico') this.cNumber = 1
    }
  },
  created() {
    if (this.editableDrugPet.length && this.editDrugPet) {
      this.denominazione = this.editableDrugPet[0].nome
      this.aic = this.editableDrugPet[0].farmaco.aic
      this.farmaci = this.editableDrugPet[0].farmaco
      this.posologia = this.editableDrugPet[0].farmaco.posologia
        ? this.editableDrugPet[0].farmaco.posologia
        : this.editableDrugPet[0].posologia
      this.tNumber = this.editableDrugPet[0].farmaco.durata
        ? this.editableDrugPet[0].farmaco.durata
        : this.editableDrugPet[0].tNumber
      this.cNumber = this.editableDrugPet[0].farmaco.qty
        ? this.editableDrugPet[0].farmaco.qty
        : this.editableDrugPet[0].qty
      this.selectedDrugType = this.editableDrugPet[0].farmaco.tipo
        ? this.editableDrugPet[0].farmaco.tipo
        : this.editableDrugPet[0].tipo
      this.chronical = this.editableDrugPet[0].data.chronical
      this.description = this.editableDrugPet[0].description
      this.selectedDeroghe = this.editableDrugPet[0].data.deroga
      this.note = this.editableDrugPet[0].data.note
      this.selectedOrmonal = this.editableDrugPet[0].data.ormonal
      this.selectedRepeatable = this.editableDrugPet[0].data.repeatable
      this.selectedAntimicrobico = this.editableDrugPet[0].antimicrobico
      this.selectedRevOption = this.editableDrugPet[0].revOption
      this.selectedTrattamento = this.editableDrugPet[0].trattamento
      this.selectedWhyProfilassiMetafilassi =
        this.editableDrugPet[0].whyProfilassiMetafilassi
      this.noteWhyProfilassiMetafilassi =
        this.editableDrugPet[0].noteWhyProfilassiMetafilassi
      if (typeof this.editableDrugPet[0].indexDrugsList !== 'undefined')
        this.indexDrugsListEditable = this.editableDrugPet[0].indexDrugsList
      this.confezione = this.editableDrugPet[0].farmaco?.confezione
      this.selectedDeroghe = this.editableDrugPet[0].deroga
    }
  },
  methods: {
    searchDrugs(search, loading) {
      if (search.length > 1) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: _.debounce((loading, search, vm) => {
      axios
        .post('/' + localStorage.getItem('clinic') + '/search/searchDrug', {
          searchText: search
        })
        .then((res) => {
          vm.drugs = res.data
          loading(false)
        })
    }, 350)
  }
})
</script>
