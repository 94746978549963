<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-20 p-2 mx-auto overflow-scroll scrollbar-none border sm:w-3/5 w-full shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->

      <button type="button" class="closing-btn mr-2" @click="$emit('close')">
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>

      <div class="flex flex-col content-end overflow-y-auto mt-10">
        <!-- Modal header -->
        <div class="flex modal-title justify-center">
          <h3 class="">Aggiungi peso</h3>
        </div>
        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div
          class="flex flex-col md:flex-row md:items-center justify-between gap-4 px-6 py-2 mt-2"
        >
          <div class="flex-1">
            <label class="form-label">Peso </label>
            <input
              v-model="weight"
              type="number"
              step="0.01"
              name="Prezzo"
              class="custom-input"
            />
          </div>
          <div class="flex-1">
            <label class="form-label">Body Score </label>
            <input
              v-model="bodyScore"
              type="number"
              step="0"
              name="Prezzo"
              class="custom-input"
            />
          </div>
        </div>

        <div class="flex justify-end items-center px-6 mt-5">
          <button type="button" class="orange-btn" @click="save">
            <PlusCircleIcon class="h-5 w-5 mr-2" /> Aggiungi
          </button>

          <button class="ml-3 gray-btn" type="button" @click="$emit('close')">
            <span class="h-5"></span>
            Annulla
          </button>
        </div>
        <!-- Modal footer -->
        <div
          class="border-t-1 border-gray-400 w-full px-6 text-right flex justify-end items-end mb-2"
        ></div>
        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  components: {},
  emits: ['close', 'save'],
  setup(props, { emit }) {
    const weight = ref('')
    const bodyScore = ref('')

    const save = () => {
      if (weight.value) emit('save', weight.value, bodyScore.value)
    }

    return {
      weight,
      bodyScore,
      save
    }
  }
})
</script>
