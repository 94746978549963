<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black scrollbar-none"
    :class="
      background && background === false ? 'bg-opacity-0' : 'bg-opacity-50'
    "
  >
    <div
      class="w-2/5 relative top-20 p-5 mx-auto overflow-scroll scrollbar-none border shadow-lg rounded-md bg-white"
    >
      <button type="button" class="closing-btn" @click="$emit('close')">
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>

      <!-- Modal content -->
      <div class="flex flex-col content-end overflow-y-auto">
        <!-- Modal header -->

        <div class="w-full">
          <h3 class="modal-title mt-8">{{ title }}</h3>
        </div>

        <!-- Fine Modal header -->

        <!-- Modal body -->

        <div class="flex items-center justify-center gap-3 pt-2">
          <div class="flex-0 flex justify-center p-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="h-24 text-theme-41 fill-current"
            >
              <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM256 336c-18 0-32 14-32 32s13.1 32 32 32c17.1 0 32-14 32-32S273.1 336 256 336zM289.1 128h-51.1C199 128 168 159 168 198c0 13 11 24 24 24s24-11 24-24C216 186 225.1 176 237.1 176h51.1C301.1 176 312 186 312 198c0 8-4 14.1-11 18.1L244 251C236 256 232 264 232 272V288c0 13 11 24 24 24S280 301 280 288V286l45.1-28c21-13 34-36 34-60C360 159 329 128 289.1 128z"
              />
            </svg>
          </div>
          <div class="flex-1 pl-3">
            <span class="text-lg">{{ subtitle }}</span>
          </div>
        </div>

        <!-- Modal footer -->

        <div
          :class="[
            confirmText === 'Elettronica' && declineText === 'Cartacea'
              ? 'flex items-center gap-10 justify-center '
              : 'flex items-center gap-2 justify-end px-2'
          ]"
        >
          <button
            type="button"
            :class="[
              confirmText === 'Elettronica'
                ? 'orange-btn-inverted'
                : 'orange-btn'
            ]"
            @click="$emit('confirm')"
          >
            <span v-if="confirmText === 'Elettronica'"
              ><i class="fas fa-laptop"></i
            ></span>
            {{ confirmText ? confirmText : 'Conferma' }}
          </button>
          <button
            type="button"
            :class="[
              declineText === 'Cartacea' ? 'orange-btn-inverted' : 'gray-btn'
            ]"
            @click="$emit('decline')"
          >
            <span v-if="declineText === 'Cartacea'"
              ><i class="fas fa-receipt"></i
            ></span>
            {{ declineText ? declineText : 'Annulla' }}
          </button>
        </div>

        <!-- <div class="p-6 space-y-6">
          <div class="flex flex-col content-start mb-1">
            <button @click="$emit('confirm')">Confermo</button>
            <button @click="$emit('decline')">Annulla</button>
          </div>
        </div> -->

        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: ['title', 'subtitle', 'background', 'confirmText', 'declineText'],
  emits: ['confirm', 'decline'],
  components: {},
  setup() {
    // ----- DATA -----

    // ----- METHODS -----

    // ----- COMPUTED -----

    return {}
  }
})
</script>
