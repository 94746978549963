<template>
  <div v-if="true">
    <div class="p-5 text-center">
      <CheckCircleIcon class="w-16 h-16 text-theme-41 mx-auto mt-3" />
      <div class="text-3xl mt-5">Funzione presto disponibile</div>
      <!-- <div class="text-gray-600 mt-2">Modal with close button</div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: 'PerformanceLog'
}
</script>
