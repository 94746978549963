<template>
  <div
    class="bg-white px-6 py-4 mx-7 rounded-lg content-container overflow-auto scrollbar_custom"
  >
    <div class="px-6 py-3 mx-6">
      <h2 class="modal-title">Dati Struttura</h2>
      <div class="">
        <!-- BEGIN: Form Layout -->
        <form
          action="http://localhost:8000/api/test"
          method="POST"
          class="grid grid-cols-12 gap-3 px-3 mt-3"
        >
          <!-- clinic img -->
          <div class="col-span-4">
            <!-- <label for="" class="form-label">Immagine clinica</label> -->
            <!-- <input
              @change="getFile"
              type="file"
              class="custom-input p-0 bg-white"
              placeholder="Input text"
            /> -->

            <!-- <input
              type="file"
              ref="myFiles"
              id="avatar"
              name="avatar"
              accept="image/png, image/jpeg"
              @change="previewFiles(e)"
            />

            <img src="" alt="" /> -->

            <div class="bg-theme-2 p-6 h-60 mt-3">
              <Dropzone
                ref-key="dropzoneMultipleRef"
                :options="{
                  url: 'https://httpbin.org/post',
                  maxFiles: 1,
                  thumbnailWidth: 300,
                  maxFilesize: 0.5
                }"
                class="m-auto"
              >
                <!-- <div class="text-lg font-medium">Clicca o trascina immagine</div> -->
              </Dropzone>
            </div>
          </div>

          <!-- clinic name  -->

          <div class="col-span-8 grid grid-cols-12">
            <div
              class="col-span-12 mt-3 mb-2 p-2 bg-theme-45 nunito rounded-md flex justify-between"
            >
              <h2 class="ml-3 text-base font-bold">Anagrafica clinica</h2>
              <h2 v-if="clinicData.affiliate_at" class="text-sm font-bold">
                <i class="fas fa-check-circle"></i> Clinica affiliata
              </h2>
            </div>

            <!-- <div class="col-span-12 mt-1 px-3">
              <label for="" class="form-label">Intestatario</label>
              <select class="custom-input mt-2" v-model="selectedClinicType">
                <option value="name">Nome e cognome</option>
                <option selected value="denomination">Denominazione</option>
              </select>
            </div> -->
            <!-- Tax ID code   -->
            <div class="col-span-12 mt-1 px-3">
              <label for="crud-form-3" class="form-label">Codice Fiscale</label>
              <input
                type="text"
                v-model="clinicData.fiscal_code"
                class="custom-input mt-2"
                placeholder="Inserisci il codice fiscale"
              />
            </div>

            <div
              v-if="selectedClinicType === 'name'"
              class="mt-1 px-3 col-span-12 grid grid-cols-2 gap-3"
            >
              <div class="col-span-1">
                <label for="" class="form-label required">Nome</label>
                <input
                  v-model="clinicData.first_name"
                  type="text"
                  class="custom-input mt-2"
                  placeholder="Inserisci il nome"
                />
              </div>
              <div class="col-span-1">
                <label for="" class="form-label required">Cognome</label>
                <input
                  v-model="clinicData.last_name"
                  type="text"
                  class="custom-input mt-2"
                  placeholder="Inserisci il cognome"
                />
              </div>
            </div>
            <div v-else class="col-span-6 mt-1 px-3">
              <label for="" class="form-label required">Denominazione</label>
              <input
                v-model="clinicData.ragione_sociale"
                type="text"
                class="custom-input mt-2"
                placeholder="Inserisci la denominazione della clinica"
              />
            </div>

            <!-- VAT code  -->
            <div
              v-if="selectedClinicType == 'denomination'"
              class="col-span-6 px-3"
            >
              <label for="crud-form-3" class="form-label required"
                >P. Iva</label
              >
              <input
                type="text"
                v-model="clinicData.vat_number"
                class="custom-input mt-2"
                placeholder="Inserisci la partita IVA"
              />
            </div>
          </div>
          <!-- address label -->
          <div class="col-span-12 mt-3 mb-2 p-2 bg-theme-45 nunito rounded-md">
            <h2 class="ml-3 text-base font-bold">Indirizzo</h2>
          </div>

          <!-- clinic address -->
          <div class="col-span-12 px-3">
            <div class="flex-1 py-2 flex">
              <label class="block text-gray-700 text-xs font-bold mb-2"
                >Non trovi l'indirizzo su Google?</label
              >
              <input
                v-model="checkResidency"
                type="checkbox"
                class="form-checkbox ml-3 h-3 w-3 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
              />
            </div>
          </div>
          <div class="col-span-12 px-3">
            <Places
              @place_changed="addressChanged"
              :addressPlaceholderProps="addressLabel"
            />
          </div>
          <div
            v-if="checkResidency"
            class="col-span-12 grid grid-cols-6 px-3 py-2 gap-3"
          >
            <!-- clinic address -->
            <div class="col-span-3 flex flex-col">
              <label for="crud-form-3" class="form-label">Indirizzo</label>
              <input
                v-model="clinicData.address.address1.address"
                type="text"
                class="custom-input"
                placeholder="Inserisci l'indirizzo"
              />
            </div>
            <div class="col-span-1 flex flex-col">
              <label for="crud-form-3" class="form-label">Nr. Civico</label>
              <input
                v-model="clinicData.address.address1.house_number"
                type="text"
                class="custom-input"
                placeholder="Inserisci il numero civico"
              />
            </div>
            <!-- clinic city -->
            <div class="col-span-2 flex flex-col">
              <label for="crud-form-3" class="form-label">Città</label>
              <input
                v-model="clinicData.address.address1.city"
                type="text"
                class="custom-input"
                placeholder="Inserisci la città"
              />
            </div>
            <!-- clinic postal code -->
            <div class="col-span-1 flex flex-col">
              <label for="crud-form-3" class="form-label">CAP</label>
              <!-- input type was number -->
              <input
                v-model="clinicData.address.address1.cap"
                type="text"
                class="custom-input"
                placeholder="Inserisci il cap"
              />
            </div>

            <!-- clinic district -->
            <div class="col-span-2 flex flex-col">
              <label for="crud-form-3" class="form-label">Provincia</label>
              <input
                v-model="clinicData.address.address1.district"
                type="text"
                class="custom-input"
                placeholder="Inserisci la provincia"
              />
            </div>
            <!-- clinic region -->
            <div class="col-span-2 flex flex-col">
              <label for="crud-form-3" class="form-label">Regione</label>
              <input
                v-model="clinicData.address.address1.region"
                type="text"
                class="custom-input"
                placeholder="Inserisci la regione"
              />
            </div>
            <!-- clinic country -->
            <div class="col-span-2 flex flex-col">
              <label for="crud-form-3" class="form-label">Stato</label>
              <input
                v-model="clinicData.address.address1.country"
                type="text"
                class="custom-input"
                placeholder="Inserisci la nazione"
              />
            </div>
          </div>

          <!-- Contacts label -->
          <div class="col-span-12 mt-3 mb-2 p-2 bg-theme-45 nunito rounded-md">
            <h2 class="ml-3 text-base font-bold">Contatti</h2>
          </div>

          <!-- phone number -->
          <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label required">Tel. fisso</label>
            <input
              type="text"
              class="custom-input mt-2"
              v-model="clinicData.phones.phone1"
              placeholder="Inserisci il numero di telefono"
            />
          </div>
          <!-- cellular number -->
          <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label">Tel cellulare</label>
            <input
              type="text"
              class="custom-input mt-2"
              v-model="clinicData.phones.phone2"
              placeholder="Inserisci il numero di cellulare"
            />
          </div>
          <!-- fax number -->
          <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label">FAX</label>
            <input
              type="text"
              class="custom-input mt-2"
              v-model="clinicData.fax"
              placeholder="Inserisci il numero di fax"
            />
          </div>
          <!-- PEC address -->
          <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label required">PEC</label>
            <input
              type="email"
              class="custom-input mt-2"
              v-model="clinicData.pecs.pec1"
              placeholder="Inserisci la PEC"
            />
          </div>
          <!-- email 1 address -->
          <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label">Email 1</label>
            <input
              type="email"
              class="custom-input mt-2"
              v-model="clinicData.emails.email1"
              placeholder="Inserisci la mail"
            />
          </div>
          <!-- email 2 address -->
          <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label">Email 2</label>
            <input
              type="email"
              class="custom-input mt-2"
              v-model="clinicData.emails.email2"
              placeholder="Inserisci la mail"
            />
          </div>
          <!-- sender email address -->
          <!-- <div class="col-span-6 mt-1 px-3">
          <label for="" class="form-label">Email mittente</label>
          <input
            type="text"
            class="custom-input"
            placeholder="Inserisci la mail del mittente"
          />
        </div> -->
          <!-- alias sender -->
          <!-- <div class="col-span-6 mt-1 px-3">
          <label for="" class="form-label">Alias mittente</label>
          <input
            type="text"
            class="custom-input"
            placeholder="Inserisci alias mittente"
          />
        </div> -->
          <!-- alias sms -->
          <!-- <div class="col-span-6 mt-1 px-3">
          <label for="" class="form-label">Alias SMS</label>
          <input
            type="text"
            class="custom-input"
            placeholder="Inserisci alias SMS"
          />
        </div> -->
          <!-- sms disponibility -->
          <!-- <div class="col-span-6 mt-1 px-3">
          <label for="" class="form-label">SMS disponibili</label>
          <div class="col-span-12 flex items-center">
            <input type="checkbox" class="cursor-pointer" />
          </div>
        </div> -->
          <!-- Albo professionale -->

          <!-- <div class="col-span-12 mt-3 mb-2 p-2 bg-theme-45 nunito rounded-md">
            <h2 class="ml-3 text-base font-bold">Albo professionale</h2>
          </div>
          <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label">Albo professionale</label>
            <input
              type="text"
              class="custom-input mt-2"
              placeholder="Inserisci l'albo professionale"
            />
          </div> -->
          <!-- provincia albo -->
          <!-- <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label">Provincia albo</label>
            <Places
              @place_changed="addressChanged"
              :addressPlaceholderProps="addressLabel"
              v-model="clinicData.prov_albo"
            />
          </div> -->
          <!-- numero iscrizione albo -->
          <!-- <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label">Numero iscrizione</label>
            <input
              type="text"
              class="custom-input mt-2"
              v-model="clinicData.num_iscr_albo"
              placeholder="Inserisci il numero di iscrizione all'albo"
            />
          </div> -->
          <!-- Data iscrizione -->
          <!-- <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label">Data iscrizione</label>
            <input
              type="text"
              class="custom-input mt-2 date"
              v-model="clinicData.data_iscr_albo"
            />
          </div> -->

          <!-- Ufficio -->

          <div class="col-span-12 mt-3 mb-2 p-2 bg-theme-45 nunito rounded-md">
            <h2 class="ml-3 text-base font-bold">Iscrizione rea</h2>
          </div>

          <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label">Ufficio</label>
            <input
              type="text"
              class="custom-input mt-2"
              v-model="clinicData.ufficio_albo"
            />
          </div>
          <!-- numero rea -->
          <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label">Numero</label>
            <input
              type="text"
              class="custom-input mt-2"
              placeholder="Inserisci il numero di iscrizione al rea"
              v-model="clinicData.ufficio_num_albo"
            />
          </div>
          <!-- capitale sociale -->
          <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label">Capitale sociale</label>
            <input
              type="text"
              class="custom-input mt-2"
              placeholder="Inserisci il capitale sociale"
              v-model="clinicData.cap_soc_albo"
            />
          </div>
          <!-- Data iscrizione -->
          <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label">Socio Unico</label>
            <select class="custom-input mt-2" v-model="clinicData.socio_albo">
              <option value="unico">Socio unico</option>
              <option value="soci">Più soci</option>
            </select>
          </div>
          <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label">Stato liquidazione</label>
            <select
              class="custom-input mt-2"
              v-model="clinicData.liquidazione_albo"
            >
              <option value="in liquidazione">In liquidazione</option>
              <option value=" non in liquidazione">Non in liquidazione</option>
            </select>
          </div>
          <!-- other info label -->

          <div class="col-span-12 mt-3 mb-2 p-2 bg-theme-45 nunito rounded-md">
            <h2 class="ml-3 text-base font-bold">Altre info</h2>
          </div>

          <!-- clinic IBAN -->
          <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label required">IBAN</label>
            <input
              type="text"
              class="custom-input mt-2"
              v-model="clinicData.IBAN.iban1"
              placeholder="Inserisci IBAN"
            />
          </div>
          <!-- clinic assurance info -->
          <div class="col-span-6 mt-1 px-3">
            <label for="" class="form-label"
              >Informazioni polizza assicurativa</label
            >
            <input
              type="text"
              class="custom-input mt-2"
              v-model="clinicData.insurance_policy"
              placeholder="Inserisci informazioni polizza assicurativa"
            />
          </div>

          <div class="col-span-12 mt-3 mb-2 p-2 bg-theme-45 nunito rounded-md">
            <h2 class="ml-3 text-base font-bold">
              Credenziali Vetinfo direttore sanitario
            </h2>
          </div>
          <div class="col-span-4 mt-1 px-3">
            <label for="" class="form-label required">Codice struttura</label>
            <input
              v-model="clinicData.vetinfo.codice_struttura"
              type="text"
              class="custom-input mt-2"
              placeholder="Inserisci codice struttura"
            />
          </div>
          <div class="col-span-4 mt-1 px-3">
            <label for="" class="form-label required">Username</label>
            <input
              v-model="clinicData.vetinfo.username"
              type="text"
              class="custom-input mt-2"
              placeholder="Inserisci username"
            />
          </div>
          <div class="col-span-4 mt-1 px-3">
            <label for="" class="form-label required">Password</label>
            <input
              v-model="clinicData.vetinfo.password"
              type="password"
              class="custom-input mt-2"
              placeholder="Inserisci password"
            />
          </div>

          <!-- submit btn  -->

          <div class="col-span-12 flex justify-end px-3 mt-3">
            <button
              class="orange-btn w-56"
              type="submit"
              @click.prevent="getFormValues"
            >
              <SaveIcon class="h-5 w-5 mr-2" /> Salva impostazioni
            </button>
          </div>
        </form>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../gateway/backend-api'
import Places from '../../components/places/Places.vue'
import flatpickr from 'flatpickr'
import { Italian } from 'flatpickr/dist/l10n/it.js'

export default {
  components: {
    Places
  },
  data() {
    return {
      img_clinic: '',
      files: [],
      passedControl: true,
      handlerTypeClinic: true,
      selectedClinicType: 'denomination',
      checkResidency: false,
      addressLabel: '',
      clinicData: {
        image: '',
        affiliate_at: null,
        address: {},
        phones: {
          phone1: '',
          phone2: ''
        },
        emails: {
          email1: '',
          email2: ''
        },
        pecs: {
          pec1: ''
        },
        IBAN: {
          iban1: ''
        },
        vetinfo: {
          codice_struttura: '',
          username: '',
          password: ''
        }
      },

      test: [
        {
          image: 'image-test',
          name: 'name-test',
          address: 'address-test',
          city: 'city-test',
          cap: 'cap-test'
        }
      ]
    }
  },
  mounted() {
    flatpickr.localize(Italian)
    flatpickr('.date', {
      maxDate: 'today',
      dateFormat: 'd/m/Y',
      allowInput: true,
      allowInvalidPreload: false
    })

    axios.get('/clinic/' + localStorage.getItem('clinic')).then((res) => {
      this.clinicData = res.data
      if (this.clinicData.pecs == null) {
        this.clinicData.pecs.pec1 = ''
      }
      this.addressLabel =
        this.clinicData.address.address1.address +
        ', ' +
        this.clinicData.address.address1.house_number +
        ', ' +
        this.clinicData.address.address1.city +
        ', ' +
        this.clinicData.address.address1.district +
        ', ' +
        this.clinicData.address.address1.country
    })
  },
  methods: {
    getFormValues: function () {
      if (
        this.clinicData.vetinfo &&
        ((this.clinicData.vetinfo.password &&
          this.clinicData.vetinfo.password !== '') ||
          (this.clinicData.vetinfo.username &&
            this.clinicData.vetinfo.username !== '') ||
          (this.clinicData.vetinfo.codice_struttura &&
            this.clinicData.vetinfo.codice_struttura !== ''))
      ) {
        if (
          !this.clinicData.vetinfo.password ||
          this.clinicData.vetinfo.password === '' ||
          !this.clinicData.vetinfo.username ||
          this.clinicData.vetinfo.username === '' ||
          !this.clinicData.vetinfo.codice_struttura ||
          this.clinicData.vetinfo.codice_struttura === ''
        ) {
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Si prega di compilare tutte le credenziali vetinfo'
          })
          return
        }
      }
      axios
        .post(
          '/clinic/' + localStorage.getItem('clinic') + '/edit',
          this.clinicData
        )
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Clinica modificata con successo'
            })
          }
        })
    },
    getFile: function (event) {
      const files = event.target.files
      files.forEach((file) => {
        this.clinicData.image = file.name
      })
    },
    addressChanged: function (elem) {
      this.clinicData.address.address1.address = elem[1].long_name
      this.clinicData.address.address1.house_number = elem[0].long_name
      this.clinicData.address.address1.cap = elem[7].long_name
      this.clinicData.address.address1.city = elem[3].long_name
      this.clinicData.address.address1.district = elem[4].short_name
      this.clinicData.address.address1.region = elem[5].long_name
      this.clinicData.address.address1.country = elem[6].long_name
    },
    validateEmail() {
      // eslint-disable-next-line
      let mailValidator = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/

      if (
        mailValidator.test(this.clinicData.emails.email1) &&
        mailValidator.test(this.clinicData.emails.email2)
      ) {
        this.passedControl = true
      } else {
        this.passedControl = false
      }
    },

    validatePhone() {},
    requiredInputs() {
      // let required = [
      //   'first_name',
      //   'last_name',
      //   'ragione_sociale',
      //   'fiscal_code',
      //   'vat_number_id',
      //   'IBAN'
      //   // Aggiugere indirizzo, telefono, pec, email1
      // ]
    }
  }
}
</script>
