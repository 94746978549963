<template>
  <ClinicalPageModal v-if="ClinicalPageModal && pet" :pet="pet" />
</template>

<script>
import axios from '../../gateway/backend-api'
import ClinicalPageModal from '../../components/modals/ClinicalPageModal.vue'

export default {
  components: {
    ClinicalPageModal
  },
  data() {
    return {
      pet: '',
      ClinicalPageModal: false
    }
  },

  mounted() {
    if (typeof this.$attrs.pet_id !== 'undefined')
      this.getPet(this.$attrs.pet_id)
    if (this.$attrs.ClinicalPageModal)
      this.ClinicalPageModal = this.$attrs.ClinicalPageModal
  },
  methods: {
    getPet(pet_id) {
      axios
        .get('/' + localStorage.getItem('clinic') + '/pet?pet_id=' + pet_id)
        .then((res) => {
          this.pet = res.data.data[0]
        })
    }
  }
}
</script>
