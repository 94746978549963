<template>
  <div v-if="tableConfigClinicDoc && documents" class="paginator-container">
    <div class="prova mr-5 my-2 flex justify-between">
      <div class="mt-1">
        <span class="flex-row text-md ml-2 font-bold mr-2 mt-1">Mostra </span>

        <select
          class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
          @change="(event) => changeNumPages(event)"
        >
          <option v-for="show in forPageOption" :key="show" :value="show">
            {{ show }}
          </option>
        </select>
        <span class="text-md font-bold ml-2 mt-1"> elementi</span>
      </div>
    </div>
    <Paginator
      v-if="totalRecords > pagination.forPage"
      :for-page-option="pagination.forPage"
      :total-records="totalRecords"
      @changePagination="(event) => changePagination(event)"
    />
  </div>

  <div class="flex w-full justify-end"></div>

  <!-- BEGIN: Data List -->
  <div class="table-container overflow-scroll scrollbar-none pb-3">
    <table v-if="tableConfigClinicDoc && documents" class="w-full">
      <thead class="t-header h-10">
        <tr>
          <th v-for="(item, itemKey) in tableConfigClinicDoc" :key="itemKey">
            {{ item.title }}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody class="mt-5">
        <tr class="">
          <td
            v-for="(elem, index) in tableConfigClinicDoc"
            :key="index"
            class="text-center px-1 py-3"
          >
            <select
              v-if="elem.type === 'select'"
              v-model="elem.value"
              class="w-full border rounded-md h-7 text-gray-500"
            >
              <option selected value="">{{ elem.title }}</option>
              <option v-for="(item, i) in elem.option" :key="i" :value="item">
                {{ item }}
              </option>
            </select>
            <input
              v-else
              v-model="elem.value"
              :type="elem.type"
              min="0"
              :name="elem.title"
              class="form-control h-7"
              :class="{
                invisible: elem.type === 'none',
                'p-0': elem.type === 'date'
              }"
              :placeholder="elem.title"
            />
          </td>
        </tr>
        <tr
          v-for="(doc, docKey) in newDoc"
          :key="docKey"
          class="h-12 text-xs font-normal hover:bg-theme-45"
          :class="{ 'bg-theme-2': docKey % 2 === 0 }"
        >
          <td>
            <input
              v-model="newDoc[docKey].send"
              type="checkbox"
              class="form-check-switch ml-1"
              required
            />
          </td>
          <td class="text-center text-sm border-r-2 border-theme-46">
            <!-- <template v-if="aliasToSave[doc.id]">{{
              $emit('getAliasData', docKey, doc.id)
            }}</template> -->

            {{ doc.name }}
          </td>
          <td class="text-center text-sm border-r-2 border-theme-46">
            <input
              v-model="doc.package_qty"
              class="custom-input focus:outline-none focus:ring-2 focus:ring-theme-41 pl-5 py-1"
              type="number"
              step="0.001"
              placeholder="Quantità"
            />
          </td>
          <td
            class="text-center align-center justify-center text-sm border-r-2 border-theme-46"
          >
            {{ doc.price * (showIva === true ? ivaPerc ?? 1 : 1) }}
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="documents && documents.length === 0"
      class="w-full flex justify-center mt-2"
    >
      Nessun Risultato
    </div>
  </div>
  <!-- END: Data List -->
</template>

<script>
import { defineComponent, ref, toRef, watch } from 'vue'
import { mapGetters } from 'vuex'

import Paginator from '../../components/paginator/Main'

export default defineComponent({
  components: {
    Paginator
  },
  props: [
    'documents',
    'totalRecords',
    'pagination',
    'forPageOption',
    'selectedCategoria',
    'aliasToSave',
    'selectedNew'
  ],
  emits: [
    'removeWeight',
    'openPriceModal',
    'addAlias',
    'getNomenclatore',
    'changePagination',
    'getAliasData',
    'changeNumPages',
    'addElement',
    'removeElement'
  ],
  computed: {
    ...mapGetters({
      showIva: 'reusableData/getShowIva',
      ivaPerc: 'reusableData/getRegimeFiscaleIvaPerc'
    })
  },
  setup(props, { emit }) {
    // variabili paginazione
    const newDoc = toRef(props, 'documents')

    const tableConfigClinicDoc = ref([
      {
        key: 'btn',
        title: '',
        type: ''
      },
      {
        key: 'name',
        title: 'Nome',
        type: 'string'
      },
      {
        key: 'qty',
        title: 'Quantità',
        type: 'none'
      },
      {
        key: 'purchase_price',
        title: 'Prezzo ',
        type: 'none'
      }
    ])

    // Metodo per eliminare un elemento dalla lista

    //  cambio elementi per pagina, e ritorno nella prima pagina
    const changePagination = (value) => {
      emit('changePagination', value)
    }

    // metodo che cambia il numero della pagina
    const changeNumPages = (elem) => {
      emit('changeNumPages', elem.target.value)
    }

    const updateData = () => {
      emit('addAlias', newDoc.value)
    }

    watch(
      newDoc,
      (elem, newElem) => {
        let send = newElem.filter((x) => x.send)
        let notsend = newElem.filter((x) => !x.send)
        // send = send.map((x) => x.id)
        // notsend = notsend.map((x) => x.id)
        emit('addElement', send)
        emit('removeElement', notsend)
      },
      { deep: true }
    )

    // ----- FINE COMPUTED ----- //

    return {
      changePagination,
      changeNumPages,
      tableConfigClinicDoc,
      newDoc,
      updateData
    }
  },
  watch: {
    documents: function () {
      if (this.selectedNew) {
        this.newDoc = this.newDoc.map((elem) => {
          if (this.selectedNew.includes(elem.id)) {
            elem.send = true
          } else {
            elem.send = false
          }
          return elem
        })
      }
    }
  }
})
</script>
