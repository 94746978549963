<template>
  <!-- Main modal -->
  <div
    id="defaultModal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none s"
  >
    <div
      class="relative top-12 max-w-2xl p-5 mx-auto overflow-scroll scrollbar-none border w-3/5 shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="flex flex-col content-end overflow-y-auto">
        <!-- Modal header -->
        <div
          class="flex justify-between items-start p-5 rounded-t border-b dark:border-gray-600"
        >
          <h3
            class="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white"
          >
            Invia SMS
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="this.$emit('close')"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- Modal body -->
        <div class="p-6 space-y-6">
          <div class="flex flex-col content-start mb-1">
            <div class="flex flex-row content-center justify-between">
              <div class="flex flex-row content-center"></div>
            </div>
            <div class="grid grid-cols-12 items-center">
              <label class="form-label font-medium text-base col-span-2"
                >Cliente:
              </label>
              <p class="form-label font-medium ml-2 col-span-6"></p>
              <select
                name=""
                id=""
                class="form-select h-10 bg-gray-100 pl-2 bg-theme-35 col-span-4 my-2"
                v-model="selectedTemplate"
              >
                <option v-for="show in templates" :key="show" :value="show">
                  {{ show.name }}
                </option>
              </select>

              <label class="form-label font-medium text-base m col-span-2"
                >Destinatario:
              </label>
              <v-select
                ref="input1"
                class="col-span-10"
                v-on:keyup.enter="switchFocus(2)"
                multiple
                v-model="destinatario"
                placeholder="Selezionare il destinatario"
                taggable
                :options="['assistenza@vanadium.com', 'cliente@mail.com']"
              />

              <label class="form-label font-medium text-base p-2 m-2 col-span-2"
                >Titolo:
              </label>
              <input
                type="text"
                class="form-control p-2 col-span-10"
                required
                v-model="titolo"
                ref="input2"
                v-on:keyup.enter="switchFocus(3)"
              />
            </div>

            <div class="flex flex-col items-center">
              <label class="form-label font-medium text-base mt-3"
                >Messaggio:
              </label>
            </div>
            <div class="h-50 col-span-12 mt-5 flex flex-col">
              <QuillEditor
                ref="input3"
                theme="snow"
                contentType="html"
                class="form-control h-40"
                v-model:content="message"
                v-on:keyup.enter="switchFocus(4)"
              />
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div
          class="border-t-1 border-gray-400 w-full px-6 text-right flex justify-end items-end"
        >
          <button
            @click="this.$emit('confirm', titolo, message, selectedFile)"
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
            ref="input4"
          >
            <PlusCircleIcon class="h-5 w-5 mr-2" /> Invia
          </button>
          <button
            @click="this.$emit('close')"
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            <span class="h-5"></span>
            Annulla
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import moment from 'moment'

export default defineComponent({
  props: ['elem', 'type'],
  components: {},

  setup() {
    const selectedFile = ref([])
    const message = ref('')
    const titolo = ref('')
    const destinatario = ref([])
    const templates = [
      {
        name: 'Nessun template',
        titolo: '',
        message: ''
      },
      {
        name: 'Attesa',
        titolo: 'Informazione inerente risultati visite',
        message:
          'Buongiorno, purtroppo la elaborazione dei risultati delle visite sta durando più del previsto, le chiediamo di attendere ancora qualche giornata. Cordialmente '
      },
      {
        name: 'Richiesta documenti',
        titolo: 'Richiesta documenti aggiuntivi',
        message:
          'Buongiorno, cordialmente le chiediamo di inviarci il codice fiscale per poter concludere le pratiche iniziate. Cordialmente'
      },
      {
        name: 'Risultati pronti',
        titolo: 'Ritiro risultati analisi',
        message:
          'Buongiorno, i risultati delle analisi sono pronti, e da domani può passare a ritirarli!'
      }
    ]

    const selectedTemplate = ref(templates[0])
    const selectedToSend = []

    const formatdate = (epoch) => {
      return moment.unix(epoch).format('DD/MM/YYYY')
    }

    return {
      selectedFile,
      titolo,
      message,
      templates,
      selectedTemplate,
      selectedToSend,
      formatdate,
      destinatario
    }
  },
  mounted() {
    // this.$refs.input1.$refs.search.focus()
  },
  methods: {},
  watch: {
    selectedTemplate: function (elem) {
      this.titolo = elem.titolo
      this.$refs.input3.setHTML(elem.message)
    }
  }
})
</script>
