<template>
  <div
    class="p-5 text-center content-container bg-theme-2 grid grid-cols-2 gap-5 px-6 content-container overflow-auto scrollbar_custom"
  >
    <!--<div class="col-span-2 flex gap-2 items-center justify-end">
      <span class="text-md ml-2 font-bold mr-2 mt-1"
        >Periodo di riferimento
      </span>

      <select
        class="custom-input w-40 text-left px-1"
        @change="(event) => changeStats(event)"
      >
        <option v-for="show in period" :key="show" :value="show.value">
          {{ show.title }}
        </option>
      </select>
    </div>-->

    <div class="col-span-2 flex gap-2 items-center justify-end">
      <span class="text-md ml-2 font-bold mr-2 mt-1"
        >Periodo di riferimento
      </span>
      <div class="flex gap-2 text-left mt-1">
        <div class="flex items-baseline">
          <span class="mr-2 font-bold">Da: </span
          ><input
            v-model="startDate"
            class="custom-input"
            type="date"
            @change="changeStats"
          />
        </div>
        <div class="flex items-baseline">
          <span class="mr-4 font-bold">A: </span
          ><input
            v-model="endDate"
            class="custom-input"
            type="date"
            @change="changeStats"
          />
        </div>
      </div>
    </div>

    <div
      class="mt-5 bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-30 p-2 col-span-2"
    >
      <h1 class="stats-title text-center">Statistiche fatture</h1>
      <div>
        <h3 class="stats-subs py-1 mt-5 w-full">Stato fatture</h3>
        <div class="grid grid-cols-4 gap-5 mt-5">
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <h1 class="stats-subs">Inviate</h1>
            <div id="attive_no_appuntamento" class="stats-number">
              {{ invoiceStatus['count_inviata'] }}
            </div>
          </div>
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <h1 class="stats-subs">Scartate</h1>
            <div id="attive_no_appuntamento" class="stats-number">
              {{ invoiceStatus['count_scartata'] }}
            </div>
          </div>
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <h1 class="stats-subs">Non consegnate</h1>
            <div id="attive_no_appuntamento" class="stats-number">
              {{ invoiceStatus['count_non_consegnata'] }}
            </div>
          </div>
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <h1 class="stats-subs">Consegnate</h1>
            <div id="attive_no_appuntamento" class="stats-number">
              {{ invoiceStatus['count_consegnata'] }}
            </div>
          </div>
        </div>
        <div class="mt-5">
          <div
            class="flex flex-col lg:flex-row items-center gap-5 justify-center"
          >
            <div class="pt-2 w-full chart-cont-w" style="min-height: 35vh">
              <h3 class="stats-subs py-1">Fatturato</h3>

              <ColumnChart
                v-if="invoiceStats && invoiceStats.length > 0"
                :values="invoiceStats"
                :labels="invoiceLabels"
                :options="chartColOptions"
                class="flex justify-center"
              ></ColumnChart>
            </div>
            <div class="pt-2 w-full chart-cont-w" style="min-height: 35vh">
              <h3 class="stats-subs py-1">IVA</h3>
              <ColumnChart
                v-if="ivaStats && ivaStats.length > 0"
                :values="ivaStats"
                :labels="invoiceLabels"
                :options="chartColOptions"
                class="flex justify-center"
              ></ColumnChart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-30 p-2 col-span-2"
    >
      <h1 class="stats-title">Statistiche Prestazioni</h1>
      <div class="grid grid-cols-2 gap-5">
        <div
          class="pt-2 chart-cont-w col-span-2 lg:col-span-1"
          style="min-height: 35vh"
        >
          <h3 class="stats-subs py-1">Top 5 per numero</h3>
          <PieChart
            :values="prestazioniNumberToShow"
            :labels="prestazioniNumberLabel"
            :options="chartColOptions"
            class="flex justify-center"
          ></PieChart>
        </div>
        <div
          class="pt-2 chart-cont-w col-span-2 lg:col-span-1"
          style="min-height: 35vh"
        >
          <h3 class="stats-subs py-1">Top 5 per fatturato</h3>
          <PieChart
            :values="prestazioniFatturatoToShow"
            :labels="prestazioniFattLabel"
            :options="chartColOptions"
            class="flex justify-center"
          ></PieChart>
        </div>
      </div>

      <div class="w-1/2 mx-auto px-12">
        <label class="form-label required">Seleziona una prestazione: </label>
        <div>
          <div>
            <v-select
              v-model="selectedPrestazione"
              :options="prestazioni"
              label="label"
              placeholder="Cerca..."
              @search="searchPrestazione"
            >
              <template #option="option">
                <span class="text-sm font-medium text-gray-900">
                  {{ option.nomePrestazione }} <br />
                </span>
                <span class="text-xs">
                  N.{{ option.numeroPrestazioni }} TOT.{{
                    option.totPrestazioni
                  }}€ AVG. {{ option.avg }}
                </span>
              </template>
            </v-select>
          </div>
        </div>
      </div>

      <div
        v-if="prestazioniDetails.length > 0 || active_filters != {}"
        class="bg-white p-5 rounded-lg my-5"
      >
        <!-- BEGIN: Data List -->

        <div class="paginator-container">
          <div class="prova mr-5 my-2 flex justify-between flex-1">
            <div class="my-1">
              <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
                >Mostra
              </span>

              <select
                class="form-select w-16 h-6 pr-2 pl-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
                @change="(event) => changeNumPagesPrestazioni(event)"
              >
                <option
                  v-for="show in forPageOptionPrestazioni"
                  :key="show"
                  :value="show"
                >
                  {{ show }}
                </option>
              </select>

              <span class="text-md font-bold ml-2 mt-1"> elementi</span>
            </div>
          </div>

          <Paginator
            :for-page-option="paginationPrestazioni.forPage"
            :total-records="totalRecordsPrestazioni"
            @changePagination="(event) => changePaginationPrestazioni(event)"
          />
        </div>

        <div class="">
          <table class="overflow-hidden sm:rounded-lg w-full">
            <THeader
              v-for="(item, itemKey) in tableDataPrestazioni.config"
              :key="itemKey"
              :name="item.title"
              class="text-white border-b-2 border-theme-41 whitespace-nowrap text-center font-bold bg-theme-40"
            />
            <tbody class="bg-white">
              <tr>
                <td
                  v-for="(elem, index) in tableDataPrestazioni.input"
                  :key="index"
                  class="text-center px-1 py-3"
                >
                  <select
                    v-if="elem.type === 'select'"
                    v-model="table_filter[elem.key]"
                    class="w-full text-gray-500 rounded-md h-7 mt-2"
                  >
                    <option selected></option>
                    <option
                      v-for="(item, i) in elem.option"
                      :key="i"
                      :value="item"
                    >
                      {{ item.name ? item.name : item }}
                    </option>
                  </select>
                  <input
                    v-else
                    v-model="elem.value"
                    :type="elem.type"
                    min="0"
                    :name="elem.title"
                    class="form-control h-7 mt-2"
                    :class="{
                      invisible: elem.type === 'none',
                      'p-0': elem.type === 'date'
                    }"
                    :placeholder="elem.title"
                    @change="filterHandler(elem)"
                  />
                </td>
              </tr>
              <tr
                v-for="(p, k) in prestazioniDetails"
                :key="k"
                class="border-r border-theme-2 h-12 text-center text-base font-normal hover:bg-theme-45"
                :class="{ 'bg-theme-2': k % 2 === 0 }"
              >
                <Cell :elementi="p.alias" class="whitespace-nowrap py-3 px-1" />
                <td class="whitespace-nowrap py-3 px-1">
                  <div class="flex flex-col">
                    <span v-for="t in JSON.parse(p.types)" :key="t"
                      >{{ t }} &nbsp;
                    </span>
                  </div>
                </td>
                <td class="whitespace-nowrap py-3 px-1">
                  <div class="flex flex-col">
                    <span v-for="year in JSON.parse(p.years)" :key="year"
                      >{{ year }} &nbsp;
                    </span>
                  </div>
                </td>
                <td class="whitespace-nowrap py-3 px-1">
                  <div class="flex flex-col">
                    <span
                      v-for="address in JSON.parse(p.addresses)"
                      :key="address"
                      >{{ address }} &nbsp;
                    </span>
                  </div>
                </td>
                <Cell
                  :elementi="p.visit_type ? p.visit_type : 'Non specificato'"
                  class="whitespace-nowrap py-3 px-1"
                />
                <Cell :elementi="p.tot" class="whitespace-nowrap py-3 px-1" />
              </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
      </div>
    </div>
    <div
      class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-30 p-2 col-span-2"
    >
      <h1 class="stats-title">Statistiche clienti</h1>
      <div class="clienti-stat-wrap grid gap-5">
        <div class="pt-2" style="min-height: 35vh">
          <div class="stats-subs">
            <label class="mb-0 mr-1">Clienti Attivi / Non attivi</label>
          </div>
          <div class="col-span-1">
            <PieChart
              :values="clientiAttivi"
              :labels="clientiAttiviLabel"
              :options="chartColOptions"
              class="flex justify-center"
            ></PieChart>
          </div>
        </div>

        <div class="pt-2 w-full" style="min-height: 35vh">
          <div class="stats-subs">
            <label class="mb-0 mr-1">Numero prime visite</label>
          </div>
          <div class="col-span-1">
            <BarChart
              :values="primeVisite"
              :labels="clientiAttiviLabel"
              :options="chartBarOptions"
              class="flex justify-center mt-3"
            ></BarChart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import PieChart from '../../components/pie-chart/Main.vue'
import ColumnChart from '../../components/column-chart/ColumnChart.vue'
import BarChart from '../../components/bar-chart/BarChart.vue'
import moment from 'moment'
import axios from '../../gateway/backend-api'
import Paginator from '../paginator/Main'
import THeader from '../cvit-table/THeader.vue'
import Cell from '../../components/cvit-table/Cell.vue'

const period = [
  { title: 'Ultimo mese', value: 1 },
  { title: 'Ultimo trimestre', value: 3 },
  { title: 'Ultimo anno', value: 12 }
]

const chartColOptions = {
  chart: {
    title: 'Company Performance',
    subtitle: 'Sales, Expenses, and Profit: 2014-2017'
  },
  width: '100%',
  height: 200,
  colors: [
    '#059393',
    '#CA6715',
    '#1B4B77',
    '#F7CD65',
    '#9FD5DA',
    '#EF9C02',
    '#6295D3'
  ]
}

const chartBarOptions = {
  legend: { position: 'bottom', alignment: 'start' },
  width: '100%',
  height: '100%',
  colors: [
    '#059393',
    '#CA6715',
    '#1B4B77',
    '#F7CD65',
    '#9FD5DA',
    '#EF9C02',
    '#6295D3'
  ]
}

const forPageOptionPrestazioni = [10, 20, 50]
export default defineComponent({
  components: {
    PieChart,
    ColumnChart,
    BarChart,
    Paginator,
    THeader,
    Cell
  },
  setup() {},

  data() {
    return {
      chartColOptions,
      chartBarOptions,
      period,
      animalStats: [],
      prestazioni: [],
      prestazioniDetails: [],
      prestazioniNumber: [],
      prestazioniNumberToShow: [],
      prestazioniFatturato: [],
      prestazioniFatturatoToShow: [],
      prestazioniNumberLabel: ['Prestazione', 'Numero'],
      prestazioniFattLabel: ['Prestazione', 'Fatturato'],
      animalLabels: ['Animale', 'Quantità'],
      invoiceLabels: ['Mese', 'Attivo', 'Passivo', 'Profitto'],
      clientiAttiviLabel: ['Attivi', 'Disattivi'],
      clientiAttivi: [
        { type: 'Attivi', qty: 3002 },
        { type: 'Disattivi', qty: 107 }
      ],
      primeVisite: [
        {
          periodo: '2022',
          attivo: 314
        },
        {
          periodo: '2023',
          attivo: 83
        }
      ],
      invoiceStats: [],
      ivaStats: [],
      invoiceStatus: [],
      startDate: null,
      endDate: null,
      selectedPrestazione: null,
      table_filter: [],
      active_filters: {},
      totalRecordsPrestazioni: 0,
      forPageOptionPrestazioni: forPageOptionPrestazioni,
      paginationPrestazioni: {
        page: 1,
        forPage: forPageOptionPrestazioni[0]
      },
      tableDataPrestazioni: {
        config: [
          {
            key: 'alias',
            title: 'Nome'
          },
          {
            key: 'species',
            title: 'Specie'
          },
          {
            key: 'birth_dates',
            title: 'Età paziente'
          },
          {
            key: 'addresses',
            title: 'Stile di vita'
          },
          {
            key: 'visit_type',
            title: 'Tipo di Visita'
          },
          {
            key: 'tot',
            title: 'Tot'
          }
        ],
        input: [
          {
            key: 'alias',
            title: 'Nome',
            type: 'none'
          },
          {
            key: 'species',
            title: 'Specie',
            type: 'select'
          },
          {
            key: 'birth_dates',
            title: 'Età paziente',
            type: 'text'
          },
          {
            key: 'addresses',
            title: 'Stile di vita',
            type: 'text'
          },
          {
            key: 'visit_type',
            title: 'Tipo di Visita',
            type: 'select',
            option: [
              { name: 'Diurno', value: 't1' },
              { name: 'Notturno', value: 't2' },
              { name: 'Festivo', value: 't3' },
              { name: 'Assicurazione', value: 't4' }
            ]
          },
          {
            key: 'tot',
            title: 'Tot',
            type: 'text'
          }
        ]
      }
    }
  },
  watch: {
    selectedPrestazione: function (elem) {
      this.getPrestazioniDetails(elem)
    },
    'table_filter.species': function (elem) {
      this.filterSelect(elem, 'species')
    },
    'table_filter.visit_type': function (elem) {
      this.filterSelect(elem, 'visit_type')
    }
  },
  mounted() {
    let b = new Date()
    let a = new Date(b.getFullYear(), b.getMonth(), 1)

    this.startDate = moment(a).format('YYYY-MM-DD')
    this.endDate = moment(b).format('YYYY-MM-DD')

    this.getInvoiceStats()
    this.getPrestazioniStats()
    this.chartSize()

    //CALL SELECT VALUE GETTER
    axios
      .get('/' + localStorage.getItem('clinic') + '/pet/types_breeds')
      .then((res) => {
        this.tableDataPrestazioni.input[1].option = res.data
      })
  },
  methods: {
    getAnimalStats() {
      axios
        .get('/animal_stats/') // scarico le statistiche relative agli animali
        .then((resp) => {
          this.animalStats = resp.data
        })
    },
    getInvoiceStats() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')

      axios
        .post('/' + localStorage.getItem('clinic') + '/report/billing', {
          startDate: this.$FormatEpochEnglish(this.startDate),
          endDate: this.$FormatEpochEnglish(this.endDate),
          timezone: 'GMT' + moment().format('Z')
        })
        .then((resp) => {
          this.invoiceStats = []
          this.ivaStats = []

          resp.data.forEach((el) => {
            this.invoiceStats.push({
              periodo: el.month_name,
              attivo: parseFloat(el.imponibile_active),
              passivo: parseFloat(el.imponibile_passive),
              profitto: parseFloat(el.profitto_imponibile)
            })

            this.ivaStats.push({
              periodo: el.month_name,
              attivo: parseFloat(el.imposta_active),
              passivo: parseFloat(el.imposta_passive),
              profitto: parseFloat(el.profitto_imposta)
            })
          })

          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })

      axios
        .post('/' + localStorage.getItem('clinic') + '/report/active-billing', {
          startDate: this.$FormatEpochEnglish(this.startDate),
          endDate: this.$FormatEpochEnglish(this.endDate),
          timezone: 'GMT' + moment().format('Z')
        })
        .then((resp) => {
          this.invoiceStatus = resp.data
        })
    },
    getPrestazioniStats() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')

      axios
        .post('/' + localStorage.getItem('clinic') + '/report/prestazioni', {
          startDate: this.$FormatEpochEnglish(this.startDate),
          endDate: this.$FormatEpochEnglish(this.endDate),
          timezone: 'GMT' + moment().format('Z')
        })
        .then((resp) => {
          this.prestazioni = []
          this.prestazioniNumber = []
          this.prestazioniFatturato = []

          Object.entries(resp.data).forEach(([k, el]) => {
            if (k) {
              this.prestazioni.push({
                label:
                  el.alias +
                  ' | n.' +
                  el.count +
                  ' -  Tot ' +
                  el.tot +
                  '€ - Media ' +
                  el.avg +
                  '€',
                nomePrestazione: el.alias,
                numeroPrestazioni: el.count,
                totPrestazioni: el.tot,
                avg: el.avg
              })

              this.prestazioniNumber.push({
                nomePrestazione: el.alias,
                numeroPrestazioni: parseFloat(el.count)
              })

              this.prestazioniFatturato.push({
                nomePrestazione: el.alias,
                numeroPrestazioni: parseFloat(el.tot)
              })
            }
          })

          this.prestazioniFatturato.sort((a, b) =>
            a.numeroPrestazioni < b.numeroPrestazioni
              ? 1
              : b.numeroPrestazioni < a.numeroPrestazioni
              ? -1
              : 0
          )
          this.prestazioniNumberToShow = this.prestazioniNumber.slice(0, 4)
          this.prestazioniFatturatoToShow = this.prestazioniFatturato.slice(
            0,
            4
          )
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    getPrestazioniDetails(prestazione) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')

      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/report/prestazioni-details',
          {
            startDate: this.$FormatEpochEnglish(this.startDate),
            endDate: this.$FormatEpochEnglish(this.endDate),
            timezone: 'GMT' + moment().format('Z'),
            active_filters: this.active_filters,
            alias: prestazione.nomePrestazione,
            per_page: this.paginationPrestazioni.forPage,
            page: this.paginationPrestazioni.page
          }
        )
        .then((resp) => {
          this.prestazioniDetails = resp.data.data
          this.totalRecordsPrestazioni = resp.data.total
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    changeStats: function () {
      this.getInvoiceStats()
      this.getPrestazioniStats()
    },
    // ----- METODI GESTIONE FILTRI ----- //
    // Metodo generico per handler filtro select
    filterSelect(elem, field) {
      if (elem) {
        this.active_filters[field] = elem.id ? elem.id : elem.value
      } else this.active_filters[field] = undefined

      this.checkIfFilterActive()

      if (this.filterActive)
        this.getPrestazioniDetails(this.selectedPrestazione)
    },
    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()

      if (this.filterActive)
        this.getPrestazioniDetails(this.selectedPrestazione)
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    },
    // ----- FINE METODI GESTIONE FILTRI ----- //
    changePaginationPrestazioni: function (value) {
      if (this.paginationPrestazioni.page != value) {
        this.paginationPrestazioni.page = value.page
        this.paginationPrestazioni.forPage = value.forPage
        this.getPrestazioniDetails(this.selectedPrestazione)
      }
    },
    changeNumPagesPrestazioni: function (elem) {
      if (this.paginationPrestazioni.forPage != elem.target.value) {
        this.paginationPrestazioni.forPage = elem.target.value
        this.getPrestazioniDetails(this.selectedPrestazione)
      }
    },

    animateValue(obj, start, end, duration) {
      let startTimestamp = null
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp
        const progress = Math.min((timestamp - startTimestamp) / duration, 1)
        obj.innerHTML = Math.floor(progress * (end - start) + start)
        if (progress < 1) {
          window.requestAnimationFrame(step)
        }
      }
      window.requestAnimationFrame(step)
    },
    chartSize() {
      let cont_w = document.querySelectorAll('.chart-cont-w')
      cont_w.forEach((container) => {
        this.chartColOptions.width =
          container.clientWidth - (container.clientWidth * 10) / 100
        this.chartColOptions.height =
          container.clientHeight - (container.clientHeight * 5) / 100
      })
    }
  }
})
</script>
<style>
.clienti-stat-wrap {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
@media (max-width: 768px) {
  .clienti-stat-wrap {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}
</style>
