<template>
  <GChart type="PieChart" :options="chartOptions" :data="chartData" />
</template>

<script>
import { defineComponent, computed } from 'vue'
// import { useStore } from '@/store'

import { GChart } from 'vue-google-charts'

export default defineComponent({
  components: {
    GChart
  },
  props: {
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    values: {
      type: Array,
      default() {
        return []
      }
    },
    labels: {
      type: Array,
      default() {
        return ['#1', '#2', '#3', '#4']
      }
    },
    colors: {
      type: Array,
      default() {
        return ['#e0440e', '#e6693e', '#ec8f6e', '#f3b49f', '#f6c7b6']
      }
    }
  },

  setup(props) {
    const chartData = computed(() => {
      let value = []
      if (props.values) {
        let tempLabel = []
        for (let count = 0; count < props.labels.length; count++) {
          tempLabel.push(props.labels[count])
        }
        value.push(tempLabel)
        for (let i = 0; i < props.values.length; i++) {
          let tempData = []
          for (let y = 0; y < Object.keys(props.values[i]).length; y++) {
            tempData.push(props.values[i][Object.keys(props.values[i])[y]])
          }
          value.push(tempData)
        }
      }

      return value
    })

    const chartOptions = computed(() => {
      return props.options
    })

    return {
      chartData,
      chartOptions
    }
  },
  data() {
    return {}
  }
})
</script>
