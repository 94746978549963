const state = {
  customersDetails: [], // array customer in anagrafica clienti
  comparisonCustomer: [], // array per controllare se un customer è già presenta in anagrafica clienti qua vengono pushati solo gli id dei customer
  petsDetails: [], // array pet in anagrafica clienti
  comparisonPet: [], // array per controllare se un pet è già presenta in anagrafica clienti qua vengono pushati solo gli id dei pet
  tab: {
    listTab: 0, // valorizzato a 0 perchè rende la tab della lista pazienti visibile
    // quando cambiano i valori di customertab o pet tab vengono visualizzata la tab con valore a 0
    customerTab: -1,
    petTab: -1
  },
  show: {
    isActive: 0, // valorizzato a 0 perchè rende la window della lista pazienti visibile
    // quando cambiano i valori di customerWindow o pet Window vengono visualizzata la Window con valore a 0
    isActiveCustomer: -1,
    isActivePet: -1
  }
}
const getters = {
  // questi getters torano in pagina i vari data (array, tab, show)
  getCustomersDetails: (state) => state.customersDetails,
  getComparisonCustomer: (state) => state.comparisonCustomer,
  getPetsDetails: (state) => state.petsDetails,
  getComparisonPets: (state) => state.comparisonPet,
  getTab: (state) => state.tab,
  getShow: (state) => state.show
}
const mutations = {
  // mutation che al click della tab lista riporta i valori di tab list e show list a 0
  getList: (state) => {
    state.tab.listTab = 0
    state.tab.petTab = -1
    state.tab.customerTab = -1
    state.show.isActive = 0
    state.show.isActivePet = -1
    state.show.isActiveCustomer = -1
  },
  // mutation che al click della tab customer riporta i valori di tab customer e show customer a 0
  getCustomer: (state, customer) => {
    state.tab.listTab = -1
    state.tab.petTab = -1
    state.tab.customerTab = customer.id
    state.show.isActive = -1
    state.show.isActivePet = -1
    state.show.isActiveCustomer = customer.id
    state.comparisonCustomer.push(customer.id)
    state.customersDetails.push(customer)
  },
  openCustomerTab: (state, id) => {
    state.tab.listTab = -1
    state.tab.petTab = -1
    state.tab.customerTab = id
    state.show.isActiveCustomer = id
    state.show.isActive = -1
    state.show.isActivePet = -1
  },
  // mutation che al click della tab customer riporta i valori della tab customer e show customer a 0 selezionati
  getSingleCustomer: (state, customer) => {
    state.tab.listTab = -1
    state.tab.petTab = -1
    state.tab.customerTab = customer
    state.show.isActive = -1
    state.show.isActivePet = -1
    state.show.isActiveCustomer = customer
  },
  // mutation che al click della tab customer riporta i valori di tab customer e show customer a 0
  getPet: (state, pet) => {
    state.tab.listTab = -1
    state.tab.petTab = pet.id
    state.tab.customerTab = -1
    state.show.isActivePet = pet.id
    state.show.isActive = -1
    state.show.isActiveCustomer = -1
    state.comparisonPet.push(pet.id)
    state.petsDetails.push(pet)
  },
  // mutation che al click della tab pet riporta i valori della tab pet e show pet a 0 selezionati
  getSinglePet: (state, pet) => {
    state.tab.listTab = -1
    state.tab.petTab = pet
    state.tab.customerTab = -1
    state.show.isActivePet = pet
    state.show.isActive = -1
    state.show.isActiveCustomer = -1
  },
  // mutation che al click della tabcustomer rimuove la tabcustomer selezionata e riporta il focus sulla tab list clienti
  removeTabCustomer: (state, index) => {
    state.customersDetails.splice(index, 1)
    state.comparisonCustomer.splice(index, 1)
    // state.tab.listTab = 0
    // state.show.isActive = 0
    state.tab.listTab = 0
    state.tab.petTab = -1
    state.tab.customerTab = -1
    state.show.isActive = 0
    state.show.isActivePet = -1
    state.show.isActiveCustomer = -1
  },
  // mutation che al click della tabpet rimuove la tabpet selezionata e riporta il focus sulla tab list clienti
  removeTabPet: (state, index) => {
    state.petsDetails.splice(index, 1)
    state.comparisonPet.splice(index, 1)
    state.tab.listTab = 0
    state.show.isActive = 0
  },
  updateCustomer: (state, payload) => {
    state.tab.listTab = -1
    state.tab.petTab = -1
    state.tab.customerTab = payload.customer.id
    state.show.isActive = -1
    state.show.isActivePet = -1
    state.show.isActiveCustomer = payload.customer.id
    state.customersDetails[payload.index] = payload.customer
  },
  clearCustomers: (state) => {
    state.customersDetails = []
    state.comparisonCustomer = []
    state.petsDetails = []
    state.comparisonPet = []
    state.tab = {
      listTab: 0,
      customerTab: -1,
      petTab: -1
    }
    state.show = {
      isActive: 0,
      isActiveCustomer: -1,
      isActivePet: -1
    }
  }
}
const actions = {
  // con le action richiamo tutte le mutations
  getListAction: (context) => {
    context.commit('getList')
  },
  getCustomerAction: (context, customer) => {
    context.commit('getCustomer', customer)
  },
  getSingleCustomerAction: (context, customer) => {
    context.commit('getSingleCustomer', customer)
  },
  getPetAction: (context, pet) => {
    context.commit('getPet', pet)
  },
  getSinglePetAction: (context, pet) => {
    context.commit('getSinglePet', pet)
  },
  removeTabCustomerAction: (context, index) => {
    context.commit('removeTabCustomer', index)
  },
  removeTabPetAction: (context, index) => {
    context.commit('removeTabPet', index)
  },
  goToOpenedClient: (context, id) => {
    context.commit('openCustomerTab', id)
  },
  updateClientData: (context, customer) => {
    let index = context.state.comparisonCustomer.indexOf(customer.id)
    context.commit('updateCustomer', { customer: customer, index: index })
  },
  clearCustomersAction: (context) => {
    context.commit('clearCustomers')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
