<template>
  <div
    id="new-appointment-modal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity scrollbar-none"
  >
    <div
      class="relative top-12 px-6 mx-auto overflow-scroll scrollbar-none w-3/5 shadow-lg rounded-md bg-theme-11"
    >
      <button
        type="button"
        class="absolute top-0 right-0 my-3 mx-6 text-gray-400 bg-transparent p-1 hover:bg-white hover:text-theme-11 rounded-lg text-sm ml-auto inline-flex items-center dark:hover:bg-white dark:hover:text-white"
        @click="closeWarning"
      >
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>
      <!-- Modal body -->

      <!--content-->

      <div
        class="justify-center rounded-lg py-5 text-lg text-white w-full flex items-center"
        role="alert"
      >
        <div class="">
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="exclamation-triangle"
            class="w-6 h-6 mr-4 fill-current"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
          >
            <path
              fill="currentColor"
              d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
            ></path>
          </svg>
        </div>

        <div class="mt-1">
          <p>Ci sono campi da controllare!</p>
        </div>
      </div>

      <!--  end footer -->

      <div class="mx-auto"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  methods: {
    closeWarning() {
      this.$emit('closeWarning')
    }
  }
})
</script>
