<template>
  <FatturatiTable />
</template>
<script>
import { defineComponent, ref } from 'vue'
import moment from 'moment'
import axios from '../../gateway/backend-api'
import FatturatiTable from '../../components/tables/FatturatiTable.vue'

const period = [
  { title: 'Ultimo mese', value: 1 },
  { title: 'Ultimo trimestre', value: 3 },
  { title: 'Ultimo anno', value: 12 }
]

const chartColOptions = {
  chart: {
    title: 'Company Performance',
    subtitle: 'Sales, Expenses, and Profit: 2014-2017'
  },
  width: 600,
  height: 400,
  colors: [
    '#059393',
    '#CA6715',
    '#1B4B77',
    '#F7CD65',
    '#9FD5DA',
    '#EF9C02',
    '#6295D3'
  ]
}

const chartBarOptions = {
  width: 400,
  height: 240,
  title: 'My amazing company performance',
  colors: [
    '#059393',
    '#CA6715',
    '#1B4B77',
    '#F7CD65',
    '#9FD5DA',
    '#EF9C02',
    '#6295D3'
  ]
}

export default defineComponent({
  components: { FatturatiTable },
  setup() {
    const forPageOption = [10, 20, 50]
    const tableConfig_doc = ref([
      {
        key: 'staff_user',
        title: 'Utente',
        type: 'string'
      },

      {
        key: 'tot_prest',
        title: 'Totale prestazioni',
        type: 'number'
      },
      {
        key: 'tot_n',
        title: 'Totale netto',
        type: 'number'
      },
      {
        key: 'tot_i',
        title: 'Totale ivato',
        type: 'number'
      },
      {
        key: 'percentage',
        title: 'Percentuale',
        type: 'number'
      },
      {
        key: 'tot_i_p',
        title: 'Totale ivato in percentuale',
        type: 'number'
      },

      {
        key: 'actions',
        title: 'Azioni rapide',
        type: 'none'
      }
    ])

    const pagination = ref({
      page: 1,
      forPage: forPageOption[0]
    })
    const totalRecords = ref(0)
    const docs = ref([])
    return { tableConfig_doc, pagination, totalRecords, docs }
  },

  data() {
    return {
      chartColOptions,
      chartBarOptions,
      period,
      customerStats: {},
      startDate: null,
      endDate: null
    }
  },
  mounted() {
    this.endDate = moment().unix()
    let start = new Date()
    start.setMonth(start.getMonth() - this.period[0].value)
    this.startDate = this.$FormatEpoch(start)
    this.getStatsCustomer()
  },
  methods: {
    getStatsCustomer() {
      axios
        .post('/' + localStorage.getItem('clinic') + '/report/customer', {
          startDate: this.startDate,
          endDate: this.endDate,
          timezone: 'GMT' + moment().format('Z')
        })
        .then((resp) => {
          this.customerStats = resp.data
        })
    },
    changeStats: function (elem) {
      if (this.period.value != elem.target.value) {
        this.period.value = elem.target.value
      }
      let start = new Date()
      start.setMonth(start.getMonth() - this.period.value)
      this.startDate = this.$FormatEpoch(start)
      this.getStatsCustomer()
    },

    animateValue(obj, start, end, duration) {
      let startTimestamp = null
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp
        const progress = Math.min((timestamp - startTimestamp) / duration, 1)
        obj.innerHTML = Math.floor(progress * (end - start) + start)
        if (progress < 1) {
          window.requestAnimationFrame(step)
        }
      }
      window.requestAnimationFrame(step)
    }
  }
})
</script>
