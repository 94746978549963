<template>
  <div class="paginator-container">
    <div class="prova mr-5 my-2 flex justify-between">
      <div class="mt-1">
        <span class="flex-row text-md ml-2 font-bold mr-2 mt-1">Mostra </span>

        <select
          class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
          @change="(event) => changeNumPages(event)"
        >
          <option v-for="show in forPageOption" :key="show" :value="show">
            {{ show }}
          </option>
        </select>
        <span class="text-md font-bold ml-2 mt-1"> elementi</span>
      </div>
    </div>
    <Paginator
      v-if="total"
      :for-page-option="pagination.forPage"
      :total-records="total"
      @changePagination="(event) => changePage(event)"
    />
  </div>

  <!-- BEGIN: Data List -->
  <div class="table-container overflow-scroll scrollbar-none pb-3">
    <table v-if="tableConfigClinicDoc && docs" class="w-full">
      <THeader
        v-for="(item, itemKey) in table_config"
        :key="itemKey"
        :name="item.title"
        class="t-header h-10"
      />
      <th class="t-header h-10"></th>
      <tbody>
        <tr>
          <td
            v-for="(elem, index) in table_config"
            :key="index"
            class="text-center px-1 py-3"
          >
            <select
              v-if="elem.type === 'select'"
              v-model="elem.value"
              class="w-full border rounded-md h-7 text-gray-500"
              @change="filterHandler(elem)"
            >
              <option selected></option>
              <option v-for="(item, i) in elem.option" :key="i" :value="item">
                {{ item.name ? item.name : item }}
              </option>
            </select>
            <input
              v-else
              v-model="elem.value"
              :type="elem.type"
              min="0"
              :name="elem.title"
              class="form-control h-7"
              :class="{
                invisible: elem.type === 'none',
                'p-0': elem.type === 'date'
              }"
              :placeholder="elem.title"
              @change="filterHandler(elem)"
            />
          </td>
        </tr>
        <tr
          v-for="(doc, docKey) in docs"
          :key="docKey"
          class="h-12 text-xs font-normal hover:bg-theme-45"
          :class="{ 'bg-theme-2': docKey % 2 === 0 }"
        >
          <td
            v-if="!movimentazione"
            class="text-center border-r border-theme-45"
          >
            <TableShotColor :stato="doc.product_status" />
          </td>
          <td
            v-if="!movimentazione"
            class="text-center text-sm border-r-2 border-theme-46"
          >
            {{ $FormatDate(doc.expiration_date) }}
          </td>
          <td
            v-if="movimentazione"
            class="text-center text-sm border-r-2 border-theme-46"
          >
            {{ $FormatDate(doc.date) }}
          </td>
          <td
            v-if="movimentazione"
            class="text-center text-sm border-r-2 border-theme-46"
          >
            {{ doc.category }}
          </td>

          <td class="text-center text-sm border-r-2 border-theme-46">
            <b> {{ doc.alias }} </b><br />
            <i class="text-xs">{{ doc.confezione }}</i>
          </td>
          <td class="text-center text-sm border-r-2 border-theme-46">
            {{ doc.lotto }}
          </td>
          <td
            v-if="scadenza"
            class="text-center text-sm border-r-2 border-theme-46"
          >
            {{ doc.days_expiration + ' gg' }}
          </td>

          <td
            v-if="!movimentazione"
            class="text-center text-sm border-r-2 border-theme-46"
          >
            {{ doc.total_quantity }}
          </td>
          <td
            v-if="!movimentazione"
            class="text-center text-sm border-r-2 border-theme-46"
          >
            {{ doc.purchase_price + ' €' }}
          </td>

          <td
            v-if="movimentazione"
            class="text-center text-sm border-r-2 border-theme-46"
          >
            {{ doc.load_unload_quantity }}
          </td>
          <td
            v-if="movimentazione"
            class="text-center text-sm border-r-2 border-theme-46"
          >
            {{ doc.purchase_price + ' €' }}
          </td>
          <td
            v-if="movimentazione"
            class="text-center text-sm border-r-2 border-theme-46"
          >
            {{ doc.staff_user }}
          </td>

          <td v-if="!movimentazione && !soglia && !scadenza" class="p-2">
            <div class="flex gap-2 justify-center items-center text-sm">
              <a
                class="w-5 h-5 flex items-center justify-center hover:text-theme-41"
                @click="$emit('editProdotto', doc)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  class="fill-current"
                  style="width: 0.85rem; height: 0.85rem"
                >
                  <path
                    d="M362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32zM421.7 220.3L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3z"
                  />
                </svg>
              </a>
              <a
                class="hover:text-theme-41 w-5 h-5 flex items-center justify-center"
                @click="$emit('deleteProdotto', doc)"
              >
                <i class="fas fa-times" />
              </a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div v-if="false" class="w-full flex justify-center mt-2">
      Nessun Risultato
    </div>
  </div>
  <!-- END: Data List -->
</template>

<script>
import { defineComponent, computed, toRef, ref, toRaw } from 'vue'
import Paginator from '../../components/paginator/Main'
import THeader from '../../components/cvit-table/THeader.vue'
import TableShotColor from '../../components/details-customer/TableShotColor.vue'

export default defineComponent({
  components: {
    Paginator,
    THeader,
    TableShotColor
  },
  props: [
    'tableConfigClinicDoc',
    'pagination',
    'forPageOption',
    'selectedPatient',
    'docs',
    'total',
    'movimentazione',
    'scadenza',
    'soglia'
  ],
  emits: ['changePagination', 'editProdotto', 'deleteProdotto', 'updateFiltri'],
  setup(props, { emit }) {
    const pagination = toRef(props, 'pagination')

    // variabili paginazione
    const active_filters = ref({})
    const filterActive = ref(false)

    //  cambio elementi per pagina, e ritorno nella prima pagina
    // const changePagination = (value) => {
    //   if (pagination.value.page != value.page) {
    //     pagination.value.page = value.page
    //     pagination.value.forPage = value.forPage
    //     emit(
    //       'refreshAnnotazioni',
    //       pagination.value,
    //       toRaw(active_filters.value)
    //     )
    //   }
    // }

    // metodo che cambia il numero della pagina
    const changeNumPages = (elem) => {
      if (pagination.value.forPage != elem.target.value) {
        pagination.value.page = 1
        pagination.value.forPage = elem.target.value
      }

      emit('changePagination', pagination)
    }

    const changePage = (elem) => {
      console.log(elem)
      if (pagination.value.page != elem.page) {
        pagination.value.page = elem.page
      }

      emit('changePagination', pagination)
    }

    const deleteNote = (note_id) => {
      emit('deleteNote', 'annotations', note_id)
    }

    // ----- COMPUTED ----- //
    const table_config = computed(function () {
      // configurazione tabella, filtrando i dati del paziente nel caso in cui siamo nella sezione anagrafica paziente
      return props.tableConfigClinicDoc.filter(
        (elem) => !(elem.key === 'patient' && props.selectedPatient)
      )
    })

    return {
      table_config,
      //changePagination,
      changeNumPages,
      deleteNote,
      changePage,
      active_filters,
      filterActive
    }
  },
  methods: {
    // ----- METODI GESTIONE FILTRI ----- //
    filterHandler(elem) {
      if (elem.type === 'date') {
        this.active_filters[elem.key] = this.$FormatEpochEnglish(elem.value)
      } else {
        if (elem.type === 'select') {
          this.active_filters[elem.key] = elem.value.value
        } else {
          this.active_filters[elem.key] = elem.value
        }
      }

      this.checkIfFilterActive()

      if (this.filterActive)
        this.$emit('updateFiltri', toRaw(this.active_filters))
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    }
    // ----- FINE METODI GESTIONE FILTRI ----- //
  }
})
</script>
