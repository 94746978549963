<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-10 p-2 mx-auto overflow-scroll scrollbar-none border sm:w-3/5 w-full shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->

      <button type="button" class="closing-btn mr-2" @click="$emit('close')">
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          XMLns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>

      <div class="flex flex-col content-end overflow-y-auto mt-10">
        <form @submit.prevent="handleExport">
          <!-- Modal header -->
          <div class="flex modal-title justify-center">
            <h3 class="">Esporta documenti fiscali</h3>
          </div>
          <!-- Fine Modal header -->

          <!-- Modal body -->
          <div class="text-center py-5 px-6 w-full flex flex-col gap-4">
            <select v-model="selected" class="custom-input w-full">
              <option value="" disabled selected>Selezionare un formato</option>
              <option value="prestazioni">Prestazioni</option>
              <option value="pacchetti">Pacchetti</option>
            </select>
            <select v-model="tariffarioSelected" class="custom-input w-full">
              <option value="" disabled selected>
                Selezionare un tariffario
              </option>

              <option v-for="t in tariffari" :key="t.id" :value="t.id">
                {{ t.name }}
              </option>
            </select>
          </div>

          <!-- START opt Scarica le fatture in formato Excel -->

          <!-- END opt Scarica fatture standard in formato XML -->

          <!-- Fine Modal body -->

          <!-- Modal footer -->
          <div
            class="border-t-1 border-gray-400 w-full px-6 text-right flex justify-end items-end mb-2"
          >
            <div class="flex justify-end items-center mt-5">
              <button
                id="exportSubmitButton"
                type="submit"
                class="orange-btn flex items-center gap-3"
              >
                <svg
                  class="fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 576 512"
                >
                  <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <path
                    d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V288H216c-13.3 0-24 10.7-24 24s10.7 24 24 24H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM384 336V288H494.1l-39-39c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39H384zm0-208H256V0L384 128z"
                  />
                </svg>
                Esporta
              </button>

              <button
                class="ml-3 gray-btn"
                type="button"
                @click="$emit('close')"
              >
                <span class="h-5"></span>
                Annulla
              </button>
            </div>
          </div>
          <!-- Fine Modal footer -->
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from '../../gateway/backend-api'
import { useStore } from '@/store'

const store = useStore()

export default defineComponent({
  components: {},
  emits: ['close'],
  props: ['tariffari'],
  data() {
    return {
      selected: null,
      tariffarioSelected: null
    }
  },
  methods: {
    handleExport() {
      if (!this.selected) {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di selezionare la tipologia'
        })
      }
      if (!this.tariffarioSelected) {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di selezionare il tariffario'
        })
      }

      if (this.selected) {
        let filename = null
        let url_endpoint = null
        if (this.selected == 'prestazioni') {
          url_endpoint = 'export-nomenclatore'
          filename = 'Nomenclatore'
        } else if (this.selected == 'pacchetti') {
          url_endpoint = 'export-pacchetti'
          filename = 'Pacchetti'
        }

        filename +=
          '-' +
          (
            new Date().toISOString().slice(0, 10) +
            '-' +
            new Date().toISOString().slice(11, 19)
          ).replaceAll(':', '-')

        this.$store.dispatch('commonComponents/showLoadingModalAction')
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/tariffario/' +
              url_endpoint,
            {
              tariffario_clinic_id: this.tariffarioSelected
            },
            { responseType: 'blob' }
          )
          .then((res) => {
            var binaryData = []
            binaryData.push(res.data)
            let fileUrl = window.URL.createObjectURL(
              new Blob(binaryData, { type: 'application/vnd.ms-excel' })
            )

            let fileLink = document.createElement('a')
            fileLink.href = fileUrl
            fileLink.setAttribute('download', filename + '.xls')
            document.body.appendChild(fileLink)
            fileLink.click()
            store.dispatch('commonComponents/hideLoadingModalAction')
            this.endpoint = null
          })
          .catch(async function (error) {
            if (typeof error.response === 'undefined') {
              store.dispatch('commonComponents/hideLoadingModalAction')
              store.dispatch('commonComponents/openNotificationModal', {
                message: "Errore, contattare l'assistenza"
              })
            } else {
              let errorString = error.response.data
              if (
                error.request.responseType === 'blob' &&
                error.response.data instanceof Blob &&
                error.response.data.type &&
                error.response.data.type.toLowerCase().indexOf('json') != -1
              ) {
                errorString = JSON.parse(await error.response.data.text())
              }

              store.dispatch('commonComponents/openNotificationModal', {
                message: errorString.error
              })
              store.dispatch('commonComponents/hideLoadingModalAction')
              this.endpoint = null
            }
          })
      }
    }
  }
})
</script>
