<template>
  <div class="p-4 rounded-lg content-container bg-white">
    <div class="flex justify-end py-3">
      <a
        class="orange-btn flex gap-3 items-center"
        target="_blank"
        href="https://us02web.zoom.us/j/86014511875?pwd=K29mMS9OenZtQjM4blozMW52b3h2QT09"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-4 h-4 fill-current"
          viewBox="0 0 512 512"
        >
          <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M352 256c0 22.2-1.2 43.6-3.3 64H163.3c-2.2-20.4-3.3-41.8-3.3-64s1.2-43.6 3.3-64H348.7c2.2 20.4 3.3 41.8 3.3 64zm28.8-64H503.9c5.3 20.5 8.1 41.9 8.1 64s-2.8 43.5-8.1 64H380.8c2.1-20.6 3.2-42 3.2-64s-1.1-43.4-3.2-64zm112.6-32H376.7c-10-63.9-29.8-117.4-55.3-151.6c78.3 20.7 142 77.5 171.9 151.6zm-149.1 0H167.7c6.1-36.4 15.5-68.6 27-94.7c10.5-23.6 22.2-40.7 33.5-51.5C239.4 3.2 248.7 0 256 0s16.6 3.2 27.8 13.8c11.3 10.8 23 27.9 33.5 51.5c11.6 26 20.9 58.2 27 94.7zm-209 0H18.6C48.6 85.9 112.2 29.1 190.6 8.4C165.1 42.6 145.3 96.1 135.3 160zM8.1 192H131.2c-2.1 20.6-3.2 42-3.2 64s1.1 43.4 3.2 64H8.1C2.8 299.5 0 278.1 0 256s2.8-43.5 8.1-64zM194.7 446.6c-11.6-26-20.9-58.2-27-94.6H344.3c-6.1 36.4-15.5 68.6-27 94.6c-10.5 23.6-22.2 40.7-33.5 51.5C272.6 508.8 263.3 512 256 512s-16.6-3.2-27.8-13.8c-11.3-10.8-23-27.9-33.5-51.5zM135.3 352c10 63.9 29.8 117.4 55.3 151.6C112.2 482.9 48.6 426.1 18.6 352H135.3zm358.1 0c-30 74.1-93.6 130.9-171.9 151.6c25.5-34.2 45.2-87.7 55.3-151.6H493.4z"
          />
        </svg>
        Link per telemedicina
      </a>
    </div>

    <h2 class="tariff-subs w-full mb-3">
      SELEZIONA PERIODO PER OFFRIRE SERVIZI DI TELEMEDICINA
    </h2>

    <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-2 px-6">
      <div class="col-span-1 py-2">
        <label for="new-appointment-data-inizio" class="form-label"
          >Data di inizio</label
        >

        <input
          id="new-appointment-data-inizio"
          v-model="dataInizio"
          type="date"
          class="date custom-input"
          placeholder="Seleziona data dell'appuntamento"
        />
      </div>

      <div class="col-span-1 py-2">
        <label for="new-appointment-data-fine" class="form-label"
          >Data di fine</label
        >
        <input
          id="new-appointment-data-fine"
          v-model="dataFine"
          ref="input5"
          type="date"
          class="date custom-input"
          placeholder="Seleziona data dell'appuntamento"
        />
      </div>

      <div class="col-span-1 py-2">
        <label for="new-appointment-ora-inizio" class="form-label"
          >Ora di inizio</label
        >

        <input
          id="new-appointment-ora-fine"
          v-model="oraInizio"
          type="time"
          class="hour custom-input"
          placeholder="Seleziona Ora di fine"
        />
      </div>
      <div class="col-span-1 py-2">
        <label for="new-appointment-ora-fine" class="form-label"
          >Ora di fine</label
        >
        <input
          id="new-appointment-ora-fine"
          v-model="oraFine"
          type="time"
          class="hour custom-input"
          placeholder="Seleziona Ora di fine"
        />
      </div>
    </div>
    <div class="my-2 flex justify-end items-end px-6">
      <button
        id="new-appointment-aggiungi-button"
        ref="input11"
        type="button"
        class="orange-btn"
        @click="salva()"
      >
        <PlusCircleIcon class="h-5 w-5 mr-2" />Salva
      </button>
    </div>

    <h2 v-if="docs.length > 0" class="tariff-subs col-span-4 mb-3">
      PERIODI SELEZIONATI
    </h2>
    <div class="px-6">
      <div v-for="d in docs" :key="d" class="">
        <div class="my-3 card py-2 px-4 grid grid-cols-4 gap-5">
          <div class="flex gap-3 items-center">
            <div class="bg-theme-41 w-2 h-2 rounded-full"></div>
            <label
              for="new-appointment-data-inizio"
              class="form-label mb-0 -ml-2"
              >Data di inizio:</label
            >
            <span>{{ d.dataInizio }}</span>
          </div>
          <div class="flex gap-3 items-center">
            <div class="bg-theme-41 w-2 h-2 rounded-full"></div>
            <label
              for="new-appointment-data-inizio"
              class="form-label mb-0 -ml-2"
              >Data di fine:</label
            >

            <span>{{ d.dataFine }}</span>
          </div>
          <div class="flex gap-3 items-center">
            <div class="bg-theme-41 w-2 h-2 rounded-full"></div>
            <label
              for="new-appointment-data-inizio"
              class="form-label mb-0 -ml-2"
              >Orario di inizio:</label
            >

            <span>{{ d.oraInizio }}</span>
          </div>
          <div class="flex gap-3 items-center">
            <div class="bg-theme-41 w-2 h-2 rounded-full"></div>
            <label
              for="new-appointment-data-inizio"
              class="form-label mb-0 -ml-2"
            >
              Orario di fine:</label
            >
            <span>{{ d.oraFine }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  setup() {
    const dataInizio = ref(null)
    const dataFine = ref(null)
    const oraInizio = ref(null)
    const oraFine = ref(null)

    const docs = ref([])

    const salva = () => {
      docs.value.push({
        dataInizio: dataInizio.value,
        dataFine: dataFine.value,
        oraInizio: oraInizio.value,
        oraFine: oraFine.value
      })

      dataInizio.value = null
      dataFine.value = null
      oraInizio.value = null
      oraFine.value = null
    }

    return {
      dataInizio,
      dataFine,
      oraInizio,
      oraFine,
      docs,
      salva
    }
  }
})
</script>
