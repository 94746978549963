<template>
  <!-- BEGIN: Attachments list -->
  <div class="flex justify-end p-2">
    <button
      type="file"
      accept="image/*,.pdf"
      class="orange-btn-inverted"
      @click="$refs['uploadInput'].click()"
    >
      <PaperclipIcon class="h-5 w-5" /> Aggiungi allegato
    </button>
    <input
      ref="uploadInput"
      type="file"
      accept="image/*,.pdf"
      class="form-control p-2 hidden"
      required
      @change="(e) => handleFileChangeImage(e)"
    />
  </div>
  <div>
    <table v-if="files && files.length > 0" class="w-full">
      <thead class="h-8 t-header">
        <tr>
          <th v-for="(item, itemKey) in allegatiConfig" :key="itemKey">
            {{ item }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(doc, docKey) in files"
          :key="doc.id"
          class="h-12 text-xs font-normal hover:bg-theme-45 cursor-pointer border-b"
          :class="{ 'bg-theme-2': docKey % 2 === 0 }"
        >
          <td class="text-center border-r whitespace-nowrap py-3 px-1">
            {{ doc.name }}
          </td>
          <td class="text-center border-r whitespace-nowrap py-3 px-1">
            {{
              $FormatDate(parseInt(doc.lastModified.toString().slice(0, -3)))
            }}
          </td>
          <td class="text-center">
            <button
              type="button"
              class="text-theme-41 hover:zoom-big"
              @click="removeFile(index, doc)"
            >
              <svg
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div v-if="files && files.length > 0" class="flex justify-end p-2">
    <button type="file" class="orange-btn" @click="salva">
      <i class="fas fa-save mr-1"></i> Salva
    </button>
  </div>
  <!-- END: Attachments list -->
</template>

<script>
import { defineComponent, ref } from 'vue'
import axios from '../../gateway/backend-api'
import { useStore } from '@/store'

export default defineComponent({
  props: ['customer_id', 'pet_id'],
  emits: ['refreshTable'],
  setup(props, { emit }) {
    const files = ref([])
    const allegatiConfig = ['Nome', 'Data', '']
    const store = useStore()

    const handleFileChangeImage = (file) => {
      file.target.files.forEach((e) => {
        if (e.size < 50000000) {
          files.value.push(e)
        } else {
          store.dispatch('commonComponents/openNotificationModal', {
            message: 'File di dimensione non consentita!'
          })
        }
      })
    }

    const removeFile = (index, file) => {
      const temp = files.value.filter((e) => {
        return e.name !== file.name
      })
      files.value = temp
    }

    const salva = () => {
      var send_data = new FormData()
      send_data.append('type', 'documents')

      files.value.forEach((elem, index) => {
        send_data.append('file_' + index, elem)
      })

      if (props.customer_id) {
        send_data.append('customer_id', props.customer_id)
      }
      if (props.pet_id) {
        send_data.append('pet_id', props.pet_id)
      }
      store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/attachments/upload-file',
          send_data
        )
        .then(() => {
          files.value = []
          store.dispatch('commonComponents/openNotificationModal', {
            message: 'File caricati con successo!'
          })
          emit('refreshTable')
          store.dispatch('commonComponents/hideLoadingModalAction')
        })
        .catch(() => {
          store.dispatch('commonComponents/hideLoadingModalAction')
        })
    }

    return {
      files,
      handleFileChangeImage,
      allegatiConfig,
      removeFile,
      salva
    }
  },
  methods: {}
})
</script>
