<template>
  <TaxableMaxChoiceModal
    v-if="showChoiceMaxModal && choiceRows"
    title="Selezionare prezzo da voler utilizzare"
    :rows="choiceRows.bill_rows"
    :iva="iva"
    @close="
      () => {
        showChoiceMaxModal = false
      }
    "
    @confirm="moveToContoAperto"
  />
  <div class="paginator-container overflow-y-auto">
    <div class="prova mr-5 my-2 flex justify-between flex-1">
      <div class="mt-1">
        <span class="flex-row text-md ml-2 font-bold mr-2 mt-1">Mostra </span>

        <select
          id=""
          name=""
          class="form-select w-16 h-6 pr-2 pl-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
          @change="(event) => changeNumPages(event)"
        >
          <option v-for="show in forPageOption" :key="show" :value="show">
            {{ show }}
          </option>
        </select>

        <span class="text-md font-bold ml-2 mt-1"> elementi</span>
      </div>
    </div>

    <Paginator
      :for-page-option="pagination.forPage"
      :total-records="totalRecords"
      @changePagination="(event) => changePagination(event)"
    />
  </div>

  <!-- BEGIN: Data List -->
  <div
    class="table-container fatt-att-cont pb-5 scrollbar-thin scrollbar-thumb-theme-44 scrollbar-track-theme-2 overflow-scroll overflow-y-auto"
  >
    <table v-if="table_config && docs" class="w-full">
      <thead class="h-8">
        <tr>
          <th
            v-for="(item, itemKey) in table_config"
            :key="itemKey"
            class="px-6 py-3 text-white border-b-2 border-theme-41 whitespace-nowrap text-center font-semibold bg-theme-40 uppercase"
          >
            {{ item.title }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td
            v-for="(elem, index) in table_config"
            :key="index"
            class="text-center px-1 py-3"
          >
            <div
              v-if="elem.title === 'Data Documento'"
              class="flex-col text-left"
            >
              <div class="flex items-baseline">
                <span class="mr-2 font-bold">Da: </span
                ><input
                  v-model="startDate"
                  class="form-control h-7 mt-2 p-0 text-center border-theme-45"
                  type="date"
                  @change="
                    (e) => $emit('filterHandler', elem, e.target.value, endDate)
                  "
                />
              </div>
              <div class="flex items-baseline">
                <span class="mr-4 font-bold">A: </span
                ><input
                  v-model="endDate"
                  class="form-control h-7 mt-2 p-0 text-center border-theme-45"
                  type="date"
                  @change="
                    (e) =>
                      $emit('filterHandler', elem, startDate, e.target.value)
                  "
                />
              </div>
            </div>
            <select
              v-if="elem.type === 'select'"
              v-model="table_filter[elem.key]"
              class="w-full border rounded-md h-7 text-gray-500 mt-2"
            >
              <option selected></option>
              <option v-for="(item, i) in elem.option" :key="i" :value="item">
                {{ item.name ? item.name : item }}
              </option>
            </select>
            <input
              v-else
              v-model="elem.value"
              :type="elem.type ?? 'text'"
              min="0"
              :name="elem.title"
              class="form-control h-7 mt-2"
              :class="{
                invisible: elem.type === 'none',
                'p-0': elem.type === 'date'
              }"
              :placeholder="elem.title"
              @change="$emit('filterHandler', elem)"
            />
          </td>
          <td></td>
        </tr>
        <tr
          v-for="(n, docKey) in docs"
          :key="docKey"
          class="h-12 text-xs font-normal hover:bg-theme-44 cursor-pointer"
          :class="{ 'bg-theme-45': docKey % 2 === 0 }"
        >
          <td
            class="text-center border-r border-gray-500 text-sm"
            @click="$emit('showInvoice', n)"
          >
            {{ $FormatDateMin(n.datetime) }}
          </td>
          <td
            class="text-center border-r border-gray-500 text-sm"
            @click="$emit('showInvoice', n)"
          >
            <b>{{ n.n_bill }}</b>
          </td>
          <td
            v-if="global"
            class="text-center border-r border-gray-500 text-sm"
            @click="$emit('showInvoice', n)"
          >
            <div v-if="n.customer" @click="handleGoToCustomer(n.customer.id)">
              <div
                v-if="n.customer"
                class="tooltipbutton"
                @click="handleGoToCustomer(n.customer)"
              >
                <ChevronsRightIcon class="orange-round" />
                <span class="tooltipbuttontext">Apri Anagrafica</span>
              </div>
              {{
                n.customer
                  ? n.customer.first_name && n.customer.last_name
                    ? n.customer.first_name + ' ' + n.customer.last_name
                    : n.customer.ragione_sociale
                    ? n.customer.ragione_sociale
                    : ''
                  : ''
              }}
            </div>
          </td>
          <!--
            <td
            class="text-center border-r border-gray-500 text-sm"
            @click="$emit('showInvoice', n)"
          >
            {{ n.bill_type ? n.bill_type.description : '' }}
          </td>-->
          <td
            class="text-center border-r border-gray-500 text-sm"
            @click="$emit('showInvoice', n)"
          >
            {{
              iva
                ? Math.round((n.total + Number.EPSILON) * 100) / 100 + '€'
                : Math.round((n.total / ivaPerc + Number.EPSILON) * 100) / 100 +
                  '€'
            }}
          </td>
          <td class="text-center border-r border-gray-500">
            <div class="flex items-center justify-center gap-5">
              <div class="col-span-1">
                <a
                  class="tooltipbutton w-5 h-5 flex items-center justify-center hover:text-theme-41"
                  @click="$emit('showPdf', n)"
                >
                  <i class="fas fa-file-pdf fill-current text-lg" />
                  <span class="tooltipbuttontext">Visualizza PDF</span>
                </a>
              </div>

              <div class="col-span-1" :class="[!n.moved ? '' : 'disabilitato']">
                <button
                  v-if="
                    !n.bill_rows.find((element) => element.range === true) &&
                    !global
                  "
                  type="button"
                  class="hover:text-theme-41 tooltipbutton"
                  @click="handleFatturaPreventivo(n)"
                >
                  <i class="fas fa-euro-sign text-lg"></i>
                  <span
                    class="tooltipbuttontext"
                    style="width: 150px; margin-left: -2.5rem; margin-top: 1rem"
                    >Fattura Preventivo</span
                  >
                  <!-- Fattura -->
                </button>
              </div>
              <div
                v-if="!global"
                class="col-span-1"
                :class="[!n.moved ? '' : 'disabilitato']"
              >
                <button
                  type="button"
                  class="hover:text-theme-41 tooltipbutton"
                  @click="handleMoveToContoAperto(n)"
                >
                  <i class="fas fa-list-alt text-lg"> </i>
                  <span
                    class="tooltipbuttontext"
                    style="width: 170px; margin-left: -2rem; margin-top: 1rem"
                    >Sposta in conto aperto</span
                  >
                  <!-- Sposta in conto aperto -->
                </button>
              </div>

              <div
                v-if="
                  !n.moved &&
                  permissions &&
                  permissions?.fatturazione?.edit_preventivi?.enabled ===
                    true &&
                  !global
                "
                class="col-span-1"
              >
                <button
                  type="button"
                  class="hover:text-theme-41 tooltipbutton"
                  @click="$emit('editInvoice', n)"
                >
                  <span
                    class="tooltipbuttontext"
                    style="margin-left: -1.5rem; margin-top: -0.35rem"
                    >Modifica</span
                  >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    class="w-3 mt-1"
                  >
                    <path
                      d="M362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32zM421.7 220.3L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3z"
                    />
                  </svg>
                </button>
              </div>
              <div
                v-if="
                  !n.moved &&
                  permissions &&
                  permissions?.fatturazione?.edit_preventivi?.enabled ===
                    true &&
                  !global
                "
                class="col-span-1"
              >
                <a
                  class="hover:text-theme-41 w-5 h-5 flex items-center justify-center"
                  @click="handleDeleteRecord(n)"
                >
                  <i class="fas fa-times" />
                </a>
              </div>
            </div>
          </td>
          <td>
            <div
              class="text-center text-xs col-span-1 text-theme-41 font-medium"
              :class="[!n.moved ? 'disabilitato' : '']"
              @click="$emit('showInvoice', n)"
            >
              <p>
                {{
                  n.moved == 'to_conto_aperto'
                    ? 'Spostato sul Conto Aperto'
                    : n.moved == 'preventivo_fatturato'
                    ? 'Preventivo fatturato'
                    : n.moved
                }}
              </p>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="docs && docs.length === 0"
      class="w-full flex justify-center mt-2 py-2"
    >
      Nessun Risultato
    </div>
  </div>
  <!-- END: Data List -->
</template>

<script>
import { defineComponent, toRef, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import axios from '../../gateway/backend-api'
import Paginator from '../../components/paginator/Main'
import { useStore } from '@/store'
import TaxableMaxChoiceModal from '../modals/TaxableMaxChoiceModal.vue'

export default defineComponent({
  components: {
    Paginator,
    TaxableMaxChoiceModal
  },

  props: {
    global: {
      type: [Boolean],
      default: function () {
        return false
      }
    },
    client: {
      type: [Object],
      default: function () {
        return null
      }
    },
    docs: {
      type: [Array],
      default: function () {
        return null
      }
    },
    totalRecords: {
      type: [Number],
      default: function () {
        return null
      }
    },
    pagination: {
      type: [Object],
      default: function () {
        return null
      }
    },
    forPageOption: {
      type: [Number],
      default: function () {
        return null
      }
    }
  },
  emits: [
    'prenotaPreventivo',
    'fatturaPreventivo',
    'showInvoice',
    'getBills',
    'refreshTable',
    'showPdf',
    'editInvoice',
    'filterHandler'
  ],

  setup(props, { emit }) {
    const store = useStore()
    // variabili paginazione
    const pagination = toRef(props, 'pagination')
    const table_config = ref([])
    const table_filter = ref({})
    const startDate = ref(null)
    const endDate = ref(null)
    if (!props.global)
      table_config.value = [
        {
          key: 'sendingDate',
          title: 'Data Documento',
          type: 'none',
          style: 'display: none'
        },
        {
          key: 'n_bill',
          title: 'Numero',
          type: 'text'
        },

        /*{
          key: 'doc',
          title: 'Tipo documento',
          type: 'none',
          option: []
        },
        */
        {
          key: 'price',
          title: 'Totale',
          type: 'none'
        },
        {
          key: 'quickactions',
          title: 'Azioni rapide',
          type: 'none'
        },
        {
          key: 'message',
          title: 'Stato',
          type: 'none'
        }
      ]
    else
      table_config.value = [
        {
          key: 'sendingDate',
          title: 'Data Documento',
          type: 'none',
          style: 'display: none'
        },
        {
          key: 'n_bill',
          title: 'Numero',
          type: 'text'
        },
        {
          key: 'customer',
          title: 'Cliente',
          type: 'text'
        },
        /*
        {
          key: 'document',
          title: 'Tipo documento',
          type: 'none',
          option: []
        },
        */
        {
          key: 'price',
          title: 'Totale',
          type: 'none'
        },
        {
          key: 'quickactions',
          title: 'Azione rapide',
          type: 'none'
        },
        {
          key: 'message',
          title: 'Stato',
          type: 'none'
        }
      ]

    const showChoiceMaxModal = ref(false)
    const choiceRows = ref(null)

    //  cambio elementi per pagina, e ritorno nella prima pagina
    const changePagination = (value) => {
      if (pagination.value.page != value.page) {
        pagination.value.page = value.page
        pagination.value.forPage = value.forPage
      }
    }

    // metodo che cambia il numero della pagina
    const changeNumPages = (elem) => {
      if (pagination.value.forPage != elem.target.value)
        pagination.value.forPage = elem.target.value
    }

    const handleMoveToContoAperto = (elem) => {
      store.dispatch('commonComponents/openConfirmModal', {
        title: 'Attenzione',
        subtitle: 'Sei sicuro di voler spostare nel conto aperto?',
        background: false,
        confirm: () => {
          checkmoveToContoAperto(elem)
        },
        decline: () => {
          return false
        }
      })
    }

    const moveToContoAperto = () => {
      showChoiceMaxModal.value = false
      if (choiceRows.value.bill_rows)
        choiceRows.value.bill_rows.forEach((el) => {
          var params = {
            price: el.finalValue ? el.finalValue : el.price,
            code: el.cod_tariffa_alias,
            qty: el.quantity,
            sconto: el.discount ? el.discount : 0,
            status:
              el.tariffario_alias_id || el.tariffario_packages_id
                ? 'pending'
                : 'libera',
            tariffario_alias_id: el.tariffario_alias_id ?? null,
            tariffario_packages_id: el.tariffario_packages_id ?? null,
            description: el.description
          }
          axios
            .post(
              '/' +
                localStorage.getItem('clinic') +
                '/account/' +
                props.client.id,
              params
            )
            .then(() => {
              axios
                .post(
                  '/' +
                    localStorage.getItem('clinic') +
                    '/bills/' +
                    choiceRows.value.id +
                    '/moveToContoAperto'
                )
                .then(() => {
                  emit('refreshTable')
                })
            })
        })
    }

    const handleDeleteRecord = (doc) => {
      store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler eliminare il preventivo?',
        background: false,
        confirm_text: 'Conferma',
        decline_text: 'Annulla',
        confirm: () => {
          store.dispatch('commonComponents/showLoadingModalAction')
          axios
            .post(
              '/' +
                localStorage.getItem('clinic') +
                '/bills/' +
                doc.id +
                '/delete',
              {}
            )
            .then(() => {
              emit('refreshTable')
              store.dispatch('commonComponents/hideLoadingModalAction')
            })
            .catch(() => {
              store.dispatch('commonComponents/openNotificationModal', {
                message: 'Errore durante cancellazione del preventivo'
              })
              store.dispatch('commonComponents/hideLoadingModalAction')
            })
        },
        decline: () => {}
      })
    }

    const checkmoveToContoAperto = (elem) => {
      let skip = true
      elem.bill_rows.forEach((elem) => {
        if (elem.range) {
          skip = false
        }
      })
      choiceRows.value = elem

      if (skip) {
        moveToContoAperto()
      } else {
        showChoiceMaxModal.value = true
      }
    }

    const handleFatturaPreventivo = (elem) => {
      store.dispatch('commonComponents/openConfirmModal', {
        title: 'Attenzione',
        subtitle: 'Sei sicuro di voler fatturare il preventivo?',
        background: false,
        confirm: () => {
          emit('fatturaPreventivo', elem)
        },
        decline: () => {
          return false
        }
      })
    }

    return {
      table_config,
      changePagination,
      changeNumPages,
      moveToContoAperto,
      checkmoveToContoAperto,
      handleMoveToContoAperto,
      handleFatturaPreventivo,
      handleDeleteRecord,
      showChoiceMaxModal,
      choiceRows,
      table_filter,
      startDate,
      endDate
    }
  },
  computed: {
    ...mapGetters({
      iva: 'reusableData/getShowIva',
      permissions: 'reusableData/getPermissions',
      ivaPerc: 'reusableData/getRegimeFiscaleIvaPerc',
      getPetsDetails: 'anagraficaPazienti/getPetsDetails',
      getComparisonPets: 'anagraficaPazienti/getComparisonPets',
      getCustomersDetails: 'anagraficaClienti/getCustomersDetails',
      getComparisonCustomer: 'anagraficaClienti/getComparisonCustomer'
    })
  },
  methods: {
    ...mapActions({
      getPetAction: 'anagraficaPazienti/getPetAction',
      getCustomerAction: 'anagraficaClienti/getCustomerAction',
      updateClientData: 'anagraficaClienti/updateClientData'
    }),
    handleGoToCustomer(customer_id) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .get('/' + localStorage.getItem('clinic') + '/customer/' + customer_id)
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$goToCustomer(res.data)
        })
        .catch(() =>
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        )
    }
  }
})
</script>
