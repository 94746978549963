<template>
  <div
    class="intro-y box px-5 py-1 overflow-auto content-container scrollbar_custom"
  >
    <div class="bg-white">
      <div
        v-if="
          permissions &&
          permissions['REV - Ricetta Elettonica Veterinaria'] &&
          permissions['REV - Ricetta Elettonica Veterinaria'].store_rev &&
          permissions['REV - Ricetta Elettonica Veterinaria'].store_rev
            .enabled === true
        "
        class="flex flex-col md:flex-row items-center justify-end my-5"
      >
        <button
          type="file"
          class="orange-btn px-3 py-1 flex mr-2"
          @click="handleNewRecipeModal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            class="h-5 w-5 fill-current mr-1"
          >
            <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
              d="M0 64C0 28.65 28.65 0 64 0H224V128C224 145.7 238.3 160 256 160H384V198.6C310.1 219.5 256 287.4 256 368C256 427.1 285.1 479.3 329.7 511.3C326.6 511.7 323.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM256 128V0L384 128H256zM288 368C288 288.5 352.5 224 432 224C511.5 224 576 288.5 576 368C576 447.5 511.5 512 432 512C352.5 512 288 447.5 288 368zM448 303.1C448 295.2 440.8 287.1 432 287.1C423.2 287.1 416 295.2 416 303.1V351.1H368C359.2 351.1 352 359.2 352 367.1C352 376.8 359.2 383.1 368 383.1H416V431.1C416 440.8 423.2 447.1 432 447.1C440.8 447.1 448 440.8 448 431.1V383.1H496C504.8 383.1 512 376.8 512 367.1C512 359.2 504.8 351.1 496 351.1H448V303.1z"
            />
          </svg>

          Nuova Ricetta
        </button>
        <button
          type="file"
          class="orange-btn px-3 py-1 flex"
          @click="handleFreeRecipeModal"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 576 512"
            class="h-5 w-5 fill-current mr-1"
          >
            <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
            <path
              d="M0 64C0 28.65 28.65 0 64 0H224V128C224 145.7 238.3 160 256 160H384V198.6C310.1 219.5 256 287.4 256 368C256 427.1 285.1 479.3 329.7 511.3C326.6 511.7 323.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM256 128V0L384 128H256zM576 368C576 447.5 511.5 512 432 512C352.5 512 288 447.5 288 368C288 288.5 352.5 224 432 224C511.5 224 576 288.5 576 368zM476.7 324.7L416 385.4L387.3 356.7C381.1 350.4 370.9 350.4 364.7 356.7C358.4 362.9 358.4 373.1 364.7 379.3L404.7 419.3C410.9 425.6 421.1 425.6 427.3 419.3L499.3 347.3C505.6 341.1 505.6 330.9 499.3 324.7C493.1 318.4 482.9 318.4 476.7 324.7H476.7z"
            />
          </svg>
          Ricetta Libera
        </button>
      </div>
      <div class="ricette-cont">
        <RegistroTable
          v-if="docs && tableConfig_clinic_doc"
          :table-config-clinic-doc="tableConfig_clinic_doc"
          :documents="docs"
          :total-records="totalRecords"
          :loading-search-rev="loadingSearchRev"
          :emit-rev="
            permissions &&
            permissions['REV - Ricetta Elettonica Veterinaria'] &&
            permissions['REV - Ricetta Elettonica Veterinaria'].emit_rev &&
            permissions['REV - Ricetta Elettonica Veterinaria'].emit_rev
              .enabled === true
          "
          :edit-rev="
            permissions &&
            permissions['REV - Ricetta Elettonica Veterinaria'] &&
            permissions['REV - Ricetta Elettonica Veterinaria'].edit_rev &&
            permissions['REV - Ricetta Elettonica Veterinaria'].edit_rev
              .enabled === true
          "
          :for-page-option="forPageOption"
          @handleSendMessage="handleSendMessage"
          @handleSavePdfRev="handleSavePdfRev"
          @handleDeleteRev="(filter) => handleDeleteRev(filter)"
          @duplicaRev="
            (elem, filter, pagination) => duplicaRev(elem, filter, pagination)
          "
          @edit="edit"
          @handleLoadRev="
            (filter, pagination) => handleLoadRev(filter, pagination)
          "
        />
        <div
          v-else-if="
            permissions &&
            permissions['REV - Ricetta Elettonica Veterinaria'] &&
            permissions['REV - Ricetta Elettonica Veterinaria'].view_rev &&
            permissions['REV - Ricetta Elettonica Veterinaria'].view_rev
              .enabled === false
          "
        >
          <p>I permessi per visualizzare le ricette non sono abilitati</p>
        </div>
      </div>
    </div>
    <!--END: Da Effettuare-->

    <div
      v-if="!tableConfig_clinic_doc || !docs"
      class="flex w-full h-screen content-center items-center justify-center"
    >
      <RotateCwIcon class="animate-spin h-full w-5 mr-3 scale-150" />
    </div>
  </div>

  <SendMessageModal
    v-if="messageModal && selectedElem"
    :elem="selectedElem"
    @close="closeSendMessageModal"
    @confirm="confirmMessage"
  />
  <NewRecipeModal
    v-if="newRecipeModal"
    :new-drugs="newDrugsList"
    :pet-invoice="petInvoice"
    :client-invoice="clientInvoice"
    :edit_id="edit_id"
    :diagnosi="prescapoDiagnosiCodice"
    :note="ricNote"
    @close="closeNewRecipeModal"
    @emitDrug="emitDrug"
    @editRev="editRev"
    @confirm="confirmAddNewRecipe"
    @addDrug="handleAddDrug"
    @removeDrug="removeDrug"
    @editDrug="editDrug"
    @selectClientPet="handleClientPet"
    @setRicNote="setRicNote"
    @setDiagnosi="setDiagnosi"
  />

  <AddDrugModal
    v-if="drugModal"
    :edit-drug-pet="editDrugPet"
    :editable-drug-pet="editableDrugPet"
    :drugs-types="drugsTypes"
    :unique-drug-type="uniqueDrugType"
    @close="closeAddDrugModal"
    @confirm="confirmAddDrug"
    @edit="confirmEditDrug"
  />
  <FreeRecipeModal
    v-if="freeRecipeModal"
    :new-drugs="newDrugsList"
    @close="closeFreeRecipeModal"
    @confirmAddNewFreeRecipe="confirmAddNewFreeRecipe"
    @addDrug="handleAddDrug"
    @removeDrug="removeDrug"
    @editDrug="editDrug"
  />
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import axios from '../../gateway/backend-api'
import _ from 'lodash'
import SendMessageModal from '../../components/modals/SendMessageModal.vue'
import NewRecipeModal from '../../components/modals/NewRecipeModal.vue'
import FreeRecipeModal from '../../components/modals/FreeRecipeModal.vue'
import AddDrugModal from '../../components/modals/AddDrugModal.vue'
import { useStore } from '@/store'

import RegistroTable from './RegistroTable.vue'

export default defineComponent({
  components: {
    SendMessageModal,
    RegistroTable,
    NewRecipeModal,
    FreeRecipeModal,
    AddDrugModal
  },
  data() {
    return {
      store: useStore(),
      tableConfig_vaccine_doc: null,
      docs: [],
      messageModal: false,
      newRecipeModal: false,
      freeRecipeModal: false,
      drugModal: false,
      selectedElem: null,
      editDrugPet: false,
      totalRecords: 0,
      editableDrugPet: [],
      newDrugsList: [],
      loadingSearchRev: true,
      clientInvoice: null,
      petInvoice: null,
      ricNote: '',
      prescapoDiagnosiCodice: null,
      uniqueDrugType: false, ////La ricetta non può contenere più di un medicinale con presrigaFlTipoMedicinale valorizzato a S o O o E.
      edit_id: null
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions'
    }),
    drugsTypes: function () {
      if (this.uniqueDrugType && !this.editDrugPet) return ['Farmaco']
      else return ['Farmaco', 'Galenico', 'Omeopatico', 'Estero']
    }
  },
  mounted() {
    axios
      .get('/registri_table_config') // scarico la configurazione della tabella
      .then((resp) => {
        const temp = []
        for (var elem of resp.data) {
          temp.push({ ...elem, value: '' }) // ai dati ricevuti aggiungo un campo per il valore del filtro che sarà inserito
        }
        this.tableConfig_vaccine_doc = temp
        this.tableConfig_clinic_doc = temp
      })
    this.handleLoadRev()
  },
  methods: {
    setRicNote(elem) {
      this.ricNote = elem
    },
    setDiagnosi(elem) {
      this.prescapoDiagnosiCodice = elem
    },
    handleClientPet(elem, value) {
      if (elem == 'clientInvoice') this.clientInvoice = value
      else if (elem == 'petInvoice') this.petInvoice = value
    },
    handleSendMessage(elem) {
      this.selectedElem = elem
      this.messageModal = true
    },
    handleNewRecipeModal(open = true) {
      if (!open) this.newDrugsList = []
      this.newRecipeModal = open
    },
    handleFreeRecipeModal(list) {
      if (list) {
        this.newDrugsList = list
      }
      this.freeRecipeModal = true
    },
    handleAddDrug() {
      this.drugModal = true
      this.newRecipeModal = false
      this.editDrugPet = false
    },
    removeDrug(elem) {
      if (elem.type != 'Farmaco') this.uniqueDrugType = false
      const filtered = this.newDrugsList.filter((e) => {
        return !_.isEqual(e, elem)
      })
      this.newDrugsList = filtered
    },
    editDrug(elem) {
      this.drugModal = true
      this.editDrugPet = true
      var indexDrugsList = null
      this.editableDrugPet = this.newDrugsList.filter((e, index) => {
        if (_.isEqual(e, elem)) {
          indexDrugsList = index
          return _.isEqual(e, elem)
        }
      })

      this.editableDrugPet[0]['indexDrugsList'] = indexDrugsList
    },

    confirmAddNewFreeRecipe(ricetta, customer, pet) {
      this.freeRecipeModal = false

      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/pet/' +
            pet.id +
            '/prescription',
          {
            customer_id: customer.id,
            prescription: ricetta
          }
        )
        .then((res) => {
          if (res.status === 201 || res.status === 200) {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Ricetta inserita con successo!'
            })
            this.handleLoadRev()
          }
        })
        .catch((err) => {
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: err
          })
          this.handleLoadRev()
        })
    },
    confirmAddDrug(elem) {
      if (elem.type.value != 'Farmaco') this.uniqueDrugType = true

      this.newDrugsList.push({
        aic: elem.aic,
        farmaco: elem.farmaci,
        nome: elem.denominazione,
        deroga: elem.deroga,
        qty: elem.cNumber,
        tipo: elem.type,
        data: {
          note: elem.note,
          repeatable: elem.repeatable,
          chronical: elem.chronical,
          deroga: elem.deroga,
          ormonal: elem.ormonal
        },
        category: elem.category,
        antimicrobico: elem.antimicrobico,
        revOption: elem.revOption,
        whyProfilassiMetafilassi: elem.whyProfilassiMetafilassi,
        trattamento: elem.trattamento,
        confezione: elem.confezione,
        tNumber: elem.tNumber,
        posologia: elem.posologia,
        description: elem.description,
        noteWhyProfilassiMetafilassi: elem.noteWhyProfilassiMetafilassi,
        indexDrugsListEditable: elem.indexDrugsListEditable
      })

      this.newRecipeModal = true
      this.drugModal = false
    },
    confirmEditDrug(elem) {
      var indexDrugsListEditable = elem.indexDrugsListEditable.value

      if (
        this.newDrugsList[indexDrugsListEditable].tipo != 'Farmaco' &&
        elem.type.value == 'Farmaco'
      )
        this.uniqueDrugType = false

      this.newDrugsList[indexDrugsListEditable] = {
        aic: elem.aic,
        farmaco: elem.farmaci,
        nome: elem.denominazione,
        deroga: elem.deroga,
        qty: elem.cNumber,
        tipo: elem.type,
        data: {
          note: elem.note,
          repeatable: elem.repeatable,
          chronical: elem.chronical,
          deroga: elem.deroga,
          ormonal: elem.ormonal
        },
        category: elem.category,
        antimicrobico: elem.antimicrobico,
        revOption: elem.revOption,
        whyProfilassiMetafilassi: elem.whyProfilassiMetafilassi,
        trattamento: elem.trattamento,
        confezione: elem.confezione,
        tNumber: elem.tNumber,
        posologia: elem.posologia,
        description: elem.description,
        noteWhyProfilassiMetafilassi: elem.noteWhyProfilassiMetafilassi,
        indexDrugsListEditable: elem.indexDrugsListEditable
      }

      this.newRecipeModal = true
      this.drugModal = false
    },
    emitDrug(elem, emit) {
      var ricMedicinali = []
      var ricCapi = []
      var ricMedicinaliCapi = []
      this.newDrugsList.forEach((drug, index) => {
        var presrigaFlTipoMedicinale = ''
        switch (drug.tipo) {
          case 'Farmaco':
            presrigaFlTipoMedicinale = 'N'
            break
          case 'Galenico':
            presrigaFlTipoMedicinale = 'S'
            break
          case 'Omeopatico':
            presrigaFlTipoMedicinale = 'O'
            break
          case 'Estero':
            presrigaFlTipoMedicinale = 'E'
            break
          // default:
        }
        ricMedicinali.push({
          presrigaFlTipoMedicinale: presrigaFlTipoMedicinale,
          presrigaProdAic: drug.aic,
          presrigaPosologia:
            drug.farmaco && drug.farmaco.posologia
              ? drug.farmaco.posologia
              : drug.posologia,
          presrigaDurataTrattamento:
            drug.farmaco && drug.farmaco.durata
              ? drug.farmaco.durata
              : drug.tNumber,
          presrigaQuantitativo:
            drug.farmaco && drug.farmaco.qty ? drug.farmaco.qty : drug.qty,
          presrigaDescrizione: drug.description,
          presrigaNote: drug.data && drug.data.note ? drug.data.note : '',
          presrigaFlMalattiaCronica:
            drug.data && drug.data.chronical !== null
              ? drug.data.chronical
              : null,
          presrigaProfilassiMetafilassiCodice: drug.revOption
            ? drug.revOption.toUpperCase()
            : null,
          extra1: drug.trattamento ? drug.trattamento : null,
          presrigaGiustificazioneCodice: drug.whyProfilassiMetafilassi
            ? drug.whyProfilassiMetafilassi
            : null,
          presrigaGiustificazioneNote: drug.noteWhyProfilassiMetafilassi
            ? drug.noteWhyProfilassiMetafilassi
            : null,
          presrigaFlRipetibile:
            drug.data && drug.data.repeatable
              ? drug.data.repeatable === 'Si'
                ? 'S'
                : 'N'
              : '',
          presrigaFlOrmonaleVaccino: drug.data.ormonal
        })

        if (index == 0) {
          ricCapi.push({
            prescapoSpecieCodice: this.petInvoice.type,
            prescapoIdentificativo: this.petInvoice.microchip
              ? this.petInvoice.microchip
              : this.petInvoice.id
          })
        }

        ricMedicinaliCapi.push({
          presrigaIndex: index,
          prescapoIndex: 0,
          prescapoDerogaCodice: '',
          prescapoNote: drug.data.deroga,
          prescapoDiagnosiCodice: this.prescapoDiagnosiCodice
        })
      })

      var send_data = {
        customer_id: elem.id,
        pet_id: this.petInvoice.id,
        strCodice: '', //codice clinica
        ricFlProsieguo: true, //todo
        vetIdFiscale: 'ZSIZSI80A01L103N', //codice fiscale veterinario
        propFlStraniero: false, //verificare se cliente ha residenza in italia
        propIdFiscale:
          this.petInvoice.owners[0].vat_number?.vat_number ??
          this.petInvoice.owners[0].fiscal_code,
        propNome: this.petInvoice.owners[0].first_name,
        propCognome: this.petInvoice.owners[0].last_name,
        propFlGiuridico:
          this.petInvoice.owners[0].type == 'Persona giuridica' ? true : false,
        propRagioneSociale: this.petInvoice.owners[0].ragione_sociale,
        ricNote: this.ricNote,
        warnDisabled: true,
        ricMedicinali: ricMedicinali,
        ricCapi: ricCapi,
        ricMedicinaliCapi: ricMedicinaliCapi,
        emit: emit
      }

      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/rev/storeRev', send_data)
        .then((res) => {
          if (emit) {
            if (typeof res.data.ricNumero != 'undefined') {
              this.newRecipeModal = false
              this.revDocs = []

              this.$store.dispatch('commonComponents/hideLoadingModalAction')
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Ricetta clinica emessa con successo!'
              })

              this.uniqueDrugType = false
              this.handleLoadRev()
            } else {
              this.handleLoadRev()
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            }
            this.closeNewRecipeModal()
          } else {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            this.uniqueDrugType = false
            //this.handleNewRecipeModal(false)
            this.closeNewRecipeModal()
            this.handleLoadRev()
          }
        })
        .catch((error) => {
          console.log(error)
          this.handleLoadRev()
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    editRev(client, pet) {
      var ricMedicinali = []
      var ricCapi = []
      var ricMedicinaliCapi = []
      this.newDrugsList.forEach((drug, index) => {
        var presrigaFlTipoMedicinale = ''
        switch (drug.tipo) {
          case 'Farmaco':
            presrigaFlTipoMedicinale = 'N'
            break
          case 'Galenico':
            presrigaFlTipoMedicinale = 'S'
            break
          case 'Omeopatico':
            presrigaFlTipoMedicinale = 'O'
            break
          case 'Estero':
            presrigaFlTipoMedicinale = 'E'
            break
          // default:
        }
        ricMedicinali.push({
          presrigaFlTipoMedicinale: presrigaFlTipoMedicinale,
          presrigaProdAic: drug.aic,
          presrigaPosologia:
            drug.farmaco && drug.farmaco.posologia
              ? drug.farmaco.posologia
              : drug.posologia,
          presrigaDurataTrattamento:
            drug.farmaco && drug.farmaco.durata
              ? drug.farmaco.durata
              : drug.tNumber,
          presrigaQuantitativo:
            drug.farmaco && drug.farmaco.qty ? drug.farmaco.qty : drug.qty,
          presrigaDescrizione: drug.description,
          presrigaNote: drug.data && drug.data.note ? drug.data.note : '',
          presrigaFlMalattiaCronica:
            drug.data && drug.data.chronical ? drug.data.chronical : '',
          presrigaProfilassiMetafilassiCodice: drug.revOption
            ? drug.revOption.toUpperCase()
            : null,
          extra1: drug.trattamento ? drug.trattamento : null,
          presrigaGiustificazioneCodice: drug.whyProfilassiMetafilassi
            ? drug.whyProfilassiMetafilassi
            : null,
          presrigaGiustificazioneNote: drug.noteWhyProfilassiMetafilassi
            ? drug.noteWhyProfilassiMetafilassi
            : null,
          presrigaFlRipetibile:
            drug.data && drug.data.repeatable
              ? drug.data.repeatable === 'Si'
                ? 'S'
                : 'N'
              : '',
          presrigaFlOrmonaleVaccino: drug.data.ormonal
        })

        if (index == 0) {
          ricCapi.push({
            prescapoSpecieCodice: this.petInvoice.type,
            prescapoIdentificativo: this.petInvoice.microchip
              ? this.petInvoice.microchip
              : this.petInvoice.id
          })
        }

        ricMedicinaliCapi.push({
          presrigaIndex: index,
          prescapoIndex: 0,
          prescapoDerogaCodice: '',
          prescapoNote: drug.data.deroga,
          prescapoDiagnosiCodice: this.prescapoDiagnosiCodice
        })
      })

      var send_data = {
        customer_id: client.id,
        pet_id: pet.id,
        strCodice: '', //codice clinica
        ricFlProsieguo: true, //todo
        vetIdFiscale: 'ZSIZSI80A01L103N', //codice fiscale veterinario
        propFlStraniero: false, //verificare se cliente ha residenza in italia
        propIdFiscale:
          this.petInvoice.owners[0].vat_number?.vat_number ??
          this.petInvoice.owners[0].fiscal_code,
        propNome: this.petInvoice.owners[0].first_name,
        propCognome: this.petInvoice.owners[0].last_name,
        propFlGiuridico:
          this.petInvoice.owners[0].type == 'Persona giuridica' ? true : false,
        propRagioneSociale: this.petInvoice.owners[0].ragione_sociale,
        ricNote: this.ricNote,
        warnDisabled: true,
        ricMedicinali: ricMedicinali,
        ricCapi: ricCapi,
        ricMedicinaliCapi: ricMedicinaliCapi
      }

      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/rev/editRev/' + this.edit_id,
          send_data
        )
        .then(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Ricetta clinica modificata con successo!'
          })
          this.uniqueDrugType = false
          //this.handleNewRecipeModal(false)
          this.closeNewRecipeModal()
          this.handleLoadRev()
        })
        .catch((error) => {
          this.handleLoadRev()
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$store.dispatch('commonComponents/openMessageModal', {
            title: 'Errore',
            subtitle:
              error.response.status == 403
                ? 'Non autorizzato'
                : error.response.data.message,
            background: false
          })
        })
    },
    handleLoadRev(value = {}, pagination) {
      this.loadingSearchRev = true

      axios
        .post('/' + localStorage.getItem('clinic') + '/rev', {
          active_filters: value,
          per_page: pagination ? pagination.forPage : 10,
          page: pagination ? pagination.page : 1
        })

        .then((res) => {
          this.loadingSearchRev = false
          this.docs = res.data.data ?? []
          this.totalRecords = res.data.total
        })
        .catch(() => {
          this.loadingSearchRev = false
        })
    },
    handleSavePdfRev(elem) {
      let customer = null

      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/customer/' +
            elem.details.customer_id
        )
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          customer = res.data
          this.openPdf(elem, customer)
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    openPdf(elem, customer) {
      if (elem.type === 'Libera') {
        this.$store.dispatch('commonComponents/showLoadingModalAction')
        axios({
          url:
            '/' +
            localStorage.getItem('clinic') +
            '/pet/prescription/show/' +
            elem.number,
          method: 'POST',
          responseType: 'blob'
        })
          .then((response) => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Ricetta_' + elem.number + '.pdf')
            document.body.appendChild(link)
            this.$store.dispatch('commonComponents/openPdfModal', {
              title: 'Ricetta ' + elem.type + '_' + elem.number,
              link: url,
              background: false,
              table_reference: 'revs',
              id_reference: elem.id,
              client: customer,
              print: () => {
                console.log('PRINT')
              },
              download: () => {
                link.click()
              }
            })
          })
          .catch(() => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
          })
      } else {
        this.$store.dispatch('commonComponents/showLoadingModalAction')
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/rev/pdfRev',
            {
              ricNumero: elem.number
            },
            {
              responseType: 'arraybuffer',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/pdf'
              }
            }
          )
          .then((response) => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Ricetta_' + elem.number + '.pdf')
            document.body.appendChild(link)
            this.$store.dispatch('commonComponents/openPdfModal', {
              title: 'Ricetta ' + elem.type + '_' + elem.number,
              link: url,
              background: false,
              table_reference: 'revs',
              id_reference: elem.number,
              client: customer,
              print: () => {
                console.log('PRINT')
              },
              download: () => {
                link.click()
              }
            })

            // link.click()
          })
          .catch((error) => {
            console.log(error)
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
          })
      }
    },
    handleDeleteRev(elem) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler annullare la ricetta?',
        background: false,
        confirm: () => {
          axios
            .post('/' + localStorage.getItem('clinic') + '/rev/annullaRev', {
              ricNumero: elem?.number?.toString(),
              id: elem.id
            })
            .then((response) => {
              if (response.status == 200) {
                const temp = this.docs.filter((doc) => {
                  return !_.isEqual(doc, elem)
                })
                this.docs = temp
                this.totalRecords = this.docs.length
                this.handleLoadRev()
              }
            })
        },
        decline: () => {
          console.log('Ho declinato la risposta')
        }
      })
    },
    closeSendMessageModal() {
      // metodo per chiudere il modal di invio messaggio
      this.selectedElem = null
      this.messageModal = false
    },
    closeNewRecipeModal() {
      this.selectedElem = null
      this.edit_id = null
      this.ricNote = null
      this.prescapoDiagnosiCodice = null
      this.newRecipeModal = false
      this.newDrugsList = []
    },
    closeFreeRecipeModal() {
      this.newDrugsList = []
      this.selectedElem = null
      this.freeRecipeModal = false
    },
    closeAddDrugModal() {
      this.drugModal = false
      this.newRecipeModal = true
    },

    confirmMessage() {
      // titolo, message, file
      this.messageModal = false
    },
    edit(elem) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      this.edit_id = elem.id
      axios
        .get(
          '/' + localStorage.getItem('clinic') + '/pet/' + elem.details.pet_id
        )
        .then((res) => {
          this.petInvoice = res.data
          this.clientInvoice = res?.data?.owners[0]
          res.data.owners.forEach((el) => {
            if (el.id === elem.details.customer_id) this.clientInvoice = el
          })
          if (elem.type == 'Libera') {
            this.handleFreeRecipeModal(elem.note)
          } else {
            axios
              .get(
                '/' +
                  localStorage.getItem('clinic') +
                  '/rev/' +
                  elem.id +
                  '/info'
              )
              .then((res) => {
                let j = JSON.parse(res.data.details)
                if (res?.data?.details && res?.data?.farmaci) {
                  for (let i = 0; i < j.ricMedicinali.length; i++) {
                    let tipo_farmaco = ''
                    if (j.ricMedicinali[i].presrigaFlTipoMedicinale)
                      switch (j.ricMedicinali[i].presrigaFlTipoMedicinale) {
                        case 'N':
                          tipo_farmaco = 'Farmaco'
                          break
                        case 'S':
                          tipo_farmaco = 'Galenico'
                          break
                        case 'O':
                          tipo_farmaco = 'Omeopatico'
                          break
                        case 'E':
                          tipo_farmaco = 'Estero'
                          break
                        // default:
                      }
                    let xtr = j.ricMedicinali[i].extraObject
                      ? JSON.parse(j.ricMedicinali[i].extraObject)
                      : null
                    console.log(j.ricMedicinali[i], res.data.farmaci[i])
                    this.newDrugsList.push({
                      aic: j.ricMedicinali[i].presrigaProdAic,
                      farmaco: {
                        aic: j.ricMedicinali[i].presrigaProdAic,
                        farmaco: res.data.farmaci[i]
                          ? res.data.farmaci[i].MARCHIO
                          : null,
                        confezione: res.data.farmaci[i]
                          ? res.data.farmaci[i].CONFEZIONE
                          : null,
                        qty: j.ricMedicinali[i].presrigaQuantitativo,
                        posologia: j.ricMedicinali[i].presrigaPosologia,
                        durata: j.ricMedicinali[i].presrigaDurataTrattamento,
                        tipo: tipo_farmaco
                      }, // res.data.farmaci[i].MARCHIO,
                      nome: res.data.farmaci[i] ? res.data.farmaci[i] : null,
                      //deroga: j.ricMedicinali[i].,
                      qty: j.ricMedicinali[i].presrigaQuantitativo,
                      tipo: tipo_farmaco,
                      data: {
                        note: j.ricMedicinali[i].presrigaNote,
                        repeatable:
                          j.ricMedicinali[i].presrigaFlRipetibile &&
                          j.ricMedicinali[i].presrigaFlRipetibile === 'S'
                            ? 'Si'
                            : 'No',
                        chronical: j.ricMedicinali[i].presrigaFlMalattiaCronica,
                        deroga: j.ricMedicinaliCapi
                          ? j.ricMedicinaliCapi[0]?.prescapoNote
                          : null,
                        ormonal: j.ricMedicinali[i].presrigaFlOrmonaleVaccino
                      },
                      category: res.data.farmaci[i]
                        ? res.data.farmaci[i].TIPO_PRODOTTO
                        : null,
                      antimicrobico:
                        xtr && xtr.presrigaProdFlAntimicrobico
                          ? xtr.presrigaProdFlAntimicrobico
                          : null, //el.antimicrobico, // c'è solo se  un farmaco estero, da provare
                      //revOption: el.revOption, // da capire cosa sia
                      // whyProfilassiMetafilassi:
                      //   xtr && xtr.presrigaProfilassiMetafilassiCodice
                      //     ? xtr.presrigaProfilassiMetafilassiCodice
                      //     : null,
                      trattamento: j.ricMedicinali[i].extra1,
                      confezione: res.data.farmaci[i]
                        ? res.data.farmaci[i].CONFEZIONE
                        : null,
                      tNumber: j.ricMedicinali[i].presrigaDurataTrattamento,
                      posologia: j.ricMedicinali[i].presrigaPosologia,
                      description: res.data.farmaci[i]
                        ? res.data.farmaci[i].DESCRIZIONE_DISPENSAZIONE
                        : j.ricMedicinali[i].presrigaDescrizione,
                      deroga: j.ricMedicinaliCapi
                        ? j.ricMedicinaliCapi[0]?.prescapoNote
                        : null,
                      revOption:
                        j.ricMedicinali[i]?.presrigaProfilassiMetafilassiCodice,
                      whyProfilassiMetafilassi:
                        j.ricMedicinali[i]?.presrigaGiustificazioneCodice,
                      noteWhyProfilassiMetafilassi:
                        j.ricMedicinali[i]?.presrigaGiustificazioneNote
                      // noteWhyProfilassiMetafilassi:
                      //   el.noteWhyProfilassiMetafilassi,
                      // indexDrugsListEditable: el.indexDrugsListEditable
                    })
                  }
                }
                this.setDiagnosi(j.ricMedicinaliCapi[0]?.prescapoDiagnosiCodice)
                this.setRicNote(j.ricNote)
                this.$store.dispatch('commonComponents/hideLoadingModalAction')

                this.handleNewRecipeModal()
              })
              .catch(() => {
                this.$store.dispatch('commonComponents/hideLoadingModalAction')
              })
          }
        })
    },
    duplicaRev(elem, filter = {}, pagination) {
      this.loadingSearchRev = true

      axios
        .post('/' + localStorage.getItem('clinic') + '/rev/duplicaRev', {
          rev_id: elem.id
        })

        .then(() => {
          this.handleLoadRev(filter, pagination)
        })
        .catch(() => {
          this.loadingSearchRev = false
        })
    }
  }
})
</script>
