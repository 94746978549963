<template>
  <!-- BEGIN: Documents-->
  <div>
    <div class="pos intro-y grid grid-cols-12 gap-5">
      <div class="intro-y col-span-12">
        <div class="post intro-y overflow-hidden bg-white rounded-b-2xl">
          <div
            class="post__tabs nav nav-tabs flex-col sm:flex-row bg-theme-2 text-theme-42"
            role="tablist"
          ></div>
          <div class="pb-4 my-2 px-3">
            <div class="w-full">
              <div class="paginator-container">
                <div class="mr-5 my-2 flex justify-between flex-1">
                  <div class="my-1">
                    <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
                      >Mostra
                    </span>

                    <select
                      id=""
                      name=""
                      class="form-select w-16 h-6 pr-2 pl-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
                      @change="(event) => changeNumPages(event)"
                    >
                      <option
                        v-for="show in forPageOption"
                        :key="show"
                        :value="show"
                      >
                        {{ show }}
                      </option>
                    </select>

                    <span class="text-md font-bold ml-2 mt-1"> elementi</span>
                  </div>
                </div>

                <Paginator
                  :for-page-option="pagination.forPage"
                  :total-records="totalRecords"
                  @changePagination="(event) => changePagination(event)"
                />
              </div>

              <!-- BEGIN: Data List -->
              <div
                class="table-container bg-white scrollbar_custom pb-5 overflow-autooverflow-auto scrollbar_custom mb-1"
              >
                <table class="w-full mb-5">
                  <THeader
                    v-for="(item, itemKey) in table_config"
                    :key="itemKey"
                    :name="item.title"
                    class="t-header h-10"
                  />
                  <tbody>
                    <tr>
                      <td
                        v-for="(elem, index) in table_config"
                        :key="index"
                        class="text-center px-1 py-3"
                      >
                        <select
                          v-if="elem.type === 'select'"
                          v-model="table_filter[elem.key]"
                          class="w-full border rounded-md h-7 text-gray-500 mt-2"
                        >
                          <option selected></option>
                          <option
                            v-for="(item, i) in elem.option"
                            :key="i"
                            :value="item"
                          >
                            {{ item.name ? item.name : item }}
                          </option>
                        </select>
                        <input
                          v-else
                          v-model="elem.value"
                          :type="elem.type"
                          min="0"
                          :name="elem.title"
                          class="form-control h-7 mt-2"
                          :class="{
                            invisible: elem.type === 'none',
                            'p-0': elem.type === 'date'
                          }"
                          :placeholder="elem.title"
                          @change="filterHandler(elem)"
                        />
                      </td>
                      <td></td>
                    </tr>
                    <tr
                      v-for="(doc, i) in documents"
                      :key="i"
                      class="text-base font-normal hover:bg-theme-44 cursor-pointer"
                      :class="{ 'bg-theme-2': i % 2 === 0 }"
                    >
                      <Cell
                        :elementi="
                          doc.completed ? 'Effettuato' : 'Da Effettuare'
                        "
                        class="text-center text-sm border-r-2 border-theme-46"
                      />
                      <Cell
                        :elementi="$FormatDate(doc.execution_date)"
                        class="text-center text-sm border-r-2 border-theme-46"
                      />
                      <Cell
                        :elementi="$FormatDate(doc.richiamo)"
                        class="text-center text-sm border-r-2 border-theme-46"
                      />
                      <Cell
                        :elementi="
                          doc.customer
                            ? doc.customer.first_name +
                              ' ' +
                              doc.customer.last_name
                            : ''
                        "
                        class="text-center text-sm border-r-2 border-theme-46"
                      />

                      <Cell
                        v-if="!selectedPatient"
                        :elementi="doc.pet ? doc.pet.name : ''"
                        class="text-center text-sm border-r-2 border-theme-46"
                      />
                      <td
                        class="border-b border-l whitespace-nowrap text-center cursor-default py-3"
                      >
                        {{
                          doc.pet && doc.pet.specie ? doc.pet.specie.name : ''
                        }}
                      </td>
                      <td
                        class="border-b border-l whitespace-nowrap text-center cursor-default py-3"
                      >
                        {{ doc.vaccine ? doc.vaccine.name : '' }}
                        <p v-if="doc.status == 'imported'" class="text-xs">
                          importato
                        </p>
                      </td>
                      <td
                        v-if="!hideActions"
                        class="border-b border-l whitespace-nowrap text-center cursor-default py-3"
                      >
                        <div
                          class="flex gap-4 items-center text-center justify-center"
                        >
                          <a
                            class="tooltipbutton hover:text-theme-41 cursor-pointer"
                            @click="$emit('modifyVaccine', doc)"
                          >
                            <i class="fa fa-cog text-base"></i>
                            <span
                              class="tooltipbuttontext"
                              style="width: 80px; margin-left: 10px"
                              >Modifica</span
                            >
                          </a>

                          <a
                            class="hover:text-theme-41 cursor-pointer"
                            @click="$emit('deleteRecord', doc, active_filters)"
                          >
                            <i class="fas fa-times text-base" />
                          </a>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <!-- END: Data List -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Documents-->
</template>
<script>
import { defineComponent, toRef, computed, ref, toRaw } from 'vue'
import Paginator from '../../components/paginator/Main.vue'
import axios from '../../gateway/backend-api'
import Cell from '../../components/cvit-table/Cell.vue'
import THeader from '../../components/cvit-table/THeader.vue'

export default defineComponent({
  components: {
    Paginator,
    THeader,
    Cell
  },
  props: [
    'tableConfigClinicDoc',
    'documents',
    'totalRecords',
    'pagination',
    'forPageOption',
    'selectedPatient',
    'hideActions'
  ],
  emits: ['handleSendMessage', 'deleteRecord', 'modifyVaccine', 'getList'],
  setup(props, { emit }) {
    const pagination = toRef(props, 'pagination')

    // variabili filtri
    const active_filters = ref({})
    const table_filter = ref([])
    const filterActive = ref(false)

    //  cambio elementi per pagina, e ritorno nella prima pagina
    const changePagination = (value) => {
      if (pagination.value.page != value.page) {
        pagination.value.page = value.page
        pagination.value.forPage = value.forPage
        emit('getList', toRaw(active_filters.value))
      }
    }

    const changeNumPages = (elem) => {
      if (pagination.value.forPage != elem.target.value) {
        pagination.value.page = 1
        pagination.value.forPage = elem.target.value
        emit('getList', toRaw(active_filters.value))
      }
    }

    // ----- COMPUTED ----- //
    const table_config = computed(function () {
      // configurazione tabella, filtrando i dati del paziente nel caso in cui siamo nella sezione anagrafica paziente
      return props.tableConfigClinicDoc.filter(
        (elem) =>
          !(elem.key === 'patient' && props.selectedPatient) &&
          !(elem.key === 'buttons' && props.hideActions)
      )
    })

    // ----- FINE COMPUTED ----- //

    return {
      table_config,
      changePagination,
      changeNumPages,

      active_filters,
      table_filter,
      filterActive
    }
  },
  watch: {
    'table_filter.state': function (elem) {
      this.filterSelect(elem, 'state')
    },
    'table_filter.species': function (elem) {
      this.filterSelect(elem, 'species')
    }
  },
  mounted() {
    //CALL SELECT VALUE GETTER
    axios
      .get('/' + localStorage.getItem('clinic') + '/pet/types_breeds')
      .then((res) => {
        this.table_config[5].option = res.data
      })
  },
  methods: {
    // ----- METODI GESTIONE FILTRI ----- //
    // Metodo generico per handler filtro select
    filterSelect(elem, field) {
      if (elem) {
        this.active_filters[field] = elem.id ? elem.id : elem.value
      } else this.active_filters[field] = undefined

      this.checkIfFilterActive()

      if (this.filterActive) this.$emit('getList', toRaw(this.active_filters))
    },
    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()

      if (this.filterActive) this.$emit('getList', toRaw(this.active_filters))
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    }
    // ----- FINE METODI GESTIONE FILTRI ----- //
  }
})
</script>
