import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import globalComponents from './global-components'
import utils from './utils'
import globalTimer from './global-timer'
import './libs'
import Toast from 'vue-toastification'

// Import the CSS or use your own!
import 'vue-toastification/dist/index.css'
import 'vue3-colorpicker/style.css'

// SASS Theme
import './assets/sass/app.scss'

const app = createApp(App).use(store).use(router).use(Toast)
globalComponents(app)
utils(app)
globalTimer(app)
app.mount('#app')
