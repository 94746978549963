<template>
  <!--<div
    v-if="tableConfigClinicDoc && documents"
    class="flex w-full justify-between py-2 my-2"
  >
   <div class="prova mr-5 my-2 flex justify-between">
      <div class="mt-1">
        <span class="flex-row text-md ml-2 font-bold mr-2 mt-1">Mostra </span>

        <select
          class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"

        >
          <option v-for="show in forPageOption" :key="show" :value="show">
            {{ show }}
          </option>
        </select>
        <span class="text-md font-bold ml-2 mt-1"> elementi</span>
      </div>
    </div> -->
  <!-- <Paginator
      v-if="totalRecords > pagination.forPage"
      :for-page-option="pagination.forPage"
      :total-records="totalRecords"
      @changePagination="(event) => changePagination(event)"
    />
  </div>-->

  <!-- BEGIN: Data List -->
  <div class="table-container overflow-scroll scrollbar-none pb-3">
    <table v-if="tableConfigClinicDoc && documents" class="w-full">
      <thead class="t-header h-8">
        <tr>
          <th v-for="(item, itemKey) in tableConfigClinicDoc" :key="itemKey">
            {{ item.title }}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody class="mt-5">
        <tr class="">
          <td
            v-for="(elem, index) in tableConfigClinicDoc"
            :key="index"
            :style="elem.style ?? ''"
            class="text-center px-1 py-1.5"
          >
            <select
              v-if="elem.type === 'select'"
              v-model="elem.value"
              class="w-full border rounded-md h-7 text-gray-500"
            >
              <option selected value="">{{ elem.title }}</option>
              <option v-for="(item, i) in elem.option" :key="i" :value="item">
                {{ item }}
              </option>
            </select>
            <input
              v-else
              v-model="elem.value"
              :type="elem.type"
              min="0"
              :name="elem.title"
              class="form-control h-7"
              :class="{
                invisible: elem.type === 'none',
                'p-0': elem.type === 'date'
              }"
              :placeholder="elem.title"
            />
          </td>
        </tr>
        <tr
          v-for="(doc, docKey, index) in newDoc"
          :key="docKey"
          class="h-7 text-xs font-normal text-center hover:bg-theme-45 border-r border-l last:border-b border-theme-2"
          :class="{ 'bg-theme-2': index % 2 === 0 }"
        >
          <td class="">{{ doc.category_name }}</td>

          <td class="">
            {{ doc.alias && doc.alias !== '' ? doc.alias : doc.description }}
          </td>
          <td
            v-if="!noPrice"
            class="text-center align-center justify-center text-xs border-r border-theme-46"
          >
            <button
              v-if="!noPrice"
              type="button"
              :class="[
                havePrice(doc)
                  ? 'orange-btn hover:bg-theme-41 hover:text-white'
                  : 'gray-btn-inverted'
              ]"
              @click="
                $emit(
                  'openPriceModal',
                  doc,
                  docKey,
                  true,
                  discount,
                  greatest,
                  tagliaSelectedLabel
                )
              "
            >
              <span> Visualizza prezzi</span>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="documents && documents.length === 0"
      class="w-full flex justify-center mt-2"
    >
      Nessun Risultato
    </div>
  </div>
  <!-- END: Data List -->
</template>

<script>
import { defineComponent, ref, toRef } from 'vue'

export default defineComponent({
  components: {},
  props: [
    'documents',
    'totalRecords',
    'pagination',
    'forPageOption',
    'selectedCategoria',
    'aliasToSave',
    'selectedNew',
    'tagliaSelected',
    'discount',
    'greatest',
    'tagliaSelectedLabel',
    'noPriceProp'
  ],
  emits: [
    'getNomenclatore',
    'changePagination',
    'getAliasData',
    'changeNumPages',
    'addElement',
    'removeElement',
    'openPriceModal'
  ],

  setup(props, { emit }) {
    // variabili paginazione
    const newDoc = toRef(props, 'documents')

    const noPrice = toRef(props, 'noPriceProp')

    const tableConfigClinicDoc = ref([
      {
        key: 'categoria',
        title: 'Categoria',
        type: 'none',
        style: 'display: none'
      },

      {
        key: 'alias',
        title: 'Alias',
        type: 'none',
        style: 'display: none'
      }
    ])

    // Metodo per eliminare un elemento dalla lista

    //  cambio elementi per pagina, e ritorno nella prima pagina
    const changePagination = (value) => {
      emit('changePagination', value)
    }

    // metodo che cambia il numero della pagina
    const changeNumPages = (elem) => {
      emit('changeNumPages', elem.target.value)
    }

    const addAlias = (doc) => {
      emit('addAlias', doc)
    }

    const handleSwitch = (doc, event) => {
      console.log(event.target.value)
    }

    const havePrice = (doc) => {
      let res = false

      Object.keys(doc.price).forEach((t) => {
        Object.keys(doc.price[t]).forEach((el) => {
          if (
            doc.price[t][el].data &&
            doc.price[t][el].id_tariffario_taglie === props.tagliaSelected
          )
            res = true
        })
      })

      return res
    }

    // ----- FINE COMPUTED ----- //

    return {
      changePagination,
      changeNumPages,
      tableConfigClinicDoc,
      newDoc,
      addAlias,
      handleSwitch,
      havePrice,
      noPrice
    }
  },
  watch: {
    // documents: function () {
    //   if (this.selectedNew) {
    //     this.newDoc = this.newDoc.map((elem) => {
    //       if (this.selectedNew.includes(elem.id)) {
    //         elem.send = true
    //       } else {
    //         elem.send = false
    //       }
    //       return elem
    //     })
    //   }
    // }
  }
})
</script>
