<template>
  <div>
    <div
      id="confirm-delete-modal-fatturazione"
      class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50"
    >
      <div
        class="relative top-48 p-2 mx-auto overflow-scroll scrollbar-none border w-4/5 md:w-3/5 lg:w-2/5 shadow-lg rounded-md bg-white"
      >
        <div class="flex flex-col content-end overflow-y-auto">
          <button type="button" class="closing-btn" @click="handleClose">
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
          </button>

          <div class="flex justify-center p-5 rounded-t">
            <h3 id="modal-title" class="modal-title w-full mt-5">
              Aggiungi Acconto
            </h3>
          </div>

          <div
            class="mx-6 flex flex-col gap-1 md:flex-row md:items-baseline md:gap-4"
          >
            <p class="form-label text-left">Inserisci prezzo finale</p>
            <div class="flex-1 flex items-center justtify-between gap-5">
              <div class="relative flex-1">
                <input
                  id="selectTariffa"
                  v-model="valore"
                  type="number"
                  min="0"
                  placeholder="0"
                  class="custom-input pl-6"
                />
                <p class="absolute top-1.5 left-2">€</p>
              </div>
              <div class="flex-1">
                <input
                  :value="
                    Math.round((valore / ivaPerc + Number.EPSILON) * 100) / 100
                  "
                  type="number"
                  min="0"
                  class="custom-input disabled"
                  disabled
                />
              </div>
            </div>
          </div>

          <div class="flex items-center justify-end gap-3 px-6 py-3 mt-2">
            <button
              id="dialogbox-delete-button"
              class="orange-btn"
              type="button"
              @click="aggiungiServizio"
            >
              Aggiungi
            </button>

            <button
              id="dialogbox-anulla-button"
              class="gray-btn"
              type="button"
              autofocus
              @click="handleClose"
            >
              Annulla
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  emits: ['remove', 'close', 'addRow'],
  data() {
    return {
      valore: null
    }
  },
  computed: {
    ...mapGetters({
      ivaPerc: 'reusableData/getRegimeFiscaleIvaPerc'
    })
  },

  methods: {
    aggiungiServizio: function () {
      if (this.valore) {
        this.$emit('addRow', this.valore / this.ivaPerc)
      } else {
        this.$store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire un valore prima di continuare!'
        })
      }
    },
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>

<style></style>
