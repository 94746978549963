<template>
  <!-- BEGIN: Responsive Table -->
  <div
    v-if="permissions && permissions?.pet?.view_pet_color?.enabled === true"
    class="px-6"
  >
    <table
      class="w-full shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
    >
      <THeader
        v-for="(item, itemKey) in tableData.config"
        :key="itemKey"
        :name="item.title"
        class="t-header"
      />
      <tbody>
        <tr
          v-for="type in types"
          :key="type.id"
          class="bg-theme-2 text-theme-43 odd:bg-white"
        >
          <td class="whitespace-nowrap text-center px-3 bg-theme-44">
            <p class="text-base font-bold">{{ type.name }}</p>
          </td>
          <td class="hover:bg-theme-45 row-custom">
            <div class="grid grid-cols-6 mx-2 p-3">
              <div
                v-for="color in type.color"
                :key="color.id"
                class="col-span-1"
              >
                <span class="text-theme-41">• </span> {{ color.name }}
                <button v-if="color.deletable" @click="deleteRow(color.id_row)">
                  <i
                    class="fas fa-times cursor-pointer pl-1 text-theme-41 fill-current text-xxs"
                  />
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="
        !showCustom &&
        permissions &&
        permissions?.pet?.store_pet_color?.enabled === true
      "
      class="text-right my-5"
    >
      <button class="orange-btn px-2 py-1" @click="handleShowCustom">
        <PlusIcon class="h-5 w-5 mr-2" /> Aggiungi abbinamento colorazione
      </button>
    </div>
    <div v-if="showCustom">
      <NewColorModal @close="updateColors" />
    </div>
  </div>
  <!-- END: Responsive Table -->
</template>
<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import THeader from '../../../components/cvit-table/THeader.vue'
import axios from '../../../gateway/backend-api'
import NewColorModal from './NewColorModal.vue'

export default defineComponent({
  components: {
    THeader,
    NewColorModal
  },
  data() {
    return {
      showCustom: false,
      types: [],
      petTypes: [],
      tableData: {
        config: [
          {
            key: 'type',
            title: 'Specie'
          },
          {
            key: 'color',
            title: 'Colore'
          }
        ],
        input: [
          {
            key: 'type',
            title: 'Specie',
            type: 'text'
          },
          {
            key: 'color',
            title: 'Colore',
            type: 'text'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions'
    })
  },
  mounted() {
    this.getColors()
  },

  methods: {
    handleShowCustom() {
      this.showCustom = !this.showCustom
    },
    getColors() {
      axios
        .post('/' + localStorage.getItem('clinic') + '/pet/pet-colors')
        .then((res) => {
          this.types = res.data
        })
    },
    updateColors() {
      this.showCustom = false
      this.getColors()
    },
    deleteRow(id) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'Cancella colore',
        subtitle: 'Sei sicuro di voler cancellare questo abbinamento?',
        background: false,
        confirm: () => {
          axios
            .delete(
              '/' + localStorage.getItem('clinic') + '/pet/' + id + '/delete'
            )
            .then((res) => {
              if (res.data.status) {
                this.$store.dispatch('commonComponents/openNotificationModal', {
                  message: res.data.msg
                })
              }
              this.getColors()
            })
        },
        decline: () => {
          return false
        }
      })
    }
  }
})
</script>
