<template>
  <div
    class="bg-white rounded-lg px-6 py-2 content-container overflow-auto scrollbar_custom"
  >
    <VetslabTable />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import VetslabTable from '../../components/vetslab/VetslabTable.vue'
export default defineComponent({
  components: {
    VetslabTable
  },
  data() {
    return {
      showVetslabModal: false,
      clientInvoice: null,
      petInvoice: null
    }
  }
})
</script>
