<template>
  <!-- Main modal -->
  <div
    id="defaultModal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-10 mt-1 mx-auto px-3 pt-3 pb-7 border w-3/4 md:w-4/6 shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="mb-9">
        <button type="button" class="closing-btn" @click="$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <!-- Modal header -->

      <div class="my-2 mx-2">
        <h3 class="modal-title">Nuova Ricetta Libera</h3>
      </div>
      <!-- Modal body -->

      <div class="flex justify-end items-center px-2 pt-2 mx-2">
        <label class="form-label font-medium text-base text-right"
          >Data:
        </label>
        <p class="form-label font-medium text-gray-600 ml-2">
          {{ now }}
        </p>
      </div>

      <div class="px-2 py-1">
        <div class="flex flex-col mb-1">
          <!-- SELEZIONARE UTENTE nuevo  -->
          <div class="flex flex-col md:flex-row gap-6 mb-6">
            <div v-if="!clientInvoice" class="flex-1 p-3">
              <label class="font-semibold text-theme-43">
                SELEZIONARE UN UTENTE
              </label>

              <div class="mt-3 relative">
                <div class="">
                  <input
                    v-model="searchBarClient"
                    type="text"
                    class="custom-input pl-10 rounded-xl py-2 z-10"
                    placeholder="Ricerca rapida nell'anagrafica"
                    @click.stop="dropDownSearchClient"
                  />
                  <svg
                    class="text-theme-44 fill-current w-6 absolute top-1 left-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 54.3 54.4"
                    @click="toggleModal"
                  >
                    <path
                      d="M16.4 4.7c9.2-7 22.3-6.1 30.8 2.1 8.4 8.1 9.4 21.1 2.4 31.1-5.5 7.9-19.5 14.2-33.5 4.9-3.2 3.2-6.5 6.5-9.7 9.8-2 2-3.8 2.3-5.4.8-1.6-1.5-1.4-3.4.7-5.6.3-.4.7-.7 1.1-1.1 2.9-2.9 5.9-5.8 8.7-8.6-8.7-12.9-4.1-26.5 4.9-33.4Zm31.2 19c0-9.2-7.8-17-17.2-17-9.5 0-17 7.7-17 17.3 0 9.4 7.6 16.9 17.2 16.9 9.5 0 17-7.6 17.1-17.3Z"
                    />
                  </svg>
                </div>
                <SearchDrop
                  v-show="isActiveSearchClient === true"
                  class="z-50 search-result bg-theme-44 bg-opacity-80 overflow-scroll scrollbar-none rounded-xl absolute invisible opacity-0 mt-4 ml-3 py-4 px-3 right-0 w-4/5 top-6"
                  :filter-categories="['customer']"
                  :pet-invoice="petInvoice ? petInvoice : pet"
                  :filter="searchBarClient"
                  @closeDropDownSearch="isActiveSearchClient = false"
                  @childToParent="onChildClickClient"
                />
              </div>
            </div>

            <div
              v-if="clientInvoice"
              class="flex-1 px-1 py-2 border-2 border-theme-44 border-opacity-10 shadow-md rounded-md"
            >
              <div
                class="bg-theme-2 rounded-md p-1 flex justify-between items-center mb-2"
              >
                <h2 class="font-bold nunito text-lg pl-1">Dati Propietario</h2>
                <a
                  class="cursor-pointer text-theme-41 fill-current"
                  @click="removeSelectedClient"
                >
                  <i class="fas fa-times text-center p-3" />
                </a>
              </div>
              <div class="flex flex-col ml-5">
                <div>
                  <label class="form-label font-medium text-base col-span-1"
                    >Cliente:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 ml-2 col-span-2"
                  >
                    {{
                      clientInvoice.first_name + ' ' + clientInvoice.last_name
                    }}
                  </p>
                </div>
                <div>
                  <label class="form-label font-medium text-base col-span-1"
                    >Indirizzo:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 ml-2 col-span-2"
                  >
                    {{
                      clientInvoice.address.address +
                      ' (' +
                      clientInvoice.address.district +
                      ')'
                    }}
                  </p>
                </div>
                <div>
                  <label class="form-label font-medium text-base col-span-1"
                    >Email:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 ml-2 col-span-2"
                  >
                    {{
                      clientInvoice.emails
                        ? clientInvoice.emails.email1.email
                        : '--'
                    }}
                  </p>
                </div>
                <div>
                  <label class="form-label font-medium text-base col-span-1"
                    >Codice Fiscale:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 ml-2 col-span-2"
                  >
                    {{ clientInvoice.fiscal_code }}
                  </p>
                </div>
              </div>
            </div>

            <div
              v-if="!selected"
              id="select-client
            "
              class="flex-1 p-3"
            >
              <label class="font-semibold text-theme-43">
                SELEZIONARE UN CLIENTE
              </label>

              <div class="mt-3 relative">
                <div class="">
                  <input
                    v-model="searchBarPet"
                    type="text"
                    class="custom-input pl-10 rounded-xl py-2 z-10"
                    placeholder="Ricerca rapida nell'anagrafica"
                    @click.stop="dropDownSearchPet"
                  />
                  <svg
                    class="text-theme-44 fill-current w-6 absolute top-1 left-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 54.3 54.4"
                    @click="toggleModal"
                  >
                    <path
                      d="M16.4 4.7c9.2-7 22.3-6.1 30.8 2.1 8.4 8.1 9.4 21.1 2.4 31.1-5.5 7.9-19.5 14.2-33.5 4.9-3.2 3.2-6.5 6.5-9.7 9.8-2 2-3.8 2.3-5.4.8-1.6-1.5-1.4-3.4.7-5.6.3-.4.7-.7 1.1-1.1 2.9-2.9 5.9-5.8 8.7-8.6-8.7-12.9-4.1-26.5 4.9-33.4Zm31.2 19c0-9.2-7.8-17-17.2-17-9.5 0-17 7.7-17 17.3 0 9.4 7.6 16.9 17.2 16.9 9.5 0 17-7.6 17.1-17.3Z"
                    />
                  </svg>
                </div>
                <SearchDrop
                  v-show="isActiveSearchPet === true"
                  class="z-50 search-result bg-theme-44 bg-opacity-80 overflow-scroll scrollbar-none rounded-xl absolute invisible opacity-0 mt-4 ml-3 py-4 px-3 right-0 w-4/5 top-6"
                  :filter-categories="['pet']"
                  :customer-invoice="clientInvoice"
                  :filter="searchBarPet"
                  @childToParent="onChildClickPet"
                />
              </div>
            </div>
            <div
              v-if="selected"
              class="flex-1 px-1 py-2 border-2 border-theme-44 border-opacity-10 shadow-md rounded-md"
            >
              <div
                class="bg-theme-2 rounded-md p-1 flex justify-between items-center mb-2"
              >
                <h2 class="font-bold nunito text-lg pl-1">Dati Pet</h2>
                <a
                  class="cursor-pointer text-theme-41 fill-current"
                  @click="removeSelectedPet"
                >
                  <i class="fas fa-times text-center p-3" />
                </a>
              </div>
              <div class="flex flex-col ml-5">
                <div>
                  <label class="form-label font-medium text-base col-span-1"
                    >Cliente:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 ml-2 col-span-2"
                  >
                    {{ selected.name }}
                  </p>
                </div>

                <div>
                  <label class="form-label font-medium text-base col-span-1"
                    >Indirizzo:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 ml-2 col-span-2"
                  >
                    {{ selected.owners[0].address.address }}
                  </p>
                </div>

                <div>
                  <label class="form-label font-medium text-base col-span-1"
                    >Sesso:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 ml-2 col-span-2"
                  >
                    {{ selected.gender }}
                  </p>
                </div>

                <div>
                  <label class="form-label font-medium text-base col-span-1"
                    >Microchip:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 ml-2 col-span-2"
                  >
                    {{ selected.microchip }}
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!--
CLIENTE VIEJO -->

          <div
            class="flex flex-col items-center justify-center md:flex-row gap-6 mx-2"
          >
            <div class="w-full md:flex-1">
              <label class="form-label font-medium text-base col-span-1"
                >Pr/:
              </label>
              <input
                v-model="pharma"
                type="text"
                class="custom-input rounded-xl py-2 z-10 w-full"
                required
              />
              <!-- <v-select
                ref="input1"
                v-model="pharma"
                class="w-full"
                placeholder="Selezionare il farmaco"
                label="name"
                :options="pharmaOptions"
                :loading="loadingPharma"
                @search="
                  (elem) => {
                    inputChanged(elem)
                  }
                "
              /> -->
            </div>

            <div class="w-full md:flex-1">
              <label class="form-label font-medium text-base col-span-1"
                >S/:
              </label>

              <input
                v-model="dosage"
                type="text"
                class="custom-input rounded-xl py-2 z-10 w-full"
                required
              />
            </div>
            <button
              ref="input4"
              type="button"
              class="orange-btn mr-auto flex-0 md:mt-7"
              :class="!pharma || dosage === '' ? 'disabled' : ''"
              @click="addPharma"
            >
              <PlusCircleIcon class="h-5 w-5 mr-2" /> Inserisci
            </button>
          </div>
        </div>

        <div class="rounded-lg mt-7 mx-2">
          <QuillEditor
            ref="myEditor"
            v-model:content="ricetta"
            content-type="html"
            class="h-40 bg-white border-gray-400"
          />
        </div>
      </div>
      <!-- Modal footer -->
      <div class="mt-5 w-full px-4 text-right flex justify-end items-end">
        <button
          ref="input4"
          type="button"
          class="orange-btn mr-3"
          @click="handleEmetti()"
        >
          <PlusCircleIcon class="h-5 w-5 mr-2" /> Emetti
        </button>
        <button type="button" class="gray-btn" @click="$emit('close')">
          <span class="h-5"></span>
          Annulla
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import { useStore } from '@/store'
import moment from 'moment'
import SearchDrop from '../search-drop/SearchDrop.vue'
import axios from '../../gateway/backend-api'

export default defineComponent({
  components: { SearchDrop },
  props: ['pet'],
  emits: ['close', 'confirmAddNewFreeRecipe'],
  setup(props, { emit }) {
    const store = useStore()

    const ricetta = ref('')
    const pharma = ref('')
    const dosage = ref('')
    const pharmaOptions = ref([])
    const loadingPharma = ref(false)
    const searchBarClient = ref('')
    const searchBarPet = ref('')
    const clientInvoice = ref(null)
    const petInvoice = ref(null)
    const isActiveSearchClient = ref(false)
    const isActiveSearchPet = ref(false)
    const now = moment().format('D/M/YYYY')

    const allegatiConfig = ['Aic', 'Farmaco', 'Nome', 'Deroga', 'Qty', 'Tipo']

    const formatdate = (epoch) => {
      return moment.unix(epoch).format('DD/MM/YYYY')
    }

    const dropDownSearchClient = () => {
      isActiveSearchClient.value = !isActiveSearchClient.value
      isActiveSearchPet.value = false
    }
    const closeDropDownSearchClient = () => {
      isActiveSearchClient.value = false
    }
    const dropDownSearchPet = () => {
      isActiveSearchPet.value = !isActiveSearchPet.value
      isActiveSearchClient.value = false
    }
    const closeDropDownSearchPet = () => {
      isActiveSearchPet.value = false
    }
    const onChildClickClient = (value) => {
      clientInvoice.value = value
      closeDropDownSearchPet()
      closeDropDownSearchClient()
    }
    const onChildClickPet = (value) => {
      petInvoice.value = value
      closeDropDownSearchPet()
      closeDropDownSearchClient()
    }

    const removeSelectedClient = () => {
      clientInvoice.value = null
    }

    const removeSelectedPet = () => {
      petInvoice.value = null
    }

    const inputChanged = (value) => {
      if (value.length > 2) {
        loadingPharma.value = true
        axios.post('/search-pharma').then((resp) => {
          loadingPharma.value = false
          pharmaOptions.value = resp.data
          loadingPharma.value = false
        })
      }
    }

    const handleEmetti = () => {
      if (!clientInvoice.value) {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire il cliente'
        })
        return
      }

      if (!selected.value) {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire il paziente'
        })
        return
      }

      emit(
        'confirmAddNewFreeRecipe',
        ricetta.value,
        clientInvoice.value,
        petInvoice.value
      )
    }

    const selected = computed(() => {
      return props.pet ? props.pet : petInvoice.value
    })

    return {
      ricetta,
      pharma,
      dosage,
      pharmaOptions,
      loadingPharma,
      allegatiConfig,
      searchBarClient,
      searchBarPet,
      clientInvoice,
      petInvoice,
      isActiveSearchClient,
      isActiveSearchPet,
      formatdate,
      now,
      dropDownSearchClient,
      closeDropDownSearchClient,
      dropDownSearchPet,
      closeDropDownSearchPet,
      onChildClickClient,
      onChildClickPet,
      removeSelectedClient,
      removeSelectedPet,
      inputChanged,
      selected,
      handleEmetti
    }
  },
  mounted: function () {
    document.addEventListener('click', this.closeDropDownSearchClient)
    document.addEventListener('click', this.closeDropDownSearchPet)
    if (this.pet && this.pet.owners && this.pet.owners[0]) {
      this.clientInvoice = this.pet.owners[0]
    }
  },
  methods: {
    addPharma() {
      this.ricetta +=
        '<p><strong>Pr/  ' +
        this.pharma +
        '</p><p>S/  </strong>' +
        this.dosage +
        '</p>'

      this.pharma = ''
      this.dosage = ''
      this.pharmaOptions = []
      this.$refs.myEditor.setHTML(this.ricetta)
    }
  }
})
</script>
