import { createStore } from 'vuex'
import main from './main'
import sideMenu from './side-menu'
import axios from '../gateway/backend-api'
import router from '@/router'
import anagraficaPazienti from './modules/anagrafica-pazienti'
import anagraficaClienti from './modules/anagrafica-clienti'
import reusableData from './reusable-data'
import commonComponents from './common-components'

const store = createStore({
  modules: {
    main,
    sideMenu,
    anagraficaPazienti,
    anagraficaClienti,
    reusableData,
    commonComponents
  },
  state: {
    token: localStorage.getItem('userToken') || '',
    status: '',
    user:
      localStorage.getItem('userData') ||
      localStorage.getItem('userName') ||
      'Staff non trovato',
    userId: localStorage.getItem('userId') || '',
    userRoleId: localStorage.getItem('userRoleId') || ''
  },
  getters: {
    isAuthenticated: (state) => !!state.token,
    authStatus: (state) => state.status,
    getUser: (state) => state.user,
    getUserAcceptances: (state) =>
      state.user && state.user ? state.user.acceptances : null,
    getUserRoleId: (state) =>
      state.user?.user?.role?.id ? state.user.role.id : null
  },
  mutations: {
    login: (state) => {
      state.status = 'loading'
    },
    login_success: (state, token) => {
      state.status = 'success'
      state.token = token
    },
    login_error: (state) => {
      state.status = 'error'
    },
    setUserData(state, userData) {
      state.user = userData.user
      state.userId = userData.user.id
      localStorage.setItem('userData', userData.user)
      localStorage.setItem('userId', userData.user.id)
      localStorage.setItem('userName', userData.user.name)
      localStorage.setItem('userId', userData.user.id)
      axios.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
        'userToken'
      )}`
    },

    clearUserData(state) {
      state.token = ''
      localStorage.clear()
    }
  },
  actions: {
    async login({ commit, dispatch }, credentials) {
      await axios
        .post('/auth/login', {
          login: credentials.username,
          password: credentials.password
        })
        .then((resp) => {
          const token = resp.data.user.access_token
          const id = resp.data.user.id
          const userRoleId = resp.data.user.role.id
          const forceRefresh = resp.data.user.force_refresh

          localStorage.setItem('userToken', token) // store the token in localstorage
          localStorage.setItem('id', id)
          localStorage.setItem('userRoleId', userRoleId)
          localStorage.setItem('forceRefresh', forceRefresh)
          if (token && resp.data && resp.data.user) {
            // if there are no permissions assigned, without this control a strange behavior occur
            commit('login_success', token)
            commit('setUserData', resp.data)
            if (resp.data.user.permissions) {
              commit(
                'sideMenu/getMenu',
                JSON.stringify(resp.data.user.permissions)
              )
            }

            if (Object.keys(resp.data.user.clinic).length > 1) {
              router.push({
                path: '/scegli-clinica'
              })
            } else {
              if (
                resp.data.user.clinic &&
                Object.keys(resp.data.user.clinic).length > 0
              ) {
                localStorage.setItem(
                  'clinic',
                  Object.keys(resp.data.user.clinic)[0]
                )
                localStorage.setItem(
                  'clinic-name',
                  resp.data.user.clinic[Object.keys(resp.data.user.clinic)[0]]
                    .name
                )

                localStorage.setItem(
                  'clinic-affiliate',
                  resp.data.user.clinic[Object.keys(resp.data.user.clinic)[0]]
                    .affiliate_at
                )
                localStorage.setItem(
                  'clinic-sign-acceptance',
                  resp.data.user.clinic[Object.keys(resp.data.user.clinic)[0]]
                    .sign_acceptances
                )
                // qua devo prendere dalla clinica se mostrare licenze o no
                store.dispatch('reusableData/getClinicaSetup')
                router.push('/')

                //Numero Notifiche
                store.dispatch('reusableData/getTotalBell')
              } else {
                this.dispatch('commonComponents/openNotificationModal', {
                  message: "Non vi è nessuna clinica associata all'utente"
                })
              }
            }

            dispatch('checkPasswordModal', resp.data.user)
          } else {
            router.push({ name: 'error' })
          }
        })
        .catch(() => {
          localStorage.clear() // if the request fails, remove any possible user token if possible
          router.push({ name: 'error' })
        })
    },
    async setMenu({ commit }, menu) {
      commit('sideMenu/getMenu', JSON.stringify(menu))
      return true
    },

    async recoverPassword(_, credentials) {
      await axios
        .post('/forgot-password', {
          email: credentials.email
        })
        .then((response) => {
          if (response.data == false) {
            console.log('Errore')
          } else {
            router.push({ name: 'resetpage' })
          }
        })
    },

    async changePassword(_, credentials) {
      await axios
        .post('/reset-password', {
          token: credentials.token,
          email: credentials.email,
          password: credentials.password,
          password_confirmation: credentials.password_confirmation
        })
        .then((response) => {
          if (response.data == false) {
            console.log('Errore')
          } else {
            router.push({ name: 'login' })
          }
        })
    },

    async changeClinic({ state, commit }) {
      await axios
        .get('/clinic/' + localStorage.getItem('clinic') + '/user')
        .then((res) => {
          commit('setUserData', { user: { ...state.user, clinic: res.data } })
          router.push({ name: 'scegli-clinica' })
        })
    },

    async goToLogin() {
      router.push({ name: 'login' })
    },

    async logout({ commit }) {
      await axios.get('/auth/logout').then((resp) => {
        console.log(resp)
      })

      commit('clearUserData')
      location.reload()
    },
    async getUserData({ commit, dispatch, state }) {
      dispatch('commonComponents/showLoadingModalAction')
      if (typeof state.userId != 'undefined') {
        await axios
          .get('/' + localStorage.getItem('clinic') + '/staff/' + state.userId)
          .then((res) => {
            dispatch('commonComponents/hideLoadingModalAction')
            dispatch('checkPasswordModal', res.data)
            commit('setUserData', { user: res.data })
          })
          .catch(() => {
            dispatch('commonComponents/hideLoadingModalAction')
            return false
          })
      }
    },
    async checkPasswordModal({ dispatch }, user) {
      let modal_password_params = {
        openPassword: true,
        openConsensi: true,
        openLicenza: false
      }

      if (user.acceptances) {
        if (user.acceptances.password_change) {
          modal_password_params.openPassword = false
        }

        if (user.acceptances.consens_signed_at) {
          modal_password_params.openConsensi = false
        }
      }

      if (
        JSON.parse(localStorage.getItem('clinic-sign-acceptance')) === true //todo localStorage empty in questo momento
      ) {
        modal_password_params.openLicenza = true
      }

      if (
        modal_password_params.openConsensi ||
        modal_password_params.openLicenza ||
        modal_password_params.openPassword
      )
        dispatch('commonComponents/openPasswordModal', modal_password_params)
    }
  }
})

export function useStore() {
  return store
}

export default store
