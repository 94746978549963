<template>
  <div
    class="tab-content overflow-hidden scrollbar-none relative bg-white px-6 py-2"
  >
    <!--BEGIN: invoice list-->
    <div
      id="invoices"
      class="tab-pane active"
      role="tabpanel"
      aria-labelledby="invoices_mobile-tab"
    >
      <!-- Show elements and global search-->
      <div class="paginator-container">
        <div class="flex items">
          <div class="mr-5">
            <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
              >Mostra
            </span>
            <select
              v-model="pagination.forPage"
              class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
              @change="(event) => changeNumPages(event)"
            >
              <option
                v-for="show in forPageOption"
                :key="show"
                :value="show"
                class="form-select"
              >
                {{ show }}
              </option>
            </select>
            <span class="text-md font-bold ml-2 mt-1"> elementi</span>
          </div>

          <input
            v-model="ricerca"
            type="text"
            placeholder="Cerca"
            class="pl-3 pr-10 py-1 border-2 border-gray-200 rounded-full focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500"
          />
        </div>
        <Paginator
          :for-page-option="pagination.forPage"
          :total-records="totalRecords"
          @changePagination="changePagination"
        />
      </div>
      <!-- BEGIN: Data List -->
      <div
        class="table-container fatt-att-cont scrollbar-thin scrollbar-thumb-theme-44 scrollbar-track-theme-2 overflow-scroll"
      >
        <table class="w-full shadow custom-border rounded-lg">
          <thead class="h-10 t-header">
            <tr>
              <th
                v-for="(item, itemKey) in tableConfig_invoices"
                :key="itemKey"
                :name="item.title"
              >
                {{ item.title }}
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody class="overflow-scroll scrollbar-none">
            <tr class="">
              <td
                v-for="(elem, index) in tableConfig_invoices"
                :key="index"
                class="text-center pr-3 pb-3"
              >
                <div
                  v-if="elem.title === 'Data invio'"
                  class="flex-col text-left"
                >
                  <div class="flex items-baseline">
                    <span class="mr-2 font-bold">Da: </span
                    ><input
                      v-model="startDate"
                      class="form-control h-7 mt-2 p-0 text-center border-theme-45"
                      type="date"
                      @change="filterHandler(elem)"
                    />
                  </div>
                  <div class="flex items-baseline">
                    <span class="mr-4 font-bold">A: </span
                    ><input
                      v-model="endDate"
                      class="form-control h-7 mt-2 p-0 text-center border-theme-45"
                      type="date"
                      @change="filterHandler(elem)"
                    />
                  </div>
                </div>
                <select
                  v-if="elem.type === 'select'"
                  v-model="elem.value"
                  class="w-full border rounded-md h-7 text-gray-500 mt-2"
                  @change="filterHandler(elem)"
                >
                  <option selected value=""></option>
                  <option
                    v-for="(item, i) in elem.option"
                    :key="i"
                    :value="item.id ? item.id : item"
                  >
                    {{ item.name ? item.name : item }}
                  </option>
                </select>
                <input
                  v-else
                  v-model="elem.value"
                  :type="elem.type"
                  :style="elem.style"
                  min="0"
                  :name="elem.title"
                  class="form-control h-7 mt-2 text-left border-theme-45 px-0"
                  :class="{
                    invisible: elem.type === 'none',
                    'p-0': elem.type === 'date'
                  }"
                  :placeholder="elem.title"
                  @change="filterHandler(elem)"
                />
              </td>
            </tr>
            <tr
              v-for="(n, index) in invoices"
              :key="index"
              class="h-12 text-xs font-normal hover:bg-theme-44 cursor-pointer"
              :class="{ 'bg-theme-2': index % 2 === 0 }"
            >
              <Cell
                class="text-center border-r border-theme-45"
                :elementi="$FormatDateMin(n.datetime)"
                @dblclick="handleDblClick_invoice()"
                @click="$emit('showInvoice', n)"
              />
              <Cell
                class="text-center border-r border-theme-45"
                :elementi="$FormatDateMin(n.data_documento)"
                @dblclick="handleDblClick_invoice()"
                @click="$emit('showInvoice', n)"
              />
              <td
                class="text-center border-r border-theme-45 text-sm"
                @dblclick="handleDblClick_invoice()"
                @click="$emit('showInvoice', n)"
              >
                <b>{{
                  n.clinic_sectional && n.clinic_sectional.n_doc
                    ? n.clinic_sectional.n_doc
                    : n.n_bill
                }}</b>
              </td>
              <Cell
                :elementi="
                  n.customer
                    ? (n.customer.ragione_sociale
                        ? n.customer.ragione_sociale
                        : '') +
                      ' ' +
                      n.customer.first_name +
                      ' ' +
                      n.customer.last_name
                    : ''
                "
                class="text-center border-r border-theme-45 px-2"
                @dblclick="handleDblClick_invoice()"
                @click="$emit('showInvoice', n)"
              />
              <Cell
                :elementi="n.bill_type ? n.bill_type.description : ''"
                class="text-center border-r border-theme-45"
                @dblclick="handleDblClick_invoice()"
                @click="$emit('showInvoice', n)"
              />
              <Cell
                :elementi="
                  n.total
                    ? Math.round((n.total + Number.EPSILON) * 100) / 100 + '€'
                    : ''
                "
                class="text-center border-r border-theme-45"
                @dblclick="handleDblClick_invoice()"
                @click="$emit('showInvoice', n)"
              />
              <td>
                <div class="flex justify-center">
                  <input type="checkbox" class="form-check-switch" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <ConfirmModal
          v-if="showModal"
          @remove="removeRow"
          @close="showModal = false"
        />

        <InvoiceStatusModal
          v-if="showStatusModal"
          :notifications="notifications"
          @close="showStatusModal = false"
        />
      </div>
      <!-- END: Data List -->
    </div>
    <!--END: invoice list-->
  </div>
</template>
<script>
const forPageOption = [5, 10, 50]

import Paginator from '../paginator/Main.vue'
import Cell from '../../components/cvit-table/Cell.vue'
import axios from '../../gateway/backend-api'
import ConfirmModal from '../invoice-table/ConfirmModal.vue'
import InvoiceStatusModal from '../modals/InvoiceStatusModal.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    Paginator,
    Cell,
    ConfirmModal,
    InvoiceStatusModal
  },
  emits: ['showInvoice', 'handleSavePdfRev', 'handleNotaDiCredito'],
  data() {
    return {
      notifications: null,
      showStatusModal: false,
      showModal: false,

      totalRecords: '',
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[1]
      },
      filterActive: false,
      ricerca: '',
      startDate: null,
      endDate: null,
      active_filters: {},

      invoices: [],
      tableConfig_invoices: [
        {
          key: 'sendingDate',
          title: 'Data invio',
          type: 'none',
          style: 'display: none'
        },
        {
          key: 'document_date',
          title: 'Data Documento',
          type: 'date'
        },
        {
          key: 'n_bill',
          title: 'Numero',
          type: 'text'
        },
        {
          key: 'customer',
          title: 'Cliente',
          type: 'text'
        },
        {
          key: 'bill_type_id',
          title: 'Tipo documento',
          type: 'select',
          option: []
        },
        {
          key: 'price',
          title: 'Totale con IVA',
          type: 'text'
        },
        {
          key: 'status_doc',
          title: 'Stato Fatturazione',
          type: 'select',
          option: []
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions',
      invoiceTypes: 'reusableData/getInvoiceTypes',
      invoiceStatus: 'reusableData/getInvoiceStatus'
    })
  },
  mounted() {
    this.getBills()
    this.getInvoiceTypes()
    this.getInvoiceStatus()
  },
  methods: {
    getInvoiceTypes() {
      this.invoiceTypes.map((elem) => {
        this.tableConfig_invoices[4].option.push({
          name: elem.description,
          id: elem.id
        })
      })
    },
    getInvoiceStatus() {
      this.invoiceStatus.map((elem) => {
        this.tableConfig_invoices[6].option.push(elem)
      })
    },
    getBills() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/bills/index-bills', {
          customer_id: this.customer ? this.customer.id : null,
          active_filters: this.active_filters,
          search: this.ricerca,
          sent_from: this.startDate,
          sent_to: this.endDate,
          iva: true,
          page: this.pagination.page,
          per_page: this.pagination.forPage
        })
        .then((res) => {
          this.invoices = res.data.data
          this.totalRecords = res.data.total

          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()

      if (this.filterActive) this.getBills()
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    },
    changePagination: function (value) {
      if (this.pagination.page != value.page) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        this.getBills()
      }
    },
    changeNumPages: function (elem) {
      this.pagination.page = 1
      this.pagination.forPage = elem.target.value
      this.getBills()
    }
  }
}
</script>
