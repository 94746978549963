<template>
  <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
</template>

<script>
import { GChart } from 'vue-google-charts'

import { defineComponent, computed } from 'vue'

export default defineComponent({
  name: 'GoogleChart',
  components: {
    GChart
  },
  props: {
    values: {
      type: Array,
      default() {
        return []
      }
    },
    labels: {
      type: Array,
      default() {
        return ['#1', '#2', '#3']
      }
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  setup(props) {
    const chartData = computed(() => {
      let value = []
      if (props.values) {
        let tempLabel = []
        for (let count = 0; count < props.labels.length; count++) {
          tempLabel.push(props.labels[count])
        }
        value.push(tempLabel)
        for (let i = 0; i < props.values.length; i++) {
          let tempData = []
          for (let y = 0; y < Object.keys(props.values[i]).length; y++) {
            tempData.push(props.values[i][Object.keys(props.values[i])[y]])
          }
          value.push(tempData)
        }
      }
      return value
    })

    const chartOptions = computed(() => {
      return props.options
    })

    return {
      chartData,
      chartOptions
    }
  },
  data() {
    return {
      /*
      data: [
        ['Year', 'Sales', 'Expenses', 'Profit'],
        ['2014', 1000, 400, 200],
        ['2015', 1170, 460, 250],
        ['2016', 660, 1120, 300],
        ['2017', 1030, 540, 350]
      ],
      options: {
        chart: {
          title: 'Company Performance',
          subtitle: 'Sales, Expenses, and Profit: 2014-2017'
        },
        width: 800,
        height: 600
      } */
    }
  }
})
</script>
