<template>
  <!-- BEGIN: Responsive Table -->
  <div class="table-container">
    <PreventiviTable
      v-if="docs && tableConfig_clinic_doc"
      :table-config-clinic-doc="tableConfig_clinic_doc"
      :docs="docs"
      :pagination="pagination"
      :for-page-option="forPageOption"
      :total-records="totalRecords"
      :client="customerDetails"
      :selected-patient="true"
      :global="true"
      @getBills="getBills"
      @showPdf="handleShowPdf"
      @filter-handler="filterHandler"
    />
  </div>
  <!-- END: Responsive Table -->
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import axios from '../../gateway/backend-api'
import PreventiviTable from '../../components/tables/PreventiviTable.vue'

const forPageOption = [5, 10, 20, 50]
export default defineComponent({
  components: {
    PreventiviTable
  },
  props: {},
  emits: [],
  data() {
    return {
      tableConfig_clinic_doc: [
        {
          key: 'number',
          title: 'Numero',
          type: 'text'
        },
        {
          key: 'datetime',
          title: 'Data',
          type: 'date'
        },
        {
          key: 'customer_id',
          title: 'Cliente',
          type: 'text'
        },
        {
          key: 'buttons',
          title: 'Azioni rapide',
          type: 'none'
        }
      ],
      elemFromPrev: {},
      invoiceModalOpen: false,
      confirmOpen: false,
      confirmString: '',
      docs: null,
      totalRecords: '',
      active_filters: {},
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      services: [],
      tipoDocumento: 'preventivo',
      startDateDocument: null,
      endDateDocument: null
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions',
      user: 'getUser',
      iva: 'reusableData/getShowIva',
      ivaPerc: 'reusableData/getRegimeFiscaleIvaPerc'
    })
  },
  watch: {
    'pagination.page': function (elem) {
      if (elem) this.getBills()
    },
    'pagination.forPage': function (elem) {
      if (elem) this.getBills()
    }
  },
  mounted() {
    this.getBills()
  },
  methods: {
    getBills() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/bills/index-bills', {
          //customer_id: this.user.id,
          active_filters: this.active_filters,
          document_date_from: this.startDateDocument,
          document_date_to: this.endDateDocument,
          code: 'TDP',
          iva: this.iva,
          page: this.pagination.page,
          per_page: this.pagination.forPage
        })
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.docs = res.data.data
          this.totalRecords = res.data.total
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    filterHandler(elem, start, end) {
      this.active_filters[elem.key] = elem.value
      this.startDateDocument = start
      this.endDateDocument = end

      this.checkIfFilterActive()

      if (this.filterActive) this.getBills()
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    },
    handleShowPdf(doc) {
      if (doc) {
        this.$store.dispatch('commonComponents/showLoadingModalAction')

        axios({
          url:
            '/' + localStorage.getItem('clinic') + '/bills/' + doc.id + '/pdf',
          method: 'POST',
          responseType: 'blob'
        })
          .then((response) => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')

            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'Preventivo_' + doc.id + '.pdf')
            document.body.appendChild(link)

            this.$store.dispatch('commonComponents/openPdfModal', {
              title: 'Preventivo_' + doc.id,
              link: url,
              background: false,
              print: () => {
                console.log('PRINT')
              },
              download: () => {
                link.click()
              },
              xml: null,
              table_reference: 'active_bill',
              id_reference: doc.id,
              pdf_signed: doc.signed,
              sign_done: () => {}
            })
          })
          .catch(() => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Ops, qualcosa è andato storto.'
            })
            this.getBills()
          })
      }
    }
  }
})
</script>
