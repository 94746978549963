<template>
  <div
    class="login w-screen h-screen grid grid-cols-10 lg:items-center pb-0 lg:pb-12"
  >
    <div class="col-span-10 lg:col-span-6 -mt-24 lg:mt-0 place-self-center">
      <img
        class="login_logo"
        alt="logo-vetsgo"
        src="@/assets/images/logo-blue.svg"
      />
    </div>

    <div
      class="col-span-10 -mt-24 md:-mt-10 lg:mt-0 lg:col-span-4 lg:place-self-center lg:w-100 flex flex-col"
    >
      <h2
        class="nunito text-theme-40 intro-x text-2xl lg:text-3xl text-center xl:text-4xl"
      >
        Log In
      </h2>
      <div
        id="login-form"
        class="intro-x w-full mt-8 flex flex-col gap-5 place-content-center"
      >
        <input type="hidden" name="_token" :value="csrf" />
        <input
          id="username"
          v-model="username"
          type="text"
          class="custom-input h-10 rounded-full w-5/5 lg:w-full mx-auto"
          name="username"
          placeholder="Email"
          @keyup.enter="login()"
        />
        <input
          id="password"
          v-model="password"
          :type="showPword ? 'text' : 'password'"
          class="custom-input h-10 rounded-full w-5/5 lg:w-full mx-auto"
          name="password"
          placeholder="Password"
          @keyup.enter="login()"
        />
        <button id="show-password">
          <div v-if="showPword" @click="showPword = false">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 576 512"
              class="h-3 fill-current hover:text-theme-41"
            >
              <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M279.6 160.4C282.4 160.1 285.2 160 288 160C341 160 384 202.1 384 256C384 309 341 352 288 352C234.1 352 192 309 192 256C192 253.2 192.1 250.4 192.4 247.6C201.7 252.1 212.5 256 224 256C259.3 256 288 227.3 288 192C288 180.5 284.1 169.7 279.6 160.4zM480.6 112.6C527.4 156 558.7 207.1 573.5 243.7C576.8 251.6 576.8 260.4 573.5 268.3C558.7 304 527.4 355.1 480.6 399.4C433.5 443.2 368.8 480 288 480C207.2 480 142.5 443.2 95.42 399.4C48.62 355.1 17.34 304 2.461 268.3C-.8205 260.4-.8205 251.6 2.461 243.7C17.34 207.1 48.62 156 95.42 112.6C142.5 68.84 207.2 32 288 32C368.8 32 433.5 68.84 480.6 112.6V112.6zM288 112C208.5 112 144 176.5 144 256C144 335.5 208.5 400 288 400C367.5 400 432 335.5 432 256C432 176.5 367.5 112 288 112z"
              />
            </svg>
          </div>
          <div v-else @click="showPword = true">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 640 512"
              class="h-3 fill-current hover:text-theme-41"
            >
              <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M150.7 92.77C195 58.27 251.8 32 320 32C400.8 32 465.5 68.84 512.6 112.6C559.4 156 590.7 207.1 605.5 243.7C608.8 251.6 608.8 260.4 605.5 268.3C592.1 300.6 565.2 346.1 525.6 386.7L630.8 469.1C641.2 477.3 643.1 492.4 634.9 502.8C626.7 513.2 611.6 515.1 601.2 506.9L9.196 42.89C-1.236 34.71-3.065 19.63 5.112 9.196C13.29-1.236 28.37-3.065 38.81 5.112L150.7 92.77zM223.1 149.5L313.4 220.3C317.6 211.8 320 202.2 320 191.1C320 180.5 316.1 169.7 311.6 160.4C314.4 160.1 317.2 159.1 320 159.1C373 159.1 416 202.1 416 255.1C416 269.7 413.1 282.7 407.1 294.5L446.6 324.7C457.7 304.3 464 280.9 464 255.1C464 176.5 399.5 111.1 320 111.1C282.7 111.1 248.6 126.2 223.1 149.5zM320 480C239.2 480 174.5 443.2 127.4 399.4C80.62 355.1 49.34 304 34.46 268.3C31.18 260.4 31.18 251.6 34.46 243.7C44 220.8 60.29 191.2 83.09 161.5L177.4 235.8C176.5 242.4 176 249.1 176 255.1C176 335.5 240.5 400 320 400C338.7 400 356.6 396.4 373 389.9L446.2 447.5C409.9 467.1 367.8 480 320 480H320z"
              />
            </svg>
          </div>
        </button>
      </div>
      <div class="mt-5 mx-auto">
        <a href="forgot-password" class="underline">Password dimenticata?</a>
      </div>
      <div class="intro-x mt-5 xl:mt-8 text-center xl:text-center">
        <button class="orange-btn text-lg w-40" @click="login()">Accedi</button>
      </div>
    </div>
    <!-- END: Login Form -->
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'
import { mapGetters } from 'vuex'
import { useToast } from 'vue-toastification'

/* import router from '@/router'
import axios from '../gateway/backend-api' */

export default defineComponent({
  components: {},
  setup() {
    const toast = useToast()
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login_body')
    })

    return { toast }
  },
  data() {
    return {
      csrf: document.head.querySelector('meta[name="csrf-token"]')
        ? document.head.querySelector('meta[name="csrf-token"]').content
        : '',
      username: '',
      password: '',
      showPword: false
    }
  },
  computed: {
    ...mapGetters({
      showNotificationModal: 'commonComponents/getShowNotificationModal',
      notificationMessage: 'commonComponents/getNotificationMessage'
    })
  },
  watch: {
    showNotificationModal: function (elem) {
      if (elem === true) {
        this.toast.success(this.notificationMessage, {
          position: 'bottom-right',
          timeout: 3000,
          closeOnClick: false,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: false,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: false,
          icon: true,
          rtl: false
        })

        this.$store.dispatch('commonComponents/closeNotificationModal', {
          message: 'Scheda clinica salvata con successo!'
        })
      }
    }
  },
  methods: {
    login: function () {
      if (this.login == '' || this.password == '') {
        console.log('Username OR password missing')
      } else {
        this.showPword = false
        this.$store.dispatch('login', {
          username: this.username,
          password: this.password
        })
      }
    }
  }
})
</script>
<style>
.nunito {
  font-family: 'Nunito', sans-serif;
  font-weight: 900;
}
.Vue-Toastification__container {
  right: 3em !important;
  bottom: 3em !important;
}

.Vue-Toastification__toast-body {
  color: white;
  font-family: roboto;
  font-weight: bold;
}

.Vue-Toastification__toast--success {
  background-color: #ff8f1c !important;
}
</style>
