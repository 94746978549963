<template>
  <div class="bg-white p-3">
    <div class="paginator-container">
      <div class="prova mr-5 my-2 flex justify-between">
        <div class="mt-1">
          <span class="flex-row text-md ml-2 font-bold mr-2 mt-1">Mostra </span>

          <select
            class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
            @change="(event) => changeNumPages(event)"
          >
            <option v-for="show in forPageOption" :key="show" :value="show">
              {{ show }}
            </option>
          </select>
          <span class="text-md font-bold ml-2 mt-1"> elementi</span>
        </div>
      </div>
      <Paginator
        :for-page-option="pagination.forPage"
        :total-records="totalRecords"
        @changePagination="(event) => changePagination(event)"
      />
    </div>

    <!-- BEGIN: Data List -->
    <div class="table-container overflow-auto scrollbar_custom py-3">
      <table v-if="tableConfigClinicDoc && casi">
        <tr>
          <div
            class="w-full bg-theme-40 text-white font-semibold uppercase flex items-center text-xs"
          >
            <div
              v-for="(item, itemKey) in filteredConfig"
              :key="itemKey"
              class="w-32 text-center h-10 py-1 px-2"
            >
              <span v-if="item.title == 'Data Creazione'"> Data Modifica </span>
              <span v-else> {{ item.title }} </span>
            </div>
          </div>
        </tr>

        <tr v-for="(caso, casoKey) in casi" :key="casoKey">
          <div
            class="w-full flex gap-2 items-center justify-between bg-theme-44 h-10"
          >
            <div class="flex gap-3 items-center">
              <div
                :style="showCase ? 'transform: rotate(180deg);' : ''"
                class="cursor-pointer"
                @click="openCaso(casoKey)"
              >
                <ChevronDownIcon class="w-6 h-6 text-theme-41 font-bold" />
              </div>
              <div class="font-black nunito text-base">
                {{ caso.clinical_case_name }}
              </div>
            </div>

            <div class="w-32 text-center mr-1">
              <a class="" @click="$emit('showCasePdfPreview', caso)">
                <i
                  class="fas fa-file-pdf text-base cursor-pointer hover:text-theme-41"
                />
              </a>
            </div>
          </div>

          <div v-if="caso.open">
            <div
              class="bg-theme-45 flex items-center text-xs font-normal py-1 h-auto hover:bg-theme-44 cursor-pointer"
            >
              <div class="casi_td">
                {{
                  caso.last_update !== caso.creation_date
                    ? $FormatDateMin(caso.creation_date)
                    : ''
                }}
                {{ $FormatDateMin(caso.last_update) }}
              </div>
              <div class="casi_td">{{ caso.layout_id.document_type }}</div>
              <div class="casi_td">
                {{
                  caso.staff_user &&
                  caso.staff_user.first_name &&
                  caso.staff_user.last_name
                    ? caso.staff_user.first_name +
                      ' ' +
                      caso.staff_user.last_name
                    : caso.staff_user && caso.staff_user.username
                    ? caso.staff_user.username
                    : ''
                }}
              </div>
              <div class="casi_td">{{ caso.layout_id.reason ?? '' }}</div>
              <div v-if="global" class="casi_td flex flex-col">
                <template v-if="caso.pet && caso.pet.customers">
                  <div
                    v-for="cust in caso.pet.customers"
                    :key="cust"
                    class="flex mb-2"
                    @click="handleGoToCustomer(cust.id)"
                  >
                    <ChevronsRightIcon
                      class="h-5 w-5 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41 mr-2"
                    />
                    <span class="whitespace-nowrap">{{
                      cust ? cust.last_name + ' ' + cust.first_name : '--'
                    }}</span>
                  </div>
                </template>
                <div v-else>--</div>
              </div>
              <div v-if="!global" class="casi_td">
                <input
                  class="form-check-switch"
                  :checked="caso.send_to_petcorner"
                  type="checkbox"
                  @click="
                    (event) => handleSetPetcorner(event.target.checked, caso)
                  "
                />
              </div>
              <div v-else class="casi_td">
                <div
                  v-if="caso.pet"
                  @click.stop="() => handleGoToPet(caso.pet.id)"
                >
                  <ChevronsRightIcon
                    class="h-5 w-5 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                  />
                  {{ caso.pet ? caso.pet.name : '--' }}
                </div>
                <div v-else>--</div>
              </div>
              <div class="casi_td">
                <div class="flex items-center gap-1 justify-between px-3">
                  <div v-if="!global">
                    <a
                      v-if="
                        permissions['schede cliniche'] &&
                        permissions['schede cliniche']?.edit_medicaltemplate
                          ?.enabled === true
                      "
                      class="mx-auto my-2 cursor-pointer fill-current px-3 hover:text-white"
                      @click="$emit('handleEdit', caso)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 512 512"
                        class="w-3.5"
                      >
                        <path
                          d="M362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32zM421.7 220.3L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3z"
                        />
                      </svg>
                    </a>
                  </div>

                  <a
                    class="w-5 h-5 flex items-center justify-center hover:text-white p-2 text-lg"
                    @click="$emit('showPdfPreview', caso)"
                  >
                    <i class="fas fa-file-pdf text-base" />
                  </a>
                  <a
                    v-if="
                      permissions['schede cliniche'] &&
                      permissions['schede cliniche']?.delete_medicaltemplate
                        ?.enabled === true
                    "
                    class="my-2 cursor-pointer hover:text-white"
                    @click="deleteRecord(caso)"
                  >
                    <i class="fas fa-times text-center pr-3 text-base" />
                  </a>
                </div>
              </div>
            </div>
            <div class="">
              <div
                v-for="(doc, docKey) in caso.clinical_cases"
                :key="docKey"
                class="flex items-center py-1 h-auto hover:bg-theme-44 cursor-pointer"
                :class="[docKey % 2 === 0 ? '' : 'bg-theme-45']"
              >
                <div class="casi_td">
                  {{
                    doc.last_update !== doc.creation_date
                      ? $FormatDateMin(doc.creation_date)
                      : ''
                  }}
                  {{ $FormatDateMin(doc.last_update) }}
                </div>
                <div class="casi_td">
                  {{ doc.layout_id.document_type }}
                </div>
                <div class="casi_td">
                  {{
                    doc.staff_user &&
                    doc.staff_user.first_name &&
                    doc.staff_user.last_name
                      ? doc.staff_user.first_name +
                        ' ' +
                        doc.staff_user.last_name
                      : doc.staff_user && doc.staff_user.username
                      ? doc.staff_user.username
                      : ''
                  }}
                </div>
                <div class="casi_td bg-purple-100 text-xs">
                  {{ doc.layout_id.reason ?? '' }}
                </div>
                <div v-if="global" class="casi_td flex flex-col">
                  <template v-if="doc.pet && doc.pet.customers">
                    <div
                      v-for="cust in doc.pet.customers"
                      :key="cust"
                      class="flex mb-2"
                      @click="handleGoToCustomer(cust.id)"
                    >
                      <ChevronsRightIcon
                        class="h-5 w-5 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41 mr-2"
                      />
                      <span class="whitespace-nowrap">{{
                        cust ? cust.last_name + ' ' + cust.first_name : '--'
                      }}</span>
                    </div>
                  </template>
                  <div v-else>--</div>
                </div>
                <div v-if="!global" class="casi_td">
                  <input
                    class="form-check-switch"
                    :checked="doc.send_to_petcorner"
                    type="checkbox"
                    @click="
                      (event) => handleSetPetcorner(event.target.checked, doc)
                    "
                  />
                </div>
                <div v-else class="casi_td">
                  <div
                    v-if="doc.pet"
                    @click.stop="() => handleGoToPet(doc.pet.id)"
                  >
                    <ChevronsRightIcon
                      class="h-5 w-5 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                    />
                    {{ doc.pet ? doc.pet.name : '--' }}
                  </div>
                  <div v-else>--</div>
                </div>
                <div class="casi_td">
                  <div class="flex items-center gap-1 justify-between px-3">
                    <div v-if="!global">
                      <a
                        v-if="
                          permissions['schede cliniche'] &&
                          permissions['schede cliniche']?.edit_medicaltemplate
                            ?.enabled === true
                        "
                        class="mx-auto my-2 cursor-pointer fill-current px-3 hover:text-white"
                        @click="$emit('handleEdit', doc)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                          class="w-3.5"
                        >
                          <path
                            d="M362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32zM421.7 220.3L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3z"
                          />
                        </svg>
                      </a>
                    </div>

                    <a
                      class="w-5 h-5 flex items-center justify-center hover:text-white p-2 text-lg"
                      @click="$emit('showPdfPreview', doc)"
                    >
                      <i class="fas fa-file-pdf text-base" />
                    </a>
                    <a
                      v-if="
                        permissions['schede cliniche'] &&
                        permissions['schede cliniche']?.delete_medicaltemplate
                          ?.enabled === true
                      "
                      class="my-2 cursor-pointer hover:text-white"
                      @click="deleteRecord(doc)"
                    >
                      <i class="fas fa-times text-center pr-3 text-base" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </tr>
      </table>

      <div
        v-if="documents && documents.length === 0"
        class="w-full flex justify-center mt-2"
      >
        <span v-if="searching">
          <RotateCwIcon class="animate-spin w-10 scale-150" /> Caricamento in
          corso...
        </span>
        <span v-else>Nessun Risultato</span>
      </div>
    </div>
  </div>
  <!-- END: Data List -->
</template>

<script>
import { defineComponent, computed, ref, toRaw, watch } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import axios from '../../gateway/backend-api'
import { useStore } from '@/store'
// import _ from 'lodash'
import moment from 'moment'
import Paginator from '../../components/paginator/Main'

export default defineComponent({
  components: {
    Paginator
  },

  props: {
    tableConfigClinicDoc: {
      type: [Array],
      default: function () {
        return []
      }
    },
    documents: {
      type: [Array],
      default: function () {
        return []
      }
    },
    forPageOption: {
      type: [Array],
      default: function () {
        return [5, 10, 15]
      }
    },
    selectedPatient: Boolean,
    searching: Boolean,
    totalRecords: {
      type: [Number],
      default: function () {
        return 0
      }
    },
    pagination: {
      type: [Object],
      default: function () {
        return {}
      }
    },
    global: {
      type: [Boolean],
      default: function () {
        return false
      }
    }
  },
  emits: [
    'handleSendMessage',
    'handleDetailsModal',
    'changePagination',
    'changeNumPages',
    'getMedical',
    'handleEdit',
    'showPdfPreview',
    'showCasePdfPreview'
  ],
  setup(props, { emit }) {
    const store = useStore()
    const active_filters = ref({})
    const filterActive = ref(false)
    const showCase = ref(false)

    const filterHandler = (elem) => {
      if (elem.type == 'date') {
        active_filters.value[elem.key] = moment
          .utc(moment(elem.value).format('DD/MM/YYYY'), 'DD/MM/YYYY')
          .unix()
      } else {
        if (elem.value && elem.value !== '')
          active_filters.value[elem.key] = elem.value
        else active_filters.value[elem.key] = null
      }

      checkIfFilterActive()

      if (filterActive.value) emit('getMedical', active_filters.value)
    }

    const checkIfFilterActive = () => {
      var count = 0
      Object.entries(active_filters.value).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) filterActive.value = true
      else filterActive.value = false
    }

    //  cambio elementi per pagina, e ritorno nella prima pagina
    const changePagination = (value) => {
      if (props.pagination.page != value.page) {
        emit('changePagination', value)
      }
    }

    // metodo che cambia il numero della pagina
    const changeNumPages = (elem) => {
      emit('changeNumPages', elem)
    }

    const handleSetPetcorner = (checked, doc) => {
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/medical/set-send-to-petcorner',
          {
            medical_id: doc.medicaltemplate_id,
            send_to_petcorner: checked
          }
        ) // scarico la configurazione della tabella
        .then(() => {
          store.dispatch('commonComponents/openNotificationModal', {
            message: 'Successo'
          })
        })
        .catch(() => {
          store.dispatch('commonComponents/openNotificationModal', {
            message: 'Errore'
          })
        })
    }

    const openCaso = (index) => {
      casi.value[index].open = !casi.value[index].open
      showCase.value = !showCase.value
    }

    // ----- COMPUTED ----- //
    const table_config = computed(function () {
      // configurazione tabella, filtrando i dati del paziente nel caso in cui siamo nella sezione anagrafica paziente
      return JSON.parse(JSON.stringify(props.tableConfigClinicDoc)).filter(
        (elem) => !(elem.key === 'patient' && props.selectedPatient)
      )
    })

    const permissions = computed(() => {
      return store.state.reusableData.permissions
        ? store.state.reusableData.permissions
        : null
    })

    let tmp_docs = props.documents.map((el) => {
      return { ...el, open: false }
    })

    const casi = ref(tmp_docs)

    // watch(
    //   'documents',
    //   (docs) => {
    //     casi.value = docs.map((el) => {
    //       return { ...el, open: false }
    //     })
    //   },
    //   { deep: true }
    // )

    watch(
      () => props.documents,
      (newDocs) => {
        console.log(newDocs)
        casi.value = newDocs.map((el) => {
          return { ...el, open: false }
        })
      }
    )
    return {
      table_config,
      changePagination,
      active_filters,
      filterHandler,
      checkIfFilterActive,
      changeNumPages,
      filterActive,
      handleSetPetcorner,
      permissions,
      casi,
      openCaso,
      showCase
    }
  },
  computed: {
    ...mapGetters({
      getPetsDetails: 'anagraficaPazienti/getPetsDetails',
      getComparisonPets: 'anagraficaPazienti/getComparisonPets',
      getCustomersDetails: 'anagraficaClienti/getCustomersDetails',
      getComparisonCustomer: 'anagraficaClienti/getComparisonCustomer',
      user: 'getUser'
    }),
    filteredConfig() {
      return this.table_config.filter((item, itemKey) => itemKey !== 0)
    }
  },
  methods: {
    ...mapActions({
      getPetAction: 'anagraficaPazienti/getPetAction',
      getCustomerAction: 'anagraficaClienti/getCustomerAction',
      updateClientData: 'anagraficaClienti/updateClientData'
    }),
    handleGoToPet: function (id) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .get('/' + localStorage.getItem('clinic') + '/pet/' + id)
        .then((res) => {
          this.$goToPet(res.data)
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
        .catch(() =>
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        )
    },
    handleGoToCustomer(customer_id) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .get('/' + localStorage.getItem('clinic') + '/customer/' + customer_id)
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$goToCustomer(res.data)
        })
        .catch(() =>
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        )
    },
    tabAlreadyOpen: function () {
      this.$store.dispatch('commonComponents/openNotificationModal', {
        message: 'Tab già aperta'
      })
    },
    maxTabOpen: function () {
      this.$store.dispatch('commonComponents/openNotificationModal', {
        message: 'Numero massimo di tab aperte'
      })
    },
    handleEdit() {},
    deleteRecord(doc) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler cancellare la scheda clinica?',
        background: false,
        confirm_text: 'Conferma',
        decline_text: 'Annulla',
        confirm: () => {
          this.$store.dispatch('commonComponents/showLoadingModalAction')
          axios
            .post(
              '/' +
                localStorage.getItem('clinic') +
                '/medical/' +
                doc.pet_id +
                '/' +
                doc.medicaltemplate_id +
                '/delete'
            )
            .then((res) => {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: res.data.msg
              })
              this.$emit('getMedical', toRaw(this.active_filters))
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
            .catch(() => {
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
        },
        decline: () => {}
      })
    }
  }
})
</script>
