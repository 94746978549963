<template>
  <!-- Main modal -->
  <div
    id="defaultModal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative drug-cont top-12 mt-1 mx-auto px-3 pt-3 pb-7 border w-3/4 md:w-4/6 shadow-lg rounded-md bg-white overflow-y-scroll scrollbar-none"
    >
      <!-- Modal content -->

      <div class="mb-9">
        <button type="button" class="closing-btn" @click="$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <!-- Modal header -->
      <div class="m-2">
        <h3 class="modal-title">Nuovo Esame</h3>
      </div>

      <div class="p-2">
        <div class="flex flex-col mb-1">
          <!-- SELEZIONARE UTENTE nuevo  -->
          <div class="flex flex-col md:flex-row gap-6">
            <div v-if="!clientInvoice" id="select-utente" class="flex-1 p-3">
              <label class="font-semibold text-theme-43">
                SELEZIONARE UN CLIENTE
              </label>
              <div class="mt-3 relative">
                <div class="">
                  <input
                    v-model="searchBarClient"
                    type="text"
                    class="custom-input pl-10 rounded-xl py-2 z-10"
                    placeholder="Ricerca rapida nell'anagrafica"
                    @click.stop="dropDownSearchClient"
                  />
                  <svg
                    class="text-theme-44 fill-current w-6 absolute top-1 left-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 54.3 54.4"
                    @click="toggleModal"
                  >
                    <path
                      d="M16.4 4.7c9.2-7 22.3-6.1 30.8 2.1 8.4 8.1 9.4 21.1 2.4 31.1-5.5 7.9-19.5 14.2-33.5 4.9-3.2 3.2-6.5 6.5-9.7 9.8-2 2-3.8 2.3-5.4.8-1.6-1.5-1.4-3.4.7-5.6.3-.4.7-.7 1.1-1.1 2.9-2.9 5.9-5.8 8.7-8.6-8.7-12.9-4.1-26.5 4.9-33.4Zm31.2 19c0-9.2-7.8-17-17.2-17-9.5 0-17 7.7-17 17.3 0 9.4 7.6 16.9 17.2 16.9 9.5 0 17-7.6 17.1-17.3Z"
                    />
                  </svg>
                </div>
                <SearchDrop
                  v-if="isActiveSearchClient === true"
                  class="z-50 search-result bg-theme-44 bg-opacity-80 overflow-scroll scrollbar-none rounded-xl absolute invisible opacity-0 mt-4 py-4 px-3 right-0 top-6"
                  style="width: 28rem"
                  :filter-categories="['customer']"
                  :pet-invoice="petInvoice"
                  :filter="searchBarClient"
                  @childToParent="onChildClickClient"
                  @closeDropDownSearch="closeDropDownSearchClient"
                />
              </div>
            </div>
            <div
              v-if="clientInvoice"
              class="flex-1 px-1 py-2 border-2 border-theme-44 border-opacity-10 shadow-md rounded-md"
            >
              <div
                class="bg-theme-2 rounded-md p-1 flex justify-between items-center mb-2"
              >
                <h2 class="font-bold nunito text-lg pl-1">Dati Proprietario</h2>
                <a
                  class="cursor-pointer text-theme-41 fill-current"
                  @click="removeSelectedClient"
                >
                  <i class="fas fa-times text-center p-3" />
                </a>
              </div>

              <div class="flex flex-col ml-5">
                <div class="grid grid-cols-3 gap-2">
                  <label
                    class="form-label font-bold text-gray-700 text-xs sm:text-sm xl:text-base col-span-1"
                    >Cliente:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 text-xs sm:text-sm xl:text-base col-span-2"
                  >
                    {{
                      clientInvoice.first_name + ' ' + clientInvoice.last_name
                    }}
                  </p>
                </div>
                <div class="grid grid-cols-3 gap-2">
                  <label
                    class="form-label font-bold text-gray-700 text-xs sm:text-sm xl:text-base col-span-1"
                    >Indirizzo:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 text-xs sm:text-sm xl:text-base col-span-2"
                  >
                    {{
                      clientInvoice.address.address +
                      ' (' +
                      clientInvoice.address.district +
                      ')'
                    }}
                  </p>
                </div>
                <div class="grid grid-cols-3 gap-2">
                  <label
                    class="form-label font-bold text-gray-700 text-xs sm:text-sm xl:text-base col-span-1"
                    >Email:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 text-xs sm:text-sm xl:text-base col-span-2"
                  >
                    {{
                      clientInvoice.emails
                        ? clientInvoice.emails.email1.email
                        : '--'
                    }}
                  </p>
                </div>
                <div class="grid grid-cols-3 gap-2">
                  <label
                    class="form-label font-bold text-gray-700 text-xs sm:text-sm xl:text-base col-span-1"
                    >Codice Fiscale:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 text-xs sm:text-sm xl:text-base col-span-2"
                  >
                    {{ clientInvoice.fiscal_code }}
                  </p>
                </div>
              </div>
            </div>

            <!-- SELEZIONARE CLIENTE nuevo -->
            <div
              v-if="!selected"
              id="select-client
            "
              class="flex-1 p-3"
            >
              <label class="font-semibold text-theme-43">
                SELEZIONARE UN PAZIENTE
              </label>
              <div class="mt-3 relative">
                <div class="">
                  <input
                    v-model="searchBarPet"
                    type="text"
                    class="custom-input pl-10 rounded-xl py-2 z-10"
                    placeholder="Ricerca rapida nell'anagrafica"
                    @click.stop="dropDownSearchPet"
                  />
                  <svg
                    class="text-theme-44 fill-current w-6 absolute top-1 left-2"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 54.3 54.4"
                    @click="toggleModal"
                  >
                    <path
                      d="M16.4 4.7c9.2-7 22.3-6.1 30.8 2.1 8.4 8.1 9.4 21.1 2.4 31.1-5.5 7.9-19.5 14.2-33.5 4.9-3.2 3.2-6.5 6.5-9.7 9.8-2 2-3.8 2.3-5.4.8-1.6-1.5-1.4-3.4.7-5.6.3-.4.7-.7 1.1-1.1 2.9-2.9 5.9-5.8 8.7-8.6-8.7-12.9-4.1-26.5 4.9-33.4Zm31.2 19c0-9.2-7.8-17-17.2-17-9.5 0-17 7.7-17 17.3 0 9.4 7.6 16.9 17.2 16.9 9.5 0 17-7.6 17.1-17.3Z"
                    />
                  </svg>
                </div>
                <SearchDrop
                  v-if="isActiveSearchPet === true"
                  class="z-50 search-result bg-theme-44 bg-opacity-80 overflow-scroll scrollbar-none rounded-xl absolute invisible opacity-0 mt-4 py-4 px-3 right-0 top-6"
                  style="width: 28rem"
                  :filter-categories="['pet']"
                  :customer-invoice="clientInvoice"
                  :filter="searchBarPet"
                  @childToParent="onChildClickPet"
                  @closeDropDownSearch="closeDropDownSearchPet"
                />
              </div>
            </div>
            <div
              v-if="selected"
              class="flex-1 px-1 py-2 border-2 border-theme-44 border-opacity-10 shadow-md rounded-md"
            >
              <div
                class="bg-theme-2 rounded-md p-1 flex justify-between items-center mb-2"
              >
                <h2 class="font-bold nunito text-lg pl-1">Dati Pet</h2>
                <a
                  class="cursor-pointer text-theme-41 fill-current"
                  @click="removeSelectedPet"
                >
                  <i class="fas fa-times text-center p-3" />
                </a>
              </div>
              <div class="flex flex-col ml-5">
                <div class="grid grid-cols-3 gap-2">
                  <label
                    class="form-label font-bold text-gray-700 text-xs sm:text-sm xl:text-base col-span-1"
                    >Cliente:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 text-xs sm:text-sm xl:text-base col-span-2"
                  >
                    {{ selected.name }}
                  </p>
                </div>
                <div class="grid grid-cols-3 gap-2">
                  <label
                    class="form-label font-bold text-gray-700 text-xs sm:text-sm xl:text-base col-span-1"
                    >Indirizzo:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 text-xs sm:text-sm xl:text-base col-span-2"
                  >
                    {{ selected.owners[0].address.address }}
                  </p>
                </div>
                <div class="grid grid-cols-3 gap-2">
                  <label
                    class="form-label font-bold text-gray-700 text-xs sm:text-sm xl:text-base col-span-1"
                    >Sesso:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 text-xs sm:text-sm xl:text-base col-span-2"
                  >
                    {{ selected.gender }}
                  </p>
                </div>
                <div class="grid grid-cols-3 gap-2">
                  <label
                    class="form-label font-bold text-gray-700 text-xs sm:text-sm xl:text-base col-span-1"
                    >Microchip:
                  </label>
                  <p
                    class="form-label font-medium text-gray-600 text-xs sm:text-sm xl:text-base col-span-2"
                  >
                    {{ selected.microchip }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="flex flex-col mt-3 ml-5">
          <label class="font-semibold text-theme-43">
            SELEZIONARE UN MACCHINARIO
          </label>
          <select
            v-model="selectedMachine"
            class="w-full border rounded-md h-7"
          >
            <option v-for="e in machines" :key="e" :value="e" class="p-3">
              {{ e.machine[0].name }}
            </option>
          </select>
        </div>
      </div>
      <div>
        <div v-if="selectedMachine" class="flex flex-col mt-3 ml-5">
          <label class="font-semibold text-theme-43">
            SELEZIONARE UN ESAME
          </label>
          <select v-model="selectedExam" class="w-full border rounded-md h-7">
            <option
              v-for="e in selectedMachine.exams"
              :key="e"
              :value="e"
              class="p-3"
            >
              {{ e.name }}
            </option>
          </select>
        </div>
      </div>
      <div>
        <div v-if="selectedExam" class="flex flex-col mt-3 ml-5">
          <label class="font-semibold text-theme-43">
            SELEZIONA I SERVIZI
          </label>

          <div
            class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 xl:grid-cols-4 gap-3 mt-3"
          >
            <div
              v-for="e in selectedExam.services"
              :key="e"
              class="col-span-1 flex gap-3 items-center justify-between px-3 py-2 shadow-md border border-theme-43 border-opacity-20 rounded-md"
            >
              <div class="">{{ e.name }}</div>
              <div class="">
                <input
                  :id="e.id"
                  type="checkbox"
                  class="form-check-switch"
                  :checked="addService(null, e)"
                  @click="addService($event, e)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Modal footer -->

      <div class="px-6 flex items-center justify-end mt-5">
        <button
          ref="input4"
          type="button"
          class="orange-btn mr-3"
          :class="
            !selected ||
            !clientInvoice ||
            !petInvoice ||
            !selectedMachine ||
            !selectedExam ||
            !selectedServices
              ? 'disabilitato'
              : ''
          "
          @click="prenota"
        >
          <PlusCircleIcon class="h-5 w-5 mr-2" /> Prenota
        </button>
        <button type="button" class="gray-btn" @click="$emit('close')">
          <span class="h-5"></span>
          Annulla
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from 'vue'
import SearchDrop from '../../components/search-drop/SearchDrop.vue'
import axios from '../../gateway/backend-api'
import { useStore } from '@/store'
import _ from 'lodash'

export default defineComponent({
  components: { SearchDrop },
  props: ['pet', 'clientInvoice', 'petInvoice', 'machines'],
  emits: ['close', 'selectClientPet', 'update'],
  setup(props, { emit }) {
    const store = useStore()
    const searchBarClient = ref('')
    const searchBarPet = ref('')
    const isActiveSearchClient = ref(false)
    const isActiveSearchPet = ref(false)
    const selectedMachine = ref(null)
    const selectedExam = ref(null)
    const selectedServices = ref([])

    const dropDownSearchClient = () => {
      isActiveSearchClient.value = true
      isActiveSearchPet.value = false
    }
    const closeDropDownSearchClient = () => {
      isActiveSearchClient.value = false
    }
    const dropDownSearchPet = () => {
      isActiveSearchPet.value = true
      isActiveSearchClient.value = false
    }
    const closeDropDownSearchPet = () => {
      isActiveSearchPet.value = false
    }
    const onChildClickClient = (value) => {
      emit('selectClientPet', 'clientInvoice', value)
      closeDropDownSearchPet()
      closeDropDownSearchClient()
    }
    const onChildClickPet = (value) => {
      emit('selectClientPet', 'petInvoice', value)
      closeDropDownSearchPet()
      closeDropDownSearchClient()
    }

    const removeSelectedClient = () => {
      emit('selectClientPet', 'clientInvoice', null)
      searchBarClient.value = ''
    }

    const removeSelectedPet = () => {
      emit('selectClientPet', 'petInvoice', null)
      searchBarPet.value = ''
    }

    const addService = (event, s) => {
      if (!event) {
        if (s.is_default) {
          selectedServices.value.push(s)
          return true
        } else {
          _.remove(selectedServices.value, s)
          return false
        }
      } else {
        if (event.target.checked) selectedServices.value.push(s)
        else _.remove(selectedServices.value, s)
      }
    }

    const prenota = () => {
      if (
        props.clientInvoice &&
        props.petInvoice &&
        selectedMachine.value &&
        selectedServices.value &&
        selectedExam.value
      ) {
        store.dispatch('commonComponents/showLoadingModalAction')

        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/vetslab-exams/reserve',
            {
              machine_id: selectedMachine.value.machine[0].id,
              exam_id: selectedExam.value.id,
              services: JSON.stringify(selectedServices.value),
              pet_id: props.petInvoice.id,
              customer_id: props.clientInvoice.id
            }
          )
          .then(() => {
            store.dispatch('commonComponents/hideLoadingModalAction')
            store.dispatch('commonComponents/openNotificationModal', {
              message: 'Esame prenotato con successo'
            })
            emit('update')
            emit('close')
          })
          .catch((error) => {
            store.dispatch('commonComponents/hideLoadingModalAction')
            store.dispatch('commonComponents/openMessageModal', {
              title: 'Errore',
              subtitle: "Contattare l'assistenza"
            })
            console.log(error)
          })
      } else {
        store.dispatch('commonComponents/openMessageModal', {
          title: 'Dati Mancanti',
          subtitle: 'Compilare tutti i campi'
        })
      }
    }

    const selected = computed(() => {
      return props.petInvoice
    })

    watch(selectedMachine, (elem, newElem) => {
      if (newElem && elem != newElem) {
        selectedServices.value = []
        selectedExam.value = null
      }
    })

    watch(selectedExam, (elem, newElem) => {
      if (newElem && elem != newElem) {
        selectedServices.value = []
      }
    })

    return {
      searchBarClient,
      searchBarPet,
      isActiveSearchClient,
      isActiveSearchPet,
      dropDownSearchClient,
      closeDropDownSearchClient,
      dropDownSearchPet,
      closeDropDownSearchPet,
      onChildClickClient,
      onChildClickPet,
      removeSelectedClient,
      removeSelectedPet,
      selected,
      selectedMachine,
      selectedExam,
      selectedServices,
      addService,
      prenota
    }
  },
  watch: {
    // isActiveSearchClient: function (elem) {
    //   if (!elem) this.searchBarClient = ''
    // },
    // isActiveSearchPet: function (elem) {
    //   if (!elem) this.searchBarPet = ''
    // }
  },
  created() {
    if (this.petInvoice && typeof this.petInvoice.owners[0] != 'undefined')
      this.$emit('selectClientPet', 'clientInvoice', this.petInvoice.owners[0])
  },
  mounted() {
    // document.addEventListener('click', this.closeDropDownSearchClient)
    // document.addEventListener('click', this.closeDropDownSearchPet)
  }
})
</script>
