<template>
  <div>
    <div class="flex flex-col p-4">
      <AnnotazioniTable
        v-if="notes && tableConfig_vaccine_doc"
        :table-config-clinic-doc="tableConfig_vaccine_doc"
        :notes="notes"
        :pagination="pagination"
        :for-page-option="forPageOption"
        :selected-patient="true"
        @handleSendMessage="handleSendMessage"
        @addNote="addNote"
        @modifyNote="handleModifyAnnotation"
        @deleteNote="deleteNote"
        @refreshAnnotazioni="
          (pagination, filters) => refreshAnnotazioni(pagination, filters)
        "
      />
      <div class="ml-auto flex-0">
        <button
          class="orange-btn w-full"
          type="file"
          @click="handleAnnotationModal"
        >
          <PlusIcon class="h-5 w-5 mr-2" /> Aggiungi Annotazione
        </button>
      </div>
    </div>
    <!--END: Da Effettuare-->

    <div
      v-if="!tableConfig_vaccine_doc || !notes"
      class="flex w-full h-screen content-center items-center justify-center"
    >
      <RotateCwIcon class="animate-spin h-full w-5 mr-3 scale-150" />
    </div>
    <!-- <NewAnnotationModal
      v-if="newAnnotationModal"
      type="annotations"
      @close="closeVaccineModal"
      @addNote="addNote"
    /> -->
  </div>
</template>

<script>
import { defineComponent, toRaw } from 'vue'
import axios from '../../../gateway/backend-api'
// import NewAnnotationModal from '../../modals/NewAnnotationModal.vue'
import AnnotazioniTable from '../../tables/AnnotazioniTable.vue'

const forPageOption = [5, 10, 20]
export default defineComponent({
  components: {
    AnnotazioniTable
    // NewAnnotationModal
  },
  props: ['notes', 'newAnnotationModal'],
  emits: [
    'addNote',
    'deleteNote',
    'modifyNote',
    'toggleNewAnnotationModal',
    'refreshAnnotazioni'
  ],
  data() {
    return {
      tableConfig_vaccine_doc: null,
      annotationToModify: null,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      forPageOption: forPageOption,
      selectedElem: null
    }
  },
  mounted() {
    axios
      .get('/annotazioni_table_config') // scarico la configurazione della tabella
      .then((resp) => {
        const temp = []
        for (var elem of resp.data) {
          temp.push({ ...elem, value: '' }) // ai dati ricevuti aggiungo un campo per il valore del filtro che sarà inserito
        }
        this.tableConfig_vaccine_doc = temp
      })
  },

  methods: {
    refreshAnnotazioni(pagination, filters) {
      this.$emit('refreshAnnotazioni', 'annotations', pagination, filters)
    },
    handleAnnotationModal() {
      this.$store.dispatch('commonComponents/openAnnotationModal', {
        annotationData: {
          close: this.closeAnnotationModal,
          type: 'annotations',
          addNote: this.addNote
        }
      })
    },

    handleModifyAnnotation(data, modify = false) {
      this.annotationToModify = data
      this.$store.dispatch('commonComponents/openAnnotationModal', {
        annotationData: {
          pet: this.pet,
          client: this.pet?.owners[0] ?? null,
          type: 'annotations',
          to_modify: modify,
          note_data: data,
          modifyNote: this.modifyNote,
          close: this.closeAnnotationModal
        }
      })
    },

    closeAnnotationModal() {
      this.$store.dispatch('commonComponents/closeAnnotationModal')
    },
    addNote(type, note) {
      this.$emit('addNote', type, note)
    },

    modifyNote(type, note_id, note) {
      console.log('modifyannotazione start')
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/note/' +
            this.annotationToModify.id +
            '/edit',
          {
            note_id: note_id,
            note: note,
            type: 'annotations'
          }
        )
        .then(() => {
          var msg = ''
          if (type == 'annotations')
            msg = 'Annotazione modificata con successo!'
          else if (type == 'events') msg = 'Evento modificato con successo!'

          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: msg
          })
          this.closeAnnotationModal()
          this.$emit(
            'refreshAnnotazioni',
            'annotations',
            toRaw(this.pagination),
            toRaw(this.active_filters)
          )
        })
    },

    deleteNote(type, note_id) {
      this.$emit('deleteNote', type, note_id)
    }
  }
})
</script>
