<template>
  <div class="flex flex-col semilg:flex-row bg-theme-2">
    <div
      v-if="title"
      id="title"
      class="w-full semilg:w-1/4 px-2 py-1 font-bold justify-between break-words flex gap-1 items-start align-middle border-b-2 border-theme-44"
      :class="[
        title.includes('non compare nella stampa')
          ? ' font-bold bg-theme-41 bg-opacity-40'
          : ''
      ]"
    >
      <span v-if="title.includes('non compare nella stampa')">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          class="h-4 fill-current"
        >
          <path
            d="M506.3 417l-213.3-364c-16.33-28-57.54-28-73.98 0l-213.2 364C-10.59 444.9 9.849 480 42.74 480h426.6C502.1 480 522.6 445 506.3 417zM232 168c0-13.25 10.75-24 24-24S280 154.8 280 168v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zM256 416c-17.36 0-31.44-14.08-31.44-31.44c0-17.36 14.07-31.44 31.44-31.44s31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"
          />
        </svg>
      </span>
      {{ title }}

      <div v-if="elem.popup" class="tooltip">
        <button type="button ">
          <InfoIcon class="w-5 mr-2" />
        </button>
        <span class="tooltiptext">{{ elem.popup }}</span>
      </div>
    </div>
    <div class="flex-1 bg-white grid grid-cols-12">
      <!-- INIZIO input normali -->
      <div
        v-for="(i, index) in inputs"
        :key="i.description"
        class=""
        :class="[
          i.type === 'sub-title' || i.type === 'sub-sub-title'
            ? 'bg-theme-44 col-span-4 border-b-0 border-none  '
            : 'py-1 bg-white px-6',
          !conditionsInput &&
          inputs.length < 2 &&
          (i.type !== 'sub-title' || i.type !== 'sub-sub-title')
            ? 'border-b-2 border-theme-44 '
            : 'last:border-b-2 border-theme-44',

          column4
            ? 'col-span-12 semilg:col-span-3 border-b-2 border-theme-44'
            : column3
            ? 'col-span-12 semilg:col-span-4 border-b-2 border-theme-44'
            : column2
            ? 'col-span-12 semilg:col-span-6 border-b-2 border-theme-44'
            : i.type !== 'draw' || i.type !== 'image'
            ? 'col-span-12'
            : ''
        ]"
      >
        <button
          v-if="i.external_id"
          @click="handleTemplateModal(i, index, idClinicRowTemp)"
        >
          <p class="underline text-xs">Seleziona template</p>
          <svg
            v-if="false"
            width="20px"
            height="20px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 15.8L7.14286 17L10 14"
              stroke="#1C274C"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 8.8L7.14286 10L10 7"
              stroke="#1C274C"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M13 9L18 9"
              stroke="#1C274C"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M13 16L18 16"
              stroke="#1C274C"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M22 12C22 16.714 22 19.0711 20.5355 20.5355C19.0711 22 16.714 22 12 22C7.28595 22 4.92893 22 3.46447 20.5355C2 19.0711 2 16.714 2 12C2 7.28595 2 4.92893 3.46447 3.46447C4.92893 2 7.28595 2 12 2C16.714 2 19.0711 2 20.5355 3.46447C21.5093 4.43821 21.8356 5.80655 21.9449 8"
              stroke="#1C274C"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
        </button>
        <div
          class="flex-1"
          :class="[
            i.label &&
            (i.type === 'textarea' ||
              i.type === 'number' ||
              i.type === 'text' ||
              i.type === 'function')
              ? 'flex items-center '
              : 'flex flex-col '
          ]"
        >
          <div
            v-if="
              i.label &&
              i.type !== 'sub-title' &&
              i.type !== 'sub-sub-title' &&
              i.type !== 'checkbox-sub-linear' &&
              i.type !== 'checkbox-linear' &&
              i.type !== 'checkbox' &&
              i.type !== 'text'
            "
            class="flex-0"
          >
            <span class="mb-1"
              >{{ i.label }} {{ i.unit ? ' (' + i.unit + ')' : null }}
              {{
                i.min !== undefined &&
                i.min !== null &&
                i.max !== undefined &&
                i.max !== null
                  ? ' [min: ' + i.min + ', max: ' + i.max + ']'
                  : null
              }}</span
            >
          </div>

          <div v-if="i.type === 'text'" class="p-2 flex-1 flex items-center">
            <span class="mb-1" :class="[!i.label ? 'w-0' : 'w-1/6']"
              >{{ i.label }}
            </span>

            <input
              v-if="modify === true"
              v-model="inputValues[index]"
              class="custom-input py-1 flex-1"
              :class="[!i.label ? 'w-100' : 'flex-1']"
              type="text"
              :readonly="!modify"
              @change="
                $emit(
                  'updateData',
                  idClinicRowTemp,
                  inputValues,
                  conditionsInput,
                  type,
                  id
                )
              "
            />
            <div
              v-else
              class="w-full py-1 px-3 rounded-md border border-opacity-40"
              :class="[
                inputValues[index] == null ? 'border-none' : 'border-theme-44'
              ]"
            >
              <span v-if="inputValues[index]" class="p-2">
                {{
                  inputValues[index] && inputValues[index] !== ''
                    ? inputValues[index]
                    : ' '
                }}
              </span>
            </div>
          </div>
          <div
            v-else-if="i.type === 'number'"
            class="p-2 flex-1 flex items-center"
          >
            <input
              v-if="modify === true"
              v-model="inputValues[index]"
              class="custom-input py-1 outline-none appearance-none hide-arrow"
              type="number"
              min="0"
              step="0.1"
              :readonly="!modify"
              @change="
                $emit(
                  'updateData',
                  idClinicRowTemp,
                  inputValues,
                  conditionsInput,
                  type,
                  id
                )
              "
            />
            <div
              v-else
              class="py-1 px-3 rounded-md border border-opacity-40"
              :class="[
                inputValues[index] == null ? 'border-none' : 'border-theme-44'
              ]"
            >
              <span v-if="inputValues[index]" class="p-2">
                {{
                  inputValues[index] && inputValues[index] !== ''
                    ? inputValues[index]
                    : ' '
                }}
              </span>
            </div>
          </div>
          <div
            v-else-if="i.type === 'function'"
            class="p-2 flex-1 flex items-center"
          >
            <input
              v-model="inputValues[index]"
              class="custom-input py-1 outline-none appearance-none hide-arrow"
              type="number"
              min="0"
              step="0.1"
              :readonly="true"
              @change="
                $emit(
                  'updateData',
                  idClinicRowTemp,
                  inputValues,
                  conditionsInput,
                  type,
                  id
                )
              "
            />
          </div>
          <div
            v-else-if="i.type === 'textarea'"
            :id="'textareacont_' + idClinicRowTemp + index"
            class="flex-1 p-2 mb-1"
            :class="[expandTxt ? 'h-34 ' : 'h-9']"
            :data-textarea-cont="i"
            :data-textarea2-cont="index"
          >
            <textarea
              v-if="modify === true"
              :id="'textarea_' + idClinicRowTemp + index"
              ref="textRef"
              :data-textarea="i"
              :data-textarea2="index"
              class="custom-input scrollbar-none"
              :class="[expandTxt ? 'h-32 z-50  ' : 'h-7 z-30']"
              style="width: 99%"
              :value="inputValues[index]"
              :readonly="!modify"
              @keyup="handleHeight($event.target.scrollHeight)"
              @input="inputValues[index] = $event.target.value"
              @change="
                $emit(
                  'updateData',
                  idClinicRowTemp,
                  inputValues,
                  conditionsInput,
                  type,
                  id
                )
              "
              @click="handleTextArea"
            />
            <div
              v-if="modify === true && expandTxt == true"
              class="flex justify-end"
            >
              <button class="orange-btn-inverted" @click="closeTextArea">
                Riduci
              </button>
            </div>

            <span v-if="modify === false" class="p-2">
              {{
                inputValues[index] && inputValues[index] !== ''
                  ? inputValues[index]
                  : ' '
              }}
            </span>
          </div>
          <div v-else-if="i.type === 'date'" class="p-2">
            <input
              v-model="inputValues[index]"
              class="custom-input w-1/4"
              type="date"
              :readonly="!modify"
              @change="
                $emit(
                  'updateData',
                  idClinicRowTemp,
                  inputValues,
                  conditionsInput,
                  type,
                  id
                )
              "
            />
          </div>
          <div v-else-if="i.type === 'radio'" class="p-2">
            <div v-if="modify === true" class="grid grid-cols-12 gap-2">
              <div
                v-for="el in i.options"
                :key="el"
                class="flex items-center"
                :class="[
                  modify === false ? 'pointer-events-none' : '',

                  column4 || column3 || column2
                    ? 'col-span-12 '
                    : i.options.length >= 4
                    ? i.options.find((elem) => elem.length > 15)
                      ? 'col-span-12 semilg:col-span-4 '
                      : i.options.find((elem) => elem.length < 4)
                      ? 'col-span-12 semilg:col-span-2 '
                      : 'col-span-12 semilg:col-span-3'
                    : i.options.find((elem) => elem.length > 15)
                    ? 'col-span-12 semilg:col-span-4 '
                    : i.options.find((elem) => elem.length <= 3)
                    ? 'col-span-12 semilg:col-span-2 '
                    : 'col-span-12 semilg:col-span-3 '
                ]"
              >
                <input
                  v-model="inputValues[index]"
                  class="form-radio text-theme-41 text-sm focus:ring-0 focus:shadow-none mr-1"
                  type="radio"
                  :value="el"
                  :class="modify === false ? 'pointer-events-none' : ''"
                  @change="
                    $emit(
                      'updateData',
                      idClinicRowTemp,
                      inputValues,
                      conditionsInput,
                      type,
                      id
                    )
                  "
                />
                <label for="flexCheckDefault">
                  {{ el }}
                </label>
              </div>
            </div>
            <div v-else class="flex items-center">
              <div v-if="inputValues[index]">{{ inputValues[index] }}</div>
              <div v-else></div>
            </div>
          </div>
          <div v-else-if="i.type === 'radio-sub-linear'" class="p-2">
            <div
              v-if="modify === true"
              class="flex items-center gap-2 flex-wrap justify-start"
            >
              <div
                v-for="el in i.options"
                :key="el"
                class="flex flex-row items-center"
              >
                <input
                  v-model="inputValues[index]"
                  class="form-radio text-theme-41 text-sm focus:ring-0 focus:shadow-none mr-1"
                  type="radio"
                  :value="el"
                  :class="modify === false ? 'pointer-events-none' : ''"
                  @change="
                    $emit(
                      'updateData',
                      idClinicRowTemp,
                      inputValues,
                      conditionsInput,
                      type,
                      id
                    )
                  "
                />
                <label for="flexCheckDefault">
                  {{ el }}
                </label>
              </div>
            </div>
            <div v-else class="flex items-center">
              <div v-if="inputValues[index]">{{ inputValues[index] }}</div>
              <div v-else></div>
            </div>
          </div>

          <div v-else-if="i.type === 'image'" class="p-2 flex justify-center">
            <VueDrawingCanvas
              :ref="'VueCanvasDrawing' + idClinicRowTemp + '_' + index"
              :id-clinic-row-temp="idClinicRowTemp"
              :input-values="inputValues"
              :conditions-input="conditionsInput"
              :id-update="id"
              :index="index"
              :canvas-id="'VueCanvasDrawing' + idClinicRowTemp + '_' + index"
              :scheda-type="configuration.description_type"
              :pet_id="pet_id"
              :type="i.type"
              :id-section="i.id"
              :column-4="column4"
              :column-3="column3"
              :column-2="column2"
              :modify="false"
              @setValue="setImageDraw"
            ></VueDrawingCanvas>
          </div>
          <div v-else-if="i.type === 'draw'" class="p-2 flex justify-center">
            <VueDrawingCanvas
              :ref="'VueCanvasDrawing' + idClinicRowTemp + '_' + index"
              :id-clinic-row-temp="idClinicRowTemp"
              :input-values="inputValues"
              :conditions-input="conditionsInput"
              :id-update="id"
              :index="index"
              :canvas-id="'VueCanvasDrawing' + idClinicRowTemp + '_' + index"
              :scheda-type="configuration.description_type"
              :pet_id="pet_id"
              :type="i.type"
              :id-section="i.id"
              :column-4="column4"
              :column-3="column3"
              :column-2="column2"
              :modify="modify"
              @setValue="setImageDraw"
            ></VueDrawingCanvas>
          </div>
          <div
            v-else-if="i.type === 'signature'"
            class="p-2 flex justify-center"
          >
            <VueDrawingCanvas
              :ref="'VueCanvasDrawing' + idClinicRowTemp + '_' + index"
              :id-clinic-row-temp="idClinicRowTemp"
              :input-values="inputValues"
              :conditions-input="conditionsInput"
              :id-update="id"
              :index="index"
              :canvas-id="'VueCanvasDrawing' + idClinicRowTemp + '_' + index"
              :scheda-type="configuration.description_type"
              :pet_id="pet_id"
              :type="i.type"
              :id-section="i.id"
              :column-4="column4"
              :column-3="column3"
              :column-2="column2"
              :modify="modify"
              :no-bg="true"
              @setValue="setImageDraw"
            ></VueDrawingCanvas>
          </div>
          <div
            v-else-if="i.type === 'upload'"
            class="flex flex-col justify-end"
          >
            <div class="flex justify-end p-2">
              <button
                v-if="!uploadData"
                type="file"
                accept="image/*,.pdf"
                class="gray-btn"
                @click="$refs['file' + idClinicRowTemp + index][0].click()"
              >
                <PaperclipIcon class="h-5 w-5" /> Aggiungi allegato
              </button>
              <input
                v-if="!uploadData"
                :ref="'file' + idClinicRowTemp + index"
                type="file"
                accept="image/*,.pdf"
                class="form-control p-2 hidden"
                required
                @change="
                  (e) =>
                    handleFileChangeImage(
                      e,
                      index,
                      'file' + idClinicRowTemp + index
                    )
                "
              />
            </div>
            <div>
              <table
                v-if="inputValues[index] && inputValues[index].length > 0"
                class="w-full"
              >
                <thead class="h-8 t-header">
                  <tr>
                    <th
                      v-for="(item, itemKey) in allegatiConfig"
                      :key="itemKey"
                    >
                      {{ item }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(doc, docKey) in inputValues[index]"
                    :key="doc.id"
                    class="h-12 text-xs font-normal hover:bg-theme-45 cursor-pointer border-b"
                    :class="{ 'bg-theme-2': docKey % 2 === 0 }"
                  >
                    <td
                      class="text-center border-r whitespace-nowrap py-3 px-1"
                    >
                      {{ !uploadData ? doc.name : doc.original_name }}
                    </td>
                    <td
                      class="text-center border-r whitespace-nowrap py-3 px-1"
                    >
                      {{
                        !uploadData
                          ? $FormatDate(
                              parseInt(doc.lastModified.toString().slice(0, -3))
                            )
                          : $FormatDate(doc.updated_at)
                      }}
                    </td>
                    <td class="text-center">
                      <template v-if="uploadData">
                        <a :href="doc.url" target="blank" class="text-sm"
                          >Link</a
                        >

                        >
                      </template>
                      <template v-else>
                        <button
                          type="button"
                          class="text-theme-41 hover:zoom-big"
                          @click="removeFile(index, doc)"
                        >
                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div
            v-else-if="i.type === 'show-agenda'"
            class="flex flex-col justify-end"
          >
            <div class="flex p-2">
              <button
                type="file"
                class="gray-btn"
                @click="$emit('openAgendaModal')"
              >
                <CalendarIcon class="h-5 w-5" /> Mostra agenda
              </button>
            </div>
          </div>
          <div
            v-else-if="i.type === 'show-tariffario'"
            class="flex flex-col justify-end"
          >
            <div class="flex justify-start p-2">
              <button
                type="file"
                class="gray-btn"
                @click="$emit('openTariffarioModal')"
              >
                <DollarSignIcon class="h-5 w-5" /> Mostra Tariffario
              </button>
            </div>
          </div>
          <div v-else-if="i.type === 'video'" class="flex flex-col justify-end">
            <div class="flex justify-end p-2">
              <button
                v-if="!uploadData"
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
                class="gray-btn"
                @click="$refs['file' + idClinicRowTemp + index][0].click()"
              >
                <PaperclipIcon class="h-5 w-5" /> Aggiungi Allegato
              </button>
              <input
                v-if="!uploadData"
                :ref="'file' + idClinicRowTemp + index"
                type="file"
                accept="video/mp4,video/x-m4v,video/*"
                class="form-control p-2 hidden"
                required
                @change="
                  (e) =>
                    handleFileChangeVideo(
                      e,
                      index,
                      'file' + idClinicRowTemp + index
                    )
                "
              />
            </div>
            <div>
              <table
                v-if="inputValues[index] && inputValues[index].length > 0"
                class="w-full"
              >
                <thead class="h-8 t-header">
                  <tr>
                    <th
                      v-for="(item, itemKey) in allegatiConfig"
                      :key="itemKey"
                    >
                      {{ item }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(doc, docKey) in inputValues[index]"
                    :key="doc.id"
                    class="h-12 text-xs font-normal hover:bg-theme-45 cursor-pointer border-b"
                    :class="{ 'bg-theme-2': docKey % 2 === 0 }"
                  >
                    <td
                      class="text-center border-r whitespace-nowrap py-3 px-1"
                    >
                      {{ !uploadData ? doc.name : doc.original_name }}
                    </td>
                    <td
                      class="text-center border-r whitespace-nowrap py-3 px-1"
                    >
                      {{
                        !uploadData
                          ? $FormatDate(
                              parseInt(doc.lastModified.toString().slice(0, -3))
                            )
                          : $FormatDate(doc.updated_at)
                      }}
                    </td>
                    <td class="text-center">
                      <template v-if="uploadData">
                        <a :href="doc.url" target="blank" class="text-sm"
                          >Link</a
                        >
                      </template>
                      <template v-else>
                        <button
                          type="button"
                          class="text-theme-41 hover:zoom-big"
                          @click="removeFile(index, doc)"
                        >
                          <svg
                            class="w-5 h-5"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </template>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-else-if="i.type === 'checkbox'" class="p-2 flex flex-col">
            <div class="w-44 mb-1">
              {{ i.label }}
            </div>

            <div v-if="modify === true" class="grid grid-cols-12 gap-1">
              <div
                v-for="el in i.options"
                :key="el"
                class=""
                :class="
                  column4 || column3 || column2
                    ? 'col-span-12 '
                    : i.options.length >= 4
                    ? i.options.find((elem) => elem.length > 15)
                      ? 'col-span-12 semilg:col-span-4 '
                      : i.options.find((elem) => elem.length < 4)
                      ? 'col-span-12 semilg:col-span-1 '
                      : 'col-span-12 semilg:col-span-3'
                    : i.options.find((elem) => elem.length <= 3)
                    ? 'col-span-12 semilg:col-span-1 '
                    : i.options.find((elem) => elem.length > 15)
                    ? 'col-span-12 semilg:col-span-4 '
                    : 'col-span-12 semilg:col-span-3 '
                "
              >
                <div class="flex items-start">
                  <input
                    v-model="inputValues[index]"
                    type="checkbox"
                    class="form-checkbox rounded text-theme-41 text-sm focus:ring-0 focus:shadow-none"
                    :value="el"
                    :class="[modify === false ? 'pointer-events-none' : '']"
                    @change="
                      $emit(
                        'updateData',
                        idClinicRowTemp,
                        inputValues,
                        conditionsInput,
                        type,
                        id
                      )
                    "
                  />
                  <label for="flexCheckDefault" class="ml-1">
                    {{ el }}
                  </label>
                </div>
              </div>
            </div>
            <div v-else class="flex gap-1 items-center flex-wrap">
              <div
                v-for="x in inputValues[index]"
                :key="x"
                class="flex items-center"
              >
                <span v-if="x" class="text-xl mr-1">• </span>
                {{ x ? x : '' }}
              </div>
            </div>
          </div>
          <div v-else-if="i.type === 'exams'" class="p-2 flex flex-col">
            <div class="w-44 mb-1">
              {{ i.label }}
            </div>

            <div v-if="modify === true" class="grid grid-cols-12">
              <div class="grid grid-cols-12 col-span-12 gap-1">
                <div
                  v-for="el in inputValues[index]"
                  :key="el"
                  class=""
                  :class="
                    column4 || column3 || column2
                      ? 'col-span-12 '
                      : i.options.length >= 4
                      ? i.options.find((elem) => elem.length > 15)
                        ? 'col-span-12 semilg:col-span-4 '
                        : i.options.find((elem) => elem.length < 4)
                        ? 'col-span-12 semilg:col-span-1 '
                        : 'col-span-12 semilg:col-span-3'
                      : i.options.find((elem) => elem.length <= 3)
                      ? 'col-span-12 semilg:col-span-1 '
                      : i.options.find((elem) => elem.length > 15)
                      ? 'col-span-12 semilg:col-span-4 '
                      : 'col-span-12 semilg:col-span-3 '
                  "
                >
                  <div class="flex items-start">
                    <input
                      v-model="inputValues[index]"
                      type="checkbox"
                      class="form-checkbox rounded text-theme-41 text-sm focus:ring-0 focus:shadow-none"
                      :value="el"
                      :class="[modify === false ? 'pointer-events-none' : '']"
                      @change="
                        $emit(
                          'updateData',
                          idClinicRowTemp,
                          inputValues,
                          conditionsInput,
                          type,
                          id
                        )
                      "
                    />
                    <label for="flexCheckDefault" class="ml-1">
                      {{ el.label }}
                    </label>
                  </div>
                </div>
                <v-select
                  v-model="checkboxSelectValue"
                  class="col-span-12 w-80"
                  :options="
                    exams.filter(
                      (item) =>
                        !inputValues[index].find(
                          (item_2) => item_2.id === item.id
                        )
                    )
                  "
                  placeholder=""
                  label="label"
                  @option:selected="
                    (el) => {
                      inputValues[index].push(el)
                      $emit(
                        'updateData',
                        idClinicRowTemp,
                        inputValues,
                        conditionsInput,
                        type,
                        id
                      )
                      checkboxSelectValue = null
                    }
                  "
                />
              </div>
            </div>
            <div v-else class="flex gap-1 items-center flex-wrap">
              <div
                v-for="x in inputValues[index]"
                :key="x"
                class="flex items-center"
              >
                <span v-if="x" class="text-xl mr-1">• </span>
                {{ x ? x : '' }}
              </div>
            </div>
          </div>
          <div v-else-if="i.type === 'checkbox-text'" class="p-2 flex flex-col">
            <div class="w-44 mb-1">
              {{ i.label }}
            </div>

            <div
              v-if="modify === true"
              class="flex flex-col flex-wrap items-start gap-2 justify-start"
            >
              <div
                v-for="(el, elIndex) in i.options"
                :key="el"
                class="flex items-center gap-1"
              >
                <input
                  v-model="inputValues[index]['data']"
                  type="checkbox"
                  class="form-checkbox rounded text-theme-41 text-sm focus:ring-0 focus:shadow-none"
                  :value="el"
                  :class="modify === false ? 'pointer-events-none' : ''"
                  @change="
                    $emit(
                      'updateData',
                      idClinicRowTemp,
                      inputValues,
                      conditionsInput,
                      type,
                      id
                    )
                  "
                />
                <label for="flexCheckDefault" class="ml-1">
                  {{ el }}
                </label>
                <input
                  v-show="inputValues[index]['data'].includes(el)"
                  v-model="inputValues[index]['text'][elIndex]"
                  type="text"
                  class="rounded text-sm focus:ring-0 focus:shadow-none custom-input flex-1 ml-1"
                />
              </div>
            </div>
            <div v-else class="flex flex-col gap-1 items-start flex-wrap">
              <div
                v-for="(x, indexEl) in i.options"
                :key="x"
                class="flex items-center"
              >
                <span v-if="inputValues[index]['data'].includes(x)"
                  >• {{ x }}
                </span>

                <!-- {{ x ? x : '' }} -->
                <span
                  v-if="
                    inputValues[index]['data'].includes(x) &&
                    inputValues[index]['text'][indexEl] !== undefined
                  "
                >
                  ( {{ inputValues[index]['text'][indexEl] }} )</span
                >
              </div>
            </div>
          </div>
          <div v-else-if="i.type === 'checkbox-linear'" class="p-2">
            <div
              v-if="modify === true"
              class="flex flex-col justify-start flex-wrap"
            >
              <span>
                {{ i.label ? i.label : '' }}
              </span>
              <div
                v-for="el in i.options"
                :key="el"
                class="flex items-center justify-start mx-2"
              >
                <input
                  v-model="inputValues[index]"
                  type="checkbox"
                  class="form-checkbox rounded text-theme-41 text-sm focus:ring-0 focus:shadow-none"
                  :value="el"
                  :class="modify === false ? 'pointer-events-none' : ''"
                  @change="
                    $emit(
                      'updateData',
                      idClinicRowTemp,
                      inputValues,
                      conditionsInput,
                      type,
                      id
                    )
                  "
                />
                <label for="flexCheckDefault" class="ml-2">
                  {{ el }}
                </label>
              </div>
            </div>
            <div v-else class="flex gap-1 items-center flex-wrap">
              <span>
                {{ i.label ? i.label : '' }}
              </span>
              <div
                v-for="x in inputValues[index]"
                :key="x"
                class="flex items-center"
              >
                <span v-if="x" class="text-xl mr-1">• </span>
                {{ x ? x : '' }}
              </div>
            </div>
          </div>
          <div v-else-if="i.type === 'checkbox-sub-linear'" class="p-2">
            <div
              v-if="modify === true"
              class="flex items-center justify-start flex-wrap pl-8"
            >
              <span>
                {{ i.label ? i.label : '' }}
              </span>
              <div
                v-for="el in i.options"
                :key="el"
                class="flex items-center justify-start mx-2"
              >
                <input
                  v-model="inputValues[index]"
                  type="checkbox"
                  class="form-checkbox rounded text-theme-41 text-sm focus:ring-0 focus:shadow-none"
                  :value="el"
                  :class="modify === false ? 'pointer-events-none' : ''"
                  @change="
                    $emit(
                      'updateData',
                      idClinicRowTemp,
                      inputValues,
                      conditionsInput,
                      type,
                      id
                    )
                  "
                />
                <label for="flexCheckDefault" class="ml-2">
                  {{ el }}
                </label>
              </div>
            </div>
            <div v-else class="flex gap-1 items-center flex-wrap">
              <span>
                {{ i.label ? i.label : '' }}
              </span>
              <div
                v-for="x in inputValues[index]"
                :key="x"
                class="flex items-center"
              >
                <span v-if="x" class="text-xl mr-1">• </span>
                {{ x ? x : '' }}
              </div>
            </div>
          </div>

          <div v-else-if="i.type === 'select'" class="p-2">
            <v-select
              v-if="modify"
              v-model="inputValues[index]"
              class="col-span-4 w-60"
              :options="i.options"
              :class="!modify ? 'disabled' : ''"
              placeholder=""
              label="name"
              @option:selected="
                $emit(
                  'updateData',
                  idClinicRowTemp,
                  inputValues,
                  conditionsInput,
                  type,
                  id
                )
              "
            />
            <span v-else>
              {{ inputValues[index] }}
            </span>
          </div>
          <div
            v-else-if="i.type === 'sub-title'"
            class="justify-between flex font-bold nunito text-sm bg-theme-44 py-2 uppercase px-4 text-theme-43"
          >
            <span class="">{{ i.label }} </span>
            <div v-if="elem.popup" class="tooltipbottom">
              <button type="button ">
                <InfoIcon class="w-5 mr-2" />
              </button>
              <span class="tooltiptext">{{ elem.popup }}</span>
            </div>
          </div>
          <div
            v-else-if="i.type === 'read'"
            class="justify-between flex font-bold nunito text-sm bg-theme-44 py-2 uppercase px-4 text-theme-43"
          >
            <span class="">{{ i.label }} </span>
            <div v-if="elem.popup" class="tooltipbottom">
              <button type="button ">
                <InfoIcon class="w-5 mr-2" />
              </button>
              <span class="tooltiptext">{{ elem.popup }}</span>
            </div>
          </div>
          <div
            v-else-if="i.type === 'sub-sub-title'"
            class="justify-between flex text-sm nunito p-6 bg-theme-45 py-2 font-semibold text-theme-43"
          >
            <span class="">{{ i.label }} </span>
            <div v-if="elem.popup" class="tooltipbottom">
              <button type="button ">
                <InfoIcon class="w-5 mr-2" />
              </button>
              <span class="tooltiptext">{{ elem.popup }}</span>
            </div>
          </div>
          <div
            v-else-if="i.type === 'textarea-edit'"
            class="font-bold text-center nunito text-xl text-theme-40 py-2"
          >
            <QuillEditor
              v-if="modify"
              v-model:content="inputValues[index]"
              theme="snow"
              content-type="html"
              class="form-control h-40"
              @textChange="
                handleQuilltextChange(
                  idClinicRowTemp,
                  inputValues,
                  conditionsInput,
                  type,
                  id,
                  index
                )
              "
            />
            <QuillEditor
              v-else
              v-model:content="inputValues[index]"
              theme="snow"
              content-type="html"
              class="form-control h-40"
              :read-only="true"
            />
            <!-- <div v-else v-html="inputValues[index]"></div> -->
          </div>
          <div v-else-if="i.type === 'table'" class="p2" @scroll.passive="true">
            <div
              v-for="(h, indexH) in inputValues[index]"
              :key="indexH"
              class="flex flex-col gap-1 p-2 items-end"
            >
              <div
                v-for="(e, indexE, indexE_number) in inputValues[index][indexH]"
                :key="indexE"
                class="w-full"
              >
                <div class="flex flex-col">
                  <span
                    v-if="
                      typeof i.headers[indexE_number] === 'string' &&
                      i.headers[indexE_number].toLowerCase() === 'created_by'
                    "
                    >{{
                      inputValues[index][indexH][indexE] ? 'CREATO DA:' : ''
                    }}
                  </span>
                  <span v-else>{{ i.headers[indexE_number] }} </span>
                  <span
                    v-if="
                      i.headers[indexE_number].toLowerCase() === 'created_by'
                    "
                  >
                    {{
                      inputValues[index][indexH][indexE]
                        ? inputValues[index][indexH][indexE].last_name +
                          ' ' +
                          inputValues[index][indexH][indexE].first_name
                        : ''
                    }}
                  </span>

                  <input
                    v-else-if="
                      i.headers[indexE_number].toLowerCase() === 'data'
                    "
                    v-model="inputValues[index][indexH][indexE]"
                    class="custom-input"
                    type="date"
                    :readonly="!modify"
                    @change="
                      $emit(
                        'updateData',
                        idClinicRowTemp,
                        inputValues,
                        conditionsInput,
                        type,
                        id
                      )
                    "
                  />
                  <textarea
                    v-else
                    v-model="inputValues[index][indexH][indexE]"
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-28"
                  />
                </div>
              </div>
              <div class="flex mt-4">
                <button
                  v-if="modify"
                  type="button"
                  class="gray-btn-inverted w-full flex items-center gap-2 px-4"
                  @click="removeTableElement(index, indexH)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                    class="w-3 fill-current"
                  >
                    <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                    <path
                      d="M400 288h-352c-17.69 0-32-14.32-32-32.01s14.31-31.99 32-31.99h352c17.69 0 32 14.3 32 31.99S417.7 288 400 288z"
                    />
                  </svg>
                  Rimuovi elemento
                </button>
              </div>
            </div>
            <div class="flex justify-end p-2">
              <button
                v-if="modify"
                type="button"
                class="orange-btn-inverted flex items-center gap-2"
                @click="addTableElement(index)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  class="w-3 fill-current"
                >
                  <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
                  />
                </svg>
                Aggiungi elemento
              </button>
            </div>
          </div>
          <div v-else-if="i.type === 'table_simple'" @scroll.passive="true">
            <div
              v-for="(h, indexH) in inputValues[index]"
              :key="indexH"
              class="grid grid-cols-2 lg:gris-cols-3 xl:grid-cols-4 p-2 gap-1 items-end"
            >
              <div
                v-for="(e, indexE, indexE_number) in inputValues[index][indexH]"
                :key="indexE"
                class="col-span-1"
              >
                <div class="flex flex-col">
                  <span>{{ i.headers[indexE_number] }} </span>
                  <input
                    v-model="inputValues[index][indexH][indexE]"
                    class="custom-input"
                    type="text"
                    :readonly="!modify"
                    @change="
                      $emit(
                        'updateData',
                        idClinicRowTemp,
                        inputValues,
                        conditionsInput,
                        type,
                        id
                      )
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <!-- <div
            v-else-if="i.type === 'double-checkbox-image'"
            class="p-2 bg-red-500"
          >
            <div
              v-for="el in i.options"
              :key="el"
              class="flex items-center justify-start mx-2"
            >
              <input
                v-model="inputValues[index]"
                type="checkbox"
                class="form-checkbox rounded text-theme-41 text-sm focus:ring-0 focus:shadow-none"
                :value="el"
                :class="modify === false ? 'pointer-events-none' : ''"
                @change="
                  $emit(
                    'updateData',
                    idClinicRowTemp,
                    inputValues,
                    conditionsInput,
                    type,
                    id
                  )
                "
              />
              <label for="flexCheckDefault" class="ml-2">
                {{ el }}
              </label>
            </div>
            <VueDrawingCanvas
              :ref="'VueCanvasDrawing' + idClinicRowTemp + '_' + index"
              :id-clinic-row-temp="idClinicRowTemp"
              :input-values="inputValues"
              :conditions-input="conditionsInput"
              :conditions-input-values="conditionalInputValues"
              :id-update="id"
              :index="index"
              :index-r="indexR"
              :canvas-id="'VueCanvasDrawing' + idClinicRowTemp + '_' + index"
              :scheda-type="configuration.description_type"
              :pet_id="pet_id"
              :type="i.type"
              :id-section="iR.id_section"
              @setValue="setImageDraw"
              :inputLenght="inputLenght"
            ></VueDrawingCanvas>
          </div> -->
          <div v-else-if="type !== 'text'" class="p-2">
            <input
              v-model="inputValues[index]"
              class="custom-input py-1"
              type="text"
              :readonly="!modify"
              @change="
                $emit(
                  'updateData',
                  idClinicRowTemp,
                  inputValues,
                  conditionsInput,
                  type,
                  id
                )
              "
            />
          </div>

          <div v-if="buttons" class="flex flex-row p-2">
            <button
              v-for="el in buttons"
              :key="el"
              class="gray-btn-inverted fill-current"
              :class="!modify ? 'disabled' : ''"
              @click="handleButtonAction(el)"
            >
              {{ el.name }}
            </button>
          </div>
          <div v-if="type === 'video'" class="flex flex-row p-2">
            <button class="gray-btn-inverted flex items-center mx-auto">
              Vedi Video
            </button>
          </div>
        </div>

        <!-- EL ORIGINAL -->
        <span
          v-if="i.richiamoValue && inputValues[index] === i.richiamoValue"
          class="font-bold text-theme-41"
          >Precompilato il: {{ $FormatDate(richiamoDate) }}</span
        >
      </div>
      <!-- Fine input normali -->
      <!-- INIZIO input condizionali -->
      <div
        v-for="(iR, indexR) in conditionsInput"
        :key="indexR"
        class="flex-1 bg-white"
        :class="[
          iR.type === 'sub-title' || iR.type === 'sub-sub-title'
            ? 'bg-theme-44 col-span-4 border-b-0 border-none  '
            : 'py-1 bg-white px-6',
          conditionsInput.length < 2 &&
          (iR.type !== 'sub-title' || iR.type !== 'sub-sub-title')
            ? 'border-b-2 border-theme-44 '
            : 'last:border-b-2 border-theme-44',

          column4
            ? 'col-span-12 semilg:col-span-3 border-b-2 border-theme-44'
            : column3
            ? 'col-span-12 semilg:col-span-4 border-b-2 border-theme-44'
            : column2
            ? 'col-span-12 semilg:col-span-6 border-b-2 border-theme-44'
            : iR.type !== 'draw' || iR.type !== 'image'
            ? 'col-span-12'
            : ''
        ]"
      >
        <div
          v-if="iR.label"
          class="font-bold text-center nunito text-xl bg-theme-2 text-theme-40 py-2"
        >
          <span class="">{{ iR.label }} </span>
        </div>

        <div v-for="(i, index, indexNumber) in iR" :key="index">
          <span
            v-if="
              i.label && i.type !== 'sub-title' && i.type !== 'sub-sub-title'
            "
            class="p-2"
            >{{ i.label }}
          </span>
          <div v-if="i.type === 'text'" class="px-6 py-2">
            <input
              v-if="modify === true"
              v-model="conditionsInput[indexR][index].data"
              class="custom-input"
              type="text"
              :readonly="!modify"
            />
            <span v-else class="custom-input">
              {{
                conditionsInput[indexR][index].data &&
                conditionsInput[indexR][index].data !== ''
                  ? conditionsInput[indexR][index].data
                  : ' '
              }}
            </span>
          </div>
          <div v-if="i.type === 'number'">
            <input
              v-if="modify === true"
              v-model="conditionsInput[indexR][index].data"
              class="custom-input"
              type="number"
              min="0"
              :readonly="!modify"
            />
            <span v-else class="custom-input">
              {{
                conditionsInput[indexR][index].data &&
                conditionsInput[indexR][index].data !== ''
                  ? conditionsInput[indexR][index].data
                  : ' '
              }}
            </span>
          </div>
          <div
            v-else-if="i.type === 'textarea'"
            :id="'textareacont_' + idClinicRowTemp + index"
            class="p-2 mb-1"
            :class="[expandTxt ? 'h-34 ' : 'h-9']"
            :data-textarea-cont="i"
            :data-textarea2-cont="index"
            @click="closeTextArea"
          >
            <textarea
              v-if="modify === true"
              :id="'textarea_' + idClinicRowTemp + index"
              ref="textRef"
              :data-textarea="i"
              :data-textarea2="index"
              class="custom-input scrollbar-none"
              :class="[expandTxt ? 'h-32 z-50  ' : 'h-7 z-30']"
              style="width: 99%"
              :value="conditionsInput[indexR][index].data"
              :readonly="!modify"
              @keyup="handleHeight($event.target.scrollHeight)"
              @change="
                $emit(
                  'updateData',
                  idClinicRowTemp,
                  inputValues,
                  conditionsInput,
                  type,
                  id
                )
              "
              @click="handleTextArea()"
              @input="conditionsInput[indexR][index].data = $event.target.value"
            />

            <div
              v-if="modify === true && expandTxt == true"
              class="flex justify-end"
            >
              <button class="orange-btn-inverted" @click="closeTextArea">
                Riduci
              </button>
            </div>

            <span v-if="modify === false">
              {{
                conditionsInput[indexR][index].data &&
                conditionsInput[indexR][index].data !== ''
                  ? conditionsInput[indexR][index].data
                  : ' '
              }}
            </span>
          </div>

          <div v-else-if="i.type === 'date'" class="p-2">
            <input
              v-model="conditionsInput[indexR][index].data"
              class="custom-input"
              type="date"
              :readonly="!modify"
            />
          </div>
          <div v-else-if="i.type === 'radio'" class="p-2">
            <div v-if="modify === true" class="grid grid-cols-12 gap-2">
              <div v-for="el in i.options" :key="el" class="">
                <input
                  v-model="conditionsInput[indexR][index].data"
                  type="radio"
                  :value="el"
                  class="flex items-center"
                  :class="[
                    modify === false ? 'pointer-events-none' : '',

                    column4 || column3 || column2
                      ? 'col-span-12 '
                      : i.options.length >= 4
                      ? i.options.find((elem) => elem.length > 15)
                        ? 'col-span-12 semilg:col-span-4 '
                        : i.options.find((elem) => elem.length < 4)
                        ? 'col-span-12 semilg:col-span-2 '
                        : 'col-span-12 semilg:col-span-3'
                      : i.options.find((elem) => elem.length > 15)
                      ? 'col-span-12 semilg:col-span-4 '
                      : i.options.find((elem) => elem.length <= 3)
                      ? 'col-span-12 semilg:col-span-2 '
                      : 'col-span-12 semilg:col-span-3 '
                  ]"
                  @change="
                    $emit(
                      'updateData',
                      idClinicRowTemp,
                      inputValues,
                      conditionsInput,
                      type,
                      id
                    )
                  "
                />
                <label for="flexCheckDefault">
                  {{ el }}
                </label>
              </div>
            </div>
            <div v-else>
              <div v-if="conditionsInput[indexR][index].data">
                {{ conditionsInput[indexR][index].data }}
              </div>
              <li v-else>- -</li>
            </div>
          </div>
          <div v-else-if="i.type === 'draw'" class="p-2">
            <VueDrawingCanvas
              :ref="'VueCanvasDrawing' + idClinicRowTemp + '_' + index"
              :id-clinic-row-temp="idClinicRowTemp"
              :input-values="inputValues"
              :conditions-input="conditionsInput"
              :conditions-input-values="conditionalInputValues"
              :id-update="id"
              :index="index"
              :index-r="indexR"
              :canvas-id="'VueCanvasDrawing' + idClinicRowTemp + '_' + index"
              :scheda-type="configuration.description_type"
              :pet_id="pet_id"
              :type="i.type"
              :id-section="iR.id_section"
              :inputLenght="inputLenght"
              :column-4="column4"
              :column-3="column3"
              :column-2="column2"
              @setValue="setImageDraw"
            ></VueDrawingCanvas>
          </div>
          <div v-else-if="i.type === 'image'" class="p-2">
            <VueDrawingCanvas
              :ref="'VueCanvasDrawing' + idClinicRowTemp + '_' + index"
              :id-clinic-row-temp="idClinicRowTemp"
              :input-values="inputValues"
              :conditions-input="conditionsInput"
              :conditions-input-values="conditionalInputValues"
              :id-update="id"
              :index="index"
              :index-r="indexR"
              :canvas-id="'VueCanvasDrawing' + idClinicRowTemp + '_' + index"
              :scheda-type="configuration.description_type"
              :pet_id="pet_id"
              :type="i.type"
              :id-section="iR.id_section"
              :inputLenght="inputLenght"
              :column-4="column4"
              :column-3="column3"
              :column-2="column2"
              @setValue="setImageDraw"
            ></VueDrawingCanvas>
          </div>

          <div v-else-if="i.type === 'video'" class="p-2">
            <img :src="anteprima" class="h-32 w-32" />
            <label>
              {{ bio }}
            </label>
          </div>

          <div v-else-if="i.type === 'checkbox'" class="p-2 flex flex-col">
            <div v-if="modify === true" class="grid grid-cols-12 gap-1">
              <div
                v-for="el in i.options"
                :key="el"
                class=""
                :class="
                  column4 || column3 || column2
                    ? 'col-span-12 '
                    : i.options.length >= 4
                    ? i.options.find((elem) => elem.length > 15)
                      ? 'col-span-12 semilg:col-span-4 '
                      : i.options.find((elem) => elem.length < 4)
                      ? 'col-span-12 semilg:col-span-1 '
                      : 'col-span-12 semilg:col-span-3'
                    : i.options.find((elem) => elem.length <= 3)
                    ? 'col-span-12 semilg:col-span-1 '
                    : i.options.find((elem) => elem.length > 15)
                    ? 'col-span-12 semilg:col-span-4 '
                    : 'col-span-12 semilg:col-span-3 '
                "
              >
                <input
                  v-model="conditionsInput[indexR][index].data"
                  class="custom-input"
                  type="checkbox"
                  :value="el"
                  :class="modify === false ? 'pointer-events-none' : ''"
                />
                <label for="flexCheckDefault">
                  {{ el }}
                </label>
              </div>
            </div>
            <div v-else>
              <div v-for="x in conditionsInput[indexR][index].data" :key="x">
                {{ x ? x : '' }}
              </div>
              <!-- <li v-if="conditionsInput[indexR][index].data.length === 0">
                - -
              </li> -->
            </div>
          </div>
          <div v-else-if="i.type === 'select'" class="p-2">
            <v-select
              v-if="modify"
              v-model="conditionsInput[indexR][index].data"
              class=""
              :options="i.options"
              :class="!modify ? 'disabled' : ''"
              placeholder=""
              label="name"
            />
            <span v-else> {{ conditionsInput[indexR][index].data }} </span>
          </div>
          <div v-else-if="i.type === 'sub-title'" class="p-2">
            <span class="">{{ i.label }}</span>
          </div>
          <div v-else-if="i.type === 'sub-sub-title'" class="p-2">
            <span class="">{{ i.label }}</span>
          </div>

          <div
            v-else-if="i.type === 'table'"
            class="p-2"
            @scroll.passive="true"
          >
            <div
              v-for="(h, indexH) in i.data[0]"
              :key="indexH"
              class="flex flex-col gap-1 p-2 items-end"
            >
              <div
                v-for="(e, indexE, indexE_number) in h"
                :key="indexE"
                class=""
              >
                <span>{{ i.headers[indexE_number] }} </span>
                <input
                  v-model="
                    conditionsInput[indexR][index].data[0][indexH][indexE]
                  "
                  class="custom-input"
                  :type="
                    i.headers[indexE_number].toLowerCase() === 'data'
                      ? 'date'
                      : 'text'
                  "
                  :readonly="!modify"
                />
              </div>
              <button
                type="button"
                @click="
                  removeTableElementConditional(indexR, indexNumber, indexH)
                "
              >
                <MinusCircleIcon class="w-5 mr-2" /> Rimuovi elemento
              </button>
            </div>

            <button
              type="button"
              @click="addTableElementConditional(indexR, indexNumber, index)"
            >
              <PlusCircleIcon class="w-5 mr-2" /> Aggiungi elemento
            </button>
          </div>

          <!-- <div
            v-else-if="i.type === 'table'"
            class="p-2"
            @scroll.passive="true"
          >
            <div
              v-for="(h, indexH) in conditionalInputValues[indexR][indexNumber]"
              :key="indexH"
              class="flex flex-col gap-1 p-2 items-end"
            >
              <div
                v-for="(e, indexE, indexE_number) in conditionalInputValues[
                  indexR
                ][indexNumber][indexH]"
                :key="indexE"
                class=""
              >
                <span>{{ i.headers[indexE_number] }} </span>
                <input
                  v-model="
                    conditionalInputValues[indexR][indexNumber][indexH][indexE]
                  "
                  class="custom-input"
                  type="text"
                  :readonly="!modify"
                />
              </div>
              <button
                type="button"
                @click="
                  removeTableElementConditional(indexR, indexNumber, indexH)
                "
              >
                <MinusCircleIcon class="w-5 mr-2" /> Rimuovi elemento
              </button>
            </div>

            <button
              type="button"
              @click="addTableElementConditional(indexR, indexNumber, index)"
            >
              <PlusCircleIcon class="w-5 mr-2" /> Aggiungi elemento
            </button>
          </div> -->

          <div v-if="buttons" class="flex flex-row p-2">
            <button
              v-for="el in buttons"
              :key="el"
              class="gray-btn-inverted flex items-center mx-auto"
              :class="!modify ? 'disabled' : ''"
              @click="handleButtonAction(el)"
            >
              {{ el.name }}
            </button>
          </div>
          <div v-if="type === 'video'" class="flex flex-row p-2">
            <button class="gray-btn-inverted flex items-center mx-auto">
              Vedi Video
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from 'vue'
// import axios from '../../gateway/backend-api'
// import VueDrawingCanvas from 'vue-drawing-canvas'
import VueDrawingCanvas from '../../global-components/draw/VueDrawingCanvas.vue'

import { useStore } from '@/store'
// import TableDetailModal from './TableDetailModal.vue'

export default defineComponent({
  components: { VueDrawingCanvas },
  props: [
    'idClinicRowTemp',
    'title',
    'value',
    'type',
    'buttons',
    'options',
    'modify',
    'imageBg',
    'anteprima',
    'bio',
    'elem',
    'id',
    'gray',
    'configuration',
    'pet_id',
    'pet-type',
    'refreshTriggerRichiamo',
    'richiamoDate'
  ],

  emits: [
    'openSpecialisticModal',
    'updateData',
    'openAgendaModal',
    'openTariffarioModal'
  ],
  setup(props, { emit }) {
    // setup(props) {
    const store = useStore()
    const column2 = ref(false)
    const column3 = ref(false)
    const column4 = ref(false)
    const inputValues = ref([])
    const inputHeight = ref('')
    const backgroundImages = ref([])
    const texareaEdits = ref({})

    const conditionsInput = ref([])
    const conditionalInputValues = ref([])

    const allegatiConfig = ['Nome', 'Data', '']
    const uploadData = ref(false)

    const checkboxSelectValue = ref(null)

    Object.keys(props.elem).forEach(function (k) {
      // let showSection = false

      if (k.includes('elemento') && props.elem[k].type === 'textarea-edit') {
        if (props.elem[k].data) {
          texareaEdits.value[k] = props.elem[k].data
        } else {
          texareaEdits.value[k] = ''
        }
      }
    })

    for (let i = 0; props.elem['elemento_' + i] !== undefined; i++) {
      if (props.elem['elemento_' + i].data) {
        if (
          props.elem['elemento_' + i].type == 'upload' ||
          props.elem['elemento_' + i].type == 'video'
        )
          uploadData.value = true
        inputValues.value.push(props.elem['elemento_' + i].data)
      } else {
        if (props.elem['elemento_' + i].richiamoValue) {
          inputValues.value.push(props.elem['elemento_' + i].richiamoValue)
        } else {
          if (
            props.elem['elemento_' + i].type === 'checkbox' ||
            props.elem['elemento_' + i].type === 'checkbox-linear' ||
            props.elem['elemento_' + i].type === 'checkbox-sub-linear' ||
            props.elem['elemento_' + i].type === 'upload' ||
            props.elem['elemento_' + i].type === 'video'
          ) {
            inputValues.value.push([])
          } else {
            if (props.elem['elemento_' + i].type === 'checkbox-text') {
              inputValues.value.push({ data: [], text: [] })
            } else {
              if (
                props.elem['elemento_' + i].type === 'table' ||
                props.elem['elemento_' + i].type === 'table_simple'
              ) {
                if (props.elem['elemento_' + i].data) {
                  inputValues.value.push(props.elem['elemento_' + i].data)
                } else {
                  let newLine = {}
                  for (
                    let y = 1;
                    y <= props.elem['elemento_' + i].headers.length;
                    y++
                  ) {
                    newLine = { ...newLine, ['header_' + y]: '' }
                  }
                  inputValues.value.push({ row_0: newLine })
                }
              } else {
                if (props.elem['elemento_' + i].type === 'exams') {
                  inputValues.value.push([
                    { id: 1, value: 'emocromo', label: 'Esame Emocromo' },
                    {
                      id: 2,
                      value: 'biochimico',
                      label: 'Esame Biochimico'
                    }
                  ])
                } else {
                  inputValues.value.push(null)
                }
              }
            }
          }
        }
      }
      if (props.elem.condictions) {
        Object.keys(props.elem.condictions).forEach(function (k) {
          let tempJ = {}
          Object.keys(props.elem.condictions[k]).forEach(function (e) {
            if (props.elem.condictions[k][e].data) {
              tempJ[e] = props.elem.condictions[k][e]
            }
          })
          let resp = false
          Object.keys(tempJ).forEach(function (e) {
            if (e) {
              resp = true
            }
          })
          if (resp === true) {
            conditionsInput.value.push({
              ...tempJ,
              condition: k,
              label:
                props.elem.condictions[k] && props.elem.condictions[k].label
                  ? props.elem.condictions[k].label
                  : '',
              id_section:
                props.elem.condictions[k] &&
                props.elem.condictions[k].id_section
                  ? props.elem.condictions[k].id_section
                  : '',
              is_true:
                props.elem.condictions[k] && props.elem.condictions[k].is_true
                  ? props.elem.condictions[k].is_true
                  : ''
            })
          }
        })
      }
    }

    emit(
      'updateData',
      props.idClinicRowTemp,
      inputValues.value,
      conditionsInput.value,
      props.type,
      props.id
    )

    const handleFileChangeImage = (e, index) => {
      if (
        e.target.files[0].type.includes('image') ||
        e.target.files[0].type.includes('pdf')
      ) {
        inputValues.value[index].push(e.target.files[0])
        emit(
          'updateData',
          props.idClinicRowTemp,
          inputValues.value,
          conditionsInput.value,
          props.type,
          props.id
        )
      } else {
        store.dispatch('commonComponents/openMessageModal', {
          title: 'Formato di file non valido',
          subtitle: 'Selezionare un tipo file giusto',
          background: false
        })
      }
    }

    const handleFileChangeVideo = (e, index) => {
      if (
        e.target.files[0].type.includes('video') &&
        e.target.files[0].size < 50000000
      ) {
        inputValues.value[index].push(e.target.files[0])
        emit(
          'updateData',
          props.idClinicRowTemp,
          inputValues.value,
          conditionsInput.value,
          props.type,
          props.id
        )
      } else if (
        e.target.files[0].type.includes('video') &&
        e.target.files[0].size > 50000000
      ) {
        store.dispatch('commonComponents/openMessageModal', {
          title: 'Il file è troppo grande',
          subtitle: 'Ridurre il peso, oppure selezionare un altro file',
          background: false
        })
      } else {
        store.dispatch('commonComponents/openMessageModal', {
          title: 'Formato di file non valido',
          subtitle: 'Selezionare un tipo file giusto',
          background: false
        })
      }
    }

    const removeFile = (index, elem) => {
      const temp = inputValues.value[index].filter((e) => {
        return e.name !== elem.name
      })
      inputValues.value[index] = temp
    }

    const handleTemplateModal = (oggetto, index, idClinicRowTemp) => {
      store.dispatch('commonComponents/openTemplateModal', {
        old_text: inputValues.value[index],
        external_id: oggetto.external_id,
        medicaltemplate_id: props.configuration ? props.configuration.id : null,
        confirm: (text) => {
          inputValues.value[index] = text

          emit(
            'updateData',
            idClinicRowTemp,
            inputValues.value,
            conditionsInput.value,
            props.type,
            props.id
          )
        }
      })
      // axios
      //   .get(
      //     '/' +
      //       localStorage.getItem('clinic') +
      //       '/medicalinputtemplate/' +
      //       oggetto.external_id
      //   ) // scarico la configurazione della tabella
      //   .then((resp) => {
      //     store.dispatch('commonComponents/openTemplateModal', {
      //       options: resp.data.options,
      //       confirm: () => {
      //         console.log('confermo')
      //       }
      //     })
      //   })
    }

    const image = ref('')

    const inputStyle = computed(() => {
      return {
        height: inputHeight.value
      }
    })

    const inputs = computed(() => {
      // return configurationData.value.filter((elem) => {
      //   return elem.type === 'section'
      // })

      let sec = []

      for (let i = 0; props.elem['elemento_' + i] !== undefined; i++) {
        sec.push(props.elem['elemento_' + i])
      }
      return sec
    })

    const exams = computed(() => {
      return store.state.reusableData.examsType
    })

    watch(
      () => props.refreshTriggerRichiamo,
      () => {
        inputs.value.forEach((input, index) => {
          if (input.richiamoValue) {
            inputValues.value[index] = input.richiamoValue
          }
        })
        emit(
          'updateData',
          props.idClinicRowTemp,
          inputValues.value,
          conditionsInput.value,
          props.type,
          props.id
        )
      },
      { deep: true }
    )

    watch(
      texareaEdits,
      (val, newVal) => {
        Object.keys(newVal).forEach(function (k, index) {
          inputValues.value[index] = newVal[k]
        })
      },
      { deep: true }
    )

    watch(
      inputValues,
      () => {
        if (props.elem.condictions) {
          Object.keys(props.elem.condictions).forEach(function (k) {
            let percorso = k.split('.')
            let inputValuesNew = []

            if (
              (props.elem[percorso[0]].options[percorso[2]] ===
                inputValues.value[percorso[0].split('_')[1]] &&
                props.elem[percorso[0]].type !== 'checkbox') ||
              (inputValues.value[percorso[0].split('_')[1]].includes(
                props.elem[percorso[0]].options[percorso[2]]
              ) &&
                props.elem[percorso[0]].type === 'checkbox')
            ) {
              if (!conditionsInput.value.some((el) => el.condition === k)) {
                let righe = {}
                Object.keys(props.elem.condictions[k]).forEach(function (e) {
                  if (e != 'id_section' && e != 'is_true') {
                    if (props.elem.condictions[k][e].type === 'table') {
                      let newLine = {}
                      for (
                        let y = 1;
                        y <= props.elem.condictions[k][e].headers.length;
                        y++
                      ) {
                        newLine = { ...newLine, ['header_' + y]: '' }
                      }
                      inputValuesNew.push({ row_0: newLine })

                      righe[[e]] = {
                        ...props.elem.condictions[k][e],
                        data: inputValuesNew
                      }
                    } else {
                      righe[[e]] = {
                        ...props.elem.condictions[k][e],
                        data: ''
                      }
                    }
                  }
                })

                conditionsInput.value.push({
                  ...righe,
                  condition: k,
                  label: props.elem.condictions[k].label
                    ? props.elem.condictions[k].label
                    : '',
                  id_section: props.elem.condictions[k].id_section
                    ? props.elem.condictions[k].id_section
                    : '',
                  is_true: props.elem.condictions[k].is_true
                    ? props.elem.condictions[k].is_true
                    : ''
                })

                Object.keys(props.elem.condictions[k]).forEach(function (e) {
                  if (e != 'id_section' && e != 'is_true') {
                    if (props.elem.condictions[k][e].type === 'table') {
                      if (props.elem.condictions[k][e].data) {
                        conditionalInputValues.value.push(
                          props.elem.condictions[k][e].data
                        )
                      } else {
                        let newLine = {}
                        for (
                          let y = 1;
                          y <= props.elem.condictions[k][e].headers.length;
                          y++
                        ) {
                          newLine = { ...newLine, ['header_' + y]: '' }
                        }
                        inputValuesNew.push({ row_0: newLine })
                      }
                    } else {
                      inputValuesNew.push('')
                    }
                  }
                })

                conditionalInputValues.value.push(inputValuesNew)
              }
            } else {
              conditionsInput.value = conditionsInput.value.filter(
                (elem, index) => {
                  if (elem.condition === k) {
                    conditionalInputValues.value.splice(index, 1)
                    return false
                  } else return true
                }
              )
            }
          })
        }
        if (inputs.value[1] && inputs.value[1].type === 'function') {
          if (
            inputValues.value[0] &&
            inputValues.value[0] !== undefined &&
            inputValues.value[0] !== ''
          ) {
            if (props['petType'] && props['petType'] === 'Cane') {
              inputValues.value[1] = (
                (10.1 * Math.pow(inputValues.value[0] * 1000, 2 / 3)) /
                10000
              ).toFixed(3)
            } else {
              inputValues.value[1] = (
                (10 * Math.pow(inputValues.value[0] * 1000, 2 / 3)) /
                10000
              ).toFixed(3)
            }
          } else {
            inputValues.value[1] = ''
          }
        }
      },
      { deep: true }
    )

    return {
      inputValues,
      image,
      inputs,
      inputStyle,
      inputHeight,
      conditionsInput,
      conditionalInputValues,
      handleFileChangeImage,
      handleFileChangeVideo,
      allegatiConfig,
      uploadData,
      removeFile,
      backgroundImages,
      texareaEdits,
      column4,
      column3,
      column2,
      checkboxSelectValue,
      exams,
      handleTemplateModal
    }
  },
  data() {
    return { expandTxt: '', inputLenght: false }
  },
  mounted() {
    this.hideTittle()
    if (this.elem && this.elem.display) {
      if (this.elem.display == 4) this.column4 = true
      if (this.elem.display == 3) this.column3 = true
      if (this.elem.display == 2) this.column2 = true
    }
  },

  methods: {
    handleQuilltextChange(
      idClinicRowTemp,
      inputValues,
      conditionsInput,
      type,
      id,
      index
    ) {
      if (
        this.texareaEdits['elemento_' + index].includes('color: transparent;')
      ) {
        this.texareaEdits['elemento_' + index] = this.texareaEdits[
          'elemento_' + index
        ].replace('color: transparent;', '')
      }
      this.$emit(
        'updateData',
        idClinicRowTemp,
        inputValues,
        conditionsInput,
        type,
        id
      )
    },
    hideTittle() {
      let title = document.getElementById('title')

      if (this.inputs.length > 4) {
        title.classList.add('hidden')
      }
    },

    handleImgDraw() {},
    closeTextArea() {
      this.expandTxt = false
    },

    handleTextArea() {
      this.expandTxt = true
    },
    handleHeight(h) {
      this.inputHeight = `${h}px`
    },
    handleButtonAction(button) {
      if (button) {
        switch (button.name) {
          case 'templates':
            this.$emit('openSpecialisticModal')
            break
          // case 'storico':
          //   break
          default:
            break
        }
      }
    },
    updateValue(value, index, idClinicRowTemp, id) {
      this.inputValues[index] = value

      this.$emit(
        'updateData',
        idClinicRowTemp,
        this.inputValues,
        this.conditionsInput,
        this.type,
        id
      )
    },
    addTableElement(index) {
      // this.inputValues[index].push(
      //   Array(this.inputs[index].headers.length).join('.').split('.')
      // )
      let row = this.getLastRow(this.inputValues[index])
      let newLine = {}
      for (let i = 1; i <= this.inputs[index].headers.length; i++) {
        //if (typeof this.inputs[index].headers[i] === 'string')
        newLine = { ...newLine, ['header_' + i]: '' }
      }
      this.inputValues[index]['row_' + row] = newLine
    },
    removeTableElement(index, indexH) {
      let number = this.getNumRow(this.inputValues[index])
      if (number > 1) {
        delete this.inputValues[index][indexH]
      }
    },
    addTableElementConditional(
      indexCondition,
      indexElement,
      indexElement_text
    ) {
      // this.inputValues[index].push(
      //   Array(this.inputs[index].headers.length).join('.').split('.')
      // )

      let row = this.getLastRow(
        this.conditionalInputValues[indexCondition][indexElement]
      )
      let newLine = {}
      for (
        let i = 1;
        i <=
        this.conditionsInput[indexCondition][indexElement_text].headers.length;
        i++
      ) {
        newLine = { ...newLine, ['header_' + i]: '' }
      }
      this.conditionalInputValues[indexCondition][indexElement]['row_' + row] =
        newLine
    },
    removeTableElementConditional(indexCondition, indexElement, indexRow) {
      let number = this.getLastRow(
        this.conditionalInputValues[indexCondition][indexElement]
      )
      if (number > 1) {
        delete this.conditionalInputValues[indexCondition][indexElement][
          indexRow
        ]
      }
    },
    getNumRow(elem) {
      let j = 0
      let tot = Object.keys(elem).length
      for (let i = 0; j < tot; i++) {
        if (elem['row_' + i] !== undefined) j++
      }
      return j
    },

    getLastRow(elem) {
      let j = 0
      for (let i = 0; elem['row_' + i] !== undefined; i++) {
        j++
      }
      return j
    },
    setImageDraw(
      image,
      strokes,
      index,
      indexR,
      idClinicRowTemp,
      type,
      id,
      insert
    ) {
      if (typeof indexR != 'undefined') {
        this.conditionsInput[indexR][index].data = {
          image: image,
          strokes: strokes,
          display_image: insert
        }
      } else {
        this.inputValues[index] = {
          image: image,
          strokes: strokes,
          display_image: insert
        }
      }

      this.$emit(
        'updateData',
        idClinicRowTemp,
        this.inputValues,
        this.conditionsInput,
        type,
        id
      )
    }
  }
})
</script>

<style>
.vcpg,
.vcp__header {
  --bg-color-body: #fff;
  --bg-color-header: #a8a8a8;
  --base-color: #a8a8a8;
  --border-color: #a8a8a8;
  --bg-color-header-hover: #585858;
  --bg-color-header-active: #a8a8a8;
}

.vcp__header {
  border-radius: 3px;
  color: #313131;
  text-transform: uppercase;
}

.vcp__header:hover {
  color: white;
}
.vcp__body-content {
  padding: 0 !important;
}

/* .vcp__header {
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
} */

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  top: 2rem;
  width: 35rem;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 1rem 2rem;
  position: absolute;
  z-index: 1;
  /* bottom: -10rem; */
  left: -2.3rem;
  /* margin-left: -60px;*/
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 3rem;
  transform: rotate(180deg);
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tooltipbottom {
  position: relative;
  display: inline-block;
}

.tooltipbottom .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: -100%;
  margin-left: -60px;
}

.tooltipbottom .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 82%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  transform: rotate(180deg);
}

.tooltipbottom:hover .tooltiptext {
  visibility: visible;
}
.tooltipbottom:focus .tooltiptext {
  visibility: visible;
}
.tooltipbottom:active .tooltiptext {
  visibility: visible;
}

.tooltiptop {
  position: relative;
  display: inline-block;
}

.tooltiptop .tooltiptext {
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -300%;
  left: 0%;
  margin-left: -90px;
}

.tooltiptop .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 82%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  transform: rotate(0deg);
}

.tooltiptop:hover .tooltiptext {
  visibility: visible;
}
.tooltiptop:focus .tooltiptext {
  visibility: visible;
}
.tooltiptop:active .tooltiptext {
  visibility: visible;
}

.tooltiptopstats {
  position: relative;
  display: inline-block;
  vertical-align: top;
}

.tooltiptopstats .tooltiptext {
  display: flex;
  visibility: hidden;
  width: 200px;
  background-color: black;
  color: #fff;
  text-align: center;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
}

.tooltiptext {
  margin-bottom: 100px;
}

.tooltiptopstats .tooltiptext::after {
  display: none;
  content: '';
  position: absolute;
  top: -5%;
  left: 60%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
  transform: rotate(180deg);
}

.tooltiptopstats:hover .tooltiptext {
  visibility: visible;
}
.tooltiptopstats:focus .tooltiptext {
  visibility: visible;
}
.tooltiptopstats:active .tooltiptext {
  visibility: visible;
}
.tooltipbutton {
  position: relative;
  display: inline-block;
}

.tooltipbutton .tooltipbuttontext {
  visibility: hidden;
  width: 120px;
  font-size: 12px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -150%;
  left: -250%;
  font-family: Roboto;
  text-transform: uppercase;
  font-weight: 400;
  color: white;
}

.tooltipbutton .tooltipbuttontext::after {
  content: '';
}

.tooltipbutton:hover .tooltipbuttontext {
  transition-delay: 1s;
  visibility: visible;
}
.tooltipbutton:focus .tooltipbuttontext {
  visibility: visible;
}
.tooltipbutton:active .tooltipbuttontext {
  visibility: visible;
}

.tooltipbuttonprofile {
  position: relative;
  display: inline-block;
}

.tooltipbuttonprofile .tooltipbuttontext {
  visibility: hidden;
  width: 120px;
  font-size: 12px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -80%;
  left: -115%;
}

.tooltipbuttonprofile .tooltipbuttontext::after {
  content: '';
}

.tooltipbuttonprofile:hover .tooltipbuttontext {
  transition-delay: 1s;
  visibility: visible;
}
.tooltipbuttonprofile:focus .tooltipbuttontext {
  visibility: visible;
}
.tooltipbuttonprofile:active .tooltipbuttontext {
  visibility: visible;
}
</style>
