<template>
  <!-- Main modal -->

  <div
    class="fixed z-50 inset-0 transition-opacity bg-black bg-opacity-50 overflow-scroll scrollbar-none"
  >
    <div
      class="relative top-1 lg:top-8 p-2 mx-auto border w-4/5 lg:w-3/5 shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="flex flex-col content-end">
        <!-- Modal header -->
        <div class="mb-9">
          <button type="button" class="closing-btn m" @click="$emit('close')">
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        <div class="my-2 mx-2">
          <h3 class="modal-title">{{ title }}</h3>
        </div>

        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div class="mr-3 mt-3 gap-3 flex justify-end">
          <button
            :class="[
              isMedical
                ? 'orange-btn hover:bg-theme-41 hover:text-white hover:border-theme-41'
                : 'orange-btn-disable'
            ]"
            @click.prevent="isMedical = true"
          >
            <div class="font-semibold">Check-in medico</div>
          </button>

          <button
            :class="[
              !isMedical
                ? 'orange-btn hover:bg-theme-41 hover:text-white hover:border-theme-41'
                : 'orange-btn-disable'
            ]"
            @click.prevent="isMedical = false"
          >
            <div class="font-semibold">Check-in non medico</div>
          </button>
        </div>
        <div class="bg-white py-3 px-6 rounded-2xl mt-3 scrollbar-none">
          <div class="pb-3 grid grid-cols-2 gap-2 scrollbar-none">
            <div class="pt-3 col-span-2 md:col-span-1 relative">
              <label for="new-enter-cliente" class="font-medium form-label"
                >Nome del Cliente</label
              >
              <div class="mt-1">
                <div class="flex gap-1 justify-between">
                  <input
                    id="new-arrive-cliente"
                    ref="input2"
                    v-model="newArrive.client"
                    type="text"
                    class="flex-1 custom-input"
                    placeholder="Nome del cliente"
                    value
                    required
                    @click.stop="keyOnCustomer()"
                    @keyup="keyOnCustomer()"
                    @keyup.enter="switchFocus(3)"
                    @keyup.left="switchFocus(1)"
                  />
                </div>
                <div class="">
                  <SearchDrop
                    v-if="isActiveSearchCustomer"
                    class="z-50 w-96 search-result bg-theme-44 bg-opacity-80 overflow-scroll scrollbar-none rounded-xl absolute invisible opacity-0 mt-4 py-4 px-3 child-centered top-16"
                    :filter-categories="['customer']"
                    :pet-invoice="petData"
                    :filter="newArrive.client"
                    @childToParent="onChildClick"
                    @closeDropDownSearch="isActiveSearchCustomer = false"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="showSearch"
              class="py-3 col-span-2 md:col-span-1 relative"
            >
              <label for="new-enter-animale" class="font-medium form-label"
                >Nome dell'animale</label
              >
              <!-- id prima era modal-form-1 -->
              <div class="mt-1">
                <div class="flex gap-1 justify-between relative">
                  <input
                    id="new-arrive-animale"
                    ref="input3"
                    v-model="newArrive.pet"
                    type="text"
                    class="flex-1 custom-input"
                    placeholder="Nome dell'animale"
                    required
                    @click.stop="keyOnPet()"
                    @keyup="keyOnPet()"
                    @keyup.enter="switchFocus(4)"
                    @keyup.left="switchFocus(2)"
                  />
                </div>
                <div class="">
                  <SearchDrop
                    v-if="isActiveSearchPet"
                    class="z-50 w-96 search-result bg-theme-44 bg-opacity-80 overflow-scroll scrollbar-none rounded-xl absolute invisible opacity-0 mt-4 py-4 px-3 child-centered top-16"
                    :filter-categories="['pet']"
                    :customer-invoice="clientData"
                    :filter="newArrive.pet"
                    @childToParent="onChildClick"
                    @closeDropDownSearch="isActiveSearchPet = false"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="!isMedical" class="my-3">
            <div class="grid grid-cols-2 gap-2">
              <div class="flex-col col-span-2 md:col-span-1 overflow-visible">
                <label for="new-enter-reason" class="font-medium form-label">
                  Motivazione
                </label>
                <v-select
                  v-model="newArrive.selected_reason"
                  :options="nonMedical"
                  label="name"
                  :reduce="(value) => value.name"
                  placeholder="Seleziona il motivo"
                  class="py-2 flex-1"
                >
                  <template #option="option">
                    {{ option.name }}
                  </template>
                </v-select>
              </div>
              <div class="flex-col col-span-2 md:col-span-1">
                <label for="new-enter-referente" class="font-medium form-label">
                  Referente
                </label>
                <v-select
                  v-model="newArrive.selected_staff"
                  :options="staff"
                  label="last_name"
                  :reduce="(value) => value.id"
                  placeholder="Seleziona il referente"
                  class="py-2 flex-1"
                >
                  <template #selected-option="option">
                    {{ option.first_name }} {{ option.last_name }}
                  </template>
                  <template #option="option">
                    {{ option.first_name }} {{ option.last_name }}
                  </template>
                </v-select>
              </div>
              <div class="flex-col col-span-2">
                <label for="new-enter-notes" class="font-medium form-label">
                  Note
                </label>

                <textarea
                  id="newclient-note"
                  v-model="newArrive.notes"
                  class="custom-input h-24 py-2 mt-1"
                  type="text"
                  placeholder="Inserisci le note"
                />
              </div>
            </div>
          </div>

          <div v-else>
            <div class="grid grid-cols-2 gap-2">
              <div class="flex-col col-span-2 md:col-span-1">
                <label for="new-enter-room" class="font-medium form-label">
                  Stanza
                </label>
                <v-select
                  v-model="newArrive.selected_room"
                  :options="rooms"
                  label="name"
                  :reduce="(value) => value.id"
                  placeholder="Seleziona la stanza"
                  class="py-2"
                >
                  <template #option="option">
                    {{ option.department_name }} - {{ option.name }}
                  </template>
                </v-select>
              </div>
              <div class="flex-col col-span-2 md:col-span-1">
                <label for="new-enter-reparto" class="font-medium form-label">
                  Codice</label
                >
                <v-select
                  v-model="newArrive.selected_cod"
                  :options="codice"
                  label="name"
                  :reduce="(value) => value.name"
                  placeholder="Seleziona il codice"
                  class="py-2"
                >
                  <template #option="option">
                    <span class="capitalize"> {{ option.name }}</span>
                  </template>
                </v-select>
              </div>
            </div>
            <div class="grid grid-cols-2 gap-2 mt-3">
              <div class="flex-col col-span-2 md:col-span-1">
                <label for="new-enter-reparto" class="font-medium form-label">
                  Medico responsabile</label
                >
                <v-select
                  v-model="newArrive.selected_staff"
                  :options="staff"
                  label="last_name"
                  :reduce="(value) => value.id"
                  placeholder="Seleziona il medico responsabile"
                  class="py-2"
                >
                  <template #selected-option="option">
                    {{ option.first_name }} {{ option.last_name }}
                  </template>
                  <template #option="option">
                    {{ option.first_name }} {{ option.last_name }}
                  </template>
                </v-select>
              </div>
              <div class="flex-col col-span-2 md:col-span-1">
                <label for="new-enter-reparto" class="font-medium form-label">
                  Referente esterno</label
                >
                <v-select
                  v-model="newArrive.selected_external"
                  class="py-2"
                  placeholder="Seleziona il referente esterno"
                  :options="externals"
                  :reduce="(value) => value.id"
                  label="last_name"
                >
                  <template #selected-option="option">
                    {{ option.first_name }} {{ option.last_name }}
                  </template>
                  <template #option="option">
                    {{ option.first_name }} {{ option.last_name }}
                  </template>
                </v-select>
              </div>
            </div>
            <div class="flex-col col-span-2">
              <label for="new-enter-reason" class="font-medium form-label">
                Motivazione
              </label>

              <textarea
                id="newarrive-reason"
                v-model="newArrive.reason"
                class="custom-input h-24 py-2 mt-1"
                type="text"
                placeholder="Inserisci la motivazione"
              />
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="flex justify-end items-end mb-2 gap-2 mx-6">
          <button
            ref="input4"
            type="button"
            class="orange-btn"
            :class="!selected ? 'disabled' : ''"
            @click="addToWaitingList"
          >
            <i class="fas fa-sign-in-alt mr-1" /> Metti in sala
          </button>
          <button type="button" class="gray-btn" @click="$emit('close')">
            Annulla
          </button>
        </div>
        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

import axios from '../../gateway/backend-api'
import '@swoga/vue-collapsible-panel/dist/vue-collapsible-panel.css'

import SearchDrop from '../search-drop/SearchDrop.vue'

export default defineComponent({
  components: { SearchDrop },
  props: ['options', 'title', 'pet', 'customer'],
  emits: ['close', 'specialisticSelected'],

  setup(_, { emit }) {
    // ----- DATA -----
    const selected = ref('')
    const searchBarPet = ref('')
    const isActiveSearchPet = ref(false)
    const selectedPet = ref(null)

    // contiene la copia della configurazione, mappando però i valori con quelli inseriti in input

    // ----- METHODS -----

    // abilito la modifica del modale

    const select = (x) => {
      // salva dati
      selected.value = x
    }

    const dropDownSearchPet = () => {
      isActiveSearchPet.value = !isActiveSearchPet.value
    }

    const selectPet = (pet) => {
      isActiveSearchPet.value = false
      selectedPet.value = pet
    }

    const salva = () => {
      // salva dati
      emit('specialisticSelected', selected.value)
    }

    // ----- COMPUTED -----

    // righe di config senza le sottosezioni

    return {
      selected,
      searchBarPet,
      selectedPet,
      dropDownSearchPet,
      select,
      salva,
      selectPet
    }
  },
  data() {
    return {
      showSearch: true,
      isActiveSearchCustomer: false,
      isActiveSearchPet: false,
      isMedical: true,
      staff: [],
      rooms: [],
      clientData: {},
      petData: {},
      newArrive: {
        client: '',
        customer_id: '',
        pet_id: '',
        pet: ''
      },
      reparto: [
        {
          id: 1,
          name: 'Medico'
        },
        {
          id: 2,
          name: 'Chirurgia'
        },
        {
          id: 3,
          name: 'Degenza'
        }
      ],
      codice: [
        {
          id: 1,
          name: 'bianco'
        },
        {
          id: 2,
          name: 'verde'
        },
        {
          id: 3,
          name: 'giallo'
        },
        {
          id: 4,
          name: 'rosso'
        }
      ],
      nonMedical: [
        {
          id: 1,
          name: 'Saldo sospeso'
        },
        {
          id: 2,
          name: 'Saldo prestazioni'
        },
        {
          id: 3,
          name: 'Acquisto prodotti'
        },
        {
          id: 4,
          name: 'Ritiro referti'
        }
      ]
    }
  },

  watch: {
    'newArrive.pet': function (elem) {
      if (elem == '') {
        if (this.newArrive.client == '') {
          // Se non avevo selezionato il cliente cancello il clientData da passare nella searchDrop
          this.clientData = {}
        }
        this.petData = {}
      }
    },
    'newArrive.client': function (elem) {
      if (elem == '') {
        if (this.newArrive.pet == '') {
          // Se non avevo selezionato l'animale cancello il petData da passare nella searchDrop
          this.petData = {}
        }
        this.clientData = {}
      }
    }
  },
  mounted() {
    axios.post('/' + localStorage.getItem('clinic') + '/staff').then((res) => {
      this.staff = res.data
    })
    axios
      .get('/' + localStorage.getItem('clinic') + '/departments/clinic-room')
      .then((res) => {
        this.rooms = res.data
      })
    axios
      .post(
        '/' + localStorage.getItem('clinic') + '/external/index?type=referent'
      )
      .then((res) => {
        this.externals = res.data.data
        this.totalRecords = res.data.total
      })
    // Se il check-in arriva dalla scheda cliente non mostro le search per selezionarlo
    if (this.pet) {
      this.showSearch = false
      this.petData = this.pet
      this.newArrive.pet_id = this.pet.id
      this.newArrive.pet = this.pet.name
      this.newArrive.client =
        this.pet.owners[0].last_name + ' ' + this.pet.owners[0].first_name
      this.newArrive.customer_id = this.pet.owners[0].id
      this.clientData = this.pet.owners[0]
    }
  },
  methods: {
    keyOnCustomer() {
      this.isActiveSearchCustomer = true
      this.isActiveSearchPet = false
    },
    keyOnPet() {
      this.isActiveSearchCustomer = false
      this.isActiveSearchPet = true
    },
    onChildClick(value, filter) {
      if (filter[0] == 'customer') {
        this.newArrive.client = value.last_name + ' ' + value.first_name
        this.newArrive.customer_id = value.id
        this.clientData = value
      }
      if (filter[0] == 'pet') {
        this.newArrive.pet = value.name
        this.newArrive.pet_id = value.id
        this.petData = value
      }
      this.isActiveSearchPet = false
      this.isActiveSearchCustomer = false
    },
    addToWaitingList() {
      // POST per inserire in sala d'attesa
      if (this.newArrive.pet_id == '' || this.newArrive.pet_id == '') {
        // GENERARE ERRORE PER CAMPO OBBLIGATORIO
      } else {
        let reason
        if (this.isMedical) {
          reason = this.newArrive.reason
        } else {
          reason = this.newArrive.selected_reason
        }
        axios
          .post('/' + localStorage.getItem('clinic') + '/waiting-room', {
            type: 'waiting',
            customer_id: this.newArrive.customer_id,
            pet_id: this.newArrive.pet_id,
            medical: this.isMedical,
            code: this.newArrive.selected_cod,
            external_id: this.newArrive.selected_external,
            clinic_room_id: this.newArrive.selected_room,
            department_id: this.newArrive.selected_reparto,
            staff_user_id: this.newArrive.selected_staff,
            reason: reason
          })
          .then(() => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: "Paziente inserito correttamento in sala d'attesa"
            })
            this.$emit('close')
          })
          .catch((e) => {
            if (e.response.status == 403) {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: "Il paziente è già stato inserito in sala d'attesa"
              })
            }

            this.$emit('close')
          })
      }
    }
  }
})
</script>
