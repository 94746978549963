<template>
  <!-- BEGIN: Responsive Table -->
  <div class="intro-y box mt-5">
    <div class="p-5 text-center">
      <CheckCircleIcon class="w-16 h-16 text-theme-41 mx-auto mt-3" />
      <div class="text-3xl mt-5">Funzione presto disponibile</div>
    </div>
  </div>
  <!-- END: Responsive Table -->
</template>
