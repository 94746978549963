<template>
  <div>
    <div
      id="payMode-modal"
      class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50"
    >
      <div
        class="w-4/5 sm:w-3/5 relative top-12 p-5 mx-auto overflow-scroll scrollbar-none border shadow-lg rounded-md bg-white"
      >
        <button type="button" class="closing-btn" @click="$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>

        <div class="flex flex-col">
          <div class="w-full">
            <h3 class="modal-title mt-8">{{ titleText }}</h3>
          </div>

          <div v-if="showPayDefault == true && !isSetUp" class="mt-2 px-3">
            <p class="form-label">{{ selectText }}</p>

            <select
              v-if="pagamenti != ''"
              v-model="tipoPagamento"
              class="custom-input"
            >
              <template v-for="n in pagamenti" :key="n.id" :value="n">
                <option v-if="n.is_active" :value="n">
                  {{ n.code }} -
                  {{ n.alias ? n.alias : n.description }}
                </option>
              </template>
            </select>
          </div>
          <div v-if="isSetUp" class="my-2">
            <div class="flex flex-col md:flex-row md:items-center gap-3">
              <div class="flex-1">
                <p class="form-label mt-2 required">Nome metodo alias</p>
                <input v-model="nameAlias" type="text" class="custom-input" />
              </div>
              <div class="flex-1">
                <p class="form-label mt-2 required">Modalità di pagamento</p>
                <select v-model="aliasPagamento" class="custom-input">
                  <option v-for="n in pagamentiDefault" :key="n.id" :value="n">
                    {{ n.code }} -
                    {{ n.description }}
                  </option>
                </select>
              </div>
            </div>

            <div v-if="isRequired == true" class="my-2">
              <h2 class="text-theme-41 font-bold italic">
                Compilare i campi obbligatori
              </h2>
            </div>
            <div class="mt-4 flex justify-end items-center gap-3">
              <button type="button" class="orange-btn" @click="aggiungiAlias">
                <PlusIcon class="w-4 h-4" /> Aggiungi
              </button>

              <button
                type="button"
                class="gray-btn"
                @click="showAggiungiPagamento"
              >
                Annulla
              </button>
            </div>
          </div>
        </div>
        <!-- BEGIN: Footer per il modale in fatturazione -->
        <div
          v-if="!isSetUp"
          class="border-t border-gray-200 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
        >
          <div class="flex items-center gap-2 mt-2">
            <button
              id="dialogbox-delete-button"
              type="button"
              class="orange-btn"
              @click="aggiungiPagamento"
            >
              Aggiungi
            </button>

            <button
              id="dialogbox-anulla-button"
              autofocus
              type="button"
              class="gray-btn"
              @click="$emit('close')"
            >
              Chiudi
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from '../../gateway/backend-api'
export default {
  // Eventi personalizzati da richiamare nel componente FatturazioneAttiva
  props: {
    comingFromSetup: {
      type: [Object],
      default: function () {
        return {}
      }
    },
    defaultPayments: {
      type: [Object],
      default: function () {
        return {}
      }
    },
    payments: {
      type: [Array],
      default: function () {
        return []
      }
    }
  },
  emits: ['close', 'add', 'addPaymentSetup'],
  data() {
    return {
      pagamenti: [],
      pagamentiDefault: [],
      tipoPagamento: [],
      isSetUp: false,
      newPayment: false,
      showPayDefault: true,
      titleText: '',
      selectText: '',
      defaultPay: true,
      isRequired: false
    }
  },
  mounted() {
    this.pagamenti = this.payments
    this.pagamentiDefault = this.defaultPayments

    if (this.comingFromSetup != null && this.comingFromSetup == true) {
      this.isSetUp = true
      this.titleText = 'Aggiungi metodo di pagamento'
      this.selectText = 'Seleziona il metodo di pagamento di default'
    } else {
      this.titleText = 'Metodo di pagamento'
      this.selectText = 'Seleziona il metodo di pagamento'
    }
  },
  methods: {
    showAggiungiPagamento: function () {
      if (this.newPayment == false) {
        this.newPayment = true
        this.showPayDefault = false
      } else {
        this.newPayment = false
        this.showPayDefault = true
      }
    },
    aggiungiPagamento: function () {
      this.$emit('add', this.tipoPagamento)
    },
    salvaPagamentoDefault() {
      this.$emit('addPaymentSetup', this.tipoPagamento)
    },
    aggiungiAlias() {
      // Aggiungere un nuovo metodo di pagamento
      if (this.aliasPagamento && this.nameAlias) {
        this.isRequired = false
        var newPaymentMethod = {
          id: this.aliasPagamento.id,
          code: this.aliasPagamento.code,
          description: this.aliasPagamento.description,
          alias: this.nameAlias,
          is_active: true
        }
      } else {
        this.isRequired = true
      }
      if (newPaymentMethod) {
        newPaymentMethod.setup_type = 'dati_pagamento'
      }

      // Ricarico i metodi di pagamento disponibili aggiornati
      if (newPaymentMethod) {
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/bills/payments',
            newPaymentMethod
          )
          .then((res) => {
            this.showAggiungiPagamento()

            this.pagamenti.push(res.data)
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Nuovo metodo di pagamento aggiunto'
            })
            this.$emit('close')
          })
      }
    }
  }
}
</script>
