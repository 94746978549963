<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-opacity-50 bg-black scrollbar-none"
  >
    <div
      class="relative top-32 p-2 mx-auto h-auto overflow-scroll scrollbar-none border sm:w-3/5 w-full shadow-lg rounded-md bg-white"
    >
      <button type="button" class="closing-btn" @click="$emit('close')">
        <svg
          class="w-4 h-4"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <!-- Modal header -->
      <div class="">
        <h3 class="modal-title w-full mt-8">{{ title }}</h3>
      </div>

      <!-- Modal body -->
      <div
        class="flex flex-col md:flex-row md:items-center justify-between gap-4 px-6 py-2 my-2"
      >
        <div class="flex-1">
          <input
            v-model="name"
            type="text"
            placeholder="Inserisci nome"
            class="custom-input"
          />
        </div>
      </div>
      <!-- Modal footer -->
      <div class="flex justify-end items-center px-6 my-5">
        <button
          type="button"
          class="orange-btn"
          @click="$emit('confirm', name)"
        >
          <PlusCircleIcon class="h-5 w-5 mr-2" /> Aggiungi
        </button>

        <button class="ml-3 gray-btn" type="button" @click="$emit('close')">
          <span class="h-5"></span>
          Annulla
        </button>
      </div>
      <!-- Fine Modal footer -->
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'

export default defineComponent({
  props: ['title'],
  emits: ['close', 'confirm'],
  setup() {
    const name = ref('')
    // ----- DATA -----
    return { name }
  }
})
</script>
