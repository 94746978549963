<template>
  <div
    id="adv-search-modal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="z-40 relative top-12 px-2 md:px-6 pt-8 pb-3 mx-auto overflow-scroll scrollbar-none border w-11/12 md:w-3/5 shadow-lg rounded-md bg-white"
    >
      <button type="button" class="closing-btn" @click="closeModal">
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>

      <div id="adv-search-titolo" class="modal-title mt-3">
        {{ modalAdvSearchTitle }}
      </div>

      <!-- END: Modal Header -->
      <div
        class="bg-white p-2 rounded-2xl ricerca_avanzata-cont overflow-auto scrollbar_custom"
      >
        <!-- BEGIN: Modal Body -->
        <!-- BEGIN: RICERCA AVANZATA -->
        <div class="flex-col">
          <!-- BEGIN: INIZIO RICERCA AVANZATA PER CLIENTE -->
          <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
            <h2 class="ml-3 text-base font-bold">Filtro per cliente</h2>
          </div>

          <div class="grid grid-cols-8 gap-3 mt-5">
            <div
              v-for="(elem, index) in input_clt"
              id="clt"
              :key="index"
              class="col-span-8 sm:col-span-4 md:col-span-2"
            >
              <label for="" class="form-label">{{ elem.title }}</label>
              <select v-if="elem.type === 'select'" class="custom-imput">
                <option v-for="(item, i) in elem.option" :key="i" :value="item">
                  {{ item.title }}
                </option>
              </select>
              <input
                v-else
                v-model="clients[elem.key]"
                :type="elem.type"
                :name="elem.title"
                class="custom-input"
                :class="{ invisible: elem.type === 'none' }"
              />
            </div>
          </div>

          <div class="flex justify-end my-5 gap-3">
            <button
              id="adv-search-client"
              type="button"
              class="orange-btn"
              @click="resetPagination('client')"
            >
              <SearchIcon class="h-5 w-5 mr-2" />Ricerca cliente
            </button>
            <button
              id="reset"
              type="button"
              class="gray-btn flex items-center gap-1 justify-end"
              @click="reset"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 576 512"
                class="fill-current"
              >
                <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                  d="M290.7 57.4L57.4 290.7c-25 25-25 65.5 0 90.5l80 80c12 12 28.3 18.7 45.3 18.7H288h9.4H512c17.7 0 32-14.3 32-32s-14.3-32-32-32H387.9L518.6 285.3c25-25 25-65.5 0-90.5L381.3 57.4c-25-25-65.5-25-90.5 0zM297.4 416H288l-105.4 0-80-80L227.3 211.3 364.7 348.7 297.4 416z"
                />
              </svg>
              Reset
            </button>
          </div>

          <!-- END: INIZIO RICERCA AVANZATA PER CLIENTE -->
          <!-- BEGIN: INIZIO RICERCA AVANZATA PER ANIMALE -->
          <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
            <h2 class="ml-3 text-base font-bold">Filtro per paziente</h2>
          </div>

          <div class="grid grid-cols-8 gap-3 mt-5">
            <div
              v-for="(elem, index) in input_pet"
              :key="index"
              class="col-span-8 sm:col-span-4 md:col-span-2"
            >
              <label for="" class="form-label">{{ elem.title }}</label>
              <select
                v-if="elem.type === 'select'"
                v-model="pets[elem.key]"
                class="custom-input"
              >
                <option v-for="(item, i) in elem.option" :key="i" :value="item">
                  {{ item.name ? item.name : item }}
                </option>
              </select>

              <input
                v-else
                v-model="pets[elem.key]"
                :type="elem.type"
                :name="elem.title"
                class="custom-input"
                :class="{ invisible: elem.type === 'none' }"
              />
            </div>
          </div>

          <div class="flex justify-end my-5 gap-3">
            <button
              id="adv-search-client"
              type="button"
              class="orange-btn"
              @click="resetPagination('pet')"
            >
              <SearchIcon class="h-5 w-5 mr-2" />Ricerca cliente
            </button>
            <button
              id="reset"
              type="button"
              class="gray-btn flex items-center gap-1 justify-end"
              @click="reset"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="1em"
                viewBox="0 0 576 512"
                class="fill-current"
              >
                <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                <path
                  d="M290.7 57.4L57.4 290.7c-25 25-25 65.5 0 90.5l80 80c12 12 28.3 18.7 45.3 18.7H288h9.4H512c17.7 0 32-14.3 32-32s-14.3-32-32-32H387.9L518.6 285.3c25-25 25-65.5 0-90.5L381.3 57.4c-25-25-65.5-25-90.5 0zM297.4 416H288l-105.4 0-80-80L227.3 211.3 364.7 348.7 297.4 416z"
                />
              </svg>
              Reset
            </button>
          </div>
        </div>
        <!-- END: INIZIO RICERCA AVANZATA PER ANIMALE -->
        <div v-if="filteredCustomers !== ''" class="post__content tab-content">
          <div class="border border-gray-200 dark:border-dark-5 rounded-md p-5">
            <div class="intro-y box mt-1">
              <div id="responsive-table-list">
                <Paginator
                  :for-page-option="pagination.forPage"
                  :total-records="totalRecords"
                  @changePagination="changePagination"
                />
                <div class="preview">
                  <div class="overflow-y-auto scrollbar_custom h-5/6 pb-8">
                    <table
                      class="shadow w-full overflow-hidden border-b border-gray-200 sm:rounded-lg"
                    >
                      <THeader
                        v-for="(elem, index) in input_clt"
                        :key="index"
                        :name="elem.title"
                        class="t-header"
                      />
                      <Cell />
                      <tbody>
                        <tr>
                          <td
                            v-for="(elem, index) in filteredCustomers"
                            :key="index"
                            class="border-b whitespace-nowrap p-2 py-4"
                          >
                            <select
                              v-if="elem.type === 'select'"
                              class="border rounded-md h-7"
                            >
                              <option value></option>
                              <option
                                v-for="(item, i) in elem.option"
                                :key="i"
                                value
                              >
                                {{ item }}
                              </option>
                            </select>
                          </td>
                        </tr>
                        <tr
                          v-for="(item, index) in filteredCustomers"
                          :key="index"
                          :class="{ 'bg-gray-200': index % 2 === 0 }"
                          class="hover:bg-theme-44 hover:font-bold cursor-pointer"
                          @click.stop="$goToCustomer(item)"
                          @click="closeModal"
                        >
                          <td
                            class="border-b border-l whitespace-nowrap text-center py-3"
                          >
                            {{ item.last_name }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center py-3"
                          >
                            {{ item.first_name }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{ item.phones ? item.phones.phone1.prefisso : '' }}
                            {{ item.phones ? item.phones.phone1.phone : '' }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{ item.emails ? item.emails.email1.email : '' }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{ item.fiscal_code }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{ item.vat_number }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{ item.address.address }}
                            {{ item.address.house_number }}
                          </td>

                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{ item.address.city }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            P. IVA
                          </td>
                          <td
                            class="border whitespace-nowrap text-center cursor-pointer"
                          >
                            <i class="fas fa-chevron-right" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--END: Risultati ricerca clienti-->
        <!--BEGIN: Risultati ricerca pazienti-->
        <div v-if="filteredPets !== ''" class="post__content tab-content">
          <div class="border border-gray-200 dark:border-dark-5 rounded-md p-5">
            <div class="intro-y box mt-1">
              <div id="responsive-table-list">
                <Paginator
                  :for-page-option="pagination.forPage"
                  :total-records="totalRecords"
                  @changePagination="changePagination"
                />
                <div class="preview">
                  <div class="scrollbar_custom overflow-auto pb-5">
                    <table
                      class="shadow w-full overflow-hidden border-b border-gray-200 sm:rounded-lg"
                    >
                      <THeader
                        v-for="(elem, index) in input_pet"
                        :key="index"
                        class="t-header"
                        :name="elem.title"
                      />
                      <Cell />
                      <tbody>
                        <tr
                          v-for="(item, index) in filteredPets"
                          :key="index"
                          :class="[index % 2 === 0 ? 'bg-theme-2' : 'bg-white']"
                          class="hover:bg-theme-44 cursor-pointer"
                          @click.stop="$goToPet(item)"
                          @click="closeModal"
                        >
                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-pointer hover:underline px-10 py-3"
                            @click="getPet(item)"
                          >
                            {{ item.name }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-pointer hover:underline px-2 py-3"
                          >
                            <template
                              v-if="item.owners && item.owners.length > 0"
                            >
                              <template v-for="cust in item.owners" :key="cust">
                                <div
                                  class="flex justify-start items-center gap-1 w-full"
                                >
                                  <div @click="getCustomer(cust)">
                                    <ChevronsRightIcon
                                      class="h-4 w-4 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                                    />
                                  </div>
                                  <div class="">
                                    {{
                                      cust.ragione_sociale
                                        ? cust.ragione_sociale
                                        : ''
                                    }}
                                    {{
                                      cust.last_name +
                                      ' ' +
                                      cust.first_name +
                                      ''
                                    }}
                                  </div>
                                </div>
                              </template>
                            </template>

                            <!--
                            {{
                              item.owners && item.owners.length > 0
                                ? item.owners[0].last_name
                                : '--'
                            }}
                            {{
                              item.owners && item.owners.length > 0
                                ? item.owners[0].first_name
                                : '--'
                            }} -->
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-default px-2 py-3"
                          >
                            {{ item.microchip }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-default px-2 py-3"
                          >
                            {{ item.type }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-default px-2 py-3"
                          >
                            {{ item.breed }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-default px-2 py-3"
                          >
                            {{ item.gender }}
                          </td>

                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-default py-3"
                          >
                            {{ $FormatDate(item.pet_info.birth_date) }}
                          </td>

                          <td
                            class="border-b border-l whitespace-nowrap text-center cursor-default truncate py-3"
                          >
                            {{ item.pet_info.blood_group }}
                          </td>
                          <td
                            v-if="item.donot === 1"
                            class="border-b border-l whitespace-nowrap text-center cursor-default px-2 py-3"
                          >
                            Si
                          </td>
                          <td
                            v-else
                            class="border-b border-l whitespace-nowrap text-center cursor-default px-2 py-3"
                          >
                            No
                          </td>
                          <td
                            v-if="item.sterilized === 1"
                            class="border-b border-l whitespace-nowrap text-center cursor-default px-2 py-3"
                          >
                            Si
                          </td>
                          <td
                            v-else
                            class="border-b border-l whitespace-nowrap text-center cursor-default px-2 py-3"
                          >
                            No
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- END: Risultati ricerca pazienti-->

        <!-- END: Risultati della ricerca -->

        <!-- BEGIN: Modal Footer -->

        <div
          v-if="!footerModalAppointment"
          id="new-appointment-footer-add-event"
          class="border-t-1 border-gray-400 text-right"
        ></div>
        <!-- END: Modal Footer -->
      </div>
    </div>
  </div>
  <!-- END: Modal Content -->
</template>

<!--suppress JSValidateTypes -->
<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import flatpickr from 'flatpickr'
import { Italian } from 'flatpickr/dist/l10n/it.js'
import axios from '../../gateway/backend-api'
import moment from 'moment'
import THeader from '../../components/cvit-table/THeader.vue'
import Paginator from '../../components/paginator/Main.vue'

const forPageOption = [10, 50, 100]
export default defineComponent({
  components: {
    THeader,
    Paginator
  },
  props: ['footerModalAppointment', 'modalAdvSearchTitle'],
  emits: ['close'],
  data() {
    return {
      input_clt: [
        {
          key: 'last_name',
          title: 'Cognome',
          type: 'text'
        },
        {
          key: 'first_name',
          title: 'Nome',
          type: 'text'
        },

        {
          key: 'phone',
          title: 'Telefono',
          type: 'text'
        },
        {
          key: 'email',
          title: 'Mail',
          type: 'text'
        },
        {
          key: 'fiscal_code',
          title: 'Codice fiscale',
          type: 'text'
        },
        {
          key: 'vat_number',
          title: 'P. Iva',
          type: 'text'
        },
        {
          key: 'address',
          title: 'Indirizzo',
          type: 'text'
        },
        {
          key: 'city',
          title: 'Città',
          type: 'text'
        },
        {
          key: 'zip_code',
          title: 'CAP',
          type: 'text'
        },
        {
          key: 'animal',
          title: 'Animale',
          type: 'text'
        }
      ],
      input_pet: [
        {
          key: 'name',
          title: 'Nome',
          type: 'text'
        },
        {
          key: 'owners',
          title: 'Proprietario',
          type: 'text'
        },
        {
          key: 'microchip',
          title: 'Microchip',
          type: 'text'
        },
        {
          key: 'species',
          title: 'Specie',
          type: 'select',
          option: []
        },
        {
          key: 'breed',
          title: 'Razza',
          type: 'select',
          option: []
        },
        {
          key: 'gender',
          title: 'Sesso',
          type: 'select',
          option: ['M', 'F']
        },
        {
          key: 'birth_date',
          title: 'Data di Nascita',
          type: 'date',
          date: '',
          format: 'DD MM YYYY'
        },
        {
          key: 'blood_group',
          title: 'Gruppo Sanguigno',
          type: 'text'
        },
        {
          key: 'donor',
          title: 'Donatore',
          type: 'select',
          option: [
            { name: 'Si', value: true },
            { name: 'No', value: false }
          ]
        },
        {
          key: 'sterilized',
          title: 'Sterilizzato',
          type: 'select',
          option: [
            { name: 'Si', value: true },
            { name: 'No', value: false }
          ]
        }
      ],
      clients: {},
      pets: {},
      filteredPets: '',
      filteredCustomers: '',
      totalRecords: '',
      types: null,
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
      getPetsDetails: 'anagraficaPazienti/getPetsDetails',
      getComparisonPets: 'anagraficaPazienti/getComparisonPets',
      getCustomersDetails: 'anagraficaClienti/getCustomersDetails',
      getComparisonCustomer: 'anagraficaClienti/getComparisonCustomer'
    })
  },
  watch: {
    'pets.species': function (elem) {
      if (elem) {
        this.input_pet[4].option = elem.breeds
      }
      this.pets.breeds = null
    }
  },
  mounted() {
    flatpickr.localize(Italian)
    flatpickr('.date', {
      maxDate: 'today',
      dateFormat: 'd/m/Y',
      allowInput: true,
      allowInvalidPreload: false
    })
    axios
      .get('/' + localStorage.getItem('clinic') + '/pet/types_breeds')
      .then((res) => {
        this.input_pet[3].option = res.data
      })
  },
  methods: {
    ...mapActions({
      getPetAction: 'anagraficaPazienti/getPetAction',
      getCustomerAction: 'anagraficaClienti/getCustomerAction'
    }),
    reset() {
      this.filteredCustomers = ''
      this.filteredPets = ''
      this.pets = {}
      this.clients = {}
    },
    closeModal() {
      this.$emit('close')
    },
    searchClient() {
      this.filteredPets = ''
      if (JSON.stringify(this.clients) === '{}') {
        alert('Non hai inserito campi di ricerca')
      } else {
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/search/searchCustomer',
            {
              toSearch: this.clients,
              per_page: this.pagination.forPage,
              page: this.pagination.page
            }
          )
          .then((res) => {
            this.filteredCustomers = res.data.customers.data.items
            this.totalRecords = res.data.customers.total
          })
      }
    },
    searchPet() {
      this.filteredCustomers = ''
      if (JSON.stringify(this.pets) === '{}') {
        alert('Non hai inserito campi di ricerca')
      } else {
        if (this.pets) {
          if (this.pets.species && this.pets.species.name) {
            this.pets.pet_type_id = this.pets.species.id
          }

          if (this.pets.breed) {
            this.pets.pet_breed_id = this.pets.breed.id
          }
          if (this.pets.donor !== null && this.pets.donor != undefined) {
            this.pets.donor = this.pets.donor.value
          }
          if (
            this.pets.sterilized !== null &&
            this.pets.sterilized != undefined
          )
            this.pets.sterilized = this.pets.sterilized.value
          if (this.pets.birth_date) {
            this.pets.birth_date = this.$FormatEpoch(
              moment(this.pets.birth_date).format('DD/MM/YYYY')
            )
          }
          delete this.pets.breed
          delete this.pets.breeds
          delete this.pets.species
        }
        axios
          .post('/' + localStorage.getItem('clinic') + '/search/searchPet', {
            toSearch: this.pets,
            per_page: this.pagination.forPage,
            page: this.pagination.page
          })
          .then((res) => {
            this.filteredPets = res.data.pet.data.items
            this.totalRecords = res.data.pet.total
          })
      }
    },
    changePagination: function (value) {
      if (this.pagination.page != value.page) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        if (this.filteredPets) this.searchPet()
        else if (this.filteredCustomers) this.searchClient()
      }
    },
    //ripristino page = 1 alla nuova ricerca
    resetPagination(search) {
      this.pagination.page = 1
      this.pagination.forPage = 10
      if (search == 'pet') this.searchPet()
      else if (search == 'client') this.searchClient()
    }
  }
})
</script>
