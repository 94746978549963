<template>
  <!-- <div class="flex justify-center">
    <h2 class="text-4xl text-theme-40 nunito mt-2 font-bold py-2">
      Fatturazione attiva
    </h2>
  </div> -->
  <ExportInvoiceModal
    v-if="showExportInvoiceModal"
    @close="handleExportInvoiceModal"
  />
  <div
    v-if="
      permissions && permissions?.fatturazione?.view_billing?.enabled === true
    "
    class="bg-white rounded-md content-container overflow-auto scrollbar_custom p-3"
  >
    <InvoiceTable
      :key="invoiceKey"
      :type="['invoice']"
      @openExportInvoice="handleExportInvoiceModal"
      @showInvoice="openInvoiceModalReadOnly"
      @editInvoice="openInvoiceModalEdit"
      @handleSavePdfRev="handleSavePdfRev"
      @handleNotaDiCredito="handleNotaDiCredito"
    />
    <div v-if="invoiceModalOpen">
      <NewInvoiceModal
        :read-only="invoiceReadOnly"
        :read-only-elem="invoiceElement"
        :client="client"
        :title="newInvoiceTitle"
        :nota-credito="notaCredito"
        :modify="modificaFattura"
        :services="[]"
        :medium-size="true"
        @refresh-tables="updateTable"
        @close="closeInvoiceModal"
        @responseModal="handleResponseSubmitInvoice"
      />
    </div>
  </div>
  <div
    v-else-if="
      permissions && permissions?.fatturazione?.view_billing?.enabled === false
    "
  >
    <p>I permessi per visualizzare i documenti fiscali non sono abilitati</p>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { mapGetters } from 'vuex'
import InvoiceTable from '../../components/invoice-table/InvoiceTable.vue'
import NewInvoiceModal from '../../components/new-invoice/NewInvoiceModal.vue'
import ExportInvoiceModal from '../../components/modals/ExportInvoiceModal.vue'
import axios from '../../gateway/backend-api'

export default defineComponent({
  components: {
    InvoiceTable,
    NewInvoiceModal,
    ExportInvoiceModal
  },
  setup() {
    const invoiceModalOpen = ref(false)
    const newInvoiceTitle = ref(null)
    const invoiceReadOnly = ref(false)
    const invoiceElement = ref(null)
    const client = ref(null)
    const notaCredito = ref(false)
    const modificaFattura = ref(false)
    const invoiceKey = ref(1)
    const showExportInvoiceModal = ref(false)

    const closeInvoiceModal = () => {
      newInvoiceTitle.value = null
      invoiceModalOpen.value = false
      notaCredito.value = false

      invoiceReadOnly.value = false
      modificaFattura.value = false

      invoiceElement.value = null
    }

    const updateTable = () => {
      invoiceKey.value = invoiceKey.value + 1
    }

    return {
      invoiceModalOpen,
      client,
      invoiceElement,
      invoiceReadOnly,
      newInvoiceTitle,
      closeInvoiceModal,
      notaCredito,
      invoiceKey,
      updateTable,
      modificaFattura,
      showExportInvoiceModal
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions'
    })
  },
  methods: {
    openInvoiceModalReadOnly(el, notaDiCredito) {
      // get single bill from backend
      // axios
      //   .get('/' + localStorage.getItem('clinic') + '/bills/' + el.id)
      //   .then((bill) => {
      //     console.log(bill)
      //     axios
      //       .get(
      //         '/' +
      //           localStorage.getItem('clinic') +
      //           '/customer/' +
      //           bill.data.customer_id
      //       )
      //       .then((res) => {
      //         this.client = res.data
      //         if (bill.data && bill.data.from_active_bill_n_doc) {
      //           this.newInvoiceTitle = 'Riepilogo Nota di credito'
      //         } else {
      //           this.newInvoiceTitle = 'Riepilogo Fattura'
      //         }

      //         this.invoiceReadOnly = true
      //         if (notaDiCredito) {
      //           this.notaCredito = true
      //           this.invoiceReadOnly = false
      //           this.newInvoiceTitle = 'Riepilogo Nota di credito'
      //         }
      //         this.invoiceModalOpen = true
      //         this.invoiceElement = bill.data
      //       })
      //   })
      axios
        .get(
          '/' + localStorage.getItem('clinic') + '/customer/' + el.customer_id
        )
        .then((res) => {
          this.client = res.data
          if (el && el.from_active_bill_n_doc) {
            this.newInvoiceTitle = 'Riepilogo Nota di credito'
          } else {
            this.newInvoiceTitle = 'Riepilogo Fattura'
          }

          this.invoiceReadOnly = true
          if (notaDiCredito) {
            this.notaCredito = true
            this.invoiceReadOnly = false
            this.newInvoiceTitle = 'Riepilogo Nota di credito'
          }
          this.invoiceModalOpen = true
          this.invoiceElement = el
        })
    },
    handleExportInvoiceModal() {
      this.showExportInvoiceModal = !this.showExportInvoiceModal
    },
    openInvoiceModalEdit(el) {
      axios
        .get(
          '/' + localStorage.getItem('clinic') + '/customer/' + el.customer_id
        )
        .then((res) => {
          this.client = res.data
          this.newInvoiceTitle = 'Modifica fattura'
          this.invoiceReadOnly = false
          this.notaCredito = false
          this.invoiceReadOnly = false
          this.modificaFattura = true
          this.invoiceModalOpen = true
          this.invoiceElement = el
        })
    },
    handleSavePdfRev(elem) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/bills/' + elem.id + '/pdf',
          {},
          {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/pdf'
            }
          }
        )
        .then((response) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'Fattura' + elem.id + '.pdf')
          document.body.appendChild(link)
          this.$store.dispatch('commonComponents/openPdfModal', {
            title: elem.number,
            link: url,
            background: false,
            print: () => {
              console.log('PRINT')
            },
            download: () => {
              link.click()
            }
          })
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    handleNotaDiCredito(doc) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'ATTENZIONE',
        subtitle:
          'Sei sicuro di voler creare la nota di credito di questa fattura?',
        background: false,
        confirm: () => {
          this.openInvoiceModalReadOnly(doc, true)
        },
        decline: () => {
          console.log('Ho declinato la risposta')
        }
      })
    }
  }
})
</script>

<style scoped></style>
