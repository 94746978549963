<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative addwhare_modal p-2 h-auto mx-auto overflow-scroll scrollbar-none border sm:w-3/5 w-full shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="flex flex-col content-end overflow-y-auto">
        <button type="button" class="closing-btn" @click="$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <!-- Modal header -->
        <div class="flex items-start p-5 rounded-t mt-4">
          <h3 class="modal-title w-full">
            {{
              articolo
                ? addLotto
                  ? 'Aggiungi lotto'
                  : 'Modifica articolo'
                : 'Nuovo articolo'
            }}
          </h3>
        </div>
        <!-- Fine Modal header -->

        <!-- Modal body -->

        <div class="bg-white pb-3 pt-1 px-6 rounded-2xl grid grid-cols-4 gap-2">
          <div class="col-span-4">
            <label class="form-label">Codice AIC: </label>
            <div id="select-aic" class="">
              <div class="">
                <v-select
                  ref="inputAic"
                  v-model="newArticle.aic"
                  :options="drugs"
                  :disabled="articolo || addLotto"
                  label="AIC"
                  placeholder="Inserisci almeno 2 caratteri"
                  @search="searchDrugs"
                >
                  <template #option="option">
                    <table class="mb-2">
                      <tr>
                        <th>{{ option.AIC }} - {{ option.MARCHIO }}</th>
                      </tr>
                      <tr>
                        <td>{{ option.CONFEZIONE }}</td>
                      </tr>
                    </table>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="col-span-4">
            <label class="form-label required">Denominazione: </label>
            <div id="multiple-select-drugs" class="">
              <div class="">
                <v-select
                  ref="inputDrugs"
                  v-model="newArticle.name"
                  :options="drugs"
                  :disabled="(articolo && articolo.aic !== null) || addLotto"
                  taggable
                  label="MARCHIO"
                  placeholder="Seleziona denominazione"
                  @search="searchDrugs"
                >
                  <template #option="option">
                    <table class="mb-2">
                      <tr>
                        <th>
                          {{ option.AIC ? option.AIC + ' - ' : '' }} -
                          {{ option.MARCHIO }}
                        </th>
                      </tr>
                      <tr>
                        <td>{{ option.CONFEZIONE }}</td>
                      </tr>
                    </table>
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <div class="pb-3 col-span-4 grid grid-cols-3 gap-3">
            <div v-if="false" class="col-span-1 sm:col-span-2">
              <label for="new-enter-cliente" class="form-label"
                >Nome dell'articolo</label
              >
              <!-- id prima era modal-form-1 -->
              <div class="mt-1">
                <div class=" ">
                  <input
                    id="new-article-name"
                    ref="input1"
                    v-model="newArticle.name"
                    type="text"
                    class="flex-1 custom-input"
                    placeholder="Nome dell'articolo"
                    value
                    required
                  />
                </div>
              </div>
            </div>

            <div class="col-span-3">
              <label for="new-enter-animale" class="form-label"
                >Descrizione</label
              >
              <!-- id prima era modal-form-1 -->
              <div class="mt-1">
                <div class=" ">
                  <textarea
                    id="new-article-description"
                    v-model="newArticle.description"
                    :disabled="
                      !editDescription ||
                      (articolo && articolo.aic !== null) ||
                      addLotto
                    "
                    class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-28"
                    placeholder="Descrizione"
                  />
                </div>
              </div>
            </div>

            <div class="col-span-1">
              <label for="new-enter-animale" class="form-label required"
                >Lotto</label
              >
              <!-- id prima era modal-form-1 -->
              <div class="mt-1">
                <div class=" ">
                  <input
                    id="new-article-description"
                    ref="input3"
                    v-model="newArticle.lotto"
                    type="text"
                    class="flex-1 custom-input"
                    placeholder="Lotto"
                  />
                </div>
              </div>
            </div>
            <div class="col-span-1">
              <label for="new-enter-animale" class="form-label required"
                >Quantità</label
              >
              <!-- id prima era modal-form-1 -->
              <div class="mt-1">
                <div class=" ">
                  <input
                    id="new-arrive-animale"
                    ref="input3"
                    v-model="newArticle.qty"
                    type="number"
                    min="0"
                    step="1"
                    class="flex-1 custom-input"
                    placeholder="Quantità"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="col-span-1">
              <label for="new-enter-animale" class="form-label required"
                >Prezzo</label
              >
              <!-- id prima era modal-form-1 -->
              <div class="mt-1">
                <div class=" ">
                  <input
                    id="new-arrive-animale"
                    ref="input3"
                    v-model="newArticle.price"
                    type="number"
                    min="0"
                    step="0.01"
                    class="flex-1 custom-input"
                    placeholder="Quantità"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="col-span-3">
              <label class="form-label required">Data di scadenza</label>
              <input
                id="exp_date"
                v-model="newArticle.expiration_date"
                type="date"
                class="date custom-input flex-1"
                placeholder="Seleziona data di scadenza"
              />
            </div>
            <div class="col-span-3">
              <label for="new-warehouse-note" class="form-label">Note</label>
              <textarea
                id="new-warehouse-note"
                v-model="newArticle.note"
                class="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline h-28"
                placeholder="Note"
              />
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="flex items-center justify-end gap-2 px-6 mb-3">
          <button
            v-if="!articolo"
            type="button"
            class="orange-btn"
            @click="addArticle"
          >
            <SaveIcon class="h-5 w-5 mr-2" /> Salva
          </button>
          <button
            v-if="articolo && !addLotto"
            type="button"
            class="orange-btn"
            @click="addArticle"
          >
            <SaveIcon class="h-5 w-5 mr-2" /> Modifica
          </button>
          <button
            v-if="articolo && addLotto"
            type="button"
            class="orange-btn"
            @click="addArticle"
          >
            <SaveIcon class="h-5 w-5 mr-2" /> Aggiungi
          </button>
          <button type="button" class="gray-btn" @click="$emit('close')">
            <span class="h-5"></span>
            Annulla
          </button>
        </div>
        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

// import flatpickr from 'flatpickr'
// import { Italian } from 'flatpickr/dist/l10n/it.js'
import _ from 'lodash'
import axios from '../../gateway/backend-api'

export default defineComponent({
  components: {},
  props: ['article', 'editArticle', 'articolo', 'addLotto'],
  emits: ['close', 'save', 'edit', 'add'],

  data() {
    return {
      newArticle: {
        name: '',
        qty: '',
        description: '',
        aic: null
      },
      denominazione: null,
      drugs: [],
      editDescription: true
    }
  },

  watch: {
    'newArticle.name'(value) {
      if (value && value['AIC']) {
        this.denominazione = value
        this.newArticle.aic = value['AIC']
        this.newArticle.name = value['MARCHIO']
        this.newArticle.description =
          value['CONFEZIONE'] + ' \n' + value['DESCRIZIONE_DISPENSAZIONE']
        this.editDescription = false
      }

      if (
        typeof value === 'string' &&
        this.denominazione &&
        (this.denominazione['AIC'] !== this.newArticle.aic ||
          this.denominazione['MARCHIO'] !== value)
      ) {
        this.newArticle.aic = null
        this.newArticle.description = null
        this.editDescription = true
      }
    },
    'newArticle.aic'(value) {
      if (value && value['AIC']) {
        this.denominazione = value
        this.newArticle.name = value['MARCHIO']
        this.newArticle.aic = value['AIC']
        this.newArticle.description =
          value['CONFEZIONE'] + ' \n' + value['DESCRIZIONE_DISPENSAZIONE']
        this.editDescription = false
      }

      // if (
      //   this.denominazione['AIC'] !== this.newArticle.aic ||
      //   this.denominazione['MARCHIO'] !== this.newArticle.name
      // )
      //   this.newArticle.aic === null
    }
  },
  mounted() {
    if (this.articolo) {
      this.newArticle.id = this.articolo.id
      this.newArticle.name = this.articolo.product_name
      this.newArticle.lotto = this.articolo.lotto
      if (!this.addLotto) this.newArticle.qty = this.articolo.total_quantity
      this.newArticle.price = this.articolo.purchase_price
      this.newArticle.note = this.articolo.note
      this.newArticle.description = ''
      if (this.articolo.confezione && this.articolo.confezione !== undefined)
        this.newArticle.description += this.articolo.confezione + '\n'
      if (
        this.articolo.descrizione_dispensazione &&
        this.articolo.descrizione_dispensazione !== undefined
      )
        this.newArticle.description +=
          this.articolo.descrizione_dispensazione + '\n'
      if (
        this.articolo.description &&
        this.articolo.description !== undefined &&
        !this.articolo.confezione
      )
        this.newArticle.description += this.articolo.description + '\n'
      // else
      //   this.newArticle.description =
      //     this.articolo.confezione +
      //     ' \n' +
      //     this.articolo.descrizione_dispensazione

      if (!this.addLotto)
        this.newArticle.expiration_date = this.$FormatDateEnglish(
          this.articolo.expiration_date
        )
      if (this.articolo.aic) {
        this.newArticle.aic = this.articolo.aic
      }
    }
  },
  methods: {
    addArticle() {
      if (
        (!this.newArticle?.qty && !this.articolo) ||
        (this.newArticle?.qty === null && this.articolo) ||
        this.newArticle.qty === ''
      ) {
        this.$store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire la quantità'
        })
        return
      }
      if (!this.newArticle?.lotto || this.newArticle.lotto === '') {
        this.$store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire numero lotto'
        })
        return
      }

      if (!this.newArticle?.price || this.newArticle.price === '') {
        this.$store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire il prezzo'
        })
        return
      }

      if (!this.newArticle?.name || this.newArticle.name === '') {
        this.$store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire la denominazione'
        })
        return
      }

      if (
        !this.newArticle?.expiration_date ||
        this.newArticle.expiration_date === ''
      ) {
        this.$store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire la data di scadenza'
        })
        return
      }

      if (
        this.denominazione &&
        this.denominazione['MARCHIO'] === this.newArticle.name &&
        this.denominazione['AIC'] === this.newArticle.aic
      ) {
        this.newArticle.marchio = this.newArticle.name
        Object.keys(this.denominazione).forEach((k) => {
          this.newArticle[k.toLowerCase()] = this.denominazione[k]
        })
        this.newArticle.description = null
        //this.newArticle = { ...this.newArticle, ...this.denominazione }
        //this.newArticle = Object.assign({}, this.newArticle, this.denominazione)
      } else {
        this.newArticle.alias = this.newArticle.name
      }

      this.newArticle.expiration_date = this.$FormatEpochEnglish(
        this.newArticle.expiration_date
      )

      if (this.articolo) {
        if (this.addLotto) this.$emit('add', this.newArticle)
        else this.$emit('edit', this.newArticle)
      } else {
        this.$emit('save', this.newArticle)
      }

      // if(this.denominazione && this.denominazione['AIC'] === this.newArticle.aic && this.denominazione['MARCHIO'] === this.newArticle.name) {
      //  this.newArticle.drug_id = this.denominazione[]
      // }
      // if (this.newArticle.expiration_date) {
      //   this.newArticle.expiration_date = this.$FormatEpoch(
      //     this.newArticle.expiration_date
      //   )
      // }
      // if (this.article) {
      //   // L'articolo è stato modificato
      //   this.$emit('edit', this.newArticle)
      // } else {
      //   // L'articolo è nuovo

      //   this.$emit('save', this.newArticle)
      // }
    },
    searchDrugs(search, loading) {
      if (search.length > 1 && !this.articolo) {
        loading(true)
        this.search(loading, search, this)
      }
    },
    search: _.debounce((loading, search, vm) => {
      axios
        .post('/' + localStorage.getItem('clinic') + '/search/searchDrug', {
          searchText: search
        })
        .then((res) => {
          vm.drugs = res.data
          loading(false)
        })
    }, 350)
  }
})
</script>
