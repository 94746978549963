<template>
  <div>
    <div class="paginator-container">
      <div class="prova mr-5 my-2 flex justify-between">
        <div class="mt-1">
          <span class="flex-row text-md ml-2 font-bold mr-2 mt-1">Mostra </span>

          <select
            class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
            @change="(event) => changeNumPages(event)"
          >
            <option v-for="show in forPageOption" :key="show" :value="show">
              {{ show }}
            </option>
          </select>
          <span class="text-md font-bold ml-2 mt-1"> elementi</span>
        </div>
      </div>
      <Paginator
        v-if="total"
        :for-page-option="pagination.forPage"
        :total-records="total"
        @changePagination="(event) => changePage(event)"
      />
    </div>
  </div>
  <div v-if="!isActiveFilter" class="w-full flex justify-end mt-3">
    <button
      class="orange-btn-inverted px-4 flex gap-2"
      @click="isActiveFilter = !isActiveFilter"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        class="h-4 fill-current"
      >
        <path
          d="M3.9 54.9C10.5 40.9 24.5 32 40 32H472c15.5 0 29.5 8.9 36.1 22.9s4.6 30.5-5.2 42.5L320 320.9V448c0 12.1-6.8 23.2-17.7 28.6s-23.8 4.3-33.5-3l-64-48c-8.1-6-12.8-15.5-12.8-25.6V320.9L9 97.3C-.7 85.4-2.8 68.8 3.9 54.9z"
        />
      </svg>
      <span>Filtri</span>
    </button>
  </div>

  <div
    v-show="isActiveFilter"
    class="flex gap-3 items-center py-1 px-3 mt-3 rounded-md shadow-md border border-theme-44 border-opacity-20 relative"
  >
    <!-- BEGIN: Multiple Select for rooms  -->
    <button
      type="button"
      class="closing-btn mr-1 top-1"
      @click="isActiveFilter = false"
    >
      <svg
        class="w-4 h-4"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
          clip-rule="evenodd"
        ></path>
      </svg>
    </button>

    <div class="w-full p-1">
      <h2 class="form-label mb-1">Data Scadenza</h2>
      <div id="multiple-select-room">
        <input
          v-model="active_filters['expiration_date']"
          type="date"
          class="custom-input"
          placeholder="Data di scadenza"
          @change="
            () => {
              // active_filters['expiration_date'] = $FormatEpochEnglish(
              //   active_filters['expiration_date']
              // )
              $emit('updateFiltri', active_filters)
            }
          "
        />
      </div>
    </div>
    <!-- END: Multiple Select for rooms -->

    <!-- BEGIN: Multiple Select for doctors -->
    <div class="w-full p-1">
      <h2 class="form-label mb-1">Lotto</h2>
      <div id="multiple-select-vet">
        <input
          v-model="active_filters['lotto']"
          type="string"
          class="custom-input"
          placeholder="Lotto"
          @change="$emit('updateFiltri', active_filters)"
        />
      </div>
    </div>

    <!-- END: Filters-->
  </div>

  <!-- BEGIN: Data List -->
  <div class="table-container overflow-scroll scrollbar-none pb-3">
    <table v-if="table_header && docs" class="w-full">
      <THeader
        v-for="(item, itemKey) in table_header"
        :key="itemKey"
        :name="item.title"
        class="t-header h-8"
      />
      <tbody>
        <tr class="h-8">
          <td v-for="(elem, itemKey) in table_header" :key="itemKey" class="">
            <select
              v-if="elem.type === 'select'"
              v-model="elem.value"
              class="w-full border rounded-md h-7 text-gray-500"
              @change="filterHandler(elem)"
            >
              <option selected></option>
              <option v-for="(item, i) in elem.option" :key="i" :value="item">
                {{ item.name ? item.name : item }}
              </option>
            </select>
            <input
              v-else
              v-model="elem.value"
              :type="elem.type"
              min="0"
              :name="elem.title"
              class="form-control h-7"
              :class="{
                invisible: elem.type === 'none',
                'p-0': elem.type === 'date'
              }"
              :placeholder="elem.title"
              @change="filterHandler(elem)"
            />
          </td>
        </tr>
        <template v-for="(doc, docKey) in docs" :key="docKey">
          <tr
            class="text-xs font-normal hover:bg-theme-45 h-8"
            :class="{ 'bg-theme-2': docKey % 2 === 0 }"
          >
            <td class="text-center text-sm border-r-2 border-theme-46">
              <button id="chevron" class="text-theme-41">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  class="h-4 fill-current"
                  :class="openVar.includes(docKey) ? 'rotate90' : 'rotate180'"
                  @click="open(docKey)"
                >
                  <path
                    d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                  />
                </svg>
              </button>
            </td>
            <td class="text-center text-sm border-r-2 border-theme-46">
              {{ doc.alias }}
            </td>
            <td class="text-center text-sm border-r-2 border-theme-46">
              <i class="text-xs">{{ doc.confezione }}</i>
            </td>
            <td class="text-center text-sm border-r-2 border-theme-46">
              {{ doc.aic }}
            </td>
            <td class="text-center text-sm border-r-2 border-theme-46">
              {{ doc.total_quantity }}
            </td>
            <td class="text-center text-sm border-r-2 border-theme-46">
              {{ doc.avg_price + ' €' }}
              <span style="text-transform: lowercase">/pz</span>
            </td>

            <!-- ACAAAAAA TE QUEDASTE! -->

            <td
              v-if="!movimentazione && !soglia && !scadenza"
              class="text-center text-sm cursor-pointer"
            >
              <a class="tooltipbuttonprofile" @click="$emit('addLotto', doc)">
                <PlusIcon class="w-4 h-4" />
                <span
                  class="tooltipbuttontext"
                  style="width: 80px; margin-top: -15px; margin-left: -10px"
                  >Aggiungi</span
                >
              </a>
            </td>
          </tr>
          <tr v-show="openVar.includes(docKey)">
            <td colspan="7">
              <table
                v-if="doc.row"
                class="w-full rounded border border-theme-45"
              >
                <thead class="bg-theme-45 h-5">
                  <th
                    v-for="(item, itemKey) in tableConfigClinicDoc"
                    :key="itemKey"
                    class="text-xs p-1"
                  >
                    {{ item.title }}
                  </th>
                </thead>

                <tbody>
                  <tr v-if="false" class="h-8">
                    <td></td>
                    <td
                      v-for="(elem, itemKey) in table_config"
                      :key="itemKey"
                      class=""
                    >
                      <select
                        v-if="elem.type === 'select'"
                        v-model="elem.value"
                        class="w-full border rounded-md h-7 text-gray-500"
                        @change="filterHandler(elem)"
                      >
                        <option selected></option>
                        <option
                          v-for="(item, i) in elem.option"
                          :key="i"
                          :value="item"
                        >
                          {{ item.name ? item.name : item }}
                        </option>
                      </select>
                      <input
                        v-else
                        v-model="elem.value"
                        :type="elem.type"
                        min="0"
                        :name="elem.title"
                        class="form-control h-7"
                        :class="{
                          invisible: elem.type === 'none',
                          'p-0': elem.type === 'date'
                        }"
                        :placeholder="elem.title"
                        @change="filterHandler(elem)"
                      />
                    </td>
                  </tr>
                  <tr
                    v-for="row in doc.row"
                    :key="row"
                    class="text-xs font-normal hover:bg-theme-45 border border-theme-44"
                  >
                    <td class="text-center text-sm border-r-2 border-theme-46">
                      <TableShotColor :stato="row.product_status" />
                    </td>
                    <td
                      v-if="!scadenza"
                      class="text-center text-xs border-r-2 border-theme-46"
                    >
                      <template v-if="row.expiration_date">
                        {{ $FormatDate(row.expiration_date) }}
                      </template>
                    </td>
                    <td class="text-center text-sm border-r-2 border-theme-46">
                      <template
                        v-if="
                          typeof row.days_expiration !== 'undefined' &&
                          row.days_expiration != null
                        "
                      >
                        <span v-if="row.days_expiration < 0"> Scaduto </span>
                        <span v-else>
                          {{ row.days_expiration }}
                          <span style="text-transform: lowercase">gg</span>
                        </span>
                      </template>
                    </td>
                    <!-- <td class="text-center text-sm border-r-2 border-theme-46">
                      {{ row.product_name }}
                    </td> -->
                    <td class="text-center text-sm border-r-2 border-theme-46">
                      {{ row.lotto }}
                    </td>
                    <td
                      v-if="scadenza"
                      class="text-center text-sm border-r-2 border-theme-46"
                    >
                      {{ $FormatDate(row.expiration_date) }}
                    </td>
                    <td class="text-center text-sm border-r-2 border-theme-46">
                      {{ row.total_quantity }}
                    </td>
                    <td class="text-center text-sm border-r-2 border-theme-46">
                      <span v-if="row.purchase_price">
                        {{ row.purchase_price + ' €' }}
                        <span style="text-transform: lowercase">/pz</span>
                      </span>
                    </td>
                    <td
                      v-if="!movimentazione && !soglia && !scadenza"
                      class="text-center text-sm"
                    >
                      <div
                        class="flex justify-center items-center text-sm cursor-pointer"
                      >
                        <a
                          class="tooltipbuttonprofile w-5 h-5 flex items-center justify-center hover:text-theme-41"
                          @click="$emit('editProdotto', row)"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            class="fill-current"
                            style="width: 0.85rem; height: 0.85rem"
                          >
                            <path
                              d="M362.7 19.32C387.7-5.678 428.3-5.678 453.3 19.32L492.7 58.75C517.7 83.74 517.7 124.3 492.7 149.3L444.3 197.7L314.3 67.72L362.7 19.32zM421.7 220.3L188.5 453.4C178.1 463.8 165.2 471.5 151.1 475.6L30.77 511C22.35 513.5 13.24 511.2 7.03 504.1C.8198 498.8-1.502 489.7 .976 481.2L36.37 360.9C40.53 346.8 48.16 333.9 58.57 323.5L291.7 90.34L421.7 220.3z"
                            />
                          </svg>
                          <span
                            class="tooltipbuttontext"
                            style="width: 80px; margin-top: -15px"
                            >Modifica</span
                          >
                        </a>
                        <a
                          class="hover:text-theme-41 w-5 h-5 flex items-center justify-center"
                          @click="$emit('deleteProdotto', row)"
                        >
                          <i class="fas fa-times" />
                        </a>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div v-if="false" class="w-full flex justify-center mt-2">
      Nessun Risultato
    </div>
  </div>
  <!-- END: Data List -->
</template>

<script>
import { defineComponent, computed, toRef, ref, toRaw } from 'vue'
import Paginator from '../../components/paginator/Main'
import THeader from '../../components/cvit-table/THeader.vue'
import TableShotColor from '../../components/details-customer/TableShotColor.vue'

export default defineComponent({
  components: {
    Paginator,
    THeader,
    TableShotColor
  },
  props: [
    'tableConfigClinicDoc',
    'pagination',
    'forPageOption',
    'selectedPatient',
    'docs',
    'total',
    'movimentazione',
    'scadenza',
    'soglia'
  ],
  emits: [
    'changePagination',
    'editProdotto',
    'deleteProdotto',
    'updateFiltri',
    'addLotto'
  ],
  setup(props, { emit }) {
    const pagination = toRef(props, 'pagination')

    // variabili paginazione
    const active_filters = ref({})
    const filterActive = ref(false)

    const isActiveFilter = ref(false)

    let table_header = [
      {
        key: 'product_status',
        title: 'Stato',
        type: 'select',
        option: [
          { name: 'Scaduto', value: 'expired' },
          { name: 'Valido', value: 'valid' },
          { name: 'Scorte limitate', value: 'qty_limit' }
        ]
      },
      {
        key: 'name',
        title: 'Nome Prodotto',
        type: 'string'
      },
      {
        key: 'confezione',
        title: 'Confezione',
        type: 'string'
      },
      {
        key: 'aic',
        title: 'aic',
        type: 'string'
      },
      {
        key: 'quantity',
        title: 'Quantità',
        type: 'string'
      },
      {
        key: 'price',
        title: 'Prezzo Medio Cad.',
        type: 'string'
      }
    ]

    if (!props.scadenza && !props.movimentazione && !props.soglia) {
      table_header.push({
        key: 'buttons',
        title: 'Azioni rapide',
        type: 'none'
      })
    }

    // metodo che cambia il numero della pagina
    const changeNumPages = (elem) => {
      if (pagination.value.forPage != elem.target.value) {
        pagination.value.page = 1
        pagination.value.forPage = elem.target.value
      }

      emit('changePagination', pagination)
    }

    const changePage = (elem) => {
      console.log(elem)
      if (pagination.value.page != elem.page) {
        pagination.value.page = elem.page
      }

      emit('changePagination', pagination)
    }

    const openVar = ref([])
    const open = (key) => {
      if (openVar.value.includes(key)) {
        var index = openVar.value.indexOf(key)
        if (index !== -1) openVar.value.splice(index, 1)
      } else openVar.value.push(key)
    }

    // ----- COMPUTED ----- //
    const table_config = computed(function () {
      // configurazione tabella, filtrando i dati del paziente nel caso in cui siamo nella sezione anagrafica paziente
      return props.tableConfigClinicDoc.filter(
        (elem) => !(elem.key === 'patient' && props.selectedPatient)
      )
    })

    return {
      table_config,
      //changePagination,
      changeNumPages,
      openVar,
      open,
      table_header,
      changePage,
      active_filters,
      filterActive,
      isActiveFilter
    }
  },
  methods: {
    // ----- METODI GESTIONE FILTRI ----- //
    filterHandler(elem) {
      if (elem.type === 'date') {
        this.active_filters[elem.key] = this.$FormatEpochEnglish(elem.value)
      } else {
        if (elem.type === 'select') {
          this.active_filters[elem.key] = elem.value.value
        } else {
          this.active_filters[elem.key] = elem.value
        }
      }

      this.checkIfFilterActive()

      if (this.filterActive)
        this.$emit('updateFiltri', toRaw(this.active_filters))
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    }
    // ----- FINE METODI GESTIONE FILTRI ----- //
  }
})
</script>
