import * as types from './mutation-types'
import axios from '../gateway/backend-api'

const state = () => {
  return {
    darkMode: false,
    clinicSetup: null
  }
}

// getters
const getters = {
  darkMode: (state) => state.darkMode,
  clinicSetup: (state) => state.clinicSetup
}

// actions
const actions = {
  setDarkMode({ commit }, darkMode) {
    commit(types.SET_DARK_MODE, { darkMode })
  },
  getClinicSetup({ commit }) {
    axios
      .get('/' + localStorage.getItem('clinic') + '/bills/default-setup')
      .then((res) => {
        commit(types.SET_CLINIC_SETUP, res.data)
      })
  }
}

// mutations
const mutations = {
  [types.SET_DARK_MODE](state, { darkMode }) {
    state.darkMode = darkMode
  },
  [types.SET_CLINIC_SETUP](state, clinicSetup) {
    state.clinicSetup = clinicSetup
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
