<template>
  <div class="tab-content overflow-hidden relative">
    <!--BEGIN: invoice list-->
    <div
      id="invoices"
      class="tab-pane active"
      role="tabpanel"
      aria-labelledby="invoices_mobile-tab"
    >
      <!-- Show elements and global search-->
      <div class="paginator-container">
        <div class="flex items">
          <div class="mr-5">
            <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
              >Mostra
            </span>
            <select
              v-model="pagination.forPage"
              class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
              @change="(event) => changeNumPages(event)"
            >
              <option
                v-for="show in forPageOption"
                :key="show"
                :value="show"
                class="form-select"
              >
                {{ show }}
              </option>
            </select>
            <span class="text-md font-bold ml-2 mt-1"> elementi</span>
          </div>

          <!-- <input
            v-model="ricerca"
            type="text"
            placeholder="Cerca"
            class="pl-3 pr-10 py-1 border-2 border-gray-200 rounded-full focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500"
          /> -->
        </div>
        <Paginator
          :for-page-option="pagination.forPage"
          :total-records="totalRecords"
          @changePagination="changePagination"
        />
      </div>
      <!-- BEGIN: Data List -->
      <div
        class="table-container fatt-att-cont scrollbar-thin scrollbar-thumb-theme-44 scrollbar-track-theme-2 overflow-scroll"
      >
        <table class="w-full shadow custom-border rounded-lg">
          <THeader
            v-for="(item, itemKey) in tableConfig_invoices"
            :key="itemKey"
            :name="item.title"
            class="t-header h-10"
          />

          <tbody class="overflow-scroll scrollbar-none">
            <tr v-if="false" class="">
              <td
                v-for="(elem, index) in tableConfig_invoices"
                :key="index"
                class="text-center pr-3 pb-3"
              >
                <div
                  v-if="elem.title === 'Data invio'"
                  class="flex-col text-left"
                >
                  <div class="flex items-baseline">
                    <span class="mr-2 font-bold">Da: </span
                    ><input
                      v-model="startDate"
                      class="form-control h-7 mt-2 p-0 text-center border-theme-45"
                      type="date"
                      @change="filterHandler(elem)"
                    />
                  </div>
                  <div class="flex items-baseline">
                    <span class="mr-4 font-bold">A: </span
                    ><input
                      v-model="endDate"
                      class="form-control h-7 mt-2 p-0 text-center border-theme-45"
                      type="date"
                      @change="filterHandler(elem)"
                    />
                  </div>
                </div>
                <select
                  v-if="elem.type === 'select'"
                  v-model="table_filter[elem.key]"
                  class="w-full border rounded-md h-7 text-gray-500 mt-2"
                >
                  <option selected></option>
                  <option
                    v-for="(item, i) in elem.option"
                    :key="i"
                    :value="item"
                  >
                    {{ item.name ? item.name : item }}
                  </option>
                </select>
                <input
                  v-else
                  v-model="elem.value"
                  :type="elem.type"
                  min="0"
                  :name="elem.title"
                  class="form-control h-7 mt-2 text-left border-theme-45 px-0"
                  :class="{
                    invisible: elem.type === 'none',
                    'p-0': elem.type === 'date'
                  }"
                  :placeholder="elem.title"
                  @change="filterHandler(elem)"
                />
              </td>
            </tr>
            <tr
              v-for="(n, index) in docsToShow"
              :key="index"
              class="h-12 text-xs font-normal hover:bg-theme-44 cursor-pointer"
              :class="{ 'bg-theme-2': index % 2 === 0 }"
              @click="$emit('selectPrestazione', n)"
            >
              <td class="text-center border-r border-theme-45 text-sm">
                <b>{{ n.category }}</b>
              </td>
              <td class="text-center border-r border-theme-45 text-sm">
                <b>{{ n.alias ?? n.description }}</b>
              </td>
            </tr>
          </tbody>
        </table>
        <ConfirmModal
          v-if="showModal"
          @remove="removeRow"
          @close="showModal = false"
        />

        <InvoiceStatusModal
          v-if="showStatusModal"
          :notifications="notifications"
          @close="showStatusModal = false"
        />
      </div>
      <!-- END: Data List -->
    </div>
    <!--END: invoice list-->
  </div>
</template>
<script>
const forPageOption = [10, 20, 50, 100]

import Paginator from '../paginator/Main.vue'
import THeader from '../cvit-table/THeader.vue'
import axios from '../../gateway/backend-api'
import ConfirmModal from '../modals/ConfirmModal.vue'
import InvoiceStatusModal from '../modals/InvoiceStatusModal.vue'

export default {
  components: {
    Paginator,
    THeader,
    ConfirmModal,
    InvoiceStatusModal
  },
  props: ['searchText', 'selectedTariffario'],
  emits: [
    'showInvoice',
    'handleSavePdfRev',
    'handleNotaDiCredito',
    'selectPrestazione'
  ],
  data() {
    return {
      notifications: null,
      showStatusModal: false,
      ricerca: '',
      invoices: null,
      invoicesFiltered: null,
      startDate: null,
      endDate: null,
      invoiceTypes: [],
      showModal: false,
      showEntries: forPageOption,
      currentPage: 1,
      allPages: 1,
      totalRecords: '',
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      table_filter: [],
      active_filters: {},
      tableConfig_invoices: [
        {
          key: 'category',
          title: 'Categoria',
          type: 'none'
        },
        {
          key: 'alias',
          title: 'Alias',
          type: 'none'
        }
      ]
    }
  },
  computed: {
    docsToShow: function () {
      console.log('ricarica', this.invoices)
      let r = []
      if (this.invoices) {
        Object.keys(this.invoices).forEach((el) => {
          console.log(el, this.invoices[el])
          this.invoices[el].forEach((e) => {
            console.log(e)
            r.push({ ...e, category: el })
          })
        })
      }
      return r
    }
  },
  watch: {
    searchText: function () {
      this.getBills()
    }
  },
  mounted() {
    this.getBills()
  },

  methods: {
    // ----- METODI GESTIONE FILTRI ----- //
    // Metodo generico per handler filtro select
    filterSelect(elem, field) {
      if (elem) {
        this.active_filters[field] = elem.id ? elem.id : elem.value
      } else this.active_filters[field] = undefined

      this.checkIfFilterActive()

      if (this.filterActive) this.getBills()
    },
    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()

      if (this.filterActive) this.getBills()
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    },
    // ----- FINE METODI GESTIONE FILTRI ----- //
    getBills() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/tariffario/nomenclatore',
          {
            tariffario_clinic_id: this.selectedTariffario,
            per_page: this.pagination.forPage,
            page: this.pagination.page,
            only_alias: true,
            search: this.searchText
          }
        )
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.invoices = res.data.data
          this.totalRecords = res.data.total
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
      // this.$store.dispatch('commonComponents/showLoadingModalAction')
      // axios
      //   .post(
      //     '/' + localStorage.getItem('clinic') + '/bills/index-passive-bills',
      //     {
      //       page: this.pagination.page,
      //       per_page: this.pagination.forPage,
      //       active_filters: this.active_filters,
      //       sent_from: this.startDate,
      //       sent_to: this.endDate
      //     }
      //   )
      //   .then((res) => {
      //     this.$store.dispatch('commonComponents/hideLoadingModalAction')
      //     this.invoices = res.data.data
      //     this.totalRecords = res.data.total
      //   })
      //   .catch(() => {
      //     this.$store.dispatch('commonComponents/hideLoadingModalAction')
      //   })
    },
    changePagination: function (value) {
      if (this.pagination.page != value.page) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        this.getBills()
      }
    },
    changeNumPages: function (elem) {
      this.pagination.forPage = elem.target.value
      this.getBills()
    },
    openDeleteModal(index) {
      this.showModal = true
      this.selectedIndexDelete = index
    }
  }
}
</script>
