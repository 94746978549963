<template>
  <div class="login w-screen">
    <div class="h-screen flex items-center mx-24">
      <!-- BEGIN: Login Info -->
      <div
        class="flex-1 grid grid-cols-10 gap-5 lg:gap-1 items-center -mt-10 place-content-center justify-center"
      >
        <div
          class="col-span-10 lg:col-span-6 mx-36 mb-8 lg:mx-16 -mt-20 lg:-mt-10"
        >
          <img
            class="login_logo"
            alt="logo-vetsgo"
            src="@/assets/images/logo-blue.svg"
          />
        </div>

        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->

        <div class="col-span-10 lg:col-span-4 mx-3 md:mx-10 flex flex-col">
          <div
            class="my-auto mx-auto xl:ml-20 bg-white xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h1
              class="nunito text-theme-40 intro-x text-xl lg:text-2xl text-center"
            >
              La tua password è stata resettata!
            </h1>
            <div
              class="flex-col justify-center items-center text-center py-2 mt-3 gap-3"
            >
              <h2 class="text-base font-medium">
                Segui le istruzioni arrivate per Email per completare il reset.
              </h2>

              <h3 class="mt-4">
                Per problemi e segnalazioni contattare il numero verde:
                <b>800 060 952</b>
              </h3>
            </div>

            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-center">
              <button
                class="orange-btn text-lg w-40"
                @click="this.$store.dispatch('goToLogin')"
              >
                Torna al login
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- END: Login Form -->
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from 'vue'

export default defineComponent({
  setup() {
    onMounted(() => {
      cash('body')
        .removeClass('main')
        .removeClass('error-page')
        .addClass('login_body')
    })
  }
})
</script>
