<template>
  <!-- BEGIN: Clinical reports-->
  <div class="intro-y box w-full border rounded border-gray-600 md:p-5">
    <h2
      class="text-center font-medium text-base pb-2 mb-2 border-b border-gray-300"
    >
      Casi clinici di {{ petDetails.name }}
    </h2>
    <div class="w-full">
      <div class="relative top-4">
        <button class="btn btn-primary">
          <PlusIcon class="w-4 h-4 mr-2" />
          Nuovo caso clinico
        </button>
      </div>
      <div class="p-5"></div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg">
        <table
          class="w-full shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <THeader
            v-for="(item, itemKey) in tableData.config"
            :key="itemKey"
            :name="item.title"
          />
          <tbody>
            <tr>
              <td
                class="whitespace-nowrap text-center pr-3 pb-3"
                v-for="(elem, index) in tableData.input"
                :key="index"
              >
                <select
                  class="w-full border rounded-md h-7 text-gray-500 mt-2"
                  v-if="elem.type === 'select'"
                >
                  <option selected>{{ elem.title }}</option>
                  <option
                    v-for="(item, index) in elem.option"
                    :key="index"
                    value=""
                  >
                    {{ item }}
                  </option>
                </select>
                <input
                  :type="elem.type"
                  min="0"
                  :name="elem.title"
                  class="form-control h-7 mt-2"
                  v-else
                  :class="{
                    invisible: elem.type === 'none',
                    'p-0': elem.type === 'date'
                  }"
                  :placeholder="elem.title"
                />
              </td>
            </tr>
            <tr
              v-for="(faker, fakerKey) in $_.take($f(), 6)"
              :key="fakerKey"
              class="h-12 text-base font-normal hover:bg-blue-200 cursor-pointer"
              :class="{ 'bg-blue-100': fakerKey % 2 === 0 }"
            >
              <td class="w-6 h-6 text-center border-r border-gray-500">
                <div class="w-6 h-6 m-auto bg-green-200 raggio">
                  <i class="fas fa-lock" />
                </div>
              </td>
              <Cell
                :elementi="faker.dates[0]"
                class="border-r border-gray-500"
              />
              <Cell
                :elementi="faker.dates[1]"
                class="border-r border-gray-500"
              />
              <Cell
                :elementi="faker.products[0].category"
                class="border-r border-gray-500"
              />
              <Cell
                :elementi="faker.products[0].category"
                class="border-r border-gray-500"
              />
              <Cell
                :elementi="faker.products[0].category"
                class="border-r border-gray-500"
              />
              <Cell
                :elementi="faker.products[0].category"
                class="border-r border-gray-500"
              />
              <td class="flex lg:block text-center text-xl">
                <!--              <a class="cursor-pointer border-r border-gray-500">-->
                <!--                <i class="fas fa-mobile-alt p-3"/>-->
                <!--              </a>-->
                <!--              <a class="cursor-pointer border-r border-gray-500">-->
                <!--                <i class="fas fa-file-pdf p-3"/>-->
                <!--              </a>-->
                <!--              <a class="cursor-pointer border-r border-gray-500">-->
                <!--                <i class="fas fa-envelope p-3"/>-->
                <!--              </a>-->
                <a class="cursor-pointer border-r border-gray-500">
                  <i class="fas fa-eye p-2" />
                </a>
                <a class="cursor-pointer">
                  <i class="fas fa-times p-3" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
    </div>
  </div>
  <!-- END: Clinical reports-->
</template>

<script>
import { defineComponent } from 'vue'
import THeader from '../../components/cvit-table/THeader.vue'
import Cell from '../../components/cvit-table/Cell.vue'

export default defineComponent({
  components: {
    THeader,
    Cell
  },
  name: 'PetClinicalCases',
  props: {
    petDetails: Object
  },
  data() {
    return {
      tableData: {
        config: [
          {
            key: 'emergencyCode',
            title: 'Codice'
          },
          {
            key: 'beginDate',
            title: 'Aperto il'
          },
          {
            key: 'endDate',
            title: 'Chiuso il'
          },
          {
            key: 'department',
            title: 'Reparto'
          },
          {
            key: 'doctor',
            title: 'Medico curante'
          },
          {
            key: 'referent',
            title: 'Medico referente'
          },
          {
            key: 'reason',
            title: 'Motivo del ricovero'
          },
          {
            key: 'quick-actions',
            title: ''
          }
        ],
        input: [
          {
            key: 'emergencyCode',
            title: 'Codice',
            type: 'select',
            options: []
          },
          {
            key: 'beginDate',
            title: 'Aperto il',
            type: 'date'
          },
          {
            key: 'endDate',
            title: 'Chiuso il',
            type: 'date'
          },
          {
            key: 'department',
            title: 'Reparto',
            type: 'select',
            option: []
          },
          {
            key: 'doctor',
            title: 'Medico curante',
            type: 'text'
          },
          {
            key: 'referent',
            title: 'Medico referente',
            type: 'text'
          },
          {
            key: 'reason',
            title: 'Motivo del ricovero',
            type: 'text'
          }
        ]
      }
    }
  }
})
</script>

<style>
.raggio {
  border-radius: 10px;
}
</style>
