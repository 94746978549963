<template>
  <div>
    <div
      v-if="permissions && permissions?.Staff?.view_staff?.enabled === true"
      class="bg-white rounded-lg px-3 py-5"
    >
      <div class="paginator-container">
        <div class="mr-5 my-2 flex justify-between flex-1">
          <div class="mt-1">
            <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
              >Mostra
            </span>
            <select
              id=""
              v-model="pagination.forPage"
              name=""
              class="form-select w-16 h-6 pr-2 pl-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
            >
              <option
                v-for="show in forPageOption"
                :key="show"
                :value="show"
                class="form-select px-3"
              >
                {{ show }}
              </option>
            </select>
            <span class="text-md font-bold ml-2 mt-1"> elementi</span>
          </div>
        </div>
        <Paginator
          class="flex-0"
          :for-page-option="pagination.forPage"
          :total-records="totalRecords"
          @changePagination="changePagination"
        />
      </div>

      <div
        class="flex flex-col items-end md:flex-row md:items-center gap-2 md:justify-between mt-3 px-3"
      >
        <div class="w-full md:w-2/5 relative">
          <input
            v-model="search"
            type="text"
            class="custom-input"
            placeholder="Cerca..."
          />
          <SearchIcon class="w-4 h-4 absolute top-2 mr-3 right-0" />
        </div>
        <div
          class="flex-1 flex items-center justify-between md:justify-end gap-2 mt-3"
        >
          <button
            v-if="
              permissions &&
              permissions?.Staff?.store_staff?.enabled === true &&
              user?.role?.id === 1
            "
            class="orange-btn-inverted"
            @click="openModalAddStaff"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 75.5 70.9"
              class="fill-current w-5 h-6"
            >
              <path
                d="M30.2 30.7c3.3 0 6.3-1.2 8.5-3.4s3.4-5.2 3.4-8.5c.1-3.1-1-6.1-3.1-8.4-1.6-1.7-4.3-3.6-8.8-3.7H30c-3.1 0-6 1.2-8.1 3.3-2.3 2.3-3.6 5.4-3.6 8.8 0 6.6 5.2 11.8 11.9 11.8Zm10.5 13.4h-.4c-.8-.1-1.6-.3-2.3-.4-.6 0-1.1 0-1.7-.1-4.6-.4-9.3-.2-13.8.2-8.6.6-15.4 7.7-15.7 16.5 0 .7 0 1.1.1 1.2.1 0 .5.2 1.2.2h30.7c-.6-2.1-1-4.3-1-6.5 0-3.9 1-7.7 3-11Z"
                style="fill: none"
              />
              <path
                d="M32.7 61.6H8c-.7 0-1-.1-1.2-.2 0-.1-.2-.5-.1-1.2.3-8.8 7.1-15.9 15.7-16.5 4.5-.3 9.1-.6 13.8-.2.6 0 1.1.1 1.7.2.8 0 1.6.2 2.3.4h.4c1-1.6 2.1-3.2 3.5-4.6s.8-.8 1.3-1.2c-1.4-.5-2.9-.9-4.4-1.2-1.7-.3-3.4-.5-5.1-.6 2.9-.9 5.6-2.4 7.7-4.6 3.5-3.5 5.4-8.1 5.4-13.1.2-4.8-1.6-9.6-4.9-13.1C40.5 2.1 35.7 0 30.3 0c-5 0-9.6 1.8-13.1 5.2-3.6 3.6-5.6 8.4-5.6 13.6 0 8.6 5.6 15.6 13.4 17.9-1 0-2 .1-3 .2C9.9 37.8.5 47.7 0 60c0 2.6.6 4.7 2 6.2s3.5 2.2 6 2.2h26.6v-.3s.1.2.2.3H42c-1.5-2.1-2.7-4.3-3.5-6.8h-6ZM18.4 18.9c0-3.4 1.3-6.5 3.6-8.8 2.1-2.1 5-3.3 8.1-3.3h.2c4.5 0 7.3 2 8.8 3.7 2.1 2.2 3.2 5.2 3.1 8.2 0 3.4-1.2 6.4-3.4 8.6-2.2 2.2-5.2 3.4-8.5 3.4-6.6 0-11.8-5.2-11.9-11.8Z"
              />
              <path
                d="M75.1 53.4c-.9-1.9-2.6-2.1-4.3-2.2h-7.2v-7.9c-.1-2.5-1.7-4-3.9-4-2.1 0-3.5 1.5-3.7 4-.1 2-.1 4 0 6 0 1.4-.4 2-1.9 1.9-1.8-.1-3.6 0-5.3 0-3.2.1-4.7 1.5-4.7 3.9 0 2.5 1.7 3.9 4.8 4 1.7 0 3.4.1 5.1 0 1.6-.1 2.1.5 2 2.1v6.2c.2 2.2 1.5 3.5 3.5 3.6 2.4.1 3.9-1.4 4-4v-7.8h7.8c3.3-.2 5.1-2.9 3.8-5.7Z"
              />
            </svg>
          </button>
        </div>
      </div>

      <!-- BEGIN: Users Layout -->

      <div
        class="grid grid-cols-12 mt-5 gap-4 mx-3 staff-cont overflow-auto scrollbar_custom"
      >
        <div
          v-for="staff in staffs"
          :key="staff"
          class="col-span-12 sm:col-span-6 md:col-span-4"
        >
          <div
            class="bg-white shadow-md border boder-theme-49 border-opacity-60 rounded-md"
          >
            <div class="flex flex-col lg:flex-row items-center p-5">
              <div class="w-24 h-24 lg:w-12 lg:h-12 image-fit lg:mr-1">
                <img
                  alt="Icewall Tailwind HTML Admin Template"
                  class="rounded-full"
                  src="@/assets/images/avatar-ok.png"
                />
              </div>
              <div
                class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0"
              >
                <a class="font-bold nunito text-theme-40"
                  >{{ staff.last_name }} {{ staff.first_name }}</a
                >
                <div class="text-gray-600 text-xs mt-0.5">
                  {{ staff.role.name }}
                </div>
              </div>
              <div class="flex mt-4 lg:mt-0">
                <button class="gray-btn" @click="getProfilo(staff)">
                  Profilo
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- BEGIN: Users Layout -->
    </div>
  </div>

  <AddStaffUserModal
    v-if="addStaffUserOpen"
    :err="err"
    :staff-user-selected="staffUserSelected"
    @close="closeModalAddStaff"
    @refresh="refreshData"
    @save="addStaffUser"
    @edit="editStaffUser"
    @deleteUser="deleteUser"
  />
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import axios from '../../../gateway/backend-api'
import Paginator from '../../../components/paginator/Main.vue'
import AddStaffUserModal from '../../../components/modals/AddStaffUserModal.vue'

const forPageOption = [12, 24, 36, 48, 60]
export default defineComponent({
  components: {
    Paginator,
    AddStaffUserModal
  },
  data() {
    return {
      staffs: [],
      search: '',
      staffUserSelected: null,
      addStaffUserOpen: false,
      err: '',
      totalRecords: 0,
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      }
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions',
      user: 'getUser'
    })
  },

  watch: {
    'pagination.forPage': function (elem) {
      if (elem) this.getStaffs()
    },
    search: function () {
      this.getStaffs()
    }
  },
  mounted() {
    this.getStaffs()
  },
  methods: {
    getStaffs() {
      axios
        .post('/' + localStorage.getItem('clinic') + '/staff', {
          per_page: this.pagination.forPage,
          page: this.pagination.page,
          search: this.search
        })
        .then((res) => {
          this.staffs = res.data.data
          this.totalRecords = res.data.total
        })
    },
    openModalAddStaff() {
      this.addStaffUserOpen = true
    },
    closeModalAddStaff() {
      this.addStaffUserOpen = false
      this.staffUserSelected = null
    },
    getProfilo(user) {
      this.staffUserSelected = user
      this.openModalAddStaff()
    },

    editStaffUser(params) {
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/staff/' +
            params.staff_user_id +
            '/edit',
          params
        )
        .then((res) => {
            this.closeModalAddStaff()
            this.getStaffs()
            this.editErr(res)
        })
    },

    addStaffUser(params) {
      axios
        .post('/' + localStorage.getItem('clinic') + '/staff/store', params)
        .then((res) => {
          if (typeof res.data.access_token != 'undefined') {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Utente inserito con successo'
            })
          }
          this.closeModalAddStaff()
          this.getStaffs()
          this.editErr(res)
        })
        .catch((e) => {
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'Errore:' + e
          })
        })
    },

    deleteUser(staff_user_id) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler eliminare questo utente?',
        background: false,
        confirm: () => {
          axios
            .delete(
              '/' + localStorage.getItem('clinic') + '/staff/' + staff_user_id
            )
            .then(() => {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Utente eliminato con successo'
              })
              this.closeModalAddStaff()
              this.getStaffs()
            })
            .catch((e) => {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Errore:' + e
              })
            })
        },
        decline: () => {
          console.log('Ho declinato la risposta')
        }
      })
    },
    refreshData() {
      this.getStaffs()
    },

    editErr(res) {
      this.err = ''
      if (typeof res.data.username != 'undefined')
        this.err += res.data.username + '<br>'
      if (typeof res.data.username != 'undefined')
        this.err += res.data.username + '<br>'
      if (typeof res.data.email != 'undefined')
        this.err += res.data.email + '<br>'
      if (typeof res.data.first_name != 'undefined')
        this.err += res.data.first_name + '<br>'
      if (typeof res.data.last_name != 'undefined')
        this.err += res.data.last_name + '<br>'
    },

    changePagination: function (value) {
      if (this.pagination.page != value.page) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        this.getStaffs()
      }
    }
  }
})
</script>
