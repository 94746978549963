<template>
  <!-- BEGIN: Clinical reports-->
  <div class="pb-4 my-2">
    <h2 class="details-title text-center">
      Schede cliniche di: {{ petDetails.name }}
    </h2>
    <div class="w-full">
      <div class="flex w-full justify-end mb-5">
        <button
          type="file"
          class="orange-btn mr-3"
          @click="openDetailsModal('scheda_clinica')"
        >
          <PlusIcon class="h-5 w-5 mr-2" /> Scheda Clinica (EOG)
        </button>
        <button type="file" class="orange-btn" @click="openSpecialisticModal()">
          <PlusIcon class="h-5 w-5 mr-2" /> Scheda Specialistica
        </button>
      </div>
      <!-- BEGIN: Data List -->
      <SchedeClinicheTable
        v-if="docs && tableConfig_clinic_doc"
        :tableConfig_clinic_doc="tableConfig_clinic_doc"
        :documents="docs"
        :selected-patient="true"
        @handleSendMessage="
          (elem) => {
            $emit('handleSendMessage', elem)
          }
        "
        @handleDetailsModal="openDetailsModal"
      />

      <!-- END: Data List -->
    </div>
    <!-- BEGIN: Modal Content -->

    <!-- END: Modal Content -->
  </div>
  <!-- END: Clinical reports-->
</template>

<script>
import { defineComponent } from 'vue'
import axios from '../../gateway/backend-api'
import SchedeClinicheTable from './../tables/SchedeClinicheTable.vue'

export default defineComponent({
  name: 'PetClinicalRecords',
  components: { SchedeClinicheTable },
  props: {
    petDetails: {
      type: [Object],
      default: function () {
        return {}
      }
    }
  },
  emits: ['handleSendMessage', 'handleDetailsModal', 'handleSpecialisticModal'],
  data() {
    return {
      tableConfig_clinic_doc: null,
      docs: null,
      messageModal: false,
      petClinicalDocuments: null
    }
  },
  mounted() {
    axios
      .get('/schede_cliniche_table_config') // scarico la configurazione della tabella
      .then((resp) => {
        const temp = []
        for (var elem of resp.data) {
          temp.push({ ...elem, value: '' }) // ai dati ricevuti aggiungo un campo per il valore del filtro che sarà inserito
        }
        this.tableConfig_clinic_doc = temp
      })

    // axios
    //   .post('/schede_cliniche') // scarico la lista di documenti
    //   .then((resp) => {
    //     this.docs = resp.data
    //     this.totalRecords = resp.data.length
    //   })
    if (localStorage.getItem('clinic')) {
      axios
        .post('/' + localStorage.getItem('clinic') + '/medical', {
          pet_id: this.petDetails.id,
          type: 'clinical_card'
        }) // scarico la lista di documenti
        .then((res) => {
          this.docs = res.data
          this.totalRecords = res.data.length
        })
    }
  },

  methods: {
    openDetailsModal(elem) {
      // chiamata per prendere la configurazione della scheda clinica da creare o modificare
      this.$emit('handleDetailsModal', elem, true)
    },
    openSpecialisticModal() {
      this.$emit('handleSpecialisticModal', true)
    }
  }
})
</script>
