<!-- Modale di conferma cancellazione fattura -->
<template>
  <div>
    <div
      id="confirm-delete-modal-fatturazione"
      class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50"
    >
      <div
        class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <div class="fixed inset-0"></div>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>
        <div
          class="inline-block align-bottom bg-white rounded-3xl py-3 text-left overflow-hidden transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
        >
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 pb-2 sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-lg font-medium" id="modal-title">
                  Cancella Fattura
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500">
                    Sei sicuro di voler cancellare questa fattura?
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            class="border-t border-gray-200 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
          >
            <div class="mt-2">
              <button
                @click="this.$emit('remove')"
                id="dialogbox-delete-button"
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
              >
                <DeleteIcon class="w-4 h-4 mr-2" />Si, cancella
              </button>

              <button
                @click="this.$emit('close')"
                autofocus
                id="dialogbox-anulla-button"
                type="button"
                class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
              >
                Annulla
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // Eventi personalizzati da richiamare nel componente FatturazioneAttiva
  event: ['remove', 'close'],
  methods: {},
  created() {}
}
</script>

<style></style>
