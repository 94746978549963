<template>
  <div class="intro-y box px-5 py-3 m-1">
    <PassiveTable
      :type="['invoice']"
      @showInvoice="(el) => openInvoiceModalReadOnly(el)"
    />
    <div v-if="invoiceModalOpen">
      <NewInvoiceModal
        :read-only="invoiceReadOnly"
        :read-only-elem="invoiceElem"
        :client="client"
        :title="newInvoiceTitle"
        :services="[]"
        :medium-size="true"
        @close="closeInvoiceModal"
        @responseModal="handleResponseSubmitInvoice"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import PassiveTable from '../../components/invoice-table/PassiveTable.vue'
import NewInvoiceModal from '../../components/new-invoice/NewInvoiceModal.vue'
import axios from '../../gateway/backend-api'

export default defineComponent({
  components: {
    PassiveTable,
    NewInvoiceModal
  },
  setup() {
    const invoiceModalOpen = ref(false)
    const newInvoiceTitle = ref(null)
    const invoiceReadOnly = ref(false)
    const invoiceElem = ref(null)
    const client = ref(null)

    const closeInvoiceModal = () => {
      newInvoiceTitle.value = null
      invoiceModalOpen.value = false

      invoiceReadOnly.value = false
      invoiceElem.value = null
    }

    return {
      invoiceModalOpen,
      client,
      invoiceElem,
      invoiceReadOnly,
      newInvoiceTitle,
      closeInvoiceModal
    }
  },
  methods: {
    openInvoiceModalReadOnly(el) {
      axios
        .get(
          '/' + localStorage.getItem('clinic') + '/customer/' + el.customer_id
        )
        .then((res) => {
          this.client = res.data

          this.newInvoiceTitle = 'Riepilogo Fattura'
          this.invoiceModalOpen = true
          this.invoiceReadOnly = true
          this.invoiceElement = el
        })
    }
  }
})
</script>

<style scoped></style>
