<template>
  <div
    v-if="permissions && permissions?.vetslab?.view_vetslab?.enabled === true"
    class=""
  >
    <div class="flex flex-col md:flex-row items-center justify-between my-5">
      <div class="w-full">
        <h5 class="form-label">SELEZIONARE UN MACCHINARIO</h5>
        <div class="flex items-center gap-3">
          <v-select
            v-model="selectedMachine"
            class="w-full"
            :options="selectedMachines"
            label="name"
            placeholder="Inserisci almeno 2 caratteri"
            @option:selected="checkMachineIfActive"
            @option:deselected="changeDotStatus"
          >
          </v-select>
          <i class="fas fa-circle" :class="colourDotStatus"></i>
          <i
            class="fas fa-sync-alt cursor-pointer fill-current hover:text-theme-41"
            @click="checkMachineIfActive()"
          ></i>
        </div>
      </div>
      <button
        v-if="
          permissions &&
          permissions?.vetslab?.reserve_exam?.enabled === true &&
          machines.length
        "
        type="file"
        class="orange-btn px-3 py-1 flex mr-4 w-48"
        @click="handleVetslabModal"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 576 512"
          class="h-5 w-5 fill-current mr-1"
        >
          <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
          <path
            d="M0 64C0 28.65 28.65 0 64 0H224V128C224 145.7 238.3 160 256 160H384V198.6C310.1 219.5 256 287.4 256 368C256 427.1 285.1 479.3 329.7 511.3C326.6 511.7 323.3 512 320 512H64C28.65 512 0 483.3 0 448V64zM256 128V0L384 128H256zM288 368C288 288.5 352.5 224 432 224C511.5 224 576 288.5 576 368C576 447.5 511.5 512 432 512C352.5 512 288 447.5 288 368zM448 303.1C448 295.2 440.8 287.1 432 287.1C423.2 287.1 416 295.2 416 303.1V351.1H368C359.2 351.1 352 359.2 352 367.1C352 376.8 359.2 383.1 368 383.1H416V431.1C416 440.8 423.2 447.1 432 447.1C440.8 447.1 448 440.8 448 431.1V383.1H496C504.8 383.1 512 376.8 512 367.1C512 359.2 504.8 351.1 496 351.1H448V303.1z"
          />
        </svg>

        Nuovo Esame
      </button>
    </div>
    <!--BEGIN: invoice list-->
    <div
      id="invoices"
      class=""
      role="tabpanel"
      aria-labelledby="invoices_mobile-tab"
    >
      <!-- Show elements and global search-->
      <div class="paginator-container">
        <div class="flex items">
          <div class="mr-5">
            <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
              >Mostra
            </span>
            <select
              v-model="pagination.forPage"
              class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
              @change="(event) => changeNumPages(event)"
            >
              <option
                v-for="show in forPageOption"
                :key="show"
                :value="show"
                class="form-select"
              >
                {{ show }}
              </option>
            </select>
            <span class="text-md font-bold ml-2 mt-1"> elementi</span>
          </div>
        </div>
        <Paginator
          v-if="pagination.forPage < totalRecords"
          :for-page-option="pagination.forPage"
          :total-records="totalRecords"
          @changePagination="changePagination"
        />
      </div>
      <!-- BEGIN: Data List -->
      <div class="table-container overflow-auto scrollbar_custom pb-6">
        <table class="w-full shadow custom-border rounded-lg">
          <thead class="h-10 t-header">
            <tr>
              <th
                v-for="(item, itemKey) in tableHeaders"
                :key="itemKey"
                :name="item.title"
              >
                {{ item.title }}
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody class="">
            <tr
              v-for="(n, index) in documents"
              :key="index"
              class="h-12 text-xs font-normal hover:bg-theme-44 cursor-pointer"
              :class="{ 'bg-theme-2': index % 2 === 0 }"
            >
              <td
                class="text-center border-r border-theme-45 whitespace-nowrap px-3"
              >
                <div>
                  <span class="text-sm break-words ml-2">
                    {{ n.exam_id }}
                  </span>
                </div>
              </td>
              <td
                class="text-center border-r border-theme-45 whitespace-nowrap px-3"
              >
                <div>
                  <TableShotColor :stato="n.status" />
                  <span class="text-sm break-words ml-2">
                    {{
                      n.status == 'created'
                        ? 'Esame Creato'
                        : n.status == 'processing' ||
                          n.status == 'exams_duplicated'
                        ? 'Esame Prenotato'
                        : 'Esame Eseguito'
                    }}
                  </span>
                </div>
              </td>

              <Cell
                class="text-center border-r border-theme-45 whitespace-nowrap px-3"
                :elementi="$FormatDate(n.datetime)"
              />
              <Cell
                class="text-center border-r border-theme-45 whitespace-nowrap px-3"
                :elementi="n.name"
              />
              <Cell
                class="text-center border-r border-theme-45 whitespace-nowrappx-3"
                :elementi="
                  n.created_by.first_name
                    ? n.created_by.first_name + ' ' + n.created_by.last_name
                    : n.created_by.username
                "
              />
              <Cell
                class="text-center border-r border-theme-45 whitespace-nowrap px-3"
                :elementi="n.machine_name"
              />
              <!-- <td class="text-center border-r border-theme-45 px-3">
                <div>
                  <span
                    v-for="s in n.services"
                    :key="s.id"
                    class="text-xs break-words ml-2"
                  >
                    {{ s.name }}
                  </span>
                </div>
              </td> -->
              <td
                class="text-center border-r border-theme-45 whitespace-nowrap px-3"
              >
                <div @click="handleGoToCustomer(n.customer?.id)">
                  <ChevronsRightIcon
                    v-if="n.customer"
                    class="h-5 w-5 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                  />
                  <span class="text-sm ml-2">
                    {{
                      n.customer
                        ? n.customer.first_name + ' ' + n.customer.last_name
                        : n.customer.username
                    }}
                  </span>
                </div>
              </td>
              <td
                class="text-center border-r border-theme-45 whitespace-nowrap px-3"
              >
                <div @click="handleGoToPet(n.pets?.id)">
                  <ChevronsRightIcon
                    v-if="n.pets"
                    class="h-5 w-5 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                  />
                  <span class="text-sm break-words ml-2">
                    {{ n.pets ? n.pets.name : '' }}
                  </span>
                </div>
              </td>
              <td class="text-xs">
                <div
                  class="flex items-center text-center justify-evenly text-xs"
                >
                  <a
                    v-if="n.details?.xml"
                    class="mx-auto my-2 cursor-pointer flex-1"
                    @click="showXml(n.details)"
                  >
                    <i class="fas fa-code px-1"></i>
                  </a>
                  <a
                    v-if="n.status == 'created'"
                    class="mx-auto my-2 cursor-pointer"
                    @click="reserve(n.id)"
                  >
                    <i class="fas fa-share p-3" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
    </div>
    <!--END: invoice list-->
  </div>
  <div
    v-else-if="
      permissions && permissions?.vetslab?.view_vetslab?.enabled === false
    "
  >
    <h1>I permessi per visualizzare i clienti non sono abilitati.</h1>
  </div>

  <VetslabModal
    v-if="showVetslabModal"
    :pet-invoice="petInvoice"
    :client-invoice="clientInvoice"
    :machines="machines"
    @selectClientPet="handleClientPet"
    @close="handleVetslabModal"
    @update="getList"
  />
</template>
<script>
const forPageOption = [5, 10, 20, 50]

import Paginator from '../paginator/Main.vue'
import Cell from '../../components/cvit-table/Cell.vue'
import axios from '../../gateway/backend-api'
import router from '@/router'
import { mapActions, mapGetters } from 'vuex'
import TableShotColor from '../details-customer/TableShotColor.vue'
import VetslabModal from '../../components/modals/VetslabModal.vue'

export default {
  components: {
    Paginator,
    Cell,
    TableShotColor,
    VetslabModal
  },
  emits: [],
  data() {
    return {
      documents: [],
      showVetslabModal: false,
      totalRecords: '',
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      tableHeaders: [
        {
          key: 'exam_id',
          title: 'ID Esame'
        },
        {
          key: 'state',
          title: 'Stato'
        },
        {
          key: 'date',
          title: 'Richiesto il'
        },
        {
          key: 'type',
          title: 'Esame'
        },
        {
          key: 'created_by',
          title: 'Richiesto da'
        },
        {
          key: 'machine',
          title: 'Macchinario'
        },
        // {
        //   key: 'services',
        //   title: 'Servizi'
        // },
        {
          key: 'customer',
          title: 'Cliente'
        },
        {
          key: 'pet',
          title: 'Paziente'
        }
      ],
      clientInvoice: null,
      petInvoice: null,
      machines: [],
      selectedMachines: [],
      selectedMachine: null,
      colourDotStatus: 'text-gray-500'
    }
  },
  computed: {
    ...mapGetters({
      getPetsDetails: 'anagraficaPazienti/getPetsDetails',
      getComparisonPets: 'anagraficaPazienti/getComparisonPets',
      getCustomersDetails: 'anagraficaPazienti/getCustomersDetails',
      getComparisonCustomer: 'anagraficaPazienti/getComparisonCustomer',
      getTab: 'anagraficaPazienti/getTab',
      getShow: 'anagraficaPazienti/getShow',
      permissions: 'reusableData/getPermissions'
    })
  },
  watch: {
    selectedMachine: function () {
      this.checkMachineIfActive()
    }
  },
  mounted() {
    this.getData()
    this.getList()
  },
  methods: {
    ...mapActions({
      getPetAction: 'anagraficaPazienti/getPetAction',
      getCustomerAction: 'anagraficaClienti/getCustomerAction',
      goToOpenedClient: 'anagraficaClienti/goToOpenedClient'
    }),
    getData() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/vetslab-exams/index-machinery'
        )
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          if (res.data && res.data[0].machine) {
            this.machines = res.data

            this.selectedMachine = res.data[0].machine[0]
            this.machines.forEach((el) => {
              this.selectedMachines.push(el.machine[0])
            })
          }
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    getList() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/vetslab-exams/list', {
          page: this.pagination.page,
          per_page: this.pagination.forPage
        })
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.documents = res.data.data
          this.totalRecords = res.data.total
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    changePagination: function (value) {
      if (this.pagination.page != value.page) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        this.getList()
      }
    },
    changeNumPages: function (elem) {
      this.pagination.forPage = elem.target.value
      this.getList()
    },
    handleGoToCustomer: function (id) {
      axios
        .get('/' + localStorage.getItem('clinic') + '/customer/' + id)
        .then((res) => {
          this.getCustomerAction(res.data)
          this.goToOpenedClient(res.data.id)
          router.push({ name: 'side-menu-anagrafica-anagrafica-clienti' })
        })
    },
    handleGoToPet: function (id) {
      axios
        .get('/' + localStorage.getItem('clinic') + '/pet/' + id)
        .then((res) => {
          const pet = res.data

          if (
            this.getPetsDetails.length < 4 &&
            !this.getComparisonPets.includes(pet.id)
          ) {
            this.getPetAction(res.data)
            router.push({ name: 'side-menu-anagrafica-anagrafica-pazienti' })
          } else if (this.getComparisonPets.includes(pet.id)) {
            this.tabAlreadyOpen()
            router.push({ name: 'side-menu-anagrafica-anagrafica-pazienti' })
          } else {
            this.maxTabOpen()
            router.push({ name: 'side-menu-anagrafica-anagrafica-pazienti' })
          }
        })
    },
    handleVetslabModal: function () {
      this.showVetslabModal = !this.showVetslabModal
    },
    showXml(xml) {
      this.$store.dispatch('commonComponents/openPdfModal', {
        title: xml.file_name,
        link: null,
        background: false,
        print: () => {
          console.log('PRINT')
        },
        download: () => {
          const url = window.URL.createObjectURL(
            new Blob([xml.xml], { type: 'text/plain' })
          )
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', xml.file_name)
          document.body.appendChild(link)
          link.click()
        },
        xml: xml.xml
      })
    },
    handleClientPet(elem, value) {
      if (elem == 'clientInvoice') this.clientInvoice = value
      else if (elem == 'petInvoice') this.petInvoice = value
    },
    changeDotStatus(status = null) {
      this.colourDotStatus =
        status == 'active'
          ? 'text-green-500'
          : status == 'disable'
          ? 'text-red-500'
          : 'text-gray-500'
    },
    checkMachineIfActive() {
      if (this.selectedMachine) {
        this.$store.dispatch('commonComponents/showLoadingModalAction')
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/vetslab-exams/ping-to-machine',
            {
              machine_name: this.selectedMachine.code
            }
          )
          .then((res) => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
            this.changeDotStatus(res.data.status)
          })
          .catch(() => {
            this.$store.dispatch('commonComponents/hideLoadingModalAction')
          })
      } else this.changeDotStatus()
    }
  }
}
</script>
