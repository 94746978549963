<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black scrollbar-none bg-opacity-50"
  >
    <div
      class="w-3/5 sm:w-3/5 relative top-12 p-5 mx-auto overflow-scroll scrollbar-none border shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div
        v-if="showPassword"
        class="flex flex-col content-end overflow-y-auto"
      >
        <!-- Modal header -->

        <div class="w-full">
          <h3 class="modal-title mt-8">Cambia Password</h3>
        </div>

        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div
          v-if="!passwordDone"
          class="px-6 py-3 gap-8 flex flex-col items-center"
        >
          <div class="flex flex-col mb-1 text-center text-xl gap-2 px-40">
            <label
              for="newclient-birth"
              class="block text-gray-700 text-xs font-bold mb-2"
              >Nuova Password</label
            >

            <input
              id="newclient-birth"
              v-model="new_psw"
              class="w-full shadow appearance-none border border-gray-300 rounded py-1.5 px-5 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none placeholder-gray-400"
              type="password"
              placeholder="Inserire nuova password"
            />
            <label
              for="newclient-birth"
              class="block text-gray-700 text-xs font-bold mb-2"
              >Ripeti Password</label
            >

            <input
              id="newclient-birth"
              v-model="new_psw_confirm"
              class="w-full shadow appearance-none border border-gray-300 rounded py-1.5 px-5 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none placeholder-gray-400"
              type="password"
              placeholder="Inserire nuova password"
            />
            <div class="py-2">
              <h2 class="text-xs font-semibold">
                La password deve essere composta da:
              </h2>
              <div class="flex items-start justify-start mt-1">
                <ul class="text-xs text-left pt-2">
                  <li class="mt-1">• Almeno 8 caratteri</li>
                  <li class="mt-1">
                    • Almeno un carattere appartenente alle lettere maiuscole
                  </li>
                  <li class="mt-1">
                    • Almeno un carattere appartenente alle lettere minuscole
                  </li>
                  <li class="mt-1">• Almeno un carattere numerico</li>
                  <li class="mt-1">• Almeno un carattere speciale</li>
                </ul>
                <!-- <p class="text-xs mr-1 text-left">
                  • Almeno 8 caratteri • Almeno un carattere appartenente alle
                  lettere maiuscole • Almeno un carattere appartenente alle
                  lettere minuscole • Almeno un carattere numerico • Almeno un
                  carattere speciale
                </p> -->
              </div>
            </div>
            <p v-if="errorePassword" class="text-red-600 text-s">
              {{ errorePassword }}
            </p>
          </div>
          <button
            type="button"
            class="orange-btn px-3 py-1 mx-1"
            @click="changePwd"
          >
            <i class="fas fa-save mr-1"></i>
            Salva password
          </button>
        </div>
        <div v-else class="px-6 py-3 gap-2 flex flex-col items-center">
          <CheckCircleIcon class="w-8 h-8 text-theme-41 mx-auto mt-3" />
          <div class="text-l">Password modificata correttamente</div>
        </div>
        <!-- Modal footer -->

        <!-- Fine Modal footer -->
      </div>
      <div
        v-if="showConsensi"
        class="flex flex-col content-end overflow-y-auto"
      >
        <!-- Modal header -->

        <div class="w-full">
          <h3 class="modal-title mt-8">Consenso comunicazioni</h3>
        </div>

        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div
          v-if="!consensiDone"
          class="px-6 py-3 gap-8 flex flex-col items-center"
        >
          <div class="flex-1 flex items-baseline py-2">
            <input
              id="newclient-gdpr"
              v-model="consenso"
              class="form-checkbox mr-3 h-3 w-3 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
              type="checkbox"
              required
              style="box-shadow: none"
            />
            <p
              v-if="affiliate === true"
              class="block text-gray-700 text-sm font-bold"
            >
              Autorizzo il trattamento dei miei dati personali ai sensi del Dlgs
              196 del 30 giugno 2003 e dell'art. 13 GDPR e acconsento all'invio,
              da parte di Gruppocvit di comunicazioni e News letter informative
              e/o promozionali
            </p>
            <p v-else class="block text-gray-700 text-sm font-bold">
              Autorizzo il trattamento dei miei dati personali ai sensi del Dlgs
              196 del 30 giugno 2003 e dell'art. 13 GDPR ai soli fini
              dell'erogazione del servizio.
            </p>
          </div>
          <button
            type="button"
            class="orange-btn px-3 py-1 mx-1"
            @click="acceptConsensi"
          >
            <i class="fas fa-save mr-1"></i>
            Salva
          </button>
          <p v-if="erroreConsenso" class="text-red-600 text-s">
            {{ erroreConsenso }}
          </p>
        </div>
        <!-- <div v-else class="px-6 py-3 gap-2 flex flex-col items-center">
          <CheckCircleIcon class="w-8 h-8 text-theme-41 mx-auto mt-3" />
          <div class="text-l">Consensi accettati correttamente 1</div>
        </div> -->
        <!-- Modal footer -->

        <!-- Fine Modal footer -->
      </div>
      <!-- <div v-else class="px-6 py-3 gap-2 flex flex-col items-center">
        <CheckCircleIcon class="w-8 h-8 text-theme-41 mx-auto mt-3" />
        <div class="text-l">Consensi accettati correttamente 2</div>
      </div> -->
      <div
        v-if="signLicence === true"
        class="flex flex-col content-end overflow-y-auto"
      >
        <!-- Modal header -->

        <div class="w-full">
          <h3 class="modal-title mt-8">Licenze</h3>
        </div>

        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div
          v-if="!licenzaDone"
          class="px-6 py-3 gap-8 flex flex-col items-center"
        >
          <div class="flex-1 flex justify-between w-full items-baseline py-2">
            <input
              id="newclient-gdpr"
              v-model="licenza_1"
              class="form-checkbox mr-3 h-3 w-3 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
              type="checkbox"
              required
              style="box-shadow: none"
            />
            <div class="flex flex-col">
              <p class="block text-gray-700 text-sm font-bold">
                Accetto le licenze
              </p>
            </div>
          </div>
          <div class="flex-1 flex items-baseline w-full justify-between py-2">
            <input
              id="newclient-gdpr"
              v-model="licenza_2"
              class="form-checkbox mr-3 h-3 w-3 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
              type="checkbox"
              required
              style="box-shadow: none"
            />
            <div class="flex flex-col">
              <p class="block text-gray-700 text-sm font-bold">
                Ai sensi e per gli effetti degli articoli 1341 e 1342 del Codice
                Civile, le Parti approvano espressamente le seguenti:
              </p>
              <p class="block text-gray-700 text-sm font-bold pl-3">
                - Accettazione dei termini e condizioni<br />
                - Credenziali di autenticazione<br />
                - Conservazione dati<br />
                - Sospensione servizio<br />
                - Limitazioni della Responsabilità<br />
                - Legge applicabile e Foro competente<br />
                - Allegato 1 -<br />
              </p>
            </div>
          </div>
          <p v-if="erroreLicenze" class="text-red-600 text-s">
            {{ erroreLicenze }}
          </p>

          <a
            class="text-blue-500"
            target="_blank"
            :href="
              affiliate === true
                ? 'https://uploadvetsgo.fra1.digitaloceanspaces.com/tos/TOS%20Vets%20Go%20Vale%20Affiliati.pdf'
                : 'https://uploadvetsgo.fra1.digitaloceanspaces.com/tos/TOS%20Vets%20Go%20Vale%20VetsBOX.pdf'
            "
            >LEGGI CONDIZIONI COMPLETE</a
          >

          <button
            type="button"
            class="orange-btn px-3 py-1 mx-1"
            @click="acceptLicenze"
          >
            <i class="fas fa-save mr-1"></i>
            Salva
          </button>
        </div>
        <div v-else class="px-6 py-3 gap-2 flex flex-col items-center">
          <CheckCircleIcon class="w-8 h-8 text-theme-41 mx-auto mt-3" />
          <div class="text-l">Licenze accettate correttamente</div>
        </div>

        <!-- Modal footer -->

        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, onMounted } from 'vue'
import axios from '../../gateway/backend-api'
import { useStore } from '@/store'

export default defineComponent({
  props: ['showPassword', 'showConsensi', 'showLicenzaUso'],
  emits: [],
  setup(props) {
    // ----- DATA -----

    const new_psw = ref(null)
    const new_psw_confirm = ref(null)
    const consenso = ref(false)
    const licenza_1 = ref(false)
    const licenza_2 = ref(false)

    const store = useStore()
    const userId = computed(() => store.state.userId)

    const errorePassword = ref(null)
    const erroreConsenso = ref(null)
    const erroreLicenze = ref(null)

    const passwordDone = ref(false)
    const licenzaDone = ref(false)
    const consensiDone = ref(false)

    const affiliate = ref(false)
    const signLicence = ref(false)

    onMounted(() => {
      setLocalStorage()
    })

    // ----- METHODS -----
    const setLocalStorage = () => {
      affiliate.value = JSON.parse(localStorage.getItem('clinic-affiliate'))
      signLicence.value = JSON.parse(
        localStorage.getItem('clinic-sign-acceptance')
      )
    }

    // this.$store.dispatch('changePassword', {
    //       token: this.$route.query.token,
    //       email: this.$route.query.email,
    //       password: this.newPassword,
    //       password_confirmation: this.confirmPassword
    //     })

    const checkIfCloseModal = () => {
      if (
        (props.showPassword === false || passwordDone.value === true) &&
        (props.showConsensi === false || consensiDone.value === true) &&
        (props.showLicenzaUso === false || licenzaDone.value === true)
      ) {
        store.dispatch('commonComponents/closePasswordModal')
      }
    }

    const checkPsw = () => {
      let charValidator = new RegExp(
        '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})'
      )

      errorePassword.value = null
      if (
        !new_psw.value ||
        !new_psw_confirm.value ||
        new_psw.value === '' ||
        new_psw_confirm.value === ''
      ) {
        errorePassword.value = 'Si prega di compilare tutti i campi'
        return false
      }
      if (new_psw.value !== new_psw_confirm.value) {
        errorePassword.value = 'I due campi non corrispondono'
        return false
      }

      if (
        !charValidator.test(new_psw.value) ||
        !charValidator.test(new_psw_confirm.value)
      ) {
        errorePassword.value = 'I requisiti della password non sono soddisfatti'
        return false
      }

      return true
    }

    const changePwd = () => {
      let controlPsw = checkPsw()
      if (!controlPsw) return false
      axios
        .post('/change-password-first-login', {
          token: localStorage.getItem('userToken'),
          staff_user_id: userId.value,
          password: new_psw.value,
          password_confirmation: new_psw_confirm.value
        })
        .then(() => {
          passwordDone.value = true
          checkIfCloseModal()
        })
    }

    const acceptConsensi = () => {
      if (consenso.value === false) {
        erroreConsenso.value = 'Selezionare consenso prima di continuare'
        return false
      }

      axios
        .post('/' + localStorage.getItem('clinic') + '/staff/set-acceptances', {
          consens_signed_at: true
        })
        .then(() => {
          consensiDone.value = true
          checkIfCloseModal()
        })
    }

    const acceptLicenze = () => {
      if (licenza_1.value === false || licenza_2.value === false) {
        erroreLicenze.value = 'Selezionare prima di continuare'
        return false
      }

      let send_data = {}
      if (affiliate.value == true) {
        send_data.tos_affiliate_signed_at = true
      } else {
        send_data.tos_not_affiliate_signed_at = true
      }
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/staff/set-acceptances',
          send_data
        )
        .then(() => {
          licenzaDone.value = true
          localStorage.setItem('clinic-sign-acceptance', false)
          checkIfCloseModal()
        })
        .catch((err) => {
          store.dispatch('commonComponents/openNotificationModal', {
            message:
              err?.response?.data?.msg ?? "Errore, contattare l'assistenza"
          })
        })
    }

    // ----- COMPUTED -----

    return {
      userId,
      new_psw,
      new_psw_confirm,
      consenso,
      errorePassword,
      erroreConsenso,
      erroreLicenze,
      licenza_1,
      licenza_2,
      changePwd,
      acceptConsensi,
      acceptLicenze,
      passwordDone,
      licenzaDone,
      consensiDone,
      affiliate,
      signLicence
    }
  }
})
</script>
