<template>
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-12 p-2 mx-auto border w-4/5 md:w-3/5 shadow-lg rounded-md bg-white"
    >
      <button type="button" class="closing-btn" @click="handleClose">
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <div class="">
        <!-- Modal header -->
        <div class="w-full">
          <h3 class="modal-title mt-10">
            Aggiungi un nuovo abbinamento colore
          </h3>
        </div>
        <div class="px-6 py-3 grid grid-cols-3 gap-3">
          <div class="col-span-3">
            <label class="form-label"> Specie</label>
            <v-select
              v-model="filter_type"
              multiple
              :options="petTypes"
              label="name"
              :reduce="(value) => value.id"
              placeholder="Seleziona specie"
            >
              <template #option="option">
                {{ option.name }}
              </template>
            </v-select>
          </div>

          <div v-if="!showCustom" class="col-span-3">
            <label for="" class="form-label">Colore</label>
            <v-select
              v-model="filter_color"
              :options="colors"
              label="color"
              :reduce="(value) => value.id"
              placeholder="Seleziona colore"
            >
              <template #option="option">
                {{ option.color }}
              </template>
            </v-select>
          </div>

          <div v-if="showCustom" class="col-span-3">
            <label for="" class="form-label">Colore custom</label>
            <input
              v-model="newColor"
              type="text"
              class="custom-input flex-1"
              placeholder="Inserisci una nuova colorazione manto"
            />
          </div>
        </div>
        <div
          class="flex flex-col sm:flex-row items-center justify-between py-4 gap-3"
        >
          <div class="flex justify-start px-6">
            <div v-if="!showCustom" class="">
              <button
                class="orange-btn-inverted flex gap-2 items-center"
                @click="showCustom = true"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 448 512"
                  class="w-3 fill-current"
                >
                  <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path
                    d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"
                  />
                </svg>
                <span>Colore custom</span>
              </button>
            </div>
            <button
              v-if="showCustom"
              class="orange-btn-inverted flex gap-2 items-center"
              @click="showCustom = false"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
                class="w-2 mt-0.5 ml-0.5 fill-current"
              >
                <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                <path
                  d="M459.5 440.6c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29V96c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4L288 214.3V256v41.7L459.5 440.6zM256 352V256 128 96c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4l-192 160C4.2 237.5 0 246.5 0 256s4.2 18.5 11.5 24.6l192 160c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29V352z"
                />
              </svg>

              <span> Torna alla selezione </span>
            </button>
          </div>
          <div class="flex gap-3 px-6 justify-end">
            <button class="orange-btn" @click="addColor()">Conferma</button>
            <button class="gray-btn" @click="handleClose">Annulla</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import axios from '../../../gateway/backend-api'

export default defineComponent({
  emits: ['close'],
  setup() {},
  data() {
    return {
      petTypes: [],
      colors: [],
      showCustom: false
    }
  },
  mounted() {
    axios
      .get('/' + localStorage.getItem('clinic') + '/pet/types_breeds')
      .then((res) => {
        this.petTypes = res.data
      })
    axios
      .get('/' + localStorage.getItem('clinic') + '/pet/colors')
      .then((res) => {
        this.colors = res.data
      })
  },
  methods: {
    addColor() {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'Aggiungi abbinamento colorazione manto',
        subtitle: 'Sei sicuro di aggiungere questo nuovo abbinamento colore?',
        background: false,
        confirm: () => {
          axios
            .post('/' + localStorage.getItem('clinic') + '/pet/new-colors', {
              pet_type_id: this.filter_type,
              color_id: this.filter_color,
              color: this.newColor
            })
            .then((res) => {
              this.$store.dispatch('commonComponents/openMessageModal', {
                title: 'Conferma',
                subtitle:
                  res.status === 200
                    ? 'Accoppiamento inserito con successo'
                    : res
              })
              this.$emit('close')
            })
          this.showNewType = false
        },
        decline: () => {
          return false
        }
      })
    },
    handleClose() {
      this.$emit('close')
    }
  }
})
</script>
