<template>
  <!--
    fatturato/completed =  verde
    acconto = blue
    pending = grigio
    ...
    -->
  <!-- <i
    class="fas fa-circle"
    :class="
      stato === 'fatturato'
        ? 'text-green-500'
        : stato === 'acconto'
        ? 'text-blue-500'
        : 'text-green-500'
    "
  /> -->

  <i v-if="stato == 'completed'" class="fas fa-circle text-green-500" />
  <i v-else-if="stato == 'debito'" class="fas fa-circle text-red-500" />
  <i
    v-else-if="stato == 'to_pay'"
    class="fas fa-circle"
    style="color: #800000"
  />
  <!--fatturato-->
  <i
    v-else-if="stato == 'bozza'"
    class="fas fa-circle"
    style="color: rgb(249 115 22)"
  />
  <i v-else-if="stato == 'pending'" class="fas fa-circle text-gray-500" />
  <i v-else-if="stato == 'acconto'" class="fas fa-circle text-yellow-500" />

  <!-- vetslab -->
  <i v-else-if="stato == 'processing'" class="fas fa-circle text-gray-500" />
  <i v-else-if="stato == 'created'" class="fas fa-circle text-yellow-500" />

  <!-- magazzino -->
  <i v-else-if="stato == 'expired'" class="fas fa-circle text-red-500" />
  <i v-else-if="stato == 'valid'" class="fas fa-circle text-green-500" />
  <i v-else-if="stato == 'qty_limit'" class="fas fa-circle text-yellow-500" />

  <!--sala d'attesa-->
  <i v-else-if="code == 'bianco'" class="fas fa-circle text-gray-400" />
  <i v-else-if="code == 'verde'" class="fas fa-circle text-green-500" />
  <i v-else-if="code == 'giallo'" class="fas fa-circle text-yellow-500" />
  <i v-else-if="code == 'rosso'" class="fas fa-circle text-red-500" />
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: ['stato', 'code'],
  setup() {}
})
</script>
