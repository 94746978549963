<template>
  <!-- Main modal -->
  <div
    id="defaultModal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-12 p-5 mx-auto overflow-scroll scrollbar-none border w-4/5 lg:w-3/5 shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->

      <div class="mb-9">
        <button type="button" class="closing-btn m" @click="$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <!-- Modal header -->
      <div class="my-2 mx-2">
        <h3 class="modal-title">Notifiche Fattura</h3>
      </div>
      <!-- Modal body -->
      <div class="mx-2 py-3">
        <div
          class="table-container scrollbar scrollbar-thumb-theme-44 scrollbar-track-theme-2 overflow-y-scroll h-5/6 pb-5"
        >
          <table class="w-full">
            <thead class="t-header w-full h-12 border border-theme-40">
              <tr>
                <template
                  v-for="(notification, i) in notifications"
                  :key="notification"
                >
                  <template v-if="i == 0">
                    <td
                      v-for="(n, k) in notification"
                      :key="k"
                      class="uppercase"
                    >
                      {{ k }}
                    </td>
                  </template>
                </template>
              </tr>
            </thead>

            <tbody>
              <template
                v-for="notification in notifications"
                :key="notification"
              >
                <tr
                  class="bg-theme-41 bg-opacity-50 border border-theme-41 py-2 rounded-sm"
                >
                  <td
                    v-for="(n, k) in notification"
                    :key="k"
                    class="text-center p-3"
                  >
                    {{ n }}
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
      </div>
      <!-- Modal footer -->
      <div class="flex justify-end items-center mx-3">
        <button class="ml-3 gray-btn" type="button" @click="$emit('close')">
          <span class="h-5"></span>
          Annulla
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    notifications: {
      type: [Array],
      default: function () {
        return []
      }
    }
  },
  emits: ['close']
})
</script>
