<template>
  <div class="relative inline-block text-left mt-6" style="width: 200px">
    <button
      type="button"
      class="bg-white custom-input flex items-center justify-between pl-5 pr-2 gap-5"
      id="menu-button"
      aria-expanded="true"
      aria-haspopup="true"
      @click="changeShowServices"
    >
      {{ this.text }}
      <!-- Heroicon name: solid/chevron-down -->
      <svg
        class="h-5 w-5"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fill-rule="evenodd"
          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
          clip-rule="evenodd"
        />
      </svg>
    </button>

    <div
      class="bg-white absolute top-8 px-4 child-centered rounded-lg"
      role="menu"
      aria-orientation="vertical"
      aria-labelledby="menu-button"
      tabindex="-1"
      v-if="showServices"
    >
      <div
        class="w-full flex items-center justify-between gap-3"
        role="none"
        v-for="(n, index) in servicesPassed"
        :key="n.id"
      >
        <div class="flex-0">
          <input
            type="checkbox"
            v-model="checked[index]"
            @click="changeCheck(index)"
            class="orange-check"
          />
        </div>
        <div class="flex-1 text-gray-700 px-4 py-2 text-sm">
          {{ n.desc }}
        </div>
        <div
          class="flex-1 text-gray-700 px-4 py-2 text-sm flex items-center gap-2"
        >
          <span class="font-bold">€</span> {{ n.taxable }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    services: [],
    oldChecks: []
  },
  watch: {
    services: {
      deep: true,
      handler() {}
    }
  },
  mounted() {
    this.servicesPassed = this.services
    if (this.oldChecks.length == 0) {
      for (let i = 0; i < this.servicesPassed.length; i++) {
        this.checked.push(true)
        //this.taxable += Number(this.servicesPassed[i].taxable)
        this.countCheck = this.servicesPassed.length
        this.text = 'Tutti'
      }
    } else {
      this.countCheck = 0
      this.checked = this.oldChecks
      for (let i = 0; i < this.checked.length; i++) {
        if (this.checked[i]) {
          this.countCheck++
          //this.taxable += Number(this.servicesPassed[i].taxable)
        }
      }
      if (this.countCheck < this.servicesPassed.length)
        this.text = this.countCheck + ' di ' + this.servicesPassed.length
      else this.text = 'Tutti'
    }

    for (let i = 0; i < this.servicesPassed.length; i++) {
      if (this.checked[i]) {
        this.taxable += Number(this.servicesPassed[i].taxable)
      }
    }

    this.changeTaxable()
  },
  data() {
    return {
      showServices: false,
      servicesPassed: [],
      checked: [],
      countCheck: '',
      text: '',
      taxable: 0
    }
  },
  methods: {
    changeShowServices() {
      this.showServices = !this.showServices
    },
    changeCheck(index) {
      this.checked[index] = !this.checked[index]
      if (this.checked[index] == false) {
        this.countCheck += -1
        this.taxable -= Number(this.servicesPassed[index].taxable)
      } else {
        this.countCheck += 1
        this.taxable += Number(this.servicesPassed[index].taxable)
      }
      if (this.countCheck == this.servicesPassed.length) {
        this.text = 'Tutti'
      } else {
        this.text = this.countCheck + ' di ' + this.servicesPassed.length
      }
      this.changeTaxable()
    },
    changeTaxable() {
      this.$emit('taxable', this.taxable, this.checked)
    }
  }
}
</script>
