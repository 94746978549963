<template>
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 md:scrollbar-none"
  >
    <div
      class="relative top-12 p-5 mx-auto border w-4/5 sm:w-3/5 shadow-lg rounded-md bg-white"
    >
      <button type="button" class="closing-btn" @click="$emit('close')">
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>

      <!-- Modal header -->
      <div class="w-full">
        <h3 class="modal-title mt-5">Aggiungi un nuovo vaccino</h3>
      </div>

      <div class="grid grid-cols-3 gap-3 py-3 px-6">
        <div class="flex-col col-span-3">
          <label class="form-label">Nome Vaccino</label>
          <input
            v-model="newVaccino.name"
            type="text"
            :class="{
              notapproved: !validation
            }"
            class="custom-input flex-1"
            placeholder="Inserisci un nuovo vaccino"
          />
          <span v-if="!validation" class="text-theme-41 mt-1">
            {{ error_message }}</span
          >
        </div>
        <div class="col-span-3 md:col-span-1">
          <label class="form-label">Numero Lotto</label>
          <input
            v-model="newVaccino.lotto"
            type="text"
            class="custom-input flex-1"
            placeholder="Inserisci il Numero Lotto"
          />
        </div>
        <div class="col-span-3 md:col-span-1">
          <label class="form-label">Specie</label>
          <v-select
            v-model="newVaccino.types"
            multiple
            :options="petTypes"
            label="name"
            :reduce="(value) => value.id"
            placeholder="Seleziona specie"
            class="flex-1"
          >
            <template #option="option">
              {{ option.name }}
            </template>
          </v-select>
        </div>
        <div class="col-span-3 md:col-span-1">
          <label class="form-label">Data</label>
          <input
            id="exp_date"
            v-model="newVaccino.exp_date"
            type="text"
            class="date custom-input flex-1"
            placeholder="Seleziona data di scadenza"
          />
        </div>
      </div>
      <div class="flex gap-3 justify-end mt-5 px-6">
        <button class="orange-btn" @click="addVaccine()">Conferma</button>
        <button @click="$emit('close')" class="gray-btn">Annulla</button>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import axios from '../../../gateway/backend-api'

import flatpickr from 'flatpickr'
import { Italian } from 'flatpickr/dist/l10n/it.js'

export default defineComponent({
  props: {},
  emits: ['close'],
  data() {
    return {
      petTypes: [],
      newVaccino: {},
      error_message: 'Questo è un campo obbligatorio',
      validation: true
    }
  },
  mounted() {
    this.getTypes()
    flatpickr.localize(Italian)

    flatpickr('.date', {
      dateFormat: 'd/m/Y',
      allowInput: true,
      allowInvalidPreload: false
    })
  },
  methods: {
    addVaccine() {
      let exp_epoch
      if (this.newVaccino.exp_date != undefined) {
        exp_epoch = this.$FormatEpoch(this.newVaccino.exp_date)
      }
      if (this.newVaccino.name == '' || this.newVaccino.name == null) {
        this.validation = false
      }
      if (this.validation) {
        axios
          .post('/' + localStorage.getItem('clinic') + '/vaccination', {
            types: this.newVaccino.types,
            name: this.newVaccino.name,
            lotto: this.newVaccino.lotto,
            exp_date: exp_epoch
          })
          .then(() => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Vaccino inserito con successo!'
            })
            this.$emit('close')
          })
      }
    },
    getTypes() {
      axios
        .get('/' + localStorage.getItem('clinic') + '/pet/types_breeds')
        .then((res) => {
          this.petTypes = res.data
        })
    }
  }
})
</script>
