<template>
  <!-- Main modal -->
  <div class="border-gray-200 border-2 scheda-container">
    <div class="schedaright_content">
      <!-- Modal content -->
      <div class="flex items-start py-3 px-8 rounded-t justify-center">
        <h3 class="text-2xl font-bold pt-1 nunito text-center">
          {{ title }}
        </h3>
      </div>
      <div
        class="flex flex-col content-end overflow-y-auto scrollbar-none mb-2"
        style="height: 66vh"
      >
        <!-- Modal header -->

        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div class="px-6 py-2">
          <div class="flex flex-col content-start mb-1 gap-2">
            <button
              v-for="x in computedOptions"
              :key="x"
              type="button"
              class="bg-theme-45 nunito rounded-lg p-2 hover:bg-theme-44"
              :class="selected === x ? 'bg-theme-40' : ''"
              @click="select(x)"
            >
              {{
                x.document_type
                  ? selectedCategory
                    ? x.document_type
                    : x.document_type.toUpperCase()
                  : '--'
              }}
            </button>
          </div>
        </div>
        <!-- Modal footer -->

        <!-- Fine Modal footer -->
      </div>
    </div>
    <div
      class="border-t-1 border-gray-400 w-full px-6 text-right flex justify-start items-end mb-2 gap-2"
    >
      <!-- <button @click="salva" type="button" class="orange-btn" ref="input4">
        <SaveIcon class="h-5 w-5 mr-2" /> Seleziona
      </button> -->
      <button type="button" class="gray-btn ml-3" @click="goBack()">
        <SkipBackIcon class="h-5 w-5 mr-2" /> Torna indietro
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'

import '@swoga/vue-collapsible-panel/dist/vue-collapsible-panel.css'

export default defineComponent({
  props: ['options', 'title'],
  emits: ['specialisticSelected', 'close'],
  setup(props, { emit }) {
    // ----- DATA -----
    const selectedCategory = ref(null)
    const selected = ref('')

    // contiene la copia della configurazione, mappando però i valori con quelli inseriti in input

    // ----- METHODS -----

    // abilito la modifica del modale

    const select = (x) => {
      if (x === selected.value) {
        salva()
      }
      // salva dati
      if (!selectedCategory.value) {
        selectedCategory.value = x.value
      } else {
        selected.value = x
      }
    }

    const salva = () => {
      // salva dati
      emit('specialisticSelected', selected.value)
    }

    const goBack = () => {
      if (selectedCategory.value && Object.keys(props.options).length > 1) {
        selectedCategory.value = null
      } else {
        emit('close')
      }
    }

    // ----- COMPUTED -----

    const computedOptions = computed(() => {
      if (!selectedCategory.value) {
        if (Object.keys(props.options).length === 1) {
          // se ho una sola sottosezione apro direttamente le scelte
          select({
            value: props.options[Object.keys(props.options)[0]],
            document_type: Object.keys(props.options)[0]
          })

          let x = props.options[Object.keys(props.options)[0]]

          x.sort((a, b) =>
            a.document_type.toLowerCase() > b.document_type.toLowerCase()
              ? 1
              : -1
          )
          return x
        }

        let tempoptions = []
        Object.keys(props.options).forEach(function (elem) {
          tempoptions.push({ value: props.options[elem], document_type: elem })
        })
        return tempoptions
      } else {
        let x = selectedCategory.value

        x.sort((a, b) =>
          a.document_type.toLowerCase() > b.document_type.toLowerCase() ? 1 : -1
        )
        return x
      }
    })

    return {
      selected,
      select,
      salva,
      computedOptions,
      selectedCategory,
      goBack
    }
  }
})
</script>
