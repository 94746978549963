<template>
  <SendMessageModal
    v-if="messageModal && selectedElem"
    :elem="selectedElem"
    @close="closeSendMessageModal"
    @confirm="confirmMessage"
  />

  <div class="flex flex-col p-4">
    <VaccinazioniTable
      v-if="docs && tableConfig_vaccine_doc"
      :table-config-clinic-doc="tableConfig_vaccine_doc"
      :documents="docs"
      :total-records="totalRecords"
      :pagination="pagination"
      :for-page-option="forPageOption"
      :selected-patient="true"
      @handleSendMessage="handleSendMessage"
      @deleteRecord="(doc, el) => handleDeleteRecord(doc, el)"
      @modifyVaccine="handleModifyVaccine"
      @removeVaccinazione="handleRemoveVaccinazione"
      @getList="(el) => getList(el)"
    />
  </div>
  <div>
    <div class="p-4">
      <div class="flex justify-end">
        <button type="file" class="orange-btn" @click="handleVaccineModal">
          <PlusIcon class="h-5 w-5 mr-2" /> Aggiungi Vaccinazione
        </button>
      </div>
    </div>
    <!--END: Da Effettuare-->

    <div
      v-if="!tableConfig_vaccine_doc || !docs"
      class="flex w-full h-screen content-center items-center justify-center"
    >
      <RotateCwIcon class="animate-spin h-full w-5 mr-3 scale-150" />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from '../../../gateway/backend-api'
import SendMessageModal from '../../modals/SendMessageModal.vue'

import VaccinazioniTable from '../../../views/area-medica/VaccinazioniTable.vue'

const forPageOption = [10, 20, 50]
export default defineComponent({
  components: {
    SendMessageModal,
    VaccinazioniTable
  },
  props: ['pet'],
  emits: ['openModal'],
  data() {
    return {
      tableConfig_vaccine_doc: null,
      docs: null,
      messageModal: false,
      vaccineModal: false,
      selectedElem: null,
      vaccineToModify: null,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      totalRecords: 0,
      forPageOption: forPageOption
    }
  },
  mounted() {
    axios
      .get('/vaccinazioni_table_config') // scarico la configurazione della tabella
      .then((resp) => {
        const temp = []
        for (var elem of resp.data) {
          temp.push({ ...elem, value: '' }) // ai dati ricevuti aggiungo un campo per il valore del filtro che sarà inserito
        }
        this.tableConfig_vaccine_doc = temp
      })

    this.getList()
  },

  methods: {
    getList(value = {}) {
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/vaccination/indexPetVaccination',
          {
            pet_id: this.pet.id,
            active_filters: value,
            per_page: this.pagination.forPage,
            page: this.pagination.page
          }
        )
        .then((resp) => {
          this.docs = resp.data.data
          this.totalRecords = resp.data.total
        })
    },
    handleSendMessage(elem) {
      // metodo che apre il popup di invio messaggio
      this.selectedElem = elem
      this.messageModal = true
    },
    closeSendMessageModal() {
      // metodo per chiudere il modal di invio messaggio
      this.selectedElem = null
      this.messageModal = false
    },
    handleVaccineModal() {
      this.$store.dispatch('commonComponents/openVaccineModal', {
        vaccineData: {
          pet: this.pet,
          client: this.pet?.owners[0] ?? null,
          elem: this.selectedElem,
          to_modify: this.vaccineToModify,
          confirm: this.addVaccinazione,
          close: this.closeVaccineModal
        }
      })
      // this.vaccineModal = true
      // document.body.scrollTop = 300 // For Safari
      // document.documentElement.scrollTop = 300 // For Chrome, Firefox, IE and Opera
    },
    closeVaccineModal() {
      this.$store.dispatch('commonComponents/closeVaccineModal')
      // this.vaccineModal = false
      this.vaccineToModify = null
    },

    confirmMessage() {
      // (titolo, message, file)
      this.messageModal = false
    },
    addVaccinazione(
      id_client,
      id_vaccine,
      execution_date,
      richiamo,
      id_medico,
      completed
    ) {
      if (!this.vaccineToModify) {
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/vaccination/storePetVaccination',
            {
              pet_id: this.pet.id,
              customer_id: id_client,
              vaccine_id: id_vaccine,
              execution_date: execution_date,
              richiamo: richiamo,
              staff_user_id: id_medico,
              completed: completed
            }
          )
          .then(() => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Vaccino salvato con successo!'
            })
            this.$store.dispatch('commonComponents/closeVaccineModal')
            this.getList()
          })
          .catch((err) => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: err
            })
            this.$store.dispatch('commonComponents/closeVaccineModal')
            this.getList()
          })
      } else {
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/vaccination/' +
              this.vaccineToModify.id +
              '/modifyPetVaccination',
            {
              pet_id: this.pet.id,
              customer_id: id_client,
              vaccine_id: id_vaccine,
              execution_date: execution_date,
              richiamo: richiamo,
              staff_user_id: id_medico,
              completed: completed
            }
          )
          .then(() => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Vaccino modificato con successo!'
            })
            this.$store.dispatch('commonComponents/closeVaccineModal')
            this.getList()
          })
          .catch(() => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Errore durante la modifica del vaccino!'
            })
          })
      }
    },
    handleDeleteRecord(doc, filter) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler cancellare la vaccinazione?',
        background: false,
        confirm_text: 'Conferma',
        decline_text: 'Annulla',
        confirm: () => {
          this.$store.dispatch('commonComponents/showLoadingModalAction')
          axios
            .post(
              '/' +
                localStorage.getItem('clinic') +
                '/vaccination/pet/' +
                doc.id
            )
            .then((res) => {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: res.data.msg
              })
              this.getList(filter)
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
            .catch(() => {
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
        },
        decline: () => {}
      })
    },
    handleModifyVaccine(data) {
      this.vaccineToModify = data
      this.$store.dispatch('commonComponents/openVaccineModal', {
        vaccineData: {
          pet: this.pet,
          client: this.pet?.owners[0] ?? null,
          elem: this.selectedElem,
          to_modify: data,
          confirm: this.addVaccinazione,
          close: this.closeVaccineModal
        }
      })
    }
  }
})
</script>
