<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-10 p-2 mx-auto overflow-scroll scrollbar-none border sm:w-3/5 w-full shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->

      <button type="button" class="closing-btn mr-2" @click="$emit('close')">
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          XMLns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>

      <div class="flex flex-col content-end overflow-y-auto mt-10">
        <form @submit.prevent="handleExport">
          <!-- Modal header -->
          <div class="flex modal-title justify-center">
            <h3 class="">Esporta documenti fiscali</h3>
          </div>
          <!-- Fine Modal header -->

          <!-- Modal body -->
          <div class="text-center py-5 px-6">
            <select v-model="selected" class="custom-input w-full">
              <option value="" disabled selected>Selezionare un formato</option>
              <option value="fattureExcel">
                Scarica le fatture in formato Excel
              </option>
              <option value="pagamentoExcel">
                Scarica i pagamenti in formato Excel
              </option>
              <option value="fattureElettroniche">
                Scarica XML fatture elettroniche
              </option>
              <option value="fattureStandard">
                Scarica XML fatture standard
              </option>
              <option value="fattureTesseraSanitaria">
                Scarica csv per Tessera Sanitaria
              </option>
            </select>
          </div>

          <!-- START opt Scarica le fatture in formato Excel -->

          <div v-if="selected === 'fattureExcel'" class="py-3 px-2">
            <h2 class="tariff-subs">Scarica le fatture in formato Excel</h2>

            <div class="grid grid-cols-7 items-center px-4 mt-5 py-2">
              <label class="form-label col-span-7">Filtra per data</label>
              <input
                v-model="FattureExcel.d1"
                type="date"
                class="col-span-3 custom-input"
              />
              <div class="col-span-1 text-center font-bold">a</div>
              <input
                v-model="FattureExcel.d2"
                type="date"
                class="col-span-3 custom-input"
              />
            </div>

            <div class="grid grid-cols-7 items-center px-4 pt-2">
              <label class="form-label col-span-7"
                >Filtra per numero di fattura</label
              >
              <input
                v-model="FattureExcel.f1"
                type="number"
                min="1"
                class="col-span-3 custom-input"
              />
              <div class="col-span-1 text-center font-bold">alla</div>
              <input
                v-model="FattureExcel.f2"
                type="number"
                min="1"
                class="col-span-3 custom-input"
              />
            </div>
          </div>
          <!-- END opt Scarica le fatture in formato Excel -->

          <!-- START opt Scarica pagamenti in formato Excel -->

          <div v-if="selected === 'pagamentoExcel'" class="py-3 px-2">
            <h2 class="tariff-subs">Scarica i pagamenti in formato Excel</h2>

            <div class="grid grid-cols-7 items-center px-4 mt-5 py-2">
              <label class="form-label col-span-7">Filtra per data</label>
              <input
                v-model="PagamentoExcel.d1"
                type="date"
                class="col-span-3 custom-input"
              />
              <div class="col-span-1 text-center font-bold">a</div>
              <input
                v-model="PagamentoExcel.d2"
                type="date"
                class="col-span-3 custom-input"
              />
            </div>

            <div class="grid grid-cols-7 items-center px-4 pt-2">
              <label class="form-label col-span-7"
                >Filtra per numero di pagamento</label
              >
              <input
                v-model="PagamentoExcel.p1"
                type="number"
                min="1"
                class="col-span-3 custom-input"
              />
              <div class="col-span-1 text-center font-bold">alla</div>
              <input
                v-model="PagamentoExcel.p2"
                type="number"
                min="1"
                class="col-span-3 custom-input"
              />
            </div>
          </div>
          <!-- END opt Scarica pagamenti in formato Excel -->

          <!-- START opt Scarica fatture elletroniche in formato XML -->

          <div v-if="selected === 'fattureElettroniche'" class="py-3 px-2">
            <h2 class="tariff-subs">
              Scarica le fatture elettroniche in formato XML
            </h2>

            <div class="grid grid-cols-7 items-center px-4 mt-5 py-2">
              <input
                v-model="FatturaElettronica.d1"
                type="date"
                class="col-span-3 custom-input"
                required
              />
              <div class="col-span-1 text-center font-bold">a</div>
              <input
                v-model="FatturaElettronica.d2"
                type="date"
                class="col-span-3 custom-input"
                required
              />
            </div>
            <div class="flex items-center gap-3 px-4 py-1">
              <input
                v-model="FatturaElettronica.consegnate"
                class="form-checkbox h-4 w-4 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
                type="checkbox"
              />
              <label class="font-bold text-gray-700"
                >Includi fatture consegnate</label
              >
            </div>
            <div class="flex items-center gap-3 px-4 py-1">
              <input
                v-model="FatturaElettronica.casetto_fiscale"
                class="form-checkbox h-4 w-4 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
                type="checkbox"
              />
              <label class="font-bold text-gray-700"
                >Includi fatture consegnate nel cassetto fiscale</label
              >
            </div>

            <div
              class="rounded-lg shadow-md border border-theme-44 border-opacity-60 mx-4 mt-2 p-2"
            >
              <div class="flex gap-3 justify-between">
                <label class="font-bold text-gray-700 py-1"
                  >Opzioni avanzate</label
                >
                <button
                  class="mt-1"
                  @click="showAdvanced = !showAdvanced"
                  type="button"
                >
                  <svg
                    XMLns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="h-4"
                    :class="[showAdvanced ? 'transform rotate-180' : '']"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </button>
              </div>

              <div v-if="showAdvanced" class="mt-2">
                <div
                  class="rounded-lg border border-theme-41 p-2 flex items-center gap-3 justify-center"
                >
                  <h2 class="font-bold text-theme-41 text-base">Attenzione!</h2>
                  <p class="font-semibold">
                    Le fatture nei seguenti stati non sono state ancora emesse!
                  </p>
                </div>
                <div class="mt-2">
                  <div class="flex items-center gap-3 px-4 py-1">
                    <input
                      v-model="FatturaElettronica.advanced.scartate"
                      class="form-checkbox h-4 w-4 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
                      type="checkbox"
                    />
                    <label class="font-bold text-gray-700"
                      >Includi fatture scartate</label
                    >
                  </div>
                  <div class="flex items-center gap-3 px-4 py-1">
                    <input
                      v-model="FatturaElettronica.advanced.inviate"
                      class="form-checkbox h-4 w-4 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
                      type="checkbox"
                    />
                    <label class="font-bold text-gray-700"
                      >Includi fatture inviate</label
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- END opt Scarica fatture elletroniche in formato XML -->

          <!-- START opt Scarica fatture standard in formato XML -->

          <div v-if="selected === 'fattureStandard'" class="py-3 px-2">
            <h2 class="tariff-subs">
              Scarica le fatture standard in formato XML
            </h2>

            <div class="grid grid-cols-7 items-center px-4 mt-5 py-2">
              <input
                v-model="FattureStandard.d1"
                type="date"
                class="col-span-3 custom-input"
              />
              <div class="col-span-1 text-center font-bold">a</div>
              <input
                v-model="FattureStandard.d2"
                type="date"
                class="col-span-3 custom-input"
              />
            </div>
          </div>
          <div v-if="selected === 'fattureTesseraSanitaria'" class="py-3 px-2">
            <h2 class="tariff-subs">
              Scarica le fatture per Tessera Sanitaria
            </h2>
            <div class="grid grid-cols-7 items-center px-4 mt-5 py-2">
              <input
                v-model="FattureTS.d1"
                type="date"
                class="col-span-3 custom-input"
              />
              <div class="col-span-1 text-center font-bold">a</div>
              <input
                v-model="FattureTS.d2"
                type="date"
                class="col-span-3 custom-input"
              />
            </div>

            <div class="grid grid-cols-7 items-center px-4 mt-5 py-2">
              <!-- <select v-model="FattureTSYear" class="custom-input w-full">
                <option value="" disabled selected>Selezionare un anno</option>

                <option v-for="y in years" :key="y" :value="y">
                  {{ y }}
                </option>
              </select> -->
            </div>
          </div>

          <!-- END opt Scarica fatture standard in formato XML -->

          <!-- Fine Modal body -->

          <!-- Modal footer -->
          <div
            class="border-t-1 border-gray-400 w-full px-6 text-right flex justify-end items-end mb-2"
          >
            <div class="flex justify-end items-center mt-5">
              <button
                id="exportSubmitButton"
                type="submit"
                class="orange-btn flex items-center gap-3"
              >
                <svg
                  class="fill-current"
                  xmlns="http://www.w3.org/2000/svg"
                  height="1em"
                  viewBox="0 0 576 512"
                >
                  <!--! Font Awesome Free 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <path
                    d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V288H216c-13.3 0-24 10.7-24 24s10.7 24 24 24H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zM384 336V288H494.1l-39-39c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39H384zm0-208H256V0L384 128z"
                  />
                </svg>
                Esporta
              </button>

              <button
                class="ml-3 gray-btn"
                type="button"
                @click="$emit('close')"
              >
                <span class="h-5"></span>
                Annulla
              </button>
            </div>
          </div>
          <!-- Fine Modal footer -->
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from '../../gateway/backend-api'
import moment from 'moment'
import { useStore } from '@/store'

const today = moment().format('YYYY-MM-DD')
const store = useStore()

export default defineComponent({
  components: {},
  emits: ['close'],
  data() {
    return {
      showAdvanced: false,
      selected: '',
      FattureExcel: {
        d1: today,
        d2: today,
        f1: null,
        f2: null
      },
      PagamentoExcel: {
        d1: today,
        d2: today,
        p1: null,
        p2: null
      },
      FatturaElettronica: {
        d1: today,
        d2: today,
        consegnate: false,
        casetto_fiscale: false,
        advanced: {
          scartate: false,
          inviate: false
        }
      },
      FattureStandard: {
        d1: today,
        d2: today
      },
      FattureTS: {
        d1: today,
        d2: today
      }
      // years: ['2019', '2020', '2021', '2022', '2023']
    }
  },
  methods: {
    handleExport() {
      var params = {}
      if (this.selected) {
        if (this.selected == 'fattureElettroniche') {
          params = {
            type: 'elettronica',
            startDate: this.$FormatEpochEnglish(this.FatturaElettronica.d1),
            endDate: this.$FormatEpochEnglish(this.FatturaElettronica.d2),
            consegnate: this.FatturaElettronica.consegnate,
            casetto_fiscale: this.FatturaElettronica.casetto_fiscale,
            advanced: this.FatturaElettronica.advanced
          }
        } else if (this.selected == 'fattureStandard') {
          params = {
            type: 'cartacea',
            startDate: this.$FormatEpochEnglish(this.FattureStandard.d1),
            endDate: this.$FormatEpochEnglish(this.FattureStandard.d2)
          }
        } else if (this.selected == 'fattureExcel') {
          params = {
            type: 'fatture_excel',
            startDate: this.$FormatEpochEnglish(this.FattureExcel.d1),
            endDate: this.$FormatEpochEnglish(this.FattureExcel.d2),
            startNumber: this.FattureExcel.f1,
            endNumber: this.FattureExcel.f2
          }
        } else if (this.selected == 'pagamentoExcel') {
          params = {
            type: 'pagamento_excel',
            startDate: this.$FormatEpochEnglish(this.PagamentoExcel.d1),
            endDate: this.$FormatEpochEnglish(this.PagamentoExcel.d2),
            startNumber: this.PagamentoExcel.p1,
            endNumber: this.PagamentoExcel.p2
          }
        } else if (this.selected === 'fattureTesseraSanitaria') {
          params = {
            startDate: this.$FormatEpochEnglish(this.FattureTS.d1),
            endDate: this.$FormatEpochEnglish(this.FattureTS.d2)
          }
        }

        params.timezone = 'GMT' + moment().format('Z')
        let filename =
          this.selected +
          '-' +
          (
            new Date().toISOString().slice(0, 10) +
            '-' +
            new Date().toISOString().slice(11, 19)
          ).replaceAll(':', '-')

        store.dispatch('commonComponents/showLoadingModalAction')

        if (
          ['fattureElettroniche', 'fattureStandard'].includes(this.selected)
        ) {
          axios
            .post('/' + localStorage.getItem('clinic') + '/export', params, {
              responseType: 'blob'
            })
            .then((res) => {
              var binaryData = []
              binaryData.push(res.data)
              let fileUrl = window.URL.createObjectURL(
                new Blob(binaryData, { type: 'application/octet-stream' })
              )

              let fileLink = document.createElement('a')
              fileLink.href = fileUrl
              fileLink.setAttribute('download', filename + '.zip')
              document.body.appendChild(fileLink)
              fileLink.click()
              store.dispatch('commonComponents/hideLoadingModalAction')
            })
            .catch(async function (error) {
              if (typeof error.response === 'undefined') {
                store.dispatch('commonComponents/hideLoadingModalAction')
                store.dispatch('commonComponents/openNotificationModal', {
                  message: "Errore, contattare l'assistenza"
                })
              } else {
                let errorString = error.response.data
                if (
                  error.request.responseType === 'blob' &&
                  error.response.data instanceof Blob &&
                  error.response.data.type &&
                  error.response.data.type.toLowerCase().indexOf('json') != -1
                ) {
                  errorString = JSON.parse(await error.response.data.text())
                }

                store.dispatch('commonComponents/openNotificationModal', {
                  message: errorString.error
                })
                store.dispatch('commonComponents/hideLoadingModalAction')
              }
            })
        } else if (['fattureExcel', 'pagamentoExcel'].includes(this.selected)) {
          axios
            .post(
              '/' + localStorage.getItem('clinic') + '/export/excel',
              params,
              { responseType: 'blob' }
            )
            .then((res) => {
              var binaryData = []
              binaryData.push(res.data)
              let fileUrl = window.URL.createObjectURL(
                new Blob(binaryData, { type: 'application/vnd.ms-excel' })
              )

              let fileLink = document.createElement('a')
              fileLink.href = fileUrl
              fileLink.setAttribute('download', filename + '.xlsx')
              document.body.appendChild(fileLink)
              fileLink.click()
              store.dispatch('commonComponents/hideLoadingModalAction')
            })
            .catch(async function (error) {
              if (typeof error.response === 'undefined') {
                store.dispatch('commonComponents/hideLoadingModalAction')
                store.dispatch('commonComponents/openNotificationModal', {
                  message: "Errore, contattare l'assistenza"
                })
              } else {
                let errorString = error.response.data
                if (
                  error.request.responseType === 'blob' &&
                  error.response.data instanceof Blob &&
                  error.response.data.type &&
                  error.response.data.type.toLowerCase().indexOf('json') != -1
                ) {
                  errorString = JSON.parse(await error.response.data.text())
                }

                store.dispatch('commonComponents/openNotificationModal', {
                  message: errorString.error
                })
                store.dispatch('commonComponents/hideLoadingModalAction')
              }
            })
        } else if (this.selected === 'fattureTesseraSanitaria') {
          axios
            .post(
              '/' +
                localStorage.getItem('clinic') +
                '/export/exportTesseraSanitaria',
              params,
              { responseType: 'blob' }
            )
            .then((res) => {
              var binaryData = []
              binaryData.push(res.data)
              let fileUrl = window.URL.createObjectURL(
                new Blob(binaryData, { type: 'text/csv;charset=utf-8;' })
              )

              let fileLink = document.createElement('a')
              fileLink.href = fileUrl
              fileLink.setAttribute('download', filename + '.csv')
              document.body.appendChild(fileLink)
              fileLink.click()
              store.dispatch('commonComponents/hideLoadingModalAction')
            })
            .catch(async function (error) {
              if (typeof error.response === 'undefined') {
                store.dispatch('commonComponents/hideLoadingModalAction')
                store.dispatch('commonComponents/openNotificationModal', {
                  message: 'Errore, ridurre il range di date'
                })
              } else {
                let errorString = error.response.data
                if (
                  error.request.responseType === 'blob' &&
                  error.response.data instanceof Blob &&
                  error.response.data.type &&
                  error.response.data.type.toLowerCase().indexOf('json') != -1
                ) {
                  errorString = JSON.parse(await error.response.data.text())
                }

                store.dispatch('commonComponents/openNotificationModal', {
                  message: errorString.error
                })
                store.dispatch('commonComponents/hideLoadingModalAction')
              }
            })
        }
      }
    }
  }
})
</script>
