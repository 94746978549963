<template>
  <!-- Main modal -->
  <div
    class="fixed flex justify-center z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <span class="text-white">
      <RotateCwIcon
        class="animate-spin h-full w-10 mr-3 scale-150 fill-white text-white"
        autofocus
      />
      Caricamento in corso ...</span
    >
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  setup() {},
  mounted() {
    document.activeElement.blur()
  }
})
</script>
