import moment from 'moment'

function formatdate(epoch) {
  return moment.unix(epoch).utc().format('DD/MM/YYYY')
}

function formatdatecalendarShort(epoch) {
  return moment.unix(epoch).format('HH:mm')
}

function formatdatecalendarEpoch(epoch) {
  return moment.unix(epoch).format('DD/MM/YYYY')
}

function formatdatelocal(epoch) {
  return moment.unix(epoch).format('DD/MM/YYYY')
}

function formatdatetime_iso(datetime) {
  return moment(datetime).utc().format('DD/MM/YYYY HH:mm')
}

function formatdatetime_iso_hours(epoch) {
  return moment.unix(epoch).format('HH:mm')
}

function formatdateenglish(epoch) {
  return moment.unix(epoch).format('YYYY-MM-DD')
}

function formatdateMonth(epoch) {
  return moment.unix(epoch).utc().format('MM/YYYY')
}

export function formatepoch(date) {
  return moment.utc(date, 'DD/MM/YYYY').unix()
}

function formatepochEnglishDate(date) {
  return moment.utc(date, 'YYYY-MM-DD').unix()
}

function formatepochmin(date) {
  return moment(date, 'DD/MM/YYYY HH:mm').unix()
}

function formatepochMonth(date) {
  return moment(date, 'MM/YYYY').unix()
}

function getDifferenceYears(date) {
  return Math.trunc((Date.now() / 1000 - date) / 31556.926 / 1000.0)
}

function getDifferenceMonths(date) {
  return Math.trunc((Date.now() / 1000 - date) / 2592.0 / 1000.0)
}

function getDifferenceDays(date) {
  return Math.trunc((Date.now() / 1000 - date) / 86.4 / 1000.0)
}

function getDifferenceMinutes(date) {
  return Math.trunc((Date.now() / 1000 - date) / 0.06 / 1000.0)
}
function formatdatemin(epoch) {
  return moment.unix(epoch).format('DD/MM/YYYY HH:mm')
}

const install = (app) => {
  app.config.globalProperties.$FormatDate = formatdate
  app.config.globalProperties.$FormatdateCalendarShort = formatdatecalendarShort
  app.config.globalProperties.$FormatDateCalendarEpoch = formatdatecalendarEpoch
  app.config.globalProperties.$FormatDateLocal = formatdatelocal
  app.config.globalProperties.$FormatDateTimeISO = formatdatetime_iso
  app.config.globalProperties.$FormatDateTimeHoursISO = formatdatetime_iso_hours
  app.config.globalProperties.$FormatDateEnglish = formatdateenglish
  app.config.globalProperties.$FormatDateMonth = formatdateMonth
  app.config.globalProperties.$FormatEpoch = formatepoch
  app.config.globalProperties.$getDifferenceYears = getDifferenceYears
  app.config.globalProperties.$getDifferenceMonths = getDifferenceMonths
  app.config.globalProperties.$getDifferenceDays = getDifferenceDays

  app.config.globalProperties.$getDifferenceMinutes = getDifferenceMinutes
  app.config.globalProperties.$FormatDateMin = formatdatemin
  app.config.globalProperties.$FormatEpochMin = formatepochmin
  app.config.globalProperties.$FormatEpochEnglish = formatepochEnglishDate
  app.config.globalProperties.$FormatEpochMonth = formatepochMonth
}

export { install as default }
