<template>
  <div v-if="true">
    <div class="p-5 text-center">
      <CheckCircleIcon class="w-16 h-16 text-theme-41 mx-auto mt-3" />
      <div class="text-3xl mt-5">Funzione presto disponibile</div>
      <!-- <div class="text-gray-600 mt-2">Modal with close button</div> -->
    </div>
  </div>
  <!-- BEGIN: Responsive Table -->
  <div v-else class="intro-y box mt-5">
    <div
      class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200"
    >
      <h2 class="font-medium text-base mr-auto">Responsive Table</h2>
      <div class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0">
        <label class="form-check-label ml-0 sm:ml-2" for="show-example-6"
          >Show example code</label
        >
        <input
          id="show-example-6"
          data-target="#responsive-table"
          class="show-code form-check-switch mr-0 ml-3"
          type="checkbox"
        />
      </div>
    </div>
    <div id="responsive-table" class="p-5">
      <div class="preview">
        <div class="overflow-x-auto">
          <table class="table">
            <thead>
              <tr>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  #
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  First Name
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  Last Name
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  Username
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  Email
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  Address
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="border-b whitespace-nowrap">1</td>
                <td class="border-b whitespace-nowrap">Angelina</td>
                <td class="border-b whitespace-nowrap">Jolie</td>
                <td class="border-b whitespace-nowrap">@angelinajolie</td>
                <td class="border-b whitespace-nowrap">
                  angelinajolie@gmail.com
                </td>
                <td class="border-b whitespace-nowrap">
                  260 W. Storm Street New York, NY 10025.
                </td>
              </tr>
              <tr>
                <td class="border-b whitespace-nowrap">2</td>
                <td class="border-b whitespace-nowrap">Brad</td>
                <td class="border-b whitespace-nowrap">Pitt</td>
                <td class="border-b whitespace-nowrap">@bradpitt</td>
                <td class="border-b whitespace-nowrap">bradpitt@gmail.com</td>
                <td class="border-b whitespace-nowrap">
                  47 Division St. Buffalo, NY 14241.
                </td>
              </tr>
              <tr>
                <td class="border-b whitespace-nowrap">3</td>
                <td class="border-b whitespace-nowrap">Charlie</td>
                <td class="border-b whitespace-nowrap">Hunnam</td>
                <td class="border-b whitespace-nowrap">@charliehunnam</td>
                <td class="border-b whitespace-nowrap">
                  charliehunnam@gmail.com
                </td>
                <td class="border-b whitespace-nowrap">
                  8023 Amerige Street Harriman, NY 10926.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="source-code hidden">
        <button
          data-target="#copy-responsive-table"
          class="copy-code btn py-1 px-2 btn-outline-secondary"
        >
          <FileIcon class="w-4 h-4 mr-2" /> Copy example code
        </button>
        <div class="overflow-y-auto mt-3 rounded-md">
          <Highlight id="copy-responsive-table" class="source-preview">
            <code
              class="text-xs p-0 rounded-md html pl-5 pt-8 pb-4 -mb-10 -mt-10"
            >
              <textarea>
                      <div class="overflow-x-auto">
                        <table class="table">
                          <thead>
                            <tr>
                              <th
                                class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                              >
                                #
                              </th>
                              <th
                                class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                              >
                                First Name
                              </th>
                              <th
                                class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                              >
                                Last Name
                              </th>
                              <th
                                class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                              >
                                Username
                              </th>
                              <th
                                class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                              >
                                Email
                              </th>
                              <th
                                class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                              >
                                Address
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td class="border-b whitespace-nowrap">1</td>
                              <td class="border-b whitespace-nowrap">Angelina</td>
                              <td class="border-b whitespace-nowrap">Jolie</td>
                              <td class="border-b whitespace-nowrap">@angelinajolie</td>
                              <td class="border-b whitespace-nowrap">
                                angelinajolie@gmail.com
                              </td>
                              <td class="border-b whitespace-nowrap">
                                260 W. Storm Street New York, NY 10025.
                              </td>
                            </tr>
                            <tr>
                              <td class="border-b whitespace-nowrap">2</td>
                              <td class="border-b whitespace-nowrap">Brad</td>
                              <td class="border-b whitespace-nowrap">Pitt</td>
                              <td class="border-b whitespace-nowrap">@bradpitt</td>
                              <td class="border-b whitespace-nowrap">
                                bradpitt@gmail.com
                              </td>
                              <td class="border-b whitespace-nowrap">
                                47 Division St. Buffalo, NY 14241.
                              </td>
                            </tr>
                            <tr>
                              <td class="border-b whitespace-nowrap">3</td>
                              <td class="border-b whitespace-nowrap">Charlie</td>
                              <td class="border-b whitespace-nowrap">Hunnam</td>
                              <td class="border-b whitespace-nowrap">@charliehunnam</td>
                              <td class="border-b whitespace-nowrap">
                                charliehunnam@gmail.com
                              </td>
                              <td class="border-b whitespace-nowrap">
                                8023 Amerige Street Harriman, NY 10926.
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </textarea
              >
            </code>
          </Highlight>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Responsive Table -->
</template>
