<template>
  <div class="c-header relative grid grid-cols-8">
    <div
      :class="fullHeader ? 'col-span-8 rounded-md' : 'col-span-5 rounded-l-md'"
      class="col-span-5 bg-theme-44 grid grid-cols-12 cursor-pointer"
      @click="handleOpen"
    >
      <p class="c-title col-span-11">{{ title }}</p>
      <div class="col-span-1 mt-3">
        <div v-if="open" class="col-span-1 flex items-center justify-center">
          <button class="text-theme-41">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              class="h-4 fill-current"
              :class="openVar ? 'rotate90' : 'rotate180'"
            >
              <path
                d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
              />
            </svg>
          </button>
        </div>
        <div v-else class="col-span-1 flex items-center justify-center"></div>
      </div>
    </div>
    <div :class="fullHeader ? '' : 'col-span-3'" class="grid grid-cols-10">
      <div v-if="stato" class="col-span-8 flex items-center justify-end pr-4">
        <p class="text-sm font-bold" :class="[vmodelVar ? '' : 'disabilitato']">
          {{ stato_text }}
          <span class="italic font-medium">
            {{ vmodelVar ? 'Attivo' : 'Disattivo' }}
          </span>
        </p>
      </div>
      <div v-else class="col-span-5 flex items-center justify-end pr-4"></div>
      <div v-if="swich" class="col-span-1 flex items-center justify-start">
        <input
          :checked="vmodelVar"
          class="form-check-switch bg-gray-200"
          type="checkbox"
          @change="handleSwich"
        />
      </div>
      <div v-else class="col-span-1 flex items-center justify-start"></div>
      <div v-if="setup" class="col-span-1 flex items-center justify-center">
        <button
          class="tooltipbutton orange-btn py-0.5 px-1.5"
          :class="[vmodelVar ? '' : 'disabilitato']"
          @click="toggleSetup"
        >
          <i class="fas fa-cog text-sm"></i>
          <span
            class="tooltipbuttontext"
            style="width: 80px; margin-left: 35px; margin-top: 5px"
            >Modifica</span
          >
        </button>
      </div>
      <div v-else class="col-span-1 flex items-center justify-center"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  props: [
    'title',
    'stato',
    'stato_text',
    'swich',
    'setup',
    'open',
    'vmodelVar',
    'openVar',
    'fullHeader'
  ],

  data() {
    return {
      // // title: '',
      // // stato: false,
      // // swich: false,
      // // setup: false,
      // // open: false
    }
  },
  methods: {
    handleSwich() {
      this.$emit('handleSwich')
    },
    toggleSetup() {
      this.$emit('toggleSetup')
    },
    handleOpen() {
      this.$emit('handleOpen')
    }
  }
})
</script>
