import axios from '../gateway/backend-api'
import store from '../store'

var idTimer = 0
var beatCounter = 0

export function startTimer() {
  globalStateMachine()
  idTimer = setTimeout(startTimer, 500)
}

export function stopTimer() {
  clearTimeout(idTimer)
}

function globalStateMachine() {
  beatCounter++
  if (
    (!(beatCounter % 60) || beatCounter == 1) &&
    localStorage.getItem('clinic')
  ) {
    axios
      .get(
        '/' +
          localStorage.getItem('clinic') +
          '/keepalive?beatCounter=' +
          beatCounter
      )
      .then((res) => {
        localStorage.setItem('userToken', res.data.token)
        localStorage.setItem('forceRefresh', res.data.force_refresh)

        if (res.data.force_refresh == 1) store.dispatch('logout')
        if (res.data.bloka === true) {
          store.dispatch('commonComponents/lockPage')
        } else {
          store.dispatch('commonComponents/unlockPage')
          //location.reload()
        }
      })
  }
  if (!(beatCounter % 120)) {
    let var_time = document.getElementsByClassName('#update_timer')
    for (let i = 0; i < var_time.length; i++) {
      let arrive = var_time[i].getAttribute('data-epoch')
      let difference = Math.trunc((Date.now() / 1000 - arrive) / 60)
      var_time[i].textContent = difference
    }
  }
  if (!(beatCounter % 60)) {
    //Numero Notifiche
    store.dispatch('reusableData/getTotalBell')
  }
}
const install = (app) => {
  app.config.globalProperties.$idGlobalTimer = idTimer
  app.config.globalProperties.$startGlobalTimer = startTimer
  app.config.globalProperties.$stopGlobalTimer = stopTimer
}

export { install as default }
