<template>
  <div class="tab-content tariff_container">
    <!--BEGIN: invoice list-->

    <!-- Show elements and global search-->
    <div class="paginator-container">
      <div class="flex items">
        <div class="mr-5 my-2 flex justify-between">
          <span class="flex-row text-sm ml-2 font-bold mr-2 mt-1">Mostra </span>
          <select
            v-model="pagination.forPage"
            class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
            @change="(event) => changeNumPages(event)"
          >
            <option
              v-for="show in forPageOption"
              :key="show"
              :value="show"
              class="form-select"
            >
              {{ show }}
            </option>
          </select>
          <span class="text-sm font-bold ml-2 mt-1"> elementi</span>
        </div>
      </div>
      <Paginator
        :for-page-option="pagination.forPage"
        :total-records="totalRecords"
        @changePagination="
          (event) => $h.debounce(() => changePagination(event))
        "
      />
    </div>
    <!-- BEGIN: Data List -->

    <div
      id="invoices"
      class="tab-pane active"
      role="tabpanel"
      aria-labelledby="invoices_mobile-tab"
    >
      <div
        class="table-container email-table-container scrollbar-thin scrollbar-thumb-theme-44 scrollbar-track-theme-2 overflow-scroll"
      >
        <table
          class="w-full shadow overflow-y-scroll border-b border-gray-200 sm:rounded-lg"
        >
          <thead class="h-10 t-header">
            <tr>
              <th
                v-for="(item, itemKey) in table_config"
                :key="itemKey"
                :name="item.title"
                class="px-6 py-3 text-white border-b-2 border-theme-41 whitespace-nowrap text-center font-semibold bg-theme-40 uppercase"
              >
                {{
                  item.key === 'price'
                    ? iva === false
                      ? item.title + ' senza IVA'
                      : item.title + ' con IVA'
                    : item.title
                }}
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            <tr v-if="false" class="">
              <td
                v-for="(elem, index) in table_config"
                :key="index"
                class="text-center pr-3 pb-3"
              >
                <div
                  v-if="elem.title === 'Data invio'"
                  class="flex-col text-left"
                >
                  <div class="flex items-baseline">
                    <span class="mr-2 font-bold">Da: </span
                    ><input
                      v-model="startDate"
                      class="custom-input"
                      type="date"
                      lang="it-IT"
                      @change="filterHandler(elem)"
                    />
                  </div>
                  <div class="flex items-baseline">
                    <span class="mr-4 font-bold">A: </span
                    ><input
                      v-model="endDate"
                      class="custom-input"
                      type="date"
                      lang="it-IT"
                      @change="filterHandler(elem)"
                    />
                  </div>
                </div>
                <div
                  v-else-if="elem.title === 'Data Documento'"
                  class="flex-col text-left"
                >
                  <div class="flex items-baseline">
                    <span class="mr-2 font-bold">Da: </span
                    ><input
                      v-model="startDateDocument"
                      class="custom-input"
                      type="date"
                      @change="filterHandler(elem)"
                    />
                  </div>
                  <div class="flex items-baseline">
                    <span class="mr-4 font-bold">A: </span
                    ><input
                      v-model="endDateDocument"
                      class="custom-input"
                      type="date"
                      @change="filterHandler(elem)"
                    />
                  </div>
                </div>
                <select
                  v-else-if="elem.type === 'select'"
                  v-model="elem.value"
                  class="w-full border rounded-md h-7 text-gray-500 mt-2"
                  @change="filterHandler(elem)"
                >
                  <option selected value=""></option>
                  <option
                    v-for="(item, i) in elem.option"
                    :key="i"
                    :value="item.id ? item.id : item"
                  >
                    {{ item.name ? item.name : item }}
                  </option>
                </select>

                <div
                  v-else-if="elem.key === 'refresh'"
                  class="flex-col text-center cursor-pointer"
                  @click="refreshStates()"
                >
                  <div class="flex items-center" style="margin-top: 8%">
                    <span class="mr-2 font-bold">
                      <i class="fas fa-sync"></i>
                    </span>
                    <span>Aggiorna Stati</span>
                  </div>
                </div>

                <input
                  v-else
                  v-model="elem.value"
                  :type="elem.type"
                  :style="elem.style"
                  min="0"
                  :name="elem.title"
                  class="form-control h-7 mt-2 text-left border-theme-45 px-0"
                  :class="{
                    invisible: elem.type === 'none',
                    'p-0': elem.type === 'date'
                  }"
                  :placeholder="
                    elem.key === 'price'
                      ? iva === false
                        ? elem.title + ' senza IVA'
                        : elem.title + ' con IVA'
                      : elem.title
                  "
                  @change="filterHandler(elem)"
                />
              </td>
            </tr>
            <tr
              v-for="(n, index) in emails"
              :key="n.id"
              class="h-12 text-xs font-normal hover:bg-theme-44 cursor-pointer"
              :class="{ 'bg-theme-2': index % 2 === 0 }"
            >
              <td class="text-center border-r border-theme-45 px-3">
                <div
                  class="flex flex-col items-center text-center justify-evenly"
                >
                  <p>{{ $FormatDateTimeISO(n.created_at) }}</p>
                </div>
              </td>
              <td class="text-center border-r border-theme-45 px-3">
                <div
                  class="flex flex-col items-center text-center justify-evenly"
                >
                  <p>{{ JSON.parse(n.payload).email }}</p>
                </div>
              </td>
              <td class="text-center border-r border-theme-45 px-3">
                <div
                  class="flex flex-col items-center text-center justify-evenly"
                >
                  <p>{{ tipoMessaggio(JSON.parse(n.payload).email_type) }}</p>
                </div>
              </td>
              <td class="text-center border-r border-theme-45 px-3">
                <div
                  class="flex flex-col items-center text-center justify-evenly"
                >
                  <p v-html="JSON.parse(n.payload).body"></p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- END: Data List -->
  </div>
</template>
<script>
const forPageOption = [5, 10, 20, 50]

import Paginator from '../paginator/Main.vue'
import { mapGetters, mapActions } from 'vuex'
// import THeader from '../cvit-table/THeader.vue'
import axios from '../../gateway/backend-api'

export default {
  components: {
    Paginator
    // THeader,
  },
  props: {
    type: [],
    customer: Object,
    active: {
      type: [Boolean],
      default: true
    }
  },
  emits: [
    'showInvoice',
    'handleSavePdfRev',
    'handleNotaDiCredito',
    'editInvoice',
    'openExportInvoice',
    'refreshTables'
  ],
  data() {
    return {
      showExportInvoiceModal: false,
      notifications: null,
      showStatusModal: false,
      showModal: false,

      totalRecords: '',
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },

      filterActive: false,
      ricerca: '',
      startDate: null,
      endDate: null,
      startDateDocument: null,
      endDateDocument: null,
      active_filters: {},

      invoices: [],
      table_config: [
        {
          key: 'sendingDate',
          title: 'Data invio',
          type: 'none',
          style: 'display: none'
        },

        {
          key: 'destinatario',
          title: 'Destinatario',
          type: 'text'
        },
        {
          key: 'type',
          title: 'Tipo messaggio',
          type: 'select',
          option: []
        },
        {
          key: 'body',
          title: 'Corpo messaggio',
          type: 'none'
        }
      ],
      emails: null
    }
  },

  computed: {
    ...mapGetters({
      getCustomersDetails: 'anagraficaClienti/getCustomersDetails',
      getComparisonCustomer: 'anagraficaClienti/getComparisonCustomer',
      invoiceTypesClinic: 'reusableData/getInvoiceTypesClinic',
      invoiceTypes: 'reusableData/getInvoiceTypes',
      invoiceStatus: 'reusableData/getInvoiceStatus',
      iva: 'reusableData/getShowIva',
      permissions: 'reusableData/getPermissions',
      ivaPerc: 'reusableData/getRegimeFiscaleIvaPerc'
    })
  },
  mounted() {
    this.getEmails()
  },
  methods: {
    ...mapActions({
      getCustomerAction: 'anagraficaClienti/getCustomerAction',
      getSingleCustomerAction: 'anagraficaClienti/getSingleCustomerAction'
    }),

    getEmails() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/mailersend/sended', {
          active_filters: this.active_filters,
          page: this.pagination.page,
          per_page: this.pagination.forPage
        })
        .then((res) => {
          this.emails = res.data.data
          this.totalRecords = res.data.total
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },

    getBills(refreshStates = false) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/bills/index-bills', {
          customer_id: this.customer ? this.customer.id : null,
          active_filters: this.active_filters,
          sent_from: this.startDate,
          sent_to: this.endDate,
          document_date_from: this.startDateDocument,
          document_date_to: this.endDateDocument,
          iva: this.iva,
          page: this.pagination.page,
          per_page: this.pagination.forPage,
          refreshStates: refreshStates
        })
        .then((res) => {
          this.invoices = res.data.data
          this.totalRecords = res.data.total

          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    changePagination: function (value) {
      if (this.pagination.page != value.page) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        this.getEmails()
      }
    },
    changeNumPages: function (elem) {
      this.pagination.forPage = elem.target.value
      this.getEmails()
    },

    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()

      if (this.filterActive) this.getEmails()
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    },

    refreshStates() {
      this.getEmails(true)
    },

    tipoMessaggio(val) {
      switch (val) {
        case 'simple_message':
          return 'Comunicazione'
        case 'vaccine':
          return 'Reminder Vaccino'
        case 'comunication':
          return 'Comunicazione con allegato'
        default:
          return ''
      }
    }
  }
}
</script>
