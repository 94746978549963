<template>
  <div ref="calendarRef" class="full-calendar"></div>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import { Calendar } from '@fullcalendar/core'

export default defineComponent({
  name: 'FullCalendar',
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },
  setup(props) {
    const calendarRef = ref(null)

    onMounted(() => {
      const Tcalendar = new Calendar(calendarRef.value, props.options)
      Tcalendar.render()
    })

    return {
      calendarRef
    }
  }
})
</script>
