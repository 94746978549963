<template>
  <div class="fixed z-50 inset-0 bg-black bg-opacity-50 scrollbar-none">
    <div
      class="relative top-12 p-5 mx-auto border w-3/5 md:w-2/5 shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->

      <div class="">
        <button type="button" class="closing-btn" @click="$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>

        <!-- Modal header -->
        <div class="mt-7">
          <h3 class="modal-title">
            Aggiungi una nuova razza di {{ typeId.name }}
          </h3>
        </div>
        <div class="mt-3 pt-4">
          <v-select
            v-model="filter_breed"
            :options="petBreedsGlobal"
            label="name"
            :reduce="(value) => value.id"
            placeholder="Seleziona razze"
            class="block px-2 py-1 my-3 rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500 flex-0"
          >
            <template #option="option">
              {{ option.name }}
            </template></v-select
          >
          <div class="flex items-center justify-end gap-1 pl-4 mt-5">
            <button class="orange-btn" @click="newBreed()">Conferma</button>
            <button @click="$emit('close')" class="gray-btn">Annulla</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import axios from '../../../gateway/backend-api'

export default defineComponent({
  props: {
    typeId: Object
  },
  emits: ['close', 'refresh'],
  data() {
    return {
      petBreedsGlobal: []
    }
  },
  mounted() {
    this.petBreedsGlobal = this.typeId.breeds
  },
  methods: {
    newBreed() {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'Aggiungi specie',
        subtitle: 'Sei sicuro di aggiungere le specie selezionate?',
        background: false,
        confirm: () => {
          axios
            .post('/' + localStorage.getItem('clinic') + '/pet/new-types', {
              pet_type_id: this.typeId.id,
              pet_breed_id: this.filter_breed
            })
            .then(() => {
              this.$emit('refresh')
            })
          this.$emit('close')
        },
        decline: () => {
          return false
        }
      })
    }
  }
})
</script>
