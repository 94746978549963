<template>
  <NewVaccineModal
    v-if="vaccineModal"
    :elem="selectedElem"
    :to-modify="vaccineToModify"
    @close="closeVaccineModal"
    @confirm="addVaccinazione"
  />

  <div
    class="intro-y box px-5 py-3 m-1 content-container overflow-auto scrollbar_custom"
  >
    <div class="bg-white">
      <div class="flex justify-end my-5">
        <button
          type="file"
          class="orange-btn px-3 py-1 flex mr-2 items-center"
          @click="handleVaccineModal"
        >
          <i class="fas fa-plus fill-current mr-2"></i> Aggiungi Vaccinazione
        </button>
      </div>
      <div class="mb-5">
        <VaccinazioniTable
          v-if="docs && tableConfig_vaccine_doc"
          :table-config-clinic-doc="tableConfig_vaccine_doc"
          :documents="docs"
          :total-records="totalRecords"
          :pagination="pagination"
          :for-page-option="forPageOption"
          @handleSendMessage="handleSendMessage"
          @deleteRecord="(doc, el) => handleDeleteRecord(doc, el)"
          @modifyVaccine="handleModifyVaccine"
          @removeVaccinazione="handleRemoveVaccinazione"
          @getList="(el) => getList(el)"
        />
      </div>
    </div>
    <!--END: Da Effettuare-->

    <div class="flex w-full content-center items-center justify-center">
      <RotateCwIcon
        v-if="!tableConfig_vaccine_doc || !docs"
        class="animate-spin h-full w-5 mr-3 scale-150"
      />
    </div>

    <SendMessageModal
      v-if="messageModal && selectedElem"
      :elem="selectedElem"
      @close="closeSendMessageModal"
      @confirm="confirmMessage"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from '../../gateway/backend-api'
import SendMessageModal from '../../components/modals/SendMessageModal.vue'
import NewVaccineModal from '../../components/modals/NewVaccineModal.vue'

import VaccinazioniTable from './VaccinazioniTable.vue'

const forPageOption = [10, 20, 50]
export default defineComponent({
  components: {
    SendMessageModal,
    NewVaccineModal,
    VaccinazioniTable
  },
  data() {
    return {
      tableConfig_vaccine_doc: null,
      docs: null,
      messageModal: false,
      vaccineModal: false,
      selectedElem: null,
      vaccineToModify: null,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      totalRecords: 0,
      forPageOption: forPageOption
    }
  },
  mounted() {
    axios
      .get('/vaccinazioni_table_config') // scarico la configurazione della tabella
      .then((resp) => {
        const temp = []
        for (var elem of resp.data) {
          temp.push({ ...elem, value: '' }) // ai dati ricevuti aggiungo un campo per il valore del filtro che sarà inserito
        }
        this.tableConfig_vaccine_doc = temp
      })
    this.getList()
  },
  methods: {
    getList(value = {}) {
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/vaccination/indexPetVaccination',
          {
            // pet_id: this.pet.id,
            active_filters: value,
            per_page: this.pagination.forPage,
            page: this.pagination.page
          }
        )
        .then((resp) => {
          this.docs = resp.data.data
          this.totalRecords = resp.data.total
        })
    },
    handleModifyVaccine(data) {
      this.vaccineToModify = data
      this.vaccineModal = true
    },
    handleSendMessage(elem) {
      this.selectedElem = elem
      this.messageModal = true
    },
    closeSendMessageModal() {
      this.selectedElem = null
      this.messageModal = false
    },
    handleVaccineModal() {
      this.vaccineModal = true
    },
    closeVaccineModal() {
      this.vaccineModal = false
      this.vaccineToModify = null
    },
    confirmMessage() {
      this.messageModal = false
    },
    addVaccinazione(
      id_client,
      id_vaccine,
      execution_date,
      richiamo,
      id_medico,
      completed,
      id_pet
    ) {
      if (!this.vaccineToModify) {
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/vaccination/storePetVaccination',
            {
              pet_id: id_pet,
              customer_id: id_client,
              vaccine_id: id_vaccine,
              execution_date: execution_date,
              richiamo: richiamo,
              staff_user_id: id_medico,
              completed: completed
            }
          )
          .then(() => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Vaccino salvato con successo!'
            })
            this.vaccineModal = false
            this.vaccineToModify = null
            this.getList()
          })
      } else {
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/vaccination/' +
              this.vaccineToModify.id +
              '/modifyPetVaccination',
            {
              pet_id: id_pet,
              customer_id: id_client,
              vaccine_id: id_vaccine,
              execution_date: execution_date,
              richiamo: richiamo,
              staff_user_id: id_medico,
              completed: completed
            }
          )
          .then(() => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Vaccino modificato con successo!'
            })
            this.vaccineModal = false
            this.vaccineToModify = null
            this.getList()
          })
          .catch(() => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Errore durante la modifica del vaccino!'
            })
          })
      }
    },
    handleDeleteRecord(doc, filter) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler cancellare la vaccinazione?',
        background: false,
        confirm_text: 'Conferma',
        decline_text: 'Annulla',
        confirm: () => {
          this.$store.dispatch('commonComponents/showLoadingModalAction')
          axios
            .delete(
              '/' + localStorage.getItem('clinic') + '/vaccination/' + doc.id
            )
            .then((res) => {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: res.data.msg
              })
              this.getList(filter)
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
            .catch(() => {
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
        },
        decline: () => {}
      })
    }
  }
})
</script>
