<template>
  <!-- Main modal -->
  <div
    id="defaultModal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative drug-cont top-12 mt-1 mx-auto px-3 pt-3 pb-7 border w-3/4 md:w-4/6 shadow-lg rounded-md bg-white overflow-y-scroll scrollbar-none"
    >
      <!-- Modal content -->

      <div class="mb-9">
        <button type="button" class="closing-btn" @click="$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
      </div>

      <!-- Modal header -->
      <div class="m-2">
        <h3 class="modal-title">Nuovo Articolo</h3>
      </div>

      <div>
        <div class="flex flex-col mt-3 ml-5">
          <label class="font-semibold text-theme-43">
            Inserisci un link del sito
            <a
              class="hover:underline text-theme-41"
              target="_blank"
              href="https://www.anmvioggi.it/"
              >{{ base_url }}</a
            >
          </label>
          <input
            v-model="url"
            type="url"
            :placeholder="base_url + '...'"
            class="pl-3 pr-10 py-1 mt-3 border-2 border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500 required"
          />
          <p v-if="errs.url">{{ errs.url }}</p>
        </div>

        <!--
        <div class="flex flex-col mt-10 ml-5">
          <label class="font-semibold text-theme-43">
            Oppure inserisci un articolo personalizzato</label
          >
          <input
            v-model="custom_article.header"
            type="text"
            placeholder="Inserisci l'occhiello"
            class="pl-3 pr-10 py-1 mt-3 border-2 border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500"
          />

          <input
            v-model="custom_article.title"
            type="text"
            placeholder="Inserisci il titolo"
            class="pl-3 pr-10 py-1 mt-3 border-2 border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500 required"
          />

          <textarea
            v-model="custom_article.short_description"
            placeholder="Inserisci una breve descrizione"
            class="pl-3 pr-10 py-1 mt-3 border-2 border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500 required"
          />

          <input
            v-model="custom_article.img_src"
            type="url"
            placeholder="Inserisci l'url di un immagine"
            class="pl-3 pr-10 py-1 mt-3 border-2 border-gray-200 rounded-md focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500 required"
          />
        </div>
      --></div>

      <!-- Modal footer -->

      <div class="px-6 flex items-center justify-end mt-3">
        <button
          ref="input4"
          type="button"
          class="orange-btn mr-3"
          :class="disable ? 'disabilitato' : ''"
          @click="add"
        >
          <PlusCircleIcon class="h-5 w-5 mr-2" /> Aggiungi
        </button>
        <button type="button" class="gray-btn" @click="$emit('close')">
          <span class="h-5"></span>
          Annulla
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed } from 'vue'
import axios from '../../gateway/backend-api'
import { useStore } from '@/store'

export default defineComponent({
  emits: ['close', 'update'],
  setup(_, { emit }) {
    const store = useStore()
    const base_url = ref('https://www.anmvioggi.it')
    const url = ref(null)
    const custom_article = ref({
      header: null,
      title: null,
      short_description: null,
      img_src: null
    })
    const errs = ref({
      url: null,
      title: null,
      short_description: null,
      img_src: null
    })

    const add = () => {
      if (url.value) {
        if (!url.value.includes(base_url.value))
          errs.value.url = 'Url non corretto'
        else if (!url.value.length <= base_url.value.length)
          errs.value.url = 'Url incompleto'

        errs.value.ur = null
        store.dispatch('commonComponents/showLoadingModalAction')
        axios
          .post('/' + localStorage.getItem('clinic') + '/carousel/store', {
            url: url.value
          })
          .then((res) => {
            if (res) {
              store.dispatch('commonComponents/hideLoadingModalAction')
              store.dispatch('commonComponents/openNotificationModal', {
                message: 'Articolo inserito con successo'
              })
              emit('update')
              emit('close')
            }
          })
          .catch((error) => {
            store.dispatch('commonComponents/openMessageModal', {
              title: 'Errore',
              subtitle: "Contattare l'assistenza"
            })
            console.log(error)
          })
      }
    }

    const disable = computed(() => {
      if (url.value) return false
      else if (
        custom_article.value.title &&
        custom_article.value.short_description &&
        custom_article.value.img_src
      )
        return false
      else return true
    })

    return {
      add,
      url,
      custom_article,
      errs,
      disable,
      base_url
    }
  }
})
</script>
