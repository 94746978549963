<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <div
      class="relative top-80 p-2 mx-auto overflow-scroll scrollbar-none border sm:w-3/5 w-full shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="flex flex-col content-end overflow-y-auto">
        <!-- Modal header -->
        <div class="flex items-start p-5 rounded-t">
          <h3
            class="text-xl font-semibold text-gray-900 lg:text-2xl dark:text-white"
          >
            {{ title }}
          </h3>
          <button
            type="button"
            class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
            @click="$emit('close')"
          >
            <svg
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
        </div>
        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div class="p-6 space-y-6">
          <div class="flex flex-col content-start mb-1">
            <button
              type="button"
              class="w-20 mb-2 inline-flex justify-center rounded-md border bg-theme-8 border-transparent shadow-sm px-3 py-2 text-base font-medium text-theme-48 hover:bg-theme-30 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              @click="$emit('close', true)"
            >
              SI
            </button>
            <button
              type="button"
              class="w-20 mb-2 inline-flex justify-center rounded-md border bg-theme-8 border-transparent shadow-sm px-3 py-2 text-base font-medium text-theme-48 hover:bg-theme-30 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              @click="$emit('close', false)"
            >
              NO
            </button>
          </div>
        </div>
        <!-- Modal footer -->
        <div
          class="border-t-1 border-gray-400 w-full px-6 text-right flex justify-end items-end mb-2"
        ></div>
        <!-- Fine Modal footer -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

import '@swoga/vue-collapsible-panel/dist/vue-collapsible-panel.css'

export default defineComponent({
  props: ['title'],
  emits: ['close'],
  setup() {
    // ----- DATA -----

    // ----- METHODS -----
    const handleResponse = (value) => {
      console.log(value)
    }

    // ----- COMPUTED -----

    return { handleResponse }
  }
})
</script>
