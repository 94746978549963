<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black scrollbar-none"
    :class="
      background && background === false ? 'bg-opacity-0' : 'bg-opacity-50'
    "
  >
    <div
      class="w-3/5 sm:w-3/5 h-4/5 relative top-12 p-5 mx-auto overflow-scroll scrollbar-none border shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="flex flex-col h-full content-end">
        <button type="button" class="closing-btn" @click="this.$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <!-- Modal header -->

        <div class="w-full">
          <h3 class="modal-title mt-8">Template</h3>
        </div>

        <!-- Fine Modal header -->

        <!-- Modal body -->
        <div class="container-template">
          <div
            v-if="
              options !== null &&
              options !== undefined &&
              options.length > 0 &&
              !modifyTemplate
            "
            class="px-6 my-6 flex flex-col justify-start"
          >
            <div
              v-for="(option, index) in options"
              :key="index"
              class="flex border border-gray-200 mb-1"
            >
              <div
                class="flex flex-col text-l text-center p-2 w-full"
                @click="selectTemplate(option)"
              >
                <span v-if="option.title" class="text-left">{{
                  option.title
                }}</span>
                <span class="font-bold">{{ option.text }}</span>
              </div>
              <div class="flex justify-end">
                <button
                  class="text-xs text-gray-700 mr-4"
                  @click="openModifyTemplate(option)"
                >
                  Modifica
                </button>
                <button
                  class="text-xl text-bold text-red-700 mr-4"
                  @click="deleteTemplate(option)"
                >
                  x
                </button>
              </div>
            </div>
          </div>
          <div class="w-full flex flex-col px-4 items-center justify-center">
            <button
              v-if="!addingNewTemplate && !modifyTemplate"
              type="button"
              class="border-1 flex border-black p-2"
              @click="() => (addingNewTemplate = true)"
            >
              <p class="font-bold">+</p>
              <p class="underline">Aggiungi nuovo template</p>
            </button>
            <div v-else class="flex w-full flex-col justify-end mt-4">
              <p>Titolo:</p>
              <textarea
                v-model="newTitle"
                class="shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                rows="1"
              />
              <p>Template:</p>
              <textarea
                v-model="newTemplate"
                class="shadow-sm border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
                rows="2"
                required
              />
              <button
                type="button"
                class="border-1 border-black p-2"
                @click="interruptAction"
              >
                annulla
              </button>
              <button
                type="button"
                class="border border-black mx-72 border-solid p-2 w-50"
                @click="
                  addingNewTemplate === true ? addTemplate() : editTemplate()
                "
              >
                salva
              </button>
            </div>
          </div>
        </div>
        <!-- Modal footer -->

        <!-- Fine Modal footer -->
        <div class="flex flex-col w-full">
          <div
            class="text-black flex flex-col w-full mt-8 border rounded-md border-blue-400"
          >
            <div class="flex items-center justify-center w-full bg-blue-300">
              <p>Anteprima del campo</p>
            </div>

            <textarea
              v-model="newText"
              class="shadow-sm border border-gray-300 rounded-md w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-2 focus:ring-theme-45 focus:outline-none placeholder-gray-400;"
              rows="4"
              required
            />
          </div>
          <div
            class="flex content-start mb-1 w-full items-center justify-around my-4"
          >
            <button
              type="button"
              class="w-20 mb-2 inline-flex justify-center rounded-md border bg-theme-8 border-transparent shadow-sm px-3 py-2 text-base font-medium text-theme-48 hover:bg-theme-30 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              @click="$emit('close')"
            >
              annulla
            </button>

            <button
              type="button"
              class="w-20 mb-2 inline-flex justify-center rounded-md border bg-theme-8 border-transparent shadow-sm px-3 py-2 text-base font-medium text-theme-48 hover:bg-theme-30 focus:outline-none focus:ring-2 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              @click="$emit('confirm', newText)"
            >
              Aggiungi
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import axios from '../../gateway/backend-api'

export default defineComponent({
  props: ['data'],
  emits: ['close', 'confirm'],
  components: {},
  setup(props) {
    const newText = ref('')
    const addingNewTemplate = ref(false)
    const modifyTemplate = ref(false)
    const newTemplate = ref(null)
    const newTitle = ref(null)
    const options = ref([])
    const templateModifying = ref(null)
    const stripTags = (str) => {
      let doc = new DOMParser().parseFromString(str, 'text/html')
      return doc.body.textContent || ''
    }

    if (props.data?.old_text) newText.value = stripTags(props.data?.old_text)
    // ----- DATA -----

    // ----- METHODS -----

    const selectTemplate = (option) => {
      newText.value += option.text + '  '
    }

    const deleteTemplate = (option) => {
      axios
        .delete(
          '/' +
            localStorage.getItem('clinic') +
            '/medicalinputtemplate/' +
            option.id +
            '/delete'
        ) // scarico la configurazione della tabella
        .then(() => {
          getTemplatesOptions()
        })
    }

    const openModifyTemplate = (option) => {
      modifyTemplate.value = true
      newTemplate.value = option.text
      newTitle.value = option.title
      templateModifying.value = option.id
    }

    const getTemplatesOptions = () => {
      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/medicalinputtemplate/' +
            props.data.medicaltemplate_id +
            '/' +
            props.data.external_id
        ) // scarico la configurazione della tabella
        .then((res) => {
          if (res.data && res.data.options) options.value = res.data.options
        })
    }

    if (props.data.external_id) {
      getTemplatesOptions()
    }

    const addTemplate = () => {
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/medicalinputtemplate/store',
          {
            text: newTemplate.value,
            title: newTitle.value,
            id_template: props.data.external_id,
            medicaltemplate_id: props.data.medicaltemplate_id
          }
        ) // scarico la configurazione della tabella
        .then(() => {
          getTemplatesOptions()
          interruptAction()
        })
        .catch(() => {})
    }

    const editTemplate = () => {
      axios
        .post(
          '/' + localStorage.getItem('clinic') + '/medicalinputtemplate/update',
          {
            text: newTemplate.value,
            title: newTitle.value,
            id_template: templateModifying.value
          }
        ) // scarico la configurazione della tabella
        .then(() => {
          getTemplatesOptions()
          interruptAction()
        })
    }

    const interruptAction = () => {
      addingNewTemplate.value = false
      modifyTemplate.value = false
      newTemplate.value = ''
      newTitle.value = ''
      templateModifying.value = null
    }

    // ----- COMPUTED -----

    return {
      newText,
      addingNewTemplate,
      modifyTemplate,
      newTemplate,
      newTitle,
      options,
      templateModifying,
      stripTags,
      selectTemplate,
      addTemplate,
      interruptAction,
      getTemplatesOptions,
      deleteTemplate,
      openModifyTemplate,
      editTemplate
    }
  }
})
</script>
