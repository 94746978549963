<template>
  <!-- Main modal -->
  <div class="scheda_rightside">
    <div
      class=""
      style=""
      :class="
        New === true || modifySchedaClinica === true
          ? 'details-scheda-container'
          : 'details-scheda-container'
      "
    >
      <!-- Modal content -->
      <div
        class="flex flex-col content-end overflow-y-auto scrollbar-none py-1"
        :class="
          New === true || modifySchedaClinica === true
            ? 'details-scheda-container'
            : 'details-scheda-container'
        "
      >
        <!-- Modal header -->

        <div class="flex justify-start items-center gap-1 p-2">
          <span class="text-base font-semibold text-gray-900 dark:text-white">
            Caso clinico:
          </span>
          <v-select
            v-model="caso_clinico"
            class="w-2/5 bg-orange-200"
            :options="casi_options"
            placeholder="Inserisci caso clinico"
            label="name"
          />
        </div>
        <div class="flex justify-end items-center gap-1 p-2">
          <span class="text-base font-semibold text-gray-900 dark:text-white">
            Key:
          </span>
          <v-select
            v-model="argumentRef"
            class="w-1/5"
            :options="keys_default"
            placeholder="Aggiungi key"
            label="key"
            multiple
            taggable
          />
        </div>
        <div class="flex items-center justify-center bg-theme-2 mb-2">
          <h3 class="text-2xl font-bold pt-1 pb-3 nunito">
            {{ title }}
          </h3>
        </div>
        <!-- Fine Modal header -->
        <!-- Inizio Bottoni header (funzionalità da creare) -->
        <div
          v-if="headerButtons"
          class="mt-1 flex justify-between items-start pb-1 px-3 rounded-t border-b dark:border-gray-600"
        >
          <button
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
          >
            <SaveIcon class="h-5 w-5 mr-2" /> Chiusura
          </button>
          <button
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
          >
            <RotateCwIcon class="h-5 w-5 mr-2" /> Aperto
          </button>
          <button
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
          >
            <PlusIcon class="h-5 w-5 mr-2 fill-black" /> Metti in conto
          </button>
          <button
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
          >
            <PlusIcon class="h-5 w-5 mr-2 fill-black" /> Lettura semplificata
          </button>
          <button
            type="button"
            class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-3 py-2 btn-primary text-base font-medium text-white hover:bg-theme-31 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-900 sm:ml-3 sm:w-auto sm:text-sm"
          >
            <PlusIcon class="h-5 w-5 mr-2 fill-black" /> Controllo
          </button>
        </div>
        <!-- Inizio Bottoni header -->
        <div
          class="px-6 flex gap-2"
          :class="[configuration.id != 1 ? 'justify-between' : 'justify-end']"
        >
          <button
            v-if="configuration.id != 1"
            class="fill-current orange-btn-inverted flex items-center gap-1"
            @click="richiamaUltimaVisitaGenerale"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 384 512"
              class="h-4"
            >
              <!--! Font Awesome Pro 6.1.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
              <path
                d="M256 0v128h128L256 0zM224 128L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V160h-127.1C238.3 160 224 145.7 224 128zM288.1 344.1C284.3 349.7 278.2 352 272 352s-12.28-2.344-16.97-7.031L216 305.9V408c0 13.25-10.75 24-24 24s-24-10.75-24-24V305.9l-39.03 39.03c-9.375 9.375-24.56 9.375-33.94 0s-9.375-24.56 0-33.94l80-80c9.375-9.375 24.56-9.375 33.94 0l80 80C298.3 320.4 298.3 335.6 288.1 344.1z"
              />
            </svg>
            <span> Richiama dall'ultima visita generale </span>
          </button>
          <div class="flex gap-2">
            <button
              type="button"
              :class="[
                expanded
                  ? 'gray-btn-inverted hover:bg-white hover:text-theme-50 '
                  : 'gray-btn hover:bg-theme-50 hover:text-white hover:border-theme-50'
              ]"
              @click="expandSections"
            >
              <span class="h-5"></span>
              Espandi Tutto
            </button>
            <button
              type="button"
              :class="[
                !expanded
                  ? 'gray-btn-inverted hover:bg-white hover:text-theme-50 '
                  : 'gray-btn hover:bg-theme-50 hover:text-white hover:border-theme-50'
              ]"
              @click="collapseSections"
            >
              <span class="h-5"></span>
              Riduci tutto
            </button>
          </div>
        </div>
        <!-- Modal body -->
        <div class="pl-3 py-2">
          <div class="flex flex-col content-start">
            <div class="items-center">
              <v-select
                v-if="arguments"
                v-model="argumentRef"
                class="col-span-4 w-60 mb-5"
                :options="arguments"
                placeholder="seleziona l'argomento"
                label="name"
              />
              <!-- Creo un componente DetailModalRow per ogni parte del modale -->
              <!-- <div
                v-for="elemRow in configurationNoSections"
                :key="elemRow.title"
                class="flex flex-col"
              >
                <DetailModalRow
                  :ref="elemRow.description"
                  :title="elemRow.description"
                  :value="elemRow.value || elemRow.didascalia"
                  :type="elemRow.type"
                  :buttons="elemRow.buttons ?? null"
                  :options="elemRow.options ?? null"
                  :modify="modify"
                  :imageBg="elemRow.imageBg"
                  :anteprima="elemRow.anteprima"
                  :bio="elemRow.bio"
                  @updateData="
                    (title, value, type) => updateData(title, value, type)
                  "
                  @openSpecialisticModal="openSpecialisticModal"
                />
              </div> -->

              <!-- Creo un componente DetailModalRow per ogni parte del modale -->
              <div v-for="(elemRow, indexx) in sections" :key="elemRow.title">
                <vue-collapsible-panel-group
                  v-if="elemRow.showSection || New || modifySchedaClinica"
                  accordion
                >
                  <vue-collapsible-panel :id="indexx" :expanded="false">
                    <template #title>
                      <p class="w-full text-center nunito font-bold text-sm">
                        {{ elemRow.title }}
                      </p>
                    </template>
                    <template #content>
                      <div
                        v-for="(elem, index) in elemRow.value"
                        :key="elem.title"
                      >
                        <DetailModalRow
                          v-if="elem.showRow || New || modifySchedaClinica"
                          :id="elem.id"
                          :ref="elem.name ? elem.name : elem.description"
                          :elem="elem"
                          :title="elem.label ? elem.label : null"
                          :id-clinic-row-temp="elem.idClinicRowTemp"
                          :value="elem.value || elem.didascalia"
                          :type="elem.type"
                          :buttons="elem.buttons ?? null"
                          :options="elem.options ?? null"
                          :image-bg="elem.imageBg"
                          :modify="modify"
                          :anteprima="elem.anteprima"
                          :bio="elem.bio"
                          :gray="index % 2 === 1"
                          :configuration="configuration"
                          :pet_id="pet_id"
                          :pet-type="petType"
                          :refresh-trigger-richiamo="inu"
                          :richiamo-date="
                            valoriRichiamo ? valoriRichiamo.last_update : null
                          "
                          @updateData="
                            (title, value, conditionalValue, type, id) =>
                              updateData(
                                title,
                                value,
                                conditionalValue,
                                type,
                                id,
                                index,
                                elemRow.title
                              )
                          "
                          @openSpecialisticModal="openSpecialisticModal"
                          @openAgendaModal="$emit('openAgenda')"
                          @openTariffarioModal="$emit('openTariffario')"
                        /></div
                    ></template>
                  </vue-collapsible-panel>
                </vue-collapsible-panel-group>
              </div>
              <div v-for="(elemRow, indexx) in followUp" :key="elemRow.title">
                <vue-collapsible-panel-group
                  v-if="elemRow.showSection || New || modifySchedaClinica"
                  accordion
                >
                  <vue-collapsible-panel :id="indexx" :expanded="false">
                    <template #title>
                      <p class="w-full text-center nunito font-bold text-sm">
                        {{ elemRow.title }}
                      </p>
                    </template>
                    <template #content>
                      <div
                        v-for="(elem, index) in elemRow.value"
                        :key="elem.title"
                      >
                        <DetailModalRow
                          v-if="elem.showRow || New || modifySchedaClinica"
                          :id="elem.id"
                          :ref="elem.name ? elem.name : elem.description"
                          :elem="elem"
                          :title="elem.label ? elem.label : null"
                          :id-clinic-row-temp="elem.idClinicRowTemp"
                          :value="elem.value || elem.didascalia"
                          :type="elem.type"
                          :buttons="elem.buttons ?? null"
                          :options="elem.options ?? null"
                          :image-bg="elem.imageBg"
                          :modify="modify"
                          :anteprima="elem.anteprima"
                          :bio="elem.bio"
                          :gray="index % 2 === 1"
                          :configuration="configuration"
                          :pet_id="pet_id"
                          :pet-type="petType"
                          :refresh-trigger-richiamo="inu"
                          :richiamo-date="
                            valoriRichiamo ? valoriRichiamo.last_update : null
                          "
                          @updateData="
                            (title, value, conditionalValue, type, id) =>
                              updateData(
                                title,
                                value,
                                conditionalValue,
                                type,
                                id,
                                index,
                                elemRow.title
                              )
                          "
                          @openSpecialisticModal="openSpecialisticModal"
                          @openAgendaModal="$emit('openAgenda')"
                          @openTariffarioModal="$emit('openTariffario')"
                        /></div
                    ></template>
                  </vue-collapsible-panel>
                </vue-collapsible-panel-group>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal footer -->

        <!-- Fine Modal footer -->
      </div>
    </div>
    <!-- Button div -->
    <div class="flex items-center justify-between pt-2 px-6 mb-1">
      <div class="flex gap-1 items-center">
        <button
          ref="input4"
          type="button"
          class="gray-btn"
          @click="$emit('back')"
        >
          <SkipBackIcon class="h-5 w-5 mr-2" /> Torna indietro
        </button>
        <button type="button" class="gray-btn" @click="$emit('close')">
          <span class="h-5"></span>
          Annulla
        </button>
      </div>
      <div class="flex gap-2">
        <button
          v-if="modifySchedaClinica"
          ref="input4"
          type="button"
          class="orange-btn-inverted"
          @click="enableModify"
        >
          <PlusCircleIcon class="h-5 w-5 mr-2" /> Modifica
        </button>
        <button
          v-if="New"
          ref="input4"
          type="button"
          class="orange-btn-inverted"
          @click="salva"
        >
          <SaveIcon class="h-5 w-5 mr-2" /> Salva
        </button>
      </div>
    </div>
  </div>
  <SpecialisticChoice
    v-if="specialisticModal"
    title=" Tiplogie Schede Specialistiche"
    :options="[
      'Dolore zampa dx anteriore',
      'Gesso per frattura',
      'Controllo ossa'
    ]"
    @close="closeSpecialisticModal"
    @specialisticSelected="handleSpecialisticChoice"
  />
</template>

<script>
import { defineComponent, ref, computed, watch } from 'vue'
import DetailModalRow from './DetailModalRow.vue'
import SpecialisticChoice from './SpecialisticChoice.vue'
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel
} from '@swoga/vue-collapsible-panel'
import '@swoga/vue-collapsible-panel/dist/vue-collapsible-panel.css'
import axios from '../../gateway/backend-api'
import { useStore } from '@/store'

export default defineComponent({
  components: {
    DetailModalRow,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
    SpecialisticChoice
  },
  props: [
    'configuration',
    'title',
    'arguments',
    'New',
    'modifySchedaClinica',
    'headerButtons',
    'pet_id',
    'idTemplate',
    'medicalTemplatesId',
    'petType',
    'keys_name',
    'keys_ids',
    'casi_options',
    'keys',
    'selected_caso'
  ],

  emits: [
    'close',
    'refresh',
    'back',
    'openAgenda',
    'openTariffario',
    'refreshKeys',
    'getCasi',
    'refreshPetData'
  ],
  setup(props, { emit }) {
    // ----- DATA -----
    const modifyRef = ref(props.modifySchedaClinica)
    const caso_clinico = ref(null)
    const argumentRef = ref([]) //ref(props.keys_name ? JSON.parse(props.keys_name) : [])
    if (props.keys_ids && props.keys) {
      JSON.parse(props.keys_ids).forEach((e) => {
        props.keys.forEach((i) => {
          if (e === i.id) argumentRef.value.push(i)
        })
      })
    }
    if (props.selected_caso && props.casi_options) {
      props.casi_options.forEach((e) => {
        if (e.id === props.selected_caso) caso_clinico.value = e
      })
    }
    const specialisticModal = ref(false)

    const casi_clinici = ref([])

    const valoriRichiamo = ref(null)

    const store = useStore()

    const inu = ref(0)
    const esempio = ref(props.keys_name)

    const keys_default = ref(props.keys) // ref(props.keys_name ? JSON.parse(props.keys_name) : [])

    // contiene la copia della configurazione, mappando però i valori con quelli inseriti in input
    const configurationData = ref(props.configuration)

    // ----- METHODS -----

    // abilito la modifica del modale
    const enableModify = () => {
      if (modifyRef.value === false) {
        modifyRef.value === true
        return
      }
      // modifyRef.value = true
      var data = {}
      var send_data = new FormData()

      sections.value.forEach((section, x) => {
        section.value.forEach((row, i) => {
          Object.keys(row).forEach(function (k) {
            if (k.includes('elemento')) {
              let number = k.split('_')[1]
              if (
                row.value !== '' &&
                row.value[number] &&
                row.value[number] !== ''
                // &&  row.value[number] !== []
              ) {
                if (row[k].type === 'upload' || row[k].type === 'video') {
                  let dTemp = []
                  row.value[number].forEach((file, index) => {
                    dTemp.push(
                      'sezione_' +
                        x +
                        '.oggetto_' +
                        i +
                        '.' +
                        k +
                        '.data' +
                        '.file_' +
                        index
                    )
                    send_data.append(
                      'sezione_' +
                        x +
                        '.oggetto_' +
                        i +
                        '.' +
                        k +
                        '.data' +
                        '.file_' +
                        index,
                      file
                    )
                  })
                  if (dTemp && dTemp.length) {
                    data['sezione_' + x + '.oggetto_' + i + '.' + k + '.data'] =
                      dTemp
                  }
                } else {
                  data['sezione_' + x + '.oggetto_' + i + '.' + k + '.data'] =
                    row.value[number]
                }
              }
            }
          })
          if (row.conditionsValue) {
            row.conditionsValue.forEach((el) => {
              var cond = {}
              Object.entries(el).forEach(([elem, e]) => {
                if (elem.includes('elemento_') && e.data)
                  cond[elem] = { data: e.data }
              })
              if (cond) {
                data['sezione_' + x + '.oggetto_' + i + '.data'] = {
                  condictions: {
                    [el['condition']]: cond
                  }
                }
              }
            })
          }
        })
      })

      send_data.append('data', JSON.stringify(data))

      data = {}

      if (followUp.value) {
        followUp.value.forEach((section) => {
          section.value.forEach((row, i) => {
            Object.keys(row).forEach(function (k) {
              if (k.includes('elemento')) {
                let number = k.split('_')[1]
                if (
                  row.value !== '' &&
                  row.value[number] &&
                  row.value[number] !== ''
                  // &&  row.value[number] !== []
                ) {
                  if (row[k].type === 'upload' || row[k].type === 'video') {
                    let dTemp = []
                    row.value[number].forEach((file, index) => {
                      dTemp.push(
                        'oggetto_' + i + '.' + k + '.data' + '.file_' + index
                      )
                      send_data.append(
                        'oggetto_' + i + '.' + k + '.data' + '.file_' + index,
                        file
                      )
                    })
                    data['oggetto_' + i + '.' + k + '.data'] = dTemp
                  } else {
                    data['oggetto_' + i + '.' + k + '.data'] = row.value[number]
                  }
                }
              }
            })
            if (row.conditionsValue) {
              row.conditionsValue.forEach((el) => {
                var cond = {}
                Object.entries(el).forEach(([elem, e]) => {
                  if (elem.includes('elemento_') && e.data)
                    cond[elem] = { data: e.data }
                })
                if (cond) {
                  data['oggetto_' + i + '.data'] = {
                    condictions: {
                      [el['condition']]: cond
                    }
                  }
                }
              })
            }
          })
        })

        send_data.append('follow_up', JSON.stringify(data))
      }

      send_data.append('layout_id', props.configuration.id)
      send_data.append('pet_id', props.pet_id)

      if (caso_clinico.value) {
        send_data.append('clinical_case_primary_id', caso_clinico.value.id)
      }

      store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/medical/' +
            props.pet_id +
            '/' +
            props.configuration.medicaltemplate_id +
            '/edit',
          send_data
        )
        .then(() => {
          if (argumentRef.value && argumentRef.value.length > 0) {
            saveKey(props.configuration.medicaltemplate_id, argumentRef.value)
          }
          store.dispatch('commonComponents/hideLoadingModalAction')
          emit('getCasi')
          emit('refresh')
          emit('close')
        })
        .catch(() => {
          store.dispatch('commonComponents/hideLoadingModalAction')
        })
    }

    const saveKey = (id_template, keys) => {
      const payload = {
        medicaltemplate_id: id_template,
        keys: keys
      }
      if (id_template !== 'undefined' && keys !== 'undefined') {
        axios
          .post('/' + localStorage.getItem('clinic') + '/medicalkey', payload) // salvo la lista
          .then(() => {
            // emit('refreshKeys')
          })
      }
    }

    const salva = () => {
      var data = {}
      var send_data = new FormData()
      let pesi = []

      sections.value.forEach((section, x) => {
        section.value.forEach((row, i) => {
          Object.keys(row).forEach(function (k) {
            if (k.includes('elemento')) {
              let number = k.split('_')[1]
              if (
                row.value !== '' &&
                row.value[number] &&
                row.value[number] !== ''
                // &&  row.value[number] !== []
              ) {
                if (row[k].id === '73') {
                  if (row[k].type === 'number') {
                    console.log('il peso è', row.value[number])
                    pesi.push(row.value[number])
                  } else {
                    console.log('il bsa è', row.value[number])
                  }
                  console.log('ecco il peso')
                }
                if (row[k].type === 'upload' || row[k].type === 'video') {
                  let dTemp = []
                  row.value[number].forEach((file, index) => {
                    dTemp.push(
                      'sezione_' +
                        x +
                        '.oggetto_' +
                        i +
                        '.' +
                        k +
                        '.data' +
                        '.file_' +
                        index
                    )
                    send_data.append(
                      'sezione_' +
                        x +
                        '.oggetto_' +
                        i +
                        '.' +
                        k +
                        '.data' +
                        '.file_' +
                        index,
                      file
                    )
                  })
                  data['sezione_' + x + '.oggetto_' + i + '.' + k + '.data'] =
                    dTemp
                } else {
                  data['sezione_' + x + '.oggetto_' + i + '.' + k + '.data'] =
                    row.value[number]
                }
              }
            }
          })
          if (row.conditionsValue) {
            row.conditionsValue.forEach((el) => {
              var cond = {}
              Object.entries(el).forEach(([elem, e]) => {
                if (elem.includes('elemento_') && e.data)
                  cond[elem] = { data: e.data }
              })
              if (cond) {
                data['sezione_' + x + '.oggetto_' + i + '.data'] = {
                  condictions: {
                    [el['condition']]: cond
                  }
                }
              }
            })
          }
        })
      })

      send_data.append('data', JSON.stringify(data))

      data = {}

      if (followUp.value) {
        followUp.value.forEach((section) => {
          section.value.forEach((row, i) => {
            Object.keys(row).forEach(function (k) {
              if (k.includes('elemento')) {
                let number = k.split('_')[1]
                if (
                  row.value !== '' &&
                  row.value[number] &&
                  row.value[number] !== ''
                  // &&  row.value[number] !== []
                ) {
                  if (row[k].type === 'upload' || row[k].type === 'video') {
                    let dTemp = []
                    row.value[number].forEach((file, index) => {
                      dTemp.push(
                        'oggetto_' + i + '.' + k + '.data' + '.file_' + index
                      )
                      send_data.append(
                        'oggetto_' + i + '.' + k + '.data' + '.file_' + index,
                        file
                      )
                    })
                    data['oggetto_' + i + '.' + k + '.data'] = dTemp
                  } else {
                    data['oggetto_' + i + '.' + k + '.data'] = row.value[number]
                  }
                }
              }
            })
            if (row.conditionsValue) {
              row.conditionsValue.forEach((el) => {
                var cond = {}
                Object.entries(el).forEach(([elem, e]) => {
                  if (elem.includes('elemento_') && e.data)
                    cond[elem] = { data: e.data }
                })
                if (cond) {
                  data['oggetto_' + i + '.data'] = {
                    condictions: {
                      [el['condition']]: cond
                    }
                  }
                }
              })
            }
          })
        })

        send_data.append('follow_up', JSON.stringify(data))
      }

      send_data.append('layout_id', props.configuration.id)
      send_data.append('pet_id', props.pet_id)
      if (caso_clinico.value) {
        send_data.append('clinical_case_primary_id', caso_clinico.value.id)
      }

      store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post(
          '/' +
            localStorage.getItem('clinic') +
            '/medical/' +
            props.pet_id +
            '/save',
          send_data
        )
        .then((res) => {
          if (argumentRef.value && argumentRef.value.length > 0) {
            saveKey(res.data.id, argumentRef.value)
          }

          pesi.forEach((peso) => {
            addWeight(peso)
          })
          if (pesi && pesi.length > 0) {
            emit('refreshPetData')
          }

          store.dispatch('commonComponents/openNotificationModal', {
            message: 'Scheda clinica salvata con successo!'
          })
          store.dispatch('commonComponents/hideLoadingModalAction')
          emit('getCasi')
          emit('refresh')
          emit('close')
        })
        .catch(() => {
          store.dispatch('commonComponents/hideLoadingModalAction')
        })
    }

    // aggiorno i dati nell'array ogni volta che nel componente "DetailModalRow" viene inserito un unput
    const updateData = (
      idClinicRowTemp,
      value,
      conditionsValue,
      type,
      id,
      index,
      section
    ) => {
      if (!section) {
        configurationData.value = configurationData.value.map((elem) => {
          // itero per i dati della config, saltando le sezioni
          if (
            elem.idClinicRowTemp === idClinicRowTemp &&
            elem.type !== 'section'
          ) {
            // il tipo di configurazione del video usa la "didascalia" per contenere il value
            if (elem.type === 'image') {
              elem.didascalia = value
            } else {
              elem.value = value
            }
          }
          return elem
        })
      } else {
        // !section --> il dato è contenuto in una sottosezioni
        sections.value = sections.value.map((elem) => {
          // identifico la sottosezioni in base alla variabile
          if (elem.type === 'section') {
            // mappo tutti i dati della sottosezione e modifico il dato inserito in input
            elem.value = elem.value.map((el) => {
              if (el.idClinicRowTemp === idClinicRowTemp) {
                if (el.type === 'image') {
                  el.didascalia = value
                } else {
                  el.value = value
                  el.conditionsValue = conditionsValue
                }
              }
              return el
            })
          }
          return elem
        })
        followUp.value = followUp.value.map((elem) => {
          // identifico la sottosezioni in base alla variabile
          if (elem.type === 'section') {
            // mappo tutti i dati della sottosezione e modifico il dato inserito in input
            elem.value = elem.value.map((el) => {
              if (el.idClinicRowTemp === idClinicRowTemp) {
                if (el.type === 'image') {
                  el.didascalia = value
                } else {
                  el.value = value
                  el.conditionsValue = conditionsValue
                }
              }
              return el
            })
          }
          return elem
        })
      }
    }

    const openSpecialisticModal = () => {
      specialisticModal.value = true
    }

    const handleSpecialisticChoice = () => {
      specialisticModal.value = false
    }

    const closeSpecialisticModal = () => {
      specialisticModal.value = false
    }

    const richiamaUltimaVisitaGenerale = () => {
      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/medical/' +
            props.pet_id +
            '/getDataFromSchedaGenerale?layout_id=' +
            configurationData.value.id
        )
        .then((res) => {
          valoriRichiamo.value = res.data
          inu.value++
        })
        .catch(() => {
          store.dispatch('commonComponents/openMessageModal', {
            title: 'Attenzione',
            subtitle: 'Prima creare la scheda generale!',
            background: false
          })
        })
    }

    const addWeight = (weight) => {
      axios
        .post('/' + localStorage.getItem('clinic') + '/weight/storePetWeight', {
          pet_id: props.pet_id,
          weight: weight
        })
        .then(() => {
          // store.dispatch('commonComponents/openNotificationModal', {
          //   message: res.data.msg
          // })
        })
    }

    // ----- WATCH -----
    watch(valoriRichiamo, (elem) => {
      if (elem && elem.data) {
        Object.entries(elem.data).forEach(([el, e]) => {
          let parts = el.split('.')
          if (
            typeof sections.value[parts[0].split('_')[1]].value[
              parts[1].split('_')[1]
            ][parts[2]] != 'undefined'
          ) {
            sections.value[parts[0].split('_')[1]].value[
              parts[1].split('_')[1]
            ][parts[2]].richiamoValue = e

            // sections.value[parts[0].split('_')[1]].value[
            //   parts[1].split('_')[1]
            // ][parts[2]].richiamoDate = elem.last_update
          }
        })
      }
    })

    // ----- COMPUTED -----

    // righe di config senza le sottosezioni
    const configurationNoSections = computed(() => {
      // return configurationData.value.filter((elem) => {
      //   return elem.type !== 'section'
      // })
      return []
    })

    // solamente le sottosezioni del modale
    const sections = computed(() => {
      let sec = []
      let temp = []
      const settings = JSON.parse(JSON.stringify(configurationData.value))

      Object.keys(settings).forEach(function (k) {
        let showSection = false
        temp = []

        if (k.includes('sezione')) {
          Object.keys(settings[k]).forEach(function (z) {
            let show = false
            let value = []
            let condictions = []

            if (z.includes('oggetto')) {
              Object.keys(settings[k][z]).forEach(function (f) {
                if (f.includes('elemento')) {
                  if (
                    settings[k][z][f].data
                    // settings[k][z][f].type === 'sub-title' ||
                    // settings[k][z][f].type === 'sub-sub-title'
                  ) {
                    show = true
                    showSection = true
                    if (props.modifySchedaClinica) {
                      value.push(settings[k][z][f].data)
                      if (settings[k][z].condictions) {
                        Object.keys(settings[k][z].condictions).forEach(
                          function (c) {
                            condictions.push({
                              ...settings[k][z].condictions[c],
                              condition: c
                            })
                          }
                        )
                      }
                    }
                  } else {
                    value.push(null)
                  }
                }
              })

              temp.push({
                ...settings[k][z],
                value: value,
                idClinicRowTemp: k + '_' + z,
                showRow: show,
                conditionsValue: condictions
              })
            }
          })
          sec.push({
            type: 'section',
            title: settings[k].label,
            value: temp,
            showSection: showSection
          })
        }
      })
      console.log(sec)

      return sec
    })

    const followUp = computed(() => {
      let sec = []
      let temp = []
      const settings = JSON.parse(JSON.stringify(configurationData.value))

      if (settings['follow_up']) {
        Object.keys(settings['follow_up']).forEach(function (z) {
          let show = false
          let value = []
          let condictions = []

          if (z.includes('oggetto')) {
            Object.keys(settings['follow_up'][z]).forEach(function (f) {
              if (f.includes('elemento')) {
                if (
                  settings['follow_up'][z][f].data
                  // settings[k][z][f].type === 'sub-title' ||
                  // settings[k][z][f].type === 'sub-sub-title'
                ) {
                  show = true

                  if (props.modifySchedaClinica) {
                    value.push(settings['follow_up'][z][f].data)
                    if (settings['follow_up'][z].condictions) {
                      Object.keys(settings['follow_up'][z].condictions).forEach(
                        function (c) {
                          condictions.push({
                            ...settings['follow_up'][z].condictions[c],
                            condition: c
                          })
                        }
                      )
                    }
                  }
                }
              }
            })

            temp.push({
              ...settings['follow_up'][z],
              value: value,
              idClinicRowTemp: 'follow_up' + '_' + z,
              showRow: show,
              conditionsValue: condictions
            })
          }
        })
        sec.push({
          type: 'section',
          title: settings['follow_up'].label,
          value: temp,
          showSection: true
        })
      }

      return sec
    })

    // ritorno modifica a true se indico nelle props che sto creando una nuova scheda clinica e non visualizzando una già creata
    const modify = computed(() => {
      return props.New === true ? true : modifyRef.value
    })

    return {
      argumentRef,
      caso_clinico,
      casi_clinici,
      enableModify,
      modify,
      salva,
      configurationData,
      updateData,
      configurationNoSections,
      sections,
      followUp,
      specialisticModal,
      openSpecialisticModal,
      handleSpecialisticChoice,
      closeSpecialisticModal,
      richiamaUltimaVisitaGenerale,
      valoriRichiamo,
      inu,
      keys_default,
      esempio,
      addWeight
    }
  },

  data() {
    return { expanded: false }
  },
  methods: {
    expandSections() {
      for (var i = 0; i < this.sections.length; i++) {
        if (!document.getElementById(i).className.includes('expanded')) {
          document.getElementById(i).getElementsByTagName('header')[0].click()
        }
      }
      this.expanded = false
    },
    collapseSections() {
      for (var i = 0; i < this.sections.length; i++) {
        if (document.getElementById(i).className.includes('expanded')) {
          document.getElementById(i).getElementsByTagName('header')[0].click()
        }
      }

      this.expanded = true
    }
  }
})
</script>
