<template>
  <div class="" style="visibility: visible; opacity: 1">
    <div
      class="search-result__content px-0 flex flex-col justify-center lg:flex-row"
    >
      <div
        v-if="filterCategories.includes('customer')"
        class="closing-btn mt-2"
      >
        <XIcon class="cursor-pointer" @click="$emit('closeDropDownSearch')" />
      </div>
      <!--search customers-->
      <div
        v-if="filterCategories.includes('customer')"
        class="flex flex-col items-center"
      >
        <div class="search-result__content__title mt-1">Clienti</div>
        <Paginator
          v-show="filteredCustomer != ''"
          :for-page-option="pagination_customer.forPage"
          :total-records="totalRecordsCustomer"
          @changePagination="changePaginationCustomer"
        />
        <div class="my-3">
          <!--no history research-->
          <div v-if="filteredCustomer === ''" class="text-center font-lg mt-4">
            Nessuna ricerca effettuata di recente
          </div>
          <!--no item found -->
          <div
            v-else-if="filteredCustomer.length === 0"
            class="text-center font-lg mt-4"
          >
            Nessun cliente trovato durante la ricerca
          </div>
          <ul class="w-96">
            <li
              v-for="elem in filteredCustomer"
              :key="elem.id"
              class="search-result_list"
              @click.stop="selected(elem)"
            >
              <div class="flex">
                <div class="flex-1 flex flex-col px-5">
                  <div class="flex py-2 items-center">
                    <i class="fas fa-user mb-1" />
                    <div
                      class="text-base font-nunito text-theme-40 font-bold ml-3"
                    >
                      {{ elem.first_name }}, {{ elem.last_name }}
                    </div>
                  </div>
                  <div v-if="elem.pets != ''" class="flex">
                    <ul class="flex flex-row flex-wrap ml-6 py-2">
                      <li v-for="pet in elem.pets" :key="pet">
                        <div class="flex items-baseline">
                          <i class="fas fa-paw w-5 h-5" />

                          <div class="mr-2">
                            <span class="font-medium">{{ pet.name }}</span>
                            <span class="pl-1 ml-1 text-gray-600"
                              >({{ pet.type }})</span
                            >
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
          <!-- <div v-if="filteredCustomer.length > 6" class="w-full text-center">
            <button
              class="p-2 mt-4 border rounded border-gray-600 mx-auto hover:bg-blue-200"
            >
              Vedi tutti
            </button>
          </div> -->
        </div>
      </div>
      <!--search pets-->
      <div>
        <div class="closing-btn mt-2" v-if="filterCategories.includes('pet')">
          <XIcon class="cursor-pointer" @click="$emit('closeDropDownSearch')" />
        </div>
        <div v-if="filterCategories.includes('pet')" class="w-full">
          <div class="flex flex-col items-center">
            <div class="search-result__content__title">Animali</div>
            <Paginator
              v-show="filteredPet != ''"
              :for-page-option="pagination_pet.forPage"
              :total-records="totalRecordsPet"
              @changePagination="changePaginationPet"
            />
          </div>
          <!--no history research-->
          <div v-if="filteredPet === ''" class="text-center font-lg mt-4">
            Nessuna ricerca effettuata di recente
          </div>
          <!--no item found -->
          <div
            v-else-if="filteredPet.length === 0"
            class="text-center font-lg mt-4"
          >
            Nessun animale trovato durante la ricerca

            <div
              v-if="searchInAppointment && customerInvoice?.id"
              class="flex-1 text-xs justify-end pt-5"
            >
              <a class="cursor-pointer" @click="newPet = true">
                <PlusIcon class="h-5 w-5 mr-2" />Nuovo Animale
              </a>
              <div v-if="newPet">
                <input
                  v-model="new_pet_name"
                  type="text"
                  class="custom-input"
                />
                <v-select
                  v-model="new_pet_type_id"
                  class="w-full my-3"
                  label="name"
                  :reduce="(value) => value.id"
                  placeholder="Selezionare la specie"
                  :options="petTypes"
                ></v-select>
                <button class="orange-btn" @click="savePet()">Inserisci</button>
              </div>
            </div>
          </div>

          <ul class="w-96">
            <li
              v-for="elem in filteredPet"
              :key="elem.id"
              class="search-result_list"
              @click.stop="selected(elem)"
            >
              <div class="flex">
                <div class="flex-1 flex flex-col px-5">
                  <div class="flex py-2 mt-2 items-center">
                    <i class="fas fa-paw mb-1" />
                    <div
                      class="text-base font-nunito text-theme-40 font-bold ml-3"
                    >
                      <span class="font-medium">{{ elem.name }}</span>
                      <span class="pl-1 ml-1 text-gray-600"
                        >({{ elem.type }})</span
                      >
                    </div>
                  </div>
                  <div class="flex">
                    <ul class="flex flex-row flex-wrap ml-6 py-2">
                      <li v-for="owner in elem.owners" :key="owner">
                        <div class="flex items-baseline">
                          <i class="fas fa-user w-5 h-5" />

                          <div>
                            <span class="font-medium">
                              {{ owner.last_name }},
                              {{ owner.first_name }}</span
                            >
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import axios from '../../gateway/backend-api'
import _ from 'lodash'
import Paginator from '../../components/paginator/Main.vue'
import { helper as $h } from '@/utils/helper'

export default {
  components: {
    Paginator
  },
  props: {
    filterCategories: {
      type: [Array],
      default: function () {
        return []
      }
    },
    petTypes: {
      type: [Array],
      default: function () {
        return []
      }
    },
    filter: {
      type: [String],
      default: function () {
        return ''
      }
    },
    petInvoice: {
      type: [Object],
      default: function () {
        return {}
      }
    },
    customerInvoice: {
      type: [Object],
      default: function () {
        return {}
      }
    },
    searchInAppointment: {
      type: Boolean,
      default: function () {
        return false
      }
    }
  },
  emits: ['childToParent', 'close', 'closeDropDownSearch', 'newPet'],
  setup(props) {
    const newPet = ref(null)
    const new_pet_name = ref(props.filter)
    const new_pet_type_id = ref(null)
    return {
      newPet,
      new_pet_name,
      new_pet_type_id
    }
  },
  data() {
    return {
      filteredPet: '',
      filteredCustomer: '',
      searchTicket: 0,
      totalRecordsCustomer: '',
      totalRecordsPet: '',
      pagination_customer: {
        page: 1,
        forPage: 5
      },
      pagination_pet: {
        page: 1,
        forPage: 5
      }
    }
  },
  watch: {
    filter: function () {
      $h.debounce(() => this.searchData(this.filterCategories))
    },
    filterCategories: function (elem) {
      $h.debounce(() => this.searchData(elem))
    }
  },

  mounted() {
    this.searchData(this.filterCategories)
  },
  methods: {
    savePet() {
      let pet = {
        name: this.new_pet_name,
        pet_type_id: this.new_pet_type_id,
        customer_id: this.customerInvoice?.id
      }
      this.$emit('newPet', pet)
    },
    closeSearchDrop() {
      this.$emit('close')
    },
    selected(element) {
      this.$emit('childToParent', element, this.filterCategories)
    },
    searchData(parameter) {
      let filterParameter
      if (parameter != undefined && parameter != null) {
        filterParameter = parameter
      } else {
        filterParameter = this.filterCategories
      }
      var send_data = {
        searchText: this.filter,
        filterParameter: filterParameter,
        ticket: ++this.searchTicket,
        pet_id: null,
        customer_id: null,
        per_page_customer: this.pagination_customer.forPage,
        page_customer: this.pagination_customer.page,
        per_page_pet: this.pagination_pet.forPage,
        page_pet: this.pagination_pet.page
      }
      if (parameter == 'pet' && !_.isEmpty(this.customerInvoice))
        send_data.customer_id = this.customerInvoice.id
      if (parameter == 'customer' && !_.isEmpty(this.petInvoice))
        send_data.pet_id = this.petInvoice.id
      if (
        this.filter !== '' ||
        !_.isEmpty(this.customerInvoice) ||
        !_.isEmpty(this.petInvoice)
      ) {
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/search/globalSearch',
            send_data
          )
          .then((res) => {
            if (res.data.ticket == this.searchTicket) {
              if (this.filterCategories.includes('customer')) {
                this.filteredCustomer = res.data.customers.data.items
                this.totalRecordsCustomer = res.data.customers.total
              } else if (this.filterCategories.includes('pet')) {
                this.filteredPet = res.data.pet.data.items
                this.totalRecordsPet = res.data.pet.total
              }
            }
          })
      } else {
        this.filteredCustomer = ''
        this.filteredPet = ''
        this.totalRecordsCustomer = 0
        this.totalRecordsPet = 0
      }
    },
    changePaginationCustomer: function (value) {
      if (this.pagination_customer.page != value.page) {
        this.pagination_customer.page = value.page
        this.pagination_customer.forPage = value.forPage
        this.searchData(this.filterCategories)
      }
    },
    changePaginationPet: function (value) {
      if (this.pagination_pet.page != value.page) {
        this.pagination_pet.page = value.page
        this.pagination_pet.forPage = value.forPage
        this.searchData(this.filterCategories)
      }
    }
  }
}
</script>
