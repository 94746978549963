<template>
  <!-- Main modal -->
  <div
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black scrollbar-none bg-opacity-50"
  >
    <div
      class="relative top-20 p-2 mx-auto overflow-scroll scrollbar-none border sm:w-3/5 w-full shadow-lg rounded-md bg-white"
    >
      <!-- Modal content -->
      <div class="flex flex-col content-end overflow-y-auto">
        <button type="button" class="closing-btn" @click="this.$emit('close')">
          <svg
            class="w-5 h-5"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>
        <!-- Modal header -->
        <div class="flex justify-center p-5 rounded-t">
          <h3 class="text-3xl font-bold nunito text-theme-40 text-center">
            {{ title }}
          </h3>
        </div>
      </div>
      <div class="flex flex-col content-end overflow-y-auto">
        <Calendar :selected-pet="pet" />
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Calendar from '../../views/calendar/Calendar.vue'

export default defineComponent({
  props: ['title', 'pet'],
  emits: ['close'],
  components: { Calendar },
  setup() {
    return {}
  }
})
</script>
