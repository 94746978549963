<template>
  <div
    class="tab-content relative bg-white intro-y box px-5 py-1 overflow-auto content-container scrollbar_custom"
  >
    <div
      v-if="
        permissions &&
        permissions['reparti e sale cliniche'] &&
        permissions['reparti e sale cliniche'].view_department?.enabled === true
      "
      class="fatt-att-cont scrollbar-thin scrollbar-thumb-theme-44 scrollbar-track-theme-2 overflow-scroll"
    >
      <div class="bg-white rounded-lg px-3 py-5">
        <div class="flex flex-col md:flex-row items-center justify-center py-2">
          <h2 class="modal-title w-full">Elenco reparti</h2>
        </div>

        <div
          class="flex flex-col items-end md:flex-row md:items-center gap-2 md:justify-between mt-3 px-3"
        >
          <div
            class="flex-1 flex items-center justify-between md:justify-end gap-2 mt-3"
          >
            <button
              v-if="
                permissions &&
                permissions['reparti e sale cliniche'] &&
                permissions['reparti e sale cliniche'].store_department
                  ?.enabled === true
              "
              class="orange-btn-inverted"
              @click="openModalAddRoom('department')"
            >
              <svg viewBox="0 0 37 32" class="h-5 fill-current">
                <path
                  d="M18.382 6.363h-1.134v-1.133c0-0.423-0.343-0.766-0.766-0.766h-0.766c-0.423 0-0.766 0.343-0.766 0.766v1.133h-1.133c-0.423 0-0.766 0.343-0.766 0.766v0.766c0 0.423 0.343 0.766 0.766 0.766h1.133v1.133c0 0.423 0.343 0.766 0.766 0.766h0.766c0.423 0 0.766-0.343 0.766-0.766v-1.133h1.134c0.423 0 0.766-0.343 0.766-0.766v-0.766c0-0.423-0.343-0.766-0.766-0.766z"
                ></path>
                <path
                  d="M35.911 22.447h-5.059v-5.059c0-0.4-0.156-0.775-0.436-1.053-0.284-0.286-0.661-0.444-1.062-0.444-0.826 0-1.498 0.672-1.498 1.498v5.059h-5.059c-0.479 0-0.917 0.225-1.205 0.617-0.192 0.264-0.293 0.569-0.293 0.882 0 0.203 0.040 0.399 0.118 0.585 0.042 0.098 0.107 0.203 0.199 0.32 0.292 0.377 0.723 0.593 1.181 0.593h5.059v5.059c0 0.314 0.102 0.617 0.292 0.876 0.29 0.395 0.729 0.622 1.205 0.622 0.574 0 1.089-0.327 1.342-0.852 0.103-0.213 0.156-0.43 0.156-0.646v-5.059h5.059c0.826 0 1.498-0.672 1.498-1.498s-0.672-1.498-1.498-1.498z"
                ></path>
                <path
                  d="M19.307 25.457c-0.179-0.424-0.27-0.874-0.27-1.338 0-0.399 0.070-0.791 0.206-1.162h-0.862v-2.655c0-1.269-1.029-2.298-2.298-2.298s-2.298 1.029-2.298 2.298v2.655h-2.094c-0.578 0-1.051-0.471-1.051-1.051v-17.975c0-0.578 0.473-1.051 1.051-1.051h8.661c0.581 0 1.051 0.473 1.051 1.051v16.919c0.34-0.112 0.701-0.17 1.072-0.17h1.807v-13.634h3.544c0.828 0 1.5 0.675 1.5 1.502v1.197h-2.105c-0.804 0-1.459 0.653-1.459 1.456s0.655 1.459 1.459 1.459h2.105v0.972c0.067-0.004 0.133-0.006 0.2-0.006 0.919 0 1.783 0.359 2.433 1.011 0.088 0.087 0.17 0.179 0.247 0.274v-6.365c0-2.416-1.966-4.382-4.379-4.382h-3.544v-0.235c0-2.167-1.764-3.931-3.931-3.931h-8.661c-2.167 0-3.931 1.764-3.931 3.931v0.235h-3.378c-2.416 0-4.382 1.966-4.382 4.382v13.072c0 2.415 1.966 4.379 4.382 4.379h15.225c-0.121-0.178-0.22-0.355-0.297-0.536l-0.003-0.007zM4.382 23.121c-0.828 0-1.502-0.672-1.502-1.5v-4.221h2.107c0.804 0 1.456-0.653 1.456-1.456s-0.653-1.459-1.456-1.459h-2.107v-1.822h2.107c0.804 0 1.456-0.655 1.456-1.459s-0.653-1.456-1.456-1.456h-2.107v-1.197c0-0.828 0.675-1.502 1.502-1.502h3.379v14.858c0 0.425 0.067 0.833 0.194 1.214h-3.573z"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        <!-- BEGIN: Users Layout -->

        <div
          class="grid grid-cols-12 gap-6 mt-5 mx-3 overflow-auto scrollbar_custom"
        >
          <div
            v-for="department in departments"
            :key="department"
            class="col-span-12 sm:col-span-6 md:col-span-4"
          >
            <div
              class="bg-white shadow-md border boder-theme-49 border-opacity-60 rounded-md"
            >
              <div class="flex flex-col lg:flex-row items-center gap-3 p-5">
                <svg viewBox="0 0 40 32" class="h-6 fill-current">
                  <path
                    d="M34.25 5.127h-4.362v-0.289c0-2.667-2.171-4.838-4.838-4.838h-10.66c-2.667 0-4.838 2.171-4.838 4.838v0.289h-4.159c-2.974-0.001-5.393 2.419-5.393 5.393v16.088c0 2.971 2.419 5.391 5.393 5.391h28.857c2.971 0 5.391-2.419 5.391-5.391v-16.088c0-2.974-2.419-5.393-5.391-5.393zM13.096 4.838c0-0.712 0.582-1.293 1.293-1.293h10.66c0.714 0 1.293 0.582 1.293 1.293v22.123c0 0.714-0.579 1.293-1.293 1.293h-2.426v-3.268c0-1.562-1.266-2.828-2.828-2.828s-2.828 1.266-2.828 2.828v3.268h-2.578c-0.712 0-1.293-0.579-1.293-1.293v-22.122zM5.393 28.455c-1.019 0-1.849-0.827-1.849-1.846v-5.195h2.593c0.989 0 1.793-0.804 1.793-1.793s-0.804-1.796-1.793-1.796h-2.593v-2.242h2.593c0.989 0 1.793-0.806 1.793-1.796s-0.804-1.793-1.793-1.793h-2.593v-1.474c0-1.019 0.83-1.849 1.849-1.849h4.159v18.29c0 0.523 0.083 1.025 0.239 1.494h-4.398zM34.25 28.455h-4.599c0.153-0.47 0.236-0.971 0.236-1.494v-18.288h4.362c1.019 0 1.846 0.83 1.846 1.849v1.474h-2.591c-0.989 0-1.796 0.804-1.796 1.793s0.806 1.796 1.796 1.796h2.591v2.242h-2.591c-0.989 0-1.796 0.806-1.796 1.796s0.806 1.793 1.796 1.793h2.591v5.195c0 1.019-0.827 1.846-1.846 1.846z"
                  ></path>
                  <path
                    d="M17.006 10.659h1.395v1.395c0 0.52 0.422 0.943 0.943 0.943h0.943c0.52 0 0.943-0.422 0.943-0.943v-1.395h1.395c0.52 0 0.943-0.422 0.943-0.943v-0.943c0-0.52-0.422-0.943-0.943-0.943h-1.395v-1.395c0-0.52-0.422-0.943-0.943-0.943h-0.943c-0.52 0-0.943 0.422-0.943 0.943v1.395h-1.395c-0.52 0-0.943 0.422-0.943 0.943v0.943c0 0.52 0.422 0.943 0.943 0.943z"
                  ></path>
                </svg>

                <div
                  class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0"
                >
                  <a class="font-bold nunito text-theme-40 text-lg">{{
                    department.name
                  }}</a>
                </div>
                <div class="flex mt-4 lg:mt-0">
                  <button
                    class="gray-btn"
                    @click="openRoom(department, 'department')"
                  >
                    Apri
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- BEGIN: Users Layout -->
      </div>
      <div class="bg-white rounded-lg px-3 py-5">
        <div class="flex flex-col md:flex-row items-center justify-center py-2">
          <h2 class="modal-title w-full">Elenco stanze</h2>
        </div>
        <div
          class="flex flex-col items-end md:flex-row md:items-center gap-2 md:justify-between mt-3 px-3"
        >
          <div
            class="flex-1 flex items-center justify-between md:justify-end gap-2 mt-3"
          >
            <button
              v-if="
                permissions &&
                permissions['reparti e sale cliniche'] &&
                permissions['reparti e sale cliniche'].store_room?.enabled ===
                  true
              "
              class="orange-btn-inverted"
              @click="openModalAddRoom('room')"
            >
              <svg viewBox="0 0 37 32" class="h-5 fill-current">
                <path
                  d="M18.382 6.363h-1.134v-1.133c0-0.423-0.343-0.766-0.766-0.766h-0.766c-0.423 0-0.766 0.343-0.766 0.766v1.133h-1.133c-0.423 0-0.766 0.343-0.766 0.766v0.766c0 0.423 0.343 0.766 0.766 0.766h1.133v1.133c0 0.423 0.343 0.766 0.766 0.766h0.766c0.423 0 0.766-0.343 0.766-0.766v-1.133h1.134c0.423 0 0.766-0.343 0.766-0.766v-0.766c0-0.423-0.343-0.766-0.766-0.766z"
                ></path>
                <path
                  d="M35.911 22.447h-5.059v-5.059c0-0.4-0.156-0.775-0.436-1.053-0.284-0.286-0.661-0.444-1.062-0.444-0.826 0-1.498 0.672-1.498 1.498v5.059h-5.059c-0.479 0-0.917 0.225-1.205 0.617-0.192 0.264-0.293 0.569-0.293 0.882 0 0.203 0.040 0.399 0.118 0.585 0.042 0.098 0.107 0.203 0.199 0.32 0.292 0.377 0.723 0.593 1.181 0.593h5.059v5.059c0 0.314 0.102 0.617 0.292 0.876 0.29 0.395 0.729 0.622 1.205 0.622 0.574 0 1.089-0.327 1.342-0.852 0.103-0.213 0.156-0.43 0.156-0.646v-5.059h5.059c0.826 0 1.498-0.672 1.498-1.498s-0.672-1.498-1.498-1.498z"
                ></path>
                <path
                  d="M19.307 25.457c-0.179-0.424-0.27-0.874-0.27-1.338 0-0.399 0.070-0.791 0.206-1.162h-0.862v-2.655c0-1.269-1.029-2.298-2.298-2.298s-2.298 1.029-2.298 2.298v2.655h-2.094c-0.578 0-1.051-0.471-1.051-1.051v-17.975c0-0.578 0.473-1.051 1.051-1.051h8.661c0.581 0 1.051 0.473 1.051 1.051v16.919c0.34-0.112 0.701-0.17 1.072-0.17h1.807v-13.634h3.544c0.828 0 1.5 0.675 1.5 1.502v1.197h-2.105c-0.804 0-1.459 0.653-1.459 1.456s0.655 1.459 1.459 1.459h2.105v0.972c0.067-0.004 0.133-0.006 0.2-0.006 0.919 0 1.783 0.359 2.433 1.011 0.088 0.087 0.17 0.179 0.247 0.274v-6.365c0-2.416-1.966-4.382-4.379-4.382h-3.544v-0.235c0-2.167-1.764-3.931-3.931-3.931h-8.661c-2.167 0-3.931 1.764-3.931 3.931v0.235h-3.378c-2.416 0-4.382 1.966-4.382 4.382v13.072c0 2.415 1.966 4.379 4.382 4.379h15.225c-0.121-0.178-0.22-0.355-0.297-0.536l-0.003-0.007zM4.382 23.121c-0.828 0-1.502-0.672-1.502-1.5v-4.221h2.107c0.804 0 1.456-0.653 1.456-1.456s-0.653-1.459-1.456-1.459h-2.107v-1.822h2.107c0.804 0 1.456-0.655 1.456-1.459s-0.653-1.456-1.456-1.456h-2.107v-1.197c0-0.828 0.675-1.502 1.502-1.502h3.379v14.858c0 0.425 0.067 0.833 0.194 1.214h-3.573z"
                ></path>
              </svg>
            </button>
          </div>
        </div>

        <!-- BEGIN: Users Layout -->

        <div class="grid grid-cols-12 gap-6 mt-5 mx-3">
          <div
            v-for="room in rooms"
            :key="room"
            class="col-span-12 sm:col-span-6 md:col-span-4"
          >
            <div
              class="bg-white shadow-md border boder-theme-49 border-opacity-60 rounded-md"
            >
              <div class="flex flex-col lg:flex-row items-center gap-3 p-5">
                <svg viewBox="0 0 40 32" class="h-6 fill-current">
                  <path
                    d="M34.25 5.127h-4.362v-0.289c0-2.667-2.171-4.838-4.838-4.838h-10.66c-2.667 0-4.838 2.171-4.838 4.838v0.289h-4.159c-2.974-0.001-5.393 2.419-5.393 5.393v16.088c0 2.971 2.419 5.391 5.393 5.391h28.857c2.971 0 5.391-2.419 5.391-5.391v-16.088c0-2.974-2.419-5.393-5.391-5.393zM13.096 4.838c0-0.712 0.582-1.293 1.293-1.293h10.66c0.714 0 1.293 0.582 1.293 1.293v22.123c0 0.714-0.579 1.293-1.293 1.293h-2.426v-3.268c0-1.562-1.266-2.828-2.828-2.828s-2.828 1.266-2.828 2.828v3.268h-2.578c-0.712 0-1.293-0.579-1.293-1.293v-22.122zM5.393 28.455c-1.019 0-1.849-0.827-1.849-1.846v-5.195h2.593c0.989 0 1.793-0.804 1.793-1.793s-0.804-1.796-1.793-1.796h-2.593v-2.242h2.593c0.989 0 1.793-0.806 1.793-1.796s-0.804-1.793-1.793-1.793h-2.593v-1.474c0-1.019 0.83-1.849 1.849-1.849h4.159v18.29c0 0.523 0.083 1.025 0.239 1.494h-4.398zM34.25 28.455h-4.599c0.153-0.47 0.236-0.971 0.236-1.494v-18.288h4.362c1.019 0 1.846 0.83 1.846 1.849v1.474h-2.591c-0.989 0-1.796 0.804-1.796 1.793s0.806 1.796 1.796 1.796h2.591v2.242h-2.591c-0.989 0-1.796 0.806-1.796 1.796s0.806 1.793 1.796 1.793h2.591v5.195c0 1.019-0.827 1.846-1.846 1.846z"
                  ></path>
                  <path
                    d="M17.006 10.659h1.395v1.395c0 0.52 0.422 0.943 0.943 0.943h0.943c0.52 0 0.943-0.422 0.943-0.943v-1.395h1.395c0.52 0 0.943-0.422 0.943-0.943v-0.943c0-0.52-0.422-0.943-0.943-0.943h-1.395v-1.395c0-0.52-0.422-0.943-0.943-0.943h-0.943c-0.52 0-0.943 0.422-0.943 0.943v1.395h-1.395c-0.52 0-0.943 0.422-0.943 0.943v0.943c0 0.52 0.422 0.943 0.943 0.943z"
                  ></path>
                </svg>

                <div
                  class="lg:ml-2 lg:mr-auto text-center lg:text-left mt-3 lg:mt-0"
                >
                  <a class="font-bold nunito text-theme-40 text-lg"
                    >{{ room.name }}
                  </a>

                  {{ room.reparto_name }}
                </div>
                <div class="flex mt-4 lg:mt-0">
                  <button class="gray-btn" @click="openRoom(room, 'room')">
                    Apri
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- BEGIN: Users Layout -->
      </div>
    </div>
  </div>
  <AddRoomModal
    v-if="addRoomOpen"
    :err="err"
    :room-selected="roomSelected"
    :type="typeOpen"
    @close="closeModalAddRoom"
    @save="addRoom"
    @edit="editRoom"
    @deleteRoom="deleteRoom"
  />
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import axios from '../../../gateway/backend-api'
import AddRoomModal from '../../../components/modals/AddRoomModal.vue'

const forPageOption = [10, 20, 50]
export default defineComponent({
  components: {
    AddRoomModal
  },
  data() {
    return {
      typeOpen: '',
      rooms: [],
      departments: [],
      roomSelected: null,
      addRoomOpen: false,
      err: '',
      totalRecords: 0,
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      }
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions'
    })
  },
  watch: {},
  mounted() {
    this.getRooms()
    this.getDepartments()
  },
  methods: {
    getRooms() {
      //Chiamata per ricevere l'elenco delle stanze
      axios
        .get('/' + localStorage.getItem('clinic') + '/departments/clinic-room')
        .then((res) => {
          this.rooms = res.data
        })
    },
    getDepartments() {
      //Chiamata per ricevere l'elenco delle stanze
      axios
        .get('/' + localStorage.getItem('clinic') + '/departments')
        .then((res) => {
          this.departments = res.data
        })
    },
    openModalAddRoom(type) {
      this.typeOpen = type
      this.addRoomOpen = true
    },
    closeModalAddRoom() {
      this.addRoomOpen = false
      this.roomSelected = null
    },
    openRoom(room, type) {
      this.roomSelected = room
      this.openModalAddRoom(type)
    },

    editRoom(params) {
      if (params.type) {
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/departments/' +
              params.id +
              '/edit-room',
            params
          )
          .then((res) => {
            if (
              typeof res.data.status != 'undefined' &&
              res.data.status == 'success'
            ) {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Stanza modificata con successo'
              })
              this.closeModalAddRoom()
              this.getRooms()
            }
            this.editErr(res)
          })
          .catch((e) => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Errore:' + e
            })
          })
      } else {
        // Si tratta di un reparto
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/departments/' +
              params.id +
              '/edit',
            params
          )
          .then((res) => {
            if (
              typeof res.data.status != 'undefined' &&
              res.data.status == 'success'
            ) {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Reparto modificato con successo'
              })
              this.closeModalAddRoom()
              this.getDepartments()
            }
            this.editErr(res)
          })
          .catch((e) => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Errore:' + e
            })
          })
      }
    },

    addRoom(params) {
      if (params.type) {
        // Si tratta di una stanza
        axios
          .post(
            '/' + localStorage.getItem('clinic') + '/departments/new-room',
            params
          )
          .then((res) => {
            if (res.status === 201 || res.status === 200) {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Stanza inserita con successo'
              })
              this.closeModalAddRoom()
              this.getRooms()
            }
            this.editErr(res)
          })
          .catch((e) => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Errore:' + e
            })
          })
      } else {
        // Si tratta di un reparto
        axios
          .post('/' + localStorage.getItem('clinic') + '/departments', params)
          .then((res) => {
            if (res.status === 201 || res.status === 200) {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'Reparto inserito con successo'
              })
              this.closeModalAddRoom()
              this.getDepartments()
            }
            this.editErr(res)
          })
          .catch((e) => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Errore:' + e
            })
          })
      }
    },

    deleteRoom(id, type) {
      if (type) {
        this.$store.dispatch('commonComponents/openConfirmModal', {
          title: 'CONFERMA',
          subtitle: 'Sei sicuro di voler eliminare questa stanza?',
          background: false,
          confirm: () => {
            axios
              .delete(
                '/' +
                  localStorage.getItem('clinic') +
                  '/departments/' +
                  id +
                  '/delete-room/'
              )
              .then((res) => {
                if (
                  typeof res.data.status != 'undefined' &&
                  res.data.status == 'success'
                ) {
                  this.$store.dispatch(
                    'commonComponents/openNotificationModal',
                    {
                      message: 'Stanza eliminata con successo'
                    }
                  )
                  this.closeModalAddRoom()
                  this.getRooms()
                }
                this.editErr(res)
              })
              .catch((e) => {
                this.$store.dispatch('commonComponents/openNotificationModal', {
                  message: 'Errore:' + e
                })
              })
          },
          decline: () => {
            console.log('Ho declinato la risposta')
          }
        })
      } else {
        // Si tratta di un reparto
        this.$store.dispatch('commonComponents/openConfirmModal', {
          title: 'CONFERMA',
          subtitle: 'Sei sicuro di voler eliminare questo reparto?',
          background: false,
          confirm: () => {
            axios
              .delete(
                '/' +
                  localStorage.getItem('clinic') +
                  '/departments/' +
                  id +
                  '/delete/'
              )
              .then((res) => {
                if (
                  typeof res.data.status != 'undefined' &&
                  res.data.status == 'success'
                ) {
                  this.$store.dispatch(
                    'commonComponents/openNotificationModal',
                    {
                      message: 'Reparto eliminato con successo'
                    }
                  )
                  this.closeModalAddRoom()
                  this.getDepartments()
                }
                this.editErr(res)
              })
              .catch((e) => {
                this.$store.dispatch('commonComponents/openNotificationModal', {
                  message: 'Errore:' + e
                })
              })
          },
          decline: () => {
            console.log('Ho declinato la risposta')
          }
        })
      }
    },

    editErr(res) {
      this.err = ''
      if (typeof res.data.username != 'undefined')
        this.err += res.data.username + '<br>'
      if (typeof res.data.username != 'undefined')
        this.err += res.data.username + '<br>'
      if (typeof res.data.email != 'undefined')
        this.err += res.data.email + '<br>'
      if (typeof res.data.first_name != 'undefined')
        this.err += res.data.first_name + '<br>'
      if (typeof res.data.last_name != 'undefined')
        this.err += res.data.last_name + '<br>'
      if (typeof res.data.role.id != 'undefined')
        this.err += res.data.role.id + '<br>'
    }
  }
})
</script>
