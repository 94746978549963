<template>
  <div
    v-if="tableConfigClinicDoc && documents"
    class="flex w-full justify-between py-2 my-2"
  >
    <div class="prova mr-5 my-2 flex justify-between"></div>
  </div>

  <div class="flex w-full justify-end"></div>

  <!-- BEGIN: Data List -->
  <div class="table-container overflow-scroll scrollbar-none pb-3">
    <table v-if="tableConfigClinicDoc && documents" class="w-full">
      <thead class="t-header h-10">
        <tr>
          <th v-for="(item, itemKey) in tableConfigClinicDoc" :key="itemKey">
            {{ item.title }}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody class="mt-5">
        <tr class="">
          <td
            v-for="(elem, index) in tableConfigClinicDoc"
            :key="index"
            class="text-center px-1 py-3"
          >
            <select
              v-if="elem.type === 'select'"
              v-model="elem.value"
              class="w-full border rounded-md h-7 text-gray-500"
            >
              <option selected value="">{{ elem.title }}</option>
              <option v-for="(item, i) in elem.option" :key="i" :value="item">
                {{ item }}
              </option>
            </select>
            <input
              v-else
              v-model="elem.value"
              :type="elem.type"
              min="0"
              :name="elem.title"
              class="form-control h-7"
              :class="{
                invisible: elem.type === 'none',
                'p-0': elem.type === 'date'
              }"
              :placeholder="elem.title"
            />
          </td>
        </tr>
        <tr
          v-for="(doc, docKey) in newDoc"
          :key="docKey"
          class="h-12 text-xs font-normal hover:bg-theme-45"
          :class="{ 'bg-theme-2': docKey % 2 === 0 }"
        >
          <td class="text-center text-sm border-r-2 border-theme-46">
            <!-- <template v-if="aliasToSave[doc.id]">{{
              $emit('getAliasData', docKey, doc.id)
            }}</template> -->

            {{ doc.name }}
          </td>
          <td class="text-center text-sm border-r-2 border-theme-46">
            {{ doc.qty }}
          </td>
          <td
            class="text-center align-center justify-center text-sm border-r-2 border-theme-46"
          >
            <input
              v-model="doc.price"
              class="custom-input focus:outline-none focus:ring-2 focus:ring-theme-41 pl-5 py-1"
              type="number"
              step="0.001"
              placeholder="Prezzo"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <div
      v-if="documents && documents.length === 0"
      class="w-full flex justify-center mt-2"
    >
      Nessun Risultato
    </div>
  </div>
  <!-- END: Data List -->
</template>

<script>
import { defineComponent, ref, toRef } from 'vue'

export default defineComponent({
  props: [
    'documents',
    'totalRecords',
    'pagination',
    'forPageOption',
    'selectedCategoria',
    'aliasToSave',
    'selectedNew'
  ],
  emits: [
    'removeWeight',
    'openPriceModal',
    'addAlias',
    'getNomenclatore',
    'changePagination',
    'getAliasData',
    'changeNumPages',
    'addElement',
    'removeElement'
  ],
  setup(props) {
    // variabili paginazione
    const newDoc = toRef(props, 'documents')
    const tableConfigClinicDoc = ref([
      {
        key: 'name',
        title: 'Nome',
        type: 'string'
      },
      {
        key: 'qty',
        title: 'Disponibilità',
        type: 'none'
      },
      {
        key: 'purchase_price',
        title: 'Prezzo ',
        type: 'none'
      }
    ])

    // Metodo per eliminare un elemento dalla lista

    //  cambio elementi per pagina, e ritorno nella prima pagina

    // metodo che cambia il numero della pagina

    // ----- FINE COMPUTED ----- //

    return {
      tableConfigClinicDoc,
      newDoc
    }
  }
})
</script>
