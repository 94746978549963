<template>
  <router-view />
</template>

<script>
export default {
  setup() {},
  created() {
    this.$store.dispatch('reusableData/getBackendVersion')
    // this.$startGlobalTimer()
  }
}

document.addEventListener('keydown', async (e) => {
  if (e.key === 'F5') {
    let res = await confirm(
      'Ricaricando la pagina si potrebbero perdere le ultime modifiche.'
    )
    if (res === false) e.preventDefault()
  }
})
</script>
