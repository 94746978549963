<template>
  <div
    v-if="
      permissions &&
      permissions['comunicazioni e note'] &&
      permissions['comunicazioni e note'].view_note?.enabled === true
    "
    class="grid grid-cols-12 gap-1"
  >
    <!-- BEGIN: Notes -->
    <div class="p-2 col-span-12 lg:col-span-6 rounded-lg shadow-md mx-2">
      <div
        class="flex md:flex-row items-center justify-center bg-theme-45 rounded-md py-2 relative"
      >
        <h2 class="text-xl mr-2 nunito text-theme-40 font-bold text-center">
          Comunicazioni interne
        </h2>

        <button
          v-if="
            permissions &&
            permissions['comunicazioni e note'] &&
            permissions['comunicazioni e note'].store_note?.enabled === true
          "
          class="text-theme-41 zoom-big absolute right-3 tooltipbutton"
          @click="openNewAnnotationModal"
        >
          <span class="tooltipbuttontext" style="width: 170px"
            >Aggiungi comunicazione</span
          >
          <svg
            id="icon-Risorsa-21"
            viewBox="0 0 34 32"
            class="h-6 fill-current"
          >
            <path
              d="M17.134 27.039c-0.229-0.292-0.407-0.589-0.541-0.901-0.215-0.509-0.323-1.044-0.323-1.595 0-0.864 0.274-1.696 0.792-2.408 0.771-1.053 2.002-1.679 3.295-1.679h2.488v-2.488c0-2.254 1.833-4.087 4.087-4.087 1.096 0 2.123 0.427 2.893 1.202 0.767 0.761 1.194 1.788 1.194 2.884v2.488h1.462c0.549-0.901 0.993-1.887 1.31-2.962 1.561-5.296-0.541-10.751-4.927-13.965-2.977-2.178-6.359-3.249-10.042-3.479-5.009-0.313-9.568 0.85-13.557 3.955-5.715 4.447-6.737 11.93-2.379 17.444 0.45 0.569 0.516 1.058 0.362 1.719-0.441 1.884-1.302 3.54-2.561 4.99-0.591 0.681-0.709 1.099-0.682 1.417 0.063 0.758 1.977 0.463 2.126 0.449 2.753-0.264 5.21-1.322 7.481-2.838 1.133-0.757 2.485 0.058 3.622 0.303 0.989 0.214 2.012 0.311 3.035 0.341 0.557 0.017 1.115 0.012 1.665-0.004-0.295-0.221-0.565-0.483-0.798-0.785z"
            ></path>
            <path
              d="M32.847 23.119h-4.663v-4.663c0-0.375-0.146-0.728-0.41-0.99-0.268-0.27-0.619-0.416-0.995-0.416-0.775 0-1.406 0.631-1.406 1.406v4.663h-4.663c-0.449 0-0.861 0.211-1.13 0.578-0.18 0.247-0.276 0.534-0.276 0.828 0 0.19 0.037 0.375 0.11 0.547 0.040 0.093 0.1 0.191 0.189 0.304 0.274 0.355 0.677 0.555 1.108 0.555h4.663v4.663c0 0.295 0.095 0.579 0.274 0.823 0.272 0.37 0.683 0.582 1.131 0.582 0.539 0 1.021-0.306 1.26-0.799 0.097-0.199 0.146-0.403 0.146-0.607v-4.663h4.663c0.775 0 1.406-0.631 1.406-1.406s-0.631-1.406-1.406-1.406z"
            ></path>
          </svg>
        </button>
      </div>

      <div
        class="overflow-y-scroll overflow-x-hidden scrollbar scrollbar-thin scrollbar-thumb-rounded-lg scroll scrollbar-thumb-theme-41 scrollbar-track-theme-2"
        style="max-height: 20vh"
      >
        <div class="flex flex-col p-1 gap-2 mt-2">
          <div
            v-for="(note, item) in notes.annotations.note"
            :key="item"
            class="flex items-center p-2 md:px-10 rounded-2xl shadow justify-between relative"
            :class="{ 'bg-theme-2': item % 2 !== 0 }"
          >
            <div
              class="flex flex-col lg:flex-row lg:items-baseline gap-2 flex-wrap 0"
            >
              <div
                v-if="
                  permissions &&
                  permissions['comunicazioni e note'] &&
                  permissions['comunicazioni e note'].delete_note?.enabled ===
                    true
                "
                class="font-medium text-theme-41 absolute right-3"
              >
                <i
                  class="fas fa-times cursor-pointer"
                  @click="deleteNote(note.id)"
                />
              </div>

              <div class="flex items-baseline flex-wrap gap-2">
                <div class="w-2 h-2 bg-theme-41 rounded-full mr-2"></div>
                <div class="flex items-baseline gap-1">
                  <label class="form-label mb-0">Nota:</label>
                  <span class="font-normal text-sm" v-html="note.note"> </span>
                </div>

                <div class="text-xs ml-4 font-medium">
                  creato da:
                  <span class="italic text-xs mr-2 font-normal">
                    {{ note.first_name }} {{ note.last_name }}</span
                  >
                  <span class="italic text-xs font-normal">
                    {{ $FormatDateMin(note.last_update) }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        v-if="
          notes &&
          notes.annotations &&
          notes.annotations.note &&
          notes.annotations.total > 3
        "
        class="flex justify-end mt-1"
      >
        <button
          class="orange-btn"
          :class="[more_notes ? '' : 'disabilitato']"
          @click="
            showMoreNotes(
              {
                forPage: notes.annotations.pagination.forPage + 1,
                page: notes.annotations.pagination.page
              },
              'annotations'
            )
          "
        >
          Mostra di più
        </button>
      </div>
    </div>

    <!-- END: Notes -->

    <!-- BEGIN: Calendar Side Menu -->

    <div
      class="p-2 pb-4 col-span-12 lg:col-span-6 rounded-lg shadow-md mx-2 bg-white"
    >
      <div
        class="flex md:flex-row items-center justify-center bg-theme-45 rounded-md py-2"
      >
        <h2 class="text-xl mr-2 nunito text-theme-40 font-bold text-center">
          Ultimi appuntamenti negli ultimi 6 mesi
        </h2>
      </div>
      <div class="flex flex-col px-1 mt-3">
        <FullCalendarDraggable
          id="calendar-events"
          class="flex flex-col gap-2 overflow-y-scroll scrollbar scrollbar-thin scrollbar-thumb-rounded-lg scroll scrollbar-thumb-theme-41 scrollbar-track-theme-2"
          style="max-height: 20vh"
        >
          <div class="">
            <table class="w-full">
              <thead class="h-5 t-header">
                <tr>
                  <th>Data</th>
                  <th>Motivo</th>

                  <th>Veterinario</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in lastAppointments"
                  :key="item.id"
                  :class="[index % 2 == 0 ? 'bg-white' : 'bg-theme-2']"
                >
                  <td>
                    {{ $FormatDateMin(item.start_datetime) }}
                  </td>
                  <td>
                    {{ item.title ? item.title : '--' }}
                  </td>

                  <td>
                    <div v-for="(vet, i) in item.staff_users" :key="vet">
                      <span class="text-sm">
                        {{ i > 0 ? ', ' : '' }}
                        <div class="flex items-center gap-1 justify-center">
                          <span>
                            {{ vet.first_name }}
                          </span>
                          <span>
                            {{ vet.last_name }}
                          </span>
                        </div>
                      </span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <!-- <div
            v-for="(item, index) in lastAppointments"
            :key="item.id"
            class="px-2 py-3 mr-5 rounded-2xl shadow flex justify-center xl:justify-start"
            :class="[index % 2 == 0 ? 'bg-white' : 'bg-theme-2']"
          >
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
                class="fill-current text-theme-41 h-4"
              >
                <path
                  d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zM329 305c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-95 95-47-47c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l64 64c9.4 9.4 24.6 9.4 33.9 0L329 305z"
                />
              </svg>
            </div>
            <div class="grid grid-cols-7 xl:grid-cols-1 gap-2 px-2">
              <div
                class="col-span-7 md:col-span-2 xl:col-span-1 flex items-baseline gap-2 mr-4"
              >
                <label class="form-label"> Data:</label>
                <span class="text-sm">
                  {{ $FormatDateMin(item.start_datetime) }}</span
                >
              </div>

              <div
                class="col-span-7 md:col-span-2 xl:col-span-1 flex items-baseline gap-2"
              >
                <label class="form-label"> Motivo:</label>

                <span class="font-sm capitalize">
                  {{ item.title ? item.title : '--' }}</span
                >
              </div>
              <div
                v-if="item.customer"
                class="col-span-7 md:col-span-2 xl:col-span-1 flex items-baseline gap-2"
              >
                <label class="form-label"> Cliente:</label>

                <span class="font-sm capitalize">
                  {{ item.customer.first_name ?? '' }}
                  {{ item.customer.last_name ?? '' }}
                  {{ item.customer.ragione_sociale ?? '' }}</span
                >
              </div>

              <div
                v-if="item.staff_users && item.staff_users.length"
                class="col-span-7 md:col-span-3 xl:col-span-1 flex items-baseline gap-2"
              >
                <label class="form-label"> Veterinario:</label>

                <div v-for="(vet, i) in item.staff_users" :key="vet">
                  <span class="text-sm">
                    {{ i > 0 ? ', ' : '' }}
                    {{ vet.first_name }} {{ vet.last_name }}
                  </span>
                </div>
              </div>
            </div>
          </div> -->

          <div
            id="calendar-no-events"
            class="text-gray-600 p-3 text-center hidden"
          >
            No events yet
          </div>
        </FullCalendarDraggable>
      </div>
      <div class="flex justify-end mt-5">
        <button
          v-if="lastAppointments.length >= 3"
          class="orange-btn"
          :class="[more_appointments ? 'disabilitato' : '']"
          @click="handleShowResults"
        >
          Mostra di piu
        </button>
      </div>
    </div>

    <!-- BEGIN: Medical reports -->
    <div
      v-if="
        permissions && permissions?.allegati?.view_attachments?.enabled === true
      "
      class="p-2 pb-4 col-span-12 md:col-span-12 rounded-lg shadow-md mx-2 bg-white"
    >
      <div
        class="flex flex-col md:flex-row items-center justify-center bg-theme-45 rounded-md py-2"
      >
        <h2 class="text-xl mr-2 nunito text-theme-40 font-bold text-center">
          Ultimi documenti clinici
        </h2>
      </div>
      <div class="px-1 mt-4 py-2 overflow-auto scrollbar_custom">
        <!-- BEGIN: Data List -->
        <div class=" ">
          <table class="w-full shadow border-b border-gray-200 sm:rounded-lg">
            <THeader
              v-for="(item, itemKey) in tableData.config"
              :key="itemKey"
              :name="item.title"
              class="t-header"
            />
            <tbody>
              <tr
                v-for="(doc, index) in docs"
                :key="index"
                class="bg-white row-custom text-theme-43 hover:bg-theme-45 h-10"
                :class="{ 'bg-theme-2': index % 2 === 0 }"
              >
                <Cell
                  :elementi="$FormatDate(doc.last_update)"
                  class="text-center text-sm border-r-2 border-theme-46"
                />
                <Cell
                  :elementi="doc.description ?? doc.file_original_name"
                  class="text-center text-sm border-r-2 border-theme-46"
                />
                <Cell
                  :elementi="
                    doc.staff_user
                      ? doc.staff_user.first_name && doc.staff_user.last_name
                        ? doc.staff_user.last_name +
                          ' ' +
                          doc.staff_user.first_name
                        : doc.staff_user.username
                      : ''
                  "
                  class="text-center text-sm border-r-2 border-theme-46"
                />
                <td class="flex text-xl text-center lg:block">
                  <a
                    :href="doc.file_url"
                    target="blank"
                    class="cursor-pointer tooltipbutton"
                  >
                    <span
                      class="tooltipbuttontext"
                      style="
                        margin-top: 2rem;
                        margin-left: 3rem;
                        font-weight: lighter !important;
                        color: white !important;
                      "
                      >Apri PDF</span
                    >
                    <i class="p-3 fas fa-file-pdf" />
                  </a>
                  <a class="cursor-pointer" @click="handleDeleteFile(doc)">
                    <i class="p-3 fas fa-times" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Data List -->
      </div>
    </div>
    <!-- END: Medical reports -->
  </div>
  <NewAnnotationModal
    v-if="newAnnotationModal"
    type="annotations"
    @close="closeNewAnnotationModal"
    @addNote="addNote"
  />
  <!-- END: Calendar Side Menu -->
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'
import THeader from '../cvit-table/THeader.vue'
import Cell from '../cvit-table/Cell.vue'
import axios from '../../gateway/backend-api'
import NewAnnotationModal from '../modals/NewAnnotationModal.vue'

export default defineComponent({
  components: {
    THeader,
    Cell,
    NewAnnotationModal
  },
  props: {
    petDetails: Object,
    notes: Object,
    newAnnotationModal: Boolean
  },
  emits: [
    'deleteNote',
    'addNote',
    'toggleNewAnnotationModal',
    'changePaginationNotes'
  ],
  data() {
    return {
      more_notes: true,
      more_appointments: false,
      lastAppointments: [],
      docs: [],
      tableData: {
        config: [
          {
            key: 'date',
            title: 'Data'
          },
          {
            key: 'document',
            title: 'Descrizione'
          },
          {
            key: 'doctor',
            title: 'Medico'
          },
          {
            key: 'quick-actions',
            title: 'Azioni Rapide'
          }
        ],
        input: [
          {
            key: 'date',
            title: 'Data',
            type: 'date'
          },
          {
            key: 'document',
            title: 'Descrizione',
            type: 'text'
          },
          {
            key: 'doctor',
            title: 'Medico',
            type: 'text'
          }
        ]
      }
    }
  },
  computed: {
    ...mapGetters({
      permissions: 'reusableData/getPermissions'
    })
  },
  mounted() {
    if (localStorage.getItem('clinic')) {
      this.getDocs()

      axios
        .post('/' + localStorage.getItem('clinic') + '/agenda/index', {
          active_filters: { pets: [this.petDetails.id] }
        })
        .then((res) => {
          this.lastAppointments = res.data.data
        })
    }
  },
  methods: {
    getDocs() {
      axios
        .post('/' + localStorage.getItem('clinic') + '/attachments', {
          pet_id: this.petDetails.id,
          type: 'documents',
          per_page: 5,
          page: 1
        })
        .then((res) => {
          this.docs = res.data.data
          this.totalRecords = res.data.total
        })
    },
    deleteNote(note_id) {
      this.$emit('deleteNote', 'annotations', note_id)
    },
    addNote(type, note) {
      this.$emit('addNote', type, note)
    },
    openNewAnnotationModal() {
      this.$emit('toggleNewAnnotationModal', true)
    },
    closeNewAnnotationModal() {
      this.$emit('toggleNewAnnotationModal', false)
    },
    showMoreNotes(pagination, type) {
      this.$emit('changePaginationNotes', pagination, type)
      if (this.notes.annotations.note.length == this.notes.annotations.total) {
        this.more_notes = false
      }
    },
    handleShowResults() {
      this.more_appointments = true
    },
    handleDeleteFile: function (doc) {
      axios
        .delete(
          '/' +
            localStorage.getItem('clinic') +
            '/attachments/' +
            doc.id +
            '/delete-file'
        )
        .then(() => {
          this.getDocs()
          this.$store.dispatch('commonComponents/openNotificationModal', {
            message: 'File rimosso con successo!'
          })
        })
    }
  }
})
</script>
<style>
.hide-results:nth-child(n + 3) {
  display: none;
}
.show-results:nth-child(n + 3) {
  display: flex;
}
</style>
