<template>
  <div class="container-pagination flex justify-end items-center mr-3">
    <ChevronsLeftIcon
      class="cursor-pointer text-theme-41"
      @click="changePage(0)"
    />
    <ChevronLeftIcon
      class="mr-5 cursor-pointer text-theme-41"
      @click="changePage(-1)"
    />
    <span class="font-medium"> {{ page }} di {{ pages }} </span>
    <ChevronRightIcon
      class="ml-5 cursor-pointer text-theme-41"
      @click="changePage(1)"
    />
    <ChevronsRightIcon
      class="cursor-pointer text-theme-41"
      @click="changePage(pages)"
    />
  </div>
</template>

<script>
export default {
  props: {
    forPageOption: {
      type: [Number],
      default: function () {
        return 0
      }
    },
    totalRecords: {
      type: [Number],
      default: function () {
        return 0
      }
    },
    pageDefault: {
      type: [Number],
      default: function () {
        return null
      }
    }
  },
  emits: ['changePagination'],
  data() {
    return {
      page: 1,
      forPage: this.forPageOption,
      pagination: {
        page: 1,
        forPage: this.forPageOption
      }
    }
  },

  computed: {
    pages: function () {
      const remainder = this.totalRecords % this.forPageOption
      if (remainder > 0) {
        // divisione con resto
        return Math.floor(this.totalRecords / this.forPageOption) + 1
      } else {
        // numero di elementi multiplo perfetto degli elementi per pagina
        return Math.max(1, this.totalRecords / this.forPageOption) // senza il max succede di avere "pagina 1 di 0"
      }
    }
  },

  watch: {
    pages: function () {
      this.changePage(0)
    }
  },
  created() {
    if (this.pageDefault) this.page = this.pageDefault
  },
  methods: {
    changePage: function (val) {
      switch (val) {
        case 0:
          this.page = 1
          break
        case -1:
          this.page = this.page > 1 ? this.page - 1 : this.page
          break
        case 1:
          this.page = this.page < this.pages ? this.page + 1 : this.page
          break
        case this.pages:
          this.page = this.pages
          break
      }
      this.pagination.page = this.page
      this.pagination.forPage = this.forPageOption
      // con $emit ritorno al padre di questo componente il click in questo componente
      this.$emit('changePagination', this.pagination)
    }
  }
}
</script>
