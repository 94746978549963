<template>
  <!-- BEGIN: Attachments list -->
  <div class="pb-4 my-2">
    <div
      class="flex flex-col items-center md:justify-center relative md:px-5 md:py-5 sm:py-3 border-b border-gray-200 dark:border-dark-5"
    ></div>
    <div class="flex w-full justify-end mb-5">
      <button
        type="file"
        class="orange-btn-inverted m-3"
        @click="showNewAcceptanceModal = true"
      >
        <FilePlusIcon class="h-5 w-5 mr-2" /> Nuovo consenso / certificato
      </button>
    </div>
    <div class="p-5">
      <!-- BEGIN: Data List -->
      <div class="paginator-container">
        <div class="prova mr-5 my-2 flex justify-between flex-1">
          <div class="my-1">
            <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
              >Mostra
            </span>

            <select
              id=""
              name=""
              class="form-select w-16 h-6 pr-2 pl-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
              @change="(event) => changeNumPages(event)"
            >
              <option v-for="show in forPageOption" :key="show" :value="show">
                {{ show }}
              </option>
            </select>

            <span class="text-md font-bold ml-2 mt-1"> elementi</span>
          </div>
        </div>

        <Paginator
          :for-page-option="pagination.forPage"
          :total-records="totalRecords"
          @changePagination="(event) => changePagination(event)"
        />
      </div>

      <!-- BEGIN: Data List -->
      <div
        class="table-container bg-white scrollbar_custom pb-5 overflow-auto overflow-auto"
      >
        <table class="w-full">
          <THeader
            v-for="(item, itemKey) in tableData.config"
            :key="itemKey"
            :name="item.title"
            class="t-header h-8"
          />

          <tbody>
            <tr>
              <td
                v-for="(elem, index) in tableData.input"
                :key="index"
                class="text-center px-1 py-3"
              >
                <select
                  v-if="elem.type === 'select'"
                  class="w-full border rounded-md h-7 text-gray-500 mt-2"
                >
                  <option selected></option>
                  <option
                    v-for="(item, i) in elem.option"
                    :key="i"
                    :value="item"
                  >
                    {{ item.name ? item.name : item }}
                  </option>
                </select>
                <input
                  v-else
                  v-model="elem.value"
                  :type="elem.type"
                  min="0"
                  :name="elem.title"
                  class="form-control h-7 mt-2"
                  :class="{
                    invisible: elem.type === 'none',
                    'p-0': elem.type === 'date'
                  }"
                  :placeholder="elem.title"
                  @change="filterHandler(elem)"
                />
              </td>
            </tr>
            <tr
              v-for="(elem, i) in acceptance.data"
              :key="i"
              class="text-xs font-normal hover:bg-theme-44"
              :class="{ 'bg-theme-2': i % 2 === 0 }"
            >
              <td class="text-center text-sm border-r-2 border-theme-46">
                {{ $FormatDate(elem.created_at) }}
              </td>
              <td class="text-center text-sm border-r-2 border-theme-46">
                {{ elem.consent.name }}
              </td>
              <td class="text-center text-sm border-r-2 border-theme-46">
                {{ elem.staff_user_full_name }}
              </td>

              <td class="text-center text-sm border-r-2 border-theme-46">
                <i v-if="elem.signed" class="p-3 fas fa-check text-theme-41" />
                <i v-else class="fas fa-times text-base" />
              </td>
              <td class="flex items-center gap-5 justify-center">
                <a
                  class="tooltipbutton my-2 cursor-pointer fill-current hover:text-white"
                  @click="showPdf(elem.id, elem.signed, elem.consent.name)"
                >
                  <i class="fas fa-file-pdf text-base" />
                  <span
                    class="tooltipbuttontext"
                    style="margin-top: 7px; margin-left: -20px"
                    >Visualizza PDF</span
                  >
                </a>
                <a
                  :class="
                    !elem.signed
                      ? ' tooltipbutton my-2 cursor-pointer fill-current hover:text-white'
                      : ' hidden'
                  "
                  @click="edit(elem)"
                >
                  <i class="fas fa-edit text-base" />
                  <span
                    class="tooltipbuttontext"
                    style="margin-top: 7px; margin-left: -10px"
                    >Modifica</span
                  >
                </a>
                <a
                  class="my-2 cursor-pointer fill-current hover:text-white"
                  @click="deleteRecord(elem)"
                >
                  <i class="fas fa-times text-center" />
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
    </div>
  </div>
  <!-- END: Attachments list -->

  <NewAcceptanceModal
    v-if="showNewAcceptanceModal"
    :pet-details="petDetails"
    :edit-selected="editSelected"
    @close="closeNewAcceptanceModal"
    @refresh="getAcceptance"
  />
</template>

<script>
import { defineComponent } from 'vue'
import Paginator from '../../components/paginator/Main.vue'
import THeader from '../../components/cvit-table/THeader.vue'
// import Cell from '../../components/cvit-table/Cell.vue'
import axios from '../../gateway/backend-api'
import NewAcceptanceModal from '../modals/NewAcceptanceModal.vue'

const forPageOption = [5, 10, 20]
export default defineComponent({
  components: {
    Paginator,
    THeader,
    // Cell,
    NewAcceptanceModal
  },
  props: {
    petDetails: {
      type: [Object],
      default: function () {
        return {}
      }
    }
  },
  data() {
    return {
      active_filters: {},
      acceptance: [],
      editSelected: null,
      showNewAcceptanceModal: false,
      filterActive: false,
      tableData: {
        config: [
          {
            key: 'release',
            title: 'Data'
          },
          {
            key: 'type',
            title: 'Tipo'
          },
          {
            key: 'staff_user',
            title: 'Creato da'
          },
          {
            key: 'signed',
            title: 'Firmato'
          },
          {
            key: 'action',
            title: 'Azioni rapide'
          }
        ],
        input: [
          {
            key: 'release',
            title: 'Emessa il',
            type: 'date'
          },
          {
            key: 'type',
            title: 'Tipologia',
            type: 'text'
          }
          // {
          //   key: 'staff_user',
          //   title: 'Creata da',
          //   type: 'text'
          // }
          // {
          //   key: 'signed',
          //   title: 'Firmato',
          //   type: 'select',
          //   option: [
          //     { name: 'Firmato', value: true },
          //     { name: 'Non firmato', value: false }
          //   ]
          // }
        ]
      },
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      totalRecords: 0,
      option: {
        url: 'https://httpbin.org/post',
        createImageThumbnails: true,
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        addRemoveLinks: true,
        dictCancelUpload: 'Rimuovi file',
        dictRemoveFile: 'Rimuovi file',
        dictRemoveFileConfirmation:
          'Attenzione! \nStai rimuovendo il file caricato',
        clickable: true
      }
    }
  },
  mounted() {
    this.getAcceptance()
  },
  methods: {
    getAcceptance() {
      axios
        .post('/' + localStorage.getItem('clinic') + '/pet/consents/list', {
          active_filters: this.active_filters,
          pet_id: this.petDetails.id,
          per_page: this.pagination.forPage,
          page: this.pagination.page
        })
        .then((res) => {
          this.acceptance = res.data
          this.totalRecords = res.data.total
        })
    },
    showPdf(id, signed, name) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios({
        url: '/' + localStorage.getItem('clinic') + '/pet/consents/show/' + id,
        method: 'POST',
        responseType: 'blob'
      }).then((response) => {
        this.$store.dispatch('commonComponents/hideLoadingModalAction')
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'Consenso_' + id + '.pdf')
        document.body.appendChild(link)
        this.$store.dispatch('commonComponents/openPdfModal', {
          title: name + ' ' + id,
          link: url,
          background: false,
          print: () => {
            console.log('PRINT')
          },
          download: () => {
            link.click()
          },
          xml: null,
          table_reference: 'pet_consents',
          id_reference: id,
          pdf_signed: signed,
          client: this.petDetails.owners ? this.petDetails.owners[0] : null,
          sign_done: () => {
            this.getAcceptance()
          }
        })
      })
    },
    edit(elem) {
      if (!elem.signed) {
        this.editSelected = elem
        this.showNewAcceptanceModal = true
      }
    },
    closeModal: function () {
      const modal = document.getElementById('static-backdrop-modal-preview')
      modal.classList.add('hidden')
    },
    closeNewAcceptanceModal: function () {
      this.editSelected = null
      this.showNewAcceptanceModal = false
    },
    changePagination: function (value) {
      if (this.pagination.page != value.page) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        this.getAcceptance()
      }
    },
    changeNumPages: function (elem) {
      this.pagination.forPage = elem.target.value
      this.getAcceptance()
    },
    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()

      if (this.filterActive) this.getAcceptance()
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    },
    deleteRecord(doc) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler cancellare il consenso?',
        background: false,
        confirm_text: 'Conferma',
        decline_text: 'Annulla',
        confirm: () => {
          this.$store.dispatch('commonComponents/showLoadingModalAction')
          axios
            .delete(
              '/' + localStorage.getItem('clinic') + '/pet/consents/' + doc.id
            )
            .then(() => {
              this.getAcceptance()
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
            .catch(() => {})
        },
        decline: () => {}
      })
    }
  }
})
</script>
