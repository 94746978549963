<template>
  <div class="">
    <div class="bg-white px-4 py-2 flex flex-col gap-5">
      <table class="overflow-hidden rounded-t-lg w-full">
        <THeader name="N. Fatture" class="t-header" />
        <THeader name="N. Note Credito" class="t-header" />
        <THeader name="N. Acconti" class="t-header" />
        <THeader name="N. Note Debito" class="t-header" />

        <tbody class="bg-white">
          <tr
            class="h-12 text-center text-base font-bold border border-theme-44"
          >
            <Cell :elementi="data.n_fatture" class="border-r border-theme-44" />
            <Cell :elementi="data.n_credito" class="border-r border-theme-44" />
            <Cell :elementi="data.n_acconto" class="border-r border-theme-44" />
            <Cell :elementi="data.n_debito" class="" />
          </tr>
        </tbody>
      </table>
      <table class="overflow-hidden rounded-t-lg w-full">
        <THeader name="Pagato" class="t-header" />
        <THeader name="Insoluto" class="t-header" />
        <THeader name="Da pagare" class="t-header" />

        <tbody class="bg-white">
          <tr
            class="h-12 text-center text-base font-bold border border-theme-44"
          >
            <td class="text-center border-r border-theme-44">
              <div class="p-3 flex items-center gap-2 justify-center">
                <div class="bg-green-500 text-white py-1 px-4 rounded-2xl">
                  €{{ data.pagato }}
                </div>
              </div>
            </td>

            <td class="text-center border-r border-theme-44">
              <div class="p-3 flex items-center gap-2 justify-center">
                <!-- <div class="rounded-full bg-red-400 w-4 h-4"></div> -->
                <div class="bg-red-500 text-white py-1 px-4 rounded-2xl">
                  €{{ data.insoluti }}
                </div>
              </div>
            </td>

            <td class="text-center">
              <div class="p-3 flex items-center gap-2 justify-center">
                <div
                  class="text-white py-1 px-4 rounded-2xl"
                  style="background-color: rgba(128, 0, 0, 0.6)"
                >
                  €{{ data.tot_to_pay }}
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import axios from '../../gateway/backend-api'
import THeader from '../cvit-table/THeader.vue'
import Cell from '../../components/cvit-table/Cell.vue'

export default defineComponent({
  components: { THeader, Cell },
  props: {
    customerDetails: {
      type: Object,
      default() {
        return null
      }
    }
  },
  emits: ['storePaymentStatus'],
  setup(props, { emit }) {
    const data = ref([])

    const getData = () => {
      axios
        .post('/' + localStorage.getItem('clinic') + '/bills/payment-status', {
          customer_id: props.customerDetails.id
        })
        .then((res) => {
          data.value = res.data
          emit('storePaymentStatus', res.data)
        })
    }

    return {
      data,
      getData
    }
  },
  mounted() {
    this.getData()
  }
})
</script>
