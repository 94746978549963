<template>
  <h2 class="font-medium text-lg text-center">{{ label }}</h2>
  <!-- <Chart
    type="line"
    :width="width"
    :height="height"
    :data="data"
    :options="options"
  /> -->
  <GChart
    type="LineChart"
    :data="chartData"
    :options="chartOptions"
    class="mx-auto chart-width"
  />
</template>

<script>
import { defineComponent, computed } from 'vue'
import { GChart } from 'vue-google-charts'

export default defineComponent({
  components: {
    GChart
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    lineLabel: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 0
    },
    values: {
      type: Array,
      default() {
        return []
      }
    },
    labels: {
      type: Array,
      default() {
        return ['#1', '#2', '#3', '#4']
      }
    }
  },
  setup(props) {
    const chartData = computed(() => {
      let value = []
      if (props.values) {
        value.push(['', props.lineLabel ? props.lineLabel : ''])
        for (let i = 0; i < props.values.length; i++) {
          //for (let i = props.values.length - 1; i >= 0; i--) { //grafico andava da destra a sinistra
          console.log(props.values)
          value.push(['#' + (i + 1), parseFloat(props.values[i])])
        }
      }

      return value
    })

    const chartOptions = {
      chart: {
        title: 'Company Performance',
        subtitle: 'Sales, Expenses, and Profit: 2014-2017'
      }
    }

    return {
      chartData,
      chartOptions
    }
  }
})
</script>

<style>
.chart-width {
  width: 35vw;
}

@media screen and (max-width: 768px) {
  .chart-width {
    width: 75vw;
  }
}
</style>
