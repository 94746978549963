<template>
  <!-- BEGIN: Mobile Menu -->
  <div class="mobile-menu md:hidden">
    <div class="mobile-menu-bar">
      <a href class="flex mr-auto">
        <img
          alt="logo-vanadium"
          class="w-3/12"
          src="@/assets/images/isologo.png"
        />
      </a>
      <BarChart2Icon
        class="w-8 h-8 text-white transform -rotate-90"
        @click="toggleMobileMenu"
      />
    </div>
    <transition @enter="enter" @leave="leave">
      <ul v-if="activeMobileMenu" class="py-5 mx-8">
        <!-- BEGIN: First Child -->
        <!-- <template v-for="(menu, menuKey) in formattedMenu"> -->
        <!-- <li
              v-if="menu == 'devider'"
              :key="menu + menuKey"
              class="side-nav__devider my-6"
        ></li>-->
        <li
          v-for="(menu, menuKey) in formattedMenu"
          :key="menu + menuKey"
          class="hover:text-theme-41 hover:font-semibold"
        >
          <SideMenuTooltip
            tag="a"
            :content="menu.title"
            :href="
              menu.subMenu
                ? 'javascript:;'
                : router.resolve({ name: menu.pageName }).path
            "
            class="menu text-white hover:text-theme-41 hover:font-semibold"
            :class="{
              'menu--active bg-theme-2 text-gray-800': menu.active,
              'menu--open': menu.activeDropdown
            }"
            @click="linkTo(menu, router, $event)"
          >
            <div class="menu__icon">
              <i :class="menu.icon" class="w-6 text-xl"></i>
            </div>
            <div class="menu__title">
              {{ menu.title }}
              <div
                v-if="menu.subMenu"
                class="menu__sub-icon"
                :class="{ 'transform rotate-180': menu.activeDropdown }"
              >
                <ChevronDownIcon />
              </div>
            </div>
          </SideMenuTooltip>
          <!-- BEGIN: Second Child -->
          <transition @enter="enter" @leave="leave">
            <ul v-if="menu.subMenu && menu.activeDropdown">
              <li
                v-for="(subMenu, subMenuKey) in menu.subMenu"
                :key="subMenuKey"
                class="hover:text-theme-41 hover:font-semibold"
              >
                <SideMenuTooltip
                  tag="a"
                  :content="subMenu.title"
                  :href="
                    subMenu.subMenu
                      ? 'javascript:;'
                      : router.resolve({ name: subMenu.pageName }).path
                  "
                  class="menu hover:text-theme-41 hover:font-semibold"
                  :class="[
                    subMenu.active ? '' : '',
                    subMenu.active ? 'text-theme-41' : 'text-white'
                  ]"
                  @click="linkTo(subMenu, router, $event)"
                >
                  <div class="menu__icon">
                    <!-- <component :is="subMenu.icon" /> -->
                    <i :class="subMenu.icon" class="w-6 text-xl"></i>
                  </div>
                  <div class="menu__title">
                    {{ subMenu.title }}
                    <div
                      v-if="subMenu.subMenu"
                      class="menu__sub-icon"
                      :class="{
                        'transform rotate-180': subMenu.activeDropdown
                      }"
                    >
                      <ChevronDownIcon />
                    </div>
                  </div>
                </SideMenuTooltip>
                <!-- BEGIN: Third Child -->
                <transition @enter="enter" @leave="leave">
                  <ul
                    v-if="subMenu.subMenu && subMenu.activeDropdown"
                    class="hover:text-theme-41 hover:font-semibold"
                  >
                    <li
                      v-for="(lastSubMenu, lastSubMenuKey) in subMenu.subMenu"
                      :key="lastSubMenuKey"
                    >
                      <SideMenuTooltip
                        tag="a"
                        style="color: #ffff"
                        :content="lastSubMenu.title"
                        :href="
                          lastSubMenu.subMenu
                            ? 'javascript:;'
                            : router.resolve({ name: lastSubMenu.pageName })
                                .path
                        "
                        class="menu hover:text-theme-41 hover:font-semibold"
                        :class="{ 'side-menu--active': lastSubMenu.active }"
                        @click="linkTo(lastSubMenu, router, $event)"
                      >
                        <div class="menu__icon">
                          <i :class="lastSubMenu.icon" class="w-6 text-xl"></i>
                        </div>
                        <div class="menu__title">{{ lastSubMenu.title }}</div>
                      </SideMenuTooltip>
                    </li>
                  </ul>
                </transition>
                <!-- END: Third Child -->
              </li>
            </ul>
          </transition>
          <!-- END: Second Child -->
        </li>
        <!-- </template> -->
        <!-- END: First Child -->
      </ul>
    </transition>
  </div>
  <!-- END: Mobile Menu -->
</template>

<script>
import { defineComponent, computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/store'
import { helper as $h } from '@/utils/helper'
import {
  activeMobileMenu,
  toggleMobileMenu,
  linkTo,
  enter,
  leave
} from './index'
import { nestedMenu } from '@/layouts/side-menu'

export default defineComponent({
  setup() {
    const route = useRoute()
    const router = useRouter()
    const store = useStore()
    const formattedMenu = ref([])
    const mobileMenu = computed(() =>
      nestedMenu(store.state.sideMenu.menu, route)
    )

    watch(
      computed(() => route.path),
      () => {
        formattedMenu.value = $h.toRaw(mobileMenu.value)
      }
    )

    onMounted(() => {
      formattedMenu.value = $h.toRaw(mobileMenu.value)
    })

    return {
      activeMobileMenu,
      toggleMobileMenu,
      formattedMenu,
      router,
      linkTo,
      enter,
      leave
    }
  }
})
</script>
