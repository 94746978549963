<template>
  <div class="">
    <div class="grid grid-cols-12 gap-5">
      <div class="col-span-12">
        <div
          v-if="
            permissions && permissions?.clienti?.view_customer?.enabled === true
          "
          class="post overflow-hidden"
        >
          <div
            class="post__tabs scrollbar_custom overflow-x-auto overflow-y-hidden nav nav-tabs flex-col sm:flex-row bg-theme-2 text-theme-48"
            role="tablist"
          >
            <button
              class="w-full sm:w-auto px-6 h-10 text-center flex justify-center items-center rounded-tr-xl z-50"
              :class="[
                getTab.listTab === 0 ? 'bg-theme-40' : ' bg-theme-40',
                getTab.listTab === 0 ? 'text-white' : 'text-white',
                getTab.listTab === 0
                  ? 'hover:text-theme-41'
                  : 'hover:text-theme-41'
              ]"
              @click="getList"
            >
              <i class="fas fa-user w-4 h-4 mr-2" />
              <span class="font-medium text-base whitespace-nowrap"
                >Lista Clienti</span
              >
            </button>
            <!-- <button
              v-for="(elem, index) in getPetsDetails"
              :key="index"
              class="sm:w-40 h-10 pl-2 -mx-1 text-center text-theme-48 relative flex justify-center items-center rounded-t-lg bg-theme-45 border border-theme-44 border-opacity-10 hover:font-bold hover:bg-theme-44 focus:bg-theme-44 focus:font-bold focus:z-40"
              :class="[getTab.petTab === elem.id ? 'bg-white' : 'bg-gray-400']"
              @click="getSinglePet(elem.id)"
            >
              <i class="fas fa-paw mx-2" />
              <span class="sm:w-40 truncate">{{ elem.name }}</span>
              <i
                class="fas fa-times-circle text-theme-41 absolute sm:relative sm:-top-2.5 right-2 sm:right-0"
                @click.stop="removeTabPet(index)"
              />
            </button> -->

            <!-- START TAB WITH CLIENT DETAILS -->

            <button
              v-for="(elem, index) in getCustomersDetails"
              id="client-details-tab"
              :key="index"
              class="sm:w-40 h-10 pl-2 -mx-1 text-center text-theme-42 relative flex justify-center items-center rounded-t-lg border border-theme-44 border-opacity-10 hover:font-bold hover:bg-theme-11 hover:text-white focus:bg-theme-41 focus:font-bold focus:z-40"
              :class="
                getShow.isActiveCustomer === elem.id
                  ? 'bg-theme-41 text-theme-46 z-10'
                  : 'bg-theme-45'
              "
              @click="getSingleCustomer(elem.id)"
            >
              <i
                class="fas fa-user mx-2"
                :class="getShow.isActiveCustomer === elem.id ? '' : ''"
              />
              <span
                class="sm:w-40 truncate capitalize"
                :class="
                  getShow.isActiveCustomer === elem.id ? ' font-bold' : ''
                "
                >{{ elem.ragione_sociale ?? '' }}
                {{ elem.last_name ?? '' }}
                {{ elem.first_name ?? '' }}
              </span>
              <i
                class="fa fa-times absolute sm:relative sm:-top-2.5 right-2 sm:right-0 pr-4 pt-2"
                @click.stop="removeTabCustomer(index)"
              />
            </button>
          </div>

          <!-- END TAB WITH CLIENT DETAILS -->

          <div
            v-if="getShow.isActive === 0"
            class="post__content tab-content bg-white rounded-b-lg overflow-auto scrollbar_custom anagrafcustomer-cont"
          >
            <div class="rounded-md p-5">
              <div class="box mt-1">
                <div id="responsive-table-list">
                  <div class="paginator-container">
                    <div class="prova mr-5 my-2 flex justify-between">
                      <div class="mt-1">
                        <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
                          >Mostra
                        </span>
                        <select
                          id=""
                          v-model="pagination.forPage"
                          name=""
                          class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
                          @change="(event) => changeNumPages(event)"
                        >
                          <option
                            v-for="show in forPageOption"
                            :key="show"
                            :value="show"
                            class="form-select"
                          >
                            {{ show }}
                          </option>
                        </select>
                        <span class="text-md font-bold ml-2 mt-1">
                          elementi</span
                        >
                      </div>
                    </div>
                    <Paginator
                      :for-page-option="pagination.forPage"
                      :total-records="totalRecords"
                      @changePagination="changePagination"
                    />
                  </div>
                  <div class="scrollbar_custom pb-5">
                    <table
                      class="overflow-hidden border-b border-gray-200 sm:rounded-lg"
                    >
                      <THeader
                        v-for="(elem, index) in config"
                        :key="index"
                        class="t-header"
                        :name="elem.title"
                      />
                      <!-- <Cell
                          class="border-b-2 border-theme-13 font-bold bg-theme-33"
                        /> -->

                      <tbody>
                        <tr>
                          <td
                            v-for="(elem, index) in input"
                            :key="index"
                            class="border-b whitespace-nowrap p-2 py-4"
                          >
                            <select
                              v-if="elem.type === 'select'"
                              v-model="elem.value"
                              placeholder="Seleziona"
                              class="block px-2 py-1 border-2 border-gray-200 rounded-lg focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500 flex-1 bg-white"
                              @change="filterHandler(elem)"
                            >
                              <option :value="null"></option>
                              <option
                                v-for="(item, i) in elem.option"
                                :key="i"
                                :value="item.value"
                              >
                                {{ item.name ? item.name : item }}
                              </option>
                            </select>

                            <input
                              v-else
                              v-model="elem.value"
                              :type="elem.type"
                              :name="elem.title"
                              class="block px-2 py-1 border-2 border-gray-200 rounded-lg w-full focus:outline-none focus:ring-1 focus:ring-gray-500 placeholder-gray-500"
                              :class="{ invisible: elem.type === 'none' }"
                              :placeholder="elem.title"
                              @change="filterHandler(elem)"
                            />
                          </td>
                        </tr>

                        <tr
                          v-for="(item, index) in customers"
                          :key="index"
                          :class="{ 'bg-theme-2': index % 2 === 0 }"
                          class="row-custom hover:bg-theme-45"
                        >
                          <td
                            class="border-b border-l whitespace-nowrap text-left py-3 px-1"
                          >
                            <div class="flex items-center px-2 gap-2">
                              <div
                                class="tooltipbutton"
                                @click="getCustomer(item)"
                              >
                                <span class="tooltipbuttontext ml-7 mb-1"
                                  >Apri Anagrafica</span
                                >
                                <ChevronsRightIcon
                                  class="h-4 w-4 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                                />
                              </div>

                              <div class="flex-1">
                                {{ item.ragione_sociale ?? '' }}
                                {{ item.first_name ?? '' }}
                              </div>
                            </div>
                          </td>

                          <td
                            class="border-b border-l whitespace-nowrap text-left py-3 px-1"
                          >
                            <div class="flex items-center px-2">
                              <div class="flex-1">
                                {{ item.last_name ? item.last_name : '--' }}
                              </div>
                            </div>
                          </td>

                          <td
                            class="border-b border-l whitespace-nowrap text-left py-3 px-1"
                          >
                            <div class="flex items-center px-2">
                              <div class="flex-1">
                                {{
                                  item.type
                                    ? item.type == 'PA'
                                      ? 'Pubb. Amministrazione'
                                      : item.type
                                    : '--'
                                }}
                              </div>
                            </div>
                          </td>

                          <td
                            v-if="
                              item.phones &&
                              item.phones.phone1 &&
                              item.phones.phone1.phone
                            "
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{ item.phones.phone1.prefisso }}

                            {{ item.phones.phone1.phone }}
                          </td>

                          <td
                            v-else
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            --
                          </td>

                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{
                              item.emails &&
                              item.emails.email1 &&
                              item.emails.email1.email
                                ? item.emails.email1.email
                                : '--'
                            }}
                          </td>

                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{ item.fiscal_code }}
                          </td>

                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{
                              item.type == 'Persona giuridica' &&
                              item.vat_number &&
                              typeof item.vat_number == 'object'
                                ? item.vat_number.vat_number
                                : ''
                            }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{ item.address.address }}

                            {{ item.address.house_number }}
                          </td>

                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{ item.address.city }}
                          </td>
                          <td
                            class="border-b border-l whitespace-nowrap text-center px-3 py-3"
                          >
                            {{ item.address.zip_code }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="(item, index) in getCustomersDetails"
            v-show="getShow.isActiveCustomer === item.id"
            :key="index"
            class="post__content tab-content"
          >
            <div class="rounded-xl">
              <div>
                <div id="">
                  <div class="preview">
                    <div
                      class="bg-white overflow-auto scrollbar_custom customermain-cont rounded-b-lg"
                    >
                      <customerDetails
                        :key="keyCustomerDetails"
                        :customer-details="item"
                        :active="getShow.isActiveCustomer === item.id"
                        @getPet="getPet"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-else-if="
            permissions &&
            permissions?.clienti?.view_customer?.enabled === false
          "
        >
          <h1>I permessi per visualizzare i clienti non sono abilitati.</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
// import _ from 'lodash'
import Toastify from 'toastify-js'
// import petDetails from '../../components/details-pet/PetMain.vue'
import customerDetails from '../../components/details-customer/CustomerMain.vue'
import Paginator from '../../components/paginator/Main.vue'
import THeader from '../../components/cvit-table/THeader.vue'
// import Cell from '../../components/cvit-table/Cell.vue'
import axios from '../../gateway/backend-api'
import { mapGetters, mapActions } from 'vuex'
// import LoadingIconModal from '../../components/modals/LoadingIconModal.vue'
const forPageOption = [10, 50, 100]
export default defineComponent({
  components: {
    // petDetails,
    // LoadingIconModal,
    customerDetails,
    THeader,
    // Cell,
    Paginator
  },
  data() {
    return {
      keyCustomerDetails: 0,
      active_filters: {},
      filterActive: false,
      customersFiltered: null,
      config: [
        {
          key: 'first_name',
          title: 'Nome  / Rag. Soc.'
        },
        {
          key: 'last_name',
          title: 'Cognome'
        },
        {
          key: 'type',
          title: 'Tipologia'
        },
        {
          key: 'phone',
          title: 'Telefono'
        },
        {
          key: 'email',
          title: 'Mail'
        },
        {
          key: 'fiscal_code',
          title: 'Codice fiscale'
        },
        {
          key: 'vat_number',
          title: 'P. Iva'
        },
        {
          key: 'adress',
          title: 'Indirizzo'
        },
        {
          key: 'city',
          title: 'Città'
        },
        {
          key: 'zip_code',
          title: 'CAP'
        }
      ],
      input: [
        {
          key: 'first_name',
          title: 'Nome',
          type: 'text'
        },
        {
          key: 'last_name',
          title: 'Cognome',
          type: 'text'
        },
        {
          key: 'type',
          title: 'Tipologia',
          type: 'select',
          option: [
            { name: 'P. Fisica', value: 'Persona fisica' },
            { name: 'P. Giuridica', value: 'Persona giuridica' },
            { name: 'Pubb. Amm.', value: 'PA' }
          ]
        },
        {
          key: 'phone',
          title: 'Telefono',
          type: 'text'
        },
        {
          key: 'email',
          title: 'Mail',
          type: 'text'
        },
        {
          key: 'fiscal_code',
          title: 'Codice fiscale',
          type: 'text'
        },
        {
          key: 'vat_number',
          title: 'P. Iva',
          type: 'text'
        },
        {
          key: 'address',
          title: 'Indirizzo',
          type: 'text'
        },
        {
          key: 'city',
          title: 'Città',
          type: 'text'
        },
        {
          key: 'zip_code',
          title: 'CAP',
          type: 'text'
        }
      ],
      customers: '',
      totalRecords: '',
      isLoading: false,
      forPageOption,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      }
    }
  },
  computed: {
    ...mapGetters({
      showLoadingModal: 'commonComponents/getShowLoadingModal',
      // getPetsDetails: 'anagraficaClienti/getPetsDetails',
      getComparisonPets: 'anagraficaClienti/getComparisonPets',
      getCustomersDetails: 'anagraficaClienti/getCustomersDetails',
      getComparisonCustomer: 'anagraficaClienti/getComparisonCustomer',
      getTab: 'anagraficaClienti/getTab',
      getShow: 'anagraficaClienti/getShow',
      permissions: 'reusableData/getPermissions'
    })
  },
  watch: {
    'getShow.isActive': function (elem) {
      if (elem == 0) this.searchAPI()
    }
  },
  mounted() {
    this.getCustomersApiList()
  },
  methods: {
    ...mapActions({
      getListAction: 'anagraficaClienti/getListAction',
      getPetAction: 'anagraficaClienti/getPetAction',
      getSinglePetAction: 'anagraficaClienti/getSinglePetAction',
      getCustomerAction: 'anagraficaClienti/getCustomerAction',
      goToOpenedClient: 'anagraficaClienti/goToOpenedClient',
      getSingleCustomerAction: 'anagraficaClienti/getSingleCustomerAction',
      removeTabPetAction: 'anagraficaClienti/removeTabPetAction',
      removeTabCustomerAction: 'anagraficaClienti/removeTabCustomerAction'
    }),
    refreshCustomerData() {
      this.keyCustomerDetails += 1
    },

    // per il dettaglio di queste funzioni vedi nel file store/mutations/anagrafica-pazienti
    getList: function () {
      this.getListAction()
    },
    getCustomer: function (elem) {
      if (
        //this.getCustomersDetails.length < 7 &&
        !this.getComparisonCustomer.includes(elem.id)
      ) {
        this.getCustomerAction(elem)
      } else if (this.getComparisonCustomer.includes(elem.id)) {
        this.goToOpenedClient(elem.id)
        this.tabAlreadyOpen()
      } else {
        this.maxTabOpen()
      }
    },
    getSingleCustomer: function (elem) {
      this.getSingleCustomerAction(elem)
      this.refreshCustomerData()
    },
    getPet: function (elem) {
      axios.post('/pet/' + elem).then((res) => {
        const pet = res.data
        if (
          this.getPetsDetails.length < 2 &&
          !this.getComparisonPets.includes(pet.id)
        ) {
          this.getPetAction(pet)
        } else if (this.getComparisonPets.includes(pet.id)) {
          this.tabAlreadyOpen()
        } else {
          this.maxTabOpen()
        }
      })
    },
    getSinglePet: function (elem) {
      this.getSinglePetAction(elem)
    },
    removeTabPet: function (index) {
      this.removeTabPetAction(index)
    },
    removeTabCustomer: function (index) {
      this.removeTabCustomerAction(index)
    },
    // funzione che fa apparire pop-up quando superi il numero di tab che si possono aprire
    maxTabOpen: function () {
      Toastify({
        node: cash('#max-tab-open').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    },
    // funzione che fa apparire pop-up quando provi ad aprire una tab già aperta
    tabAlreadyOpen: function () {
      Toastify({
        node: cash('#tab-already-open').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    },
    filterHandler(elem) {
      console.log('elem', elem)
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()
      if (this.filterActive) this.searchAPI()
      else this.searchApi()
    },
    searchAPI() {
      // this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/search/searchCustomer', {
          toSearch: this.active_filters,
          per_page: this.pagination.forPage,
          page: this.pagination.page
        })
        .then((res) => {
          // this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.customers = res.data.customers.data.items
          this.totalRecords = res.data.customers.total
        })
    },
    changeNumPages: function (elem) {
      this.pagination.forPage = elem.target.value
      this.searchAPI()
    },
    getCustomersApiList: function () {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      this.customers = []

      axios
        .get(
          '/' +
            localStorage.getItem('clinic') +
            '/customer?per_page=' +
            this.pagination.forPage +
            '&page=' +
            this.pagination.page
        )
        .then((res) => {
          this.customers = res.data.data
          this.totalRecords = res.data.total
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    // funzione che da l'input al componente paginatore per cambiare pagina
    changePagination: function (value) {
      if (this.pagination.page != value.page) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        if (!this.filterActive) this.getCustomersApiList()
        else this.searchAPI()
      }
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    }
  }
})
</script>
