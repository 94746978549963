<template>
  <!-- Main modal -->
  <div
    id="defaultModal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none s"
  >
    <div
      class="relative top-12 p-5 mx-auto overflow-scroll scrollbar-none border w-4/5 sm:w-3/5 lg:w-2/5 shadow-lg rounded-md bg-white"
    >
      <button type="button" class="closing-btn" @click="handleClose">
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          ></path>
        </svg>
      </button>
      <!-- Modal content -->
      <div class="flex flex-col content-end overflow-y-auto">
        <!-- Modal header -->
        <div class="">
          <h3 class="modal-title mt-6">
            Invia {{ data && data.type ? data.type : 'Comunicazione' }}
          </h3>
        </div>
        <!-- Modal body -->
        <div class="px-6 py-3 flex flex-col gap-2">
          <div class="flex flex-col gap-0.5">
            <label class="form-label">Destinatario: </label>

            <v-select
              v-model="destinatario"
              :options="destinatarioOptions"
              label="email"
              taggable
              placeholder="Seleziona destinatario"
            >
              <!-- <template #selected-option="option">
                {{ option.first_name }} {{ option.last_name }}
              </template>
              <template #option="option">
                {{ option.first_name }} {{ option.last_name }}
              </template> -->
            </v-select>
          </div>

          <div
            v-if="!data?.type"
            class="flex items-center justify-end gap-3 mt-2"
          >
            <div class="flex items-center gap-3">
              <label class="form-label">Email: </label>
              <input
                v-model="emailCheckbox"
                type="checkbox"
                class="form-check-switch mb-2"
                required
                :value="emailCheckbox"
              />
            </div>

            <div class="flex items-center gap-3">
              <label class="form-label">SMS: </label>
              <input
                v-model="smsCheckbox"
                type="checkbox"
                class="form-check-switch mb-2"
                required
                :value="smsCheckbox"
              />
            </div>
          </div>

          <!-- <v-select
                ref="input1"
                class="col-span-10"
                v-on:keyup.enter="switchFocus(2)"
                multiple
                v-model="destinatario"
                placeholder="Selezionare il destinatario"
                taggable
                :options="['assistenza@vanadium.com', 'cliente@mail.com']"
              /> -->

          <div v-if="false" class="flex flex-col gap-0.5">
            <label for="" class="form-label">Template:</label>

            <select
              v-model="selectedTemplate"
              name=""
              id=""
              class="custom-input"
            >
              <option v-for="show in templates" :key="show" :value="show">
                {{ show.name }}
              </option>
            </select>
          </div>

          <div class="flex flex-col gap-0.5">
            <label class="form-label">CC: </label>
            <v-select
              v-model="cc"
              :options="[]"
              label="email"
              taggable
              multiple
              :components="{OpenIndicator: {render: null},}"
            >
            </v-select>
          </div>

          <div class="flex flex-col gap-0.5">
            <label class="form-label">Oggetto: </label>
            <input
              ref="input2"
              v-model="titolo"
              type="text"
              class="custom-input"
              required
            />
          </div>
          <div class="flex flex-col gap-0.5">
            <label class="form-label">Corpo della mail: </label>
            <QuillEditor
              ref="input3"
              theme="snow"
              contentType="html"
              class="form-control h-40"
              v-model:content="message"
            />
          </div>

          <div v-if="false" class="flex flex-row justify-end">
            <button @click="$refs.file.click()" type="file" class="orange-btn">
              <PaperclipIcon class="h-5 w-5 mr-2" /> Aggiungi Allegato
            </button>
            <input
              type="file"
              ref="file"
              class="form-control p-2 hidden"
              required
              @change="handleFileChange"
            />
          </div>
          <div v-if="data.attachements" class="table-container">
            <table class="w-full">
              <thead class="h-8 t-header">
                <tr>
                  <th v-for="(item, itemKey) in allegatiConfig" :key="itemKey">
                    {{ item }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(doc, docKey) in allegati"
                  :key="doc.id"
                  class="h-12 text-xs font-normal hover:bg-theme-44 cursor-pointer"
                  :class="{ 'bg-theme-45': docKey % 2 === 0 }"
                >
                  <td
                    v-for="c in doc"
                    :key="c"
                    class="text-center border-r border-gray-500"
                  >
                    {{ c }}
                  </td>
                  <td class="text-center border-r border-gray-500">
                    <input
                      type="checkbox"
                      :value="doc"
                      v-model="selectedToSend"
                    />
                  </td>
                </tr>
                <tr
                  v-for="(doc, docKey) in selectedFile"
                  :key="doc.id"
                  class="h-12 text-sm font-normal hover:bg-theme-44 cursor-pointer"
                  :class="{ 'bg-theme-45': docKey % 2 === 0 }"
                >
                  <td class="text-center border-r border-gray-500">
                    {{ doc.name }}
                  </td>
                  <td v-if="false" class="text-center border-r border-gray-500">
                    {{
                      doc.lastModified
                        ? formatdate(doc.lastModified)
                        : doc.date
                        ? formatdate(doc.date)
                        : '- -'
                    }}
                  </td>
                  <td class="text-center border-r border-gray-500">
                    <button
                      type="button"
                      class="fill-current hover:text-theme-41"
                      @click="removeFile(doc)"
                    >
                      <svg
                        class="w-5 h-5"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="false" class="flex items-baseline gap-3 mt-2">
            <label class="form-label"> Staff user: </label>
            <p>Sono un Staff user</p>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="flex justify-end px-6 gap-3">
          <button
            @click="handleConfirm"
            type="button"
            class="orange-btn w-20 flex items-center gap-2"
            ref="input4"
          >
            Invia
          </button>
          <button @click="handleClose" type="button" class="gray-btn">
            <span class="h-5"></span>
            Annulla
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useStore } from 'vuex'
import axios from '../../gateway/backend-api'
import _ from 'lodash'
import moment from 'moment'

export default defineComponent({
  props: ['elem', 'data'],
  emits: ['confirm'],
  setup(props, { emit }) {
    const store = useStore()
    const searchTicket = ref(0)
    const selectedFile = ref([])
    const message = ref('')
    const titolo = ref('')
    const cc = ref('')
    const destinatario = ref([])
    const emailCheckbox = ref(true)
    const smsCheckbox = ref(false)

    const destinatarioOptions = ref([])
    if (props.data?.client && props.data.client.emails) {
      if (typeof props.data.client.emails === 'string') {
        Object.keys(JSON.parse(props.data.client.emails)).forEach((el) => {
          destinatarioOptions.value.push(
            JSON.parse(props.data.client.emails)[el]['email']
          )
        })
      } else {
        Object.keys(props.data.client.emails).forEach((el) => {
          destinatarioOptions.value.push(props.data.client.emails[el]['email'])
        })
      }
    }
    const templates = [
      {
        name: 'Nessun template',
        cc: '',
        titolo: '',
        message: ''
      },
      {
        name: 'Attesa',
        cc: '',
        titolo: 'Informazione inerente risultati visite',
        message:
          'Buongiorno, purtroppo la elaborazione dei risultati delle visite sta durando più del previsto, le chiediamo di attendere ancora qualche giornata. Cordialmente '
      },
      {
        name: 'Richiesta documenti',
        cc: '',
        titolo: 'Richiesta documenti aggiuntivi',
        message:
          'Buongiorno, cordialmente le chiediamo di inviarci il codice fiscale per poter concludere le pratiche iniziate. Cordialmente'
      },
      {
        name: 'Risultati pronti',
        cc: '',
        titolo: 'Ritiro risultati analisi',
        message:
          'Buongiorno, i risultati delle analisi sono pronti, e da domani può passare a ritirarli!'
      }
    ]
    // const allegati = ref([
    //   {
    //     name: 'Prima visita.pdf',
    //     date: '02/12/2021'
    //   },
    //   {
    //     name: 'Diagnosi.pdf',
    //     date: '02/01/2022'
    //   }
    // ])

    const allegatiConfig = ['Nome', 'Da inviare'] //['Nome', 'Data', 'Da inviare']
    const selectedTemplate = ref(templates[0])
    const selectedToSend = []
    if (props.data && props.data.attachements) {
      props.data.attachements.forEach((el) => {
        selectedFile.value.push(el)
      })
    }

    const handleFileChange = (e) => {
      selectedFile.value.push(e.target.files[0])
    }

    const removeFile = (elem) => {
      const temp = selectedFile.value.filter((e) => {
        return e.name !== elem.name
      })
      selectedFile.value = temp
    }

    const formatdate = (epoch) => {
      return moment.unix(epoch).format('DD/MM/YYYY')
    }

    const handleConfirm = () => {
      if (
        !titolo.value ||
        titolo.value === '' ||
        !message.value ||
        message.value === '' ||
        !destinatario.value
      ) {
        store.dispatch('commonComponents/openNotificationModal', {
          message: 'Si prega di inserire tutti i dati'
        })
        return
      }
      if(cc.value.length === 0) cc.value = null
      emit('confirm', {
        titolo: titolo.value,
        cc: cc.value,
        message: message.value,
        selectedFile: selectedFile.value,
        destinatario: destinatario.value
      })
    }

    return {
      selectedFile,
      titolo,
      cc,
      message,
      templates,
      emailCheckbox,
      smsCheckbox,
      allegatiConfig,
      handleFileChange,
      selectedTemplate,
      selectedToSend,
      removeFile,
      formatdate,
      destinatario,
      destinatarioOptions,
      searchTicket,
      handleConfirm
    }
  },
  mounted() {
    // this.$refs.input1.$refs.search.focus()
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    switchFocus(value) {
      switch (value) {
        case 1:
          this.$refs.input1.$refs.search.focus()
          break
        case 2:
          this.$refs.input2.focus()
          break
        case 3:
          this.$refs.input3.focus()
          break
        case 4:
          this.$refs.input4.focus()
          break
      }
    },
    searchDestinatario(search, loading) {
      if (search.length > 1) {
        loading(true)
        this.search(loading, search, this)
      } else {
        this.destinatarioOptions = []
      }
    },
    search: _.debounce((loading, search, vm) => {
      axios
        .post('/' + localStorage.getItem('clinic') + '/search/globalSearch', {
          searchText: search,
          ticket: ++this.searchTicket,
          filterParameter: ['customer']
        })
        .then((res) => {
          if (res.data.ticket == vm.searchTicket) {
            vm.destinatarioOptions = res.data.customers.data.items
            loading(false)
          }
        })
    }, 350)
  },
  watch: {
    selectedTemplate: function (elem) {
      this.titolo = elem.titolo
      this.$refs.input3.setHTML(elem.message)
    }
  }
})
</script>
