<template>
  <!-- START Dialog Box Container-->
  <div
    id="confirm-delete-modal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50"
  >
    <div
      class="relative top-24 mx-auto p-4 overflow-scroll scrollbar-none border sm:w-3/5 w-full shadow-lg rounded-md bg-white"
    >
      <div class="flex flex-col gap-3">
        <h3 class="modal-title">Cancellare Appuntamento</h3>

        <div class="flex items-center">
          <div class="w-3/12 flex-0">
            <div class="flex justify-center">
              <svg
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="exclamation-triangle"
                class="w-28 h-28 fill-current text-theme-41"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 576 512"
              >
                <path
                  fill="currentColor"
                  d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                ></path>
              </svg>
            </div>
          </div>
          <div class="w-9/12 flex justify-start">
            <div class="flex flex-col items-center">
              <div class="text-2xl text-theme-43 mt-3">
                Sei sicuro di cancellare questo appuntamento?
              </div>
              <div class="flex items-center gap-3 mt-5">
                <button
                  id="dialogbox-delete-button"
                  type="button"
                  class="orange-btn flex gap-1 items-center"
                  @click="removeEvent"
                >
                  <i class="fas fa-trash fill-current"></i>Si, cancella
                </button>

                <button
                  id="dialogbox-anulla-button"
                  autofocus
                  type="button"
                  class="gray-btn"
                  @click="closeConfirm"
                >
                  Annulla
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="sm:flex sm:items-start">
    <div class="mt-3 pb-2 sm:mt-0 sm:ml-4 sm:text-left">
      <h3 id="modal-title" class="text-lg font-medium">
        Cancellare Appuntamento
      </h3>
      <div class="mt-2">
        <p class="text-sm text-gray-500">
          Sei sicuro di cancellare questo appuntamento?
        </p>
      </div>
    </div>
  </div>

  <div
    class="border-t border-gray-200 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
  ></div>
  <div class="mt-2"></div>
</template>
<script>
export default {
  props: ['title', 'description'],

  methods: {
    closeConfirm() {
      this.$emit('closeconfirm')
    },
    removeEvent() {
      this.$emit('removeEvent')
    }
  }
}
</script>
