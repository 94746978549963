<template>
  <div
    class="p-5 text-center content-container grid grid-cols-2 gap-5 px-6 overflow-auto scrollbar_custom"
    style="align-content: flex-start"
  >
    <!--<div class="col-span-2 flex gap-2 items-center justify-end">
      <span class="text-md ml-2 font-bold mr-2 mt-1"
        >Periodo di riferimento
      </span>

      <select
        class="custom-input w-40 text-left px-1"
        @change="(event) => changeStats(event)"
      >
        <option v-for="show in period" :key="show" :value="show.value">
          {{ show.title }}
        </option>
      </select>
    </div>-->
    <div class="col-span-2 flex gap-2 items-center justify-end">
      <span class="text-md ml-2 font-bold mr-2 mt-1"
        >Periodo di riferimento
      </span>
      <div class="flex gap-2 text-left mt-1">
        <div class="flex items-baseline">
          <span class="mr-2 font-bold">Da: </span
          ><input
            v-model="startDate"
            class="custom-input"
            type="date"
            @change="changeStats"
          />
        </div>
        <div class="flex items-baseline">
          <span class="mr-4 font-bold">A: </span
          ><input
            v-model="endDate"
            class="custom-input"
            type="date"
            @change="changeStats"
          />
        </div>
      </div>
    </div>
    <div
      class="mt-5 bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-30 p-2 col-span-2"
    >
      <h1 class="stats-title">Statistiche Clienti</h1>
      <div>
        <div class="grid grid-cols-4 gap-5 mt-5">
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <div class="stats-subs">
              <div class="tooltiptopstats">
                <label class="mb-0 mr-1">Nuovi Clienti</label>
                <button type="button ml-2">
                  <InfoIcon class="w-4 mb-1" />
                </button>
                <span class="tooltiptext" style="font-size: small"
                  >Si intendono i nuovi clienti acquisiti nel periodo di
                  riferimento</span
                >
              </div>
            </div>
            <div class="stats-number">
              {{ customerStats['count_new_customers'] }}
            </div>
          </div>
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <!--<h1 class="stats-subs">Nuovi clienti nell'esercizio</h1>-->
            <div class="stats-subs">
              <div class="tooltiptopstats">
                <label class="mb-0 mr-1">Nuovi clienti nell'esercizio</label>
                <button type="button ml-2">
                  <InfoIcon class="w-4 mb-1" />
                </button>
                <span class="tooltiptext" style="font-size: small"
                  >Si intendono tutti i clienti acquisiti dall'inizio dell'anno
                  corrente</span
                >
              </div>
            </div>
            <div class="stats-number">
              {{ customerStats['count_new_customers_current_year'] }}
            </div>
          </div>
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <div class="stats-subs">
              <div class="tooltiptopstats">
                <label class="mb-0 mr-1">Clienti attivi %</label>
                <button type="button ml-2">
                  <InfoIcon class="w-4 mb-1" />
                </button>
                <span class="tooltiptext" style="font-size: small"
                  >Si intende la percentuale di clienti con almeno una fattura a
                  carico</span
                >
              </div>
            </div>
            <div class="stats-number">
              {{ customerStats['perc_count_customer_with_bill'] }}%
            </div>
          </div>
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <div class="stats-subs">
              <div class="tooltiptopstats">
                <label class="mb-0 mr-1">Clienti Inattivi %</label>
                <button type="button ml-2">
                  <InfoIcon class="w-4 mb-1" />
                </button>
                <span class="tooltiptext" style="font-size: small"
                  >Si intende la percentuale di clienti senza fatture a
                  carico</span
                >
              </div>
            </div>
            <div class="stats-number">
              {{ customerStats['per_count_customer_with_no_bill'] }}%
            </div>
          </div>
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <div class="stats-subs">
              <div class="tooltiptopstats">
                <label class="mb-0 mr-1">Valore medio cliente</label>
                <button type="button ml-2">
                  <InfoIcon class="w-4 mb-0.5" />
                </button>
                <span class="tooltiptext" style="font-size: small"
                  >Si intende il valore totale delle fatture diviso per il
                  numero di clienti attivi (quindi con almeno una fattura a
                  carico) nel periodo di riferimento</span
                >
              </div>
            </div>
            <div class="stats-number">
              {{ customerStats['avg_fatturato_customer_active'] }}
            </div>
          </div>
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <div class="stats-subs">
              <div class="tooltiptopstats">
                <label class="mb-0 mr-1">Media prestazioni in fattura</label>
                <button type="button ml-2">
                  <InfoIcon class="w-4 mb-0.5" />
                </button>
                <span class="tooltiptext" style="font-size: small"
                  >Si intende la media del numero di prestazioni inserite nelle
                  fatture nel periodo di riferimento</span
                >
              </div>
            </div>
            <div class="stats-number">
              {{ customerStats['avg_prestazioni_n_fatture_tot'] }}
            </div>
          </div>
          <div
            class="bg-white rounded-md shadow-sm border-2 border-theme-44 border-opacity-20 col-span-2 md:col-span-1 p-2"
          >
            <div class="stats-subs">
              <div class="tooltiptopstats">
                <label class="mb-0 mr-1">Valore medio prestazione</label>
                <button type="button ml-2">
                  <InfoIcon class="w-4 mb-1" />
                </button>
                <span class="tooltiptext" style="font-size: small"
                  >Si intende l'importo totale in fattura diviso per il numero
                  di prestazioni totali</span
                >
              </div>
            </div>
            <div class="stats-number">
              {{ customerStats['avg_fatturato_n_transazioni'] }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
import moment from 'moment'
import axios from '../../gateway/backend-api'

const period = [
  { title: 'Ultimo mese', value: 1 },
  { title: 'Ultimo trimestre', value: 3 },
  { title: 'Ultimo anno', value: 12 }
]

const chartColOptions = {
  chart: {
    title: 'Company Performance',
    subtitle: 'Sales, Expenses, and Profit: 2014-2017'
  },
  width: 600,
  height: 400,
  colors: [
    '#059393',
    '#CA6715',
    '#1B4B77',
    '#F7CD65',
    '#9FD5DA',
    '#EF9C02',
    '#6295D3'
  ]
}

const chartBarOptions = {
  width: 400,
  height: 240,
  title: 'My amazing company performance',
  colors: [
    '#059393',
    '#CA6715',
    '#1B4B77',
    '#F7CD65',
    '#9FD5DA',
    '#EF9C02',
    '#6295D3'
  ]
}

export default defineComponent({
  components: {},
  setup() {},

  data() {
    return {
      chartColOptions,
      chartBarOptions,
      period,
      customerStats: {},
      startDate: null,
      endDate: null
    }
  },
  mounted() {
    let b = new Date()
    let a = new Date(b.getFullYear(), b.getMonth(), 1)

    this.startDate = moment(a).format('YYYY-MM-DD')
    this.endDate = moment(b).format('YYYY-MM-DD')
    this.getStatsCustomer()
  },
  methods: {
    getStatsCustomer() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')

      axios
        .post('/' + localStorage.getItem('clinic') + '/report/customer', {
          startDate: this.$FormatEpochEnglish(this.startDate),
          endDate: this.$FormatEpochEnglish(this.endDate),
          timezone: 'GMT' + moment().format('Z')
        })
        .then((resp) => {
          this.customerStats = resp.data
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    changeStats: function () {
      this.getStatsCustomer()
    },

    animateValue(obj, start, end, duration) {
      let startTimestamp = null
      const step = (timestamp) => {
        if (!startTimestamp) startTimestamp = timestamp
        const progress = Math.min((timestamp - startTimestamp) / duration, 1)
        obj.innerHTML = Math.floor(progress * (end - start) + start)
        if (progress < 1) {
          window.requestAnimationFrame(step)
        }
      }
      window.requestAnimationFrame(step)
    }
  }
})
</script>
