<template>
  <div
    id="new-appointment-modal"
    class="fixed z-50 inset-0 overflow-y-auto transition-opacity bg-black bg-opacity-50 scrollbar-none"
  >
    <!-- Modal body -->

    <div
      class="relative top-12 px-6 py-8 h-auto mx-auto overflow-scroll scrollbar-none border w-3/5 shadow-lg rounded-md bg-white"
    >
      <button
        type="button"
        class="absolute top-0 right-0 my-3 mx-6 text-gray-400 bg-transparent p-1 hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
        @click="closeReferente"
      >
        <svg
          class="w-5 h-5"
          fill="currentColor"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill-rule="evenodd"
            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
            clip-rule="evenodd"
          />
        </svg>
      </button>

      <!-- Modal Header -->

      <div class="mx-auto justify-center my-1 bg-theme-38 rounded-md p-3 mt-5">
        <h2 class="font-bold text-center text-xl text-white">
          {{ external ? 'Modifica ' + title : 'Aggiungi un nuovo ' + title }}
        </h2>
      </div>

      <!-- end Modal Header -->

      <!-- Section Modal header -->
      <div class="">
        <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
          <h2 class="ml-3 text-base font-bold">Dati {{ title }}</h2>
        </div>
        <!-- first row -->

        <div class="mx-3 flex flex-col justify-between gap-3 md:flex-row">
          <div class="flex-1 py-2">
            <label
              class="block text-gray-700 text-xs font-bold mb-2"
              for="newReferent-lastName"
            >
              Cognome/Denominazione
            </label>
            <input
              v-model="formData.last_name"
              id="newReferent-lastName"
              class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
              :class="{
                notapproved:
                  !inputcontrol.last_name || !inputcontrol.last_name_length
              }"
              type="text"
              placeholder="Inserisci il cognome o denominazione"
            />
            <span v-if="!inputcontrol.last_name" class="text-theme-41 mt-1">
              {{ error_message }}</span
            >
            <span
              v-if="!inputcontrol.last_name_length"
              class="text-theme-41 mt-1"
            >
              Il cognome deve avere almeno 2 caratteri</span
            >
          </div>
          <div class="flex-1 py-2">
            <label
              class="block text-gray-700 text-xs font-bold mb-2"
              for="newReferent-name"
            >
              Nome
            </label>
            <input
              v-model="formData.first_name"
              id="newReferent-name"
              class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
              :class="{
                notapproved:
                  !inputcontrol.first_name || !inputcontrol.first_name_length
              }"
              type="text"
              placeholder="Inserisci il nome"
            />
            <span v-if="!inputcontrol.first_name" class="text-theme-41 mt-1">
              {{ error_message }}</span
            >
            <span
              v-if="!inputcontrol.first_name_length"
              class="text-theme-41 mt-1"
            >
              Il nome deve avere almeno 2 caratteri</span
            >
          </div>
          <div class="flex-1 py-2" v-if="title != 'referente'">
            <label
              class="block text-gray-700 text-xs font-bold mb-2"
              for="newReferent-type"
            >
              Tipo
            </label>
            <v-select
              v-model="formData.type"
              class="shadow border border-gray-300 rounded w-full px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
              id="newReferent-type"
              placeholder="Seleziona il tipo di referente"
              :options="['Medico', 'Clinica']"
            />
          </div>
        </div>

        <div class="">
          <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
            <h2 class="ml-3 text-base font-bold">Telefono</h2>
          </div>
          <div class="mx-3 items-center">
            <input
              v-model="checkPhone"
              type="checkbox"
              class="form-checkbox mr-1 h-3 w-3 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
              @click="provaFunction"
            />
            <label class="text-gray-700 text-xs font-semibold"
              >Non ho un telefono</label
            >
            <!-- <p>{{ checkPhone }}</p> -->
          </div>
          <!-- first row -->

          <div
            v-for="(elem, elemKey) in phoneConfig"
            :key="elemKey"
            class="mx-3 flex flex-col justify-between gap-3 mt-3 lg:flex-row"
          >
            <div class="flex-1 py-2">
              <label
                for="newclient-telefono"
                class="block text-gray-700 text-xs font-bold mb-2"
              >
                {{ elem.name }}
              </label>

              <div class="flex">
                <input
                  id="newclient-telefono-prefisso"
                  v-model="formData.phones[elemKey]['prefisso']"
                  type="phone"
                  maxlength="5"
                  class="custom-input w-2/5 mr-1"
                  placeholder="+ prefisso"
                />

                <input
                  id="newclient-telefono"
                  v-model="formData.phones[elemKey]['phone']"
                  type="phone"
                  maxlength="16"
                  class="custom-input"
                  placeholder="Inserisci il telefono"
                />
              </div>
            </div>
            <div class="flex">
              <div class="flex-1 py-2">
                <label
                  for="newclient-etichetta"
                  class="block text-gray-700 text-xs font-bold mb-2"
                >
                  Etichetta
                </label>
                <input
                  id="newclient-etichetta"
                  v-model="formData.phones[elemKey]['label']"
                  :type="elem.type"
                  :class="{ disabilitato: checkPhone == true }"
                  class="custom-input"
                  placeholder="Inserisci etichetta"
                />
              </div>
              <div class="flex items-center mt-8 ml-2">
                <div class="flex items-baseline">
                  <label class="block text-gray-700 text-sm font-bold mb-2">
                    {{ elem.buttonText }}
                  </label>
                  <a
                    :class="{ disabilitatoBottone: checkPhone == true }"
                    class="cursor-pointer"
                    @click="elem.function(elemKey)"
                  >
                    <PlusIcon
                      v-if="elem.button === 'add'"
                      class="w-4 h-4 ml-2.5 text-white rounded-sm bg-theme-41 font-bold"
                    />

                    <MinusIcon
                      v-else
                      class="w-4 h-4 ml-2.5 text-white rounded-sm bg-theme-41 font-bold"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="">
          <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
            <h2 class="ml-3 text-base font-bold">Email</h2>
          </div>
          <div class="mx-3 items-center">
            <input
              v-model="checkMail"
              type="checkbox"
              class="form-checkbox mr-1 h-3 w-3 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
              @click="provaFunction"
            />
            <label class="text-gray-700 text-xs font-semibold"
              >Non ho un'email</label
            >
            <!-- <p>{{ checkMail }}</p> -->
          </div>
          <!-- first row -->

          <div
            v-for="(elem, elemKey) in emailConfig"
            :key="elemKey"
            class="mx-3 flex flex-col justify-between gap-3 mt-3 lg:flex-row"
          >
            <div class="flex-1 py-2">
              <label
                for="newclient-email"
                class="block text-gray-700 text-xs font-bold mb-2"
              >
                {{ elem.name }}
              </label>
              <input
                id="newclient-email"
                v-model="formData.emails[elemKey]['email']"
                class="custom-input"
                :type="elem.type"
                placeholder="Inserisci l'email"
              />
            </div>

            <div class="flex">
              <div class="flex-1 py-2">
                <label
                  for="newclient-etichetta"
                  class="block text-gray-700 text-xs font-bold mb-2"
                >
                  Etichetta
                </label>
                <input
                  id="newclient-etichetta"
                  v-model="formData.emails[elemKey]['label']"
                  type="text"
                  class="custom-input"
                  placeholder="Inserisci etichetta"
                />
              </div>

              <div class="flex items-center mt-8 ml-2">
                <div class="flex items-baseline">
                  <label class="block text-gray-700 text-sm font-bold mb-2">
                    {{ elem.buttonText }}
                  </label>
                  <a
                    :class="{ disabilitatoBottone: checkMail == true }"
                    class="cursor-pointer"
                    @click="elem.function(elemKey)"
                  >
                    <PlusIcon
                      v-if="elem.button === 'add'"
                      class="w-4 h-4 ml-2.5 text-white rounded-sm bg-theme-41 font-bold"
                    />
                    <MinusIcon
                      v-else
                      class="w-4 h-4 ml-2.5 text-white rounded-sm bg-theme-41 font-bold"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end second row -->

        <!-- Third row -->

        <!-- end third row -->

        <!-- fourth row -->

        <!-- fifth  row -->
        <div>
          <div class="mx-3 flex-1 mt-2">
            <div class="mt-5 mb-2 p-2 bg-theme-45 nunito rounded-md">
              <h2 class="ml-3 text-base font-bold">Indirizzo</h2>
            </div>
            <label
              class="block text-gray-700 text-xs font-bold mb-2"
              for="newReferent-indirizzo"
            >
              Indirizzo
            </label>
            <Places
              :address-placeholder-props="formData.addressLabel"
              @place_changed="residencyChanged"
            />
          </div>
          <div class="mx-3 flex flex-col justify-between gap-3 lg:flex-row">
            <div class="flex-1 py-2 flex">
              <label class="block text-gray-700 text-xs font-bold mb-2"
                >Non trovi l'indirizzo su Google?</label
              >
              <input
                v-model="checkResidency"
                type="checkbox"
                class="form-checkbox ml-3 h-3 w-3 text-theme-41 rounded-sm text-sm focus:ring-0 focus:shadow-none"
              />
            </div>
          </div>
          <div v-if="checkResidency">
            <div class="mx-3 flex flex-col justify-between gap-3 md:flex-row">
              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newReferent-indirizzo"
                >
                  Indirizzo
                </label>
                <input
                  v-model="formData.address"
                  id="newReferent-indirizzo"
                  class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                  type="text"
                  placeholder="Inserisci l'indirizzo"
                />
              </div>
              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newReferent-house-number"
                >
                  Numero civico
                </label>
                <input
                  v-model="formData.house_number"
                  id="newReferent-house-number"
                  class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                  type="text"
                  placeholder="Inserisci il numero civico"
                />
              </div>
              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newReferent-citta"
                >
                  Città
                </label>
                <input
                  v-model="formData.city"
                  id="newReferent-citta"
                  class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                  type="text"
                  placeholder="Inserisci la città"
                />
              </div>
            </div>

            <!-- end fourth row -->

            <div class="mx-3 flex flex-col justify-between gap-3 md:flex-row">
              <div class="flex-0 py-2 w-1/6">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newReferent-cap"
                >
                  CAP
                </label>
                <input
                  v-model="formData.zip_code"
                  id="newReferent-cap"
                  class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                  type="text"
                  placeholder="Inserisci il CAP"
                />
              </div>
              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newReferent-provincia"
                >
                  Provincia
                </label>
                <input
                  v-model="formData.district"
                  class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                  id="newReferent-provincia"
                  placeholder="Inserisci la provincia"
                />
              </div>
              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newReferent-regione"
                >
                  Regione
                </label>
                <input
                  v-model="formData.region"
                  class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                  id="newReferent-regione"
                  placeholder="Inserisci la regione"
                />
              </div>
              <div class="flex-1 py-2">
                <label
                  class="block text-gray-700 text-xs font-bold mb-2"
                  for="newReferent-nazione"
                >
                  Nazione
                </label>
                <input
                  v-model="formData.country"
                  class="shadow appearance-none border border-gray-300 rounded w-full py-1.5 px-3 text-gray-700 text-sm leading-tight focus:ring-1 focus:ring-gray-400 focus:outline-none focus:border-gray-100 placeholder-gray-400"
                  id="newReferent-nazione"
                  placeholder="Inserisci la nazione"
                />
              </div>
            </div>
          </div>
        </div>
        <!-- end fifth row -->
      </div>
      <!-- end modal section -->

      <!-- footer -->

      <div class="mt-5 flex flex-col justify-end md:flex-row gap-3">
        <button
          @click="() => (external ? editReferent() : saveNewReferent())"
          type="button"
          class="orange-btn"
        >
          <SaveIcon class="w-5 h-5 mr-2" />
          {{ external ? 'Modifica' : 'Salva' }}
        </button>
        <button @click="closeReferente" type="button" class="gray-btn">
          <span class="h-5"></span>
          Annulla
        </button>
      </div>

      <!--  end footer -->

      <div class="mx-auto"></div>
    </div>
  </div>
  <div v-if="openWarning">
    <Warning @closeWarning="openWarning = false" />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import axios from '../../gateway/backend-api'
import Places from '../../components/places/Places.vue'
import Warning from '../nuovo-cliente/Warning'
export default defineComponent({
  components: {
    Places,
    Warning
  },
  props: {
    title: String,
    external: Object
  },
  emits: ['close'],
  data() {
    return {
      error_message: 'Questo è un campo obbligatorio',
      openWarning: false,
      inputcontrol: {
        first_name: true,
        last_name: true,
        last_name_length: true,
        first_name_length: true
      },
      formData: {
        phones: [{ prefisso: '+39', phone: '', label: '' }],
        emails: [
          {
            email: '',
            label: ''
          }
        ],
        addressLabel: ''
      },
      checkResidency: false,
      checkEmail: false,
      checkPhone: false,
      phoneConfig: [
        {
          name: 'Telefono',
          type: 'text',
          button: 'add',
          buttonText: 'Aggiungi',
          function: this.addPhone
        }
      ],
      emailConfig: [
        {
          name: 'Email',
          type: 'text',
          button: 'add',
          buttonText: 'Aggiungi',
          function: this.addEmail
        }
      ]
    }
  },
  mounted() {
    if (this.external) {
      this.formData.first_name = this.external.first_name
      this.formData.last_name = this.external.last_name

      if (this.external.phones && this.external.phones['phone1']) {
        for (let i = 1; this.external.phones['phone' + i] != null; i++) {
          if (i > 1) {
            this.addPhone()
          }
          if (this.external.phones['phone' + i].phone === null) {
            this.checkPhone = true
          }

          this.formData.phones[i - 1]['phone'] =
            this.external.phones['phone' + i].phone
          this.formData.phones[i - 1]['prefisso'] =
            this.external.phones['phone' + i].prefisso ?? '+39'
          this.formData.phones[i - 1]['label'] =
            this.external.phones['phone' + i].label
        }
      } else {
        this.checkPhone = true
      }

      if (this.external.emails && this.external.emails['email1']) {
        for (let i = 1; this.external.emails['email' + i] != null; i++) {
          if (i > 1) {
            this.addEmail()
          }
          if (this.external.emails['email' + i].email === null) {
            this.checkMail = true
          }
          this.formData.emails[i - 1]['email'] = this.external.emails[
            'email' + i
          ].email
            ? this.external.emails['email' + i].email
            : ''
          this.formData.emails[i - 1]['label'] = this.external.emails[
            'email' + i
          ].label
            ? this.external.emails['email' + i].label
            : ''
        }
      } else {
        this.checkMail = true
      }

      if (this.external.address) {
        let addressLabel = ''
        if (this.external.address.address) {
          this.formData.address = this.external.address.address
          addressLabel += this.external.address.address + ''
        }
        if (this.external.address.house_number) {
          this.formData.house_number = this.external.address.house_number
          addressLabel += ', ' + this.external.address.house_number
        }
        if (this.external.address.city) {
          this.formData.city = this.external.address.city
          addressLabel += ', ' + this.external.address.city
        }
        if (this.external.address.district) {
          this.formData.district = this.external.address.district
          addressLabel += ', ' + this.external.address.district
        }
        if (this.external.address.country) {
          this.formData.country = this.external.address.country
          addressLabel += ', ' + this.external.address.country
        }

        if (this.external.address.region) {
          this.formData.region = this.external.address.region
        }
        if (this.external.address.zip_code) {
          this.formData.zip_code = this.external.address.zip_code
        }
        this.formData.addressLabel = addressLabel
      }
    }
  },
  methods: {
    clearResidency() {
      this.formData['house_number'] = ''
      this.formData['country'] = ''
      this.formData['region'] = ''
      this.formData['district'] = ''
      this.formData['city'] = ''
      this.formData['address'] = ''
      this.formData['zip_code'] = ''
      this.formData['lat'] = ''
      this.formData['lng'] = ''
    }, // Method called by Places emit to bind the address from the Google API to our residency's fields
    residencyChanged: function (elem) {
      this.clearResidency()
      this.formData['lat'] = elem.lat
      this.formData['lng'] = elem.lng
      let includeRoute = false
      for (let i = 0; i < elem.length; i++) {
        if (elem[i].types.includes('route')) includeRoute = true
        if (elem[i].types.includes('street_number'))
          this.formData['house_number'] = elem[i].long_name
      }
      if (includeRoute) {
        elem.find((element) => {
          if (element.types.includes('country')) {
            this.formData['country'] = element.long_name
          }
          if (element.types.includes('administrative_area_level_1')) {
            this.formData['region'] = element.long_name
          }
          if (element.types.includes('administrative_area_level_2')) {
            this.formData['district'] = element.short_name
          }
          if (element.types.includes('administrative_area_level_3')) {
            this.formData['city'] = element.long_name
          }
          if (element.types.includes('route')) {
            this.formData['address'] = element.long_name
          }
          if (element.types.includes('postal_code')) {
            this.formData['zip_code'] = element.long_name
          }
        })
      }
    },
    addPhone: function () {
      if (this.formData.phones.length > 1) {
        alert('Puoi aggiungere solo 2 campi')
      } else {
        const newPhone = {
          name: 'Telefono',
          type: 'text',
          button: 'remove',
          buttonText: 'Rimuovi',
          function: this.removePhone
        }
        this.formData.phones.push({ phone: '', label: '', prefisso: '+39' })
        this.phoneConfig.push(newPhone)
      }
    },
    removePhone: function (elemKey) {
      this.formData.phones.splice(elemKey, 1)
      this.phoneConfig.splice(elemKey, 1)
    },
    addEmail: function () {
      if (this.formData.emails.length > 1) {
        alert('Puoi aggiungere solo 2 campi')
      } else {
        const newEmail = {
          name: 'Email',
          type: 'text',
          button: 'remove',
          buttonText: 'Rimuovi',
          function: this.removeEmail
        }
        this.formData.emails.push({ email: '', label: '' })
        this.emailConfig.push(newEmail)
      }
    },
    removeEmail: function (elemKey) {
      this.formData.emails.splice(elemKey, 1)
      this.emailConfig.splice(elemKey, 1)
    },
    closeReferente() {
      this.$emit('close')
    },
    checkData() {
      let control = true
      if (this.formData.last_name == undefined) {
        this.inputcontrol.last_name = false
        control = false
      } else {
        if (this.formData.last_name.length < 2) {
          this.inputcontrol.last_name_length = false
          control = false
        }
      }
      if (this.formData.first_name == undefined) {
        this.inputcontrol.first_name = false
        control = false
      } else {
        if (this.formData.first_name.length < 2) {
          this.inputcontrol.first_name_length = false
          control = false
        }
      }
      return control
    },
    editReferent() {
      if (!this.checkData()) {
        this.openWarning = true
      } else {
        let external = this.formData
        if (this.title === 'referente') external.type = 'referent'
        else external.type = 'supplier'
        let phones = {}
        if (this.checkPhone === true) {
          phones['phone1'] = null
        } else {
          for (let i = 0; i < this.formData.phones.length; i++) {
            phones['phone' + (i + 1)] = this.formData.phones[i]
          }
        }

        let emails = {}
        if (this.checkMail === true) {
          emails['email1'] = null
        } else {
          for (let i = 0; i < this.formData.emails.length; i++) {
            emails['email' + (i + 1)] = this.formData.emails[i]
          }
        }
        external.emails = emails
        external.phones = phones
        axios
          .post(
            '/' +
              localStorage.getItem('clinic') +
              '/external/' +
              this.external.id +
              '/edit',
            external
          )
          .then(() => {
            this.$emit('close')
          })
      }
    },
    saveNewReferent() {
      if (!this.checkData()) {
        this.openWarning = true
      } else {
        let external = this.formData
        if (this.title === 'referente') external.type = 'referent'
        else external.type = 'supplier'
        let phones = {}
        if (this.checkPhone === true) {
          phones['phone1'] = null
        } else {
          for (let i = 0; i < this.formData.phones.length; i++) {
            phones['phone' + (i + 1)] = this.formData.phones[i]
          }
        }

        let emails = {}
        if (this.checkMail === true) {
          emails['email1'] = null
        } else {
          for (let i = 0; i < this.formData.emails.length; i++) {
            emails['email' + (i + 1)] = this.formData.emails[i]
          }
        }
        external.emails = emails
        external.phones = phones
        axios
          .post('/' + localStorage.getItem('clinic') + '/external', external)
          .then(() => {
            this.$store.dispatch('commonComponents/openNotificationModal', {
              message: 'Nuovo ' + this.title + ' inserito con successo'
            })

            this.$emit('close')
          })
      }
    }
  }
})
</script>

<style scoped>
>>> {
  --vs-controls-size: 0.7;
  --vs-controls-color: #94a3b8;
  --vs-border-color: none;

  --vs-dropdown-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);

  --vs-actions-padding: 1px 0 1px -2px;

  --vs-dropdown-option--active-color: #333435;
  --vs-dropdown-option-padding: 3px 20px;
  --vs-dropdown-option--active-bg: #edf2f7;

  --vs-selected-bg: #edf2f7;
  /* --vs-selected-border-color: none; */
  --vs-selected-color: #333435;

  --vs-dropdown-option--active-bg: #aebfcf;
  --vs-search-input-placeholder-color: #d4d8e1;
  --vs-font-size: 0.8 rem;

  --vs-transition-timing-function: cubic-bezier(1, -0.115, 0.975, 0.855);
  --vs-transition-duration: 150ms;
}
</style>
