<template>
  <div
    class="tab-content relative bg-white intro-y box px-5 py-1 overflow-auto content-container scrollbar_custom"
  >
    <div
      class="tab-pane active"
      role="tabpanel"
      aria-labelledby="invoices_mobile-tab"
    >
      <div class="paginator-container">
        <div class="flex items">
          <div class="mr-5">
            <span class="flex-row text-md ml-2 font-bold mr-2 mt-1"
              >Mostra
            </span>
            <select
              v-model="pagination.forPage"
              class="form-select w-16 h-6 px-3 pt-0 pb-1 border-transparent bg-theme-2 focus:ring-1 focus:ring-theme-44 focus:outline-none placeholder-theme-44"
              @change="(event) => changeNumPages(event)"
            >
              <option
                v-for="show in forPageOption"
                :key="show"
                :value="show"
                class="form-select"
              >
                {{ show }}
              </option>
            </select>
            <span class="text-md font-bold ml-2 mt-1"> elementi</span>
          </div>
        </div>
        <Paginator
          :for-page-option="pagination.forPage"
          :total-records="totalRecords"
          @changePagination="changePagination"
        />
      </div>
      <div
        class="table-container fatt-att-cont scrollbar-thin scrollbar-thumb-theme-44 scrollbar-track-theme-2 overflow-scroll"
      >
        <table class="w-full shadow custom-border rounded-lg">
          <thead class="h-10 t-header">
            <tr>
              <th
                v-for="(item, itemKey) in table_config"
                :key="itemKey"
                :name="item.title"
              >
                {{ item.title }}
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody class="overflow-scroll scrollbar-none">
            <tr>
              <td
                v-for="(elem, index) in table_config"
                :key="index"
                class="text-center px-1 py-3"
              >
                <input
                  v-model="elem.value"
                  :type="elem.type"
                  min="0"
                  :name="elem.title"
                  class="form-control h-7 mt-2"
                  :class="{
                    invisible: elem.type === 'none',
                    'p-0': elem.type === 'date'
                  }"
                  :placeholder="elem.title"
                  @change="filterHandler(elem)"
                />
              </td>
            </tr>
            <tr
              v-for="(n, index) in attachments"
              :key="index"
              class="h-12 text-xs font-normal hover:bg-theme-44 cursor-pointer"
              :class="{ 'bg-theme-2': index % 2 === 0 }"
            >
              <Cell
                class="text-center border-r border-theme-45"
                :elementi="$FormatDateMin(n.last_update)"
              />
              <Cell
                class="text-center border-r border-theme-45"
                :elementi="n.file_original_name"
              />
              <Cell
                class="text-center border-r border-theme-45"
                :elementi="n.description"
              />
              <td class="text-center border-r border-theme-45 text-sm px-3">
                <div
                  v-if="n.customer"
                  class="flex items-center gap-5 capitalize"
                >
                  <div @click="handleGoToCustomer(n.customer.id)">
                    <ChevronsRightIcon
                      class="h-5 w-5 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                    />
                    {{
                      n.customer.ragione_sociale
                        ? n.customer.ragione_sociale
                        : n.customer.first_name + ' ' + n.customer.last_name
                    }}
                  </div>
                </div>
              </td>
              <td class="text-center border-r border-theme-45 text-sm px-3">
                <div v-if="n.pet" class="flex items-center gap-5 capitalize">
                  <div
                    class="tooltipbuttonprofile"
                    @click="handleGoToPet(n.pet.id)"
                  >
                    <ChevronsRightIcon
                      class="h-5 w-5 bg-theme-41 border border-theme-41 rounded-full text-white cursor-pointer hover:bg-white hover:text-theme-41"
                    />
                    {{ n.pet.name }}
                    <span
                      class="tooltipbuttontext"
                      style="margin-top: -15px; margin-left: 20px"
                      >Apri anagrafica</span
                    >
                  </div>
                </div>
              </td>

              <Cell
                class="text-center border-r border-theme-45"
                :elementi="
                  n.staff_user
                    ? n.staff_user.first_name + ' ' + n.staff_user.last_name
                    : 'Vanadium'
                "
              />
              <!-- <Cell
                class="text-center border-r border-theme-45"
                :elementi="n.type"
              /> -->
              <td>
                <div class="flex gap-5 items-center text-center justify-center">
                  <a
                    :href="n.file_url"
                    target="blank"
                    class="tooltipbuttonprofile hover:text-theme-41"
                  >
                    <i class="fas fa-file-pdf text-base" />
                    <span
                      class="tooltipbuttontext"
                      style="margin-top: -10px; margin-left: -45px"
                      >Visualizza PDF</span
                    >
                  </a>
                  <a class="hover:text-theme-41" @click="deleteRecord(n)">
                    <i class="fas fa-times text-base" />
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
const forPageOption = [5, 10, 50]

import Paginator from '../../components/paginator/Main.vue'
import Cell from '../../components/cvit-table/Cell.vue'
import axios from '../../gateway/backend-api'
import router from '@/router'
import Toastify from 'toastify-js'
import { mapActions, mapGetters } from 'vuex'
// import moment from 'moment'
// import CellBadge from '../../components/cvit-table/Cell-Badge.vue'

export default {
  components: {
    Paginator,
    Cell
    // CellBadge,
  },
  data() {
    return {
      attachments: [],
      totalRecords: 0,
      forPageOption,
      showEntries: forPageOption,
      active_filters: {},
      filterActive: false,
      pagination: {
        page: 1,
        forPage: forPageOption[0]
      },
      table_config: [
        {
          key: 'last_update',
          title: 'Data Caricamento',
          type: 'date'
        },
        {
          key: 'file_original_name',
          title: 'Filename',
          type: 'text'
        },
        {
          key: 'description',
          title: 'Descrizione',
          type: 'text'
        },
        {
          key: 'customer',
          title: 'Cliente',
          type: 'text'
        },
        {
          key: 'pet',
          title: 'Paziente',
          type: 'text'
        },
        {
          key: 'staff_user',
          title: 'Caricato da',
          type: 'text'
        },
        {
          key: 'buttons',
          title: 'Azioni rapide',
          type: 'none'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      getPetsDetails: 'anagraficaPazienti/getPetsDetails',
      getComparisonPets: 'anagraficaPazienti/getComparisonPets',
      getCustomersDetails: 'anagraficaPazienti/getCustomersDetails',
      getComparisonCustomer: 'anagraficaPazienti/getComparisonCustomer',
      getTab: 'anagraficaPazienti/getTab',
      getShow: 'anagraficaPazienti/getShow'
    })
  },
  mounted() {
    this.getAttachments()
    const temp = []
    const x = this.table_config
    for (var elem of x) {
      temp.push({ ...elem, value: '' })
    }

    this.table_config = temp
  },
  methods: {
    ...mapActions({
      getPetAction: 'anagraficaPazienti/getPetAction',
      getCustomerAction: 'anagraficaClienti/getCustomerAction',
      goToOpenedClient: 'anagraficaClienti/goToOpenedClient'
    }),

    // Metodo generico per handler filtro select
    filterSelect(elem, field) {
      if (elem) {
        this.active_filters[field] = elem.id ? elem.id : elem.value
      } else this.active_filters[field] = undefined

      this.checkIfFilterActive()

      if (this.filterActive) this.getAttachments()
    },
    filterHandler(elem) {
      this.active_filters[elem.key] = elem.value

      this.checkIfFilterActive()

      if (this.filterActive) this.getAttachments()
    },
    checkIfFilterActive() {
      var count = 0
      Object.entries(this.active_filters).forEach((v) => {
        if (v) ++count
      })
      if (count > 0) this.filterActive = true
      else this.filterActive = false
    },

    getAttachments() {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .post('/' + localStorage.getItem('clinic') + '/attachments', {
          active_filters: this.active_filters,
          per_page: this.pagination.forPage,
          page: this.pagination.page
        })
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.attachments = res.data.data
          this.totalRecords = res.data.total
        })
        .catch(() => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
        })
    },
    changePagination: function (value) {
      if (this.pagination.page != value.page) {
        this.pagination.page = value.page
        this.pagination.forPage = value.forPage
        this.getAttachments()
      }
    },
    changeNumPages: function (elem) {
      this.pagination.forPage = elem.target.value
      this.getAttachments()
    },

    handleGoToCustomer(customer_id) {
      this.$store.dispatch('commonComponents/showLoadingModalAction')
      axios
        .get('/' + localStorage.getItem('clinic') + '/customer/' + customer_id)
        .then((res) => {
          this.$store.dispatch('commonComponents/hideLoadingModalAction')
          this.$goToCustomer(res.data)
        })
    },
    handleGoToPet: function (id) {
      axios
        .get('/' + localStorage.getItem('clinic') + '/pet/' + id)
        .then((res) => {
          const pet = res.data

          if (
            this.getPetsDetails &&
            this.getPetsDetails.length < 4 &&
            !this.getComparisonPets.includes(pet.id)
          ) {
            this.getPetAction(res.data)
            router.push({ name: 'side-menu-anagrafica-anagrafica-pazienti' })
          } else if (this.getComparisonPets.includes(pet.id)) {
            this.tabAlreadyOpen()
            router.push({ name: 'side-menu-anagrafica-anagrafica-pazienti' })
          } else {
            this.maxTabOpen()
            router.push({ name: 'side-menu-anagrafica-anagrafica-pazienti' })
          }
        })
    },
    preview(url) {
      this.$store.dispatch('commonComponents/hideLoadingModalAction')
      // const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Fattura' + 'test' + '.pdf')
      document.body.appendChild(link)
      this.$store.dispatch('commonComponents/openPdfModal', {
        title: 'test',
        link: url,
        background: false,
        print: () => {
          console.log('PRINT')
        },
        download: () => {
          link.click()
        }
      })
    },
    tabAlreadyOpen: function () {
      Toastify({
        node: cash('#tab-already-open').clone().removeClass('hidden')[0],
        duration: 3000,
        newWindow: true,
        close: true,
        gravity: 'top',
        position: 'right',
        stopOnFocus: true
      }).showToast()
    },
    deleteRecord: function (doc) {
      this.$store.dispatch('commonComponents/openConfirmModal', {
        title: 'CONFERMA',
        subtitle: 'Sei sicuro di voler cancellare il documento?',
        background: false,
        confirm_text: 'Conferma',
        decline_text: 'Annulla',
        confirm: () => {
          this.$store.dispatch('commonComponents/showLoadingModalAction')
          axios
            .delete(
              '/' +
                localStorage.getItem('clinic') +
                '/attachments/' +
                doc.id +
                '/delete-file'
            )
            .then(() => {
              this.$store.dispatch('commonComponents/openNotificationModal', {
                message: 'File rimosso con successo!'
              })
              this.getAttachments()
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
            .catch(() => {
              this.$store.dispatch('commonComponents/hideLoadingModalAction')
            })
        },
        decline: () => {}
      })
    }
  }
}
</script>
